import { PatientStatus, StatusOfPatient } from "@src/types/wait-list";
import { QueueData } from "@src/types/waitlist/waitlist";
import useUserStore from "@store/useUserStore";
import useWaitListStore from "@store/useWaitListStore";
import { useQueryClient } from "react-query";

const useStationSelection: () => {
	handleStationChange: (value: string) => void;
} = () => {
	const queryClient = useQueryClient();
	const setCurrentStationId = useUserStore((s) => s.setCurrentStationId);
	const currentLocationId = useUserStore((s) => s.currentLocationId);
	const setPatientsObject = useWaitListStore((s) => s.setPatientsObject);
	const getQueryList = queryClient.getQueryData([
		"queue-list",
		currentLocationId,
	]) as {
		status: boolean;
		data: QueueData;
		message: string;
	};

	const handleStationChange = (value: string) => {
		if (value !== "All Stations") {
			const stationId = parseInt(value);
			setCurrentStationId(stationId);
			setPatientsObject({
				[PatientStatus.Pending]: getQueryList.data.queue_order.filter(
					(item) =>
						item.station_id === stationId &&
						item.status === StatusOfPatient.Pending
				),
				[PatientStatus.Upcoming]: getQueryList.data.queue_order.filter(
					(item) =>
						item.station_id === stationId &&
						item.status === StatusOfPatient.Upcoming
				),
				[PatientStatus.NextInLine]:
					getQueryList.data.queue_order.filter(
						(item) =>
							item.station_id === stationId &&
							item.status === StatusOfPatient.NextInLine
					),
				[PatientStatus.Serving]: getQueryList.data.queue_order.filter(
					(item) =>
						item.station_id === stationId &&
						item.status === StatusOfPatient.GettingService
				),
				[PatientStatus.Schedule]: [],
			});
		} else {
			setCurrentStationId("All Stations");
			setPatientsObject({
				[PatientStatus.Pending]: getQueryList.data.queue_order.filter(
					(item) =>
						item.location_id === currentLocationId &&
						item.status === StatusOfPatient.Pending
				),
				[PatientStatus.Upcoming]: getQueryList.data.queue_order.filter(
					(item) =>
						item.location_id === currentLocationId &&
						item.status === StatusOfPatient.Upcoming
				),
				[PatientStatus.NextInLine]:
					getQueryList.data.queue_order.filter(
						(item) =>
							item.location_id === currentLocationId &&
							item.status === StatusOfPatient.NextInLine
					),
				[PatientStatus.Serving]: getQueryList.data.queue_order.filter(
					(item) =>
						item.location_id === currentLocationId &&
						item.status === StatusOfPatient.GettingService
				),
				[PatientStatus.Schedule]: [],
			});
		}
	};

	return {
		handleStationChange,
	};
};

export default useStationSelection;
