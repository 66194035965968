import { Option } from "@components/form/CustomSelect";
import MultiSelect from "@src/components/ui-extended/multi-select";
import RoundCheckbox from "@src/components/ui-extended/round-checkbox";
import { Label } from "@src/components/ui/label";
import useUserStore from "@src/store/useUserStore";
import { TeamMemberRole, TeamMemberRoleType } from "@src/types/team-member";
import { cn } from "@src/utils/functions";
import React from "react";

const TeamMemberRoleSelector: React.FC<{
	label: string;
	value: TeamMemberRoleType;
	isActive: boolean;
	description: string;
	currentSelectedRole: TeamMemberRoleType;
	selectedLocations: Option[];
	setSelectedLocations: React.Dispatch<React.SetStateAction<Option[]>>;
	onClick: () => void;
}> = ({
	label,
	value,
	isActive,
	description,
	currentSelectedRole,
	selectedLocations,
	setSelectedLocations,
	onClick,
}) => {
	const user = useUserStore((s) => s.user);
	const [adminPartners, setAdminPartners] = React.useState([
		{
			value: "none",
			label: "None",
			disabled: true,
		},
	]);

	return (
		<div
			className={`flex items-center space-x-2.5 rounded-xl bg-white px-5 py-3.5 shadow-[0px_0px_5px_0px_rgba(0,0,0,0.25)]`}
		>
			<div
				className={cn("self-center", {
					"self-start py-2.5":
						currentSelectedRole ===
							TeamMemberRole.LocationManager &&
						value === TeamMemberRole.LocationManager,
				})}
				onClick={onClick}
			>
				<RoundCheckbox isActive={isActive} />
			</div>
			<div className="flex flex-col space-y-3">
				<div className="flex flex-col space-y-[5px]">
					<div
						className="flex w-fit cursor-pointer items-center space-x-2"
						// onClick={() => setShowInfo(true)}
					>
						<p className="text-medium text-[14px] leading-[20px] tracking-[-0.1px]">
							{label}
						</p>
						<i className="mgc_information_line grid size-[20px] place-content-center rounded-md bg-[#F5F5F5] text-[12px] before:!text-primary" />
					</div>
					<p className="text-[12px] leading-[18px] text-[#323539]">
						{description}
					</p>
				</div>
				{currentSelectedRole === TeamMemberRole.LocationManager &&
					value === TeamMemberRole.LocationManager && (
						<div className="flex flex-col space-y-1.5">
							<Label className="text-[12px] leading-[12px] text-[#323539]">
								Assigned Locations
							</Label>
							<MultiSelect
								options={
									user?.business.locations.map((item) => ({
										value: item.id.toString(),
										label: item.name,
									})) ?? []
								}
								placeholder={"Location(s)"}
								selected={selectedLocations}
								onSelect={(option) => {
									setSelectedLocations(option);
								}}
								onUnselect={(selectedOption) =>
									setSelectedLocations((prev) =>
										prev.filter(
											(s) =>
												s.value !== selectedOption.value
										)
									)
								}
							/>
						</div>
					)}
			</div>
		</div>
	);
};

export default TeamMemberRoleSelector;
