import { Button } from "@src/components/ui/button";
import { Input } from "@src/components/ui/input";
import { cn, getInitials } from "@src/utils/functions";
import React from "react";

const MessagesTab: React.FC = () => {
	return (
		<section className="">
			<h2 className="text-[22px] font-semibold leading-[30px] tracking-[-1%] text-[#323539]">
				Messages
			</h2>
			<ul className="mt-3 flex h-[320px] flex-col rounded-xl p-4 shadow-[inset_0px_0px_4px_0px_rgba(0,0,0,0.15)]">
				{dummyMessages.map((item, idx) => (
					<IndividualMessage key={idx} {...item} />
				))}
			</ul>
			<form
				className="mt-2.5 flex max-h-[44px] flex-1 justify-between space-x-2.5 rounded-xl py-2.5 pl-5 pr-2.5 shadow-[inset_0px_0px_4px_0px_rgba(0,0,0,0.25)] outline-none"
				// onSubmit={handleSubmit(onSubmit)}
			>
				<Input
					type="text"
					className="h-fit min-h-fit flex-1 rounded-none border-none p-0"
					placeholder="Enter Here"
					// {...register("displayText")}
				/>
				<Button
					className="h-8 max-w-[140px] flex-1 self-center rounded-md text-white"
					type="submit"
				>
					Send
				</Button>
			</form>
		</section>
	);
};

export default MessagesTab;

const IndividualMessage: React.FC<{
	id: number;
	senderName: string;
	message: string;
	deliveryStatus: string;
	date: string;
	time: string;
	isMe?: boolean;
}> = ({ senderName, message, deliveryStatus, date, time, isMe }) => {
	return (
		<li
			className={cn("flex size-fit items-start gap-x-1.5", {
				"ml-auto flex flex-row-reverse": isMe,
			})}
		>
			<p
				className={cn(
					"size-fit rounded-xl bg-primary px-2 py-1 font-semibold text-[#E5E5E7]",
					{ "bg-[#D5D5D5] text-[#323539]": isMe }
				)}
			>
				{getInitials(senderName, true)}
			</p>
			<div className="space-y-0.5">
				<p
					className={cn(
						"rounded-xl bg-[#EEF5FA] px-3.5 py-1 tracking-[-0.16px] text-[#323539]",
						{ "bg-[#F6F6F7]": isMe }
					)}
				>
					{message}
				</p>
				<div
					className={cn(
						"flex items-center space-x-1.5 text-[10px] tracking-[-1%] text-[#858C95]",
						{ "pl-3.5": !isMe, "justify-end pr-3.5 ": isMe }
					)}
				>
					<p>{deliveryStatus}</p>
					<p className="">|</p>
					<p>{date}</p>
					<p className="">|</p>
					<p>{time}</p>
				</div>
			</div>
		</li>
	);
};

const dummyMessages: {
	id: number;
	senderName: string;
	message: string;
	deliveryStatus: string;
	date: string;
	time: string;
	isMe?: boolean;
}[] = [
	{
		id: 1,
		senderName: "Poju Nameki",
		message: "Hello, I am running late!",
		deliveryStatus: "Delivered",
		date: "2024-08-26",
		time: "10:45 AM",
	},
	{
		id: 2,
		senderName: "Bosun Naa",
		message: "No worries, we've moved you by 30 mins",
		deliveryStatus: "Delivered",
		date: "2024-08-26",
		time: "10:47 AM",
		isMe: true,
	},
	{
		id: 3,
		senderName: "Poju Nameki",
		message: "Okay Thanks",
		deliveryStatus: "Delivered",
		date: "2024-08-26",
		time: "10:50 AM",
	},
];
