import { ScheduleCustomIntake } from "@/src/types/schedule";
import { z } from "zod";

export const createScheduleCustomFieldsSchema = (
	customIntakes: ScheduleCustomIntake[]
) => {
	const customIntakeSchema: Record<string, z.ZodTypeAny> = {};

	customIntakes.forEach((intake) => {
		let fieldSchema: z.ZodTypeAny;

		switch (intake.type) {
			case "text":
				fieldSchema = z.string();
				break;
			case "numeric":
				fieldSchema = z.string();
				break;
			case "boolean":
				fieldSchema = z.boolean();
				break;
			case "date":
				fieldSchema = z.date();
				break;
			case "date_range":
				fieldSchema = z.object({
					from: z.date(),
					to: z.date(),
				});
				break;
			case "dropdown":
				fieldSchema = z.array(
					z.object({
						label: z.string(),
						value: z.string(),
					})
				);
				break;
			case "checkbox":
				fieldSchema = z
					.array(z.string())
					.nonempty(`${intake.name} is required`);
				break;

			case "attachment":
				fieldSchema = z.instanceof(File);
				break;
			default:
				fieldSchema = z.any();
		}

		if (intake.field_requirement === "yes") {
			fieldSchema = z.preprocess(
				(val) => {
					if (val === "" || val === null || val === undefined) {
						return undefined;
					}
					return val;
				},
				fieldSchema.refine((val) => val !== undefined, {
					message: `${intake.name} is required`,
				})
			);
		} else {
			fieldSchema = fieldSchema.optional();
		}

		customIntakeSchema[intake.key] = fieldSchema;
	});

	// Return just the custom_intakes schema
	return z.object({
		custom_intakes: z.object(customIntakeSchema).optional(),
	});
};

export const ScheduleCustomIntakesFormat = (
	data: z.infer<typeof schema>,
	customIntakeFields,
	schema
) => {
	if (!data?.custom_intakes) return undefined;
	const fullCustomIntakes: Record<string, any> = {};
	customIntakeFields?.forEach((intake) => {
		if (!data?.custom_intakes) return undefined;
		const value = data?.custom_intakes[intake.key];

		switch (intake.type) {
			case "text":
				fullCustomIntakes[intake.key] = value;
				break;
			case "numeric":
				fullCustomIntakes[intake.key] = value;
				break;

			case "boolean":
				fullCustomIntakes[intake.key] = value;
				break;

			case "date":
				fullCustomIntakes[intake.key] =
					value && value instanceof Date
						? value.toISOString().split("T")[0]
						: value;
				break;

			case "date_range":
				if (
					value &&
					typeof value === "object" &&
					"from" in value &&
					"to" in value
				) {
					fullCustomIntakes[intake.key] = `${
						value.from instanceof Date
							? value.from.toISOString().split("T")[0]
							: value.from
					} - ${
						value.to instanceof Date
							? value.to.toISOString().split("T")[0]
							: value.to
					}`;
				}
				break;

			case "dropdown":
				fullCustomIntakes[intake.key] = Array.isArray(value)
					? value.map((v) => v.value)
					: undefined;
				break;
			case "checkbox":
				fullCustomIntakes[intake.key] = value;
				break;

			case "attachment":
				// Handle file attachment (you might need to implement file upload logic)
				fullCustomIntakes[intake.key] =
					value instanceof File ? value.name : value;
				break;

			default:
				fullCustomIntakes[intake.key] = value;
		}
	});
	return fullCustomIntakes;
};
