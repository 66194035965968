import React, { useRef } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Label } from "@src/components/ui/label";
import { Button } from "@src/components/ui/button";
import Checkbox from "@src/components/ui-extended/checkbox";
import FormInput from "@src/components/form/FormInput";
import DraggableWrapper from "@src/components/global/DraggableContent";
import RichTextEditor from "@src/components/RichTextEditor/Editor";
import useCustomToast from "@src/components/CustomToast";
import { HiOutlineCloudUpload } from "react-icons/hi";
import { getErrorMessage } from "@utils/functions";

type Props = {
	type: string;
};

const IntakeFieldOption: React.FC<Props> = ({ type }) => {
	const fileInputRef = useRef<HTMLInputElement>(null);
	const {
		register,
		watch,
		setValue,
		getValues,
		control,
		formState: { errors },
	} = useFormContext();
	const customToast = useCustomToast();

	const handleCheckboxSelection = (field: string, value: boolean) => {
		setValue(field, value ? 1 : 0);
	};

	const handleApprovedFormatsChange = (
		format: string,
		isChecked: boolean
	) => {
		const currentFormats = watch("approved_formats");
		const updatedFormats = isChecked
			? [...currentFormats, format]
			: currentFormats.filter((f: string) => f !== format);
		setValue("approved_formats", updatedFormats);
	};

	const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (!file) return;

		const fileFormat = file.type.split("/")[1];
		const acceptedFormats = ["jpg", "jpeg", "png", "gif", "svg"];

		if (file.size > 2 * 1024 * 1024) {
			customToast("Max file size is 2MB", {
				id: "image-file-upload",
				type: "error",
			});
			return;
		}

		if (!acceptedFormats.includes(fileFormat)) {
			customToast(`${fileFormat} is not supported`, {
				id: "logo-file-upload",
				type: "error",
			});
			return;
		}

		const reader = new FileReader();
		reader.onloadend = () => {
			setValue("image", reader.result as string);
		};
		reader.readAsDataURL(file);
	};

	const renderFieldOptions = () => {
		switch (type) {
			case "checkbox":
			case "dropdown":
				return (
					<div className="mt-5">
						<Label className="mt-4 text-sm font-semibold tracking-[-0.1px] text-[#323539]">
							{type === "dropdown"
								? "[Dropdown] Options"
								: "Options"}
						</Label>
						<DraggableWrapper
							initialItems={getValues("options")}
							newItem="New Option"
						/>
						<div className="mt-3 flex items-center space-x-2">
							<Checkbox
								id="allow_multiple"
								className="rounded-sm border-2 border-[#D1D1D1]"
								isChecked={watch("allow_multiple") === 1}
								handleCheckboxChange={() =>
									handleCheckboxSelection(
										"allow_multiple",
										!watch("allow_multiple")
									)
								}
							/>
							<Label htmlFor="allow_multiple">
								Allow multiple selections
							</Label>
						</div>
					</div>
				);
			case "text":
				return (
					<div className="mt-3 flex items-center space-x-2">
						<Checkbox
							id="long_text"
							className="rounded-sm border-2 border-[#D1D1D1]"
							isChecked={watch("long_text") === 1}
							handleCheckboxChange={() =>
								handleCheckboxSelection(
									"long_text",
									!watch("long_text")
								)
							}
						/>
						<Label htmlFor="long_text">Long Text</Label>
					</div>
				);
			case "numeric":
				return (
					<div className="mt-5">
						<FormInput
							inputType="text"
							label="Numerical Unit Title"
							className="h-10 w-full"
							placeholder="N/A"
							register={{ ...register("numeric_unit_title") }}
						/>
					</div>
				);
			case "attachment":
				return (
					<div className="mt-3">
						<Label className="mt-4 text-sm font-medium tracking-[-0.1px] text-[#323539]">
							Select approved format(s)
						</Label>
						<div className="mt-3 grid max-w-[300px] grid-cols-2 gap-3 pl-3">
							{["Png", "Jpeg", "Pdf", "Csv"].map((format) => (
								<div
									key={format}
									className="flex items-center space-x-2"
								>
									<Controller
										name="approved_formats"
										control={control}
										render={({ field }) => (
											<Checkbox
												id={format}
												className="rounded-sm border-2 border-[#D1D1D1]"
												isChecked={field.value.includes(
													format
												)}
												handleCheckboxChange={() =>
													handleApprovedFormatsChange(
														format,
														!field.value.includes(
															format
														)
													)
												}
											/>
										)}
									/>
									<Label htmlFor={format.toLowerCase()}>
										{format.toUpperCase()}
									</Label>
								</div>
							))}
						</div>
					</div>
				);
			case "infoText":
				return (
					<div className="mt-3">
						<RichTextEditor
							name="info_text_value"
							control={control}
						/>
					</div>
				);
			case "infoImage":
				return (
					<div className="mt-3">
						<input
							type="file"
							accept="image/jpeg,image/png"
							{...register("image", {
								onChange: handleImageUpload,
							})}
							ref={fileInputRef}
							className="hidden"
							id="image-upload"
						/>
						<Button
							type="button"
							variant="outline"
							className="mb-2 w-full border-primary text-primary"
							onClick={() => fileInputRef.current?.click()}
						>
							<HiOutlineCloudUpload className="mr-2 text-lg" />
							Upload Image
						</Button>
						<p>File must be a JPG or PNG. Max size of 5MB</p>
						{getErrorMessage(errors.image?.message) && (
							<small className="mt-1.5 text-sm text-red-500">
								{getErrorMessage(errors.image?.message)}
							</small>
						)}
					</div>
				);
			default:
				return null;
		}
	};

	return <div className="border-b pb-4">{renderFieldOptions()}</div>;
};

export default IntakeFieldOption;
