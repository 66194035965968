import React, { useState } from "react";
import EveryOtherTimeOscarIntegration from "./EveryOtherTime/EveryOtherTimeOscarIntegration";
import FirstTimeOscarIntegration from "./FirstTime/FirstTimeOscarIntegration";

const OscarIntegration: React.FC = () => {
	const [firstTime, setFirstTime] = useState(true);

	if (firstTime)
		return <FirstTimeOscarIntegration setFirstTime={setFirstTime} />;
	if (!firstTime) return <EveryOtherTimeOscarIntegration />;
};

export default OscarIntegration;
