import { Button } from "@src/components/ui/button";
import useAppointmentStore from "@src/store/useAppointmentStore";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useFormContext } from "../../helpers/AppointmentFormContext";
import { CreateAppointment } from "@src/store/slices/schedule/scheduleSlice";
import Loader from "@src/components/Loader/Loader";
import useUserStore from "@src/store/useUserStore";
import useCustomToast from "@src/components/CustomToast";
import {
	createScheduleCustomFieldsSchema,
	ScheduleCustomIntakesFormat,
} from "../../helpers/functions";
import ScheduleCustomIntakeField from "../ScheduleCustomIntakeField";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

interface AppointmentCustomFieldsStepProps {
	step: number;
	setStep: Dispatch<SetStateAction<number>>;
}
const AppointmentCustomFieldsStep = ({
	step,
	setStep,
}: AppointmentCustomFieldsStepProps) => {
	const currentLocationId = useUserStore((s) => s.currentLocationId);
	const toast = useCustomToast();
	const [customFieldsSchema, setCustomFieldsSchema] = useState(
		createScheduleCustomFieldsSchema([])
	);
	const [submissionType, setSubmissionType] = useState<"submit" | "skip">();
	const { formData } = useFormContext();

	const locationsDetailsForAppointment = useAppointmentStore(
		(s) => s.appointmentLocationDetails
	);
	const customFields = locationsDetailsForAppointment?.stations?.find(
		(station) => station.id == formData.stationId
	)?.custom_intakes;
	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm<z.infer<typeof customFieldsSchema>>({
		resolver: zodResolver(customFieldsSchema),
	});

	const submitWithoutCustomFields = () => {
		const data: any = {
			full_name: formData.patientDetails.name,
			phone_number: formData.patientDetails.phone,
			email: formData.patientDetails.email,
			date: formData.date
				? new Date(formData.date).toISOString().split("T")[0]
				: null,
			start_time: formData.startTime.slice(0, -3) || null,
		};
		createAppointment(data);
	};

	const onSubmit: SubmitHandler<z.infer<typeof customFieldsSchema>> = (
		data
	) => {
		const formattedCustomIntakes = ScheduleCustomIntakesFormat(
			data,
			customFields,
			customFieldsSchema
		);
		const submissionData: any = {
			full_name: formData.patientDetails.name,
			phone_number: formData.patientDetails.phone,
			email: formData.patientDetails.email,
			date: formData.date
				? new Date(formData.date).toISOString().split("T")[0]
				: null,
			start_time: formData.startTime.slice(0, -3) || null,
			custom_intakes: formattedCustomIntakes,
		};
		createAppointment(submissionData);
	};
	const onSuccess = () => {
		setStep(step + 1);
	};
	const onError = (error) => {
		toast(error?.response?.data?.message, {
			id: "skip-error",
			type: "error",
		});
	};
	const { mutate: createAppointment, isLoading } = CreateAppointment(
		formData.stationId,
		currentLocationId,
		onSuccess,
		onError
	);

	useEffect(() => {
		if (locationsDetailsForAppointment === null) {
			return;
		}
		if (customFields) {
			const newSchema = createScheduleCustomFieldsSchema(customFields);
			setCustomFieldsSchema(newSchema);
		}
	}, [locationsDetailsForAppointment, customFields]);
	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className="flex h-full w-full min-w-[489px] max-w-[700px] flex-col gap-y-2 overflow-y-hidden py-2"
		>
			<div>
				<h5 className="text-xl font-[600] ">Patient Intake Form</h5>
				<p className="text-[#6D748D]">
					Add details of the customer to complete scheduling an
					appointment.
				</p>
			</div>

			<div className="flex h-[530px] flex-col gap-y-3 overflow-y-auto pt-4">
				{customFields?.map((field, idx) => (
					<ScheduleCustomIntakeField
						key={idx}
						intake={field}
						register={register}
						errors={errors}
						control={control}
					/>
				))}
			</div>

			<div className="mt-auto flex w-full gap-x-2 ">
				<Button
					variant={"outline"}
					className="w-full"
					type="button"
					disabled={isLoading}
					onClick={() => {
						setSubmissionType("skip");
						submitWithoutCustomFields();
					}}
				>
					{submissionType === "skip" && isLoading ? (
						<Loader size={18} />
					) : (
						"Skip"
					)}
				</Button>
				<Button
					variant={"default"}
					className="w-full text-white"
					type="submit"
					disabled={isLoading}
					onClick={() => {
						setSubmissionType("submit");
					}}
				>
					{submissionType === "submit" && isLoading ? (
						<Loader size={18} />
					) : (
						"Next"
					)}
				</Button>
			</div>
		</form>
	);
};

export default AppointmentCustomFieldsStep;
