import useCustomToast from "@src/components/CustomToast";
import {
	APIVersion1ChangePassword,
	APIVersion1UpdateAccountSettings,
} from "@src/http/v1";
import {
	ChangePasswordType,
	UserAccountSettingType,
} from "@src/types/settings/account";
import { useMutation } from "react-query";

export const UpdateAccountSettings = (
	onSuccess: (data: unknown) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const customToast = useCustomToast();
	// const queryClient = useQueryClient();

	return useMutation<unknown, Error, UserAccountSettingType>(
		APIVersion1UpdateAccountSettings,
		{
			onSuccess: (data) => {
				onSuccess(data);
				setTimeout(() => {
					customToast("Account updated successfully 🎉", {
						id: "oscar-emr-url",
						type: "success",
					});
				}, 750);
			},
			onError: (error) => {
				onError(error);
				customToast("Account could not be updated 🤕", {
					id: "oscar-emr-url",
					type: "error",
				});

				// console.error(error);
			},
		}
	);
};

export const AccountChangePassword = (
	onSuccess: (data: unknown) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const customToast = useCustomToast();
	// const queryClient = useQueryClient();

	return useMutation<unknown, Error, ChangePasswordType>(
		APIVersion1ChangePassword,
		{
			onSuccess: (data) => {
				onSuccess(data);
				setTimeout(() => {
					customToast("Password updated successfully 🎉", {
						id: "oscar-emr-url",
						type: "success",
					});
				}, 750);
			},
			onError: (error) => {
				onError(error);
				customToast("Password could not be updated 🤕", {
					id: "oscar-emr-url",
					type: "error",
				});

				// console.error(error);
			},
		}
	);
};
