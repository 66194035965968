import {
	useMutation,
	UseMutationResult,
	useQuery,
	useQueryClient,
} from "react-query";
import toast from "react-hot-toast";
import {
	APIVersion1AddCustomTimeSlot,
	APIVersion1AddRemoveCustomSlote,
	APIVersion1GetCustomTimeSlot,
	APIVersion1UpdateCustomTimeSlot,
} from "@src/http/v1";
import { AxiosError } from "axios";
import {
	AddBusinessLocationResponseType,
	queueSettingsProps,
	AppointmentTypeProps,
	addRemoveCUstomSlotProps,
} from "@src/types/Location";
import useCustomToast from "@src/components/CustomToast";
import {
	displayErrorsWithTimeout,
	isValidQueryParams,
} from "@src/utils/functions";
import { ErrorResponse } from "@src/types";

export const AddCustomTimeSlotSlice = (
	onSuccess: (data: { data: queueSettingsProps }) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	return useMutation<
		any,
		Error,
		{
			data: AppointmentTypeProps;
			queryParams?: {
				station_id?: number | string;
				location_id?: number | string;
			};
		}
	>(
		({ data, queryParams }) =>
			APIVersion1AddCustomTimeSlot({ data, queryParams }),
		{
			onSuccess: (data) => {
				onSuccess(data);

				toast.success(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p>
								{data.message ||
									"Appointment type added successfully"}
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() =>
								toast.dismiss("add-appointment-settings")
							}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "add-appointment-settings",
						duration: 10000,
					}
				);
				setTimeout(() => {
					onSuccess(data);
				}, 500);
			},
			onError: (error) => {
				onError(error);
				toast.error(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p className="text-lg font-medium text-[#E33B32]">
								{error?.message || "Update failed"}
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() =>
								toast.dismiss("add-appointment-settings")
							}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "add-appointment-settings",
						duration: 10000,
					}
				);
				console.error(error);
			},
		}
	);
};

export const UpdateCustomTimeSlotSlice = (
	onSuccess: (data: { data: queueSettingsProps }) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	return useMutation<
		any,
		Error,
		{
			data: AppointmentTypeProps;
			customFieldId: number;
		}
	>(
		({ data, customFieldId }) =>
			APIVersion1UpdateCustomTimeSlot({ data, customFieldId }),
		{
			onSuccess: (data) => {
				onSuccess(data);

				toast.success(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p>
								{data.message ||
									"Appointment type added successfully"}
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() =>
								toast.dismiss("add-appointment-settings")
							}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "add-appointment-settings",
						duration: 10000,
					}
				);
				setTimeout(() => {
					onSuccess(data);
				}, 500);
			},
			onError: (error) => {
				onError(error);
				toast.error(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p className="text-lg font-medium text-[#E33B32]">
								{error?.message || "Update failed"}
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() =>
								toast.dismiss("add-appointment-settings")
							}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "add-appointment-settings",
						duration: 10000,
					}
				);
				console.error(error);
			},
		}
	);
};

export const AddRemoveCustomSlotSlice = (
	onSuccess?: (data: AddBusinessLocationResponseType) => void,
	onError?: (error: AxiosError) => void
): UseMutationResult<
	any,
	AxiosError,
	{ id: number; data: addRemoveCUstomSlotProps }
> => {
	const queryClient = useQueryClient();
	const customToast = useCustomToast();

	return useMutation<
		any,
		AxiosError<ErrorResponse>,
		{ id: number; data: addRemoveCUstomSlotProps }
	>(({ id, data }) => APIVersion1AddRemoveCustomSlote(id, data), {
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries([
				"addRemoveCustomSlot",
				variables.id,
			]);
			onSuccess?.(data);
			toast.success(
				<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
					<div className="flex items-center space-x-5">
						<p>{data?.message || "Slot removed successfully"}</p>
					</div>
					<button
						className="h-fit p-2.5"
						onClick={() => toast.dismiss("add-remove-custom-slot")}
					>
						<i className="mgc_close_line" />
					</button>
				</div>,
				{
					id: "add-remove-custom-slot",
					duration: 5000,
				}
			);
		},
		onError: (error: AxiosError<ErrorResponse>) => {
			if (error.response?.data) {
				displayErrorsWithTimeout(error.response.data, customToast, {
					toastIdPrefix: "add-remove-custom-slots",
				});
			} else {
				displayErrorsWithTimeout(
					"An unexpected error occurred. Please try again later",
					customToast,
					{
						toastIdPrefix: "add-remove-custom-slots",
					}
				);
			}
			onError?.(error);
		},
	});
};

export const GetCustomTimeSlotSlice = (queryParams: {
	station_id?: number | string;
	location_id?: number | string;
}) => {
	const queryClient = useQueryClient();
	// const queueSettings = useQueueStore((s) => s.queueSettings);
	// const setQueueSettings = useQueueStore((s) => s.setQueueSettings);
	const queryKey = ["get-queue-settingss", queryParams];
	const query = useQuery<{ status: boolean; data: any }, Error>(
		queryKey,
		() => APIVersion1GetCustomTimeSlot(queryParams),
		{
			enabled: isValidQueryParams(queryParams),
			onSuccess: () => {
				// setQueueSettings(response.data);
			},
			onError: (error) => {
				toast.error(error.message);
			},
		}
	);

	const invalidateQuery = () => {
		queryClient.invalidateQueries(queryKey);
	};

	return { ...query, invalidateQuery };
};
