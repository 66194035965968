import { TeamMember } from "@/src/types/TeamMember";
import { Button } from "@components/ui/button";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@components/ui/tooltip";
import Checkbox from "@src/components/ui-extended/checkbox";
import { useNavigate } from "react-router";

interface TeamMemberCardProps {
	onSelect: () => void;
	onDelete: () => void;
	checked: boolean;
	item: TeamMember;
}
const TeamMemberCard = ({
	onSelect,
	onDelete,
	item,
	checked,
}: TeamMemberCardProps) => {
	const navigate = useNavigate();

	return (
		<div className="flex items-center justify-between rounded-[12px] bg-white px-4 py-3 shadow-[0px_0px_5px_0px_rgba(0,0,0,0.25)]">
			<div className="flex flex-[0.25] items-center space-x-5">
				<Checkbox
					id={item.id.toString()}
					isChecked={checked}
					className="rounded-sm border-2 border-[#D1D1D1]"
					handleCheckboxChange={onSelect}
				/>
				<p className="text-[18px] leading-[20px] tracking-[-0.1px] text-[#323539]">
					{item.name}
				</p>
			</div>

			<div className="flex flex-[0.55] items-center justify-between gap-x-4">
				{item.email && (
					<div className="hidden flex-1 items-center space-x-2 lg:flex">
						<i className="mgc_mail_line text-[18px] before:!text-[#858C95]" />
						<small className="text-[12px] leading-[20px] tracking-[-0.1px] text-[#323539]">
							{item.email}
						</small>
					</div>
				)}
				{item.phone_number && (
					<div className="flex flex-1 items-center space-x-2">
						<i className="mgc_phone_line text-[18px] before:!text-[#858C95]" />
						<small className="text-[12px] leading-[20px] tracking-[-0.1px] text-[#323539]">
							{!!item.phone_number.slice(0, -10) &&
								"(" + item.phone_number.slice(0, -10) + ") "}

							{item.phone_number.slice(-10)}
						</small>
					</div>
				)}

				<div className="flex flex-1 items-center space-x-2">
					<i className="mgc_user_security_line text-[18px] before:!text-[#858C95]" />
					<small className="text-[12px] capitalize leading-[20px] tracking-[-0.1px] text-[#323539]">
						{item.role.toLowerCase().replace("_", " ")}
					</small>
				</div>
			</div>
			<div className="hidden justify-end gap-1.5 lg:flex">
				<Tooltip delayDuration={0}>
					<TooltipTrigger asChild>
						<Button
							variant="outline-primary"
							className="size-fit px-2 py-2"
							onClick={() =>
								navigate("edit/" + item.id, {
									state: {
										team_member: item,
									},
								})
							}
						>
							<i className="mgc_edit_2_line text-[12px] before:!text-primary" />
						</Button>
					</TooltipTrigger>
					<TooltipContent side="top" sideOffset={10}>
						Edit
					</TooltipContent>
				</Tooltip>
				<Tooltip delayDuration={0}>
					<TooltipTrigger asChild>
						<Button
							variant="outline-primary"
							className="size-fit px-2 py-2"
							onClick={onDelete}
						>
							<i className="mgc_delete_2_line text-[12px] before:!text-primary" />
						</Button>
					</TooltipTrigger>
					<TooltipContent side="top" sideOffset={10}>
						Delete
					</TooltipContent>
				</Tooltip>
				{/* <DeleteTeamMember teamMember={item} /> */}
			</div>
		</div>
	);
};

export default TeamMemberCard;
