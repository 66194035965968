import { AxiosError, AxiosResponse } from "axios";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import { Button } from "../components/ui/button";
import APIClient from "../services/api-client";
import { Station } from "../types/Station";
import { getCookie } from "../utils/cookies";
import { getLocationsData } from "./api/business";

export const useDeletePatient = (
	patientId: string | number,
	onSuccess: (data: Station) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const apiClient = new APIClient<Station, object>(
		"/my-queue/cancel/" + patientId
	);

	return useMutation<Station, Error, object>(
		() =>
			apiClient.post(
				{},
				{
					headers: {
						Authorization: "Bearer " + getCookie("ac-token"),
					},
				}
			),
		{
			onSuccess: (data) => {
				toast.success(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p>Patient deleted successfully</p>
							<Button className="h-[34px] w-[117px] bg-[#138576] hover:bg-[#138576]">
								Undo
							</Button>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() => toast.dismiss("delete-patient")}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "delete-patient",
						duration: 10000,
					}
				);
				setTimeout(() => {
					onSuccess(data);
				}, 500);
			},
			onError: (error) => {
				onError(error);
				toast.error(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p className="text-lg font-medium text-[#E33B32]">
								Patient could not be deleted
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() => toast.dismiss("delete-patient")}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "delete-patient",
						duration: 10000,
					}
				);
				console.error(error);
			},
		}
	);
};

const stationOrderApiClient = (patientId: string | number) =>
	new APIClient<Station, { station_id?: string; order?: string }>(
		"/my-queue/change-order-or-station/" + patientId
	);

export const useChangePatientStation = (
	patientId: string | number,
	onSuccess: (data: Station) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	return useMutation<Station, Error, { station_id?: string; order?: string }>(
		(data) =>
			stationOrderApiClient(patientId).post(data, {
				headers: {
					Authorization: "Bearer " + getCookie("ac-token"),
				},
			}),
		{
			onSuccess: (data) => {
				toast.success(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p>Patient station changed successfully</p>
							<Button className="h-[34px] w-[117px] bg-[#138576] hover:bg-[#138576]">
								Undo
							</Button>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() =>
								toast.dismiss("change-patient-station")
							}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "change-patient-station",
						duration: 10000,
					}
				);
				setTimeout(() => {
					onSuccess(data);
				}, 500);
			},
			onError: (error) => {
				onError(error);
				toast.error(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p className="text-lg font-medium text-[#E33B32]">
								Patient&apos;s station could not be changed
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() =>
								toast.dismiss("change-patient-station")
							}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "change-patient-station",
						duration: 10000,
					}
				);
				console.error(error);
			},
		}
	);
};

export const useChangePatientOrder = (
	patientId: string | number,
	onSuccess: (data: Station) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	return useMutation<Station, Error, { station_id?: string; order?: string }>(
		(data) =>
			stationOrderApiClient(patientId).post(data, {
				headers: {
					Authorization: "Bearer " + getCookie("ac-token"),
				},
			}),
		{
			onSuccess: (data) => {
				toast.success(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p className="text-lg font-medium text-[#6D748D]">
								Patient&apos;s order changed successfully
							</p>
							<Button className="h-[34px] w-[117px] bg-[#138576] hover:bg-[#138576]">
								Undo
							</Button>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() =>
								toast.dismiss("change-patient-order")
							}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "change-patient-order",
						duration: 10000,
					}
				);
				setTimeout(() => {
					onSuccess(data);
				}, 500);
			},
			onError: (error) => {
				onError(error);
				toast.error(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p className="text-lg font-medium text-[#E33B32]">
								Patient&apos;s order could not be changed
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() =>
								toast.dismiss("change-patient-order")
							}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "change-patient-order",
						duration: 10000,
					}
				);
				console.error(error);
			},
		}
	);
};

export const usePatientSwapRequestConfirmation = (
	swapId: string | number,
	onSuccess: (data: Station) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const apiClient = new APIClient<
		Station,
		{ order?: string; accept?: boolean }
	>("/my-queue/approve-swap-request/" + swapId);

	return useMutation<Station, Error, { order?: string; accept?: boolean }>(
		(data) => {
			if (data.accept === true)
				return apiClient.post(data, {
					headers: {
						Authorization: "Bearer " + getCookie("ac-token"),
					},
				});
			else
				return apiClient.delete({
					headers: {
						Authorization: "Bearer " + getCookie("ac-token"),
					},
				});
		},
		{
			onSuccess: (data) => {
				toast.success(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p className="text-lg font-medium text-[#6D748D]">
								Patient&apos;s swap request changed successfully
							</p>
							<Button className="h-[34px] w-[117px] bg-[#138576] hover:bg-[#138576]">
								Undo
							</Button>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() => toast.dismiss("change-patient-swap")}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "change-patient-swap",
						duration: 10000,
					}
				);
				setTimeout(() => {
					onSuccess(data);
				}, 500);
			},
			onError: (error) => {
				onError(error);
				toast.error(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p className="text-lg font-medium text-[#E33B32]">
								Patient&apos;s swap request could not be changed
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() => toast.dismiss("change-patient-swap")}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "change-patient-swap",
						duration: 10000,
					}
				);
				console.error(error);
			},
		}
	);
};

export const usePatientApprove = (
	patientId: string | number,
	onSuccess: (data: Station) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const apiClient = new APIClient<Station, object>(
		"/my-queue/approve/" + patientId
	);

	return useMutation<Station, Error, object>(
		() =>
			apiClient.post(
				{},
				{
					headers: {
						Authorization: "Bearer " + getCookie("ac-token"),
					},
				}
			),
		{
			onSuccess: (data) => {
				onSuccess(data);
			},
			onError: (error) => {
				onError(error);
				toast.error(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p className="text-lg font-medium text-[#E33B32]">
								Patient could not be approved
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() =>
								toast.dismiss("move-patient-approved")
							}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "move-patient-approved",
						duration: 10000,
					}
				);
				console.error(error);
			},
		}
	);
};

export const usePatientSendToServing = (
	patientId: string | number,
	onSuccess: (data: Station) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const apiClient = new APIClient<Station, object>(
		"/my-queue/set-customer-as-getting-service/" + patientId
	);

	return useMutation<Station, Error, object>(
		() =>
			apiClient.post(
				{},
				{
					headers: {
						Authorization: "Bearer " + getCookie("ac-token"),
					},
				}
			),
		{
			onSuccess,
			onError: (error) => {
				onError(error);
				toast.error(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p className="text-lg font-medium text-[#E33B32]">
								Patient could not be moved to serving
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() =>
								toast.dismiss("move-patient-serving")
							}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "move-patient-serving",
						duration: 10000,
					}
				);
				console.error(error);
			},
		}
	);
};

export const useNotifyPatient = (
	patientId: string | number,
	onSuccess: (data: Station) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const apiClient = new APIClient<Station, object>(
		"/my-queue/notify-waitlist-customer/" + patientId
	);

	return useMutation<Station, Error, object>(
		() =>
			apiClient.post(
				{},
				{
					headers: {
						Authorization: "Bearer " + getCookie("ac-token"),
					},
				}
			),
		{
			onSuccess,
			onError: (error) => {
				onError(error);
				toast.error(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p className="text-lg font-medium text-[#E33B32]">
								Patient could not be notified
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() => toast.dismiss("notify-patient")}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "notify-patient",
						duration: 10000,
					}
				);
				console.error(error);
			},
		}
	);
};

// export const useGetQueueOrder = (
// 	// onSuccess: (data: BusinessQueueOrderLocation[]) => void = () => {
// 	// 	return;
// 	// },
// 	onError: (error: Error) => void = () => {
// 		return;
// 	}
// ) => {
// 	const apiClient = new APIClient<
// 		{ status: boolean; data: { locations: BusinessQueueOrderLocation[] } },
// 		object
// 	>("/my-queue/informations/queue-order/");

// 	const currentLocationId = useUserStore((s) => s.currentLocationId);
// 	const currentStationId = useUserStore((s) => s.currentStationId);
// 	const setPatients = useWaitListStore((s) => s.setPatients);
// 	const setPending = useWaitListStore((s) => s.setPending);
// 	const setTotalPending = useWaitListStore((s) => s.setTotalPending);
// 	const setUpcoming = useWaitListStore((s) => s.setUpcoming);
// 	const setTotalUpcoming = useWaitListStore((s) => s.setTotalUpcoming);
// 	const setNextInLine = useWaitListStore((s) => s.setNextInLine);
// 	const setTotalNextInLine = useWaitListStore((s) => s.setTotalNextInLine);
// 	const setServing = useWaitListStore((s) => s.setServing);
// 	const setTotalServing = useWaitListStore((s) => s.setTotalServing);
// 	const setSchedule = useWaitListStore((s) => s.setSchedule);
// 	const setTotalSchedule = useWaitListStore((s) => s.setTotalSchedule);
// 	const setLocationsList = useUserStore((s) => s.setLocationsList);
// 	const setStationsList = useUserStore((s) => s.setStationsList);

// 	return useQuery<
// 		{ status: boolean; data: { locations: BusinessQueueOrderLocation[] } },
// 		Error
// 	>(
// 		"queue-order",
// 		() =>
// 			apiClient.get({
// 				headers: {
// 					Authorization: "Bearer " + getCookie("ac-token"),
// 				},
// 			}),
// 		{
// 			onSuccess: (data) => {
// 				setTotalSchedule(
// 					data.data.locations.flatMap((location) =>
// 						location.stations.flatMap(
// 							(station) =>
// 								station.queue_informations
// 									?.customers_waiting_to_be_approved
// 									.appointmets
// 						)
// 					) as AppointmentWaitingToBeApproved[]
// 				);

// 				setTotalPending(
// 					data.data.locations.flatMap((location) =>
// 						location.stations.flatMap(
// 							(station) =>
// 								station.queue_informations
// 									?.customers_waiting_to_be_approved.waitlist
// 						)
// 					) as CustomerNextToBeServed[]
// 				);

// 				setTotalUpcoming(
// 					(
// 						data.data.locations.flatMap((location) =>
// 							location.stations.flatMap((station) =>
// 								station?.queue_informations?.upcoming.waitlist.filter(
// 									(waitlistItem) =>
// 										!station.queue_informations
// 											?.customer_next_to_be_served
// 											?.email ||
// 										!station.queue_informations.customer_next_to_be_served.email.includes(
// 											waitlistItem.email ?? ""
// 										)
// 								)
// 							)
// 						) as CustomerNextToBeServed[]
// 					).sort((a, b) => {
// 						const aDate = new Date(
// 							a.waitlist!.approved_at!
// 						).getTime();
// 						const bDate = new Date(
// 							b.waitlist!.approved_at!
// 						).getTime();
// 						return aDate - bDate;
// 					})
// 				);

// 				setTotalNextInLine(
// 					(
// 						data.data.locations.flatMap((location) =>
// 							location.stations.flatMap((station) =>
// 								station?.queue_informations
// 									?.customer_next_to_be_served !== null
// 									? station?.queue_informations
// 											?.customer_next_to_be_served
// 									: []
// 							)
// 						) as CustomerNextToBeServed[]
// 					).sort((a, b) => {
// 						const aDate = new Date(
// 							a.waitlist!.approved_at!
// 						).getTime();
// 						const bDate = new Date(
// 							b.waitlist!.approved_at!
// 						).getTime();

// 						return aDate - bDate;
// 					})
// 				);

// 				setTotalServing(
// 					data.data.locations.flatMap((location) =>
// 						location.stations.flatMap(
// 							(station) =>
// 								station?.queue_informations
// 									?.customers_getting_service
// 						)
// 					) as CustomersGettingService[]
// 				);

// 				setSchedule(
// 					data.data.locations.flatMap((location) =>
// 						location.id === currentLocationId
// 							? location.stations.flatMap((station) =>
// 									currentStationId === "All Stations" ||
// 									station.id === currentStationId
// 										? station.queue_informations
// 												?.customers_waiting_to_be_approved
// 												.appointmets
// 										: []
// 								)
// 							: []
// 					) as AppointmentWaitingToBeApproved[]
// 				);

// 				setPending(
// 					data.data.locations.flatMap((location) =>
// 						location.id === currentLocationId
// 							? location.stations.flatMap((station) =>
// 									currentStationId === "All Stations" ||
// 									station.id === currentStationId
// 										? station.queue_informations
// 												?.customers_waiting_to_be_approved
// 												?.waitlist || []
// 										: []
// 								)
// 							: []
// 					) as CustomerNextToBeServed[]
// 				);

// 				setUpcoming(
// 					(
// 						data.data.locations.flatMap((location) =>
// 							location.id === currentLocationId
// 								? location.stations.flatMap((station) =>
// 										currentStationId === "All Stations" ||
// 										station.id === currentStationId
// 											? station.queue_informations?.upcoming.waitlist.filter(
// 													(waitlistItem) =>
// 														!station
// 															.queue_informations
// 															?.customer_next_to_be_served
// 															?.email ||
// 														!station.queue_informations.customer_next_to_be_served.email.includes(
// 															waitlistItem.email ??
// 																""
// 														)
// 												)
// 											: []
// 									)
// 								: []
// 						) as CustomerNextToBeServed[]
// 					).sort((a, b) => {
// 						const aDate = new Date(
// 							a.waitlist!.approved_at!
// 						).getTime();
// 						const bDate = new Date(
// 							b.waitlist!.approved_at!
// 						).getTime();
// 						return aDate - bDate;
// 					})
// 				);

// 				setNextInLine(
// 					(
// 						data?.data?.locations.flatMap((location) =>
// 							location.id === currentLocationId
// 								? location.stations.flatMap((station) =>
// 										currentStationId === "All Stations" ||
// 										station.id === currentStationId
// 											? station?.queue_informations
// 													?.customer_next_to_be_served ||
// 												[]
// 											: []
// 									)
// 								: []
// 						) as CustomerNextToBeServed[]
// 					).sort((a, b) => {
// 						const aDate = new Date(
// 							a.waitlist!.approved_at!
// 						).getTime();
// 						const bDate = new Date(
// 							b.waitlist!.approved_at!
// 						).getTime();

// 						return aDate - bDate;
// 					})
// 				);

// 				setServing(
// 					data.data.locations.flatMap((location) =>
// 						location.id === currentLocationId
// 							? location.stations.flatMap((station) =>
// 									currentStationId === "All Stations" ||
// 									station.id === currentStationId
// 										? station?.queue_informations
// 												?.customers_getting_service ||
// 											[]
// 										: []
// 								)
// 							: []
// 					) as CustomersGettingService[]
// 				);

// 				setPatients(
// 					data.data.locations.flatMap((location) =>
// 						location.id === currentLocationId
// 							? location.stations.flatMap((station) =>
// 									currentStationId === "All Stations" ||
// 									station.id === currentStationId
// 										? station.queue_informations
// 												?.queue_order || []
// 										: []
// 								)
// 							: []
// 					) as CustomerNextToBeServed[]
// 				);

// 				// setPending(
// 				// 	data.data.locations.flatMap((location) =>
// 				// 		location.stations.flatMap(
// 				// 			(station) =>
// 				// 				station.queue_informations
// 				// 					?.customers_waiting_to_be_approved
// 				// 					.waitlist
// 				// 		)
// 				// 	)
// 				// );

// 				// setSchedule(
// 				// 	data.data.locations.flatMap((location) =>
// 				// 		location.stations.flatMap(
// 				// 			(station) =>
// 				// 				station.queue_informations
// 				// 					?.customers_waiting_to_be_approved
// 				// 					.appointmets
// 				// 		)
// 				// 	)
// 				// );

// 				// setUpcoming(
// 				// 	data.data.locations
// 				// 		.flatMap((location) =>
// 				// 			location.stations.flatMap((station) =>
// 				// 				station?.queue_informations?.upcoming.waitlist.filter(
// 				// 					(waitlistItem) =>
// 				// 						!station.queue_informations
// 				// 							?.customer_next_to_be_served
// 				// 							?.email ||
// 				// 						!station.queue_informations.customer_next_to_be_served.email.includes(
// 				// 							waitlistItem.email ?? ""
// 				// 						)
// 				// 				)
// 				// 			)
// 				// 		)
// 				// 		.sort((a: any, b: any) => {
// 				// 			const aDate = new Date(
// 				// 				a.waitlist!.approved_at!
// 				// 			).getTime();
// 				// 			const bDate = new Date(
// 				// 				b.waitlist!.approved_at!
// 				// 			).getTime();
// 				// 			return aDate - bDate;
// 				// 		}) as CustomerNextToBeServed[]
// 				// );

// 				// setNextInLine(
// 				// 	(
// 				// 		data.data.locations.flatMap((location) =>
// 				// 			location.stations.flatMap(
// 				// 				(station) =>
// 				// 					station?.queue_informations
// 				// 						?.customer_next_to_be_served ?? []
// 				// 			)
// 				// 		) as CustomerNextToBeServed[]
// 				// 	).sort((a, b) => {
// 				// 		const aDate = new Date(
// 				// 			a.waitlist!.approved_at!
// 				// 		).getTime();
// 				// 		const bDate = new Date(
// 				// 			b.waitlist!.approved_at!
// 				// 		).getTime();

// 				// 		return aDate - bDate;
// 				// 	})
// 				// );

// 				// setServing(
// 				// 	data.data.locations.flatMap((location) =>
// 				// 		location.stations.flatMap(
// 				// 			(station) =>
// 				// 				station?.queue_informations
// 				// 					?.customers_getting_service
// 				// 		)
// 				// 	)
// 				// );

// 				// setPatients(
// 				// 	data.data.locations.flatMap((location) =>
// 				// 		location.stations.flatMap(
// 				// 			(station) =>
// 				// 				station.queue_informations?.queue_order
// 				// 		)
// 				// 	)
// 				// );

// 				setLocationsList(
// 					data.data.locations.length
// 						? data.data.locations?.map((location) => ({
// 								label: location.name,
// 								value: location.id.toString(),
// 							})) || []
// 						: []
// 				);

// 				setStationsList(
// 					data.data.locations.length
// 						? [
// 								{
// 									label: "All Stations",
// 									value: "All Stations",
// 								},
// 								...(data.data.locations.flatMap((location) =>
// 									location.stations.map((station) => ({
// 										label: station.name,
// 										value: station.id.toString(),
// 									}))
// 								) || []),
// 							]
// 						: []
// 				);
// 			},
// 			onError: (error) => {
// 				onError(error);
// 				toast.error(
// 					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
// 						<div className="flex items-center space-x-5">
// 							<p className="text-lg font-medium text-[#E33B32]">
// 								Error fetching queue order
// 							</p>
// 						</div>
// 						<button
// 							className="h-fit p-2.5"
// 							onClick={() => toast.dismiss("queue-order")}
// 						>
// 							<i className="mgc_close_line" />
// 						</button>
// 					</div>,
// 					{
// 						id: "queue-order",
// 						duration: 10000,
// 					}
// 				);
// 				console.error(error);
// 			},
// 		}
// 	);
// };

///// Locations //////
export const useGetLocationsData = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void = () => {
		return;
	}
) => {
	return useQuery("locationsData", getLocationsData, {
		onSuccess,
		onError,
	});
};
