import OperatingHours from "@src/pages/locations/components/OperatingHours";
import { GetLocationDetailsSlice } from "@src/store/slices/locations/locationSlice";
import useStationStore from "@src/store/useStationStore";

interface OpreatingTimeProps {
	tabView?: "schedule" | "waitlist" | undefined;
}

const OpreatingTime: React.FC<OpreatingTimeProps> = ({ tabView }) => {
	const stationData = useStationStore((s) => s.stationDetails);

	const { data, error, isLoading } = GetLocationDetailsSlice(
		stationData?.location_id || 0
	);

	return (
		<div className="h-full space-y-8">
			<OperatingHours
				stationLoading={false}
				stationData={data?.data?.stations}
				title="Add Operating Hours"
				updateMode="station"
				showTab={false}
				tabView={tabView}
			/>
		</div>
	);
};

export default OpreatingTime;
