import React, { useState } from "react";

type Props = {
	navMapping: any;
};

const CustomTab = ({ navMapping }: Props) => {
	const keysArray = Object.keys(navMapping);
	const [tab, setTab] = useState(keysArray[0]);
	const renderTab = (tab: any) => {
		return navMapping[tab];
	};

	return (
		<div>
			<div className="flex justify-evenly border-b font-medium text-gray-400">
				{keysArray.map((item, index) => (
					<p
						key={index}
						onClick={() => setTab(item)}
						className={`cursor-pointer py-3 ${tab === item ? "border-b-2 border-primary font-semibold text-primary" : ""}`}
					>
						{item}
					</p>
				))}
			</div>

			{renderTab(tab)}
		</div>
	);
};

export default CustomTab;
