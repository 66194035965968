import { Slider } from "@/src/components/ui/slider";
import { QueueSettingsSlice } from "@/src/store/slices/locations/locationSlice";
import { queueSettingsProps } from "@/src/types/Location";
import { Input } from "@src/components/ui/input";
import { Label } from "@src/components/ui/label";
import { ScrollArea } from "@src/components/ui/scroll-area";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "@src/components/ui/select";
import { Switch } from "@src/components/ui/switch";
import React from "react";
import { UseFormReturn } from "react-hook-form";

const GeneralSettings: React.FC<{
	formMethods: UseFormReturn<queueSettingsProps>;
	autoClearingMinuteFormat: "hours" | "minutes";
	setAutoClearingMinuteFormat: React.Dispatch<
		React.SetStateAction<"hours" | "minutes">
	>;
}> = ({
	formMethods,
	autoClearingMinuteFormat,
	setAutoClearingMinuteFormat,
}) => {
	// const initialFormState: WaitlistGeneralSettings = {
	// 	waitlist_setting.is_queue_active: user?.business.is_active as 1 | 0,
	// 	waitlist_setting.use_average_time",: user?.business.waitlist_setting.use_average_time", as 1 | 0,
	// 	waitlist_setting.manual_wait_time: formatTimeToHHMMSS(
	// 		user?.business?.average_waiting_time ?? "30"
	// 	),
	// };

	const {
		// handleSubmit,
		setValue,
		getValues,
		watch,
		// reset,
		// formState: { errors },
	} = formMethods;

	// const [autoClearingMinuteFormat, setAutoClearingMinuteFormat] =
	// 	React.useState<"minutes" | "hours">("minutes");

	const [isUsingHistoricalAvg, setIsUsingHistoricalAvg] = React.useState(
		Boolean(formMethods.getValues("waitlist_setting.use_average_time"))
	);
	const businessSettingsMutation = QueueSettingsSlice();

	return (
		<div className="flex flex-1 flex-col self-stretch">
			<div className="pl-12 pr-6">
				<h2 className="text-[22px] font-semibold leading-[30px] tracking-[-1%] text-[#323539]">
					General Settings
				</h2>
			</div>
			<ScrollArea className="mt-6 flex-1 pl-12 pr-6">
				<div className="flex flex-col space-y-3 px-3">
					<div className="border-b-1 flex justify-between border-b border-b-[#E5E5E7] pb-4 pt-1">
						<div>
							<h3 className="text-[15px] font-medium leading-[22px] text-[#323539]">
								Join Waitlist
							</h3>
							<p className="traking-[-0.1px] text-[14px] leading-[20px] text-[#858C95]">
								Allow patients to join the waitlist
							</p>
						</div>
						<div className="flex items-center">
							<Switch
								checked={
									!!watch("waitlist_setting.is_queue_active")
								}
								id="queue-on-off"
								className="scale-75 data-[state=checked]:bg-[#32D74B] data-[state=unchecked]:bg-[#E5E5E7]"
								onClick={() =>
									setValue(
										"waitlist_setting.is_queue_active",
										getValues(
											"waitlist_setting.is_queue_active"
										) === 1
											? 0
											: 1
									)
								}
								disabled={businessSettingsMutation.isLoading}
							/>
							<Label
								htmlFor="queue-on-off"
								className="font-medium leading-[24px] tracking-[-0.16px] text-[#323539]"
							>
								{watch("waitlist_setting.is_queue_active")
									? "On"
									: "Off"}
							</Label>
						</div>
					</div>
					<div className="border-b-1 flex flex-col space-y-2.5 border-b border-b-[#E5E5E7] pb-4 pt-1">
						<div>
							<h3 className="text-[15px] font-medium leading-[22px] text-[#323539]">
								Wait Times
							</h3>
							<p className="traking-[-0.1px] text-[14px] leading-[20px] text-[#858C95]">
								This determines how our system will calculate
								the wait time.
							</p>
						</div>
						<div className="flex w-full items-center justify-between gap-x-6">
							<p className="traking-[-0.1px] max-w-[480px] text-[14px] leading-[20px] text-[#858C95]">
								Historical Avg. calculates the total average and
								Custom allows you to enter a number.
							</p>
							<div className="flex flex-1 items-center justify-end gap-3">
								<Select
									value={
										isUsingHistoricalAvg
											? "historical-avg"
											: "manual"
									}
									onValueChange={(value) => {
										// setCountryCode(value);
										if (value === "historical-avg") {
											setValue(
												"waitlist_setting.use_average_time",
												1
											);
											setValue(
												"waitlist_setting.manual_wait_time",
												undefined
											);
											setIsUsingHistoricalAvg(true);
										} else if (value === "manual") {
											setValue(
												"waitlist_setting.use_average_time",
												0
											);
											setValue(
												"waitlist_setting.manual_wait_time",
												formMethods.getValues(
													"waitlist_setting.manual_wait_time"
												)
											);
											setIsUsingHistoricalAvg(false);
										}
									}}
								>
									<SelectTrigger className="h-9 max-w-fit py-0">
										<SelectValue
											placeholder={"Select Priority"}
											className="text-[#858C95]"
										/>
									</SelectTrigger>
									<SelectContent className="!z-[9999]">
										<SelectGroup>
											<SelectLabel className="px-2">
												Select Historical Average
											</SelectLabel>

											{waitTimes.map((option) => {
												return (
													<SelectItem
														key={option.value}
														value={option.value}
														className="px-8"
													>
														{option.label}
													</SelectItem>
												);
											})}
										</SelectGroup>
									</SelectContent>
								</Select>
								{!isUsingHistoricalAvg && (
									<div className="flex h-9 items-center rounded-md border border-input py-1.5 focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2">
										<Input
											// defaultValue={formatHHMMSSToMinutes(
											// 	watch(
											// 		"waitlist_setting.manual_wait_time"
											// 	)
											// )}
											className="remove-number-input-arrow max-h-full w-[30px] min-w-[48px] max-w-fit border-none text-[14px] font-medium leading-[20px] focus-within:ring-0 focus:outline-none"
											{...formMethods.register(
												"waitlist_setting.manual_wait_time",
												{
													valueAsNumber: true,
												}
											)}
											// onChange={(e) => {
											// 	if (
											// 		parseInt(e.target.value) ===
											// 			0 ||
											// 		!e.target.value.length
											// 	)
											// 		return;
											// 	// setValue(
											// 	// 	"waitlist_setting.manual_wait_time",
											// 	// 	formatTimeToHHMMSS(
											// 	// 		e.target.value
											// 	// 	)
											// 	// );
											// }}
											min={1}
											type="number"
										/>
										<p className="border-l border-[#E5E5E7] px-1.5 text-[14px] font-medium leading-[20px] text-[#323539]">
											minutes
										</p>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="flex flex-col space-y-1 pb-4 pt-1">
						<div className="flex justify-between">
							<div>
								<h3 className="text-[15px] font-medium leading-[22px] text-[#323539]">
									Auto Clear Queue
								</h3>
								<p className="traking-[-0.1px] max-w-[412px] text-[14px] leading-[20px] text-[#858C95]">
									The time after which the queue will be
									automatically cleared after business hours.
								</p>
							</div>
							<div className="flex items-center">
								<Switch
									checked={
										!!watch(
											"waitlist_setting.auto_clearing"
										)
									}
									id="clear-queue-on-off"
									className="scale-75 data-[state=checked]:bg-[#32D74B] data-[state=unchecked]:bg-[#E5E5E7]"
									onClick={() =>
										setValue(
											"waitlist_setting.auto_clearing",
											getValues(
												"waitlist_setting.auto_clearing"
											) === 1
												? 0
												: 1
										)
									}
								/>
								<Label
									htmlFor="clear-queue-on-off"
									className="font-medium leading-[24px] tracking-[-0.16px] text-[#323539]"
								>
									{watch("waitlist_setting.auto_clearing")
										? "On"
										: "Off"}
								</Label>
							</div>
						</div>
						<div className="flex items-center justify-between space-x-4">
							<Slider
								value={[
									watch(
										"waitlist_setting.auto_clearing_minute"
									) || 0,
								]}
								onValueChange={(value) => {
									setValue(
										"waitlist_setting.auto_clearing_minute",
										value[0]
									);
								}}
								max={
									autoClearingMinuteFormat === "minutes"
										? 120
										: 2
								}
								step={
									autoClearingMinuteFormat === "minutes"
										? 5
										: 0.2
								}
								thumbClassName="bg-primary"
							/>

							<div className="flex h-9 items-center space-x-1 rounded-md border border-input px-3 py-1.5 focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2">
								<Input
									{...formMethods.register(
										"waitlist_setting.auto_clearing_minute",
										{ valueAsNumber: true }
									)}
									className="remove-number-input-arrow max-h-full min-w-[48px] max-w-[80px] flex-1 rounded-none border-r border-[#E5E5E7] border-y-transparent border-l-transparent text-[14px] font-medium leading-[20px] focus-within:ring-0 focus:outline-none"
									// min={1}
									type="number"
								/>
								{/* <p className="border-l border-[#E5E5E7] px-1.5 text-[14px] font-medium leading-[20px] text-[#323539]">
									minutes
								</p> */}

								<Select
									value={autoClearingMinuteFormat}
									onValueChange={(
										value: "hours" | "minutes"
									) => {
										setAutoClearingMinuteFormat(
											(prevValue) => {
												// Get the current waitlist setting value (in minutes or hours)
												const currentValue =
													watch(
														"waitlist_setting.auto_clearing_minute"
													) ?? 0;

												if (
													value === "hours" &&
													prevValue === "minutes"
												) {
													setValue(
														"waitlist_setting.auto_clearing_minute",
														parseFloat(
															(
																currentValue /
																60
															).toFixed(2)
														)
													);
												} else if (
													value === "minutes" &&
													prevValue === "hours"
												) {
													setValue(
														"waitlist_setting.auto_clearing_minute",
														Math.round(
															currentValue * 60
														) // Convert back to minutes
													);
												}

												return value;
											}
										);
									}}
								>
									<SelectTrigger className="h-fit max-w-fit space-x-1.5 border-none px-0 py-1 shadow-none focus-within:ring-0 focus:outline-none">
										<SelectValue
											placeholder="Hours"
											className="text-[#858C95]"
										/>
									</SelectTrigger>
									<SelectContent className="!z-[9999]">
										<SelectGroup>
											{[
												{
													label: "Minutes",
													value: "minutes",
												},
												{
													label: "Hours",
													value: "hours",
												},
											].map((option) => {
												return (
													<SelectItem
														key={option.value}
														value={option.value}
														className="px-8"
													>
														{option.label}
													</SelectItem>
												);
											})}
										</SelectGroup>
									</SelectContent>
								</Select>
							</div>
						</div>
					</div>
				</div>
			</ScrollArea>
		</div>
	);
};

export default GeneralSettings;

const waitTimes = [
	{
		label: "Historical Avg.",
		value: "historical-avg",
	},
	{
		label: "Manual",
		value: "manual",
	},
];
