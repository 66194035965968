import { z } from "zod";

export const UpdateBusinessSettingsSchema = z.object({
	name: z.string().min(1, "Name is required"),
	email: z.string().email("Invalid email address"),
	phone_number: z.string().optional(),
	address: z.string().min(10, "Address is required"),
	country: z.string().min(2, "Country is required"),
	state: z.string(),
	city: z.string(),
	["zip_code"]: z.string().max(11).optional(),
	business_category_id: z.string().max(11).optional(),
	logo: z.instanceof(File).optional(),
});

export type UpdateBusinessSettingsType = z.infer<
	typeof UpdateBusinessSettingsSchema
>;
