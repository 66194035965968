import { Input } from "@src/components/ui/input";
import { Label } from "@src/components/ui/label";
import Header from "@src/layouts/Header/Header";
import React from "react";
import { LuChevronLeft } from "react-icons/lu";
import { Link } from "react-router-dom";
import { Option } from "@components/form/CustomSelect";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "@src/components/ui/select";
import { countryCodes, teamMemberRoleOptions } from "@src/utils/constants";
import TeamMemberRoleSelector from "@src/components/Dashboard/team-members/TeamMemberRoleSelector";
import { TeamMemberRole, TeamMemberRoleType } from "@src/types/team-member";
import { Button } from "@src/components/ui/button";

const AddTeamMember: React.FC = () => {
	const [countryCode, setCountryCode] = React.useState("+1");
	const [selectedLocations, setSelectedLocations] = React.useState<Option[]>(
		[]
	);
	const [selectedRole, setSelectedRole] = React.useState<TeamMemberRoleType>(
		TeamMemberRole.BusinessAdmin
	);

	return (
		<main className="flex flex-1 flex-col">
			<Header title="Manage Members" />
			<section className="flex flex-1 flex-col">
				<div className="flex space-x-3.5">
					<Link to="/dashboard/theme">
						<LuChevronLeft color="#858C95" size={24} />
					</Link>
					<h2 className="text-[20px] font-semibold leading-[24px] text-[#323539]">
						Add Member
					</h2>
				</div>
				<form className="ml-10 mt-10 max-w-[700px] flex-1 self-stretch">
					<div className="flex flex-col space-y-5 ">
						<h2 className="leading-[19.2px] text-[#323539]">
							Member Information
						</h2>
						<div className="grid grid-cols-2 gap-x-1.5 gap-y-2">
							<div className="space-y-1.5">
								<Input
									className="h-9"
									placeholder="First Name"
								/>
							</div>
							<div className="space-y-1.5">
								<Input
									className="h-9"
									placeholder="Last Name"
								/>
							</div>
							<div className="space-y-1.5">
								<Input className="h-9" placeholder="Email" />
								{/* {errors.email &&
									errors?.email?.message?.length && (
										<small className="mt-1.5 text-sm leading-[16px] text-red-500">
											{errors.email.message}
										</small>
									)} */}
							</div>
							<div className="flex h-9 items-stretch">
								<Select
									value={countryCode}
									onValueChange={(value) => {
										setCountryCode(value);
									}}
								>
									<SelectTrigger className="h-full w-fit rounded-r-none border-r-transparent shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]">
										<SelectValue
											className="text-[#858C95]"
											placeholder="+1"
										/>
									</SelectTrigger>
									<SelectContent className="!z-[9999]">
										<SelectGroup>
											<SelectLabel className="px-2">
												Country Codes
											</SelectLabel>

											{countryCodes.map((option) => {
												return (
													<SelectItem
														key={option.value}
														value={option.value}
														className="px-8"
													>
														{option.label}
													</SelectItem>
												);
											})}
										</SelectGroup>
									</SelectContent>
								</Select>
								<Input
									className="h-full rounded-l-none border border-[#E4E4E7] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
									minLength={11}
									maxLength={11}
									placeholder="Phone Number"
								/>
							</div>
						</div>
					</div>
					<div className="mt-7 space-y-5">
						<h2 className="leading-[19.2px]">Select Role</h2>
						<div className="flex flex-col space-y-2.5">
							{teamMemberRoleOptions.map((item, index) => {
								return (
									<TeamMemberRoleSelector
										key={index}
										selectedLocations={selectedLocations}
										setSelectedLocations={
											setSelectedLocations
										}
										{...item}
										onClick={() => {
											setSelectedRole(item.value);
										}}
										currentSelectedRole={selectedRole}
										isActive={selectedRole === item.value}
									/>
								);
							})}
						</div>
						{/* <TeamMemberRole /> */}
					</div>
					<div className="my-7 flex items-center justify-end space-x-2.5">
						<Button
							className="max-w-[170px] flex-1 px-4 py-2 font-semibold leading-[28px] tracking-[-1%]"
							variant="outline-primary"
						>
							Cancel
						</Button>
						<Button className="max-w-[170px] flex-1 px-4 py-2 font-semibold leading-[28px] tracking-[-1%] text-white">
							Add Member
						</Button>
					</div>
				</form>
			</section>
		</main>
	);
};

export default AddTeamMember;
