import {
	APIVersion1AddBusinessLocation,
	APIVersion1AddBusinessLocationStation,
	APIVersion1AddBusinessWorkingHours,
	APIVersion1DeleteLocation,
	APIVersion1DeleteLocations,
	APIVersion1DeleteStation,
	APIVersion1GetBusinessLocationStations,
	APIVersion1GetLinkedCalendar,
	APIVersion1GetLocations,
	APIVersion1GetQueueSettings,
	APIVersion1GetSingleBusinessLocationDetail,
	APIVersion1GetSingleBusinessStationDetail,
	APIVersion1UpdateBusinessLocation,
	APIVersion1UpdateBusinessLocationStation,
	APIVersion1UpdateLinkedCalendar,
	// APIVersion1GetBusinessTeamMembers,
	APIVersion1UpdateQueueSettings,
} from "@src/http/v1";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import {
	useMutation,
	UseMutationResult,
	useQuery,
	useQueryClient,
} from "react-query";

import { APIVersion2GetBusinessLocations } from "@/src/http/v2";
import useCustomToast from "@src/components/CustomToast";
import useLocationsStore from "@src/store/useLocationsStore";
import useStationStore from "@src/store/useStationStore";
import { ErrorResponse } from "@src/types";
import {
	AddBusinessLocationResponseType,
	GetBusinessLocationsQueueResponse,
	GetLocationsResponse,
	queueSettingsProps,
} from "@src/types/Location";
import { AddStationType, EditStationType } from "@src/types/Station";
import {
	displayErrorsWithTimeout,
	isValidQueryParams,
} from "@src/utils/functions";

// export const GetLocationsSlice = (
// 	onError: (error: Error) => void = () => {}
// ) => {
// 	const locations = useLocationsStore((s) => s.locations);
// 	const setLocationsData = useLocationsStore((s) => s.setLocations);

// 	return useQuery<{ status: boolean; data: AddLocationData }, Error>({
// 		queryKey: ["get-locations", locations],
// 		queryFn: APIVersion1GetLocations,
// 		onSuccess: (response) => {
// 			if (Array.isArray(response.data)) {
// 				setLocationsData(response.data);
// 			} else {
// 				console.error("Unexpected data structure");
// 			}
// 		},
// 		onError,
// 	} as UseQueryOptions<{ status: boolean; data: AddLocationData }, Error>);
// };

export const GetLocationsSlice = (
	onError: (error: Error) => void = () => {}
) => {
	const queryClient = useQueryClient();
	const locations = useLocationsStore((s) => s.locations);
	const setLocationsData = useLocationsStore((s) => s.setLocations);

	const queryKey = ["get-locations", locations];

	const query = useQuery<GetLocationsResponse, Error>(
		queryKey,
		() => APIVersion1GetLocations(),
		{
			onSuccess: (response) => {
				setLocationsData(response.data);
			},
			onError,
		}
	);

	const invalidateQuery = () => {
		queryClient.invalidateQueries(queryKey);
	};

	return { ...query, invalidateQuery };
};

export const DeleteLocationsSlice = (
	onSuccess: (data: any) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	return useMutation<any, Error, { location_ids: number[] }>(
		({ location_ids }) => APIVersion1DeleteLocations({ location_ids }),
		{
			onSuccess: (data) => {
				toast.success(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p>
								{data?.message ||
									"Deleted location(s) successfully"}
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() => toast.dismiss("delete-locations")}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "delete-locations",
						duration: 10000,
					}
				);
				setTimeout(() => {
					onSuccess(data);
				}, 500);
			},
			onError: (error) => {
				onError(error);
				toast.error(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p className="text-lg font-medium text-[#E33B32]">
								Locations could not be deleted
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() => toast.dismiss("delete-locations")}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "delete-locations",
						duration: 10000,
					}
				);
				console.error(error);
			},
		}
	);
};

export const GetLocationDetailsSlice = (
	id: number,
	onError: (error: Error) => void = () => {}
) => {
	const queryClient = useQueryClient();
	const locationDetails = useLocationsStore((s) => s.locationDetails);
	const setLocationDetailsData = useLocationsStore(
		(s) => s.setLocationDetails
	);

	const queryKey = ["locationDetails", id, locationDetails];

	const query = useQuery<{ status: boolean; data: any }, Error>(
		queryKey,
		() => APIVersion1GetSingleBusinessLocationDetail(id),
		{
			onSuccess: (response) => {
				setLocationDetailsData(response.data);
			},
			enabled: Boolean(id),
			onError,
		}
	);

	const invalidateQuery = () => {
		queryClient.invalidateQueries(queryKey);
	};

	return { ...query, invalidateQuery };
};

// export const GetLocationStationsSlice = (
// 	id: number,
// 	onError: (error: Error) => void = () => {}
// ) => {
// 	return useQuery<{ status: boolean; data: StationsArrayProps }, Error>(
// 		["get-station", id],
// 		() => APIVersion1GetBusinessLocationStations(id),
// 		{
// 			onSuccess: () => {},
// 			onError,
// 		}
// 	);
// };

export const GetLocationStationsSlice = (
	id: number,
	onError: (error: Error) => void = () => {}
) => {
	const queryClient = useQueryClient();
	const stations = useStationStore((s) => s.stations);
	const setStations = useStationStore((s) => s.setStations);

	const queryKey = ["get-stations", id, stations];

	const query = useQuery<{ status: boolean; data: any }, Error>(
		queryKey,
		() => APIVersion1GetBusinessLocationStations(id),
		{
			onSuccess: (response) => {
				setStations(response.data);
			},
			onError,
		}
	);

	const invalidateQuery = () => {
		queryClient.invalidateQueries(queryKey);
	};

	return { ...query, invalidateQuery };
};

export const AddBusinessLocationSlice = (
	onSuccess?: (data: AddBusinessLocationResponseType) => void,
	onError?: (error: AxiosError) => void
): UseMutationResult<AddBusinessLocationResponseType, AxiosError, FormData> => {
	const queryClient = useQueryClient();
	const customToast = useCustomToast();

	return useMutation<
		AddBusinessLocationResponseType,
		AxiosError<ErrorResponse>,
		FormData
	>(APIVersion1AddBusinessLocation, {
		onSuccess: (data) => {
			queryClient.invalidateQueries("get-locations");
			onSuccess?.(data);
			toast.success(
				<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
					<div className="flex items-center space-x-5">
						<p>{data?.message || "Location added successfully"}</p>
					</div>
					<button
						className="h-fit p-2.5"
						onClick={() => toast.dismiss("add-location")}
					>
						<i className="mgc_close_line" />
					</button>
				</div>,
				{
					id: "add-location",
					duration: 5000,
				}
			);
		},
		onError: (error: AxiosError<ErrorResponse>) => {
			if (error.response?.data) {
				displayErrorsWithTimeout(error.response.data, customToast, {
					toastIdPrefix: "add-locations",
				});
			} else {
				displayErrorsWithTimeout(
					"An unexpected error occurred. Please try again later",
					customToast,
					{
						toastIdPrefix: "add-locations",
					}
				);
			}
			onError?.(error);
		},
	});
};

export const UpdateLocationOperatingHoursSlice = (
	onSuccess?: (data: any) => void,
	onError?: (error: AxiosError) => void
): UseMutationResult<any, AxiosError, any> => {
	const queryClient = useQueryClient();
	const customToast = useCustomToast();

	return useMutation<any, AxiosError<ErrorResponse>, any>(
		APIVersion1AddBusinessWorkingHours,
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries("get-locations");
				onSuccess?.(data);
				toast.success(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p>
								{data?.message ||
									"Working hours updated successfully"}
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() =>
								toast.dismiss("update-working-hours")
							}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "update-working-hours",
						duration: 5000,
					}
				);
			},
			onError: (error: AxiosError<ErrorResponse>) => {
				if (error.response?.data) {
					displayErrorsWithTimeout(error.response.data, customToast, {
						toastIdPrefix: "update-working-hourss",
					});
				} else {
					displayErrorsWithTimeout(
						"An unexpected error occurred. Please try again later",
						customToast,
						{
							toastIdPrefix: "update-working-hourss",
						}
					);
				}
				onError?.(error);
			},
		}
	);
};

export const DeleteLocationSlice = (
	locationId: string | number,
	onSuccess: (data: any) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const queryClient = useQueryClient();
	return useMutation<any, Error, object>(
		() => APIVersion1DeleteLocation(locationId),
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries("get-locations");
				toast.success(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p>Location deleted successfully</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() => toast.dismiss("delete-location")}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "delete-location",
						duration: 10000,
					}
				);
				setTimeout(() => {
					onSuccess(data);
				}, 500);
			},
			onError: (error) => {
				onError(error);
				toast.error(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p className="text-lg font-medium text-[#E33B32]">
								Location could not be deleted
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() => toast.dismiss("delete-location")}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "delete-location",
						duration: 10000,
					}
				);
				console.error(error);
			},
		}
	);
};

export const UpdateBusinessLocationSlice = (
	onSuccess?: (data: AddBusinessLocationResponseType) => void,
	onError?: (error: AxiosError) => void
): UseMutationResult<
	AddBusinessLocationResponseType,
	AxiosError,
	{ id: number; data: FormData }
> => {
	const customToast = useCustomToast();
	const queryClient = useQueryClient();

	return useMutation<
		AddBusinessLocationResponseType,
		AxiosError<ErrorResponse>,
		{ id: number; data: FormData }
	>(({ id, data }) => APIVersion1UpdateBusinessLocation(id, data), {
		onSuccess: (data) => {
			queryClient.invalidateQueries("get-locations");
			onSuccess?.(data);
			toast.success(
				<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
					<div className="flex items-center space-x-5">
						<p>
							{data?.message || "Location updated successfully"}
						</p>
					</div>
					<button
						className="h-fit p-2.5"
						onClick={() => toast.dismiss("update-location")}
					>
						<i className="mgc_close_line" />
					</button>
				</div>,
				{
					id: "update-location",
					duration: 5000,
				}
			);
		},
		onError: (error: AxiosError<ErrorResponse>) => {
			if (error.response?.data) {
				displayErrorsWithTimeout(error.response.data, customToast, {
					toastIdPrefix: "update-location",
				});
			} else {
				displayErrorsWithTimeout(
					"An unexpected error occurred. Please try again later",
					customToast,
					{
						toastIdPrefix: "update-location",
					}
				);
			}
			if (onError) onError(error);
		},
	});
};

export const UpdateBusinessLocationStationSlice = (
	onSuccess?: (data: any) => void,
	onError?: (error: AxiosError) => void
): UseMutationResult<
	any,
	AxiosError,
	{ locationId: number; stationId: number; data: EditStationType }
> => {
	const customToast = useCustomToast();
	const queryClient = useQueryClient();

	return useMutation<
		any,
		AxiosError<ErrorResponse>,
		{ locationId: number; stationId: number; data: EditStationType }
	>(
		({ locationId, stationId, data }) =>
			APIVersion1UpdateBusinessLocationStation(
				locationId,
				stationId,
				data
			),
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries("get-station");
				onSuccess?.(data);
				toast.success(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p>
								{data?.message ||
									"Location updated successfully"}
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() => toast.dismiss("update-station")}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "update-station",
						duration: 5000,
					}
				);
			},
			onError: (error: AxiosError<ErrorResponse>) => {
				if (error.response?.data) {
					displayErrorsWithTimeout(error.response.data, customToast, {
						toastIdPrefix: "update-station",
					});
				} else {
					displayErrorsWithTimeout(
						"An unexpected error occurred. Please try again later",
						customToast,
						{
							toastIdPrefix: "update-station",
						}
					);
				}
				if (onError) onError(error);
			},
		}
	);
};

export const AddBusinessLocationStationSlice = (
	onSuccess?: (data: AddBusinessLocationResponseType) => void,
	onError?: (error: AxiosError) => void
): UseMutationResult<any, AxiosError, { id: number; data: AddStationType }> => {
	const queryClient = useQueryClient();
	const customToast = useCustomToast();

	return useMutation<
		any,
		AxiosError<ErrorResponse>,
		{ id: number; data: AddStationType }
	>(({ id, data }) => APIVersion1AddBusinessLocationStation(id, data), {
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries(["locationDetails", variables.id]);
			onSuccess?.(data);
			toast.success(
				<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
					<div className="flex items-center space-x-5">
						<p>{data?.message || "Location added successfully"}</p>
					</div>
					<button
						className="h-fit p-2.5"
						onClick={() => toast.dismiss("add-location")}
					>
						<i className="mgc_close_line" />
					</button>
				</div>,
				{
					id: "add-location",
					duration: 5000,
				}
			);
		},
		onError: (error: AxiosError<ErrorResponse>) => {
			if (error.response?.data) {
				displayErrorsWithTimeout(error.response.data, customToast, {
					toastIdPrefix: "add-locations",
				});
			} else {
				displayErrorsWithTimeout(
					"An unexpected error occurred. Please try again later",
					customToast,
					{
						toastIdPrefix: "add-locations",
					}
				);
			}
			onError?.(error);
		},
	});
};

export const DeleteStationSlice = (
	locationId: number,
	stationId: number,
	onSuccess: (data: any) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const queryClient = useQueryClient();
	return useMutation<any, Error, object>(
		() => APIVersion1DeleteStation(locationId, stationId),
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries("get-station");
				toast.success(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p>Station deleted successfully</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() => toast.dismiss("delete-location")}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "delete-station",
						duration: 1000,
					}
				);
				setTimeout(() => {
					onSuccess(data);
				}, 500);
			},
			onError: (error) => {
				onError(error);
				toast.error(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p className="text-lg font-medium text-[#E33B32]">
								Station could not be deleted
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() => toast.dismiss("delete-station")}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "delete-station",
						duration: 10000,
					}
				);
				console.error(error);
			},
		}
	);
};

export const GetStationDetailsSlice = (
	id: number,
	onError: (error: Error) => void = () => {}
) => {
	const queryClient = useQueryClient();
	const stationDetails = useStationStore((s) => s.stationDetails);
	const setStationDetails = useStationStore((s) => s.setStationDetails);

	const queryKey = ["stationDetails", id, stationDetails];

	const query = useQuery<{ status: boolean; data: any }, Error>(
		queryKey,
		() => APIVersion1GetSingleBusinessStationDetail(id),
		{
			onSuccess: (response) => {
				setStationDetails(response.data);
			},
			enabled: Boolean(id),
			onError,
		}
	);

	const invalidateQuery = () => {
		queryClient.invalidateQueries(queryKey);
	};

	return { ...query, invalidateQuery };
};

//The function down here is used by schedule and waitlist --- in cases of refactor, please reach out to other devs
export const GetQueueSettingsSlice = (queryParams?: {
	station_id?: number | string;
	location_id?: number | string;
}) => {
	const queryClient = useQueryClient();
	// const queueSettings = useQueueStore((s) => s.queueSettings);
	// const setQueueSettings = useQueueStore((s) => s.setQueueSettings);
	const queryKey = ["get-queue-settingss", queryParams];
	const query = useQuery<
		{ status: boolean; data: queueSettingsProps },
		Error
	>(queryKey, () => APIVersion1GetQueueSettings(queryParams!), {
		//added the compulsory params availability
		enabled: isValidQueryParams(queryParams),
		onSuccess: () => {
			// setQueueSettings(response.data);
		},
		onError: (error) => {
			toast.error(error.message);
		},
	});

	const invalidateQuery = () => {
		queryClient.invalidateQueries(queryKey);
	};

	return { ...query, invalidateQuery };
};

//The function down here is used by schedule and waitlist --- in cases of refactor, please reach out to other devs
export const QueueSettingsSlice = (
	onSuccess: (data: { data: queueSettingsProps }) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	return useMutation<
		any,
		Error,
		{
			data: queueSettingsProps;
			queryParams?: {
				station_id?: number | string;
				location_id?: number | string;
			};
		}
	>(
		({ data, queryParams }) =>
			APIVersion1UpdateQueueSettings({ data, queryParams }),
		{
			onSuccess: (data) => {
				onSuccess(data);

				toast.success(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p>
								{data.message ||
									"Settings updated successfully"}
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() =>
								toast.dismiss("update-queue-settings")
							}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "update-queue-settings",
						duration: 10000,
					}
				);
				// setTimeout(() => {
				// 	onSuccess(data);
				// }, 500);
			},
			onError: (error) => {
				onError(error);
				toast.error(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p className="text-lg font-medium text-[#E33B32]">
								{error?.message || "Update failed"}
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() =>
								toast.dismiss("update-queue-settings")
							}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "update-queue-settings",
						duration: 10000,
					}
				);
				console.error(error);
			},
		}
	);
};

export const useGetBusinessLocationsAndStations = (
	onSuccess: (data: GetBusinessLocationsQueueResponse) => void = () => {},
	onError: (error: Error) => void = () => {}
) => {
	const getBusinessLocationsAndStationsQuery = useQuery<
		GetBusinessLocationsQueueResponse,
		Error
	>({
		queryKey: ["get-business-locations"],
		queryFn: APIVersion2GetBusinessLocations,
		onSuccess,
		onError,
	});

	return getBusinessLocationsAndStationsQuery;
};

// Start Link Calendar
export const GetLinkedCalendarSlice = (station_id: number) => {
	const queryClient = useQueryClient();
	const queryKey = ["get-queue-settingss", station_id];
	const query = useQuery<{ status: boolean; data: any }, Error>(
		queryKey,
		() => APIVersion1GetLinkedCalendar(station_id),
		{
			enabled: Boolean(station_id),
			onSuccess: () => {},
			onError: (error) => {
				toast.error(error.message);
			},
		}
	);

	const invalidateQuery = () => {
		queryClient.invalidateQueries(queryKey);
	};

	return { ...query, invalidateQuery };
};

export const UpdateLinkedCalendarSlice = (
	onSuccess: (data: { data: any }) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	return useMutation<
		any,
		Error,
		{
			data: Record<string, boolean>;
			queryParams: {
				station_id: number | string;
				account_id: number | string;
			};
		}
	>(
		({ data, queryParams }) =>
			APIVersion1UpdateLinkedCalendar({ data, queryParams }),
		{
			onSuccess: (data) => {
				onSuccess(data);

				toast.success(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p>
								{data.message ||
									"Settings updated successfully"}
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() =>
								toast.dismiss("update-linked-calendar-settings")
							}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "update-linked-calendar-settings",
						duration: 10000,
					}
				);
				// setTimeout(() => {
				// 	onSuccess(data);
				// }, 500);
			},
			onError: (error) => {
				onError(error);
				toast.error(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p className="text-lg font-medium text-[#E33B32]">
								{error?.message || "Update failed"}
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() =>
								toast.dismiss("update-linked-calendar-settings")
							}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "update-linked-calendar-settings",
						duration: 10000,
					}
				);
				console.error(error);
			},
		}
	);
};
// End Link Calendar
