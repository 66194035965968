import { Dispatch, SetStateAction } from "react";
import { Tabs, TabsList, TabsTrigger } from "@components/ui/tabs";
import { Views } from "react-big-calendar";
// import { Switch } from "@components/ui/switch";
// import { Label } from "@components/ui/label";
import { DatePicker } from "@components/ui/datepicker";
// import useScreenDimension from "@hooks/useScreenDimension";
import { Button } from "@src/components/ui/button";
import { IoMdAddCircleOutline } from "react-icons/io";
import AutoApprove from "../../AutoApprove";

interface CustomCalendarToolbarProps {
	view: string;
	setDate: (date: Date) => void;
	date: Date | undefined;
	setView: Dispatch<SetStateAction<string>>;
	setShowAppointmentModal: Dispatch<SetStateAction<boolean>>;
}
const CustomCalendarToolbar = ({
	view,
	setView,
	date,
	setDate,
	setShowAppointmentModal,
}: CustomCalendarToolbarProps) => {
	// const { width } = useScreenDimension();
	return (
		<div className="relative flex items-center justify-end gap-4">
			{/* {width > 1024 && (
				<div className="flex items-center space-x-2">
					<p>Schedule Optimizer</p>
					<Switch
						defaultChecked={isScheduleOptimizer}
						id="schedule-optimizer"
						onClick={() =>
							setIsScheduleOptimizer(!isScheduleOptimizer)
						}
						className="scale-[0.8] data-[state=checked]:bg-[#005893]  data-[state=unchecked]:bg-gray-300"
					/>
					<Label htmlFor="optimize-schedule">
						{isScheduleOptimizer ? (
							<span className="text-base font-bold text-[#2AA63C]">
								ON
							</span>
						) : (
							<span className="text-base font-bold text-[#C1C1C1]">
								OFF
							</span>
						)}
					</Label>
				</div>
			)} */}
			<AutoApprove />

			<Tabs defaultValue={view}>
				<TabsList>
					<TabsTrigger
						className="data-[state=active]:border-primary data-[state=active]:text-primary"
						onClick={() => setView(Views.DAY)}
						value="day"
					>
						Day View
					</TabsTrigger>
					<TabsTrigger
						className="data-[state=active]:border-primary data-[state=active]:text-primary"
						onClick={() => setView(Views.WEEK)}
						value="week"
					>
						Week View
					</TabsTrigger>
					<TabsTrigger
						className="data-[state=active]:border-primary data-[state=active]:text-primary"
						onClick={() => setView(Views.MONTH)}
						value="month"
					>
						Month View
					</TabsTrigger>
				</TabsList>
			</Tabs>

			<div>
				<DatePicker date={date} className="w-fit" setDate={setDate} />
				{/* <DatePickerWithRange
					className="w-fit"
					title="Select Start - End Date"
				/> */}
			</div>
			<Button
				className="flex h-[36px] items-center gap-x-2 text-sm font-[600] text-white"
				variant="default"
				onClick={() => setShowAppointmentModal(true)}
			>
				<span className="pr-1 text-lg">
					<IoMdAddCircleOutline color={"#fff"} />
				</span>
				<p>Add Appointment</p>
			</Button>
		</div>
	);
};

export default CustomCalendarToolbar;
