import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Button } from "@src/components/ui/button";
import { useForm, FormProvider } from "react-hook-form";

import { StationsArrayProps, AddLocationDataV2 } from "@src/types/Location";
import ScheduleForm from "./ScheduleForm";
import {
	defaultTimeSlots,
	defaultTimeSlotsV2,
	transformSchedule,
} from "@src/utils/constants";
import ApplyOperatingHoursModal from "@src/pages/locations/components/OperatingHours/ApplyOperatingHoursModal";
import { GetWorkingHoursSlice } from "@src/store/slices/working-hours/workingHoursSlice";
import RequestIsLoading from "@src/components/RequestIsLoading";
import Loader from "@src/components/Loader/Loader";

interface StationsIProps {
	stationLoading?: boolean;
	stationData: StationsArrayProps | undefined;
	title?: string;
	tabView?: "schedule" | "waitlist" | undefined;
	updateMode?: "station" | "location";
	showTab?: boolean;
}

const OperatingHours: React.FC<StationsIProps> = ({
	stationData,
	title,
	tabView,
	updateMode,
	showTab = true,
}) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const locationId = searchParams.get("locationId") || "";
	const stationId = searchParams.get("stationId") || "";

	const [slots, setSlots] = useState<any[]>(defaultTimeSlots);
	const [slotsV2, setSlotsV2] = useState<any>(defaultTimeSlotsV2);

	const {
		data: workingHoursData,
		isLoading: workingHoursLoading,
		isSuccess,
	} = GetWorkingHoursSlice({
		[locationId ? "location_id" : "station_id"]:
			parseInt(locationId || stationId) || 0,
	});

	const methods = useForm<AddLocationDataV2>({
		defaultValues: {
			child_update_strategy: "ignore", // "specific|ignore|default|non_default|override",
			child_ids: [],
			waitlist_schedule_option: tabView || "waitlist", // "all|schedule|waitlist"
			data: slotsV2,
			slots: slots,
			members: [],
			is_waitlist: tabView === "waitlist" ? true : tabView ? false : true,
			is_schedule:
				tabView === "schedule" ? true : tabView ? false : false,
			tabView: tabView || "waitlist",
			is_station_default_hour: false,
			is_station_override_hour: false,
			manual_wait_time: 0,
			schedule_block_in_minute: 0,
		},
	});

	const { watch, reset } = methods;

	const waitlist_tab = watch("tabView");

	useEffect(() => {
		if (!workingHoursLoading && isSuccess) {
			if (waitlist_tab === "waitlist") {
				setSlots(
					transformSchedule(
						workingHoursData?.data?.default_working_hours || []
					)
				);
				reset({
					child_update_strategy: "ignore", // "specific|ignore|default|non_default|override",
					child_ids: [],
					waitlist_schedule_option: tabView || "waitlist", // "all|schedule|waitlist"
					data: slotsV2,
					slots: transformSchedule(
						workingHoursData?.data?.default_working_hours || []
					),
					members: [],
					is_waitlist:
						tabView === "waitlist" ? true : tabView ? false : true,
					is_schedule:
						tabView === "schedule" ? true : tabView ? false : false,
					tabView: tabView || "waitlist",
					is_station_default_hour: false,
					is_station_override_hour: false,
					manual_wait_time: 0,
				});
			}
			if (waitlist_tab === "schedule") {
				reset({
					child_update_strategy: "ignore", // "specific|ignore|default|non_default|override",
					child_ids: [],
					waitlist_schedule_option: tabView || "waitlist", // "all|schedule|waitlist"
					data: slotsV2,
					slots: transformSchedule(
						workingHoursData?.data?.default_working_hours ||
							defaultTimeSlots
					),
					members: [],
					is_waitlist: false,
					is_schedule: true,
					is_station_default_hour: false,
					is_station_override_hour: false,
					schedule_block_in_minute: 0,
				});
				setSlots(
					transformSchedule(
						workingHoursData?.data?.default_working_hours ||
							defaultTimeSlots
					)
				);
			}
		}
	}, [
		waitlist_tab,
		workingHoursData?.data?.default_working_hours,
		workingHoursData?.data?.schedule_working_hours,
		isSuccess,
		workingHoursLoading,
		tabView,
		reset,
	]);

	const handleCancel = () => {
		navigate("/dashboard/locations");
	};

	return (
		<div className="h-full">
			{workingHoursLoading ? (
				<div className="flex h-full w-full items-center justify-center">
					<Loader size={40} />
				</div>
			) : (
				<FormProvider {...methods}>
					<form className={`${showTab ? "mt-7" : ""}`}>
						<ScheduleForm
							title={title}
							showTab={showTab}
							activeTab={tabView}
						/>
						<div className="mx-auto flex w-full max-w-6xl justify-end gap-2 py-4">
							<Button
								type="reset"
								onClick={handleCancel}
								variant={"ghost"}
							>
								Cancel
							</Button>
							<ApplyOperatingHoursModal
								station={stationData}
								id={parseInt(locationId || stationId)}
								updateMode={updateMode}
							/>
						</div>
					</form>
				</FormProvider>
			)}
		</div>
	);
};

export default OperatingHours;
