import React, { useRef, useCallback, useEffect, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import {
	$getSelection,
	$isRangeSelection,
	CAN_REDO_COMMAND,
	CAN_UNDO_COMMAND,
	FORMAT_TEXT_COMMAND,
	FORMAT_ELEMENT_COMMAND,
	REDO_COMMAND,
	UNDO_COMMAND,
	SELECTION_CHANGE_COMMAND,
} from "lexical";
import {
	FaBold,
	FaItalic,
	FaUnderline,
	FaStrikethrough,
	FaAlignLeft,
	FaAlignCenter,
	FaAlignRight,
	FaAlignJustify,
	FaUndo,
	FaRedo,
} from "react-icons/fa";

const LowPriority = 1;

const ToolbarPlugin: React.FC = () => {
	const toolbarRef = useRef(null);
	const [editor] = useLexicalComposerContext();
	const [canUndo, setCanUndo] = useState(false);
	const [canRedo, setCanRedo] = useState(false);
	const [isBold, setIsBold] = useState(false);
	const [isItalic, setIsItalic] = useState(false);
	const [isUnderline, setIsUnderline] = useState(false);
	const [isStrikethrough, setIsStrikethrough] = useState(false);

	const updateToolbar = useCallback(() => {
		const selection = $getSelection();
		if ($isRangeSelection(selection)) {
			setIsBold(selection.hasFormat("bold"));
			setIsItalic(selection.hasFormat("italic"));
			setIsUnderline(selection.hasFormat("underline"));
			setIsStrikethrough(selection.hasFormat("strikethrough"));
		}
	}, []);

	useEffect(() => {
		return mergeRegister(
			editor.registerUpdateListener(({ editorState }) => {
				editorState.read(() => {
					updateToolbar();
				});
			}),
			editor.registerCommand(
				SELECTION_CHANGE_COMMAND,
				() => {
					updateToolbar();
					return false;
				},
				LowPriority
			),
			editor.registerCommand(
				CAN_UNDO_COMMAND,
				(payload) => {
					setCanUndo(payload);
					return false;
				},
				LowPriority
			),
			editor.registerCommand(
				CAN_REDO_COMMAND,
				(payload) => {
					setCanRedo(payload);
					return false;
				},
				LowPriority
			)
		);
	}, [editor, updateToolbar]);

	const ToolbarButton: React.FC<{
		icon: React.ReactNode;
		active?: boolean;
		onClick: () => void;
		disabled?: boolean;
	}> = ({ icon, active, onClick, disabled = false }) => (
		<button
			onClick={onClick}
			disabled={disabled}
			className={`rounded p-2 ${active ? "bg-gray-200" : ""} ${disabled ? "cursor-not-allowed opacity-50" : ""}`}
		>
			{icon}
		</button>
	);

	return (
		<div
			className="flex items-center space-x-2 border-b border-gray-200 p-2"
			ref={toolbarRef}
		>
			<ToolbarButton
				icon={<FaUndo />}
				onClick={() => editor.dispatchCommand(UNDO_COMMAND, undefined)}
				disabled={!canUndo}
			/>
			<ToolbarButton
				icon={<FaRedo />}
				onClick={() => editor.dispatchCommand(REDO_COMMAND, undefined)}
				disabled={!canRedo}
			/>
			<div className="mx-2 h-6 w-px bg-gray-300" />
			<ToolbarButton
				icon={<FaBold />}
				active={isBold}
				onClick={() =>
					editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold")
				}
			/>
			<ToolbarButton
				icon={<FaItalic />}
				active={isItalic}
				onClick={() =>
					editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic")
				}
			/>
			<ToolbarButton
				icon={<FaUnderline />}
				active={isUnderline}
				onClick={() =>
					editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline")
				}
			/>
			<ToolbarButton
				icon={<FaStrikethrough />}
				active={isStrikethrough}
				onClick={() =>
					editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough")
				}
			/>
			<div className="mx-2 h-6 w-px bg-gray-300" />
			<ToolbarButton
				icon={<FaAlignLeft />}
				onClick={() =>
					editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left")
				}
			/>
			<ToolbarButton
				icon={<FaAlignCenter />}
				onClick={() =>
					editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center")
				}
			/>
			<ToolbarButton
				icon={<FaAlignRight />}
				onClick={() =>
					editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right")
				}
			/>
			<ToolbarButton
				icon={<FaAlignJustify />}
				onClick={() =>
					editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify")
				}
			/>
		</div>
	);
};

export default ToolbarPlugin;
