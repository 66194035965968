import React, { useState, forwardRef } from "react";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { cn } from "../../utils/functions";
import Select, { GroupBase, StylesConfig } from "react-select";

interface FormInputProps {
	label?: string;
	labelStyles?: string;
	name?: string;
	id?: string;
	className?: string;
	placeholder?: string;
	pattern?: string;
	sideLabel?: string;
	select?: boolean;
	options?: Option[];
	selectStyleOptions?:
		| StylesConfig<unknown, boolean, GroupBase<unknown>>
		| { [index: string]: boolean | string | number };
	error?: string | undefined | null;
	value?: string | number;
	minLength?: number;
	maxLength?: number;
	required?: boolean;
	inputType?: string;
	inputWithSide?: boolean;
	textarea?: boolean;
	isMulti?: boolean;
	disabled?: boolean;
	password?: boolean;
	inputMode?: InputMode;
	onChange?: (e: React.ChangeEvent<HTMLInputElement> | any) => void;
	register?: any;
	defaultValue?: number | string;
}

const FormInput = forwardRef<HTMLInputElement, FormInputProps>(
	(
		{
			label,
			placeholder,
			disabled,
			onChange,
			inputMode,
			className,
			textarea,
			password,
			required,
			inputType,
			inputWithSide,
			register,
			value,
			name,
			selectStyleOptions,
			isMulti,
			id,
			options,
			select,
			minLength,
			maxLength,
			error,
			sideLabel,
			defaultValue,
			labelStyles,
		},
		ref
	) => {
		const [visible, setVisible] = useState<boolean>(false);
		return (
			<div className="w-full flex-1">
				{label && (
					<label
						className={cn(
							`bt-1.5 mb-1.5 block text-sm font-medium tracking-[-0.1px] text-[#323539]`,
							labelStyles
						)}
					>
						{label}{" "}
						{required && <span className="text-red-600">*</span>}
					</label>
				)}

				{inputType && (
					<input
						ref={ref}
						{...register}
						{...(onChange && { onChange })}
						className={cn(
							`${"w-full rounded-md border border-[#E5E5E7] bg-white px-3 py-2 text-[#323539] placeholder:text-[14px] placeholder:text-[#323539]/50 focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2"} ${(error || error === "") && "border-red-500"} h-fit`,
							className
						)}
						type={inputType ? inputType : "text"}
						autoComplete="on"
						placeholder={placeholder}
						inputMode={inputMode ? inputMode : "text"}
						minLength={minLength}
						maxLength={maxLength}
					/>
				)}

				{password && (
					<i className="absolute right-4 top-1/2 flex -translate-y-1/2 items-center text-[#002060]">
						<button
							type="button"
							onClick={() => setVisible(!visible)}
							aria-label={
								visible ? "Hide password" : "Show password"
							}
						>
							<span className="sr-only">
								{visible ? "Hide password" : "Show password"}
							</span>
							{visible ? <BsEyeSlashFill /> : <BsEyeFill />}
						</button>
					</i>
				)}

				{textarea && (
					<textarea
						{...register}
						className={cn(
							`${"w-full rounded-md border-[0.5px] border-[#E5E5E7] bg-white px-4 py-2 text-[#323539] outline-none placeholder:text-[#323539]/50"} ${(error || error === "") && "border-red-500"} h-fit`,
							className
						)}
						disabled={disabled}
						placeholder={placeholder}
						minLength={minLength}
						maxLength={maxLength}
					/>
				)}

				{inputWithSide && (
					<>
						<div
							className={`flex w-full flex-1 items-center justify-between space-x-2 rounded-md border border-[#E5E5E7] bg-white px-3 py-2 text-[#323539] focus:outline-[transparent] ${className ? className : ""}  ${(error || error === "") && "border-red-500"}`}
						>
							<input
								ref={ref}
								className={cn(
									`h-[22px] w-full border-none px-0 text-[15px] font-medium leading-[22px] text-[#323539] placeholder:text-[15px] placeholder:text-[#323539]/40`,
									className
								)}
								placeholder={placeholder}
								defaultValue={defaultValue}
								inputMode={inputMode ? inputMode : "text"}
								type={inputType ? inputType : "text"}
								minLength={minLength}
								maxLength={maxLength}
								{...register}
								{...(onChange && { onChange })}
							/>
							<div className="flex min-w-[62px] items-center space-x-2">
								<div className="h-[18px] w-[1px] rounded-full bg-[#B7B7B7]" />
								<p className="rounded-full text-[15px] leading-[22px] text-[#323539]">
									{sideLabel}
								</p>
							</div>
						</div>
					</>
				)}
				{select && (
					<div className="block w-full">
						<Select
							name={name}
							id={id}
							options={options}
							placeholder={placeholder}
							className={className}
							styles={
								customSelectStyles(selectStyleOptions) ||
								undefined
							}
							value={options?.find(
								(option) => option.value === value
							)}
							isMulti={isMulti}
							onChange={onChange}
							isDisabled={disabled}
						/>
					</div>
				)}
				{error && error.length && (
					<small className="mt-1.5 text-sm text-red-500">
						{error}
					</small>
				)}
			</div>
		);
	}
);

FormInput.displayName = "FormInput";

export default FormInput;

export const customSelectStyles = (selectStyleOptions?: any) => {
	return {
		control: (defaultStyles: any) => ({
			...defaultStyles,
			...(selectStyleOptions && selectStyleOptions.control
				? selectStyleOptions.control()
				: defaultStyles),
			"&:active": { borderColor: "inherit" },
			"&:focus": { borderColor: "#043a6c" },
			"&:focus-within": { borderColor: "#043a6c" },
			"&:hover": "transparent",
			"border-color": "#e5e5e7",
		}),
		menu: (provided: any) => ({
			...provided,
			...(selectStyleOptions && selectStyleOptions.menu
				? selectStyleOptions.menu()
				: provided),
			backgroundColor: "#fff",
			zIndex: 9999,
			color: "#323539",
		}),
		placeholder: (base: any) => ({
			...base,
			...(selectStyleOptions && selectStyleOptions.placeholder
				? selectStyleOptions.placeholder()
				: base),
			fontWeight: 400,
			fontSize: "14px",
			color: "#858C95",
		}),
		valueContainer: (base: any) => ({
			...base,
			...(selectStyleOptions && selectStyleOptions.valueContainer
				? selectStyleOptions.valueContainer()
				: base),
		}),
		indicatorsContainer: (base: any) => ({
			...base,
			...(selectStyleOptions && selectStyleOptions.indicatorsContainer
				? selectStyleOptions.indicatorsContainer()
				: base),
		}),
		indicatorSeparator: (base: any) => ({
			...base,
			...(selectStyleOptions && selectStyleOptions.indicatorSeparator
				? selectStyleOptions.indicatorSeparator()
				: base),
		}),
		input: (provided: any) => ({
			...provided,
			caretColor: "transparent",
			border: "none",
			fontSize: "15px",
			"&:focus": {
				outline: "transparent",
			},
		}),
	};
};

export enum InputMode {
	Url = "url",
	Text = "text",
	Search = "search",
	Numeric = "numeric",
	None = "none",
	Tel = "tel",
	Email = "email",
	Decimal = "decimal",
}

export interface Option {
	value: string | number;
	label: string;
}
