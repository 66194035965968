import CustomCheckbox2 from "@src/components/form/CustomCheckbox2";
import { Button } from "@src/components/ui/button";
import { Card } from "@src/components/ui/card";
import React, { useState } from "react";
import { IoMdStopwatch } from "react-icons/io";
import ScheduleSettings from "../components/ScheduleSettings";
import WaitlistSettings from "../components/waitlist-settings";
import { TbListDetails } from "react-icons/tb";
import { PiMapPinAreaBold } from "react-icons/pi";
import { RiCalendarScheduleLine } from "react-icons/ri";
import Header from "@src/layouts/Header/Header";
import { useSearchParams } from "react-router-dom";
import { GetStationDetailsSlice } from "@src/store/slices/locations/locationSlice";
import {
	Line,
	LineChart,
	ResponsiveContainer,
	XAxis,
	YAxis,
	ReferenceLine,
} from "recharts";
import { convertTimeFormat, formatDate } from "@src/utils/functions";
import { StationInfoModal } from "../components";
interface StationDetailsProps {}

const StationDetails: React.FC<StationDetailsProps> = () => {
	const [searchParams] = useSearchParams();
	const [showStationInfoModal, setShowStationInfoModal] = useState(false);
	const [activeInfoModalScreen, setActiveInfoModalScreen] =
		useState("station");
	const stationId = searchParams.get("stationId") || "";

	const {
		data: stationData,
		error,
		isLoading: stationLoading,
	} = GetStationDetailsSlice(Number(parseInt(stationId)));
	// console.log("🚀 ~ stationData:", stationData);

	const totalCustomers = stationData?.data?.statics?.hourly_statics?.reduce(
		(acc: number, curr: any) => acc + curr.number_of_customers,
		0
	);
	const averageCustomers =
		totalCustomers / stationData?.data?.statics?.hourly_statics?.length;

	return (
		<main className="flex flex-1 flex-col">
			<Header
				title={`${stationData?.data?.location.name || "Location"} < ${stationData?.data?.name || "Station"}`}
				showDate={false}
				canGoBack
			/>
			<div className="flex flex-1 flex-col overflow-auto">
				<div className="flex-1 space-y-4">
					<div className=" grid grid-cols-6 gap-6">
						<Card className="boxShadow-custom2 col-span-4 space-y-8 p-4">
							<div className="flex items-center justify-between">
								<div>
									<h3 className="mb-1 text-xl font-medium">
										{stationData?.data?.name || "N/A"}
									</h3>
									<p className="text-sm text-gray-400">
										Last activity:{" "}
										{formatDate(
											stationData?.data?.updated_at || ""
										)}
									</p>
								</div>
								<Button variant={"secondary"} size="icon-sm">
									<i className="mgc_edit_2_line text-[16px] text-primary"></i>
								</Button>
							</div>
							<div className="flex justify-between">
								<div>
									<h3 className="mb-1 text-xl font-medium">
										Station Visits (Past 24 hours)
									</h3>
									<p className="text-sm text-gray-400">
										Total Visits: {totalCustomers || 0}
									</p>
								</div>
							</div>
							<div className="h-[127px]">
								<ResponsiveContainer
									width="100%"
									className="flex-1"
								>
									<LineChart
										data={
											stationData?.data?.statics
												?.hourly_statics || []
										}
										margin={{
											top: 0,
											right: 0,
											left: 0,
											bottom: 0,
										}}
									>
										<XAxis
											dataKey="hour"
											tickLine={false}
											axisLine={false}
											className="fill-[#858C95] text-xs lowercase leading-[20] tracking-[0%]"
										/>
										<YAxis
											axisLine={false}
											hide={true}
											padding={{ bottom: 30 }}
										/>
										<Line
											type="monotone"
											dataKey="number_of_customers"
											stroke="#5D57C8"
											strokeWidth={1}
											dot={false}
										/>
										<ReferenceLine
											y={averageCustomers}
											stroke="#E6E6E7"
										/>
									</LineChart>
								</ResponsiveContainer>
							</div>

							<div className="flex justify-between">
								<div className="flex items-center">
									<IoMdStopwatch className="mr-2" size={42} />
									<div className="flex-1">
										<p className="text-xl font-medium">
											{convertTimeFormat(
												stationData?.data
													?.waitlist_setting
													?.use_average_time || ""
											)}
											{" mins"}
										</p>
										<p className="text-sm">Avg Wait Time</p>
									</div>
								</div>
								<div className="flex">
									<RiCalendarScheduleLine
										className="mr-2"
										size={42}
									/>
									<div className="flex-1">
										<p className="text-l mb-xl font-medium">
											{stationData?.data?.statics
												?.appointment_count || 0}
											{" Appointments"}
										</p>
										<p className="text-sm text-gray-400">
											Scheduled
										</p>
									</div>
								</div>
								<div className="flex">
									<TbListDetails className="mr-2" size={42} />
									<div className="flex-1">
										<p className="text-l mb-xl font-medium">
											{stationData?.data?.statics
												?.waitlist_count || 0}
											{" Waitlist"}
										</p>
										<p className="text-sm text-gray-400">
											Patients
										</p>
									</div>
								</div>
								<div className="flex">
									<PiMapPinAreaBold
										className="mr-2"
										size={42}
									/>
									<div className="flex-1">
										<p className="text-l mb-xl font-medium">
											{stationData?.data?.statics
												?.customers_count || 0}
											{" Patients"}
										</p>
										<p className="text-sm text-gray-400">
											Currently checked-in
										</p>
									</div>
								</div>
							</div>
						</Card>
						<Card className="boxShadow-custom2 col-span-2 space-y-3 p-4">
							<div className="flex items-center justify-between space-x-2">
								<div className="flex-1">
									<h3 className="text-2xl font-semibold">
										Station members
									</h3>
								</div>
								<Button variant={"secondary"} size="icon-sm">
									<i className="mgc_user_add_line text-[16px] text-primary"></i>
								</Button>
								<Button variant={"secondary"} size="icon-sm">
									<i className="mgc_edit_2_line text-[16px] text-primary"></i>
								</Button>
							</div>
							{stationData?.data?.team_members.length === 0 ? (
								<div className="flex h-full flex-col items-center justify-center py-4">
									<i className="mgc_file_line text-[32px] text-primary"></i>
									<h2 className="mt-5 font-semibold">
										No team member data
									</h2>
									<p className="text-gray-500">
										Get started by adding a new custom
										field.
									</p>
								</div>
							) : (
								stationData?.data?.team_members.map(
									(members: any) => (
										<div
											key={members?.id}
											className="rounded-lg bg-gray-50 p-3"
										>
											<p className="text-sm font-medium">
												Name{" "}
												<span className="font-light">
													({members.name})
												</span>
											</p>
											<p className="text-sm text-gray-400">
												{`${members.phone_number} ${members.email}`}
											</p>
										</div>
									)
								)
							)}
						</Card>
					</div>
					<ScheduleSettings
						stationData={stationData}
						setShowInfoModal={setShowStationInfoModal}
						setActiveInfoModalScreen={setActiveInfoModalScreen}
					/>
					<WaitlistSettings
						stationData={stationData}
						setShowInfoModal={setShowStationInfoModal}
						setActiveInfoModalScreen={setActiveInfoModalScreen}
					/>
				</div>
			</div>
			<StationInfoModal
				station={stationData?.data}
				showModal={showStationInfoModal}
				setShowModal={setShowStationInfoModal}
				isLoading={stationLoading}
				buttonAction={() => {}}
				activeInfoModalScreen={activeInfoModalScreen}
				setActiveInfoModalScreen={setActiveInfoModalScreen}
			/>
		</main>
	);
};

export default StationDetails;
