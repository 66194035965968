import React from "react";
import { BsInfoCircle } from "react-icons/bs";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import { PiCheckCircle } from "react-icons/pi";
import { TbCalendarStats } from "react-icons/tb";
import { cn } from "../../../utils/functions";

interface DetailsPaneProps {
	onClick: () => void;
	onApprove: () => void;
	onCancel: () => void;
	onReschedule?: () => void;
	status: string;
}
const DetailsPane = ({
	onClick,
	onApprove,
	onCancel,
	onReschedule,
	status,
}: DetailsPaneProps) => {
	return (
		<div
			className={cn(
				"flex flex-col items-center justify-start gap-4 text-[14px] font-[400]"
			)}
		>
			<button
				className="flex items-center gap-2 self-start px-0 text-[14px]"
				onClick={onClick}
			>
				<BsInfoCircle className="text-[14px]" />
				Details
			</button>
			{status == "waiting_to_be_approved" && (
				<button
					className="flex items-center gap-2 self-start text-[14px] text-[#2AA63C]"
					onClick={onApprove}
				>
					<PiCheckCircle />
					Approve
				</button>
			)}

			{status != "expired" && (
				<button
					className="flex items-center gap-2 self-start text-[14px]"
					onClick={onReschedule}
				>
					<TbCalendarStats />
					Reschedule
				</button>
			)}

			{status != "expired" && (
				<button
					className=" flex items-center gap-2 self-start text-[14px] text-[#C8322B]"
					onClick={onCancel}
				>
					<IoMdRemoveCircleOutline />
					Decline
				</button>
			)}
		</div>
	);
};

export default DetailsPane;
