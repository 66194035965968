import UpdateWaitlistSlice from "@src/store/slices/waitlist/updateWaitlistSlice";
import { PatientStatus, StatusOfPatient } from "@src/types/wait-list";
import { QueueEntry } from "@src/types/waitlist/waitlist";

const useWaitlistWebSocketActions = () => {
	const updateWaitlistMutation = UpdateWaitlistSlice();

	const statusMap = {
		[PatientStatus.Schedule]: StatusOfPatient.Pending,
		[PatientStatus.Pending]: StatusOfPatient.Pending,
		[PatientStatus.Upcoming]: StatusOfPatient.Upcoming,
		[PatientStatus.NextInLine]: StatusOfPatient.NextInLine,
		[PatientStatus.Serving]: StatusOfPatient.GettingService,
		[PatientStatus.Done]: StatusOfPatient.Done,
	};

	const updateStatusOfPatientCard = (
		overColumn: string,
		selectedPatient: QueueEntry,
		order_at?: string
	) => {
		updateWaitlistMutation.mutate({
			waitlist_id: selectedPatient?.waitlist_id,
			status: statusMap[overColumn],
			order_at,
		});
	};

	return { updateStatusOfPatientCard };
};

export default useWaitlistWebSocketActions;
