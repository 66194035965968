import { LuRotateCcw, LuSettings, LuTrash2 } from "react-icons/lu";
import { Button } from "../../../components/ui/button";
import { Dispatch, SetStateAction } from "react";
import { EventItem } from "../../../services/data";
import Select from "react-select";
import useUserStore from "@src/store/useUserStore";
import useLocationSelection from "@/src/hooks/waitlist/useLocationSelection";
import CustomMultiSelect from "@src/components/common/CustomMultiSelect";
import useAppointmentStore from "@/src/store/useAppointmentStore";

interface SchedulerHeaderProps {
	events: any[];
	setEvents: Dispatch<SetStateAction<EventItem[]>>;
	selectedStations: { value: string; label: string }[];
	setSelectedStations: Dispatch<
		SetStateAction<{ value: string; label: string }[]>
	>;
	setShowAppointmentModal: Dispatch<SetStateAction<boolean>>;
	// setShowSettingsModal: Dispatch<SetStateAction<boolean>>;
}

const ScheduleHeader = ({
	events,
	// setShowSettingsModal,
	selectedStations,
	setSelectedStations,
}: SchedulerHeaderProps) => {
	// const {locationsList = useUserStore((s) => s.locationsList);
	const { currentLocationId, locationsList } = useUserStore();
	const { handleLocationChange } = useLocationSelection();
	const { setShowAppointmentSettingsModal } = useAppointmentStore();
	const stations = Array.from(
		new Map(
			events?.map((event) => [
				event?.station?.id,
				{
					label: event.station.name,
					value: event.station.id,
				},
			])
		)
	).map(([_, station]) => station);
	const isStationsOptionDisabled = (option) => {
		return (
			selectedStations.length >= 4 && !selectedStations.includes(option)
		);
	};
	const locationName = locationsList.filter(
		(location) => location.value == currentLocationId.toString()
	);

	return (
		<div className="flex items-center justify-end gap-x-4 text-sm">
			<div className="flex items-center gap-3">
				<Select
					className="h-[36px] w-[140px]"
					placeholder="Locations"
					value={locationName}
					options={locationsList}
					onChange={(selectedOption: any) => {
						handleLocationChange(selectedOption.value);
					}}
				/>
				<CustomMultiSelect
					isSearchable
					isMulti
					closeMenuOnSelect={false}
					value={selectedStations}
					placeholder="Stations"
					className="xl:w-[180px]"
					options={stations}
					isOptionDisabled={isStationsOptionDisabled}
					onChange={(selectedOption) => {
						setSelectedStations(selectedOption);
					}}
				/>
				{/* <CustomSelect
					className="h-[36px] w-[160px] focus:outline-none "
					placeholder="Stations"
					isMulti
					closeMenuOnSelect={false}
					options={stations}
					value={selectedStations}
					onChange={(selectedOption) => {
						setSelectedStations(selectedOption);
					}}
				/> */}
			</div>
			<div className="flex items-center gap-3">
				{/* <div className="relative">
					<Input
						className="h-[36px] w-[250px] pl-6  focus:outline-none"
						placeholder="Search by Name"
					/>
					<span className=" absolute left-1 top-[25%] text-lg">
						<PiMagnifyingGlass width={"14px"} height={"14px"} />
					</span>
				</div> */}
				{/* <Button
					className="flex h-[36px] items-center gap-x-2 text-sm font-[600] text-white"
					variant="default"
					onClick={() => setShowAppointmentModal(true)}
				>
					<span className="pr-1 text-lg">
						<IoMdAddCircleOutline color={"#fff"} />
					</span>
					<p>Add Appointment</p>
				</Button> */}

				<Button
					variant="outline"
					size="icon-sm"
					onClick={() => location.reload()}
				>
					<LuRotateCcw size={18} />
				</Button>

				<Button
					variant="outline"
					size="icon-sm"
					onClick={() => setShowAppointmentSettingsModal(true)}
				>
					<LuSettings size={18} />
				</Button>
				<Button variant="outline" size="icon-sm">
					<LuTrash2 size={18} className=" text-red-500" />
				</Button>
			</div>
		</div>
	);
};

export default ScheduleHeader;
