import React from "react";
import { TabButton } from "../waitlist/PatientInformation/PatientInformation";
import TeamMembersTab from "./TeamMembersTab";

const TeamMemberTabs: React.FC = () => {
	const [currentTab, setCurrentTab] = React.useState<
		"team-members" | "service-providers"
	>("team-members");

	return (
		<div className="flex flex-1 flex-col">
			<div className="flex">
				<TabButton
					label="Team Members"
					isActive={currentTab === "team-members"}
					onClick={() => setCurrentTab("team-members")}
					className="w-full"
				/>
				<TabButton
					label="Service Providers"
					isActive={currentTab === "service-providers"}
					onClick={() => setCurrentTab("service-providers")}
					className="w-full"
				/>
			</div>

			<div className="flex flex-1 items-stretch justify-stretch">
				{currentTab === "team-members" && <TeamMembersTab />}
				{/* {currentTab === "activity" && <ActivityTab patient={patient} />} */}
			</div>
		</div>
	);
};

export default TeamMemberTabs;
