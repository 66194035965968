import { UseFormSetError } from "react-hook-form";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useNavigate } from "react-router";
import useCustomToast from "../components/CustomToast";
import APIClient from "../services/api-client";
import useUserStore from "../store/useUserStore";
import {
	ForgotSchema,
	ResetPasswordDataResponse,
	ResetSchema,
	UserLoginSchema,
	UserRegisterParams,
} from "../types/Auth";
import { GoogleUserProfile, User } from "../types/NewUser";
import { getCookie } from "../utils/cookies";
import {
	changePassword,
	contactUs,
	logoutUser,
	useQuerySample,
} from "./api/auth";
import { useHandleLoginSuccess } from "./useLoginSuccess";

export const useRegisterUser = (
	onSuccess: (data: {
		status: boolean;
		message: string;
		user: User;
		token: string;
	}) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void = () => {
		return;
	},
	setError: UseFormSetError<UserRegisterParams>
) => {
	const handleLoginSuccess = useHandleLoginSuccess();
	const customToast = useCustomToast();

	const apiClient = new APIClient<
		{ status: boolean; message: string; user: User; token: string },
		UserRegisterParams
	>("/register");

	return useMutation<
		{ status: boolean; message: string; user: User; token: string },
		Error,
		UserRegisterParams
	>((data) => apiClient.post(data), {
		onSuccess: (data: {
			status: boolean;
			message: string;
			user: User;
			token: string;
		}) => {
			handleLoginSuccess(data);
			onSuccess(data);
		},
		onError: (error: any) => {
			if (error.response?.data.errors["email"][0]) {
				setError("email", {
					message: error.response?.data.errors["email"][0],
				});
				return;
			}
			customToast("An error occured kindly try again later", {
				id: "signup",
				type: "error",
			});

			onError(error);
		},
	});
};

export const useLoginUser = (
	onSuccess: (data: {
		status: boolean;
		message: string;
		user: User;
		token: string;
	}) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void = () => {
		return;
	},
	setError: UseFormSetError<UserLoginSchema>
) => {
	const handleLoginSuccess = useHandleLoginSuccess();
	const customToast = useCustomToast();

	const apiClient = new APIClient<
		{ status: boolean; message: string; user: User; token: string },
		UserLoginSchema
	>("/login");

	return useMutation<
		{ status: boolean; message: string; user: User; token: string },
		AxiosError<any>,
		UserLoginSchema
	>(
		(data) =>
			apiClient.post(data, {
				headers: {
					Authorization: "Bearer " + getCookie("ac-token"),
				},
			}),
		{
			onSuccess: (data) => {
				onSuccess(data);
				handleLoginSuccess(data);
			},
			onError: (error: AxiosError<any>) => {
				if (error.response?.data.message) {
					setError("email", {
						message: "Email mismatch",
					});
					setError("password", {
						message: "Password mismatch",
					});
					return;
				}

				customToast("An error occured kindly try again later", {
					id: "login",
					type: "error",
				});
				onError(error);
			},
		}
	);
};

export const useGoogleRegisterUser = (
	onSuccess: (data: {
		status: boolean;
		message: string;
		user: User;
		token: string;
	}) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void = () => {
		return;
	}
	// setError: UseFormSetError<UserRegisterParams>
) => {
	const handleLoginSuccess = useHandleLoginSuccess();
	const customToast = useCustomToast();

	const apiClient = new APIClient<
		{
			status: boolean;
			message: string;
			user: User;
			token: string;
		},
		{ token: string }
	>("sign-up-with-google");

	return useMutation<
		{
			status: boolean;
			message: string;
			user: User;
			token: string;
		},
		AxiosError<{ status: string; message: string }>,
		{ token: string }
	>(
		(data) =>
			apiClient.post(data, {
				headers: {
					Authorization: "Bearer " + getCookie("ac-token"),
				},
			}),

		{
			onSuccess: (data: {
				status: boolean;
				message: string;
				user: User;
				token: string;
			}) => {
				handleLoginSuccess(data);
				onSuccess(data);
			},
			onError: (
				error: AxiosError<{ status: string; message: string }>
			) => {
				if (
					error.response &&
					error.response.status >= 400 &&
					error.response?.data.message
				) {
					customToast(error.response?.data.message, {
						id: "google-signup",
						type: "error",
					});
					return;
				}
				customToast("An error occured kindly try again later", {
					id: "google-signup",
					type: "error",
				});

				onError(error);
			},
		}
	);
};

export const useGoogleGetUserProfile = (
	onSuccess: (data: GoogleUserProfile) => void = () => {
		return;
	},
	onError: (error: AxiosError<any>) => void = () => {
		return;
	}
) => {
	const apiClient = new APIClient<GoogleUserProfile, unknown>(
		"https://www.googleapis.com/oauth2/v3/userinfo"
	);

	return useMutation<
		GoogleUserProfile,
		AxiosError<any>,
		{ access_token: string }
	>(
		(data) =>
			apiClient.get({
				// params: {
				// 	access_token: data.access_token,
				// },
				headers: {
					Authorization: "Bearer " + data.access_token,
				},
			}),
		{
			onSuccess: (data) => {
				console.log(data);
				onSuccess(data);
			},
			onError: (error: AxiosError<any>) => {
				toast.error("An error occured kindly try again later");
				onError(error);
			},
		}
	);
};

export const useLogoutUser = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void = () => {
		return;
	}
) => {
	return useMutation<AxiosResponse<unknown>, AxiosError, void>(logoutUser, {
		onSuccess: (data: AxiosResponse) => {
			// Clear cookies and local storage
			// navigate("/");
			onSuccess(data);
		},
		onError: (error: AxiosError) => {
			//Force logout on logout error
			onError(error);
		},
	});
};

export const useForgotPassword = (
	onSuccess: (data: unknown) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void = () => {
		return;
	}
) => {
	const apiClient = new APIClient<unknown, ForgotSchema>("forgot-password");

	return useMutation<unknown, AxiosError, ForgotSchema>(
		(data) => apiClient.post(data),
		{
			onSuccess,
			onError,
		}
	);
};

// export const useVerifyResetLink = (
// 	token: string,
// 	onSuccess: (data: unknown) => void = () => {
// 		return;
// 	},
// 	onError: (error: AxiosError) => void = () => {
// 		return;
// 	}
// ) => {
// 	const navigate = useNavigate();
// 	const customToast = useCustomToast();
// 	const apiClient = new APIClient<unknown, ResetSchema>(
// 		"verify-password-reset/" + token
// 	);

// 	return useQuery<unknown, AxiosError, ResetSchema>(
// 		"reset-link",
// 		() => apiClient.get(),
// 		{
// 			onSuccess: (data) => {
// 				console.log(data);
// 			},
// 			onError: (error: AxiosError) => {
// 				onError(error);
// 				customToast("This token has expired!", {
// 					id: "verify-token",
// 					type: "error",
// 				});
// 				console.log(error);

// 				// navigate("/sign-in");
// 			},
// 		}
// 	);
// };

export const useResetPassword = (
	setError: UseFormSetError<ResetSchema>,
	onError: (
		error: AxiosError<{ success: boolean; message: any }>
	) => void = () => {
		return;
	}
) => {
	const apiClient = new APIClient<unknown, ResetSchema>("reset-password");
	const navigate = useNavigate();
	const customToast = useCustomToast();

	return useMutation<
		unknown,
		AxiosError<{
			success: boolean;
			message: { password: string[]; token: string[] };
		}>,
		ResetSchema
	>((data) => apiClient.post(data), {
		onSuccess: (data) => {
			customToast("Password changed successfully!", {
				id: "reset-password",
				type: "success",
			});
			if (
				(data as ResetPasswordDataResponse)?.product_types.some(
					(product_type) => product_type === "room_booking"
				)
			) {
				window.open("https://spaces.migranium.com/", "_self");
			} else {
				navigate("/sign-in");
			}
		},
		onError: (error) => {
			if (error.response?.data.message)
				if (error.response?.data.message.password) {
					console.log(error.response?.data.message.password);
					setError("password", {
						message: "Passwords do not match",
					});
					setError("password_confirmation", {
						message: "Passwords do not match",
					});
				}
			if (error?.status == 422) {
				customToast("The Token used is invalid, please try again", {
					id: "reset-password",
					type: "error",
				});
				navigate("/forgot-password");
			}
			onError(error);
		},
	});
};

export const useChangePassword = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void = () => {
		return;
	}
) => {
	const { mutate, ...rest } = useMutation(changePassword, {
		onSuccess,
		onError,
	});

	return { changePassword: mutate, ...rest };
};

export const useQueryEaxmple = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void = () => {
		return;
	}
) => {
	return useQuery("use_query_name", useQuerySample, {
		onSuccess,
		onError,
	});
};

export const useContactUs = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void = () => {
		return;
	}
) => {
	return useMutation(contactUs, {
		onSuccess,
		onError,
	});
};

export const useGetUserInformation = (
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const apiClient = new APIClient<{ data: User }, unknown>(
		"/user-information"
	);
	const navigate = useNavigate();
	const setOnboardingState = useUserStore((s) => s.setOnboardingState);
	const setUser = useUserStore((s) => s.setUser);
	const setLocationsList = useUserStore((s) => s.setLocationsList);
	const setStationsList = useUserStore((s) => s.setStationsList);
	const setCurrentLocationId = useUserStore((s) => s.setCurrentLocationId);
	const setCurrentStationId = useUserStore((s) => s.setCurrentStationId);
	const setQueueUrl = useUserStore((s) => s.setQueueUrl);

	return useMutation<{ data: User }, Error, unknown>(
		() =>
			apiClient.get({
				headers: {
					Authorization: "Bearer " + getCookie("ac-token"),
				},
			}),
		{
			onSuccess: (data) => {
				setUser(data.data);
				setCurrentLocationId(data.data.business.locations[0].id);
				setQueueUrl(
					data.data.business.locations[0].stations[0].url_code ?? ""
				);
				setCurrentStationId("All Stations");
				setLocationsList(
					data.data.business.locations.length
						? data.data.business.locations?.map((location) => ({
								label: location.name,
								value: location.id.toString(),
							})) || []
						: []
				);

				setStationsList(
					data.data.business.locations
						? [
								{
									label: "All Stations",
									value: "All Stations",
								},
								...(data.data.business.locations.flatMap(
									(location) =>
										location.stations.map((station) => ({
											label: station.name,
											value: station.id.toString(),
										}))
								) || []),
							]
						: []
				);

				const productType = localStorage.getItem("product_type");

				if (productType === "room_booking") {
					window.location.href = "https://spaces.migranium.com/";
				} else if (import.meta.env.PROD) {
					setOnboardingState(3);
					window.location.href = "https://migranium.com/sign-in";
				} else {
					setOnboardingState(3);
					navigate("/dashboard/waitlist");
				}
			},
			onError,
		}
	);
};
