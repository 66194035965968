import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
	DialogFooter,
} from "@src/components/ui/dialog";
import { CustomSelect } from "@components/form/CustomSelect";
import { Button } from "@src/components/ui/button";
import FormInput from "@components/form/FormInput";
import IntakeFieldOption from "./IntakeFieldOption";
import { getErrorMessage } from "@src/utils/functions";
import { AddCustomizationFieldProps } from "@src/types/customization";
import Loader from "@src/components/Loader/Loader";

const customFieldTypeOptions = [
	{ value: "text", label: "Text" },
	{ value: "numeric", label: "Number" },
	{ value: "date", label: "Date" },
	{ value: "date_range", label: "Date Range" },
	{ value: "dropdown", label: "Dropdown" },
	{ value: "checkbox", label: "Checkboxes" },
	{ value: "attachment", label: "Attachment" },
	{ value: "infoImage", label: "Informational Image" },
	{ value: "infoText", label: "Informational Text" },
];

interface AddFieldsProps {
	modalMode: string | undefined;
	setModalMode: React.Dispatch<React.SetStateAction<string | undefined>>;
	onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
	isLoading?: boolean;
}

const AddFieldModal: React.FC<AddFieldsProps> = ({
	setModalMode,
	modalMode,
	onSubmit,
	isLoading,
}) => {
	const {
		register,
		watch,
		control,
		reset,
		formState: { errors, isValid },
	} = useFormContext<AddCustomizationFieldProps>();
	console.log("🚀 ~ errors, isValid:", errors, isValid);

	const toggleModal = (status: boolean) => {
		setModalMode(status ? modalMode || "add" : undefined);
		if (!status) {
			reset({
				name: "",
				subtitle: "",
				type: "text",
				numeric_unit_title: "",
				info_text_value: "",
				long_text: 0,
				date_range: 0,
				date_range_value: null,
				options: ["Option 1"],
				allow_multiple: 0,
				image: "",
				approved_formats: [],
				apply_to: "all",
				field_requirement: "yes",
				apply_to_option: {
					apply_to_all: 0,
					locations: [],
				},
			});
		}
	};

	const handleDialogSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		onSubmit();
	};

	return (
		<Dialog
			open={modalMode === "add" || modalMode === "edit-add"}
			onOpenChange={toggleModal}
		>
			<DialogTrigger asChild>
				<Button className="max-w-[140px] flex-1 self-center rounded-md">
					Add New Field
				</Button>
			</DialogTrigger>
			<DialogContent
				aria-describedby="dialog-description"
				className="overflow-y-auto"
			>
				<DialogHeader>
					<DialogTitle className="text-center">
						{modalMode === "edit-add"
							? "Edit Intake Field"
							: "Add New Intake Field"}
					</DialogTitle>
					<DialogDescription></DialogDescription>
				</DialogHeader>
				<form className="grid gap-4" onSubmit={handleDialogSubmit}>
					<FormInput
						inputType="text"
						label="Field Title *"
						labelStyles="font-semibold tracking-[-0.1px] text-[#323539]"
						register={{ ...register("name") }}
						className="h-10"
						error={getErrorMessage(errors.name?.message)}
					/>
					<FormInput
						inputType="text"
						label="Field Subtitle"
						register={{ ...register("subtitle") }}
						className="h-10"
						error={getErrorMessage(errors.subtitle?.message)}
					/>
					<div className="border-t pt-3">
						<label className="bt-1.5 mb-1.5 block text-sm font-semibold tracking-[-0.1px] text-[#323539]">
							Input Type *
						</label>
						<Controller
							name={"type"}
							control={control}
							render={({ field }) => (
								<CustomSelect
									placeholder="Select from below"
									className="mb-1 h-10"
									options={customFieldTypeOptions}
									value={field.value}
									onChange={(value: any) =>
										field.onChange(value)
									}
									error={getErrorMessage(
										errors.type?.message
									)}
								/>
							)}
						/>
						<IntakeFieldOption type={watch("type")} />
					</div>
					<DialogFooter>
						<div className="flex justify-end space-x-3 pt-3">
							<Button
								variant="outline"
								type="button"
								className="self-center rounded-md"
								onClick={() => setModalMode(undefined)}
							>
								Cancel
							</Button>
							<Button
								type="submit"
								className="rounded-md"
								disabled={!isValid}
							>
								{isLoading ? (
									<Loader size={20} />
								) : modalMode === "edit-add" ? (
									"Update"
								) : (
									"Add"
								)}
							</Button>
						</div>
					</DialogFooter>
				</form>
			</DialogContent>
		</Dialog>
	);
};

export default AddFieldModal;
