import { create } from "zustand";
import { persist } from "zustand/middleware";
import {
	LocationArray,
	LocationDetailsArray,
	LocationResponse,
} from "../types/Location";

interface LocationsStore {
	locations: LocationArray;
	locationDetails: LocationResponse;
	addLocationInfo: AddLocationInfoType | null;
	setLocations: (locations: LocationArray) => void;
	setLocationDetails: (locationDetails: LocationResponse) => void;
	setAddLocationInfo: (addLocationInfo: AddLocationInfoType) => void;
	reset: () => void;
}

interface AddLocationInfoType {
	id: number;
}

const initialState = {
	locations: [],
	locationDetails: {} as LocationResponse,
	addLocationInfo: null,
};

const useLocationsStore = create<
	LocationsStore,
	[["zustand/persist", LocationsStore]]
>(
	persist(
		(set) => ({
			...initialState,
			setLocations: (locations: LocationArray) => {
				set(() => ({ locations }));
			},
			setLocationDetails: (locationDetails: LocationResponse) => {
				set(() => ({ locationDetails }));
			},
			setAddLocationInfo: (addLocationInfo: AddLocationInfoType) => {
				set(() => ({ addLocationInfo }));
			},
			reset: () => set(initialState),
		}),
		{
			name: "locations-storage",
			getStorage: () => localStorage,
		}
	)
);

export default useLocationsStore;
