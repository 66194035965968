import React, { useEffect } from "react";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
	DialogFooter,
} from "@src/components/ui/dialog";
import { Label } from "@src/components/ui/label";
import { Button } from "@src/components/ui/button";
import FormInput from "@components/form/FormInput";
import { SubmitHandler, useForm } from "react-hook-form";
import { getErrorMessage } from "@src/utils/functions";
import { Input } from "@src/components/ui/input";
import {
	AppointmentTypeProps,
	AppointmentTypeSchema,
	CustomTimeSlotProps,
} from "@src/types/Location";
import { zodResolver } from "@hookform/resolvers/zod";
import { GetStationDetailsSlice } from "@src/store/slices/locations/locationSlice";
import { useSearchParams } from "react-router-dom";
import { cn } from "@src/utils/functions";
import {
	AddCustomTimeSlotSlice,
	UpdateCustomTimeSlotSlice,
	GetCustomTimeSlotSlice,
	AddRemoveCustomSlotSlice,
} from "@src/store/slices/custom-intake/customTimeSlotSlice";
import useStationStore from "@src/store/useStationStore";
import Loader from "@src/components/Loader/Loader";
import toast from "react-hot-toast";

interface AddFieldsProps {
	modalMode: string | undefined;
	setModalMode: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const AddExistingAppointmentTypeModal: React.FC<AddFieldsProps> = ({
	setModalMode,
	modalMode,
}) => {
	const [searchParams] = useSearchParams();
	const stationId = searchParams.get("stationId") || "";
	const stationData = useStationStore((s) => s.stationDetails);

	const toggleModal = (status: boolean) => {
		setModalMode(status ? "exisiting" : "");
	};

	const { invalidateQuery } = GetStationDetailsSlice(
		Number(parseInt(stationId))
	);

	const {
		data: customTimeSlotData,
		invalidateQuery: invalidateCustomTimeSlot,
	} = GetCustomTimeSlotSlice({});

	// console.log("🚀 ~ customTimeSlotData:", customTimeSlotData);

	const methods = useForm<AppointmentTypeProps>({
		resolver: zodResolver(AppointmentTypeSchema),
		defaultValues: {
			id: 0,
		},
	});

	const {
		handleSubmit,
		setValue,
		reset,
		formState: { errors, isValid },
	} = methods;

	const {
		isLoading: addExistingFieldLoading,
		mutate: addExistingFieldMutation,
	} = AddRemoveCustomSlotSlice(
		(data) => {
			console.log("Update successful:", data);
			invalidateQuery();
			invalidateCustomTimeSlot();
			setModalMode(undefined);
			reset({ id: 0 });
		},
		(error) => {
			console.error("Update failed:", error);
			toast.dismiss("add-remove-custom-slot");
		}
	);

	const handleDialogSubmit: SubmitHandler<AppointmentTypeProps> = (data) => {
		addExistingFieldMutation({
			id: data.id || 0,
			data: { station_ids_to_add: [stationData.id || 0] },
		});
	};

	return (
		<Dialog
			open={modalMode === "exisiting"}
			onOpenChange={(e: boolean) => toggleModal(e)}
		>
			<DialogTrigger asChild>
				<Button
					variant={"link"}
					className="h-auto rounded-none border-b-2 border-primary p-0 font-semibold leading-none outline-none hover:no-underline"
				>
					Add Existing Appointment Type
				</Button>
			</DialogTrigger>
			<DialogContent aria-describedby="dialog-description">
				<DialogHeader>
					<DialogTitle className="text-center">
						Add Existing Appointment Type
					</DialogTitle>
					<DialogDescription></DialogDescription>
				</DialogHeader>
				<form
					className="mt-4 grid gap-4"
					onSubmit={handleSubmit(handleDialogSubmit)}
				>
					<FormInput
						select
						label="Select Appointment Type"
						placeholder="Select"
						options={
							customTimeSlotData?.data.map((location: any) => ({
								label: location.title,
								value: location.id,
							})) || []
						}
						className="h-10"
						onChange={(selectedOptions) => {
							const selectedId = selectedOptions?.value;
							setValue("id", selectedId);
						}}
						// error={getErrorMessage(errors.title?.message)}
					/>
					<DialogFooter>
						<div className="mt-2 flex justify-end space-x-3 pt-3">
							<Button
								variant={"outline"}
								type="button"
								className=" self-center rounded-md"
								onClick={() => setModalMode(undefined)}
							>
								Cancel
							</Button>
							<Button
								type="submit"
								disabled={!isValid}
								className="min-w-[90px] rounded-md"
							>
								{addExistingFieldLoading ? (
									<Loader size={20} />
								) : (
									"Add"
								)}
							</Button>
						</div>
					</DialogFooter>
				</form>
			</DialogContent>
		</Dialog>
	);
};

export default AddExistingAppointmentTypeModal;
