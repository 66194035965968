import { Slider } from "@src/components/ui/slider";
import { Switch } from "@src/components/ui/switch";
import useStationStore from "@src/store/useStationStore";
import { Input } from "@src/components/ui/input";
import { Button } from "@src/components/ui/button";
import Loader from "@src/components/Loader/Loader";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { queueSettingsSchema, queueSettingsProps } from "@src/types/Location";
import { useEffect } from "react";
import {
	GetStationDetailsSlice,
	QueueSettingsSlice,
} from "@src/store/slices/locations/locationSlice";
import { useSearchParams } from "react-router-dom";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "@src/components/ui/select";
import Checkbox from "@src/components/ui-extended/checkbox";
import { Label } from "@src/components/ui/label";
import useUserStore from "@src/store/useUserStore";

interface GeneralProps {}

const General: React.FC<GeneralProps> = () => {
	const stationData = useStationStore((s) => s.stationDetails);
	const user = useUserStore((s) => s.user);
	const [searchParams] = useSearchParams();
	const stationId = searchParams.get("stationId") || "";

	const { invalidateQuery } = GetStationDetailsSlice(
		Number(parseInt(stationId))
	);

	const methods = useForm<queueSettingsProps>({
		resolver: zodResolver(queueSettingsSchema),
		defaultValues: {
			waitlist_setting: {
				is_visible: 0,
				is_queue_active: 0,
				manual_wait_time: 0,
				use_average_time: 0,
				auto_clearing_minute: 0,
				auto_clearing: 0,
			},
		},
	});

	const {
		handleSubmit,
		reset,
		setValue,
		getValues,
		watch,
		control,
		formState: { errors, isValid },
	} = methods;

	const handleSwitchChange = (field: any) => (value: boolean) => {
		setValue(field, value ? 1 : 0);
	};

	const { mutate: updateQueueSettings, isLoading } = QueueSettingsSlice(
		(data) => {
			console.log("Update successful:", data);
			invalidateQuery();
		},
		(error) => {
			console.error("Update failed:", error);
		}
	);

	const onSubmit: SubmitHandler<queueSettingsProps> = (data) => {
		updateQueueSettings({
			data: {
				...data,
				apply_to_option: {
					apply_to_all: 0,
					locations: [
						{
							id: stationData?.location_id || 0,
							update_location: 0,
							apply_to_all_stations: 0,
							selected_stations: [stationData?.id || 0],
						},
					],
				},
			},
			queryParams: { station_id: stationId || 0 },
		});
	};

	useEffect(() => {
		reset({
			waitlist_setting: {
				is_visible:
					stationData?.queue_setting?.waitlist_setting?.is_visible ||
					0,
				is_queue_active:
					stationData?.queue_setting?.waitlist_setting
						?.is_queue_active || 0,
				manual_wait_time:
					stationData?.queue_setting?.waitlist_setting
						?.manual_wait_time || 0,
				use_average_time:
					stationData?.queue_setting?.waitlist_setting
						?.use_average_time || 0,
				auto_clearing_minute:
					stationData?.queue_setting?.waitlist_setting
						?.auto_clearing_minute || 0,
				auto_clearing:
					stationData?.queue_setting?.waitlist_setting
						?.auto_clearing || 0,
				waitlist_autoqueue_option: "minutes",
			},
		});
	}, [reset, stationData]);

	const options = [
		{ value: "minutes", label: "minutes" },
		{ value: "hours", label: "hours" },
	];

	const handleCheckboxSelection = (type: any, value: boolean) => {
		setValue(type, value ? 1 : 0);
	};

	return (
		<div className="space-y-8">
			<h3 className="text-xl font-semibold">General Waitlist</h3>
			<div className="space-y-5">
				<div className=" space-y-5">
					<div className="flex items-center border-b pb-5">
						<div className="flex-1">
							<h3 className="font-medium">
								This station will not use a waitlist
							</h3>
						</div>
						<div className="flex items-center">
							<Controller
								name="waitlist_setting.is_visible"
								control={control}
								render={({ field }) => (
									<div className="flex items-center space-x-2">
										<Checkbox
											id="field"
											className="rounded-sm border-2 border-[#D1D1D1]"
											isChecked={Boolean(field.value)}
											handleCheckboxChange={() =>
												// setValue(
												// 	"waitlist_setting.is_visible",
												// 	!watch(
												// 		"waitlist_setting.is_visible"
												// 	)
												// 		? 1
												// 		: 0
												// )
												field.onChange(
													field.value ? 0 : 1
												)
											}
										/>
										<Label
											htmlFor="field"
											className="font-medium"
										>
											{watch(
												"waitlist_setting.is_visible"
											)
												? "Waitlist Available"
												: "No Waitlist"}
										</Label>
									</div>
								)}
							/>
							{/* <span className="">
								{watch("schedule_setting.is_visible")
									? "On"
									: "Off"}
							</span> */}
						</div>
					</div>
					<div className="flex items-center border-b pb-5">
						<div className="flex-1 space-y-2">
							<p>Wait Times</p>
							<p className="text-sm text-gray-400">
								This determines how our system will calculate
								the wait time.
							</p>
							<div className="flex items-center gap-x-6">
								<p className="traking-[-0.1px] flex-1 text-[14px] leading-[20px] text-[#858C95]">
									Historical Avg. calculates the total average
									and Custom allows you to enter a number.
								</p>
								<div className="flex flex-1 items-center gap-3">
									<Select
										value={(
											watch(
												"waitlist_setting.use_average_time"
											) || 0
										).toString()}
										onValueChange={(value) => {
											if (value === "0") {
												setValue(
													"waitlist_setting.use_average_time",
													0
												);
												setValue(
													"waitlist_setting.manual_wait_time",
													parseInt(
														user?.business
															?.average_waiting_time ||
															"0"
													) ?? 30
												);

												// setIsUsingHistoricalAvg(true);
											} else if (value === "1") {
												setValue(
													"waitlist_setting.use_average_time",
													1
												);
												setValue(
													"waitlist_setting.manual_wait_time",
													0
												);
											}
										}}
									>
										<SelectTrigger className="h-9 flex-1 py-0">
											<SelectValue
												placeholder={"Select Priority"}
												className="text-[#858C95]"
											/>
										</SelectTrigger>
										<SelectContent className="!z-[9999]">
											<SelectGroup>
												<SelectLabel className="px-2">
													Select Historical Average
												</SelectLabel>

												{waitTimes.map((option) => {
													return (
														<SelectItem
															key={option.value.toString()}
															value={option.value.toString()}
															className="px-8"
														>
															{option.label}
														</SelectItem>
													);
												})}
											</SelectGroup>
										</SelectContent>
									</Select>
									{Boolean(
										getValues(
											"waitlist_setting.use_average_time"
										)
									) && (
										<div className="flex h-9 items-center rounded-md border border-input py-1.5 focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2">
											<Input
												className="remove-number-input-arrow max-h-full w-[30px] min-w-[48px] max-w-fit border-none text-[14px] font-medium leading-[20px] focus-within:ring-0 focus:outline-none"
												value={parseInt(
													watch(
														"waitlist_setting.manual_wait_time"
													)?.toString() || ""
												)}
												onChange={(e) => {
													if (
														parseInt(
															e.target.value
														) === 0 ||
														!e.target.value.length
													)
														return;
													setValue(
														"waitlist_setting.manual_wait_time",
														parseInt(e.target.value)
													);
												}}
												min={1}
												type="number"
											/>
											<p className="border-l border-[#E5E5E7] px-1.5 text-[14px] font-medium leading-[20px] text-[#323539]">
												minutes
											</p>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>

					<div className="border-b pb-5">
						<div className="mb-8 flex items-center">
							<div className="flex-1">
								<p>Auto Clear Queue</p>
								<p className="text-sm text-gray-400">
									The time after which the queue will be
									automatically cleared after business hours.
								</p>
							</div>
							<div className="flex items-center">
								<Controller
									name="waitlist_setting.auto_clearing"
									control={control}
									render={({ field }) => (
										<Switch
											className="scale-[0.70] data-[state=checked]:bg-[#2EBF43]"
											checked={Boolean(field.value)}
											onCheckedChange={handleSwitchChange(
												"waitlist_setting.auto_clearing"
											)}
										/>
									)}
								/>
								<span className="">
									{watch("waitlist_setting.auto_clearing")
										? "On"
										: "Off"}
								</span>
							</div>
						</div>
						<div className="flex items-center space-x-4">
							{/* <Slider
								value={[
									watch(
										"waitlist_setting.auto_clearing_minute"
									) || 0,
								]}
								max={
									watch(
										"waitlist_setting.waitlist_autoqueue_option"
									) === "minutes"
										? 60
										: 24
								}
								step={1}
								onValueChange={(value) => {
									setValue(
										"waitlist_setting.auto_clearing_minute",
										value[0]
									);
								}}
							/> */}

							<div className="focus-within::border-transparent flex h-9 w-max items-center divide-x-2 rounded-md border border-input py-1.5">
								<Input
									className="remove-number-input-arrow max-h-full max-w-fit border-none text-right text-[14px] font-medium leading-[20px] focus-within:ring-0 focus:outline-none"
									value={String(
										watch(
											"waitlist_setting.auto_clearing_minute"
										)
									)}
									onChange={(e) => {
										setValue(
											"waitlist_setting.auto_clearing_minute",
											parseInt(e.target.value)
										);
									}}
									min={1}
									type="number"
								/>
								<Select
									value={watch(
										"waitlist_setting.waitlist_autoqueue_option"
									)}
									onValueChange={(
										value: "minutes" | "hours"
									) => {
										setValue(
											"waitlist_setting.waitlist_autoqueue_option",
											value
										);
									}}
									disabled
								>
									<SelectTrigger className="focus-within::border-transparent flex h-7 w-max items-center space-x-2.5 rounded-none border-0 px-4 py-0">
										<SelectValue
											placeholder={"Select duration"}
											className="focus-within::border-transparent text-[#858C95]"
										/>
									</SelectTrigger>
									<SelectContent className="focus-within::border-transparent !z-[9999]">
										<SelectGroup>
											{options.map(
												(option: {
													label: string;
													value: string;
												}) => {
													return (
														<SelectItem
															key={option.value}
															value={option.value}
															className="px-8"
														>
															{option.label}
														</SelectItem>
													);
												}
											)}
										</SelectGroup>
									</SelectContent>
								</Select>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="mx-auto flex w-full max-w-[1000px] justify-end gap-2 py-4">
				<Button
					type="reset"
					// onClick={handleCancel}
					variant={"ghost"}
				>
					Cancel
				</Button>
				<Button
					type="submit"
					className="px-9"
					onClick={handleSubmit(onSubmit)}
				>
					{isLoading ? <Loader size={20} /> : "Update"}
				</Button>
			</div>
		</div>
	);
};

export default General;

const waitTimes = [
	{
		label: "Historical Avg.",
		value: "1",
	},
	{
		label: "Manual",
		value: "0",
	},
];
