import useUserStore from "@/src/store/useUserStore";
import appointmentIcon from "@/public/assets/icons/schedule-icons/appointment.svg";
import noAppointmentIcon from "@/public/assets/icons/schedule-icons/no-appointments.svg";
import { Calendar } from "@src/components/ui/calendar";
import Select from "react-select";
import { useCallback, useEffect, useState } from "react";
import { cn } from "@/src/utils/functions";
import TimeSlot from "@/src/components/common/TimeSlot";
import { Button } from "@/src/components/ui/button";
import { CgClose } from "react-icons/cg";
import { Dialog, DialogContent, DialogTitle } from "@/src/components/ui/dialog";
import {
	GetAvailableTimeSlotsForStations,
	GetLocationDetailsForAppointment,
	UpdateAppointment,
	useGetAppointmentDetails,
} from "@/src/store/slices/schedule/scheduleSlice";
import useAppointmentStore from "@/src/store/useAppointmentStore";
import useCustomToast from "@/src/components/CustomToast";
import moment from "moment";
import Loader from "@/src/components/Loader/Loader";

const RescheduleAppointment = ({
	showRescheduleModal,
	setShowRescheduleModal,
	appointmentData,
	closeModal,
}) => {
	const { data: appointmentDetails } = useGetAppointmentDetails(
		appointmentData.id
	);
	const currentLocationId = useUserStore((s) => s.currentLocationId);
	const { appointmentLocationDetails } = useAppointmentStore();
	const toast = useCustomToast();

	const { mutate, isLoading } = UpdateAppointment(
		currentLocationId,
		appointmentData.id,
		(response) => {
			toast(`${response?.data?.message || "Request was successful"}`, {
				type: "success",
				id: "reschedule",
			});
			closeModal();
		},
		(error) => {
			toast(`${error?.response?.data?.message ?? "An Error Occured"}`, {
				type: "error",
				id: "reschedule",
			});
		}
	);
	const handleSelectTimeSlot = (stationId: number, time: string) => {
		setSelectedTime(time);
		setSelectedStationId(stationId);
	};
	const handleRemoveStation = (stationId: number) => {
		setSelectedStation((prevSelectedStations) =>
			prevSelectedStations.filter(
				(station: any) => station.value !== stationId
			)
		);
		if (selectedStationId === stationId) {
			setSelectedTime(null);
			setSelectedStationId(null);
		}
	};
	const [selectedTime, setSelectedTime] = useState<string | null>(null);
	const [selectedStationId, setSelectedStationId] = useState<number | null>(
		null
	);
	const { isError } = GetLocationDetailsForAppointment(currentLocationId);

	const [selectedStation, setSelectedStation] = useState<any>([
		{
			label: appointmentDetails?.data?.station?.name,
			value: appointmentDetails?.data?.station?.id,
		},
	]);
	const [selectedDate, setSelectedDate] = useState<Date | undefined>(
		new Date()
	);
	const [duration, setDuration] = useState<number>(15);
	const [availableStations, setAvailableStations] = useState<any[]>([]);
	const [selectedAppointmentType, setSelectedAppointmentType] =
		useState<any>(null);

	const onSuccess = (data: any) => {
		setAvailableStations(data.data);
	};

	const onError = (error: any) => {
		toast(
			`${error?.response?.data?.message ?? "Failed to fetch available time slots"}`,
			{
				type: "error",
				id: "location-fetch-error",
			}
		);

		console.error("Failed to fetch available time slots", error);
	};

	const { mutate: fetchTimeSlots } = GetAvailableTimeSlotsForStations(
		currentLocationId,
		onSuccess,
		onError
	);

	const fetchAvailableTimeSlots = useCallback(() => {
		if (
			selectedStation?.length > 0 ||
			selectedAppointmentType ||
			selectedDate ||
			duration
		) {
			const params = {
				station_ids: selectedStation?.map(
					(station: any) => station.value
				),
				appointment_type_id: selectedAppointmentType?.value ?? "",
				duration,
				date: selectedDate?.toISOString(),
			};
			fetchTimeSlots(params);
		}
	}, [
		selectedStation,
		selectedAppointmentType,
		selectedDate,
		duration,
		fetchTimeSlots,
	]);

	useEffect(() => {
		fetchAvailableTimeSlots();
	}, [fetchAvailableTimeSlots]);

	const handleReschedule = () => {
		mutate({
			action: "reschedule",
			reschedule_date: selectedDate
				? moment(selectedDate).startOf("day").format("YYYY-MM-DD")
				: undefined,
			reschedule_time: selectedTime
				? selectedTime.slice(0, -3)
				: undefined,
		});
	};

	//get the location info from endpoint with the details of the appointment and mix it with the appointment details passed on the modal as props
	return (
		<Dialog
			open={showRescheduleModal}
			onOpenChange={(newValue) => {
				if (!newValue) {
					setShowRescheduleModal(false);
				}
				setShowRescheduleModal(newValue);
			}}
		>
			<DialogContent className="max-w-[860px]">
				<DialogTitle className="sr-only">
					<p>Reschedule Appointment</p>
				</DialogTitle>
				<section className="flex flex-col gap-y-4 !rounded-lg">
					<div className="flex items-center justify-between">
						<div className="flex items-center gap-4">
							<span>
								<img
									src={appointmentIcon}
									className="aspect-square w-[54px]"
								/>
							</span>
							<h1 className=" text-[22px] font-semibold capitalize">
								{/* Make Selection to schedule an appointment */}
								Reschedule An Appointment
							</h1>
						</div>
						<button
							className="self-start"
							onClick={() => setShowRescheduleModal(false)}
						>
							<i className="mgc_close_line text-[16px] before:!text-main-7"></i>
						</button>
					</div>

					<section className="flex h-[500px]  max-w-[1000px] gap-x-4 overflow-y-auto overflow-x-hidden">
						<div className="flex w-[400px] flex-col gap-x-2 gap-y-6">
							<div className="flex w-full flex-col gap-y-2 pr-4">
								<div className="flex flex-col gap-y-3">
									<div className="flex flex-col gap-y-2">
										<p className="text-sm font-medium">
											Station/ Healthcare Provider
										</p>
										<Select
											isMulti
											options={appointmentLocationDetails?.stations?.map(
												(station) => ({
													value: station.id,
													label: station.name,
												})
											)}
											placeholder="Available Stations"
											value={
												selectedStation ?? {
													label: appointmentDetails
														?.data?.station?.name,
													value: appointmentDetails
														?.data?.station?.id,
												}
											}
											onChange={(selectedOption) => {
												setSelectedStation(
													selectedOption
												);
											}}
										/>
									</div>
									<div className="flex flex-col gap-y-1">
										<p className="text-sm font-medium">
											Select Appointment Type
										</p>
										<Select
											className="w-full"
											placeholder="Appointment Type"
											options={appointmentLocationDetails?.appointment_types?.map(
												(type) => ({
													value: type.id,
													label: type.title,
												})
											)}
											value={selectedAppointmentType}
											onChange={(selectedOption) => {
												setSelectedAppointmentType(
													selectedOption
												);
											}}
										/>
									</div>
									<div className="flex flex-col gap-y-1">
										<p className="text-sm font-medium">
											Appointment Duration
										</p>
										<Select
											className="w-full"
											placeholder="Select Duration"
											options={[15, 30, 45, 60].map(
												(d) => ({
													value: d,
													label: `${d} minutes`,
												})
											)}
											value={{
												value: duration,
												label: `${duration} minutes`,
											}}
											onChange={(selectedOption: any) => {
												setDuration(
													selectedOption.value
												);
											}}
										/>
									</div>
								</div>
							</div>
							<div className="w-full  justify-center rounded-md border  px-4 py-2">
								<h5 className="font-semibold text-[#323539] ">
									Select Your Appointment Date
								</h5>
								<div className="flex justify-center">
									<Calendar
										mode="single"
										className="text-[20px]"
										styles={{
											head_cell: {
												width: "36px",
											},
											cell: {
												width: "36px",
											},
											table: {
												maxWidth: "none",
											},
											day: {
												margin: "auto",
											},
										}}
										selected={
											selectedDate ??
											new Date(
												appointmentDetails?.data?.appointment_date
											)
										}
										onSelect={(date) => {
											setSelectedDate(date);
										}}
										disabled={{ before: new Date() }}
									/>
								</div>
							</div>
						</div>
						<div
							className={cn(
								" h-full  w-full max-w-full  rounded-lg bg-white p-4 ",
								{
									" w-[600px] drop-shadow-[0_0_4px_rgba(0,0,0,0.15)] ":
										selectedStation?.length > 1,
								}
							)}
						>
							{selectedStation && selectedStation?.length > 0 ? (
								<div className="flex flex-col items-center  justify-center gap-y-4">
									<div className=" flex w-full flex-col items-center">
										<h5 className="text-xl font-[600] ">
											Select Appointment Slot
										</h5>
										<p> Choose from any one (1) provider</p>
									</div>

									{/* Should be a time block for the select type and is fetched based off the selected station  */}
									<div className="flex h-full w-full gap-4 overflow-x-auto ">
										{selectedStation?.length < 2 ? (
											<div className="mx-auto  h-[60vh] min-w-[350px] max-w-[350px] overflow-y-auto  rounded-md bg-[#F9F9F9] p-3">
												<h1 className="pb-3 text-center">
													{selectedStation[0]?.label}
												</h1>
												<div className="flex  flex-wrap  gap-x-2 gap-y-4">
													{/* //loading state  */}
													{availableStations
														?.find(
															(s) =>
																s.id ===
																selectedStation[0]
																	.value
														)
														?.available_time_slots?.map(
															(
																timeslot: any,
																i: number
															) => (
																<TimeSlot
																	selected={
																		selectedTime ===
																			timeslot.start_time &&
																		selectedStationId ===
																			selectedStation[0]
																				.value
																	}
																	onClick={() =>
																		handleSelectTimeSlot(
																			selectedStation[0]
																				.value,
																			timeslot.start_time
																		)
																	}
																	className="w-[144px]"
																	timeslot={
																		timeslot
																	}
																	key={i}
																/>
															)
														)}
												</div>
											</div>
										) : (
											availableStations?.map(
												(station: any, id: number) => (
													<div
														className=" flex h-[450px]  min-w-[350px] max-w-full flex-col  gap-y-2 overflow-y-auto rounded-md bg-[#F6F6F6] p-4"
														key={`${id} + ${station.name} + "pane"`}
													>
														<div className="flex items-center justify-between">
															<h1 className="w-full text-center">
																{station.name}
															</h1>
															<button
																className="p-2 text-gray-500 hover:text-red-600"
																onClick={() =>
																	handleRemoveStation(
																		station.id
																	)
																}
															>
																<CgClose />
															</button>
														</div>
														{/* //loading state  */}

														<div
															className={cn(
																"flex flex-wrap justify-center gap-x-2 gap-y-4",
																{
																	"h-full":
																		station
																			?.available_time_slots
																			?.length <
																		1,
																}
															)}
														>
															{station
																.available_time_slots
																?.length ? (
																station.available_time_slots?.map(
																	(
																		timeslot: any,
																		i: number
																	) => (
																		<TimeSlot
																			selected={
																				selectedTime ===
																					timeslot.start_time &&
																				selectedStationId ===
																					station.id
																			}
																			onClick={() =>
																				handleSelectTimeSlot(
																					station.id,
																					timeslot.start_time
																				)
																			}
																			className="w-[144px]"
																			timeslot={
																				timeslot
																			}
																			key={`${timeslot}-${i}`}
																		/>
																	)
																)
															) : (
																<div className="flex h-full flex-col items-center justify-center">
																	<img
																		src={
																			noAppointmentIcon
																		}
																		alt="select a station"
																		className="w-[81px]"
																	/>
																	<div className="text-center">
																		<p className="font-semibold text-[#323539]">
																			No
																			Appointments
																			Available
																		</p>
																		<p className="text-xs  font-medium text-[#323539]">
																			Select
																			A
																			Different
																			Date
																		</p>
																	</div>
																</div>
															)}
														</div>
													</div>
												)
											)
										)}
									</div>
								</div>
							) : (
								<div className="flex h-full w-full flex-col items-center justify-center gap-y-4 rounded-md bg-white drop-shadow-[0_0_4px_rgba(0,0,0,0.15)] ">
									<img
										src={noAppointmentIcon}
										alt="select a station"
										className="w-[81px]"
									/>
									<p className="font-semibold text-[#323539]">
										Select Healthcare Providers
									</p>
								</div>
								// <DefaultStationSelectionSection
								// 	stations={locationsDetailsForAppointment?.stations}
								// 	handleSelection={handleSelectTimeSlot}
								// 	selectedTime={selectedTime}
								// 	selectedStationId={selectedStationId}
								// />
							)}
						</div>
					</section>
					<div className="flex w-full gap-x-2">
						<Button
							className="w-full"
							variant={"outline"}
							onClick={closeModal}
						>
							Cancel
						</Button>
						<Button
							className="w-full"
							variant={"default"}
							disabled={!selectedStationId || !selectedTime}
							onClick={handleReschedule}
						>
							{isLoading ? <Loader size={18} /> : "Reschedule"}
						</Button>
					</div>
				</section>
			</DialogContent>
		</Dialog>
	);
};

export default RescheduleAppointment;
