import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $insertNodeToNearestRoot } from "@lexical/utils";
import {
	COMMAND_PRIORITY_EDITOR,
	createCommand,
	LexicalCommand,
} from "lexical";
import { useEffect } from "react";
import { ImageNode, $createImageNode } from "../nodes/ImageNode";

export const INSERT_IMAGE_COMMAND: LexicalCommand<{
	src: string;
	altText: string;
}> = createCommand("INSERT_IMAGE_COMMAND");

export function ImagePlugin(): null {
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		if (!editor.hasNodes([ImageNode])) {
			throw new Error("ImagePlugin: ImageNode not registered on editor");
		}

		return editor.registerCommand<{ src: string; altText: string }>(
			INSERT_IMAGE_COMMAND,
			(payload) => {
				const imageNode = $createImageNode(
					payload.src,
					payload.altText
				);
				$insertNodeToNearestRoot(imageNode);
				return true;
			},
			COMMAND_PRIORITY_EDITOR
		);
	}, [editor]);

	return null;
}
