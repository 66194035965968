import SettingsLayout from "@src/layouts/SettingsLayout";
import { Route, Routes } from "react-router";
import AccountSettings from "./components/AccountSettings/AccountSettings";
import IntegationsAndPlugins from "./components/IntegrationsAndPluginsSettings";
import OscarIntegration from "./components/Oscar/OscarIntegration";
import ThemeSettings from "./components/ThemeSettings";
import BusinessSettings from "./components/BusinessSettings";

const SettingsRoutes: React.FC = () => {
	return (
		<Routes>
			<Route element={<SettingsLayout />}>
				<Route index element={<AccountSettings />} />
				<Route path="/account-setting" element={<AccountSettings />} />
				<Route
					path="/business-setting"
					element={<BusinessSettings />}
				/>
				<Route path="/theme" element={<ThemeSettings />} />
				<Route path="/integrations-plugins">
					<Route index element={<IntegationsAndPlugins />} />
					<Route
						path="/integrations-plugins/oscar"
						element={<OscarIntegration />}
					/>
				</Route>
			</Route>
		</Routes>
	);
};

export default SettingsRoutes;
