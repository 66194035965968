import UpdateWaitlistSlice from "@/src/store/slices/waitlist/updateWaitlistSlice";
import { cn } from "@/src/utils/functions";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	QueueEntry,
	UpdateWaitlistEnum,
	UpdateWaitlistSchema,
	UpdateWaitlistType,
} from "@type/waitlist/waitlist";
import React from "react";
import { useForm } from "react-hook-form";
import useCustomToast from "../../CustomToast";
import { Button } from "../../ui/button";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../ui/tooltip";

const NotifyPatient: React.FC<{
	patientDetails: QueueEntry;
	className?: string;
	iconClassName?: string;
	variant?:
		| "outline-primary"
		| "outline-destructive"
		| "default"
		| "destructive"
		| "outline"
		| "secondary"
		| "ghost"
		| "link"
		| null
		| undefined;
}> = ({ patientDetails, className, iconClassName, variant }) => {
	const { getValues } = useForm<UpdateWaitlistType>({
		resolver: zodResolver(UpdateWaitlistSchema),
		defaultValues: {
			status: UpdateWaitlistEnum.NotifyTurn,
			waitlist_id: patientDetails.waitlist_id ?? 0,
		},
	});

	const customToast = useCustomToast();

	const updateWaitlistMutation = UpdateWaitlistSlice(
		() => {
			customToast(`Patient notified`, {
				id: "manage-patient-" + patientDetails.customer_id,
				type: "success",
			});
		},
		() => {
			customToast(`Patient could not be notified`, {
				id: "manage-patient-" + patientDetails.customer_id,
				type: "error",
			});
		}
	);

	return (
		<Tooltip delayDuration={1000}>
			<TooltipTrigger>
				<Button
					className={cn(
						"size-[30px] rounded-md bg-[#F5F5F5] p-0 hover:bg-[#F5F5F5]",
						className
					)}
					variant={variant}
					onClick={() => {
						customToast("Nofiying patient", {
							id: "manage-patient-" + patientDetails.customer_id,
							type: "loading",
							duration: 5000,
						});
						updateWaitlistMutation.mutate(getValues());
					}}
				>
					<i
						className={cn(
							"mgc_bell_ringing_line text-[14px] before:!text-[#09244B]",
							iconClassName
						)}
					/>
				</Button>
			</TooltipTrigger>
			<TooltipContent side="top" sideOffset={4}>
				Notify
			</TooltipContent>
		</Tooltip>
	);
};

export default NotifyPatient;
