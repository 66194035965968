import {
	APIVersion2GetServingDetails,
	APIVersion2GetWeeklyWaitlistHistory,
} from "@/src/http/v2";
import {
	ServingQueueResponse,
	WeeklyWaitlistHistoryResponse,
} from "@/src/types/serving/serving";
import { useEffect } from "react";
import { useQuery } from "react-query";
import useUserStore from "../../useUserStore";

export const GetServingSlice = (onError: (error: Error) => void = () => {}) => {
	const currentLocationId = useUserStore((s) => s.currentLocationId);

	const getServingQuery = useQuery<ServingQueueResponse, Error>({
		queryKey: ["get-serving", currentLocationId],
		queryFn: () =>
			APIVersion2GetServingDetails({
				location_id: currentLocationId,
			}),
	});

	useEffect(() => {
		if (getServingQuery.isError && onError) onError(getServingQuery.error);
	}, [getServingQuery.isError]);

	return getServingQuery;
};

export const GetWeeklyHistorySlice = (
	onError: (error: Error) => void = () => {}
) => {
	const currentLocationId = useUserStore((s) => s.currentLocationId);

	const getServingQuery = useQuery<WeeklyWaitlistHistoryResponse, Error>({
		queryKey: ["get-weekly-history", currentLocationId],
		queryFn: () =>
			APIVersion2GetWeeklyWaitlistHistory({
				location_id: currentLocationId,
			}),
	});

	useEffect(() => {
		if (getServingQuery.isError && onError) onError(getServingQuery.error);
	}, [getServingQuery.isError]);

	return getServingQuery;
};
