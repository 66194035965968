import useCustomToast from "@src/components/CustomToast";
import { APIVersion1UpdateTheme } from "@src/http/v1";
import useUserStore from "@src/store/useUserStore";
import { useMutation } from "react-query";

export const UpdateThemeSettings = (
	onSuccess: (data: unknown) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const customToast = useCustomToast();

	const user_id = useUserStore((s) => s.user?.business_id) ?? 0;
	return useMutation<unknown, Error, { theme: string }>(
		(data) => APIVersion1UpdateTheme(data, user_id),
		{
			onSuccess,

			onError: (error) => {
				onError(error);
				customToast("Theme could not be updated", {
					id: "update-theme",
					type: "error",
				});
			},
		}
	);
};
