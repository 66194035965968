import React, { useEffect } from "react";
import moment from "moment-timezone";
import { zodResolver } from "@hookform/resolvers/zod";
import LocationInfo from "../components/LocationInfo";
import { Button } from "@components/ui/button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SubmitHandler, useForm, FormProvider } from "react-hook-form";
import {
	EditLocationInfoData,
	EditLocationInfoSchema,
} from "@src/types/Location";
// import { defaultTimeSlots } from "@utils/constants";
// import { isTimeOverlapping } from "@utils/functions";
import {
	GetLocationDetailsSlice,
	UpdateBusinessLocationSlice,
} from "@src/store/slices/locations/locationSlice";
import RequestIsLoading from "@components/RequestIsLoading";
import Header from "@src/layouts/Header/Header";

export default function LocationTabList() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const locationId = searchParams.get("locationId") || "";

	useEffect(() => {
		if (!locationId) navigate("/dashboard/locations");
	}, [navigate, locationId]);

	const { data, isLoading } = GetLocationDetailsSlice(
		Number(parseInt(locationId))
	);

	const updateBusinessLocationMutation = UpdateBusinessLocationSlice(
		() => {
			navigate("/dashboard/locations");
		},
		(error) => console.log("🚀 ~ LocationTabList ~ error:", error)
	);

	const methods = useForm<EditLocationInfoData>({
		resolver: zodResolver(EditLocationInfoSchema),
		defaultValues: {
			name: "",
			state: "",
			address: "",
			city: "",
			country: "",
			image: "",
			phone_number: "",
			time_zone: "",
		},
	});

	const {
		handleSubmit,
		reset,
		formState: { errors, isValid },
	} = methods;
	// console.log("🚀 ~ LocationTabList ~ errors, isValid:", errors, isValid);

	useEffect(() => {
		if (locationId && !isLoading && data) {
			reset({
				name: data?.data?.name || "",
				state: data.data.state || "",
				address: data.data.address || "",
				city: data.data.city || "",
				country: data.data.country || "",
				image: data.data.image_url || "",
				phone_number: data.data.phone_number || "",
				time_zone: data.data.time_zone || "",
			});
		}
	}, [navigate, locationId, isLoading, data, reset]);

	const handleEditLocation: SubmitHandler<EditLocationInfoData> = async (
		data
	) => {
		if (isValid) {
			// const body: EditLocationInfoData = {
			// 	name: data.name,
			// 	state: data.state,
			// 	address: data.address,
			// 	city: data.city,
			// 	country: data.country,
			// };

			if ((data?.approximate_waiting_time ?? "").length <= 0)
				data.approximate_waiting_time = "15";

			const hours = Math.floor(
				+(data.approximate_waiting_time ?? 0) / 60
			);
			const minutes = +(data.approximate_waiting_time ?? 0) % 60;

			const newFormData = new FormData();
			newFormData.append("name", data?.name || "");
			newFormData.append("state", data?.state || "");
			newFormData.append("address", data.address || "");
			newFormData.append("city", data.city || "");
			newFormData.append("country", data.country || "");
			newFormData.append("time_zone", data.time_zone || "");
			newFormData.append(
				"approximate_waiting_time",
				`${String(hours).padStart(2, "0")}:${String(
					data.approximate_waiting_time ? minutes : 30
				).padStart(2, "0")}:00`
			);
			newFormData.append("time_zone", data.time_zone || "");
			newFormData.append(
				"scheule_block_in_min",
				(data.scheule_block_in_min || 15).toString()
			);
			newFormData.append("auto_clearing_minutes", (15).toString());
			newFormData.append("is_auto_clearing_on", (1).toString());
			// if (data.image) newFormData.append("image", data.image);

			updateBusinessLocationMutation.mutate({
				id: parseInt(locationId),
				data: newFormData,
			});
			return;
		}
	};

	return (
		<main className="flex flex-1 flex-col">
			<Header
				title={"Edit Location"}
				showDate={false}
				canGoBack
				titleContent={
					<div className="flex items-center space-x-1">
						<Button
							variant={"link"}
							className="contents p-0 text-base font-medium leading-none text-main-7 hover:no-underline"
							onClick={() => navigate("/dashboard/locations")}
						>
							{"Location > "}
						</Button>

						<h2 className="text-base font-medium text-primary">
							{"Edit Location"}
						</h2>
					</div>
				}
			/>
			<div className="flex flex-1 flex-col overflow-auto">
				<FormProvider {...methods}>
					<RequestIsLoading
						isLoading={
							updateBusinessLocationMutation.isLoading ||
							isLoading
						}
					/>
					<form onSubmit={handleSubmit(handleEditLocation)}>
						<div className="mx-auto w-full max-w-[804px] flex-1 space-y-6">
							<LocationInfo />
						</div>
						<div className="mx-auto flex w-full max-w-[804px] justify-end gap-2 py-4">
							<Button
								type="button"
								onClick={() => navigate("/dashboard/locations")}
								variant={"ghost"}
							>
								Cancel
							</Button>
							<Button type="submit" className="px-9">
								Save
							</Button>
						</div>
					</form>
				</FormProvider>
			</div>
		</main>
	);
}
