import { z } from "zod";

export const FirstTimeOscarSchema = z.object({
	emr_base_url: z.string().url(),
});

export const FirstTimeOscarAddLinksIDSchema = z.object({
	client_id: z.string().min(1, {
		message: "Client ID too short",
	}),
	client_secret: z.string().min(1, {
		message: "Client secret too short",
	}),
});

export type FirstTimeOscarType = z.infer<typeof FirstTimeOscarSchema>;
export type FirstTimeOscarAddLinksIDType = z.infer<
	typeof FirstTimeOscarAddLinksIDSchema
>;

export interface OscarDetailsType {
	emr_base_url?: string;
	client_id?: string;
	client_secret?: string;
	detail?: string;
	created_at?: string | Date;
}
