import {
	APIVersion1GetAdminAppointments,
	APIVersion1GetAdminLocationInformationForScheduling,
	APIVersion1GetStationAvailableTimeSlotsForSchedule,
	APIVersion1AdminCreateAppointment,
	APIVersion1AdminUpdateAppointment,
	APIVersion1GetAdminAppointmentDetails,
} from "@src/http/v1";
import useAppointmentStore from "@src/store/useAppointmentStore";
import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const GetLocationDetailsForAppointment = (
	locationId: number,
	onError: (error: Error) => void = () => {}
) => {
	const setAppointmentLocationDetails = useAppointmentStore(
		(s) => s.setAppointmentLocationDetails
	);
	const query = useQuery<{ status: boolean; data: any }, Error>(
		["appointmentLocationDetails", locationId],
		() => APIVersion1GetAdminLocationInformationForScheduling(locationId),
		{
			onSuccess: (response) => {
				setAppointmentLocationDetails(response.data);
				// console.log(response.data);
			},
			onError,
		}
	);

	return { ...query };
};
export const GetAppointments = (locationId: number) => {
	const setAppointments = useAppointmentStore((s) => s.setAppointments);
	const queryClient = useQueryClient();
	const query = useQuery<{ status: boolean; data: any }, Error>(
		["appointments", locationId],
		() => APIVersion1GetAdminAppointments(locationId),
		{
			onSuccess: (response) => {
				setAppointments(response.data);
			},
			onError: (error) => {
				console.error(error);
			},
			refetchOnMount: true,
		}
	);
	useEffect(() => {
		queryClient.invalidateQueries(["appointments", locationId], {
			refetchActive: true, // Refetch if the query is already active
			refetchInactive: true, // Refetch if the query is inactive
		});
	}, [locationId, queryClient]);
	return { ...query };
};

export const GetAvailableTimeSlotsForStations = (
	locationId: number,
	onSuccess: (data) => void,
	onError: (error) => void
) => {
	return useMutation(
		(data: {
			station_ids: string[];
			appointment_type_id: number;
			duration: number;
			date: string | undefined;
		}) =>
			APIVersion1GetStationAvailableTimeSlotsForSchedule(
				locationId,
				data
			),
		{
			onSuccess: (response) => {
				onSuccess(response);
			},
			onError: (error) => {
				onError(error);
				console.error(error);
			},
		}
	);
};
export const CreateAppointment = (
	stationId: number,
	locationId: number,
	onSuccess: (data) => void,
	onError: (error) => void
) => {
	const { setAppointmentSubmissionDetails, setAppointmentLocationDetails } =
		useAppointmentStore();
	const queryClient = useQueryClient();
	return useMutation(
		(data: {
			full_name: string;
			phone_number: string;
			email: string;
			custom_intakes?: any;
			date: string;
			start_time: string;
			appointment_type_id: number;
		}) => APIVersion1AdminCreateAppointment(stationId, data),
		{
			onSuccess: (response) => {
				onSuccess(response);
				setAppointmentSubmissionDetails(response.data);
				setAppointmentLocationDetails([]);
				queryClient.invalidateQueries(["appointments", locationId], {
					refetchActive: true, // Refetch if the query is already active
					refetchInactive: true, // Refetch if the query is inactive
				});
				// console.log(response.data);
			},
			onError: (error) => {
				onError(error);
				console.error(error);
			},
		}
	);
};

export const UpdateAppointment = (
	locationId: number,
	appointmentId: number,
	onSuccess: (data) => void,
	onError: (error) => void
) => {
	const queryClient = useQueryClient();
	return useMutation(
		(data: {
			action:
				| "done"
				| "cancel"
				| "reschedule"
				| "follow_up"
				| "approve"
				| "serve"
				| "remind";
			reschedule_date?: string;
			reschedule_time?: string;
		}) => APIVersion1AdminUpdateAppointment(appointmentId, data),
		{
			onSuccess: (response) => {
				onSuccess(response);
				console.log(response.data);
				// setAppointmentSubmissionDetails(response.data);
				queryClient.invalidateQueries(["appointments", locationId], {
					refetchActive: true, // Refetch if the query is already active
					refetchInactive: true, // Refetch if the query is inactive
				});
				// console.log(response.data);
			},
			onError: (error) => {
				onError(error);
				console.error(error);
			},
		}
	);
};

export const useGetAppointmentDetails = (
	appointmentId: number,
	onError: (error: Error) => void = () => {}
) => {
	const query = useQuery<{ status: boolean; data: any }, Error>(
		["appointmentLocationDetails", appointmentId],
		() => APIVersion1GetAdminAppointmentDetails(appointmentId),
		{
			onError,
		}
	);
	return { ...query };
};
