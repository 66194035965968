import React, { useEffect } from "react";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
	DialogFooter,
} from "@src/components/ui/dialog";
import { Label } from "@src/components/ui/label";
import { Button } from "@src/components/ui/button";
import FormInput from "@components/form/FormInput";
import { SubmitHandler, useForm } from "react-hook-form";
import { getErrorMessage } from "@src/utils/functions";
import { Input } from "@src/components/ui/input";
import {
	AppointmentTypeProps,
	AppointmentTypeSchema,
	CustomTimeSlotProps,
} from "@src/types/Location";
import { zodResolver } from "@hookform/resolvers/zod";
import { GetStationDetailsSlice } from "@src/store/slices/locations/locationSlice";
import { useSearchParams } from "react-router-dom";
import { cn } from "@src/utils/functions";
import {
	AddCustomTimeSlotSlice,
	UpdateCustomTimeSlotSlice,
} from "@src/store/slices/custom-intake/customTimeSlotSlice";
import useStationStore from "@src/store/useStationStore";
import Loader from "@src/components/Loader/Loader";

interface AddFieldsProps {
	modalMode: string | undefined;
	setModalMode: React.Dispatch<React.SetStateAction<string | undefined>>;
	selectedSlot: CustomTimeSlotProps | null;
}

const AddNewAppointmentTypeModal: React.FC<AddFieldsProps> = ({
	setModalMode,
	modalMode,
	selectedSlot,
}) => {
	const [searchParams] = useSearchParams();
	const stationId = searchParams.get("stationId") || "";
	const stationData = useStationStore((s) => s.stationDetails);

	const toggleModal = (status: boolean, isEditMode: boolean = false) => {
		setModalMode(status ? (isEditMode ? "edit-add" : "add") : "");
	};

	const { invalidateQuery } = GetStationDetailsSlice(
		Number(parseInt(stationId))
	);

	const addCustomIntake = AddCustomTimeSlotSlice(() => {
		invalidateQuery();
		toggleModal(false);
		reset({
			title: "",
			time_in_minute: 30,
		});
	});

	const updateCustomTimeSlot = UpdateCustomTimeSlotSlice(() => {
		invalidateQuery();
		toggleModal(false);
		reset({
			id: 0,
			title: "",
			time_in_minute: 30,
		});
	});

	const methods = useForm<AppointmentTypeProps>({
		resolver: zodResolver(AppointmentTypeSchema),
		defaultValues: {
			id: 0,
			title: "",
			time_in_minute: 30,
		},
	});

	const {
		handleSubmit,
		reset,
		setValue,
		watch,
		register,
		formState: { errors, isValid },
	} = methods;

	const handleDialogSubmit: SubmitHandler<AppointmentTypeProps> = (data) => {
		if (modalMode === "edit") {
			updateCustomTimeSlot.mutate({
				data: {
					...data,
					apply_to_option: {
						apply_to_all: 0,
						locations: [
							{
								id: stationData?.location_id || 0,
								update_location: 0,
								apply_to_all_stations: 0,
								selected_stations: [stationData?.id || 0],
							},
						],
					},
				},
				customFieldId: data.id || 0,
			});
		} else {
			addCustomIntake.mutate({
				data: {
					...data,
					apply_to_option: {
						apply_to_all: 0,
						locations: [
							{
								id: stationData?.location_id || 0,
								update_location: 0,
								apply_to_all_stations: 0,
								selected_stations: [stationData?.id || 0],
							},
						],
					},
				},
			});
		}
	};

	useEffect(() => {
		if (modalMode === "edit") {
			reset({
				id: selectedSlot?.id || 0,
				title: selectedSlot?.title || "",
				time_in_minute: selectedSlot?.time_in_minute || 30,
			});
		}
	}, [reset, selectedSlot, modalMode]);

	return (
		<Dialog
			open={modalMode === "add" || modalMode === "edit"}
			onOpenChange={(e: boolean) => toggleModal(e)}
		>
			<DialogTrigger asChild>
				<Button
					variant={"link"}
					className="h-auto rounded-none border-b-2 border-primary p-0 font-semibold leading-none outline-none hover:no-underline"
				>
					Add New Appointment Type
				</Button>
			</DialogTrigger>
			<DialogContent aria-describedby="dialog-description">
				<DialogHeader>
					<DialogTitle className="text-center">
						{modalMode === "edit"
							? "Edit New Appointment Type"
							: "Add New Appointment Type"}
					</DialogTitle>
					<DialogDescription></DialogDescription>
				</DialogHeader>
				<form
					className="mt-4 grid gap-4"
					onSubmit={handleSubmit(handleDialogSubmit)}
				>
					<FormInput
						inputType={"text"}
						label="Name Appointment Type"
						placeholder="Enter Name Here"
						register={{ ...register("title") }}
						className="h-10"
						error={getErrorMessage(errors.title?.message)}
					/>
					<div className="flex flex-col space-y-2">
						<Label className="text-sm font-medium tracking-[-0.1px] text-[#323539]">
							Time
						</Label>
						<div
							className={cn(
								"flex h-10 items-center rounded-md border border-input py-3 focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2"
							)}
						>
							<Input
								className="remove-number-input-arrow h-full border-none border-[#E5E5E7] px-8 py-3 text-right text-[#323539] placeholder:text-[14px] placeholder:text-[#323539]/50"
								value={watch("time_in_minute")}
								onChange={(e) => {
									setValue(
										"time_in_minute",
										parseInt(e.target.value)
									);
								}}
								min={1}
								type="number"
							/>
							<p className="border-l border-[#E5E5E7] px-5 text-[14px] font-medium leading-[20px] text-[#323539]">
								minutes
							</p>
						</div>
					</div>
					<DialogFooter>
						<div className="mt-2 flex justify-end space-x-3 pt-3">
							<Button
								variant={"outline"}
								type="button"
								className=" self-center rounded-md"
								onClick={() => setModalMode(undefined)}
							>
								Cancel
							</Button>
							<Button
								type="submit"
								disabled={!isValid}
								className="min-w-[90px] rounded-md"
							>
								{addCustomIntake.isLoading ||
								updateCustomTimeSlot.isLoading ? (
									<Loader size={20} />
								) : modalMode === "edit" ? (
									"Update"
								) : (
									"Add"
								)}
							</Button>
						</div>
					</DialogFooter>
				</form>
			</DialogContent>
		</Dialog>
	);
};

export default AddNewAppointmentTypeModal;
