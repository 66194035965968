import React, { useState } from "react";
import CustomCheckbox2 from "@src/components/form/CustomCheckbox2";
import { Button } from "@src/components/ui/button";
import { Input } from "@src/components/ui/input";
import { CustomSelect } from "@components/form/CustomSelect";
import { useFormContext } from "react-hook-form";
import FormInput from "@src/components/form/FormInput";
import { getErrorMessage, transformText } from "@src/utils/functions";
import { set } from "date-fns";
import { DeleteTeamMemberSlice } from "@src/store/slices/team-members/teamMembersSlice";
import { GetLocationStationsSlice } from "@src/store/slices/locations/locationSlice";
import Loader from "@src/components/Loader/Loader";
import { DeleteLocationModal } from "./DeleteLocationModal";
import { useQueryClient } from "react-query";

interface MemberListProps {
	member: Record<string, any>;
	role: any[];
	locationId: number;
}

const MemberList: React.FC<MemberListProps> = ({
	member,
	role,
	locationId,
}) => {
	const queryClient = useQueryClient();
	const [isEdit, setIsEdit] = useState(false);
	const [showDeleteTeamMemberModal, setShowDeleteTeamMemberModal] =
		useState(false);

	const {
		watch,
		control,
		register,
		getValues,
		setValue,
		reset,
		formState: { errors },
	} = useFormContext();

	const handleEdit = () => {
		reset(member);
		setIsEdit(true);
	};

	// const { data, isLoading } = GetLocationStationsSlice(locationId);
	const {
		isLoading: deleteTeamMemberLoading,
		mutate: deleteTeamMemberMutation,
	} = DeleteTeamMemberSlice(
		() => {
			queryClient.invalidateQueries(["get-available-team-member"]);
			queryClient.invalidateQueries([
				"get-team-members",
				{ location_Id: locationId },
			]);
			setShowDeleteTeamMemberModal(false);
		},
		() => null
	);

	const handleDeleteTeamMember = () => {
		deleteTeamMemberMutation({
			userId: member.id,
		});
	};

	return (
		<div className="flex cursor-pointer items-center justify-between rounded-lg border p-2 text-sm hover:bg-slate-50">
			<div className="flex w-[25%] items-center space-x-2 pr-2">
				{/* user_security_line */}
				<i className="mgc_user_security_line text-[16px] text-primary"></i>
				{!isEdit ? (
					<p>{transformText(member.name || "")} </p>
				) : (
					<FormInput
						inputType={"text"}
						label=""
						register={{ ...register("name") }}
						className="h-10"
						error={getErrorMessage(errors.name?.message)}
					/>
				)}
			</div>
			<div className="flex w-[35%] items-center space-x-3 divide-x pr-2">
				{!isEdit ? (
					<p className="flex-1">{transformText(member.role || "")}</p>
				) : (
					<div className="flex-1">
						<CustomSelect
							placeholder="Select"
							label="Role  *"
							className="h-10"
							options={role}
							defaultValue={getValues("role")}
							value={watch("role")}
							onChange={(value) => {
								setValue("role", value);
							}}
							error={getErrorMessage(errors.role?.message)}
						/>
					</div>
				)}
				{!isEdit ? (
					<p className="flex-1 pl-3 text-gray-400">All Station</p>
				) : (
					<div className="flex-1 pl-3">
						<CustomSelect
							placeholder="Select"
							label="Role  *"
							className="h-10"
							options={role}
							defaultValue={getValues("role")}
							value={watch("role")}
							onChange={(value) => {
								setValue("role", value);
							}}
							error={getErrorMessage(errors.role?.message)}
						/>
					</div>
				)}
			</div>
			<div className="w-[15%] pr-2 text-gray-400">
				{!isEdit ? (
					<p>{member.phone_number}</p>
				) : (
					<FormInput
						inputType={"text"}
						label=""
						register={{ ...register("phone_number") }}
						className="h-10"
						error={getErrorMessage(errors.phone_number?.message)}
					/>
				)}
			</div>
			{/* <div className="w-[15%]">Waitlist schedule</div> */}
			<div className="flex w-[25%] items-center justify-end space-x-2">
				{!isEdit ? (
					<p className="text-gray-400">{member.email}</p>
				) : (
					<div>
						<FormInput
							inputType={"email"}
							label=""
							register={{ ...register("email") }}
							className="h-10"
							error={getErrorMessage(errors.email?.message)}
						/>
					</div>
				)}
				{isEdit && (
					<>
						<Button
							variant="secondary"
							size="icon-sm"
							onClick={() => setIsEdit(false)}
						>
							<i className="mgc_close_line text-[16px] text-primary"></i>
						</Button>
						<Button
							variant="default"
							size="icon-sm"
							className="text-white"
							// onClick={() => setShowClearQueueModal(true)}
						>
							<i className="mgc_save_2_line text-[16px]"></i>
						</Button>
					</>
				)}
				{!isEdit && (
					<Button
						variant="secondary"
						size="icon-sm"
						onClick={handleEdit}
					>
						<i className="mgc_edit_2_line text-[16px] text-primary"></i>
					</Button>
				)}
				<Button
					variant="secondary"
					size="icon-sm"
					onClick={() => setShowDeleteTeamMemberModal(true)}
				>
					<div className="">
						{/* {deleteTeamMemberLoading ? (
							<Loader size={20} />
						) : (
						)} */}
						<i className="mgc_delete_2_line text-[16px] text-primary"></i>
					</div>
				</Button>
			</div>
			<DeleteLocationModal
				locationName={member?.name || ""}
				showModal={showDeleteTeamMemberModal}
				setShowModal={setShowDeleteTeamMemberModal}
				isLoading={deleteTeamMemberLoading}
				buttonAction={handleDeleteTeamMember}
				DeleteContent={DeleteStationContent}
				buttonText="Yes, delete member"
			/>
		</div>
	);
};

export default MemberList;

const DeleteStationContent = ({ displayString }: { displayString: string }) => {
	return (
		<div className="space-y-4">
			<p className="mt-2 text-sm leading-[20px] -tracking-[0.1px] text-[#6D748D]">
				Are you sure you want to delete{" "}
				<span className="font-semibold">
					&quot;{displayString}?&quot;
				</span>
				{". "}
			</p>
			<p className="mt-2 text-sm leading-[20px] -tracking-[0.1px] text-[#6D748D]">
				This action cannot be undone.
			</p>
		</div>
	);
};
