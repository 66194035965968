import { zodResolver } from "@hookform/resolvers/zod";
import { LoaderButton } from "@src/components/form/LoaderButton";
import { Button } from "@src/components/ui/button";
import { Input } from "@src/components/ui/input";
import { Label } from "@src/components/ui/label";
import { AccountChangePassword } from "@src/store/slices/settings/accountSettingsSlice";
import {
	ChangePasswordSchema,
	ChangePasswordType,
} from "@src/types/settings/account";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";

const ChangePassword: React.FC = () => {
	const changeAccountPasswordMutation = AccountChangePassword(() => reset());

	const initialFormState = {
		password: "",
		password_confirmation: "",
	};

	const {
		register,
		handleSubmit,
		setError,
		reset,
		formState: { errors },
	} = useForm<ChangePasswordType>({
		resolver: zodResolver(ChangePasswordSchema),
		defaultValues: initialFormState,
	});

	const [isEditing, setIsEditing] = React.useState(false);

	const onSubmit: SubmitHandler<ChangePasswordType> = async (data) => {
		try {
			changeAccountPasswordMutation.mutate(data);
		} catch (error) {
			setError("root", {
				message: "An error occured kindly try again later",
			});
		}
	};

	return (
		<section>
			<h2 className="text-[22px] font-semibold leading-[24px] tracking-[-1%] text-[#323539]">
				Password and authentication
			</h2>
			<form
				className="mt-3 grid grid-cols-2 gap-4"
				onSubmit={handleSubmit(onSubmit)}
			>
				<div className="col-span-2 flex flex-col space-y-1.5">
					<Label>
						Current Password <span className="text-red-500">*</span>
					</Label>
					<Input
						className="border border-[#E4E4E7] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
						type="password"
						{...register("password")}
						disabled={!isEditing}
					/>
					{errors.password && errors?.password?.message?.length && (
						<small className="mt-1.5 text-sm leading-[16px] text-red-500">
							{errors.password.message}
						</small>
					)}
				</div>
				<div className="col-span-2 flex flex-col space-y-1.5">
					<Label>
						New Password <span className="text-red-500">*</span>
					</Label>
					<Input
						className="border border-[#E4E4E7] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
						type="password"
						{...register("password")}
						disabled={!isEditing}
					/>
					{errors.password && errors?.password?.message?.length ? (
						<small className="mt-1.5 text-sm leading-[16px] text-red-500">
							{errors.password.message}
						</small>
					) : (
						<small className="mt-1.5 text-sm leading-[16px] text-[#71717A]">
							Password must be 8+ characters with an uppercase
							letter, lowercase letter, number, and special
							character.
						</small>
					)}
				</div>
				<div className="col-span-2 flex flex-col space-y-1.5">
					<Label>
						Confirm Password <span className="text-red-500">*</span>
					</Label>
					<Input
						className="border border-[#E4E4E7] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
						type="password"
						{...register("password_confirmation")}
						disabled={!isEditing}
					/>
					{errors.password_confirmation &&
						errors?.password_confirmation?.message?.length && (
							<small className="mt-1.5 text-sm leading-[16px] text-red-500">
								{errors.password_confirmation.message}
							</small>
						)}
				</div>
				<div className="col-span-2 ml-auto space-x-4">
					{isEditing ? (
						<>
							<Button
								variant="outline"
								className="shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
								type="button"
								disabled={
									changeAccountPasswordMutation.isLoading
								}
								onClick={(e) => {
									e.preventDefault();
									setIsEditing(false);
									reset();
								}}
							>
								Cancel
							</Button>
							<LoaderButton
								className="text-white"
								disabled={
									changeAccountPasswordMutation.isLoading
								}
								loading={
									changeAccountPasswordMutation.isLoading
								}
								loaderSize={20}
								type="submit"
							>
								Save
							</LoaderButton>
						</>
					) : (
						<Button
							className="text-white"
							type="button"
							onClick={(e) => {
								e.preventDefault();
								setIsEditing(true);
							}}
						>
							Update
						</Button>
					)}
				</div>
			</form>
		</section>
	);
};

export default ChangePassword;
