import { useEffect, useState } from "react";
import { Switch } from "../../../components/ui/switch";
import { Label } from "../../../components/ui/label";
import useUserStore from "@/src/store/useUserStore";
import {
	GetQueueSettingsSlice,
	QueueSettingsSlice,
} from "@/src/store/slices/locations/locationSlice";

const AutoApprove = () => {
	const [isAutoApprove, setIsAutoApprove] = useState<boolean>(false);
	const { currentLocationId } = useUserStore();
	const { mutate } = QueueSettingsSlice();

	const { data } = GetQueueSettingsSlice({
		location_id: currentLocationId,
	});

	useEffect(() => {
		if (data) {
			setIsAutoApprove(
				data?.data.schedule_setting?.auto_approve == 1
					? true
					: false || false
			);
		}
	}, [data]);

	const handleToggle = () => {
		const newAutoApproveState = !isAutoApprove;
		setIsAutoApprove(newAutoApproveState);

		mutate({
			data: {
				schedule_setting: {
					auto_approve: newAutoApproveState ? 1 : 0,
				},
				apply_to_option: {
					apply_to_all: 0,
					locations: [
						{
							id: currentLocationId,
							update_location: 1,
							apply_to_all_stations: 0,
							selected_stations: [],
						},
					],
				},
			},
			queryParams: {
				location_id: currentLocationId,
			},
		});
	};

	return (
		<div className="flex flex-col items-center xl:flex-row ">
			<p className="text-sm font-medium text-[#323539]">Auto Approve</p>
			<Switch
				checked={isAutoApprove}
				id="queue-on-off"
				onClick={handleToggle}
				className="scale-[0.6] text-sm data-[state=checked]:bg-[#2AA63C] data-[state=unchecked]:bg-gray-300"
			/>
			<Label htmlFor="automatic-approve" className="text-sm">
				{isAutoApprove ? (
					<span className=" font-bold text-[#2AA63C]">ON</span>
				) : (
					<span className=" font-bold text-[#C1C1C1]">OFF</span>
				)}
			</Label>
		</div>
	);
};

export default AutoApprove;
