import Checkbox from "@src/components/ui-extended/checkbox";
import { Button } from "@src/components/ui/button";
import {
	DeleteCustomFieldsSlice,
	GetCustomFieldsSlice,
} from "@src/store/slices/customization/customFieldsSlice";
import { useState } from "react";
import { DeleteLocationModal } from "./DeleteLocationModal";

interface IntakeFieldListProps {
	fieldData: any;
	handleFieldListAction: (action: string, fieldId: string) => void;
	customFieldsIds: number[];
	setCustomFieldsIds: React.Dispatch<React.SetStateAction<number[]>>;
}

const IntakeFieldList: React.FC<IntakeFieldListProps> = ({
	fieldData,
	handleFieldListAction,
	customFieldsIds,
	setCustomFieldsIds,
}) => {
	const [showDeleteCustomFieldsModal, setShowDeleteCustomFieldsModal] =
		useState(false);
	// const [isEdit, setIsEdit] = useState(false);

	const { invalidateQuery } = GetCustomFieldsSlice({});

	const {
		isLoading: deleteCustomFieldLoading,
		mutate: deleteCustomFieldMutation,
	} = DeleteCustomFieldsSlice(
		() => {
			invalidateQuery();
			setShowDeleteCustomFieldsModal(false);
		},
		() => null
	);

	const handleDeleteCustomField = () => {
		deleteCustomFieldMutation({
			id: fieldData.id,
		});
	};

	return (
		<div className="flex items-center justify-between rounded-lg border border-[#E5E5E7] p-2 text-sm text-gray-400">
			<div className={`flex w-[40%] items-center space-x-2 pr-2`}>
				<Checkbox
					isChecked={customFieldsIds.includes(fieldData?.id)}
					handleCheckboxChange={() =>
						setCustomFieldsIds((prev: any) => {
							if (prev.includes(fieldData.id)) {
								return prev.filter((id) => id !== fieldData.id);
							} else {
								return [...prev, fieldData.id];
							}
						})
					}
					className="rounded-sm border-2 border-[#E5E5E7]"
					id={""}
				/>
				<label
					htmlFor="terms"
					className="text-sm font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
				>
					{fieldData?.name}
				</label>
			</div>
			<div className="flex w-[35%] items-center justify-end space-x-2 capitalize">
				<div className={`flex-1 pr-2 capitalize`}>
					<p>{fieldData?.type}</p>
				</div>
				<Button
					type="button"
					variant="secondary"
					size="icon-sm"
					onClick={() => handleFieldListAction("edit-add", fieldData)}
				>
					<i className="mgc_edit_2_line text-[16px] text-primary"></i>
				</Button>
				<Button
					type="button"
					variant="secondary"
					size="icon-sm"
					onClick={() => handleFieldListAction("edit-tag", fieldData)}
				>
					<i className="mgc_tag_line text-[16px] text-primary"></i>
				</Button>
				<Button
					type="button"
					variant="secondary"
					size="icon-sm"
					onClick={() => setShowDeleteCustomFieldsModal(true)}
				>
					<i className="mgc_delete_2_line text-[16px] text-primary"></i>
				</Button>
			</div>
			<DeleteLocationModal
				locationName={fieldData?.name || ""}
				showModal={showDeleteCustomFieldsModal}
				setShowModal={setShowDeleteCustomFieldsModal}
				isLoading={deleteCustomFieldLoading}
				buttonAction={handleDeleteCustomField}
				DeleteContent={DeleteCustomFieldsContent}
				buttonText="Yes, delete custom field"
			/>
		</div>
	);
};

export default IntakeFieldList;

const DeleteCustomFieldsContent = ({
	displayString,
}: {
	displayString: string;
}) => {
	return (
		<div className="space-y-4">
			<p className="mt-2 text-sm leading-[20px] -tracking-[0.1px] text-[#6D748D]">
				Are you sure you want to delete{" "}
				<span className="font-semibold">
					&quot;{displayString}?&quot;
				</span>
				{". "}
			</p>
			<p className="mt-2 text-sm leading-[20px] -tracking-[0.1px] text-[#6D748D]">
				This action cannot be undone.
			</p>
		</div>
	);
};
