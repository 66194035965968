import { DeleteManyTeamMemberSlice } from "@/src/store/slices/team-members/teamMembersSlice";
import { GetTeamMembersResponse, TeamMember } from "@/src/types/TeamMember";
import { LoaderButton } from "@src/components/form/LoaderButton";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogTitle,
} from "@src/components/ui/dialog";
import React from "react";
import { LuX } from "react-icons/lu";
import useCustomToast from "../../CustomToast";
import { Button } from "../../ui/button";
import { useQueryClient } from "react-query";

const DeleteManyTeamMemberModal: React.FC<{
	selectedRows: number[];
	showManyDeleteTeamMemberModal: boolean;
	setShowManyDeleteTeamMemberModal: React.Dispatch<
		React.SetStateAction<boolean>
	>;
	queryParams: Record<string, string | number | undefined>;
}> = ({
	selectedRows,
	queryParams,
	showManyDeleteTeamMemberModal,
	setShowManyDeleteTeamMemberModal,
}) => {
	const customToast = useCustomToast();
	const queryClient = useQueryClient();

	const deleteManyTeamMemberMutation = DeleteManyTeamMemberSlice(
		() => {
			queryClient.setQueryData<GetTeamMembersResponse | undefined>(
				["get-team-members", queryParams],
				(prevData) => {
					if (!prevData) return undefined;
					return {
						...prevData,
						data: prevData.data.filter(
							(member) => !selectedRows.includes(member.id)
						),
					};
				}
			);
			queryClient.invalidateQueries(["get-team-members", queryParams]);
			setShowManyDeleteTeamMemberModal(false);
		},
		() => {
			// customToast("Team Member could not be deleted", {
			// 	id: "many-delete-team-member",
			// 	type: "error",
			// });
		}
	);

	return (
		<Dialog
			open={showManyDeleteTeamMemberModal}
			onOpenChange={(newState) => {
				if (!deleteManyTeamMemberMutation.isLoading)
					setShowManyDeleteTeamMemberModal(newState);
			}}
		>
			<DialogContent className="max-w-[360px] p-4">
				<div className="flex justify-between space-x-2">
					<i className="mgc_user_x_line py-1 text-[20px] before:!text-[#C8322B]" />
					<div className="flex flex-col">
						<DialogTitle className="text-[22px] font-semibold capitalize leading-[30px] -tracking-[1%] text-[#323539]">
							Delete {selectedRows.length} team members
						</DialogTitle>
						<p className="trakcing-[-0.1px] text-[14px] leading-[20px] text-[#6D748D]">
							Are you sure you want to delete{" "}
							{selectedRows.length} team member
							{selectedRows.length > 1 && "s"}? This action
							can&apos;t be undone.
						</p>
						<DialogFooter className="mt-6 space-x-1 sm:space-x-1">
							<Button
								variant="ghost"
								className="space-x-5 text-[#323539]"
								onClick={() => {
									if (!deleteManyTeamMemberMutation.isLoading)
										setShowManyDeleteTeamMemberModal(false);
								}}
								disabled={
									deleteManyTeamMemberMutation.isLoading
								}
							>
								Cancel
							</Button>
							<LoaderButton
								variant="destructive"
								className="px-5 text-white"
								onClick={() => {
									customToast(
										`Deleting Team Member${selectedRows.length > 1 && "s"}`,
										{
											id: "many-delete-team-member",
											type: "loading",
										}
									);
									deleteManyTeamMemberMutation.mutate({
										user_ids: selectedRows,
									});
								}}
								loading={deleteManyTeamMemberMutation.isLoading}
								disabled={
									deleteManyTeamMemberMutation.isLoading
								}
								loaderSize={20}
							>
								Delete
							</LoaderButton>
						</DialogFooter>
					</div>
					<button
						disabled={deleteManyTeamMemberMutation.isLoading}
						className="flex items-start"
						onClick={(e) => {
							e.preventDefault();
							if (!deleteManyTeamMemberMutation.isLoading)
								setShowManyDeleteTeamMemberModal(false);
						}}
					>
						<LuX
							color="#858C95"
							className="cursor-pointer"
							width="20px"
							height="20px"
						/>
					</button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default DeleteManyTeamMemberModal;
