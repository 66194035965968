import CustomSelect from "@src/components/common/CustomSelect";
import Checkbox from "@src/components/ui-extended/checkbox";
import { Button } from "@src/components/ui/button";
import { Card } from "@src/components/ui/card";
import { Input } from "@src/components/ui/input";
import { Select } from "@src/components/ui/select";
import IntakeFieldList from "./IntakeFieldList";
import {
	GetCustomFieldsSlice,
	CreateCustomFields,
	UpdateCustomFields,
	AddRemoveBulkCustomFieldSlice,
} from "@src/store/slices/customization/customFieldsSlice";
import {
	convertLocationData,
	displayErrorsWithTimeout,
} from "@src/utils/functions";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import useCustomToast from "@src/components/CustomToast";
import {
	CustomizationSchema,
	CustomizationProps,
	CustomizationTagSchema,
	AddCustomizationFieldSchema,
} from "@src/types/customization";
import AddFieldModal from "@src/pages/customization/components/AddFieldModal";
import TagFieldModal from "@src/pages/customization/components/TagFieldModal";
import { useSearchParams } from "react-router-dom";
import AddExistingCustomFieldModal from "./ScheduleSettings/AddExisitingCustomField";
import { DeleteLocationModal } from "./DeleteLocationModal";

interface IntakeFieldsProps {}

const IntakeFields: React.FC<IntakeFieldsProps> = () => {
	const [modalMode, setModalMode] = useState<string | undefined>(undefined);
	const [customFieldsIds, setCustomFieldsIds] = useState<number[]>([]);
	const [selectedCustomFieldId, setSelectedCustomFieldId] =
		useState<number>(0);
	const [searchParams] = useSearchParams();
	const locationId = searchParams.get("locationId") || "";
	const [checkAllCustomFields, setCheckAllCustomFields] = useState(false);
	const [showDeleteCustomFieldsModal, setShowDeleteCustomFieldsModal] =
		useState(false);
	const customToast = useCustomToast();

	const { data, isLoading, isSuccess, invalidateQuery } =
		GetCustomFieldsSlice({ location_id: locationId || 0 });

	const methods = useForm<CustomizationProps>({
		resolver: zodResolver(
			modalMode === "add" || modalMode === "edit-add"
				? AddCustomizationFieldSchema
				: CustomizationSchema
		),
		mode: "onChange",
		defaultValues: {
			name: "",
			subtitle: "",
			type: undefined,
			numeric_unit_title: "",
			info_text_value: "",
			long_text: 0,
			date_range: 0,
			date_range_value: null,
			options: ["Option 1"],
			allow_multiple: 0,
			image: "",
			approved_formats: [],
			apply_to: "all",
			field_requirement: "yes",
			apply_to_option: {
				apply_to_all: 0,
				locations: [],
			},
		},
	});

	const {
		reset,
		getValues,
		formState: { isValid, errors },
		handleSubmit,
	} = methods;

	const handleFieldListAction = (action: string, data: any) => {
		setModalMode(action);
		setSelectedCustomFieldId(data?.id);
		reset({
			name: data?.name || "",
			subtitle: data?.subtitle || "",
			type: data?.type || undefined,
			numeric_unit_title: data?.numeric_unit_title || "",
			info_text_value: data?.info_text_value || "",
			long_text: data?.long_text || 0,
			date_range: data?.date_range || 0,
			date_range_value: data?.date_range_value || null,
			options: data?.options || ["Option 1", "Option 2"],
			allow_multiple: data?.allow_multiple || 0,
			image: data?.image_url || "",
			approved_formats: data?.approved_formats || [],
			apply_to: data?.apply_to || "all",
			field_requirement: data?.field_requirement || "yes",
			apply_to_option: {
				apply_to_all: 0,
				locations:
					convertLocationData(data?.relations || [])?.locations || [],
			},
		});
	};

	const handleCheckAllCustomFields = () => {
		if (customFieldsIds?.length === data?.data.length) {
			setCustomFieldsIds([]);
			setCheckAllCustomFields(false);
		} else {
			setCustomFieldsIds(data?.data?.map((field: any) => field.id) || []);
			setCheckAllCustomFields(true);
		}
	};

	const {
		mutate: addRemoveBulkCustomFieldMutation,
		isLoading: addRemoveBulkCustomFieldLoading,
	} = AddRemoveBulkCustomFieldSlice(() => {
		invalidateQuery();
		setModalMode(undefined);
		setCustomFieldsIds([]);
		setCheckAllCustomFields(false);
		setShowDeleteCustomFieldsModal(false);
	});

	const handleRemoveCustomFields = () => {
		console.log("customFieldsIds", customFieldsIds);
		addRemoveBulkCustomFieldMutation({
			data: {
				location_id: parseInt(locationId) || 0,
				custom_field_ids_to_remove: customFieldsIds,
			},
			queryParams: {
				apply_to: "waitlist_only",
			},
		});
	};

	const {
		mutate: createCustomFieldsMutation,
		isLoading: createCustomFieldsLoading,
	} = CreateCustomFields(() => {
		invalidateQuery();
		setModalMode(undefined);
		reset({
			name: "",
			subtitle: "",
			type: undefined,
			numeric_unit_title: "",
			info_text_value: "",
			long_text: 0,
			date_range: 0,
			date_range_value: null,
			options: ["Option 1", "Option 2"],
			allow_multiple: 0,
			image: "",
			approved_formats: [],
			apply_to: "all",
			field_requirement: "yes",
			apply_to_option: {
				apply_to_all: 0,
				locations: [],
			},
		});
	});

	const {
		mutate: updateCustomFieldsMutation,
		isLoading: updateCustomFieldsLoading,
	} = UpdateCustomFields(() => {
		invalidateQuery();
		setModalMode(undefined);
		reset({
			name: "",
			subtitle: "",
			type: undefined,
			numeric_unit_title: "",
			info_text_value: "",
			long_text: 0,
			date_range: 0,
			date_range_value: null,
			options: ["Option 1", "Option 2"],
			allow_multiple: 0,
			image: "",
			approved_formats: [],
			apply_to: "all",
			field_requirement: "yes",
			apply_to_option: {
				apply_to_all: 0,
				locations: [],
			},
		});
	});

	const onSubmit = (data: CustomizationProps) => {
		if (isValid && modalMode === "add") {
			setModalMode("tag");
		} else if (isValid && modalMode === "edit-add") {
			setModalMode("edit-tag");
		}
		if (isValid && modalMode === "tag") {
			createCustomFieldsMutation(
				{
					data: data,
				},
				{
					onSuccess: () => {
						setModalMode(undefined);
					},
					onError: (error: any) => {
						displayErrorsWithTimeout(
							error.response.data?.error ||
								"An unexpected error occurred. Please try again later",
							customToast,
							{
								toastIdPrefix: "create-custom-fields",
							}
						);
					},
				}
			);
		}
		if (isValid && modalMode === "edit-tag") {
			updateCustomFieldsMutation(
				{
					data: data,
					id: selectedCustomFieldId,
				},
				{
					onSuccess: () => {
						setModalMode(undefined);
					},
					onError: (error: any) => {
						displayErrorsWithTimeout(
							error.response.data?.error ||
								"An unexpected error occurred. Please try again later",
							customToast,
							{
								toastIdPrefix: "create-custom-fields",
							}
						);
					},
				}
			);
		}
	};

	return (
		<div className="space-y-4 p-5">
			<Card className="flex justify-end space-y-2 p-4">
				{/* <div className="flex items-center justify-between">
					<p className="font-medium">
						Add from Existing Custom fields
					</p>
					<Button
						type="button"
						variant={"link"}
						className="h-auto rounded-none border-b-2 border-primary p-0 font-semibold leading-none outline-none hover:no-underline"
						onClick={() => setModalMode("add")}
					>
						Add new custom fields
					</Button>
				</div> */}
				{/* <CustomSelect placeholder="Select all the applies" /> */}
				<AddExistingCustomFieldModal
					modalMode={modalMode}
					setModalMode={setModalMode}
					updateMode={"location"}
				/>
			</Card>

			<div className="flex items-center justify-between">
				<div className="flex items-center space-x-2">
					<Checkbox
						isChecked={checkAllCustomFields}
						handleCheckboxChange={handleCheckAllCustomFields}
						className="rounded-sm border-2 border-[#D1D1D1]"
						id={""}
					/>
					<label className="font-normal text-gray-400">
						Select all
					</label>
				</div>
				<Button
					type="button"
					variant="secondary"
					onClick={() => setShowDeleteCustomFieldsModal(true)}
					disabled={customFieldsIds?.length === 0}
				>
					<i className="mgc_delete_2_line text-[16px] text-primary"></i>
				</Button>
				<DeleteLocationModal
					locationName={`Selected Custom Field${
						customFieldsIds?.length > 1 ? "s" : ""
					}`}
					showModal={showDeleteCustomFieldsModal}
					setShowModal={setShowDeleteCustomFieldsModal}
					isLoading={addRemoveBulkCustomFieldLoading}
					buttonAction={() => handleRemoveCustomFields()}
					DeleteContent={DeleteCustomFieldsContent}
					buttonText={`Yes, delete custom field${
						customFieldsIds?.length > 1 ? "s" : ""
					}`}
				/>
			</div>

			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="w-full space-y-2">
						<div className="flex justify-between rounded-t-lg border-b p-2 text-sm">
							<div className="flex w-[40%] items-center font-semibold">
								<p className="ml-2">Field Name</p>
							</div>
							<div className="w-[35%] font-semibold">Type</div>
						</div>
						{data?.data?.map((item: any) => (
							<IntakeFieldList
								key={item.id}
								fieldData={item}
								handleFieldListAction={handleFieldListAction}
								customFieldsIds={customFieldsIds}
								setCustomFieldsIds={setCustomFieldsIds}
							/>
						))}
					</div>
					<div className="hidden">
						<AddFieldModal
							modalMode={modalMode}
							setModalMode={setModalMode}
							onSubmit={methods.handleSubmit(onSubmit)}
						/>
						<TagFieldModal
							modalMode={modalMode}
							setModalMode={setModalMode}
							onSubmit={methods.handleSubmit(onSubmit)}
							isLoading={
								createCustomFieldsLoading ||
								updateCustomFieldsLoading
							}
						/>
					</div>
				</form>
			</FormProvider>
		</div>
	);
};

export default IntakeFields;

const DeleteCustomFieldsContent = ({
	displayString,
}: {
	displayString: string;
}) => {
	return (
		<div className="space-y-4">
			<p className="mt-2 text-sm leading-[20px] -tracking-[0.1px] text-[#6D748D]">
				Are you sure you want to delete{" "}
				<span className="font-semibold">
					&quot;{displayString}?&quot;
				</span>
				{". "}
			</p>
			<p className="mt-2 text-sm leading-[20px] -tracking-[0.1px] text-[#6D748D]">
				This action cannot be undone.
			</p>
		</div>
	);
};
