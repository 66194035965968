import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogFooter,
	DialogTitle,
	DialogTrigger,
} from "@components/ui/dialog";
import React, { useState } from "react";
import { Button } from "@components/ui/button";
import { Input } from "@src/components/ui/input";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "@src/components/ui/select";
import { countryCodes } from "@src/utils/constants";
import { LoaderButton } from "@src/components/form/LoaderButton";
import useUserStore from "@/src/store/useUserStore";
import { useGetBusinessLocationsAndStations } from "@/src/store/slices/locations/locationSlice";
import { createJoinWaitlistSchema } from "../CustomIntakeField";
import { AddPatientSlice } from "@/src/store/slices/waitlist/managePatientSlice";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { ModalProps } from "@/src/types";

const CustomerManualAdd: React.FC<ModalProps> = ({ show, setShow }) => {
	// const [showAddPatient, setShowAddPatient] = React.useState(false);
	// const [countryCode, setCountryCode] = React.useState("+1");

	const { user, currentStationId } = useUserStore((s) => ({
		user: s.user,
		currentStationId: s.currentStationId,
	}));
	const getBusinessLocationsAndStationsQuery =
		useGetBusinessLocationsAndStations();

	const [countryCode, setCountryCode] = useState("+1");
	const [selectedLocationId, setSelectedLocationId] = useState<
		string | undefined
	>(user?.business.locations[0].id.toString() ?? undefined);
	const [selectedStationId, setSelectedStationId] = useState<
		string | number | undefined
	>(currentStationId);
	const [lastName, setLastName] = useState("");
	const [schema, setSchema] = React.useState(createJoinWaitlistSchema([]));

	const addPatientMutation = AddPatientSlice(() => {
		setShow(false);
		reset();
	});

	const {
		register,
		handleSubmit,
		control,
		setError,
		reset,
		getValues,
		formState: { errors },
	} = useForm<z.infer<typeof schema>>({
		resolver: zodResolver(schema),
	});

	const onSubmit: SubmitHandler<z.infer<typeof schema>> = async (data) => {
		try {
			const station =
				getBusinessLocationsAndStationsQuery.data?.data.locations
					.find(
						(location) =>
							location.id === parseInt(selectedLocationId ?? "0")
					)
					?.stations.find(
						(station) =>
							station.id.toString() ===
							(selectedStationId === "All Stations"
								? undefined
								: selectedStationId?.toString())
					) ?? undefined;
			console.log(station, "station");
			addPatientMutation.mutate({
				...data,
				full_name: (data.full_name + " " + lastName).trim(),
				phone_number: countryCode + data.phone_number,
				station_id: data?.station_id ?? 0,
			});
		} catch (error) {
			setError("root", {
				message: "An error occured kindly try again later",
			});
		}
	};

	React.useEffect(() => {
		if (
			getBusinessLocationsAndStationsQuery.data?.data.locations &&
			getBusinessLocationsAndStationsQuery.data?.data.locations
				.find(
					(location) =>
						location.id === parseInt(selectedLocationId ?? "0")
				)
				?.stations.find(
					(station) =>
						station.id.toString() ===
						(selectedStationId === "All Stations"
							? undefined
							: selectedStationId?.toString())
				)?.waitlist_custom_fields
		) {
			const newSchema = createJoinWaitlistSchema(
				(getBusinessLocationsAndStationsQuery.data?.data.locations &&
					getBusinessLocationsAndStationsQuery.data?.data.locations
						.find(
							(location) =>
								location.id ===
								parseInt(selectedLocationId ?? "0")
						)
						?.stations.find(
							(station) =>
								station.id.toString() ===
								(selectedStationId === "All Stations"
									? undefined
									: selectedStationId?.toString())
						)?.waitlist_custom_fields) ??
					[]
			);
			setSchema(newSchema);
		}
	}, [getBusinessLocationsAndStationsQuery.data, selectedStationId]);

	return (
		<Dialog
			open={show}
			onOpenChange={(newOpen) => {
				reset();

				setShow(newOpen);
			}}
		>
			<DialogTrigger className="flex max-w-[144px] flex-1">
				<Button className="h-9 flex-1 text-white">Add</Button>
			</DialogTrigger>
			<DialogContent className="flex max-w-[692px] flex-col">
				<form onSubmit={handleSubmit(onSubmit)}>
					<DialogTitle className="flex items-center justify-between">
						<DialogClose>
							<i className="mgc_left_line text-[24px] before:!text-[#858C95]" />
						</DialogClose>
						<h1 className="text-lg tracking-[-0.18px] text-primary">
							Add New Customer
						</h1>
						<DialogClose>
							<i className="mgc_close_line text-[24px] before:!text-[#858C95]" />
						</DialogClose>
					</DialogTitle>
					<div className="grid grid-cols-2 gap-2">
						<div>
							<Input
								className="col-span-1"
								placeholder="First Name*"
								{...register("full_name")}
							/>
							{errors.full_name?.message && (
								<small className="text-sm text-destructive">
									{errors.full_name?.message as string}
								</small>
							)}
						</div>
						<Input
							className="col-span-1"
							placeholder="Last Name *"
							value={lastName}
							onChange={(e) => {
								setLastName(e.target.value);
							}}
						/>
						<div className="col-span-2 flex flex-col space-y-1.5">
							<div className="flex h-10 items-stretch">
								<Select
									value={countryCode}
									onValueChange={(value) => {
										setCountryCode(value);
									}}
								>
									<SelectTrigger className="w-fit rounded-r-none border-r-transparent">
										<SelectValue
											className="text-[#858C95]"
											placeholder="+1"
										/>
									</SelectTrigger>
									<SelectContent className="!z-[9999]">
										<SelectGroup>
											<SelectLabel className="px-2">
												Country Codes
											</SelectLabel>

											{countryCodes.map((option) => {
												return (
													<SelectItem
														key={option.value}
														value={option.value}
														className="px-8"
													>
														{option.label}
													</SelectItem>
												);
											})}
										</SelectGroup>
									</SelectContent>
								</Select>
								<Input
									className="rounded-l-none border border-[#E4E4E7]"
									placeholder="Phone Number"
									minLength={10}
									maxLength={10}
									{...register("phone_number")}
								/>
							</div>
							{errors.phone_number?.message && (
								<small className="text-sm text-destructive">
									{errors.phone_number?.message as string}
								</small>
							)}
						</div>
						<Input
							className="col-span-2"
							placeholder="Email Address"
							{...register("email")}
						/>
						{errors.email?.message && (
							<small className="text-sm text-destructive">
								{errors.email?.message as string}
							</small>
						)}
						<div className="col-span-2">
							<Select
								value={selectedLocationId}
								onValueChange={(value) => {
									setSelectedLocationId(value);
									// setSelectedStations([]);
									setSelectedStationId(undefined);
								}}
							>
								<SelectTrigger>
									<SelectValue
										placeholder={"Select Location"}
										className="text-[#858C95]"
									/>
								</SelectTrigger>
								<SelectContent className="!z-[9999]">
									<SelectGroup>
										<SelectLabel className="px-2">
											Select Location
										</SelectLabel>

										{(user?.business.locations.length
											? user?.business.locations?.map(
													(location) => ({
														label: location.name,
														value: location.id.toString(),
													})
												) || []
											: []
										).map((option) => {
											return (
												<SelectItem
													key={option.value}
													value={option.value}
													className="px-8"
												>
													{option.label}
												</SelectItem>
											);
										})}
									</SelectGroup>
								</SelectContent>
							</Select>
						</div>
						<div className="col-span-2">
							<Controller
								control={control}
								name="station_id"
								render={({ field: { onChange, value } }) => (
									<Select
										// value={
										// 	selectedStationId === "All Stations"
										// 		? undefined
										// 		: selectedStationId?.toString()
										// }
										// onValueChange={setSelectedStationId}

										value={value?.toString()}
										onValueChange={(value) =>
											onChange(Number(value))
										}
										// {...rest}
									>
										<SelectTrigger>
											<SelectValue
												placeholder={"Select Station *"}
												className="text-[#858C95]"
											/>
										</SelectTrigger>
										<SelectContent className="!z-[9999]">
											<SelectGroup>
												<SelectLabel className="px-2">
													Select Station
												</SelectLabel>

												{(selectedLocationId
													? user?.business.locations
															.find(
																(location) =>
																	location.id.toString() ===
																	selectedLocationId
															)
															?.stations.map(
																(station) => ({
																	label: station.name,
																	value: station.id.toString(),
																})
															) || []
													: []
												).map((option) => {
													return (
														<SelectItem
															key={option.value}
															value={option.value}
															className="px-8"
														>
															{option.label}
														</SelectItem>
													);
												})}
											</SelectGroup>
										</SelectContent>
									</Select>
								)}
							/>
							{errors.station_id?.message && (
								<small className="text-sm text-destructive">
									{errors.station_id?.message as string}
								</small>
							)}
						</div>
					</div>
					<DialogFooter className="mt-4 flex w-full items-center space-x-2">
						<Button
							variant="outline-primary"
							className="flex-1"
							onClick={() => {
								setShow(false);
								reset();
							}}
						>
							Cancel
						</Button>
						<LoaderButton
							className="flex-1 text-white"
							type="submit"
							loading={addPatientMutation.isLoading}
							disabled={addPatientMutation.isLoading}
							loaderSize={20}
						>
							Add Customer
						</LoaderButton>
					</DialogFooter>
				</form>
			</DialogContent>
		</Dialog>
	);
};

export default CustomerManualAdd;
