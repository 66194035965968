import React from "react";

const RoundCheckbox: React.FC<{
	isActive: boolean;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ isActive, onChange }) => {
	return (
		<label
			className="relative flex w-fit cursor-pointer items-center rounded-full"
			htmlFor="role-picker"
		>
			<input
				type="radio"
				name="role-picker"
				className="peer relative h-[24px] w-[24px] cursor-pointer appearance-none rounded-full border border-[#E5E5E7] transition-all checked:border-primary checked:bg-primary hover:before:opacity-10"
				checked={isActive}
				{...(onChange && { onChange })}
				readOnly
			/>
			<span className="pointer-events-none absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="h-[14px] w-[14px]"
					viewBox="0 0 20 20"
					fill="currentColor"
					stroke="currentColor"
					strokeWidth="2"
				>
					<path
						fillRule="evenodd"
						d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
						clipRule="evenodd"
					></path>
				</svg>
			</span>
		</label>
	);
};

export default RoundCheckbox;
