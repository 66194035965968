import React from "react";
import { Button } from "@src/components/ui/button";
import { FiMinusCircle } from "react-icons/fi";
import { CustomTimeSlotProps } from "@src/types/Location";

interface EditDeletePillProps {
	slotData: any;
	handleSlotSelection: (slot: CustomTimeSlotProps) => void;
	handleSlotEdit: (slot: CustomTimeSlotProps) => void;
}

const EditDeletePill: React.FC<EditDeletePillProps> = ({
	slotData,
	handleSlotSelection,
	handleSlotEdit,
}) => {
	return (
		<div className="flex items-center justify-between rounded-lg bg-[#F9F9F9] px-3 py-4">
			<div>
				<h2 className="text-md font-medium">
					{slotData?.title || "N/A"}
				</h2>
				<p className="text-gray-400">
					{`${slotData?.time_in_minute} min` || "N/A"}
				</p>
			</div>
			<div className="flex space-x-0">
				<Button
					variant="ghost"
					size="icon-sm"
					onClick={() => handleSlotEdit(slotData)}
				>
					<i className="mgc_edit_2_line text-[24px]" />
				</Button>
				<Button
					variant="ghost"
					className="text-red-500"
					size="icon-sm"
					onClick={() => handleSlotSelection(slotData)}
				>
					<FiMinusCircle size={"24"} className="text-red-500" />
				</Button>
			</div>
		</div>
	);
};

export default EditDeletePill;
