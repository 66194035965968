import CustomTab from "@src/components/common/CustomTab";
import { Button } from "@src/components/ui/button";
import React from "react";
import { LuArrowBigUp } from "react-icons/lu";
import IntakeFields from "./components/IntakeFields";
import Header from "@src/layouts/Header/Header";

const Customization = () => {
	const tabOptions = {
		"Intake Fields": <IntakeFields />,
		"Build Forms": <p>N/A</p>,
		Events: <p>N/A</p>,
	};

	return (
		<main className="flex flex-1 flex-col">
			<Header title={"Customize Your Workspace"} showDate={false} />
			<div className="flex flex-1 flex-col overflow-auto">
				<CustomTab navMapping={tabOptions} />
			</div>
		</main>
	);
};

export default Customization;
