import FormInput from "@src/components/form/FormInput";
import appointmentIcon from "../../../../../public/assets/icons/schedule-icons/appointment.svg";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@src/components/ui/button";
import { Dispatch, SetStateAction, useState } from "react";
import { useFormContext } from "../../helpers/AppointmentFormContext";
import { countryCodes } from "@/src/utils/constants";
import { Label } from "@/src/components/ui/label";
import CustomSelect from "@/src/components/common/CustomSelect";

type FormFields = z.infer<typeof schema>;
const schema = z.object({
	name: z.string().min(4, { message: "Name must be at least 4 characters" }),
	email: z.string().email({ message: "Invalid email address format" }),
	phone: z
		.string()
		.min(10, { message: "Phone number must be at least 10 characters" })
		.refine((value) => /^[0-9]+$/.test(value), {
			message: "Phone number must only contain numbers 0-9",
		}),
	// address: z
	// 	.string()
	// 	.min(10, { message: "Address must be at least 10 characters" }),
});

interface AppointmentFormStepProps {
	step: number;
	setStep: Dispatch<SetStateAction<number>>;
}

const AppointmentFormStep = ({ step, setStep }: AppointmentFormStepProps) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormFields>({
		resolver: zodResolver(schema),
	});
	const [countryCode, setCountryCode] = useState("+1");

	const { formData, setFormData } = useFormContext();
	const [formValues, setFormValues] = useState<FormFields>({
		name: "",
		email: "",
		phone: "",
	});
	// Handle form submission
	const onSubmit = (data: FormFields) => {
		// Proceed to the next step if form is valid
		setStep(step + 1);
		setFormData({
			...formData,
			patientDetails: {
				name: data.name,
				email: data.email,
				phone: countryCode + data.phone,
			},
		});
	};

	const handleFieldChange = (key: keyof FormFields, value: string) => {
		setFormValues((prevValues) => ({
			...prevValues,
			[key]: value,
		}));
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className="flex  h-full w-full  max-w-[700px] flex-col  gap-y-6 pr-4"
		>
			<div className="flex w-full justify-center">
				<img
					src={appointmentIcon}
					alt=""
					className="aspect-square w-[100px]"
				/>
			</div>
			<div className="flex flex-col gap-y-1">
				<h5 className="text-xl font-[600] ">Schedule a Appointment</h5>
				<p className="font-normal text-[#6D748D]">
					Add the customer&apos;s details to schedule an appointment.
				</p>
			</div>

			<div className="flex flex-col gap-y-3">
				<FormInput
					required
					inputType={"text"}
					label="Full Name"
					placeholder="Enter Patient's Full Name"
					register={{
						...register("name", {
							onChange: (e) =>
								handleFieldChange("name", e.target.value),
						}),
					}}
					error={errors.name?.message}
					minLength={5}
					maxLength={40}
				/>

				<div className="flex flex-col gap-y-1.5">
					<Label className="text-sm font-medium leading-5 tracking-[0.1%] text-main-1">
						Phone Number{" "}
						<span className="text-destructive"> *</span>
					</Label>
					<div className="relative z-[10000] flex h-10 items-stretch">
						<span className="absolute left-[4px] top-[2px]">
							<CustomSelect
								bordercolor={"#fff"}
								value={{
									label: countryCode,
									value: countryCode,
								}}
								className="w-fit rounded-none border-0 "
								onChange={(
									value:
										| any
										| {
												label: string;
												value: string;
										  }
								) => {
									setCountryCode(value.value);
								}}
								placeholder="+1"
								options={countryCodes.map((item) => ({
									value: item.value,
									label: item.value,
								}))}
							/>
						</span>

						<FormInput
							inputType={"phone"}
							required
							className=" pl-20 "
							placeholder="Enter Patient's Phone Number"
							register={{
								...register("phone", {
									onChange: (e) =>
										handleFieldChange(
											"phone",
											e.target.value
										),
								}),
							}}
							minLength={8}
							maxLength={10}
						/>
					</div>
					{errors.phone?.message && (
						<small className="text-sm text-destructive">
							{errors.phone?.message as string}
						</small>
					)}
				</div>
				<FormInput
					required
					inputType={"email"}
					label="Email Address"
					placeholder="Enter Patient's Email Address"
					register={{
						...register("email", {
							onChange: (e) =>
								handleFieldChange("email", e.target.value),
						}),
					}}
					error={errors.email?.message}
					minLength={8}
					maxLength={40}
				/>
			</div>
			<div className="mt-auto flex w-full gap-x-2">
				<Button variant={"outline"} className="w-full" type="button">
					Cancel
				</Button>
				<Button
					variant={"default"}
					className="w-full"
					type="submit" // Ensure this button submits the form
				>
					Next
				</Button>
			</div>
		</form>
	);
};

export default AppointmentFormStep;
