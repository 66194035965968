import Loader from "@src/components/Loader/Loader";
import { ScrollArea } from "@src/components/ui/scroll-area";
import { GetOscarDetails } from "@src/store/slices/settings/integrations/oscarSlice";
import { OscarDetailsType } from "@src/types/settings/integrations/oscar";
import { cn } from "@src/utils/functions";
import React, { useEffect, useRef, useState } from "react";
import ErrorMessageDialog from "../../ErrorMessageDialog";
import CompletedAllSteps from "./CompletedAllSteps";
import FirstTimeStepFour from "./StepFour";
import FirstTimeStepOne from "./StepOne";
import FirstTimeStepThree from "./StepThree";
import FirstTimeStepTwo from "./StepTwo";

const FirstTimeOscarIntegration: React.FC<{
	setFirstTime: React.Dispatch<React.SetStateAction<boolean>>;
}> = () => {
	const getOscarDetailsQuery = GetOscarDetails(
		(data) => {
			console.log(data);
			setEMRIntegrationInformation(data);
			setCurrentAPIStep(data.emr_base_url ? 4 : 1);
			if (data.client_id && data.client_secret && data.emr_base_url) {
				setIsCompleted(true);
				return;
			} else {
				setIsCompleted(false);
				// setEmrURL(removeTrailingSlash(data?.emr_base_url ?? ""));
			}
		},
		(error) => {
			if (error.response && error.response?.status !== 404) {
				setErrorMessage(error.response.data.detail);
				setIsErrorMessageOpen(true);
			}
			// if(error)
		}
	);

	const [step, setStep] = useState(1);
	const [currentAPIStep, setCurrentAPIStep] = useState(1);
	const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [isCompleted, setIsCompleted] = useState(false);
	const [emrIntegrationInfromation, setEMRIntegrationInformation] = useState<
		OscarDetailsType | undefined
	>();
	const observer = useRef<IntersectionObserver | null>(null);

	// Create refs for each step component
	const stepRefs = useRef<(HTMLDivElement | null)[]>([]);

	// Function to handle scrolling to a specific step
	const scrollToStep = (stepIndex: number) => {
		if (stepRefs.current[stepIndex]) {
			stepRefs.current[stepIndex]?.scrollIntoView({ behavior: "smooth" });
		}
	};

	useEffect(() => {
		observer.current = new IntersectionObserver(
			(entries) => {
				// Loop over each entry
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						const stepIndex = stepRefs.current.indexOf(
							entry.target as HTMLDivElement
						);
						// console.log(stepIndex, stepRefs.current, entry.target);
						// If the stepIndex is valid, update the current step
						if (stepIndex !== -1) {
							setStep(stepIndex + 1);
						}
					}
				});
			},
			{ threshold: 0.5 } // Trigger callback when 50% of the element is visible
		);

		// Observe each step
		stepRefs.current.forEach((ref) => {
			if (ref) {
				observer.current?.observe(ref);
			}
		});

		// Cleanup
		return () => {
			observer.current?.disconnect();
		};
	}, [getOscarDetailsQuery.isLoading]);

	return (
		<>
			<section className="flex h-0 min-h-full flex-1 flex-col">
				<h2 className="text-[22px] font-semibold leading-[24px] tracking-[-1%] text-[#323539]">
					Integrate EMR
				</h2>
				<ScrollArea className="flex-1">
					{!getOscarDetailsQuery.isLoading ? (
						<div className="flex h-[75dvh] flex-1 flex-col">
							<div className="mb-[30px] mt-[42px] flex items-center space-x-[27px]">
								<figure className="size-16 overflow-hidden rounded-xl border border-[#DEDEDE] drop-shadow-[0_0_20px_0_rgba(0,0,0,0.10)]">
									<img
										src="/assets/images/integration/oscar/oscar.png"
										alt="Oscar Pro Image"
									/>
								</figure>
								<p className="text-[22px] font-semibold leading-[24px] tracking-[-1%] text-[#323539]">
									OSCAR Pro
								</p>
							</div>
							<hr />
							{!isCompleted ? (
								<div className="flex flex-1 space-x-[18px] pt-[34px]">
									<div className="flex flex-col items-center justify-between self-stretch">
										{Array.from({ length: 4 }).map(
											(_, index) => (
												<StepCounter
													key={index}
													step={index + 1}
													isActive={step >= index + 1}
													setStep={(stepIndex) => {
														setStep(stepIndex);
														scrollToStep(
															stepIndex - 1
														); // Scroll to the step
													}}
												/>
											)
										)}
									</div>
									<ScrollArea className="h-0 min-h-[100%] flex-1 px-8">
										<div className="space-y-[50px]">
											<div
												ref={(el) =>
													(stepRefs.current[0] = el)
												}
												className="flex flex-1"
											>
												<FirstTimeStepOne
													currentAPIStep={
														currentAPIStep
													}
													step={step}
													isErrorMessageOpen={
														isErrorMessageOpen
													}
													emrIntegrationInfromation={
														emrIntegrationInfromation
													}
													errorMessage={errorMessage}
													getOscarDetailsQuery={
														getOscarDetailsQuery
													}
													setCurrentAPIStep={
														setCurrentAPIStep
													}
													setStep={setStep}
													setErrorMessage={
														setErrorMessage
													}
													setIsErrorMessageOpen={
														setIsErrorMessageOpen
													}
													scrollToStep={scrollToStep}
													setEMRIntegrationInformation={
														setEMRIntegrationInformation
													}
												/>
											</div>
											<div
												ref={(el) =>
													(stepRefs.current[1] = el)
												}
												className="flex flex-1"
											>
												<FirstTimeStepTwo
													currentAPIStep={
														currentAPIStep
													}
													setCurrentAPIStep={
														setCurrentAPIStep
													}
													step={step}
													setStep={setStep}
													isErrorMessageOpen={
														isErrorMessageOpen
													}
													errorMessage={errorMessage}
													setErrorMessage={
														setErrorMessage
													}
													setIsErrorMessageOpen={
														setIsErrorMessageOpen
													}
												/>
											</div>
											<div
												ref={(el) =>
													(stepRefs.current[2] = el)
												}
												className="flex flex-1"
											>
												<FirstTimeStepThree
													currentAPIStep={
														currentAPIStep
													}
													setCurrentAPIStep={
														setCurrentAPIStep
													}
													step={step}
													setStep={setStep}
													isErrorMessageOpen={
														isErrorMessageOpen
													}
													errorMessage={errorMessage}
													setErrorMessage={
														setErrorMessage
													}
													setIsErrorMessageOpen={
														setIsErrorMessageOpen
													}
												/>
											</div>
											<div
												ref={(el) =>
													(stepRefs.current[3] = el)
												}
												className="flex flex-1"
											>
												<FirstTimeStepFour
													step={step}
													currentAPIStep={
														currentAPIStep
													}
													isErrorMessageOpen={
														isErrorMessageOpen
													}
													errorMessage={errorMessage}
													emrIntegrationInfromation={
														emrIntegrationInfromation
													}
													getOscarDetailsQuery={
														getOscarDetailsQuery
													}
													setCurrentAPIStep={
														setCurrentAPIStep
													}
													setStep={setStep}
													setErrorMessage={
														setErrorMessage
													}
													setIsErrorMessageOpen={
														setIsErrorMessageOpen
													}
													setIsCompleted={
														setIsCompleted
													}
													scrollToStep={scrollToStep}
													setEMRIntegrationInformation={
														setEMRIntegrationInformation
													}
												/>
											</div>
										</div>
									</ScrollArea>
								</div>
							) : (
								<CompletedAllSteps
									emrIntegrationInfromation={
										emrIntegrationInfromation
									}
									setEMRIntegrationInformation={
										setEMRIntegrationInformation
									}
									setIsCompleted={setIsCompleted}
									setCurrentAPIStep={setCurrentAPIStep}
								/>
							)}
						</div>
					) : (
						<div className="relative grid h-[75dvh] place-content-center">
							<Loader size={24} />
						</div>
					)}

					{}
				</ScrollArea>
			</section>
			<ErrorMessageDialog
				isOpen={isErrorMessageOpen}
				setIsOpen={setIsErrorMessageOpen}
				errorMessage={errorMessage}
				setErrorMessage={setErrorMessage}
			/>
		</>
	);
};

export default FirstTimeOscarIntegration;

const StepCounter: React.FC<{
	step: number;
	isActive: boolean;
	setStep: (step: number) => void;
}> = ({ isActive, setStep, step }) => {
	return (
		<>
			<div
				className={cn(
					"grid size-8 cursor-pointer place-content-center rounded-full  font-medium leading-[24px] tracking-[-1%] duration-200 ease-in-out",
					{
						"bg-primary text-white": isActive,
						"border border-[#E5E5E7] bg-white text-[#858C95]":
							!isActive,
					}
				)}
				onClick={() => setStep(step)}
			>
				{step}
			</div>
			<div
				className={cn(
					"w-[2px] flex-1 border-l border-dashed duration-200 ease-in-out last:hidden",
					{
						"border-[#E5E5E7]": !isActive,
						"border-primary": isActive,
					}
				)}
			/>
		</>
	);
};
