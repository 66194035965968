import { cn } from "@utils/functions";
import dayjs from "dayjs";
import React, { useEffect } from "react";

const TimeAgo: React.FC<{
	timeStart: Date | string;
	className?: string;
}> = ({ timeStart, className }) => {
	const getCardTime: () => string = () => {
		let time = "";
		const t = dayjs(new Date().getTime() - new Date(timeStart).getTime());
		const hours =
			+t.format("H") < 1
				? ""
				: +t.format("H") === 1
					? "1 hr "
					: t.format("H [hrs] ");
		let minutes = "";
		minutes =
			+t.format("m") === 0
				? ""
				: +t.format("m") === 1
					? t.format("m [min]")
					: t.format("m [mins]");

		time = hours + minutes;

		return time;
	};

	useEffect(() => {
		const interval = setInterval(() => {
			getCardTime();
		}, 5000);
		return () => clearInterval(interval);
	}, []);

	return (
		<p className={cn("text-[10px] leading-[10px]", className)}>
			{getCardTime()} ago
		</p>
	);
};

export default TimeAgo;

// const getCardTime: () => string = () => {
//   let time = "";

//   if (status === PatientStatus.Pending) {
//     const t = dayjs(
//       new Date().getTime() - new Date(patient.created_at).getTime()
//     );

//     time =
//       +t.format("H") < 1
//         ? t.format("m [min(s)]")
//         : t.format("H[hr(s)] m [min(s)]");
//   } else if (
//     status === PatientStatus.Confirmed
//     //  ||
//     // status === PatientStatus.NextInLine
//   ) {
//     const t = dayjs(
//       new Date().getTime() -
//         new Date(patient.waitlist.updated_at).getTime()
//     );
//     console.log(t.format("H ,  m"));
//     time =
//       +t.format("H") < 1
//         ? t.format("m [min(s)]")
//         : t.format("H[hr(s)] m [min(s)]");
//   } else if (status === PatientStatus.Serving) {
//     const t = dayjs(
//       new Date().getTime() -
//         new Date(patient.wait_list.updated_at).getTime()
//     );

//     time =
//       +t.format("H") < 1
//         ? t.format("m [min(s)]")
//         : t.format("H[hr(s)] m [min(s)]");
//   }
//   return time;
// };
