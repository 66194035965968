import React, { useCallback, useEffect } from "react";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $generateHtmlFromNodes } from "@lexical/html";

import { useController, Control } from "react-hook-form";

import ToolbarPlugin from "./ToolBarPlugin";
import { ImageNode } from "./nodes/ImageNode";
import { ImagePlugin } from "./plugins/ImagePlugin";

type RichTextEditorProps = {
	name: string;
	control: Control<any>;
};

function Placeholder() {
	return (
		<div className="absolute left-[1.125rem] top-[1.125rem] text-gray-400">
			Enter some text...
		</div>
	);
}

const editorConfig = {
	namespace: "MyEditor",
	onError(error: Error) {
		console.error("Lexical error:", error);
	},
	nodes: [
		HeadingNode,
		ListNode,
		ListItemNode,
		QuoteNode,
		CodeNode,
		CodeHighlightNode,
		TableNode,
		TableCellNode,
		TableRowNode,
		AutoLinkNode,
		LinkNode,
		ImageNode,
	],
};

function OnChangePluginWrapper({
	onChange,
}: {
	onChange: (editorState: string) => void;
}) {
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		return editor.registerUpdateListener(({ editorState }) => {
			onChange(JSON.stringify(editorState));
		});
	}, [editor, onChange]);

	return null;
}

export default function RichTextEditor({ name, control }: RichTextEditorProps) {
	const {
		field: { onChange, value },
	} = useController({ name, control });

	const initialEditorState = value
		? () => {
				const parser = new DOMParser();
				const dom = parser.parseFromString(value, "text/html");
				return dom.body.innerHTML;
			}
		: undefined;

	const handleEditorChange = useCallback(
		(editorState: string) => {
			onChange(editorState);
		},
		[onChange]
	);

	return (
		<LexicalComposer
			initialConfig={{ ...editorConfig, editorState: initialEditorState }}
		>
			<div className="relative rounded-lg border border-gray-300 bg-white">
				<ToolbarPlugin />
				<div className="relative">
					<RichTextPlugin
						contentEditable={
							<ContentEditable className="max-h-[200px] min-h-[150px] overflow-y-auto p-4 outline-none" />
						}
						placeholder={<Placeholder />}
						ErrorBoundary={LexicalErrorBoundary}
					/>
					<OnChangePluginWrapper onChange={handleEditorChange} />
					<HistoryPlugin />
					<AutoFocusPlugin />
					<ListPlugin />
					<LinkPlugin />
					<ImagePlugin />
					<MarkdownShortcutPlugin transformers={TRANSFORMERS} />
				</div>
			</div>
		</LexicalComposer>
	);
}
