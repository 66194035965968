import { zodResolver } from "@hookform/resolvers/zod";
import { LoaderButton } from "@src/components/form/LoaderButton";
import { Input } from "@src/components/ui/input";
import { Label } from "@src/components/ui/label";
import { AddOscarEMRUrl } from "@src/store/slices/settings/integrations/oscarSlice";
import {
	FirstTimeOscarSchema,
	FirstTimeOscarType,
	OscarDetailsType,
} from "@src/types/settings/integrations/oscar";
import { removeTrailingSlash } from "@src/utils/functions";
import React, { Dispatch, SetStateAction } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { UseQueryResult } from "react-query";

const StepOne: React.FC<{
	step?: number;
	currentAPIStep: number;
	isErrorMessageOpen: boolean;
	errorMessage: string;
	getOscarDetailsQuery: UseQueryResult<OscarDetailsType, Error>;
	emrIntegrationInfromation: OscarDetailsType | undefined;
	setErrorMessage: Dispatch<SetStateAction<string>>;
	setIsErrorMessageOpen: Dispatch<SetStateAction<boolean>>;
	setStep: Dispatch<SetStateAction<number>>;
	setCurrentAPIStep: Dispatch<SetStateAction<number>>;
	scrollToStep: (step: number) => void;
	setEMRIntegrationInformation: Dispatch<
		SetStateAction<OscarDetailsType | undefined>
	>;
}> = ({
	currentAPIStep,
	getOscarDetailsQuery,
	emrIntegrationInfromation,
	setEMRIntegrationInformation,
	scrollToStep,
	setCurrentAPIStep,
	setStep,
}) => {
	const {
		register,
		handleSubmit,
		setError,
		getValues,
		formState: { errors },
	} = useForm<FirstTimeOscarType>({
		resolver: zodResolver(FirstTimeOscarSchema),
		defaultValues: {
			emr_base_url: emrIntegrationInfromation?.emr_base_url,
		},
	});

	const addOscarEMRUrlMutation = AddOscarEMRUrl(() => {
		setStep(2);
		setCurrentAPIStep(4);
		scrollToStep(1);
		setEMRIntegrationInformation({
			emr_base_url: removeTrailingSlash(getValues("emr_base_url")),
		});
	});

	const onSubmit: SubmitHandler<FirstTimeOscarType> = async (data) => {
		try {
			if (isFormDisabled) return;

			addOscarEMRUrlMutation.mutate({
				emr_base_url: removeTrailingSlash(data.emr_base_url),
			});
		} catch (error) {
			setError("root", {
				message: "An error occured kindly try again later",
			});
		}
	};

	const isFormDisabled =
		(currentAPIStep > 1 || addOscarEMRUrlMutation.isLoading) &&
		getOscarDetailsQuery.data?.detail ===
			"No OscarEMR matches the given query.";

	// useEffect(() => {
	// 	// setEmrURL(getValues("emr_base_url"));
	// 	reset();
	// 	alert("deqd");
	// }, []);

	return (
		<div className="m-1.5 flex flex-1 flex-col space-y-3">
			<h2 className="text-[22px] font-semibold leading-[30px] text-[#09090B]">
				Step 1
			</h2>
			<form
				className="mt-[29px] flex w-full max-w-[805px] flex-col space-y-4 rounded-xl border border-[#E4E4E7] bg-white p-6 shadow-[0_10px_15px_-3px_rgba(0,0,0,0.1)]"
				onSubmit={handleSubmit(onSubmit)}
				aria-disabled={isFormDisabled}
			>
				<div>
					<h3 className="text-[18px] font-semibold leading-[30px] text-[#09090B]">
						Sign in to your EMR!
					</h3>
					<p className="text-[14px] leading-[20px] text-[#71717A]">
						Go to your EMR website, paste the login link here, and
						then sign into your account!
					</p>
				</div>
				<div className="space-y-1.5">
					<Label>Paste Below</Label>
					<Input
						className="h-9 shadow-[0px_0px_2px_0.5px_rgba(16,24,40,0.05)]"
						placeholder="Enter the URL here"
						disabled={
							isFormDisabled ||
							!!emrIntegrationInfromation?.emr_base_url?.length
						}
						{...register("emr_base_url")}
						value={emrIntegrationInfromation?.emr_base_url}
					/>
					{errors.emr_base_url &&
						errors?.emr_base_url?.message?.length && (
							<small className="mt-1.5 text-sm text-red-500">
								{errors.emr_base_url.message}
							</small>
						)}
				</div>
				<LoaderButton
					className="mt-4 h-9 self-end text-white"
					type="submit"
					disabled={
						isFormDisabled ||
						!!emrIntegrationInfromation?.emr_base_url?.length
					}
					loading={addOscarEMRUrlMutation.isLoading}
					loaderSize={20}
				>
					Connect to your EMR
				</LoaderButton>
			</form>
		</div>
	);
};

export default StepOne;
