import {
	Dialog,
	DialogContent,
	DialogTrigger,
	DialogHeader,
	DialogTitle,
} from "@components/ui/dialog";
import CustomCheckbox2 from "@src/components/form/CustomCheckbox2";
import Loader from "@src/components/Loader/Loader";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@src/components/ui/accordion";
import { Button } from "@src/components/ui/button";
import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "@src/components/ui/tabs";
import { UpdateLocationOperatingHoursSlice } from "@src/store/slices/locations/locationSlice";
import {
	AddLocationDataV2,
	StationsArrayProps,
	StationsProps,
} from "@src/types/Location";
import { convertSchedule, isTimeOverlapping } from "@src/utils/functions";
import React, { useState } from "react";
import { useFormContext, Controller, SubmitHandler } from "react-hook-form";

interface Props {
	station: StationsArrayProps | undefined;
	id: number;
	updateMode?: "station" | "location";
}

const ApplyOperatingHoursModal: React.FC<Props> = ({
	station,
	id,
	updateMode,
}) => {
	const [showWaitlistSettingsModal, setWaitlistSettingsModal] =
		useState(false);
	const { control, setValue, getValues, handleSubmit, watch } =
		useFormContext();
	const [stationIds, setStationIds] = useState<number[]>([]);
	const [checkAllStations, setCheckAllStations] = useState(false);
	const [isChecked, setIsChecked] = useState(false);

	const updateBusinessWorkingHoursMutation =
		UpdateLocationOperatingHoursSlice(
			() => {
				setWaitlistSettingsModal(false);
			},
			(error) => console.log("🚀 ~ LocationTabList ~ error:", error)
			// slots
		);

	const handleCheckboxChange = () => {
		setIsChecked(!isChecked);
		setStationIds([]);
		setCheckAllStations(false);
		setValue("child_ids", []);
	};

	const handleCheckAllStations = () => {
		if (stationIds?.length === station?.length) {
			setStationIds([]);
			setCheckAllStations(false);
			setValue("child_ids", []);
		} else {
			const data =
				station?.map((station: StationsProps) => station.id) || [];
			setStationIds(data);
			setCheckAllStations(true);
			setValue("child_ids", data);
		}
	};

	const handleStationSelection = (station: StationsProps) => {
		setStationIds((prev) => {
			if (prev.includes(station.id)) {
				const data = prev.filter((id) => id !== station.id);
				setValue("child_ids", data);
				return data;
			} else {
				const data = [...prev, station.id];
				setValue("child_ids", data);
				return data;
			}
		});
	};

	const handleUpdateOperatingHours: SubmitHandler<AddLocationDataV2> = async (
		data
	) => {
		if (isTimeOverlapping(data?.slots || [])) return;
		const convertedSlot = convertSchedule(data.slots);

		const payload = {
			schedule_block_in_minute: parseInt(
				(data.schedule_block_in_minute || 0).toString()
			),
			manual_wait_time: parseInt((data.manual_wait_time || 0).toString()),
			child_update_strategy: data.is_station_default_hour
				? "default"
				: data.is_station_override_hour
					? "override"
					: data.child_ids.length > 1
						? "specific"
						: "ignore",
			child_ids: data.child_ids,
			waitlist_schedule_option:
				data.is_schedule && data.is_waitlist
					? "all"
					: data.is_schedule
						? "schedule"
						: "waitlist",
			data: convertedSlot,
		};

		console.log("🚀 ~ payload:", payload);
		updateBusinessWorkingHoursMutation.mutate({
			data: payload,
			queryParams: {
				[updateMode === "station" ? "station_id" : "location_id"]:
					id || 0,
			},
		});

		return;
	};

	const handleDisabled = () => {
		const child_ids = watch("child_ids");
		const is_station_default_hour = watch("is_station_default_hour");
		const is_station_override_hour = watch("is_station_override_hour");
		const payload = watch("data");
		// console.log("🚀 ~ handleDisabled ~ payload:", payload);
		const isEmpty = (obj: any) => Object.entries(obj).length === 0;

		if (!isEmpty(payload)) {
			return false;
		} else if (
			isEmpty(payload) &&
			child_ids.length <= 0 &&
			!is_station_override_hour &&
			!is_station_default_hour
		) {
			return true;
		} else return true;
	};

	return (
		<Dialog
			open={showWaitlistSettingsModal}
			onOpenChange={setWaitlistSettingsModal}
		>
			<DialogTrigger asChild>
				<Button className="px-9">Update</Button>
			</DialogTrigger>
			<DialogContent className="flex min-h-[408px] max-w-[450px] flex-col overflow-hidden p-6">
				<div className="space-y-4">
					<DialogHeader>
						<DialogTitle className="text-lg font-medium tracking-normal">
							Apply Operating Hours to All Stations?
						</DialogTitle>
					</DialogHeader>
					<div className="space-y-3.5 py-4">
						<div className="flex items-start gap-x-2">
							<Controller
								name={`is_station_default_hour`}
								control={control}
								render={() => (
									<CustomCheckbox2
										className="checked:border-[#195388] checked:bg-[#195388]"
										isChecked={getValues(
											"is_station_default_hour"
										)}
										handleCheckboxChange={() =>
											setValue(
												"is_station_default_hour",
												!getValues(
													"is_station_default_hour"
												)
											)
										}
										id={"is_station_default_hour"}
									/>
								)}
							/>
							<div className="space-y-2.5">
								<h3 className="m-0 text-sm font-medium leading-none">
									Apply to stations following the default
									hours
								</h3>
								<p className="text-sm font-normal text-muted-foreground">
									This will apply the updated hours to all
									stations{" "}
									<span className="font-semibold">
										except
									</span>{" "}
									those that already have their own customized
									hours.{" "}
								</p>
							</div>
						</div>
						<div className="flex items-start gap-x-2">
							<Controller
								name={`is_station_override_hour`}
								control={control}
								render={() => (
									<CustomCheckbox2
										className="checked:border-[#195388] checked:bg-[#195388]"
										isChecked={getValues(
											"is_station_override_hour"
										)}
										handleCheckboxChange={() =>
											setValue(
												"is_station_override_hour",
												!getValues(
													"is_station_override_hour"
												)
											)
										}
										id={"is_station_override_hour"}
									/>
								)}
							/>

							<div className="space-y-2.5">
								<h3 className="m-0 text-sm font-medium leading-none">
									Apply to all stations, override all other
									hours
								</h3>
								<p className="text-sm font-normal text-muted-foreground">
									This will apply the updated hours to{" "}
									<span className="font-semibold">
										ALL stations
									</span>{" "}
									in this location.
								</p>
							</div>
						</div>
						{/* <div className="flex items-start gap-x-2">
							<CustomCheckbox2
								isChecked
								handleCheckboxChange={() => {}}
								className="checked:border-[#195388] checked:bg-[#195388]"
								id={""}
							/>
							<div className="space-y-2.5">
								<h3 className="m-0 text-sm font-medium leading-none">
									Apply to stations following the default
									hours
								</h3>
								<p className="text-sm font-normal text-muted-foreground">
									This will apply the updated hours to all
									stations except those that already have
									their own customized hours.{" "}
								</p>
							</div>
						</div> */}
						<Accordion
							type="single"
							collapsible
							className="w-full"
							value={isChecked ? "item-2" : ""}
						>
							<AccordionItem
								value="item-2"
								className="space-y-2.5 border-0"
							>
								<AccordionTrigger className="flex items-start justify-start gap-x-2 py-1 text-main-1 hover:no-underline">
									{/* <div className="flex items-start gap-x-2"> */}
									<CustomCheckbox2
										isChecked={isChecked}
										handleCheckboxChange={
											handleCheckboxChange
										}
										className="checked:border-[#195388] checked:bg-[#195388]"
										id={""}
									/>
									<div className="flex flex-col items-start space-y-2.5">
										<h3 className="m-0 text-sm font-medium leading-none">
											Select stations to apply hours to
										</h3>
										<p className="ml-2 text-left text-sm font-normal text-muted-foreground">
											This will apply the updated hours to{" "}
											<span className="font-semibold">
												all selected stations
											</span>{" "}
											in this location.
										</p>
									</div>
									{/* </div> */}
								</AccordionTrigger>
								<AccordionContent className="flex flex-col pb-0">
									{/* <p className=" py-1"></p> */}
									<div className="ml-6 space-y-2.5">
										<div className="flex items-center gap-x-2">
											<CustomCheckbox2
												isChecked={checkAllStations}
												handleCheckboxChange={
													handleCheckAllStations
												}
												className="checked:border-[#195388] checked:bg-[#195388]"
												id={""}
											/>
											<div className="space-y-2.5">
												<h3 className="m-0 text-sm font-medium leading-none">
													All Stations in this
													Location
												</h3>
											</div>
										</div>

										{station?.map(
											(station: StationsProps) => (
												<div
													key={station.id}
													className="flex items-center gap-x-2"
												>
													<CustomCheckbox2
														isChecked={stationIds.includes(
															station.id
														)}
														handleCheckboxChange={() => {
															handleStationSelection(
																station
															);
														}}
														className="checked:border-[#195388] checked:bg-[#195388]"
														id={
															"station-" +
															station.id
														}
													/>
													<div className="space-y-2.5">
														<h3 className="m-0 text-sm font-medium leading-none">
															{station.name}
														</h3>
													</div>
												</div>
											)
										)}
									</div>
								</AccordionContent>
							</AccordionItem>
						</Accordion>
					</div>
					<div className="mx-auto flex w-full max-w-6xl justify-end gap-6 py-4">
						<Button
							type="reset"
							onClick={() => setWaitlistSettingsModal(false)}
							variant={"outline"}
						>
							Cancel
						</Button>
						<Button
							type="button"
							onClick={handleSubmit((data: any) => {
								handleUpdateOperatingHours(data);
							})}
							disabled={handleDisabled()}
						>
							{updateBusinessWorkingHoursMutation.isLoading ? (
								<Loader size={20} />
							) : (
								"Update Selected"
							)}
						</Button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default ApplyOperatingHoursModal;
