import React, { useEffect, useState } from "react";
import { CustomSelect } from "@components/form/CustomSelect";
import { Button } from "@components/ui/button";
import FormInput from "@components/form/FormInput";
import { AccordionComboboxSearch } from "@components/common/CustomSearch";
import { useFormContext, useFieldArray } from "react-hook-form";
import { getErrorMessage } from "@src/utils/functions";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { transformText } from "@src/utils/functions";
import {
	// GetTeamMembersSlice,
	GetAvailableTeamMembersSlice,
	CreateTeamMemberSlice,
	AddExisitingMemberToLocationSlice,
	RemoveTeamMemberSlice,
} from "@src/store/slices/team-members/teamMembersSlice";
import RequestIsLoading from "@components/RequestIsLoading";
import { TeamMemberSchema, TeamMemberProps } from "@src/types/TeamMember";
import Loader from "@src/components/Loader/Loader";
import { DeleteLocationModal } from "./DeleteLocationModal";

const roleOptions = [
	{ value: "ROOM_MANAGER", label: "Room Manager" },
	{ value: "LOCATION_MANAGER", label: "Location Manager" },
	{ value: "PARTNER_MANAGER", label: "Partner Manager" },
	{ value: "TEAM_MEMBER", label: "Team Member" },
	{ value: "SUPER_ADMIN", label: "Super Admin" },
	{ value: "BUSINESS_ADMIN", label: "Business Admin" },
	{ value: "LOCATION_ADMIN", label: "Location Admin" },
	{ value: "STATION_ADMIN", label: "Station Admin" },
	{ value: "PARTENER_SUPER_ADMIN", label: "Partner Super Admin" },
	{ value: "PARTNER_ADMIN", label: "Partner Admin" },
];

interface LocationMemberProps {
	locationId: string;
}

const LocationMember: React.FC<LocationMemberProps> = ({ locationId }) => {
	const [showAddForm, setShowAddForm] = useState(false);
	const [isUpdateTeamMember, setIsUpdateTeamMember] = useState(false);
	const [showRemoveTeamMemberModal, setShowRemoveTeamMemberModal] =
		useState(false);
	const [isAddExisitingTeamMember, setIsAddExisitingTeamMember] =
		useState(false);
	const [selectedMember, setSelectedMember] =
		useState<TeamMemberProps | null>(null);
	const newLocationId = parseInt(locationId) || 0;

	const { data: availableMemberData, isLoading: availableMemberLoading } =
		GetAvailableTeamMembersSlice();

	const {
		isLoading: removeTeamMemberLoading,
		mutate: removeTeamMemberMutation,
	} = RemoveTeamMemberSlice(
		() => {
			const index = fields.findIndex(
				(item) => parseInt(item.id) === selectedMember?.id
			);
			if (index !== -1) {
				remove(index);
			}
			setShowAddForm(false);
			setShowRemoveTeamMemberModal(false);
		},
		() => setShowRemoveTeamMemberModal(false)
	);

	const {
		register,
		handleSubmit,
		reset,
		setValue,
		getValues,
		formState: { errors },
	} = useForm({
		resolver: zodResolver(TeamMemberSchema),
		defaultValues: {
			id: undefined,
			name: "",
			role: "",
			email: "",
			phone_number: "",
			user_id: undefined,
		},
	});

	const { watch, control } = useFormContext();

	const { fields, append, update, remove } = useFieldArray({
		control,
		name: "members",
		keyName: "_id" as "id",
	});

	const handleAddNewMember = () => {
		setShowAddForm(true);
		setIsUpdateTeamMember(false);
		reset();
	};

	const {
		mutate: addBusinessLocationMutation,
		isLoading: addCreateTeamMemberLoading,
	} = CreateTeamMemberSlice(
		(data) => {
			// const index = fields.findIndex(
			// 	(item) => item.id === data?.data?.id
			// );
			const payload = {
				id: data?.data?.id,
				name: data?.data?.name,
				role: data?.data?.role,
				email: data?.data?.email,
				phone_number: data?.data?.phone_number,
				user_id: data.data.user_id,
			};
			// if (index !== -1) {
			// 	update(index, payload);
			// } else {
			// }
			append(payload);
			reset({
				id: undefined,
				name: "",
				role: "",
				email: "",
				phone_number: "",
				user_id: undefined,
			});
			setShowAddForm(false);
		},
		(error) => console.log("🚀 ~ LocationTabList ~ error:", error)
	);

	const {
		mutate: addExisitingMemberToLocationMutation,
		isLoading: addExisitingMemberToLocationLoading,
	} = AddExisitingMemberToLocationSlice(
		(data) => {
			// const index = fields.findIndex(
			// 	(item) => item.id === data?.data?.id
			// );
			const payload = {
				id: data?.data?.id,
				name: data?.data?.name,
				role: data?.data?.role,
				email: data?.data?.email,
				phone_number: data?.data?.phone_number,
				user_id: data.data.user_id,
			};
			append(payload);
			// reset({
			// 	id: undefined,
			// 	name: "",
			// 	role: "",
			// 	email: "",
			// 	phone_number: "",
			// });
			setShowAddForm(false);
			setIsAddExisitingTeamMember(false);
		},
		() => {
			setIsAddExisitingTeamMember(false);
		}
	);

	const onSubmit = (data: TeamMemberProps) => {
		if (isUpdateTeamMember) {
			addExisitingMemberToLocationMutation({
				data: { ...data, user_id: data.user_id },
				queryParams: { location_id: newLocationId },
			});
		} else {
			addBusinessLocationMutation({
				data: data,
				queryParams: { location_id: newLocationId },
			});
		}
	};

	const handleChange = (value: string) => {
		const member = availableMemberData?.data?.find(
			(item: any) => item.id === value
		);

		addExisitingMemberToLocationMutation({
			data: { ...member, role: "LOCATION_MANAGER", user_id: member.id },
			queryParams: { location_id: newLocationId },
		});

		// reset(member);
		// setShowAddForm(true);
		// setIsAddExisitingTeamMember(true);
	};

	const handleDeleteMember = (member: any) => {
		// remove(fields.indexOf(member));
		setSelectedMember(member);
		setShowRemoveTeamMemberModal(true);
	};

	const handleEditMember = (member: any) => {
		reset({
			email: member.email,
			id: member.id,
			user_id: member.id,
			name: member.name,
			phone_number: member.phone_number,
			role: member.role,
		});
		setShowAddForm(true);
		setIsUpdateTeamMember(true);
		// remove(fields.indexOf(member));
	};

	const handleRemoveTeamMember = () => {
		removeTeamMemberMutation({ userId: selectedMember?.id || 0 });
	};

	return (
		<div>
			{/* <RequestIsLoading isLoading={addExisitingMemberToLocationLoading} /> */}
			<h3 className="text-xl font-medium">Members</h3>
			<div>
				<div>
					<label className="text-sm">Admin Member *</label>
					<div className="relative flex items-center">
						<AccordionComboboxSearch
							options={
								availableMemberData?.data?.map((item: any) => ({
									value: item.id,
									label: item.name,
								})) || []
							}
							placeholder="Type a for search..."
							emptyMessage="No options found."
							onSelect={handleChange}
						/>
					</div>
				</div>
				<Button
					type="button"
					variant={"link"}
					className="p-0 text-primary outline-none"
					onClick={handleAddNewMember}
				>
					<i className="mgc_user_add_line mr-2 text-sm text-primary" />
					Add new member
				</Button>
				{showAddForm && (
					<>
						<div className="flex items-center justify-between space-x-1 rounded-lg bg-gray-100 p-3">
							<div className="grid flex-1 grid-cols-4 gap-3">
								<div>
									<FormInput
										inputType={"text"}
										label="Member Name  * "
										register={{
											...register("name"),
										}}
										className="h-10"
										error={getErrorMessage(
											errors?.name?.message
										)}
									/>
								</div>
								<div className="flex-1">
									<label className="bt-1.5 mb-1.5 block text-sm font-medium tracking-[-0.1px] text-[#323539]">
										Role *
									</label>
									<CustomSelect
										placeholder="Select"
										label="Role  *"
										className="h-10"
										options={roleOptions}
										defaultValue={getValues("role")}
										value={watch("role")}
										onChange={(value) => {
											setValue("role", value);
										}}
										error={getErrorMessage(
											errors.role?.message
										)}
									/>
								</div>
								<div>
									<FormInput
										inputType={"text"}
										label="Email Address "
										register={{
											...register("email"),
										}}
										className="h-10"
										error={getErrorMessage(
											errors.email?.message
										)}
										minLength={5}
										maxLength={40}
									/>
								</div>
								<div>
									<FormInput
										inputType={"text"}
										label="Phone Number"
										register={{
											...register("phone_number"),
										}}
										className="h-10"
										error={getErrorMessage(
											errors.phone_number?.message
										)}
										minLength={5}
										maxLength={40}
									/>
								</div>
							</div>
						</div>
						<div className="mt-2 flex items-center justify-end">
							<Button
								type="button"
								size="icon-sm"
								className="w-[90px] p-1"
								onClick={handleSubmit(onSubmit)}
							>
								{addCreateTeamMemberLoading ||
								addExisitingMemberToLocationLoading ? (
									<div className="">
										<Loader size={20} />
									</div>
								) : isUpdateTeamMember ? (
									"Update"
								) : (
									"Add"
								)}
							</Button>
						</div>
					</>
				)}
			</div>

			{fields.length > 0 && (
				<div className="mt-4">
					<h3 className="mb-2 text-xl font-medium">
						Selected Member
					</h3>
					<div className="space-y-3">
						{fields.map((member: any) => (
							<div
								key={member.id}
								className="flex items-center justify-between rounded-lg bg-gray-100 p-3"
							>
								<div>
									<h2 className="text-md font-medium">
										{transformText(member.name || "")}{" "}
										<span className="font-thin">
											({transformText(member.role || "")})
										</span>
									</h2>
									<div className="flex space-x-3 text-sm text-gray-400">
										<p>{member.phone_number}</p>
										<p>{member.email}</p>
									</div>
								</div>
								<div className="flex">
									<Button
										variant="secondary"
										size="icon-sm"
										type="button"
										onClick={() => handleEditMember(member)}
									>
										<i className="mgc_edit_2_line mr-2 text-[24px]" />
									</Button>
									<Button
										variant="secondary"
										className="text-red-500"
										size="icon-sm"
										onClick={() =>
											handleDeleteMember(member)
										}
									>
										<i className="mgc_minus_circle_line before-text-red mr-2 text-[24px]" />
									</Button>
								</div>
							</div>
						))}
					</div>
				</div>
			)}
			<DeleteLocationModal
				locationName={selectedMember?.name || ""}
				showModal={showRemoveTeamMemberModal}
				setShowModal={setShowRemoveTeamMemberModal}
				isLoading={removeTeamMemberLoading}
				buttonAction={handleRemoveTeamMember}
				DeleteContent={DeleteStationContent}
				buttonText="Yes, remove member"
			/>
		</div>
	);
};

export default LocationMember;

const DeleteStationContent = ({ displayString }: { displayString: string }) => {
	return (
		<div className="space-y-4">
			<p className="mt-2 text-sm leading-[20px] -tracking-[0.1px] text-[#6D748D]">
				Are you sure you want to remove{" "}
				<span className="font-semibold">
					&quot;{displayString}?&quot;
				</span>
				{". "}
			</p>
			<p className="mt-2 text-sm leading-[20px] -tracking-[0.1px] text-[#6D748D]">
				This action cannot be undone.
			</p>
		</div>
	);
};
