import RequestIsLoading from "@/src/components/RequestIsLoading";
import { GetWeeklyHistorySlice } from "@/src/store/slices/serving/getServingSlice";
import { ScrollArea } from "@src/components/ui/scroll-area";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@src/components/ui/tooltip";
import { StatusOfPatient } from "@src/types/wait-list";
import { SinglePatientQueueData } from "@src/types/waitlist/waitlist";
import {
	cn,
	formatTimeRangeForHistory,
	toTitleCase,
} from "@src/utils/functions";
import React from "react";
import ServingPatientInformation from "./ServingPatientInformation/ServingPatientInformation";
import ServingPatientPriorityModal from "./ServingPatientPriorityModal";
import { Button } from "@/src/components/ui/button";
import { formattedPatientStatus } from "@/src/utils/constants";
import { format } from "date-fns";

const HistorySection: React.FC = () => {
	const getWeeklyHistoryQuery = GetWeeklyHistorySlice();

	const [activePatient, setActivePatient] =
		React.useState<SinglePatientQueueData>();
	const [showPatientInformation, setShowPatientInformation] =
		React.useState(false);
	const [showPriorityModal, setShowPriorityModal] = React.useState(false);

	return (
		<section className="flex h-full flex-1 flex-col pt-[28px]">
			<ScrollArea className="max-h-[75dvh] flex-1">
				{getWeeklyHistoryQuery.isLoading ? (
					<RequestIsLoading
						isLoading={
							// queryClient.getQueryState(["queue-list", currentLocationId])
							// 	?.status === "idle" ||
							// queryClient.getQueryState(["queue-list", currentLocationId])
							// 	?.status === "loading"
							getWeeklyHistoryQuery.isLoading
						}
						size={24}
					/>
				) : (
					<ul className="flex flex-1 flex-col space-y-2.5 py-2.5">
						<TooltipProvider>
							{getWeeklyHistoryQuery.data &&
								!getWeeklyHistoryQuery.isLoading &&
								getWeeklyHistoryQuery.data.data.map(
									(patient, index) => (
										<HistorySectionCard
											key={index}
											patient={patient}
											setActivePatient={setActivePatient}
											setShowPatientInformation={
												setShowPatientInformation
											}
										/>
									)
								)}
						</TooltipProvider>
					</ul>
				)}
			</ScrollArea>
			<ServingPatientInformation
				patientDetails={activePatient}
				showPatientInformation={showPatientInformation}
				setShowPatientInformation={setShowPatientInformation}
			/>
			<ServingPatientPriorityModal
				patientDetails={activePatient}
				showPriorityModal={showPriorityModal}
				setShowPriorityModal={setShowPriorityModal}
				setActivePatient={setActivePatient}
			/>
		</section>
	);
};

export default HistorySection;

const HistorySectionCard: React.FC<{
	patient: SinglePatientQueueData;
	setActivePatient: React.Dispatch<
		React.SetStateAction<SinglePatientQueueData | undefined>
	>;
	setShowPatientInformation: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ patient, setActivePatient, setShowPatientInformation }) => {
	const patientType = patient.waitlist ?? patient.appointment;
	return (
		<li
			className="m-[5px] flex items-center justify-between rounded-lg border border-[#E5E5E7] bg-white px-4 py-2 shadow-[0px_0px_8px_0px_rgba(0,0,0,0.15)]"
			onClick={() => {
				setActivePatient(patient);
			}}
		>
			<div className="flex flex-[0.25] flex-col">
				<div className="flex items-center space-x-4">
					<Tooltip delayDuration={0}>
						<TooltipTrigger asChild>
							<h3 className="max-w-[13ch] overflow-hidden text-ellipsis whitespace-nowrap text-[20px] font-semibold text-[#323539]">
								{patient.full_name}
							</h3>
						</TooltipTrigger>
						<TooltipContent
							side="top"
							className="capitalize"
							sideOffset={10}
						>
							{patient.full_name}
						</TooltipContent>
					</Tooltip>

					<Button
						className="h-[18px] w-[22px] rounded-sm bg-[#F5F5F5] p-0 hover:bg-[#F5F5F5]"
						onClick={(e) => {
							e.stopPropagation();
							setActivePatient?.(patient);
							setShowPatientInformation(true);
						}}
					>
						<i className="mgc_information_line text-[14px] before:!text-main-1" />
					</Button>
					<Button
						className={cn(
							"flex h-[18px] w-[22px] cursor-default items-center justify-center rounded-[4px] bg-[#EAEBF0] p-0 hover:bg-[#EAEBF0]"
						)}
					>
						<i
							className={cn(
								"mgc_tag_fill text-[14px] before:!text-[#969696]",
								{
									"before:!text-[#C8322B]":
										patientType?.priority === "high",
								}
							)}
						/>
					</Button>

					<i
						className={cn(`block size-2 rounded-full`, {
							"bg-[#2EBF43]":
								patientType?.check_in_status === "pending",
							"bg-destructive":
								patientType?.check_in_status ===
								"to_be_approved",
						})}
					/>
				</div>

				<Tooltip delayDuration={0}>
					<TooltipTrigger asChild>
						<div className="flex max-w-fit items-center space-x-1 py-2">
							<i className="mgc_shop_line before:!text-[#6D748D]" />
							<p className="max-w-[15ch] text-[14px] text-[#858C95]">
								{patientType?.station?.name}
							</p>
						</div>
					</TooltipTrigger>
					<TooltipContent
						side="top"
						className="capitalize"
						sideOffset={10}
					>
						{patientType?.station?.name}
					</TooltipContent>
				</Tooltip>
			</div>

			{patient.appointment && (
				<div className="flex min-w-fit max-w-fit flex-[0.14] items-center space-x-1.5 rounded-full bg-primary/5 px-3 py-1">
					<i className="mgc_calendar_time_add_line text-[18px] before:!text-[#323539]" />
					<p className="text-[12px]">
						Scheduled - {format(new Date(), "hh : mm a")}
					</p>
				</div>
			)}

			<ul className="flex items-center justify-between space-x-4">
				{patientType && patientType?.created_at && (
					<li className="flex items-center space-x-2">
						<i className="mgc_calendar_line before:!text-main-4" />
						<p className="text-sm text-main-1">
							{format(
								new Date(patientType?.created_at ?? "") ??
									new Date(),
								"dd MMM yyyy"
							)}
						</p>
					</li>
				)}
				{(patientType?.service_start_at ||
					patientType?.service_done_at) && (
					<li className="flex items-center space-x-2">
						<i className="mgc_time_line before:!text-main-4" />

						{patientType && (
							<p className="text-sm text-main-1">
								{patientType?.service_start_at &&
									format(
										new Date(
											patientType?.service_start_at ?? ""
										),
										"hh : mm a"
									) + " - "}

								{patientType?.service_done_at &&
									format(
										new Date(
											patientType?.service_done_at ?? ""
										),
										"hh : mm a"
									)}
							</p>
						)}
					</li>
				)}
			</ul>
			{patientType?.status && (
				<button
					className="grid h-7 w-[100px] cursor-default place-content-center rounded-md text-[14px] leading-[24px] tracking-[-1%] text-white"
					style={{
						backgroundColor:
							patientType?.status ===
							StatusOfPatient.GettingService
								? "#138576"
								: patientType?.status ===
									  StatusOfPatient.Upcoming
									? "#005893"
									: patientType?.status ===
										  StatusOfPatient.NextInLine
										? "#285A8A"
										: patientType?.status ===
											  StatusOfPatient.Pending
											? "#C8322B"
											: "#596574",
					}}
				>
					{formattedPatientStatus[patientType?.status] &&
						toTitleCase(
							formattedPatientStatus[patientType?.status].replace(
								/_/g,
								" "
							)
						)}
				</button>
			)}
		</li>
	);
};

// const patients: any = [
// 	{
// 		id: 21,
// 		customer_id: 21,
// 		waitlist_id: 21,
// 		station_id: 29,
// 		station_name: "Text 7",
// 		location_id: 15,
// 		location_name: "Demo test",
// 		business_id: 17,
// 		business_name: "Adetunji Dummyy 2",
// 		order_at: "2024-08-07 04:37:41",
// 		status: "done",
// 		order: 1,
// 		customer: {
// 			id: 21,
// 			full_name: "Customer 1",
// 			email: "cust1@gmail.com",
// 			phone_number: "00999433443",
// 			business_id: 17,
// 			application_type: "QUEUE",
// 			created_at: "2024-08-07T08:37:41.000000Z",
// 			updated_at: "2024-08-07T08:37:41.000000Z",
// 			health_card_expire_date: null,
// 			location_id: 15,
// 			station_id: 29,
// 			custom_informations: [],
// 		},
// 	},
// 	{
// 		id: 21,
// 		customer_id: 21,
// 		waitlist_id: 21,
// 		station_id: 29,
// 		station_name: "qedqedqe 7",
// 		location_id: 15,
// 		location_name: "Demo test",
// 		business_id: 17,
// 		business_name: "Adetunji Dummyy 2",
// 		order_at: "2024-08-07 04:37:41",
// 		status: "getting_service",
// 		order: 1,
// 		customer: {
// 			id: 21,
// 			full_name: "Adetunji Adeyinka Looopp",
// 			email: "cust1@gmail.com",
// 			phone_number: "00999433443",
// 			business_id: 17,
// 			application_type: "QUEUE",
// 			created_at: "2024-08-07T08:37:41.000000Z",
// 			updated_at: "2024-08-07T08:37:41.000000Z",
// 			health_card_expire_date: null,
// 			location_id: 15,
// 			station_id: 29,
// 			custom_informations: [],
// 		},
// 	},
// ];
