import { Button } from "@components/ui/button";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@components/ui/tooltip";
import Checkbox from "@src/components/ui-extended/checkbox";
import { CustomerData } from "@src/types/customers";
import { format } from "date-fns";
import CustomerDelete from "./CustomerDelete";
import { useState } from "react";
// import { useNavigate } from "react-router";

interface CustomerListCardProps {
	onSelect: () => void;
	checked: boolean;
	item: CustomerData;
	setShowPatientInformation: React.Dispatch<React.SetStateAction<boolean>>;
	setModalPatientInformation: React.Dispatch<
		React.SetStateAction<CustomerData | undefined>
	>;
}
const CustomerListCard = ({
	onSelect,
	item: patient,
	checked,
	setShowPatientInformation,
	setModalPatientInformation,
}: CustomerListCardProps) => {
	// const navigate = useNavigate();
	const [showDeleteCustomerModal, setShowDeleteCustomerModal] =
		useState(false);

	return (
		<div className="flex items-center justify-between rounded-[12px] bg-white px-4 py-3 shadow-[0px_0px_5px_0px_rgba(0,0,0,0.25)]">
			<div className="flex flex-1 flex-[0.19] items-center space-x-5">
				<Checkbox
					id={patient.id.toString()}
					isChecked={checked}
					className="rounded-sm border-2 border-[#D1D1D1]"
					handleCheckboxChange={onSelect}
				/>
				<p className="text-[18px] leading-[20px] tracking-[-0.1px] text-[#323539]">
					{patient?.full_name}
				</p>
				<Button
					className="h-[18px] w-[22px] rounded-sm bg-[#F5F5F5] p-0 hover:bg-[#F5F5F5]"
					onClick={() => {
						setModalPatientInformation?.(patient);
						setShowPatientInformation(true);
					}}
				>
					<i className="mgc_information_line text-[14px] before:!text-[#323539]" />
				</Button>
			</div>

			<div className="hidden flex-[0.55] items-center justify-between gap-x-4 lg:flex">
				<div className="flex flex-1 items-center space-x-2">
					<i className="mgc_hashtag_line text-[18px] before:!text-[#858C95]" />
					<small className="text-sm leading-[20px] tracking-[-0.1px] text-[#323539]">
						{patient?.id}
					</small>
				</div>
				{patient?.phone_number && (
					<div className="flex flex-1 items-center space-x-2">
						<i className="mgc_phone_line text-[18px] before:!text-[#858C95]" />
						<small className="text-sm leading-[20px] tracking-[-0.1px] text-[#323539]">
							{!patient?.phone_number.slice(0, -10).length &&
								patient?.phone_number.slice(0, -10)}{" "}
							{patient?.phone_number.slice(-10)}
						</small>
					</div>
				)}

				<div className="flex flex-1 items-center space-x-2">
					<i className="mgc_user_follow_line text-[18px] before:!text-[#858C95]" />
					<small className="text-sm capitalize leading-[20px] tracking-[-0.1px] text-[#323539]">
						{patient?.waitlist?.checked_in_confirmed || 0}
					</small>
				</div>
				<div className="flex flex-1 items-center space-x-2">
					<i className="mgc_calendar_line text-[18px] before:!text-[#858C95]" />
					<small className="text-sm capitalize leading-[20px] tracking-[-0.1px] text-[#323539]">
						{format(new Date(patient?.created_at), "dd MMM yyyy")}
					</small>
				</div>
			</div>
			<div className="hidden justify-end gap-1.5 lg:flex">
				<Tooltip delayDuration={0}>
					<TooltipTrigger asChild>
						<Button
							variant="outline-primary"
							className="size-fit px-2 py-2"
							onClick={() => {
								setModalPatientInformation?.(patient);
								setShowPatientInformation(true);
							}}
						>
							<i className="mgc_information_line text-[12px] before:!text-primary" />
						</Button>
					</TooltipTrigger>
					<TooltipContent side="top" sideOffset={10}>
						Patient Info
					</TooltipContent>
				</Tooltip>
				<Tooltip delayDuration={0}>
					<TooltipTrigger asChild>
						<Button
							variant="outline-primary"
							className="size-fit px-2 py-2"
							onClick={() => {
								setShowDeleteCustomerModal(true);
							}}
						>
							<i className="mgc_edit_2_line text-[12px] before:!text-primary" />
						</Button>
					</TooltipTrigger>
					<TooltipContent side="top" sideOffset={10}>
						Edit
					</TooltipContent>
				</Tooltip>
				<Tooltip delayDuration={0}>
					<TooltipTrigger asChild>
						<Button
							variant="outline-primary"
							className="size-fit px-2 py-2"
							// onClick={() =>
							// 	navigate("edit/" + patient.id, {
							// 		state: {
							// 			team_member: patient,
							// 		},
							// 	})
							// }
							onClick={() => {
								setShowDeleteCustomerModal(true);
							}}
						>
							<i className="mgc_delete_2_line text-[12px] before:!text-primary" />
						</Button>
					</TooltipTrigger>
					<TooltipContent side="top" sideOffset={10}>
						Delete
					</TooltipContent>
				</Tooltip>
				{/* <DeleteTeamMember teamMember={item} /> */}
				<div className="hidden">
					<CustomerDelete
						patientDetails={patient}
						setShowDeleteCustomerModal={setShowDeleteCustomerModal}
						showDeleteCustomerModal={showDeleteCustomerModal}
					/>
				</div>
			</div>
		</div>
	);
};

export default CustomerListCard;
