import useWaitlistWebSocket from "@src/hooks/waitlist/useWaitlistWebSocket";
import {
	APIVersion2BulkNotifyWaitlist,
	APIVersion2BulkUpdateWaitlist,
	APIVersion2ClearQueue,
	APIVersion2UpdateWaitlist,
} from "@src/http/v2";
import useUserStore from "@src/store/useUserStore";
import {
	BulkNotifyWaitlistCustomersType,
	BulkUpdateWaitlistType,
	QueueData,
	SinglePatientQueueWaitlistResponse,
	UpdateWaitlistType,
} from "@src/types/waitlist/waitlist";
import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";

const UpdateWaitlistSlice = (
	onSuccess: (data: SinglePatientQueueWaitlistResponse) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const { handleUpdateWaitlist } = useWaitlistWebSocket();
	const currentLocationId = useUserStore((s) => s.currentLocationId);
	const oldWaitlistData = useQueryClient().getQueryData([
		"queue-list",
		currentLocationId,
	]) as { data: QueueData };

	return useMutation<
		SinglePatientQueueWaitlistResponse,
		Error,
		UpdateWaitlistType
	>({
		mutationFn: APIVersion2UpdateWaitlist,
		onSuccess: (data) => {
			onSuccess(data);

			// queryClient.invalidateQueries("queue-list");
		},
		onError: (error) => {
			handleUpdateWaitlist(oldWaitlistData.data.queue_order);
			onError(error);

			console.error(error);
		},
	});
};

export default UpdateWaitlistSlice;

export const useClearQueue = (
	onSuccess: (data: unknown) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void = () => {
		return;
	}
) => {
	// const { handleUpdateWaitlist } = useWaitlistWebSocket();
	const currentLocationId = useUserStore((s) => s.currentLocationId);
	// const oldWaitlistData = useQueryClient().getQueryData([
	// 	"queue-list",
	// 	currentLocationId,
	// ]) as { data: QueueData };

	return useMutation<unknown, AxiosError>({
		mutationFn: () =>
			APIVersion2ClearQueue({ location_id: currentLocationId }),
		onSuccess: (data) => {
			onSuccess(data);

			// queryClient.invalidateQueries("queue-list");
		},
		onError: (error) => {
			// handleUpdateWaitlist(oldWaitlistData.data.queue_order);
			onError(error);

			console.error(error);
		},
	});
};

export const BulkUpdateWaitlistSlice = (
	onSuccess: (data: SinglePatientQueueWaitlistResponse) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const { handleUpdateWaitlist } = useWaitlistWebSocket();
	const currentLocationId = useUserStore((s) => s.currentLocationId);
	const oldWaitlistData = useQueryClient().getQueryData([
		"queue-list",
		currentLocationId,
	]) as { data: QueueData };

	return useMutation<
		SinglePatientQueueWaitlistResponse,
		Error,
		BulkUpdateWaitlistType
	>({
		mutationFn: APIVersion2BulkUpdateWaitlist,
		onSuccess: (data) => {
			onSuccess(data);

			// queryClient.invalidateQueries("queue-list");
		},
		onError: (error) => {
			handleUpdateWaitlist(oldWaitlistData.data.queue_order);
			onError(error);

			console.error(error);
		},
	});
};

export const BulkNotifyWaitlistSlice = (
	onSuccess: (data: SinglePatientQueueWaitlistResponse) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	return useMutation<
		SinglePatientQueueWaitlistResponse,
		Error,
		BulkNotifyWaitlistCustomersType
	>({
		mutationFn: APIVersion2BulkNotifyWaitlist,
		onSuccess,
		onError: (error) => {
			onError(error);

			console.error(error);
		},
	});
};
