import { Button } from "@src/components/ui/button";
import useAppointmentStore from "@src/store/useAppointmentStore";
import CalendarIcon from "../../../../../public/assets/images/appointment_confirmed.svg";
import { formatTime } from "@src/utils/functions";
import { Dispatch, SetStateAction, useMemo } from "react";

interface AppointmentConfirmationStepProps {
	closeModal: () => void;
	step?: number;
	setStep: Dispatch<SetStateAction<number>>;
}

const AppointmentConfirmationStep = ({
	closeModal,
	setStep,
}: AppointmentConfirmationStepProps) => {
	const {
		setAppointmentLocationDetails,
		appointmentSubmissionDetails,
		setAppointmentSubmissionDetails,
	} = useAppointmentStore();
	const appointmentDetails = appointmentSubmissionDetails;
	const handleDone = () => {
		closeModal();
		setStep(1);
		setAppointmentSubmissionDetails([]);
		setAppointmentLocationDetails([]);
	};

	const formattedDate = useMemo(() => {
		const date = appointmentDetails?.appointment_date?.split("T")[0];
		const time = formatTime(
			appointmentDetails?.appointment_date
				?.split("T")[1]
				?.split(".")[0] ?? "N/A"
		);
		return { date, time };
	}, [appointmentDetails?.appointment_date]);
	return (
		<div className="flex h-full flex-col gap-y-4 px-2.5 pb-1 pt-2.5 sm:w-[408px] md:w-full">
			<img
				src={CalendarIcon}
				alt="booking_image"
				className="aspect-auto w-[132px] self-center"
			/>
			<div className="mx-auto flex max-w-[500px] flex-col gap-y-8  pt-16 text-center">
				<div>
					<h1 className=" text-[24px] font-bold text-[#323539]">
						Appointment Scheduled Successfully
					</h1>
					<p className="text-sm text-[#6D748D]">
						You have successfully scheduled the appointment.
					</p>
				</div>

				<ul>
					<li className="flex items-center gap-2">
						<i className="mgc_user_follow_line text-[18px] before:!text-[#005893]" />
						<p className="font-semibold capitalize">
							{appointmentDetails?.customer?.full_name ?? "N?A"}
						</p>
					</li>
					<li className="flex items-center gap-2">
						<i className="mgc_schedule_line  text-[18px] before:!text-[#005893]" />
						<p className="font-semibold">
							{formattedDate.date} at {formattedDate.time}
						</p>
					</li>
					<li className="flex items-center gap-2">
						<i className="mgc_choice_line  text-[18px]  before:!text-[#005893]" />
						<p className="font-semibold">
							{appointmentDetails?.appointment_type ?? "N/A"}
						</p>
					</li>
					<li className="flex items-center gap-2">
						<i className="mgc_store_line  text-[18px]  before:!text-[#005893]" />
						<p className="font-semibold">
							{appointmentDetails?.station?.name ?? "N/A"}
						</p>
					</li>
					<li className="flex items-center gap-2">
						<i className="mgc_building_2_line  text-[18px] before:!text-[#005893]" />
						<p className="font-semibold">
							{appointmentDetails?.station?.location?.name ??
								"N/A"}
						</p>
					</li>
					<li className="flex items-center gap-2">
						<i className="mgc_location_line  text-[18px]  before:!text-[#005893]" />
						<p className="font-semibold">
							{appointmentDetails?.station?.location?.address ??
								"N/A"}
						</p>
					</li>
				</ul>
			</div>
			<div className="mt-auto flex w-full items-center gap-x-2 pt-10">
				<Button className="w-full" variant="outline-primary" disabled>
					Reschedule
				</Button>
				<Button
					className="w-full text-white"
					variant="default"
					onClick={handleDone}
				>
					Done
				</Button>
			</div>
		</div>
	);
};

export default AppointmentConfirmationStep;
