import { useEffect, useState } from "react";
import useCustomToast from "@/src/components/CustomToast";
import { Button } from "@/src/components/ui/button";
import { Switch } from "@/src/components/ui/switch";
import { DeleteLocationModal } from "@/src/pages/locations/components";
import { DeleteSlotContent } from "@/src/pages/locations/components/ScheduleSettings/CustomTimeSlot";
import { AddRemoveCustomSlotSlice } from "@/src/store/slices/custom-intake/customTimeSlotSlice";
import { GetStationDetailsSlice } from "@/src/store/slices/locations/locationSlice";
import useAppointmentStore from "@/src/store/useAppointmentStore";
import {
	CustomTimeSlotProps,
	queueSettingsProps,
	queueSettingsSchema,
} from "@/src/types/Location";
import { zodResolver } from "@hookform/resolvers/zod";
import EditDeletePill from "@src/pages/locations/components/EditDeletePill";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { FaRegFile } from "react-icons/fa";
import AddNewAppointmentTypeModal from "@/src/pages/locations/components/ScheduleSettings/AddNewAppointmentTypeModal";
import AddExistingAppointmentType from "../modal-components/AddExistingAppointmentType";
import { useQueryClient } from "react-query";

const CustomTimeSlots = () => {
	const queryClient = useQueryClient();
	const [modalMode, setModalMode] = useState<string | undefined>(undefined);
	const [selectedCustomField, setSelectedCustomField] =
		useState<CustomTimeSlotProps | null>(null);
	const [showDeleteSlotModal, setShowDeleteSlotModal] = useState(false);
	const { appointmentSettingsSelectedStation, appointmentSettings } =
		useAppointmentStore();
	const toast = useCustomToast();

	const handleSlotSelection = (slot: CustomTimeSlotProps) => {
		setSelectedCustomField(slot);
		setShowDeleteSlotModal(true);
	};

	const handleSlotEdit = (slot: CustomTimeSlotProps) => {
		setSelectedCustomField(slot);
		setModalMode("edit");
	};

	const methods = useForm<queueSettingsProps>({
		resolver: zodResolver(queueSettingsSchema),
		defaultValues: {
			custom_time_slots: [],
		},
	});

	const {
		reset,
		control,
		watch,
		formState: { errors },
	} = methods;

	const { fields } = useFieldArray<queueSettingsProps>({
		control,
		name: "custom_time_slots",
		keyName: "_id" as "id",
	});

	const { isLoading: removeFieldLoading, mutate: removeFieldMutation } =
		AddRemoveCustomSlotSlice(
			() => {
				queryClient.invalidateQueries(["get-queue-settingss"]);
				setShowDeleteSlotModal(false);
			},
			() => {
				// console.error("Update failed:", error);
				toast("", {
					id: "add-remove-custom-slot",
					type: "dismiss",
				});
			}
		);

	const handleRemoveSlot = () => {
		toast("Removing slot", {
			id: "add-remove-custom-slot",
			type: "loading",
		});
		if (selectedCustomField) {
			removeFieldMutation(
				{
					id: selectedCustomField.id,
					data: {
						station_ids_to_remove: [
							parseInt(appointmentSettingsSelectedStation) || 0,
						],
					},
				},
				{
					onSuccess: () => {
						queryClient.invalidateQueries(["get-queue-settingss"]);
					},
				}
			);
		}
	};

	const handleAddExistingSlot = () => {
		setSelectedCustomField(null);
		setModalMode("existing");
	};

	// const handleSwitchChange = (fieldPath: any) => (value: boolean) => {
	// 	const newValue = value ? 1 : 0;
	// 	setValue(fieldPath, value);
	// 	const updatedData = createNestedObject(fieldPath, newValue);
	// 	updateQueueSettings({
	// 		data: {
	// 			...updatedData,
	// 			apply_to_option: {
	// 				apply_to_all: 0,
	// 				locations: [
	// 					{
	// 						id: stationData?.data?.location_id || 0,
	// 						update_location: 0,
	// 						apply_to_all_stations: 0,
	// 						selected_stations: [stationData?.data?.id || 0],
	// 					},
	// 				],
	// 			},
	// 		},
	// 		queryParams: { station_id: stationData?.data?.id || 0 },
	// 	});
	// 	customToast("Updating queue settings", {
	// 		id: "update-queue-settings",
	// 		type: "loading",
	// 	});
	// };

	useEffect(() => {
		reset({
			custom_time_slots: appointmentSettings?.custom_time_slots,
		});
	}, [reset, appointmentSettings]);
	return (
		<div className="flex flex-col gap-y-4">
			<h3 className="text-xl font-semibold">Custom Appointment Types</h3>
			<div className=" space-y-5">
				<div className="flex items-center border-b pb-5">
					<div className="flex-1">
						<p>Activate Custom Time Slots</p>
						<p className="text-sm text-gray-400">
							These slots allow patients to book specific
							appointments with allocated times
						</p>
					</div>
					<div
						className="flex items-center"
						onClick={(e) => e.stopPropagation()}
					>
						<Controller
							name="schedule_setting.is_open"
							control={control}
							render={({ field }) => (
								<Switch
									className="scale-[0.70] data-[state=checked]:bg-[#2EBF43]"
									checked={Boolean(field.value)}
									// onCheckedChange={handleSwitchChange(
									// 	"schedule_setting.is_open"
									// )}
								/>
							)}
						/>
						<span className="">
							{watch("schedule_setting.is_open") ? "On" : "Off"}
						</span>
					</div>
				</div>
				<div className="flex items-center gap-x-5">
					<Button
						variant={"link"}
						className="h-auto rounded-none border-b-2 border-primary p-0 font-semibold leading-none outline-none hover:no-underline"
						onClick={handleAddExistingSlot}
					>
						Add from Existing
					</Button>
					<AddNewAppointmentTypeModal
						modalMode={modalMode}
						setModalMode={setModalMode}
						selectedSlot={selectedCustomField}
					/>
					<div className="hidden">
						<AddExistingAppointmentType
							modalMode={modalMode}
							setModalMode={setModalMode}
						/>
					</div>
				</div>

				{fields.length > 0 ? (
					<div className="space-y-3 pb-4">
						<h3 className="text-xl font-semibold">
							Selected Time Slots for this Station
						</h3>
						{fields.map((field) => (
							<EditDeletePill
								key={field.id}
								slotData={field}
								handleSlotSelection={handleSlotSelection}
								handleSlotEdit={handleSlotEdit}
							/>
						))}
					</div>
				) : (
					<div className="space-y-1 rounded-xl bg-[#F8F8F8] p-6 text-center">
						<FaRegFile
							size={28}
							className="mx-auto text-gray-500"
						/>
						<p className="font-semibold">
							No custom time slots have been added
						</p>
						<p className="text-gray-400">
							Custom time slots will appear here once created.
						</p>
					</div>
				)}
			</div>
			<DeleteLocationModal
				locationName={selectedCustomField?.title || ""}
				showModal={showDeleteSlotModal}
				setShowModal={setShowDeleteSlotModal}
				isLoading={removeFieldLoading}
				buttonAction={handleRemoveSlot}
				DeleteContent={DeleteSlotContent}
				buttonText="Yes, remove slot"
			/>
		</div>
	);
};

export default CustomTimeSlots;
