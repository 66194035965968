import { useState, useRef, FC } from "react";

interface ImageData {
	src: string | undefined | null;
	alt: string;
	imageClass?: string;
}

const getDefaultImageDataUrl = (): string => {
	const svg = `
		<svg width="114" height="114" viewBox="0 0 114 114" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="0.5" y="0.5" width="113" height="113" rx="56.5" fill="#EFEFF1" stroke="#E5E5E7"/>
			<g clip-path="url(#clip0_53_19351)">
				<path d="M58.3333 45C59.006 44.9998 59.654 45.2539 60.1473 45.7113C60.6407 46.1688 60.9428 46.7958 60.9933 47.4667L60.9999 47.6667V53H64.9999C65.6727 52.9998 66.3207 53.2539 66.814 53.7113C67.3073 54.1688 67.6095 54.7958 67.6599 55.4667L67.6666 55.6667V66.3333H68.9999C69.3398 66.3337 69.6667 66.4638 69.9138 66.6971C70.1609 66.9304 70.3096 67.2493 70.3295 67.5885C70.3494 67.9278 70.239 68.2618 70.0209 68.5224C69.8028 68.783 69.4934 68.9505 69.1559 68.9907L68.9999 69H44.9999C44.6601 68.9996 44.3332 68.8695 44.0861 68.6362C43.839 68.4029 43.6903 68.0841 43.6704 67.7448C43.6505 67.4056 43.7608 67.0715 43.979 66.8109C44.1971 66.5503 44.5065 66.3828 44.8439 66.3427L44.9999 66.3333H46.3333V47.6667C46.3331 46.9939 46.5872 46.3459 47.0446 45.8526C47.5021 45.3593 48.1291 45.0571 48.7999 45.0067L48.9999 45H58.3333ZM64.9999 55.6667H60.9999V66.3333H64.9999V55.6667ZM58.3333 47.6667H48.9999V66.3333H58.3333V47.6667ZM55.6666 61V63.6667H51.6666V61H55.6666ZM55.6666 55.6667V58.3333H51.6666V55.6667H55.6666ZM55.6666 50.3333V53H51.6666V50.3333H55.6666Z" fill="#979AA0"/>
			</g>
			<defs>
				<clipPath id="clip0_53_19351">
					<rect width="32" height="32" fill="white" transform="translate(41 41)"/>
				</clipPath>
			</defs>
		</svg>
  `;
	return `data:image/svg+xml;base64,${btoa(svg)}`;
};

export const CustomImage: FC<ImageData> = ({ src, alt, imageClass }) => {
	const [error, setError] = useState(false);
	const defaultImage = getDefaultImageDataUrl();

	const imgRef = useRef<HTMLImageElement>(null);

	const handleError = () => {
		setError(true);
	};

	return (
		<img
			className={`h-auto w-auto object-cover object-center ${
				imageClass ? imageClass : ""
			}`}
			src={error || !src ? defaultImage : src}
			alt={alt}
			ref={imgRef}
			onError={handleError}
		/>
	);
};
