import useCustomToast from "@src/components/CustomToast";
import {
	APIVersion1GetBusinessCategories,
	APIVersion1UpdateBusinessAccountSettings,
	APIVersion1UpdateBusinessLogoSettings,
} from "@src/http/v1";
import useUserStore from "@src/store/useUserStore";
import { UpdateBusinessSettingsType } from "@src/types/settings/business-account";
import { AxiosResponse } from "axios";
import { useMutation, useQuery } from "react-query";

export const UpdateBusinessSettings = (
	onSuccess: (data: unknown) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const customToast = useCustomToast();
	// const queryClient = useQueryClient();

	return useMutation<unknown, Error, UpdateBusinessSettingsType>(
		APIVersion1UpdateBusinessAccountSettings,
		{
			onSuccess: (data) => {
				onSuccess(data);
				setTimeout(() => {
					customToast("Business Account updated successfully 🎉", {
						id: "oscar-emr-url",
						type: "success",
					});
				}, 750);
			},
			onError: (error) => {
				onError(error);
				customToast("Business Account could not be updated 🤕", {
					id: "oscar-emr-url",
					type: "error",
				});

				// console.error(error);
			},
		}
	);
};

export const UpdateBusinessLogoSettings = (
	onSuccess: (data: unknown) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const customToast = useCustomToast();
	const user = useUserStore((s) => s.user);
	const setUser = useUserStore((s) => s.setUser);

	return useMutation<AxiosResponse<string>, Error, FormData>(
		APIVersion1UpdateBusinessLogoSettings,
		{
			onSuccess: (data) => {
				onSuccess(data);
				console.log(data);
				if (user)
					setUser({
						...user,
						business: {
							...user.business,
							logo_url: data.data,
						},
					});
				setTimeout(() => {
					customToast("Logo updated successfully 🎉", {
						id: "oscar-emr-url",
						type: "success",
					});
				}, 200);
			},
			onError: (error) => {
				onError(error);
				customToast("Logo could not be updated 🤕", {
					id: "oscar-emr-url",
					type: "error",
				});

				// console.error(error);
			},
		}
	);
};

export const useGetBusinessCategories = (
	onSuccess: (
		data: AxiosResponse<
			{
				id: number;
				name: string;
				created_at: Date | null;
				updated_at: Date | null;
			}[]
		>
	) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	return useQuery<
		AxiosResponse<
			{
				id: number;
				name: string;
				created_at: Date | null;
				updated_at: Date | null;
			}[]
		>,
		Error
	>("business-categories", APIVersion1GetBusinessCategories, {
		onSuccess,
		onError,
	});
};
