import { Dispatch, SetStateAction, useState } from "react";
import ModalWrapper from "@components/common/ModalWrapper";
import AppointmentFormStep from "../components/modal-components/AppointmentFormStep";
import AppointmentStationSelectionStep from "../components/modal-components/AppointmentStationSelectionStep";
import AppointmentCustomFieldsStep from "../components/modal-components/AppointmentCustomFieldsStep";
import { AppointmentFormProvider } from "../helpers/AppointmentFormContext";
import AppointmentConfirmationStep from "../components/modal-components/AppointmentConfirmationStep";

interface AddAppointmentProps {
	show: boolean;
	setShow: Dispatch<SetStateAction<boolean>>;
}

const AddAppointment = ({ show, setShow }: AddAppointmentProps) => {
	const [step, setStep] = useState<number>(1);
	const handleClose = () => {
		setStep(1);
		setShow(false);
	};
	return (
		<ModalWrapper
			className=" h-full max-h-[708px]  min-w-[746px] rounded-[12px]"
			isPersistent
			show={show}
			setShow={setShow}
			handleClose={handleClose}
		>
			<AppointmentFormProvider>
				{step == 1 && (
					<AppointmentFormStep step={step} setStep={setStep} />
				)}
				{step == 2 && (
					<AppointmentStationSelectionStep
						step={step}
						setStep={setStep}
						closeModal={() => setShow(false)}
					/>
				)}
				{step == 3 && (
					<AppointmentCustomFieldsStep
						step={step}
						setStep={setStep}
					/>
				)}
				{step == 4 && (
					<AppointmentConfirmationStep
						setStep={setStep}
						closeModal={() => setShow(false)}
					/>
				)}
			</AppointmentFormProvider>
		</ModalWrapper>
	);
};

export default AddAppointment;
