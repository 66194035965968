import React, { useRef, useState } from "react";
import Overlay from "@components/Overlay";
import { Button } from "@components/ui/button";
import { Card } from "@components/ui/card";
import { IoClose } from "react-icons/io5";
import FormInput from "@components/form/FormInput";
import Loader from "@src/components/Loader/Loader";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { AddStationType, addStationSchema } from "@src/types/Station";
import { AddBusinessLocationStationSlice } from "@src/store/slices/locations/locationSlice";
import { getErrorMessage } from "@utils/functions";

export const AddStationModal: React.FC<{
	locationId: number;
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
	showModal: boolean;
	isLoading: boolean;
	buttonAction: () => void;
}> = ({ locationId, showModal, setShowModal, isLoading }) => {
	const modalRef = useRef(null);
	const [statusStep, setStatusStep] = useState(false);

	const addBusinessLocationStationMutation = AddBusinessLocationStationSlice(
		() => {
			setStatusStep(true);
		},
		(error) => console.log("🚀 ~ LocationTabList ~ error:", error)
	);

	const methods = useForm<AddStationType>({
		resolver: zodResolver(addStationSchema),
		defaultValues: {
			name: "",
		},
	});

	const {
		handleSubmit,
		register,
		getValues,
		reset,
		formState: { errors, isValid },
	} = methods;

	const handleAddStation: SubmitHandler<AddStationType> = async (data) => {
		if (isValid) {
			const body: AddStationType = {
				name: data.name,
			};
			addBusinessLocationStationMutation.mutate({
				id: locationId || 0,
				data: body,
			});
			return;
		}
	};

	const handleCloseModal = () => {
		setShowModal(false);
		setStatusStep(false);
		reset({
			name: "",
		});
	};

	const resetForm = () => {
		setStatusStep(false);
		reset({
			name: "",
		});
	};

	const RenderScreen = (activeStep: boolean) => {
		switch (activeStep) {
			case true:
				return (
					<Card
						className="relative z-50 w-full max-w-[400px] space-y-8 bg-white p-5 text-main-1"
						ref={modalRef}
					>
						<div className="flex items-center justify-between space-x-2">
							<h3 className="text-xl font-semibold">
								Station added! 🥳
							</h3>
							<Button
								type="button"
								className="m-0 bg-transparent p-0 text-black hover:bg-transparent"
								onClick={handleCloseModal}
							>
								<IoClose className=" text-black" size={24} />
							</Button>
						</div>
						<div className="space-y-8">
							<p>
								<span className="capitalize">
									{getValues().name || "Station"}
								</span>{" "}
								has been added to this location!
							</p>
							<p>
								You can{" "}
								<span className="font-bold">
									customize your station
								</span>{" "}
								by going to the station details page!
							</p>
						</div>
						<div className="mt-6 flex justify-end space-x-2">
							<Button
								type="button"
								className="font-semibold text-[#323539]"
								variant={"ghost"}
								onClick={handleCloseModal}
							>
								Cancel
							</Button>
							<Button
								type="button"
								className="min-w-[147px] font-semibold"
								onClick={() => {}}
							>
								Go to Station Details
							</Button>
						</div>
					</Card>
				);
			case false:
				return (
					<Card
						className="relative z-50 w-full max-w-[500px] space-y-8 bg-white p-5 text-main-1"
						ref={modalRef}
					>
						<div className="flex items-center justify-between space-x-2">
							<h3 className="text-[22px] font-semibold">
								Add a Station
							</h3>
							<Button
								type="button"
								className="m-0 bg-transparent p-0 text-black hover:bg-transparent"
								onClick={() => setShowModal(false)}
							>
								<IoClose className=" text-black" size={24} />
							</Button>
						</div>
						<form onSubmit={handleSubmit(handleAddStation)}>
							<div className="flex flex-1 flex-col space-x-8">
								<FormInput
									inputType={"text"}
									label="Station Name"
									labelStyles="font-medium"
									defaultValue={getValues().name}
									register={{ ...register("name") }}
									className="h-10"
									placeholder="Enter the name of the station"
									error={getErrorMessage(
										errors.name?.message
									)}
									maxLength={40}
								/>
								<div className="mt-6 flex justify-end space-x-2">
									<Button
										type="button"
										className="font-semibold text-[#323539]"
										variant={"ghost"}
										onClick={() => setShowModal(false)}
									>
										Cancel
									</Button>
									<Button
										className="min-w-[147px] font-semibold"
										type="submit"
									>
										{addBusinessLocationStationMutation.isLoading ? (
											<Loader size={24} />
										) : (
											"Add Station"
										)}
									</Button>
								</div>
							</div>
						</form>
					</Card>
				);
			default:
				return "";
		}
	};

	return (
		<Overlay
			show={showModal}
			setShow={setShowModal}
			modalRef={modalRef}
			isPersistent={isLoading}
		>
			{RenderScreen(statusStep)}
		</Overlay>
	);
};
