import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "../ui/select";

export type Option = {
	label: string;
	value: string;
	disabled?: boolean;
	metadata?: any;
};

type Props = {
	options: Option[];
	placeholder?: string;
	label?: string;
	className?: string;
	itemClassName?: string;
	labelClassName?: string;
	onChange: (value: string) => void;
	value?: string;
	valueKey?: string;
	defaultValue?: string;
	hasChevron?: boolean;
	error?: string | null;
};

export const CustomSelect = ({
	options,
	placeholder,
	label,
	className,
	itemClassName,
	labelClassName,
	value,
	defaultValue,
	hasChevron = true,
	error,
	onChange,
}: Props) => {
	return (
		<>
			<Select
				// {...(defaultValue && { defaultValue })}
				defaultValue={defaultValue}
				value={value}
				onValueChange={onChange}
			>
				<SelectTrigger className={className} hasChevron={hasChevron}>
					<SelectValue
						placeholder={
							placeholder ? placeholder : "Select an item"
						}
						className="text-[#858C95]"
					/>
				</SelectTrigger>
				<SelectContent className="!z-[9999]">
					<SelectGroup>
						{label ? (
							<SelectLabel className={labelClassName}>
								{label}
							</SelectLabel>
						) : null}
						{options.map((option) => {
							return (
								<SelectItem
									key={option.value}
									value={option.value}
									className={itemClassName}
								>
									{option.label}
								</SelectItem>
							);
						})}
					</SelectGroup>
				</SelectContent>
			</Select>
			{error && error.length && (
				<small className="mt-1.5 text-sm text-red-500">{error}</small>
			)}
		</>
	);
};
