import RequestIsLoading from "@/src/components/RequestIsLoading";
import { Label } from "@/src/components/ui/label";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/src/components/ui/select";
import {
	GetQueueSettingsSlice,
	QueueSettingsSlice,
	UpdateLocationOperatingHoursSlice,
} from "@/src/store/slices/locations/locationSlice";
import useUserStore from "@/src/store/useUserStore";
import { queueSettingsProps, queueSettingsSchema } from "@/src/types/Location";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogTitle,
	DialogTrigger,
} from "@components/ui/dialog";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@src/components/ui/button";
import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "@src/components/ui/tabs";
import { isEqual } from "lodash";
import React, { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import GeneralSettings from "./GeneralSettings";
import OperatingHours from "./OperatingHours";
import WaitlistSettingsSaveModal from "./WaitlistSettingsSaveModal";
import { convertSchedule, sortWorkingHoursByDay } from "@/src/utils/functions";
import { OperatingHour } from "@/src/types/DaySlots";
import { QueueData } from "@/src/types/waitlist/waitlist";

const WaitlistSettings: React.FC = () => {
	const queryClient = useQueryClient();
	const { user, currentLocationId } = useUserStore((s) => ({
		user: s.user,
		currentLocationId: s.currentLocationId,
	}));
	const [selectedLocation, setSelectedLocation] = useState(currentLocationId);
	const [activeState, setActiveState] = useState<
		"general" | "operating-hours"
	>("general");
	const [autoClearingMinuteFormat, setAutoClearingMinuteFormat] =
		React.useState<"minutes" | "hours">("minutes");
	const getQuerySettingsQuery = GetQueueSettingsSlice({
		location_id: selectedLocation,
	});

	const methods = useForm<queueSettingsProps>({
		defaultValues: getQuerySettingsQuery?.data?.data ?? undefined,
		resolver: zodResolver(queueSettingsSchema),
	});

	const [showWaitlistSettingsModal, setShowWaitlistSettingsModal] =
		useState(false);

	const [isDisabled, setIsDisabled] = React.useState(true);
	const [showApplyModal, setShowApplyModal] = useState<boolean>(false);

	const businessSettingsMutation = QueueSettingsSlice((data) => {
		setShowApplyModal(false);
		queryClient.setQueryData<
			| {
					status: boolean;
					data: queueSettingsProps;
			  }
			| undefined
		>(
			["get-queue-settingss", { location_id: selectedLocation }],
			(prevData) => {
				if (!prevData) return;
				return { ...prevData, data: data.data };
			}
		);
		queryClient.setQueryData<
			{ status: boolean; data: QueueData; message: string } | undefined
		>(["queue-list", selectedLocation], (prevData) => {
			if (!prevData) return;
			return {
				...prevData,
				data: {
					queue_order: prevData.data.queue_order,
					queue_settings: {
						auto_flow: data?.data?.waitlist_setting?.auto_flow ?? 0,
						average_wait_time:
							prevData.data.queue_settings.average_wait_time ?? 0,
						is_active: Boolean(
							data?.data?.waitlist_setting?.is_queue_active
						),
					},
				},
			};
		});
		setAutoClearingMinuteFormat("minutes");
	});

	const operatingHourSettingsMutation = UpdateLocationOperatingHoursSlice(
		(data: { data: { default_working_hours: OperatingHour[] } }) => {
			setShowApplyModal(false);
			console.log(data);
			queryClient.setQueryData<
				| {
						status: boolean;
						data: queueSettingsProps;
				  }
				| undefined
			>(
				["get-queue-settingss", { location_id: selectedLocation }],
				(prevData) => {
					if (!prevData) return;
					return {
						...prevData,
						data: {
							...prevData.data,
							working_hours: data.data.default_working_hours,
						},
					};
				}
			);
		}
	);

	const updateGeneralSettigs: SubmitHandler<queueSettingsProps> = async (
		data
	) => {
		businessSettingsMutation.mutate({
			data: {
				waitlist_setting: {
					...data.waitlist_setting,
					auto_clearing_minute:
						autoClearingMinuteFormat === "minutes"
							? data?.waitlist_setting?.auto_clearing_minute
							: (data?.waitlist_setting?.auto_clearing_minute ??
									0) * 60,
				},
				apply_to_option: data.apply_to_option,
			},
			queryParams: {
				location_id: selectedLocation,
			},
		});
	};

	const updateOperatingHourSettigs: SubmitHandler<
		queueSettingsProps
	> = async (data) => {
		operatingHourSettingsMutation.mutate({
			data: {
				data: convertSchedule(data.working_hours),
				apply_to_option: data.apply_to_option,
				waitlist_schedule_option: "waitlist",
				// child_update_strategy: "override", This is not used or related to the current implementation
			},
			queryParams: {
				location_id: selectedLocation,
			},
		});
	};

	React.useEffect(() => {
		const subscription = methods.watch(() => {
			setIsDisabled(
				isEqual(methods.getValues(), {
					...getQuerySettingsQuery?.data?.data,
					working_hours: sortWorkingHoursByDay(
						getQuerySettingsQuery?.data?.data?.working_hours ?? []
					),
					apply_to_option: {
						apply_to_all: 1,
						locations: [],
					},
				})
			);
		});
		return () => subscription.unsubscribe();
	}, [methods.watch, getQuerySettingsQuery?.data?.data]);

	React.useEffect(() => {
		if (
			getQuerySettingsQuery?.data?.data &&
			getQuerySettingsQuery?.isSuccess
		) {
			methods.reset({
				...getQuerySettingsQuery?.data?.data,
				working_hours: sortWorkingHoursByDay(
					getQuerySettingsQuery?.data?.data?.working_hours ?? []
				),
				apply_to_option: {
					apply_to_all: 1,
					locations: [],
				},
			});
		}
	}, [getQuerySettingsQuery?.data?.data, getQuerySettingsQuery?.isSuccess]);

	// console.log(
	// 	queryClient.getQueryState([
	// 		"get-queue-settingss",
	// 		{ location_id: selectedLocation },
	// 	])
	// );
	// console.log(methods.formState.errors);
	return (
		<>
			<Dialog
				open={showWaitlistSettingsModal}
				onOpenChange={setShowWaitlistSettingsModal}
			>
				<DialogTrigger asChild>
					<Button
						variant="outline"
						className="size-9 shadow-[0px_1px_2px_0_rgba(16,24,40,0.04)]"
					>
						<i className="mgc_settings_5_line text-[18px] before:stroke-2 before:!text-[#4E4E4E]" />
					</Button>
				</DialogTrigger>
				<FormProvider {...methods}>
					<DialogContent className="flex min-h-[620px] max-w-[1153px] flex-col space-y-8 p-0">
						<form
							className="flex w-full flex-1 flex-col justify-between space-y-8 p-0"
							onSubmit={
								activeState === "general"
									? methods.handleSubmit(updateGeneralSettigs)
									: methods.handleSubmit(
											updateOperatingHourSettigs
										)
							}
						>
							<DialogTitle className="border-b-main-[#D4D3D3] flex flex-col items-stretch border-b px-6 pb-2.5 pt-6">
								<button
									className="self-end"
									onClick={() =>
										setShowWaitlistSettingsModal(
											!showWaitlistSettingsModal
										)
									}
								>
									<i className="mgc_close_line text-[24px] before:!text-main-7"></i>
								</button>
								<h1 className="tracknig-[-1%] text-[22px] font-semibold leading-[30px] text-main-1">
									Waitlist Settings for{" "}
									{user?.business?.locations.find(
										(location) =>
											location.id == +selectedLocation
									)?.name ?? ""}
								</h1>
							</DialogTitle>
							<div className="flex flex-col space-y-2.5  px-6">
								<div>
									<h2
										className="text-base font-medium text-main-1"
										onClick={() =>
											console.log(methods.getValues())
										}
									>
										Select a Location
									</h2>
									<Label className="text-base font-normal text-main-7">
										Select from the dropdown below to{" "}
										<span className="font-semibold">
											view
										</span>{" "}
										and{" "}
										<span className="font-semibold">
											edit
										</span>{" "}
										a specific station&apos;s schedule
										settings
									</Label>
								</div>
								<Select
									value={selectedLocation?.toString()}
									onValueChange={(e) => {
										const newLocationId =
											user?.business?.locations.find(
												(location) => location.id == +e
											)?.id ?? 0;
										setSelectedLocation(newLocationId);

										queryClient.invalidateQueries([
											"get-queue-settingss",
											{ location_id: newLocationId },
										]);
									}}
								>
									<SelectTrigger className="flex-1">
										<SelectValue />
									</SelectTrigger>
									<SelectContent>
										{user?.business?.locations?.map(
											(location, i) => (
												<SelectItem
													key={i}
													value={location.id.toString()}
												>
													{location.name}
												</SelectItem>
											)
										)}
									</SelectContent>
								</Select>
							</div>

							<Tabs
								defaultValue="general"
								className="relative flex h-full flex-1 px-6"
							>
								<div className="flex min-w-[209px] flex-col space-y-1">
									<TabsList className="text-basecolor flex h-full flex-col items-start justify-normal gap-y-0 bg-transparent text-left shadow-none">
										{tabsData.map((tab) => (
											<TabsTrigger
												key={tab.value}
												className="group flex w-full items-center justify-between rounded-[8px] border-none px-4 py-2 text-[#323539] shadow-none drop-shadow-none transition-all duration-200 ease-in-out data-[state=active]:bg-primary/[0.05] data-[state=active]:text-primary"
												value={tab.value}
												onClick={() =>
													setActiveState(
														tab.value as
															| "general"
															| "operating-hours"
													)
												}
											>
												{tab.label}
											</TabsTrigger>
										))}
									</TabsList>
								</div>
								<div className="flex flex-1 flex-col">
									<TabsContent
										value="general"
										className="mt-0 flex w-full data-[state=inactive]:hidden data-[state=active]:flex-1"
									>
										<GeneralSettings
											formMethods={methods}
											autoClearingMinuteFormat={
												autoClearingMinuteFormat
											}
											setAutoClearingMinuteFormat={
												setAutoClearingMinuteFormat
											}
										/>
									</TabsContent>
									<TabsContent
										value="operating-hours"
										className="mt-0 flex w-full data-[state=inactive]:hidden data-[state=active]:flex-1"
									>
										<OperatingHours formMethods={methods} />
									</TabsContent>
								</div>
								<RequestIsLoading
									isLoading={getQuerySettingsQuery.isLoading}
									size={20}
								/>
							</Tabs>
							<DialogFooter className="flex flex-col p-6 shadow-[2px_0px_10px_0_rgba(0,0,0,0.15)]">
								<Button
									className="h-[46px] text-destructive hover:text-destructive/90"
									variant="ghost"
									type="button"
									onClick={() => {
										setShowWaitlistSettingsModal(false);
									}}
								>
									Cancel
								</Button>

								<Button
									className="h-[46px] max-w-[100px] flex-1"
									type="button"
									disabled={
										activeState === "general"
											? isDisabled
											: isDisabled
									}
									onClick={() => {
										setShowApplyModal(true);
									}}
								>
									Save
								</Button>
							</DialogFooter>
							<WaitlistSettingsSaveModal
								showApplyModal={showApplyModal}
								setShowApplyModal={setShowApplyModal}
								onSubmit={methods.handleSubmit(
									updateGeneralSettigs
								)}
								isLoading={
									businessSettingsMutation.isLoading ||
									operatingHourSettingsMutation.isLoading
								}
							/>
						</form>
					</DialogContent>
				</FormProvider>
			</Dialog>
		</>
	);
};

export default WaitlistSettings;

const tabsData = [
	{ value: "general", label: "General" },
	{ value: "operating-hours", label: "Operating Hours" },
];
