import UpdateWaitlistSlice from "@/src/store/slices/waitlist/updateWaitlistSlice";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	SinglePatientQueueWaitlistResponse,
	UpdateWaitlistEnum,
	UpdateWaitlistSchema,
	UpdateWaitlistType,
} from "@type/waitlist/waitlist";
import React from "react";
import { useForm } from "react-hook-form";
import useCustomToast from "../../CustomToast";
import { Button } from "../../ui/button";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../ui/tooltip";

const PatientSentToSentToDoneModal: React.FC<{
	waitlist_id: number;
	customer_id: number;
	onSuccess?: (data: SinglePatientQueueWaitlistResponse) => void;
}> = ({ waitlist_id, customer_id, onSuccess }) => {
	const { getValues } = useForm<UpdateWaitlistType>({
		resolver: zodResolver(UpdateWaitlistSchema),
		defaultValues: {
			status: UpdateWaitlistEnum.Done,
			waitlist_id: waitlist_id ?? 0,
		},
	});

	const customToast = useCustomToast();

	const updateWaitlistMutation = UpdateWaitlistSlice(
		(data) => {
			customToast(`Patient marked as done`, {
				id: "manage-patient-" + customer_id,
				type: "success",
			});
			onSuccess?.(data);
		},
		() => {
			customToast(`Patient could not be marked as done`, {
				id: "manage-patient-" + customer_id,
				type: "error",
			});
		}
	);

	return (
		<>
			<Tooltip delayDuration={1000}>
				<TooltipTrigger>
					<Button
						className="flex size-[30px] items-center justify-center rounded-md bg-[#1E8A7C] p-0 hover:bg-[#1E8A7C]/90"
						disabled={updateWaitlistMutation.isLoading}
						onClick={(e) => {
							e.stopPropagation();
							customToast("Marking patient as done", {
								id: "manage-patient-" + customer_id,
								type: "loading",
							});
							updateWaitlistMutation.mutate(getValues());
						}}
					>
						<i className="mgc_check_circle_fill text-[14px] before:!text-white" />
					</Button>
				</TooltipTrigger>
				<TooltipContent side="top" sideOffset={4}>
					Service Done
				</TooltipContent>
			</Tooltip>
			{/* <Overlay
				show={showPatientSentToServingModal}
				setShow={setShowPatientSentToServingModal}
				modalRef={modalRef}
			>
				<form className="w-full max-w-[400px]">
					<Card className="flex flex-col space-y-4 p-0">
						<div className="space-y-1">
							<CardHeader className="p-4 pb-0">
								<div className="flex justify-between">
									<CardTitle className="text-[22px] leading-[30px] -tracking-[1%]">
										Sent to Serving
									</CardTitle>
									<LuX
										color="#858C95"
										size={20}
										className="cursor-pointer self-center"
										onClick={() =>
											setShowPatientSentToServingModal(
												false
											)
										}
									/>
								</div>
							</CardHeader>
							<CardContent className="px-4 py-0 text-sm leading-[20px] tracking-[-0.1px] text-main-4">
								{toTitleCase(patientDetails.customer.full_name)}{" "}
								has been sent to serving from next inline list.
								This action can&apos;t be undone after 10
								seconds.
							</CardContent>
						</div>
						<CardFooter className="p-4 pt-0">
							<Button
								variant="ghost"
								type="submit"
								className="px-0 text-[#005893] hover:!bg-transparent hover:!text-[#005893]"
							>
								Undo Action
							</Button>
						</CardFooter>
					</Card>
				</form>
			</Overlay> */}
		</>
	);
};

export default PatientSentToSentToDoneModal;
