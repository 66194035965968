import SettingsSaveModal from "@/src/components/common/SettingsSaveModal";
import { QueueSettingsSlice } from "@/src/store/slices/locations/locationSlice";
import useAppointmentStore from "@/src/store/useAppointmentStore";
import { queueSettingsProps, queueSettingsSchema } from "@/src/types/Location";
import { zodResolver } from "@hookform/resolvers/zod";
import AddLocationTimer from "@src/components/Onboarding/AddLocationTimer";
import { Button } from "@src/components/ui/button";
import { ScrollArea } from "@src/components/ui/scroll-area";
import { OperatingHour } from "@src/types/DaySlots";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { isEqual } from "lodash";
import { useQueryClient } from "react-query";

const OperationalHours = () => {
	const queryClient = useQueryClient();
	const [showApplyModal, setShowApplyModal] = useState<boolean>(false);
	const {
		appointmentSettings,
		setShowAppointmentSettingsModal,
		appointmentSettingsSelectedStation,
	} = useAppointmentStore();
	const [slots, setSlots] = React.useState<OperatingHour[]>(
		appointmentSettings.schedule_working_hours
	);

	const methods = useForm<queueSettingsProps>({
		resolver: zodResolver(queueSettingsSchema),
		defaultValues: {
			working_hours: appointmentSettings?.schedule_working_hours ?? [],
			apply_to_option: {
				apply_to_all: 1,
				locations: [],
			},
		},
	});
	const { mutate: updateQueueSettings, isLoading } = QueueSettingsSlice(
		() => {
			setShowApplyModal(false);
			queryClient.invalidateQueries(["get-queue-settingss"]);
		}
	);

	const { handleSubmit, reset, watch } = methods;

	const watchedFields = watch();
	const initialValues = {
		working_hours: appointmentSettings?.schedule_working_hours ?? [],
		apply_to_option: {
			apply_to_all: 1,
			locations: [],
		},
	};

	// Determine if the form has changed
	const isFormChanged = !isEqual(
		{ ...watchedFields, working_hours: slots },
		initialValues
	);

	const onSubmit = (data: queueSettingsProps) => {
		const updatedData = {
			...data,
			working_hours: slots,
			apply_to_option: data.apply_to_option,
		};
		updateQueueSettings({
			data: updatedData,
			queryParams: { station_id: appointmentSettingsSelectedStation },
		});
	};

	useEffect(() => {
		reset({
			working_hours: appointmentSettings?.schedule_working_hours ?? [],
			apply_to_option: {
				apply_to_all: 1,
				locations: [],
			},
		});
		setSlots(appointmentSettings?.schedule_working_hours ?? []);
	}, [reset, appointmentSettings]);
	return (
		<FormProvider {...methods}>
			<form
				className="flex h-full w-full flex-col gap-y-8"
				onSubmit={handleSubmit(onSubmit)}
			>
				<div className="flex items-center justify-between ">
					<h2 className="text-[22px] font-semibold leading-[30px] tracking-[-1%] text-[#323539]">
						Add Operating Hours
					</h2>
				</div>
				<ScrollArea className="mt-2 max-h-[42vh] flex-1 border-b border-[#1018280A] px-4">
					<div className="flex flex-col space-y-4  border-t border-[#979AA0] pt-5">
						{slots.map((slot, i) => (
							<AddLocationTimer
								{...slot}
								key={i}
								index={i}
								slots={slots}
								shouldShowDay
								shouldShowPlus
								setSlots={setSlots as any}
							/>
						))}
					</div>
				</ScrollArea>
				<div className="flex w-full justify-end  gap-2 py-4">
					<Button
						variant="ghost"
						type="reset"
						onClick={() => setShowAppointmentSettingsModal(false)}
					>
						Cancel
					</Button>
					<Button
						type="button"
						className="px-9 text-white"
						onClick={() => setShowApplyModal(true)}
						disabled={!isFormChanged}
					>
						Update
					</Button>
				</div>
				<SettingsSaveModal
					showApplyModal={showApplyModal}
					setShowApplyModal={setShowApplyModal}
					onSubmit={methods.handleSubmit(onSubmit)}
					isLoading={isLoading}
				/>
			</form>
		</FormProvider>
	);
};

export default OperationalHours;
