import { cn } from "@src/utils/functions";
import React from "react";
import HistorySection from "./ServingTabs/HistorySection";
import ServingSection from "./ServingTabs/ServingSection/ServingSection";
import {
	GetServingSlice,
	GetWeeklyHistorySlice,
} from "@/src/store/slices/serving/getServingSlice";

const ServingWrapper: React.FC = () => {
	const [currentTab, setCurrentTab] = React.useState<
		"today-activity" | "history"
	>("history");
	const getServingQuery = GetServingSlice();
	const getWeeklyHistoryQuery = GetWeeklyHistorySlice();

	return (
		<section className="flex flex-1 flex-col items-stretch">
			<div className="flex justify-center">
				<button
					className="flex flex-1 border-b-2"
					type="button"
					onClick={() => setCurrentTab("today-activity")}
				>
					<div
						className={cn(
							"-mb-0.5 flex w-full items-center justify-center space-x-2 border-b-2 py-1.5 transition-all duration-200 ease-in-out",
							{
								"border-b-primary":
									currentTab === "today-activity",
							}
						)}
					>
						<h1
							className={cn(
								"text-[15px] font-semibold leading-[22px] text-[#858C95] transition-all duration-200 ease-in-out",
								{
									"text-primary":
										currentTab === "today-activity",
								}
							)}
						>
							Today&apos;s Activity
						</h1>
						<p
							className={cn(
								"grid size-[30px] place-content-center rounded-full bg-[#E5E5E7] text-[10px] font-semibold leading-[22px] text-[#858C95] transition-all duration-200 ease-in-out",
								{
									"bg-primary/10 text-primary":
										currentTab === "today-activity",
								}
							)}
						>
							{getServingQuery.data &&
								getServingQuery.data?.data.length}
						</p>
					</div>
				</button>
				<button
					className="flex flex-1 border-b-2"
					type="button"
					onClick={() => setCurrentTab("history")}
				>
					<div
						className={cn(
							"-mb-0.5 flex w-full items-center justify-center space-x-2 border-b-2 py-1.5 transition-all duration-200 ease-in-out",
							{ "border-b-primary": currentTab === "history" }
						)}
					>
						<h1
							className={cn(
								"text-[15px] font-semibold leading-[22px] text-[#858C95] transition-all duration-200 ease-in-out",
								{ "text-primary": currentTab === "history" }
							)}
						>
							History
						</h1>
						<p
							className={cn(
								"grid size-[30px] place-content-center rounded-full bg-[#E5E5E7] text-[10px] font-semibold leading-[22px] text-[#858C95]",
								{
									"bg-primary/10 text-primary":
										currentTab === "history",
								}
							)}
						>
							{getWeeklyHistoryQuery.data &&
								getWeeklyHistoryQuery.data?.data.length}
						</p>
					</div>
				</button>
			</div>
			<div className="flex flex-1 flex-col items-stretch overflow-hidden">
				{currentTab === "today-activity" && <ServingSection />}
				{currentTab === "history" && <HistorySection />}
			</div>
		</section>
	);
};

export default ServingWrapper;
