import React from "react";
import useLocationsStore from "@src/store/useLocationsStore";

const DisplayLocationAndStationCount: React.FC = () => {
	const locationsData = useLocationsStore((s) => s.locations);

	const totalStations = locationsData.reduce((count, location) => {
		return count + location.stations.length;
	}, 0);

	return (
		<div className="flex items-center space-x-6">
			<DisplayLocationAndStationCountItem
				icon={"mgc_building_1_line"}
				title={locationsData.length}
				desc={"Locations"}
			/>
			<DisplayLocationAndStationCountItem
				icon={"mgc_box_line"}
				title={totalStations}
				desc={"Stations"}
			/>
		</div>
	);
};

export default DisplayLocationAndStationCount;

const DisplayLocationAndStationCountItem: React.FC<{
	icon: string;
	title: string | number;
	desc: string;
}> = ({ icon, title, desc }) => {
	return (
		<div className="flex items-center space-x-[5px] rounded-full bg-[#FFFFFF]  py-1 pl-1 pr-6 shadow-custom-medium-200">
			<span className="grid size-12 place-content-center rounded-full bg-[#0058931A]">
				<i
					className={`${icon} before-text-dark-blue-2 size-6 text-[24px]`}
				/>
			</span>

			<div className="flex flex-col py-[3.5px]">
				<p className="traking-[-1%] text-[18px] font-semibold leading-[28px] text-[#005893]">
					{title}
				</p>
				<small className="text-[13px] leading-[100%] tracking-[-0.1px] text-[#005893]">
					{desc}
				</small>
			</div>
		</div>
	);
};
