import { cn } from "../../../utils/functions";
import AppointmentCard from "./AppointmentCard";

interface AppointmentColumnProps {
	status: "pending" | "confirmed" | "followup";
	data?: [];
	// viewType: "Today" | "Day" | "Week" | "Month";
	patientData?: Array<Record<string, object>>;
}

const AppointmentColumn = ({ status, data }: AppointmentColumnProps) => {
	return (
		<div className="flex h-[80vh] w-[318px] max-w-full flex-col rounded-md border-[0.5px] border-[#D9D9D9]  bg-[#F7F7F8]">
			<div
				className={cn(" rounded-tl-md rounded-tr-md bg-[#138576]", {
					"bg-[#C8322B]": status == "pending",
					"bg-[#138576]": status == "confirmed",
					"bg-[#E4AC29]": status == "followup",
				})}
			>
				<p className="p-3 text-[20px] font-[600] text-white">
					{status === "pending" && "Pending Appointments"}
					{status === "confirmed" && "Scheduled Appointments"}
					{status === "followup" && "Book for Follow Up"}
				</p>
			</div>
			<div className="h-full  overflow-y-scroll">
				{data?.map((data, index) => (
					<AppointmentCard key={index} status={status} data={data} />
				))}
			</div>
		</div>
	);
};

export default AppointmentColumn;
