import React, { useEffect, useState } from "react";
import {
	Controller,
	FormProvider,
	SubmitHandler,
	useForm,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { MdOutlineDragIndicator } from "react-icons/md";
import { Input } from "@src/components/ui/input";
import { Slider } from "@src/components/ui/slider";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@src/components/ui/select";
import { QueueSettingsSlice } from "@src/store/slices/locations/locationSlice";
import { queueSettingsProps, queueSettingsSchema } from "@src/types/Location";
import Checkbox from "@src/components/ui-extended/checkbox";
import { Button } from "@src/components/ui/button";
import { Switch } from "@src/components/ui/switch";
import _ from "lodash";
import useAppointmentStore from "@/src/store/useAppointmentStore";
import SettingsSaveModal from "@/src/components/common/SettingsSaveModal";
import { useQueryClient } from "react-query";

const ScheduleOptimizer = () => {
	const queryClient = useQueryClient();
	const [showApplyModal, setShowApplyModal] = useState<boolean>(false);
	const { appointmentSettings, appointmentSettingsSelectedStation } =
		useAppointmentStore();
	const methods = useForm<queueSettingsProps>({
		resolver: zodResolver(queueSettingsSchema),
		defaultValues: {
			schedule_optimizer: {
				is_active: 0,
				waitlist_selected: 0,
				waitlist_number_of_people: 0,
				high_priority_selected: 0,
				high_priority_number_of_people: 0,
				upcoming_appointments_selected: 0,
				upcoming_appointments_number_of_people: 0,
				upcoming_appointments_limit: 0,
				upcoming_appointments_limit_options: "days",
			},
			apply_to_option: {
				apply_to_all: 1,
				locations: [],
			},
		},
	});

	const { handleSubmit, reset, setValue, watch, control } = methods;

	const defaultData = {
		is_active: appointmentSettings.schedule_optimizer?.is_active,
		waitlist_selected:
			appointmentSettings.schedule_optimizer?.waitlist_selected,
		waitlist_number_of_people:
			appointmentSettings.schedule_optimizer?.waitlist_number_of_people,
		high_priority_selected:
			appointmentSettings.schedule_optimizer?.high_priority_selected,
		high_priority_number_of_people:
			appointmentSettings.schedule_optimizer
				?.high_priority_number_of_people,
		upcoming_appointments_selected:
			appointmentSettings.schedule_optimizer
				?.upcoming_appointments_selected,
		upcoming_appointments_number_of_people:
			appointmentSettings.schedule_optimizer
				?.upcoming_appointments_number_of_people,
		upcoming_appointments_limit:
			appointmentSettings.schedule_optimizer?.upcoming_appointments_limit,
		upcoming_appointments_limit_options:
			appointmentSettings.schedule_optimizer
				?.upcoming_appointments_limit_options,
	};

	const checkUpdatePayloadButton = {
		is_active: watch("schedule_optimizer")?.is_active,
		waitlist_selected: watch("schedule_optimizer")?.waitlist_selected,
		waitlist_number_of_people:
			watch("schedule_optimizer")?.waitlist_number_of_people,
		high_priority_selected:
			watch("schedule_optimizer")?.high_priority_selected,
		high_priority_number_of_people:
			watch("schedule_optimizer")?.high_priority_number_of_people,
		upcoming_appointments_selected:
			watch("schedule_optimizer")?.upcoming_appointments_selected,
		upcoming_appointments_number_of_people:
			watch("schedule_optimizer")?.upcoming_appointments_number_of_people,
		upcoming_appointments_limit:
			watch("schedule_optimizer")?.upcoming_appointments_limit,
		upcoming_appointments_limit_options:
			watch("schedule_optimizer")?.upcoming_appointments_limit_options,
	};

	const handleSwitchChange = (field: any) => (value: boolean) => {
		setValue(field, value ? 1 : 0);
	};

	const { mutate: updateQueueSettings, isLoading } = QueueSettingsSlice(
		() => {
			setShowApplyModal(false);
			queryClient.invalidateQueries(["get-queue-settingss"]);
		}
	);

	const handleCancel = () => {
		reset({
			schedule_optimizer: {
				is_active:
					appointmentSettings.schedule_optimizer?.is_active || 0,
				waitlist_selected:
					appointmentSettings.schedule_optimizer?.waitlist_selected ||
					0,
				waitlist_number_of_people:
					appointmentSettings.schedule_optimizer
						?.waitlist_number_of_people || 0,
				high_priority_selected:
					appointmentSettings.schedule_optimizer
						?.high_priority_selected || 0,
				high_priority_number_of_people:
					appointmentSettings.schedule_optimizer
						?.high_priority_number_of_people || 0,
				upcoming_appointments_selected:
					appointmentSettings.schedule_optimizer
						?.upcoming_appointments_selected || 0,
				upcoming_appointments_number_of_people:
					appointmentSettings.schedule_optimizer
						?.upcoming_appointments_number_of_people || 0,
				upcoming_appointments_limit:
					appointmentSettings.schedule_optimizer
						?.upcoming_appointments_limit || 0,
				upcoming_appointments_limit_options:
					appointmentSettings.schedule_optimizer
						?.upcoming_appointments_limit_options || "days",
			},
		});
	};

	const onSubmit: SubmitHandler<queueSettingsProps> = (data) => {
		updateQueueSettings({
			data: {
				...data,
				apply_to_option: data.apply_to_option,
			},
			queryParams: { station_id: appointmentSettingsSelectedStation },
		});
	};

	useEffect(() => {
		reset({
			schedule_optimizer: {
				is_active:
					appointmentSettings.schedule_optimizer?.is_active || 0,
				waitlist_selected:
					appointmentSettings.schedule_optimizer?.waitlist_selected ||
					0,
				waitlist_number_of_people:
					appointmentSettings.schedule_optimizer
						?.waitlist_number_of_people || 0,
				high_priority_selected:
					appointmentSettings.schedule_optimizer
						?.high_priority_selected || 0,
				high_priority_number_of_people:
					appointmentSettings.schedule_optimizer
						?.high_priority_number_of_people || 0,
				upcoming_appointments_selected:
					appointmentSettings.schedule_optimizer
						?.upcoming_appointments_selected || 0,
				upcoming_appointments_number_of_people:
					appointmentSettings.schedule_optimizer
						?.upcoming_appointments_number_of_people || 0,
				upcoming_appointments_limit:
					appointmentSettings.schedule_optimizer
						?.upcoming_appointments_limit || 0,
				upcoming_appointments_limit_options:
					appointmentSettings.schedule_optimizer
						?.upcoming_appointments_limit_options || "days",
			},
			apply_to_option: {
				apply_to_all: 1,
				locations: [],
			},
		});
	}, [reset, appointmentSettings]);

	const options = [
		{ value: "days", label: "Days" },
		{ value: "weeks", label: "Weeks" },
		{ value: "months", label: "Months" },
	];

	const handleCheckboxSelection2 = (type: any, isChecked: boolean) => {
		setValue(type, isChecked ? 1 : 0);
	};

	return (
		<FormProvider {...methods}>
			<form
				className="flex flex-col gap-y-4"
				onSubmit={handleSubmit(onSubmit)}
			>
				<div className="flex justify-between">
					<div className="">
						<h3 className="text-xl font-semibold">
							Schedule Optimizer
						</h3>
						<p className="text-sm text-gray-400">
							We email/sms your patients who are in line in the
							following categories.
						</p>
					</div>
					{/* <CustomSwitchAlt /> */}
					<div className="flex items-center">
						<Controller
							name="schedule_optimizer.is_active"
							control={control}
							render={({ field }) => (
								<Switch
									className="scale-[0.70] data-[state=checked]:bg-[#2EBF43]"
									checked={Boolean(field.value)}
									onCheckedChange={handleSwitchChange(
										"schedule_optimizer.is_active"
									)}
								/>
							)}
						/>
						<span className="">
							{watch("schedule_optimizer.is_active")
								? "Yes"
								: "No"}
						</span>
					</div>
				</div>

				<div className="border-b ">
					<p className="pb-2">
						Select categories you want notified in order.
						<br />
						You may also select the quantity of people who will be
						notified.
					</p>

					<div
						className={`mb-2 flex overflow-hidden rounded-lg bg-[#F8F8F8] ${
							watch("schedule_optimizer.waitlist_selected")
								? "border border-primary shadow-custom-primary"
								: ""
						}`}
					>
						<div className="flex h-auto cursor-grab items-center px-3 hover:bg-[#000]/5">
							<MdOutlineDragIndicator
								className="text-gray-400"
								size={"24"}
							/>
						</div>
						<div className="flex flex-1 items-center justify-between space-x-4 p-2">
							<Controller
								name="schedule_optimizer.waitlist_selected"
								control={control}
								render={({ field }) => (
									<Checkbox
										isChecked={Boolean(field.value)}
										handleCheckboxChange={() =>
											handleCheckboxSelection2(
												"schedule_optimizer.waitlist_selected",
												!watch(
													"schedule_optimizer.waitlist_selected"
												)
											)
										}
										className="rounded-sm border-2 border-[#D1D1D1]"
										id={
											"schedule_optimizer.waitlist_selected"
										}
									/>
								)}
							/>

							<p className="w-[185px] text-sm">Waitlist</p>
							<div className="flex-1">
								<Slider
									max={100}
									step={5}
									value={[
										watch(
											"schedule_optimizer.waitlist_number_of_people"
										) || 0,
									]}
									onValueChange={(value) => {
										setValue(
											"schedule_optimizer.waitlist_number_of_people",
											value[0]
										);
									}}
									thumbClassName={`border-[0.5px] ${
										watch(
											"schedule_optimizer.waitlist_selected"
										)
											? "bg-primary border-primary"
											: "bg-[#DEDEDE] border-[#FFFFFF]"
									}`}
									beforeThumbClassName={` ${
										watch(
											"schedule_optimizer.waitlist_selected"
										)
											? "bg-primary"
											: "bg-[#DEDEDE]"
									}`}
									afterThumbClassName="bg-[#DEDEDE] shadow-custom-inset"
								/>
							</div>
							<div>
								<Controller
									name="schedule_optimizer.waitlist_number_of_people"
									control={control}
									render={({ field }) => (
										<Input
											className={`h-10 w-[80px] border-[#E5E5E7] bg-[#FFFFFF] text-center text-main-1 ${
												watch(
													"schedule_optimizer.waitlist_selected"
												)
													? "opacity-100"
													: "opacity-50"
											}`}
											value={String(field.value)}
											onChange={(e) => {
												setValue(
													"schedule_optimizer.waitlist_number_of_people",
													parseInt(e.target.value)
												);
											}}
											min={1}
											type="number"
										/>
									)}
								/>
							</div>
						</div>
					</div>
					{/* High priority Label  */}
					<div
						className={`mb-2 flex overflow-hidden rounded-lg bg-[#F8F8F8] ${
							watch("schedule_optimizer.high_priority_selected")
								? "border border-primary shadow-custom-primary"
								: ""
						}`}
					>
						<div className="flex h-auto cursor-grab items-center px-3 hover:bg-[#000]/5">
							<MdOutlineDragIndicator
								className="text-gray-400"
								size={"24"}
							/>
						</div>
						<div className="flex flex-1 items-center justify-between space-x-4 p-2">
							<Controller
								name="schedule_optimizer.high_priority_selected"
								control={control}
								render={({ field }) => (
									<Checkbox
										isChecked={Boolean(field.value)}
										handleCheckboxChange={() =>
											handleCheckboxSelection2(
												"schedule_optimizer.high_priority_selected",
												!watch(
													"schedule_optimizer.high_priority_selected"
												)
											)
										}
										className="rounded-sm border-2 border-[#D1D1D1]"
										id={
											"schedule_optimizer.high_priority_selected"
										}
									/>
								)}
							/>

							<p className="w-[185px] text-sm">High Priority</p>
							<div className="flex-1">
								<Slider
									// defaultValue={[33]}
									// max={100}
									// step={1}
									value={[
										watch(
											"schedule_optimizer.high_priority_number_of_people"
										) || 0,
									]}
									onValueChange={(value) => {
										setValue(
											"schedule_optimizer.high_priority_number_of_people",
											value[0]
										);
									}}
									max={100}
									step={5}
									thumbClassName={`border-[0.5px] ${
										watch(
											"schedule_optimizer.high_priority_selected"
										)
											? "bg-primary border-primary"
											: "bg-[#DEDEDE] border-[#FFFFFF]"
									}`}
									beforeThumbClassName={` ${
										watch(
											"schedule_optimizer.high_priority_selected"
										)
											? "bg-primary"
											: "bg-[#DEDEDE]"
									}`}
									afterThumbClassName="bg-[#DEDEDE] shadow-custom-inset"
								/>
							</div>
							<div>
								<Controller
									name="schedule_optimizer.high_priority_number_of_people"
									control={control}
									render={({ field }) => (
										<Input
											className={`h-10 w-[80px] border-[#E5E5E7] bg-[#FFFFFF] text-center text-main-1 ${
												watch(
													"schedule_optimizer.high_priority_selected"
												)
													? "opacity-100"
													: "opacity-50"
											}`}
											value={String(field.value)}
											onChange={(e) => {
												setValue(
													"schedule_optimizer.high_priority_number_of_people",
													parseInt(e.target.value)
												);
											}}
											min={1}
											type="number"
										/>
									)}
								/>
							</div>
						</div>
					</div>
					{/* // Upcoming appointment status */}
					<div
						className={`mb-2 flex overflow-hidden rounded-lg bg-[#F8F8F8] ${
							watch(
								"schedule_optimizer.upcoming_appointments_selected"
							)
								? "border border-primary shadow-custom-primary"
								: ""
						}`}
					>
						<div className="flex h-auto cursor-grab items-center px-3 hover:bg-[#000]/5">
							<MdOutlineDragIndicator
								className="text-gray-400"
								size={"24"}
							/>
						</div>
						<div className="flex flex-1 items-center justify-between space-x-2 p-2">
							<Controller
								name="schedule_optimizer.upcoming_appointments_selected"
								control={control}
								render={({ field }) => (
									<Checkbox
										isChecked={Boolean(field.value)}
										handleCheckboxChange={() =>
											handleCheckboxSelection2(
												"schedule_optimizer.upcoming_appointments_selected",
												!watch(
													"schedule_optimizer.upcoming_appointments_selected"
												)
											)
										}
										className="rounded-sm border-2 border-[#D1D1D1]"
										id={
											"schedule_optimizer.upcoming_appointments_selected"
										}
									/>
								)}
							/>

							<p className="w-[185px] text-sm">
								Upcoming Appointments
							</p>
							<Controller
								name="schedule_optimizer.upcoming_appointments_number_of_people"
								control={control}
								render={({ field }) => (
									<Input
										className={`h-10 w-[40px] border-[#E5E5E7] bg-[#FFFFFF] px-1 text-center text-main-1 ${
											watch(
												"schedule_optimizer.upcoming_appointments_selected"
											)
												? "opacity-100"
												: "opacity-50"
										}`}
										value={String(field.value)}
										onChange={(e) => {
											setValue(
												"schedule_optimizer.upcoming_appointments_number_of_people",
												parseInt(e.target.value)
											);
										}}
										min={1}
										type="number"
									/>
								)}
							/>

							<div className="flex-1">
								<Slider
									value={[
										watch(
											"schedule_optimizer.upcoming_appointments_number_of_people"
										) || 0,
									]}
									onValueChange={(value) => {
										setValue(
											"schedule_optimizer.upcoming_appointments_number_of_people",
											value[0]
										);
									}}
									max={
										watch(
											"schedule_optimizer.upcoming_appointments_limit_options"
										) === "days"
											? 30
											: watch(
														"schedule_optimizer.upcoming_appointments_limit_options"
												  ) === "weeks"
												? 56
												: 12
									}
									step={
										watch(
											"schedule_optimizer.upcoming_appointments_limit_options"
										) === "days"
											? 5
											: 1
									}
									thumbClassName={`border-[0.5px] ${
										watch(
											"schedule_optimizer.upcoming_appointments_selected"
										)
											? "bg-primary border-primary"
											: "bg-[#DEDEDE] border-[#FFFFFF]"
									}`}
									beforeThumbClassName={` ${
										watch(
											"schedule_optimizer.upcoming_appointments_selected"
										)
											? "bg-primary"
											: "bg-[#DEDEDE]"
									}`}
									afterThumbClassName="bg-[#DEDEDE] shadow-custom-inset"
								/>
							</div>
							<div>
								<Controller
									name="schedule_optimizer.upcoming_appointments_limit"
									control={control}
									render={({ field }) => (
										<Input
											className={`h-10 w-[40px] border-[#E5E5E7] bg-[#FFFFFF] px-1 text-center text-main-1 ${
												watch(
													"schedule_optimizer.upcoming_appointments_selected"
												)
													? "opacity-100"
													: "opacity-50"
											}`}
											value={String(field.value)}
											onChange={(e) => {
												setValue(
													"schedule_optimizer.upcoming_appointments_limit",
													parseInt(e.target.value)
												);
											}}
											min={1}
											type="number"
										/>
									)}
								/>
							</div>
							<Select
								value={watch(
									"schedule_optimizer.upcoming_appointments_limit_options"
								)}
								onValueChange={(
									value: "days" | "weeks" | "months"
								) => {
									setValue(
										"schedule_optimizer.upcoming_appointments_limit_options",
										value
									);
								}}
							>
								<SelectTrigger
									className={`focus-within::border-transparent flex w-max items-center space-x-2.5 rounded-md border-[#E5E5E7] bg-[#FFFFFF] px-4 py-0 ${
										watch(
											"schedule_optimizer.upcoming_appointments_selected"
										)
											? "opacity-100"
											: "opacity-50"
									}`}
								>
									<SelectValue
										placeholder={"Select duration"}
										className="focus-within::border-transparent text-[#323539]"
									/>
								</SelectTrigger>
								<SelectContent className="focus-within::border-transparent !z-[9999]">
									<SelectGroup>
										{options.map(
											(option: {
												label: string;
												value: string;
											}) => {
												return (
													<SelectItem
														key={option.value}
														value={option.value}
														className="px-8"
													>
														{option.label +
															" " +
															"away"}
													</SelectItem>
												);
											}
										)}
									</SelectGroup>
								</SelectContent>
							</Select>
						</div>
					</div>
				</div>

				<div className="mx-auto flex w-full max-w-[1000px] justify-end gap-2 py-4">
					<Button
						type="reset"
						onClick={handleCancel}
						variant={"ghost"}
					>
						Cancel
					</Button>
					<Button
						className="px-9"
						type="button"
						disabled={_.isEqual(
							defaultData,
							checkUpdatePayloadButton
						)}
						onClick={() => setShowApplyModal(true)}
					>
						Update
					</Button>
				</div>
				<SettingsSaveModal
					showApplyModal={showApplyModal}
					setShowApplyModal={setShowApplyModal}
					onSubmit={methods.handleSubmit(onSubmit)}
					isLoading={isLoading}
				/>
			</form>
		</FormProvider>
	);
};

export default ScheduleOptimizer;
