import React, { useState } from "react";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogDescription,
	DialogTitle,
	DialogTrigger,
} from "@src/components/ui/dialog";
import Checkbox from "@src/components/ui-extended/checkbox";
import { Button } from "@src/components/ui/button";
import { RadioGroup, RadioGroupItem } from "@src/components/ui/radio-group";
import { Label } from "@src/components/ui/label";
import { useFormContext, Controller } from "react-hook-form";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@src/components/ui/accordion";

import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "@components/ui/command";
import useLocationsStore from "@src/store/useLocationsStore";
import { StationsProps } from "@src/types/Location";
import Loader from "@src/components/Loader/Loader";

interface AddFieldsProps {
	modalMode: string | undefined;
	setModalMode: React.Dispatch<React.SetStateAction<string | undefined>>;
	onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
	isLoading: boolean;
}

const TagFieldModal: React.FC<AddFieldsProps> = ({
	setModalMode,
	modalMode,
	onSubmit,
	isLoading,
}) => {
	const locationsData = useLocationsStore((s) => s.locations);

	const {
		control,
		register,
		watch,
		setValue,
		getValues,
		trigger,
		formState: { errors, isValid },
	} = useFormContext();
	const toggleModal = (status: boolean, isEditMode: boolean = false) => {
		setModalMode(status ? (isEditMode ? "edit-add" : "add") : "");
	};

	// const inputType = getValues("type");
	const handleDialogSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		onSubmit();
	};

	// console.log("🚀 ~ errors:", errors, isValid);

	const handleApplyToStationLocationCheckboxChange = (
		field: any,
		status: any,
		location: any
	) => {
		let updatedLocations = [...field.value];
		if (!status) {
			updatedLocations.push({
				id: location.id,
				name: location.name,
				update_location: 1,
				apply_to_all_stations: 0,
				selected_stations: [],
				stations: location.stations,
			});
		} else {
			updatedLocations = updatedLocations.filter(
				(l) => l.id !== location.id
			);
		}
		field.onChange(updatedLocations);
	};

	return (
		<Dialog
			open={modalMode === "tag" || modalMode === "edit-tag"}
			onOpenChange={(e: boolean) => toggleModal(e)}
		>
			<DialogTrigger asChild></DialogTrigger>
			<DialogContent
				aria-describedby="dialog-description"
				className="overflow-y-auto"
			>
				<DialogHeader>
					<DialogTitle className="text-left text-[22px] font-semibold">
						Apply Field to the following
					</DialogTitle>
					<DialogDescription></DialogDescription>
				</DialogHeader>
				<form className="grid gap-4">
					<div className="flex items-start gap-x-2">
						<Controller
							name={`apply_to_option.apply_to_all`}
							control={control}
							render={() => (
								<Checkbox
									className="rounded-sm border-2 border-[#D1D1D1]"
									isChecked={getValues(
										"apply_to_option.apply_to_all"
									)}
									handleCheckboxChange={() =>
										setValue(
											"apply_to_option.apply_to_all",
											!getValues(
												"apply_to_option.apply_to_all"
											)
												? 1
												: 0
										)
									}
									id={"apply_to_option"}
								/>
							)}
						/>
						<div className="space-y-2.5">
							<h3 className="m-0 text-lg font-semibold leading-none">
								Apply to all locations and stations
							</h3>
							<p className="text-sm font-normal text-muted-foreground">
								Selecting this will apply this field to all
								stations and all locations.
							</p>
						</div>
					</div>
					<div className="flex items-start gap-x-2">
						<Controller
							name={`apply_to_option.apply_to_all`}
							control={control}
							render={() => (
								<Checkbox
									className="rounded-sm border-2 border-[#D1D1D1]"
									isChecked={
										!getValues(
											"apply_to_option.apply_to_all"
										)
									}
									handleCheckboxChange={() =>
										setValue(
											"apply_to_option.apply_to_all",
											!getValues(
												"apply_to_option.apply_to_all"
											)
												? 1
												: 0
										)
									}
									id={"not-apply_to_option"}
								/>
							)}
						/>

						<div className="space-y-2.5">
							<h3 className="m-0 text-lg font-semibold leading-none">
								Apply to select locations and stations only
							</h3>
							<p className="text-sm font-normal text-muted-foreground">
								This will add this intake field to{" "}
								<span className="font-semibold">
									all selected stations.
								</span>
							</p>
						</div>
					</div>
					{Boolean(!watch("apply_to_option.apply_to_all")) && (
						<Command className="rounded-lg border shadow-md md:min-w-[450px]">
							<CommandInput placeholder="Search by name" />
							<CommandList>
								<CommandEmpty>No results found.</CommandEmpty>
								<CommandGroup>
									<Controller
										name="apply_to_option.locations"
										control={control}
										render={({ field }) => (
											<>
												{locationsData?.map(
													(location) => {
														const isLocationSelected =
															field?.value?.some(
																(l) =>
																	l.id ===
																	location.id
															);
														return (
															<CommandItem
																key={
																	location.id
																}
															>
																<Accordion
																	type="single"
																	collapsible
																	className="w-full"
																	value={
																		isLocationSelected
																			? "item-2"
																			: ""
																	}
																>
																	<AccordionItem
																		value="item-2"
																		className="space-y-2.5 border-0"
																	>
																		<AccordionTrigger className="flex items-start justify-start gap-x-2 py-1 text-main-1 hover:no-underline">
																			<Checkbox
																				isChecked={
																					isLocationSelected
																				}
																				handleCheckboxChange={() =>
																					handleApplyToStationLocationCheckboxChange(
																						field,
																						isLocationSelected,
																						location
																					)
																				}
																				className="rounded-sm border-2 border-[#D1D1D1]"
																				id={
																					"location-" +
																					location.id
																				}
																			/>
																			<div className="flex flex-1 flex-col items-start space-y-2.5">
																				<h3 className="m-0 text-sm font-medium leading-none">
																					{
																						location.name
																					}
																				</h3>
																			</div>
																		</AccordionTrigger>
																		<AccordionContent className="flex flex-col pb-0">
																			<div className="ml-6 space-y-2.5">
																				<>
																					<div className="flex items-center gap-x-2">
																						<Checkbox
																							isChecked={
																								field?.value?.find(
																									(
																										loc
																									) =>
																										loc.id ===
																										location.id
																								)
																									?.apply_to_all_stations ||
																								false
																							}
																							handleCheckboxChange={() => {
																								const updatedLocations =
																									[
																										...field.value,
																									];
																								const selectedLocation =
																									updatedLocations?.find(
																										(
																											l
																										) =>
																											l.id ===
																											location.id
																									);

																								if (
																									selectedLocation
																								) {
																									selectedLocation.apply_to_all_stations =
																										selectedLocation.apply_to_all_stations
																											? 0
																											: 1;
																									if (
																										selectedLocation.apply_to_all_stations
																									) {
																										selectedLocation.selected_stations =
																											location.stations?.map(
																												(
																													station
																												) =>
																													station.id
																											);
																									} else {
																										selectedLocation.selected_stations =
																											[];
																									}
																									field.onChange(
																										updatedLocations
																									);
																								}
																							}}
																							className="rounded-sm border-2 border-[#D1D1D1]"
																							id={`apply-to-all-${location.id}`}
																						/>
																						<div className="space-y-2.5">
																							<h3 className="m-0 text-sm font-medium leading-none">
																								All
																								Stations
																								in
																								this
																								Location
																							</h3>
																						</div>
																					</div>

																					{location.stations?.map(
																						(
																							station: StationsProps
																						) => (
																							<div
																								key={
																									station.id
																								}
																								className="flex items-center gap-x-2"
																							>
																								<Checkbox
																									isChecked={
																										field.value
																											?.find(
																												(
																													loc: any
																												) =>
																													loc.id ===
																													location.id
																											)
																											?.selected_stations?.includes(
																												station.id
																											) ||
																										false
																									}
																									handleCheckboxChange={() => {
																										const updatedLocations =
																											[
																												...field.value,
																											];
																										const selectedLocation =
																											updatedLocations?.find(
																												(
																													l
																												) =>
																													l.id ===
																													location.id
																											);

																										if (
																											selectedLocation
																										) {
																											const selectedStations =
																												selectedLocation.selected_stations ||
																												[];
																											const stationIndex =
																												selectedStations?.indexOf(
																													station.id
																												);

																											if (
																												stationIndex ===
																												-1
																											) {
																												selectedStations?.push(
																													station.id
																												);
																											} else {
																												selectedStations?.splice(
																													stationIndex,
																													1
																												);
																											}
																											selectedLocation.selected_stations =
																												selectedStations;
																											field.onChange(
																												updatedLocations
																											);
																										}
																									}}
																									className="rounded-sm border-2 border-[#D1D1D1]"
																									id={
																										"station-" +
																										station.id
																									}
																								/>

																								<div className="space-y-2.5">
																									<h3 className="m-0 text-sm font-medium leading-none">
																										{
																											station.name
																										}
																									</h3>
																								</div>
																							</div>
																						)
																					)}
																				</>
																			</div>
																		</AccordionContent>
																	</AccordionItem>
																</Accordion>
															</CommandItem>
														);
													}
												)}
											</>
										)}
									/>
								</CommandGroup>
							</CommandList>
						</Command>
					)}

					<div className="border-b border-t py-4">
						<p className="mb-2 text-lg font-semibold">
							Field Requirement
						</p>
						<Controller
							name={"field_requirement"}
							control={control}
							render={({ field }) => (
								<RadioGroup
									className="flex space-x-4"
									onValueChange={field.onChange}
									defaultValue={field.value}
								>
									<div className="flex items-center space-x-2">
										<RadioGroupItem value="yes" id="yes" />
										<Label htmlFor="yes">Yes</Label>
									</div>
									<div className="flex items-center space-x-2">
										<RadioGroupItem value="no" id="no" />
										<Label htmlFor="no">No</Label>
									</div>
									<div className="flex items-center space-x-2">
										<RadioGroupItem
											value="new_customers_only"
											id="new_customers_only"
										/>
										<Label htmlFor="new_customers_only">
											Only for first time visitors
										</Label>
									</div>
								</RadioGroup>
							)}
						/>
					</div>
					<div>
						<p className="mb-2 text-lg font-semibold">Apply To</p>
						<Controller
							name={"apply_to"}
							control={control}
							render={({ field }) => (
								<RadioGroup
									className="flex space-x-4"
									onValueChange={field.onChange}
									defaultValue={field.value}
								>
									<div className="flex items-center space-x-2">
										<RadioGroupItem
											value="waitlist_only"
											id="waitlist_only"
										/>
										<Label htmlFor="waitlist_only">
											Waitlist
										</Label>
									</div>
									<div className="flex items-center space-x-2">
										<RadioGroupItem
											value="schedule_only"
											id="schedule_only"
										/>
										<Label htmlFor="schedule_only">
											Schedule
										</Label>
									</div>
									<div className="flex items-center space-x-2">
										<RadioGroupItem value="all" id="all" />
										<Label htmlFor="all">Both</Label>
									</div>
								</RadioGroup>
							)}
						/>
					</div>
					<div className="flex justify-between pt-3">
						<Button
							variant={"outline"}
							className=" self-center rounded-md"
							onClick={() => setModalMode(undefined)}
						>
							Cancel
						</Button>

						<div className=" flex justify-end space-x-3">
							<Button
								variant={"outline"}
								className=" self-center rounded-md"
								onClick={() =>
									setModalMode(
										modalMode === "edit-tag"
											? "edit-add"
											: "add"
									)
								}
							>
								Back
							</Button>
							<Button type="submit" className="rounded-md">
								{isLoading ? (
									<Loader size={20} />
								) : modalMode === "edit-tag" ? (
									"Update Field"
								) : (
									"Add Field"
								)}
							</Button>
						</div>
					</div>
				</form>
			</DialogContent>
		</Dialog>
	);
};

export default TagFieldModal;
