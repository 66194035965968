import React, {
	useState,
	useEffect,
	useMemo,
	useCallback,
	useRef,
} from "react";
import {
	DragDropContext,
	Droppable,
	Draggable,
	DropResult,
} from "react-beautiful-dnd";
import {
	BACKEND_URL,
	IIdNameSchema,
	IWaitlist,
	token,
} from "./QueueManagement";
import {
	format,
	parse,
	addSeconds,
	addMinutes,
	differenceInSeconds,
} from "date-fns";
import axios from "axios";

interface IWaitlistGroup {
	label: string;
	key: string;
	waitLists: IWaitlist[];
}

const QueueContainer = ({
	queueData,
	onQueueStatusChange,
	sessionId,
	// stations,
}: {
	queueData: IWaitlist[];
	onQueueStatusChange: any;
	sessionId: any;
	stations: IIdNameSchema[];
}) => {
	// Local state to manage the queue data
	const [localQueueData, setLocalQueueData] =
		useState<IWaitlist[]>(queueData);

	// State to manage the auto-done feature
	const [autoDone, setAutoDone] = useState<boolean>(
		JSON.parse(localStorage.getItem("auto_done") || "false")
	);

	// State to manage timers for each waitlist item
	const [timers, setTimers] = useState<{ [key: number]: number }>({});

	// State to manage paused timers for each waitlist item
	const [pausedTimers, setPausedTimers] = useState<{
		[key: number]: boolean;
	}>({});

	// Update localQueueData whenever queueData prop changes
	useEffect(() => {
		setLocalQueueData(queueData);
	}, [queueData]);

	// Persist autoDone state in localStorage
	useEffect(() => {
		localStorage.setItem("auto_done", JSON.stringify(autoDone));
	}, [autoDone]);

	// Memoized state mappings to categorize waitlist items by status
	const stateMappings = useMemo(
		() => [
			{
				label: "Pending",
				key: "waiting_to_be_approved",
				waitLists: localQueueData.filter(
					(qd) => qd.status === "waiting_to_be_approved"
				),
			},
			{
				label: "Approved",
				key: "accepted",
				waitLists: localQueueData.filter(
					(qd) => qd.status === "accepted"
				),
			},
			{
				label: "Next",
				key: "next_to_serve",
				waitLists: localQueueData.filter(
					(qd) => qd.status === "next_to_serve"
				),
			},
			{
				label: "Getting service",
				key: "getting_service",
				waitLists: localQueueData.filter(
					(qd) => qd.status === "getting_service"
				),
			},
		],
		[localQueueData]
	);

	// Get the list of customers currently being served
	const onService = stateMappings.find(
		(stateM) => stateM.key === "getting_service"
	);

	/**
	 * Handles the auto-done logic.
	 * Marks customers as done if their service time exceeds the average service time.
	 */
	useEffect(() => {
		if (autoDone) {
			const interval = setInterval(() => {
				onService?.waitLists.forEach((queueItem) => {
					if (queueItem.status === "getting_service") {
						const orderAtDate = parse(
							queueItem.order_at,
							"yyyy-MM-dd HH:mm:ss",
							new Date()
						);
						const serviceEndTime = addMinutes(
							orderAtDate,
							parseInt(
								queueItem.station_average_service_time_in_minute.toString()
							)
						);
						if (new Date() > serviceEndTime) {
							makeCustomerDone(queueItem);
							alert(
								"This customer has been marked done " +
									queueItem.waitlist_id
							);
						}
					}
				});
			}, 60000); // Check every minute

			return () => clearInterval(interval);
		}
	}, [autoDone, onService?.waitLists]);

	/**
	 * Handles the countdown timers for each waitlist item.
	 * Updates every second.
	 */
	useEffect(() => {
		const interval = setInterval(() => {
			setTimers((prevTimers) => {
				const newTimers = { ...prevTimers };
				onService?.waitLists.forEach((queueItem) => {
					if (
						queueItem.status === "getting_service" &&
						!pausedTimers[queueItem.waitlist_id]
					) {
						const orderAtDate = parse(
							queueItem.order_at,
							"yyyy-MM-dd HH:mm:ss",
							new Date()
						);
						const serviceEndTime = addMinutes(
							orderAtDate,
							parseInt(
								queueItem.station_average_service_time_in_minute.toString()
							)
						);
						const remainingTime = differenceInSeconds(
							serviceEndTime,
							new Date()
						);
						newTimers[queueItem.waitlist_id] =
							remainingTime > 0 ? remainingTime : 0;
					}
				});
				return newTimers;
			});
		}, 1000); // Update every second

		return () => clearInterval(interval);
	}, [onService?.waitLists, pausedTimers]);

	// Toggles the pause state of a specific timer
	const togglePauseTimer = (waitlistId: number) => {
		setPausedTimers((prevPausedTimers) => ({
			...prevPausedTimers,
			[waitlistId]: !prevPausedTimers[waitlistId],
		}));
	};

	// Converts remaining seconds to a formatted timer string
	const formatTime = (seconds: number) => {
		const minutes = Math.floor(seconds / 60);
		const hours = Math.floor(seconds / 60 / 60);
		const remainingSeconds = seconds % 60;
		return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds.toString().padStart(2, "0")}`;
	};

	/**
	 * Handles the drag-and-drop event.
	 * Reorders the queue data based on the new position of a dragged item.
	 */
	const handleDragEnd = useCallback(
		async (result: DropResult) => {
			const { destination, source, draggableId } = result;

			if (!destination) return;

			if (
				destination.droppableId === source.droppableId &&
				destination.index === source.index
			)
				return;

			const updatedQueueData = Array.from(localQueueData);
			const reorderedItemIndex = updatedQueueData.findIndex(
				(w) => w.waitlist_id === parseInt(draggableId)
			);

			if (reorderedItemIndex === -1) return;

			const [reorderedItem] = updatedQueueData.splice(
				reorderedItemIndex,
				1
			);

			const { waitlist_id, status, order_at } = reorderedItem;

			// Get surrounding items for calculating new order_at timestamp
			const surroundingItems = getSurroundingItems(
				updatedQueueData,
				destination.index
			);
			const newOrderAt = calculateNewOrderAt(surroundingItems);

			reorderedItem.order_at = newOrderAt;
			reorderedItem.status = destination.droppableId;
			updatedQueueData.splice(destination.index, 0, reorderedItem);

			setLocalQueueData(updatedQueueData);

			try {
				// Update the server with the new order and status
				const response = await callUpdateApi(waitlist_id, {
					status: reorderedItem.status,
					order_at: reorderedItem.order_at,
				});

				if (response.status === 200) {
					onQueueStatusChange(updatedQueueData);
				} else {
					// Revert the changes if the update fails
					setLocalQueueData(
						queueData.map((qD) =>
							qD.waitlist_id === waitlist_id
								? { ...qD, status: status, order_at: order_at }
								: qD
						)
					);
				}
			} catch (error) {
				console.error("Error updating waitlist:", error);
				alert("Something went wrong please try again");
				// Revert the changes if there's an error
				setLocalQueueData(
					queueData.map((qD) =>
						qD.waitlist_id === waitlist_id
							? { ...qD, status: status, order_at: order_at }
							: qD
					)
				);
			}
		},
		[localQueueData, onQueueStatusChange]
	);

	// Memoized function to get surrounding items based on index
	const getSurroundingItems = useMemo(
		() => (queueData: any, index: any) => {
			const prevItem = queueData[index - 1];
			const nextItem = queueData[index + 1];

			return { prevItem, nextItem };
		},
		[]
	);

	// Memoized function to calculate new order_at timestamp based on surrounding items
	const calculateNewOrderAt = useMemo(
		() => (surroundingItems: any) => {
			const { prevItem, nextItem } = surroundingItems;

			if (!prevItem && !nextItem) {
				return format(new Date(), "yyyy-MM-dd HH:mm:ss");
			} else if (!prevItem) {
				return addSecondsToString(nextItem.order_at, -10);
			} else if (!nextItem) {
				return addSecondsToString(prevItem.order_at, 10);
			} else {
				const prevDate = parse(
					prevItem.order_at,
					"yyyy-MM-dd HH:mm:ss",
					new Date()
				);
				const nextDate = parse(
					nextItem.order_at,
					"yyyy-MM-dd HH:mm:ss",
					new Date()
				);
				const averageTime = new Date(
					(prevDate.getTime() + nextDate.getTime()) / 2
				);
				return format(averageTime, "yyyy-MM-dd HH:mm:ss");
			}
		},
		[]
	);

	// Memoized function to add or subtract seconds from a date string
	const addSecondsToString = useMemo(
		() => (dateTimeString: string, seconds: number) => {
			const date = parse(
				dateTimeString,
				"yyyy-MM-dd HH:mm:ss",
				new Date()
			);
			const newDate = addSeconds(date, seconds);
			return format(newDate, "yyyy-MM-dd HH:mm:ss");
		},
		[]
	);

	// Marks a customer as done and removes them from the queue
	const makeCustomerDone = async (waitlistData: IWaitlist) => {
		const { waitlist_id } = waitlistData;
		const updatedQueueData = Array.from(localQueueData).filter(
			(lQData) => lQData.waitlist_id !== waitlist_id
		);
		setLocalQueueData(updatedQueueData);

		try {
			// Update the server to mark the customer as done
			const response = await callUpdateApi(waitlist_id, {
				status: "done",
			});

			if (response.status === 200) {
				onQueueStatusChange(updatedQueueData);
			} else {
				// Revert changes if the update fails
				setLocalQueueData(queueData);
			}
		} catch (error) {
			console.error("Error updating waitlist:", error);
			alert("Something went wrong please try again");
			// Revert changes if there's an error
			setLocalQueueData(queueData);
		}
	};

	// Function to call the API to update waitlist data
	const callUpdateApi = async (waitlistId: number, data: any) => {
		const response = await axios.post(
			`${BACKEND_URL}/update`,
			{
				waitlist_id: waitlistId,
				session_id: sessionId,
				...data,
			},
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response;
	};

	return (
		<div className="container">
			<DragDropContext onDragEnd={handleDragEnd}>
				{stateMappings.map((sMap) => {
					const { key, label, waitLists } = sMap;
					return (
						<div
							className={`${key.toLowerCase()}__wrapper`}
							key={key}
						>
							<h3>{label} Waitlists</h3>
							{key === "getting_service" && (
								<div>
									<label>
										Auto Done:
										<input
											type="checkbox"
											checked={autoDone}
											onChange={() =>
												setAutoDone(!autoDone)
											}
										/>
									</label>
								</div>
							)}
							<div className={`${key.toLowerCase()}__container`}>
								<Droppable
									droppableId={key}
									isCombineEnabled={true}
								>
									{(provided) => (
										<div
											ref={provided.innerRef}
											{...provided.droppableProps}
										>
											{waitLists
												.sort(
													(a, b) =>
														new Date(
															a.order_at
														).getTime() -
														new Date(
															b.order_at
														).getTime()
												)
												.map((queueItem, index) => {
													const isOnService =
														key ===
														"getting_service";
													const timer =
														autoDone && isOnService
															? timers[
																	queueItem
																		.waitlist_id
																]
															: undefined;
													const showAutoDoneActions =
														autoDone && isOnService;
													const isPaused =
														autoDone && isOnService
															? pausedTimers[
																	queueItem
																		.waitlist_id
																]
															: undefined;
													return (
														<Draggable
															key={queueItem.waitlist_id.toString()}
															draggableId={queueItem.waitlist_id.toString()}
															index={index}
														>
															{(provided) => (
																<div
																	ref={
																		provided.innerRef
																	}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	className={`${key.toLowerCase()}__items`}
																>
																	<p>
																		Waitlist
																		Id{" "}
																		{
																			queueItem.waitlist_id
																		}{" "}
																	</p>

																	<p>
																		Customer{" "}
																		{
																			queueItem
																				.customer
																				.full_name
																		}{" "}
																	</p>
																	<p>
																		Order:{" "}
																		{index +
																			1}
																	</p>
																	<p>
																		Order
																		At:{" "}
																		{
																			queueItem.order_at
																		}
																	</p>
																	<p>
																		Station:{" "}
																		{
																			queueItem.station_name
																		}
																	</p>
																	{isOnService && (
																		<div>
																			<button
																				onClick={() =>
																					makeCustomerDone(
																						queueItem
																					)
																				}
																				style={{
																					backgroundColor:
																						"green",
																					color: "white",
																				}}
																			>
																				Mark
																				Done
																			</button>

																			{showAutoDoneActions ? (
																				<div>
																					{!isPaused &&
																					timer &&
																					timer >
																						0 ? (
																						<p>
																							Time
																							Remaining
																							for
																							marking:{" "}
																							{formatTime(
																								timer
																							)}
																						</p>
																					) : (
																						<>

																						</>
																					)}
																					<button
																						onClick={() =>
																							togglePauseTimer(
																								queueItem.waitlist_id
																							)
																						}
																						style={{
																							backgroundColor:
																								pausedTimers[
																									queueItem
																										.waitlist_id
																								]
																									? "red"
																									: "blue",
																							color: "white",
																						}}
																					>
																						{pausedTimers[
																							queueItem
																								.waitlist_id
																						]
																							? "Resume Auto Done"
																							: "Pause Auto Done"}
																					</button>
																				</div>
																			) : (
																				<>

																				</>
																			)}
																		</div>
																	)}
																</div>
															)}
														</Draggable>
													);
												})}
											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</div>
						</div>
					);
				})}
			</DragDropContext>
		</div>
	);
};

export default QueueContainer;
