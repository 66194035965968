import React, { useState } from "react";
import useWaitListStore from "../../../store/useWaitListStore";
import { IGridViewProps, PatientStatus } from "../../../types/wait-list";
import StatusCard from "./StatusCard";
import { Button } from "@components/ui/button";
import CustomCheckbox2 from "@components/form/CustomCheckbox2";
import { handleSelectAllChange } from "@utils/functions";
import fileIcon from "@assets/images/file.svg";
import GridCard from "./GridCard";
import { TooltipProvider } from "@components/ui/tooltip";
import { QueueEntry } from "@type/waitlist/waitlist";

const GridView: React.FC<IGridViewProps> = ({ status }) => {
	const [selectAllChecked, setSelectAllChecked] = useState(false);
	const [selectedRows, setSelectedRows] = useState<number[]>([]);

	const patientsObject = useWaitListStore((s) => s.patientsObject);

	const number: number = patientsObject[status].length;

	return (
		<div className="flex h-[375.23px] flex-col overflow-x-hidden rounded-lg bg-primary/5">
			<StatusCard
				statusCardBg="bg-status-3"
				status={status}
				statusText={`Showing ${number} of ${number}`}
			/>
			{number ? (
				<div className="flex flex-1 flex-col overflow-auto">
					<div
						className={`grid w-full grid-cols-[24px_180px_auto_auto_auto] place-items-center justify-items-stretch gap-2 border-b py-2 pl-[26px] pr-[18px] text-center`}
					>
						<CustomCheckbox2
							handleCheckboxChange={() =>
								handleSelectAllChange(
									!selectAllChecked,
									setSelectedRows,
									setSelectAllChecked,
									(
										patientsObject[status] as QueueEntry[]
									).map((item) => item.waitlist_id)
								)
							}
							isChecked={selectAllChecked}
							id={status + " selectAll"}
							className="rounded-sm border-2"
						/>

						<p className="text-xs font-semibold text-[#111827]">
							Name
						</p>
						<p className="text-xs font-semibold text-[#111827]">
							Station
						</p>
						{status === PatientStatus.NextInLine && (
							<p className="text-center text-xs font-semibold text-[#111827]">
								Phone
							</p>
						)}
						{(status === PatientStatus.Pending ||
							status === PatientStatus.Upcoming ||
							status === PatientStatus.Serving) && (
							<p className="text-center text-xs font-semibold text-[#111827]">
								Wait Time
							</p>
						)}

						<div className="flex items-center justify-end space-x-[5px] opacity-90">
							<Button
								className="flex h-8 w-8 items-center justify-center rounded-[6px] border border-[#6B7280] bg-transparent"
								variant="outline"
							>
								<i className="mgc_delete_2_line before-text-gray-2 text-[12px]" />
							</Button>

							<Button
								className="flex h-8 w-8 items-center justify-center rounded-[6px] border border-[#6B7280] bg-transparent"
								variant="outline"
							>
								<i className="mgc_bell_ringing_line before-text-gray-2 text-[12px]" />
							</Button>

							<Button
								className="flex h-8 w-8 items-center justify-center rounded-[6px] border border-[#6B7280] bg-transparent"
								variant="outline"
							>
								<i className="mgc_check_circle_line before-text-gray-2 text-[12px]" />
							</Button>
						</div>
					</div>
					<TooltipProvider>
						<div className="flex flex-1 flex-col space-y-1 px-3 py-1">
							{patientsObject[status]?.map(
								(patient: QueueEntry, index: number) => (
									<GridCard
										key={index}
										selectedRows={selectedRows}
										noOfPatients={
											patientsObject[status].length
										}
										status={status}
										index={index}
										patient={patient}
										setSelectAllChecked={
											setSelectAllChecked
										}
										setSelectedRows={setSelectedRows}
									/>
								)
							)}
						</div>
					</TooltipProvider>
				</div>
			) : (
				<div className="mt-10 flex justify-center text-base text-[#A1AAB6]">
					<img src={fileIcon} alt="file" />
					<span className="ml-1">No Data</span>
				</div>
			)}
		</div>
	);
};

export default GridView;
