import { useState } from "react";
import moment from "moment-timezone";
import { zodResolver } from "@hookform/resolvers/zod";
import LocationInfo from "../components/LocationInfo";
import Stepper from "@components/Stepper";
import ScheduleForm from "../components/ScheduleForm";
import LocationMember from "../components/LocationMember";
import { Button } from "@components/ui/button";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm, FormProvider } from "react-hook-form";
import {
	AddLocationDataV2,
	AddLocationSchemaV2,
	WorkingHoursSchema,
	TeamMembersSchema,
} from "@src/types/Location";
import { defaultTimeSlots, defaultTimeSlotsV2 } from "@utils/constants";
import { isTimeOverlapping, convertSchedule } from "@utils/functions";
import {
	AddBusinessLocationSlice,
	UpdateLocationOperatingHoursSlice,
} from "@src/store/slices/locations/locationSlice";
import RequestIsLoading from "@components/RequestIsLoading";
import Header from "@src/layouts/Header/Header";
import toast from "react-hot-toast";

export default function LocationTabList() {
	const [addLocationResponse, setAddLocationResponse] = useState<any>(null);

	const [currentStep, setCurrentStep] = useState<number>(0);
	const [slots, setSlots] = useState<any[]>(defaultTimeSlots);
	const [slotsV2, setSlotsV2] = useState<any>(defaultTimeSlotsV2);
	const navigate = useNavigate();
	const stepDetails: string[] = [
		"Location Information",
		"Operating Hours",
		"Members",
	];

	const addBusinessLocationMutation = AddBusinessLocationSlice(
		(data) => {
			setAddLocationResponse(data.location);
			setCurrentStep(currentStep + 1);
		},
		(error) => console.log("🚀 ~ LocationTabList ~ error:", error)
	);

	const updateBusinessWorkingHoursMutation =
		UpdateLocationOperatingHoursSlice(
			() => {
				setCurrentStep(currentStep + 1);
			},
			(error) => console.log("🚀 ~ LocationTabList ~ error:", error)
		);

	const methods = useForm<AddLocationDataV2>({
		mode: "onChange",
		reValidateMode: "onChange",
		resolver: zodResolver(
			currentStep === 0
				? AddLocationSchemaV2
				: currentStep === 1
					? WorkingHoursSchema
					: TeamMembersSchema
		),
		defaultValues: {
			name: "",
			state: "",
			address: "",
			city: "",
			country: "",
			approximate_waiting_time: "15",
			scheule_block_in_min: 15,
			time_zone: moment.tz.guess(),
			day_time_slots: slots,
			child_update_strategy: "specific", // "specific|ignore|default|non_default|override",
			child_ids: [],
			waitlist_schedule_option: "waitlist", // "all|schedule|waitlist"
			data: slotsV2,
			slots: slots,
			members: [],
			is_waitlist: true,
			is_schedule: false,
		},
	});

	const {
		handleSubmit,
		formState: { isValid, errors },
	} = methods;

	const handleAddLocationForm: SubmitHandler<AddLocationDataV2> = async (
		data
	) => {
		if (!isValid) {
			// Trigger validation for the current step only
			await methods.trigger(Object.keys(methods.getValues()) as any);
			return;
		}

		try {
			if (currentStep === 0) {
				if (data.approximate_waiting_time.length <= 0)
					data.approximate_waiting_time = "15";

				const hours = Math.floor(+data.approximate_waiting_time / 60);
				const minutes = +data.approximate_waiting_time % 60;

				const newFormData = new FormData();
				newFormData.append("name", data.name);
				newFormData.append("state", data.state);
				newFormData.append("address", data.address);
				newFormData.append("city", data.city);
				newFormData.append("country", data.country);
				newFormData.append(
					"approximate_waiting_time",
					`${String(hours).padStart(2, "0")}:${String(
						data.approximate_waiting_time ? minutes : 30
					).padStart(2, "0")}:00`
				);
				newFormData.append("time_zone", data.time_zone);
				newFormData.append(
					"scheule_block_in_min",
					(data.scheule_block_in_min || 15).toString()
				);
				newFormData.append("auto_clearing_minutes", (15).toString());
				newFormData.append("is_auto_clearing_on", (1).toString());
				if (data.image) newFormData.append("image", data.image);

				await addBusinessLocationMutation.mutateAsync(newFormData);
				setCurrentStep(1);
			} else if (currentStep === 1) {
				if (isTimeOverlapping(slots)) {
					toast.error(
						"Time slots are overlapping. Please adjust your schedule."
					);
					return;
				}
				const payload = {
					child_update_strategy: "specific",
					child_ids: [addLocationResponse?.id],
					waitlist_schedule_option:
						data.is_schedule && data.is_waitlist
							? "all"
							: data.is_schedule
								? "schedule"
								: "waitlist",
					data: convertSchedule(methods.getValues("slots")),
				};
				await updateBusinessWorkingHoursMutation.mutateAsync({
					data: payload,
					queryParams: { location_id: addLocationResponse?.id || 0 },
				});
				setCurrentStep(2);
			} else if (currentStep === 2) {
				navigate("/dashboard/locations");
			}
		} catch (error) {
			console.error("Error in form submission:", error);
			toast.error("An error occurred. Please try again.");
		}
	};

	// const handleAddLocationForm: SubmitHandler<AddLocationDataV2> = async (
	// 	data
	// ) => {
	// 	if (isValid && currentStep === 0) {
	// 		if (data.approximate_waiting_time.length <= 0)
	// 			data.approximate_waiting_time = "15";

	// 		const hours = Math.floor(+data.approximate_waiting_time / 60);
	// 		const minutes = +data.approximate_waiting_time % 60;

	// 		const newFormData = new FormData();
	// 		newFormData.append("name", data.name);
	// 		newFormData.append("state", data.state);
	// 		newFormData.append("address", data.address);
	// 		newFormData.append("city", data.city);
	// 		newFormData.append("country", data.country);
	// 		newFormData.append(
	// 			"approximate_waiting_time",
	// 			`${String(hours).padStart(2, "0")}:${String(
	// 				data.approximate_waiting_time ? minutes : 30
	// 			).padStart(2, "0")}:00`
	// 		);
	// 		newFormData.append("time_zone", data.time_zone);
	// 		newFormData.append(
	// 			"scheule_block_in_min",
	// 			(data.scheule_block_in_min || 15).toString()
	// 		);
	// 		newFormData.append("auto_clearing_minutes", (15).toString());
	// 		newFormData.append("is_auto_clearing_on", (1).toString());
	// 		if (data.image) newFormData.append("image", data.image);
	// 		addBusinessLocationMutation.mutate(newFormData);
	// 		return;
	// 	} else if (isValid && currentStep === 1) {
	// 		if (isTimeOverlapping(slots)) return;
	// 		const payload = {
	// 			child_update_strategy: "specific",
	// 			child_ids: [addLocationResponse?.id],
	// 			waitlist_schedule_option:
	// 				data.is_schedule && data.is_waitlist
	// 					? "all"
	// 					: data.is_schedule
	// 						? "schedule"
	// 						: "waitlist",
	// 			data: convertSchedule(methods.getValues("slots")),
	// 		};
	// 		updateBusinessWorkingHoursMutation.mutate({
	// 			data: payload,
	// 			queryParams: { location_id: addLocationResponse?.id || 0 },
	// 		});
	// 		return;
	// 	} else if (isValid && currentStep === 2) {
	// 		navigate("/dashboard/locations");
	// 		return;
	// 	}
	// };

	const handleCancel = () => {
		navigate("/dashboard/locations");
	};

	const RenderStepContent = (activeStep: number) => {
		switch (activeStep) {
			case 0:
				return <LocationInfo />;
			case 1:
				return <ScheduleForm />;
			case 2:
				return <LocationMember locationId={addLocationResponse?.id} />;
			default:
				return "";
		}
	};

	return (
		<main className="flex flex-1 flex-col">
			<Header
				title={"Add Locations"}
				showDate={false}
				canGoBack
				titleContent={
					<div className="flex items-center space-x-1">
						<Button
							variant={"link"}
							className="p-0 text-base font-medium leading-none text-main-7 hover:no-underline"
							onClick={() => navigate("/dashboard/locations")}
						>
							{"Location > "}
						</Button>

						<h2 className="text-base font-medium text-primary">
							{"Add Location"}
						</h2>
					</div>
				}
			/>
			<div className="flex flex-1 flex-col overflow-auto">
				<FormProvider {...methods}>
					<RequestIsLoading
						isLoading={
							addBusinessLocationMutation.isLoading ||
							updateBusinessWorkingHoursMutation.isLoading
						}
					/>
					<form
						encType="multipart/form-data"
						onSubmit={handleSubmit(handleAddLocationForm)}
					>
						<div className="mx-auto w-full max-w-[804px] flex-1 space-y-6">
							<Stepper
								step={currentStep}
								steplist={stepDetails}
							/>
							{RenderStepContent(currentStep)}
						</div>
						<div className="mx-auto flex w-full max-w-[804px] justify-end gap-2 py-4">
							<Button
								type="reset"
								onClick={handleCancel}
								variant={"ghost"}
							>
								{currentStep === 2 ? "Skip for now" : "Cancel"}
							</Button>
							<Button type="submit" className="px-9">
								{currentStep === 0
									? "Next"
									: currentStep === 1
										? "Save & Continue"
										: "Save"}
							</Button>
						</div>
					</form>
				</FormProvider>
			</div>
		</main>
	);
}
