import { Views } from "react-big-calendar";
import { create } from "zustand";
import { persist } from "zustand/middleware";

type Keys = keyof typeof Views;
interface AppointmentStore {
	appointments: any;
	appointmentDetails: any;
	appointmentSubmissionDetails: any;
	appointmentLocationDetails: any;
	stationIds: string[];
	filteredAppointments: any[];
	appointmentTypeId: number;
	duration: number;
	view: (typeof Views)[Keys];
	date: Date | string;
	appointmentSettings: Record<string, any>;
	appointmentSettingsSelectedLocation: string;
	appointmentSettingsSelectedStation: string;
	showAppointmentSettingsModal: boolean;
	showPatientInformationModal: boolean;
	setAppointments: (appointments: any) => void;
	setAppointmentDetails: (locationDetails: any) => void;
	setAppointmentLocationDetails: (locationDetails: any) => void;
	setFilteredAppointments: (filteredAppointments: any) => void;
	setAppointmentSubmissionDetails: (submissionData: any) => void;
	setStationIds: (stationIds: string[]) => void;
	setAppointmentTypeId: (appointmentTypeId: number) => void;
	setDuration: (duration: number) => void;
	setView: (view: (typeof Views)[Keys]) => void;
	setDate: (date: string) => void;
	setAppointmentSettings: (settings: any) => void;
	setAppointmentSettingsSelectedLocation: (location_id: string) => void;
	setAppointmentSettingsSelectedStation: (station_id: string) => void;
	setShowAppointmentSettingsModal: (show: boolean) => void;
	setShowPatientInformationModal: (show: boolean) => void;
	reset: () => void;
}

const initialState = {
	appointments: [],
	filteredAppointments: [],
	appointmentDetails: [],
	appointmentLocationDetails: [],
	appointmentSubmissionDetails: [],
	appointmentSettings: {},
	appointmentSettingsSelectedLocation: "",
	appointmentSettingsSelectedStation: "",
	stationIds: [],
	showPatientInformationModal: false,
	appointmentTypeId: 0,
	duration: 0,
	date: new Date().toISOString(),
	showAppointmentSettingsModal: false,
	view: Views.DAY,
};

const useAppointmentStore = create<
	AppointmentStore,
	[["zustand/persist", AppointmentStore]]
>(
	persist(
		(set) => ({
			...initialState,
			setAppointments: (appointments: any) => {
				set(() => ({ appointments }));
			},
			setAppointmentDetails: (appointmentDetails: any) => {
				set(() => ({ appointmentDetails }));
			},
			setAppointmentLocationDetails: (
				appointmentLocationDetails: any
			) => {
				set(() => ({ appointmentLocationDetails }));
			},
			setFilteredAppointments: (filteredAppointments: []) => {
				set(() => ({ filteredAppointments }));
			},
			setAppointmentSubmissionDetails: (
				appointmentSubmissionDetails: any
			) => {
				set(() => ({ appointmentSubmissionDetails }));
			},
			setStationIds: (stationIds: string[]) => {
				set(() => ({ stationIds }));
			},
			setAppointmentTypeId: (appointmentTypeId: number) => {
				set(() => ({ appointmentTypeId }));
			},
			setDuration: (duration: number) => {
				set(() => ({ duration }));
			},
			setView: (view) => set({ view }),
			setDate: (date: string) => {
				set(() => ({ date }));
			},
			setAppointmentSettings: (settings) => {
				set({
					appointmentSettings: settings,
				});
			},
			setAppointmentSettingsSelectedLocation: (location_id: string) => {
				set({
					appointmentSettingsSelectedLocation: location_id,
				});
			},
			setAppointmentSettingsSelectedStation: (station_id: string) => {
				set({
					appointmentSettingsSelectedStation: station_id,
				});
			},
			setShowAppointmentSettingsModal: (show: boolean) => {
				set({
					showAppointmentSettingsModal: show,
				});
			},
			setShowPatientInformationModal: (show: boolean) => {
				set({
					showPatientInformationModal: show,
				});
			},
			reset: () => set(initialState),
		}),
		{
			name: "appointments-storage",
			getStorage: () => localStorage,
		}
	)
);

export default useAppointmentStore;
