import { BulkUpdateWaitlistSlice } from "@/src/store/slices/waitlist/updateWaitlistSlice";
import {
	BulkUpdateWaitlistType,
	UpdateWaitlistEnum,
	UpdateWaitlistEnumType,
} from "@/src/types/waitlist/waitlist";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { LuX } from "react-icons/lu";
import useCustomToast from "../../CustomToast";
import { LoaderButton } from "../../form/LoaderButton";
import { Button } from "../../ui/button";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "../../ui/dialog";

const BulkUpdateModal: React.FC<{
	showBulkUpdateModal: boolean;
	setShowBulkUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
	selectedRows: number[];
	action?: UpdateWaitlistEnumType;
	setSelectAllChecked: React.Dispatch<React.SetStateAction<boolean>>;
	setSelectedRows: React.Dispatch<React.SetStateAction<number[]>>;
}> = ({
	action,
	selectedRows,
	setSelectedRows,
	showBulkUpdateModal,
	setShowBulkUpdateModal,
	setSelectAllChecked,
}) => {
	const customToast = useCustomToast();

	const {
		handleSubmit,
		// register,
		// setValue,
		// watch,
		reset,
		formState: { errors },
	} = useForm<BulkUpdateWaitlistType>();

	const bulkUpdateWaitlistMutation = BulkUpdateWaitlistSlice(
		() => {
			customToast("Action completed", {
				id: "manage-patients",
				type: "success",
			});
			setShowBulkUpdateModal(false);
			setSelectAllChecked(false);
			setSelectedRows([]);
		},
		() => {
			customToast("Action could not be completed", {
				id: "manage-patients",
				type: "error",
			});
		}
	);

	const onSubmit: SubmitHandler<BulkUpdateWaitlistType> = (data) => {
		bulkUpdateWaitlistMutation.mutate(data);
	};

	const getActionTitle = () => {
		switch (action) {
			case UpdateWaitlistEnum.CancelledByBusinessAdmin:
				return "Delete Selected Patients";
			case UpdateWaitlistEnum.NotifyTurn:
				return "Notify Selected Patients";
			case UpdateWaitlistEnum.Upcoming:
				return "Accept Selected Patients";
			case UpdateWaitlistEnum.NextInLine:
				return "Move Selected Patients to Next In Line";
			case UpdateWaitlistEnum.GettingService:
				return "Serve Selected Patients";
			case UpdateWaitlistEnum.Done:
				return "Mark Selected Patients as Done";
			default:
				return "";
		}
	};

	const getActionSubtitle = () => {
		switch (action) {
			case UpdateWaitlistEnum.CancelledByBusinessAdmin:
				return (
					<p className="text-sm leading-[24px] tracking-[-1%] text-main-4">
						<span className="font-semibold">
							Are you sure you want to delete the selected
							patients?
						</span>
						<br />
						This will remove them from the waitlist and cancel their
						appointments.
					</p>
				);
			case UpdateWaitlistEnum.NotifyTurn:
				return (
					<p className="text-sm leading-[24px] tracking-[-1%] text-main-4">
						<span className="font-semibold">
							Are you sure you want to notify the selected
							patients?
						</span>
						<br />
						They will receive a notification about their upcoming
						turn.
					</p>
				);
			case UpdateWaitlistEnum.Upcoming:
				return (
					<p className="text-sm leading-[24px] tracking-[-1%] text-main-4">
						<span className="font-semibold">
							Are you sure you want to accept the selected
							patients?
						</span>
						<br />
						They will be confirmed for their upcoming appointment.
					</p>
				);
			case UpdateWaitlistEnum.NextInLine:
				return (
					<p className="text-sm leading-[24px] tracking-[-1%] text-main-4">
						<span className="font-semibold">
							Are you sure you want to move the selected patients
							next in line?
						</span>
						<br />
						This will advance them to the front of the queue.
					</p>
				);
			case UpdateWaitlistEnum.GettingService:
				return (
					<p className="text-sm leading-[24px] tracking-[-1%] text-main-4">
						<span className="font-semibold">
							Are you sure you want to serve the selected
							patients?
						</span>
						<br />
						They will be marked as being attended to.
					</p>
				);
			case UpdateWaitlistEnum.Done:
				return (
					<p className="text-sm leading-[24px] tracking-[-1%] text-main-4">
						<span className="font-semibold">
							Are you sure you want to mark the selected patients
							as done?
						</span>
						<br />
						Their service will be completed and appointments
						finalized.
					</p>
				);
			default:
				return "";
		}
	};

	React.useEffect(() => {
		if (showBulkUpdateModal && selectedRows.length && action)
			reset({
				status: action,
				waitlist_ids: selectedRows,
			});
	}, [showBulkUpdateModal]);

	return (
		<Dialog
			onOpenChange={() => {
				if (!bulkUpdateWaitlistMutation.isLoading) {
					reset();
					setShowBulkUpdateModal(!showBulkUpdateModal);
				}
			}}
			open={showBulkUpdateModal}
		>
			<DialogContent className="max-w-[400px]">
				<form
					onSubmit={handleSubmit(onSubmit)}
					className="flex flex-col space-y-8"
				>
					<DialogHeader className="flex flex-row justify-between pb-0">
						<DialogTitle className="text-[22px] leading-[30px] -tracking-[1%] text-[#323539]">
							{getActionTitle()}
						</DialogTitle>

						<LuX
							color="#858C95"
							size={20}
							className="cursor-pointer"
							onClick={() => {
								if (!bulkUpdateWaitlistMutation.isLoading) {
									reset();
									setShowBulkUpdateModal(false);
								}
							}}
						/>
					</DialogHeader>
					<div className="flex flex-col items-start space-y-2">
						{getActionSubtitle()}
					</div>

					{errors.root?.message && (
						<p className="!-mt-0 px-8 text-sm tracking-[-0.1px] text-red-500">
							{errors.root?.message}
						</p>
					)}
					<DialogFooter className="flex justify-between space-x-4">
						<Button
							disabled={bulkUpdateWaitlistMutation.isLoading}
							onClick={() => {
								if (!bulkUpdateWaitlistMutation.isLoading) {
									reset();
									setShowBulkUpdateModal(false);
								}
							}}
							variant="outline"
							className="w-full flex-1 px-8"
						>
							Cancel
						</Button>

						<LoaderButton
							disabled={bulkUpdateWaitlistMutation.isLoading}
							loading={bulkUpdateWaitlistMutation.isLoading}
							loaderSize={20}
							className="flex-1 px-8 text-white"
							type="submit"
							variant={
								action ===
								UpdateWaitlistEnum.CancelledByBusinessAdmin
									? "destructive"
									: "default"
							}
						>
							Update
						</LoaderButton>
					</DialogFooter>
				</form>
			</DialogContent>
		</Dialog>
	);
};

export default BulkUpdateModal;
