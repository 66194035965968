import { z } from "zod";
import { CustomIntake } from "./waitlist/custom-intakes";
import { operatingHourSchema, timeSlotSchema } from "./DaySlots";

export interface Location {
	id: number;
	name: string;
	address: string;
	country: string;
	state: string;
	city: string;
	admin_id: number;
	business_id: number;
	approximate_waiting_time: string;
	created_at: Date;
	updated_at: Date;
	deleted_at: null;
	admin: Admin;
	schedule_block_in_min: string;
	stations: Station[];
}

interface Admin {
	id: number;
	name: string;
	email: string;
	role: string;
	job_title: null;
	stripe_customer_id: null;
	created_at: Date;
	updated_at: Date;
	deleted_at: null;
	stripe_id: string;
	pm_type: string;
	pm_last_four: string;
	trial_ends_at: null;
	business_id: number;
	is_email_verified: number;
}

interface Station {
	id: number;
	name: string;
	location_id: number;
	admin_id: number;
	created_at: Date;
	updated_at: Date;
	deleted_at: null;
	url_code: string;
	queue_url: string;
	is_queue_active: boolean;
	is_active: boolean;
}

interface LocationsAdmin {
	id: number;
	name: string;
	email: string;
	role: string;
	job_title: string | null;
	stripe_customer_id: string | null;
	created_at: string;
	updated_at: string;
	stripe_id: string;
	pm_type: string | null;
	pm_last_four: string | null;
	trial_ends_at: string | null;
	business_id: number;
	is_email_verified: number;
	is_active: number;
	company_id: string | null;
	phone_number: string | null;
}

interface StationSettings {
	time_zone: string;
	is_queue_active: number;
	manual_wait_time: string;
	auto_flow_enabled: number;
	average_wait_time: string;
	auto_approve_enabled: number;
	schedule_block_in_min: number;
	total_waitlists_count: number;
	use_average_wait_time: number;
	priority_order_enabled: number;
}

interface Settings {
	id: number;
	settingable_type: string;
	settingable_id: number;
	created_at: string;
	updated_at: string;
	settings: StationSettings;
}

export interface StationsProps {
	id: number;
	name: string;
	location_id: number;
	admin_id: number;
	created_at: string;
	updated_at: string;
	is_active: number;
	business_id: number;
	schedule_before: string | null;
	average_waiting_time: string | null;
	use_average_wait_time: number | null;
	url_code: string;
	schedule_url: string;
	queue_url: string;
	is_queue_active: boolean;
	is_authorized_to_user: boolean;
	settings: Settings;
	queue_setting: QueueSchemaData;
	schedule_optimizer: scheduleOptimizerProps;
}

export type StationsArrayProps = StationsProps[];

export interface LocationsIProps {
	id: number;
	image?: string;
	image_url?: string;
	name: string;
	address: string;
	country: string;
	state: string;
	city: string;
	admin_id: number;
	business_id: number;
	approximate_waiting_time: string;
	created_at: string;
	updated_at: string;
	is_active: number;
	available_slots_per_day: string | null;
	scheule_block_in_min: number;
	time_zone: string;
	average_waiting_time: string | null;
	use_average_wait_time: number;
	auto_clearing_minutes: number;
	is_auto_clearing_on: number;
	settings: string | null;
	admin: LocationsAdmin;
	stations: StationsProps[];
	qr_url: string;
	join_url: string;
	team_members: [];
	description: string | null;
}

export type LocationArray = LocationsIProps[];

export interface AddLocationInfoType {
	id: number;
}

export interface LocationResponse {
	id: number;
	location_image?: string;
	name: string;
	address: string;
	country: string;
	state: string;
	city: string;
	phone_number: string;
	image: string;
	image_url?: string;
	admin_id: number;
	business_id: number;
	approximate_waiting_time: string;
	created_at: string;
	updated_at: string;
	is_active: number;
	available_slots_per_day: number | null;
	scheule_block_in_min: number;
	time_zone: string;
	average_waiting_time: string | null;
	use_average_wait_time: number;
	auto_clearing_minutes: number;
	is_auto_clearing_on: number;
	settings: any | null;
	stations: StationsArrayProps;
	queue_settings: GetLocationsQueueSetting;
}

interface GetLocationsQueueSetting {
	id: number;
	auto_approve: number;
	is_visible: number;
	settingable_id: number;
	settingable_type: string;
	created_at: string;
	updated_at: string;
}

export interface GetLocationsResponse {
	status: boolean;
	message: string;
	data: LocationArray;
}

export type LocationDetailsArray = LocationResponse[];

export interface AddBusinessLocationResponseType {
	status: boolean;
	message: string;
	location: LocationResponse;
}

interface GetBusinessLocationsStation {
	id: number;
	name: string;
	location_id: number;
	admin_id: number;
	created_at: string;
	updated_at: string;
	is_active: number;
	business_id: number;
	schedule_before: string | null;
	average_waiting_time: string | null;
	use_average_wait_time: number;
	waitlist_custom_fields: CustomIntake[];
	schedule_custom_fields: CustomIntake[];
}

interface GetBusinessLocationsLocation {
	id: number;
	name: string;
	stations: GetBusinessLocationsStation[];
}

interface GetBusinessLocationsQueueData {
	id: number;
	name: string;
	locations: GetBusinessLocationsLocation[];
	logo: string | null;
}

export interface GetBusinessLocationsQueueResponse {
	status: boolean;
	message: string;
	data: GetBusinessLocationsQueueData;
}

const dayDataSchema = z.object({
	is_active: z.boolean(),
	time_slots: z.array(timeSlotSchema),
});

export const AddLocationSchema = z.object({
	name: z.string().min(1, { message: "Location name is required" }),
	address: z.string().min(1, { message: "Address is required" }),
	image: z.any(),
	country: z.string().min(1, { message: "Country is required" }),
	state: z.string().min(1, { message: "State is required" }),
	city: z.string().min(1, { message: "City is required" }),
	approximate_waiting_time: z.string().refine(
		(time) => {
			return +time >= 5;
		},
		{
			message: "Minimum wait time: 5 mins",
		}
	),
	schedule_block_in_min: z
		.number()
		.min(5, "Minimum schedule block time: 5 mins"),
	time_zone: z.string().refine((value) => !!value, {
		message: "Kindly select time zone",
	}),
	day_time_slots: z.array(
		z.object({
			day: z.string(),
			day_value: z.number(),
			is_active: z.number(),
			time_slots: z.array(
				z.object({
					start_time: z.string(),
					end_time: z.string(),
				})
			),
		})
	),
});

// Define the TimeSlot schema
const TimeSlotSchema = z.object({
	start_time: z.string(),
	end_time: z.string(),
});

// Define the OperatingHour schema
const OperatingHourSchema = z.object({
	id: z.number().optional(),
	day: z.string(),
	day_value: z.number(),
	is_active: z.union([z.literal(0), z.literal(1)]),
	location: z.any().optional(),
	location_id: z.number().optional(),
	service_time_slots: z.array(z.any()).optional(),
	time_slots: z.array(TimeSlotSchema),
});

export const AddLocationSchemaV2 = z.object({
	name: z.string().min(1, { message: "Location name is required" }),
	address: z.string().min(1, { message: "Address is required" }),
	image: z.any(),
	country: z.string().min(1, { message: "Country is required" }),
	state: z.string().min(1, { message: "State is required" }),
	city: z.string().min(1, { message: "City is required" }),
	approximate_waiting_time: z.string().refine(
		(time) => {
			return +time >= 5;
		},
		{
			message: "Minimum wait time: 5 mins",
		}
	),
	scheule_block_in_min: z
		.number()
		.min(5, "Minimum schedule block time: 5 mins"),
	time_zone: z.string().refine((value) => !!value, {
		message: "Kindly select time zone",
	}),
	day_time_slots: z.array(operatingHourSchema),
	child_update_strategy: z
		.enum(["specific", "ignore", "default", "non_default", "override"])
		.optional(),
	child_ids: z.array(z.number()),
	waitlist_schedule_option: z.enum(["all", "schedule", "waitlist"]),
	data: z.record(dayDataSchema),
	is_waitlist: z.boolean(),
	is_schedule: z.boolean(),
	members: z.array(z.object({})).optional(),
	slots: z.array(OperatingHourSchema).optional(),
	tabView: z.enum(["schedule", "waitlist"]).optional(),
	is_station_default_hour: z.boolean().optional(),
	is_station_override_hour: z.boolean().optional(),
	manual_wait_time: z.number().optional(),
	schedule_block_in_minute: z.number().optional(),
});

export const TeamMembersSchema = z.object({
	members: z.array(
		z.object({
			memberId: z.number().optional(),
			memberName: z.string().min(1, "Name is required").optional(),
			memberRole: z.string().min(1, "Role is required").optional(),
			memberEmail: z.string().email("Invalid email address").optional(),
			memberPhone: z
				.string()
				.min(1, "Phone number is required")
				.optional(),
		})
	),
});

export const WorkingHoursSchema = z.object({
	child_update_strategy: z.enum([
		"specific",
		"ignore",
		"default",
		"non_default",
		"override",
	]),
	child_ids: z.array(z.number()),
	waitlist_schedule_option: z.enum(["all", "schedule", "waitlist"]),
	data: z.record(dayDataSchema),
	is_waitlist: z.boolean(),
	is_schedule: z.boolean(),
});

export const EditLocationInfoSchema = z.object({
	name: z
		.string()
		.min(3, { message: "Location name is required" })
		.optional(),
	phone_number: z.string().optional(),
	image: z.string().optional(),
	address: z.string().optional(),
	country: z.string().optional(),
	state: z.string().optional(),
	city: z.string().optional(),
	is_active: z.number().optional(),
	approximate_waiting_time: z
		.string()
		.refine(
			(time) => {
				return +time >= 5;
			},
			{
				message: "Minimum wait time: 5 mins",
			}
		)
		.optional(),
	scheule_block_in_min: z
		.number()
		.min(5, "Minimum schedule block time: 5 mins")
		.optional(),
	time_zone: z
		.string()
		.refine((value) => !!value, {
			message: "Kindly select time zone",
		})
		.optional(),
	day_time_slots: z
		.array(
			z.object({
				day: z.string(),
				day_value: z.number(),
				is_active: z.number(),
				time_slots: z.array(
					z.object({
						start_time: z.string(),
						end_time: z.string(),
					})
				),
			})
		)
		.optional(),
});

export const waitlistSettingSchema = z.object({
	auto_clearing: z.number().optional(),
	auto_clearing_minute: z.number().optional(),
	auto_flow: z.number().optional(),
	manual_wait_time: z.number().optional(),
	use_average_time: z.number().optional(),
	is_queue_active: z.number().optional(),
	is_visible: z.number().optional(),
	join_from_qr: z.number().optional(),
	join_from_url: z.number().optional(),
	qr_code_url: z.string().optional(),
	join_url: z.string().optional(),
	waitlist_autoqueue_option: z.enum(["minutes", "hours"]).optional(),
});

export const scheduleSettingSchema = z.object({
	join_from_qr: z.number().optional(),
	join_from_url: z.number().optional(),
	schedule_block_in_minute: z.number().optional(),
	is_visible: z.number().optional(),
	schedule_block_away_count: z.number().optional(),
	schedule_block_away_option: z.enum(["days", "weeks", "months"]).optional(),
	auto_approve: z.number().optional(),
	is_open: z.number().optional(),
	qr_code_url: z.string().optional(),
	join_url: z.string().optional(),
});

export const addRemoveCUstomSlotSchema = z.object({
	location_ids_to_remove: z.array(z.number()).optional(),
	location_ids_to_add: z.array(z.number()).optional(),
	station_ids_to_add: z.array(z.number()).optional(),
	station_ids_to_remove: z.array(z.number()).optional(),
});

export const scheduleOptimizerSchema = z.object({
	is_active: z.number().optional(),
	waitlist_selected: z.number().optional(),
	waitlist_number_of_people: z.number().optional(),
	high_priority_selected: z.number().optional(),
	high_priority_number_of_people: z.number().optional(),
	upcoming_appointments_selected: z.number().optional(),
	upcoming_appointments_number_of_people: z.number().optional(),
	upcoming_appointments_limit: z.number().optional(),
	upcoming_appointments_limit_options: z
		.enum(["days", "weeks", "months"])
		.optional(),
});

export const AppointmentTypeSchema = z.object({
	id: z.number().optional(),
	title: z.string().optional(),
	time_in_minute: z.number().optional(),
	apply_to_option: z
		.object({
			apply_to_all: z.number(),
			locations: z.array(
				z
					.object({
						id: z.number().optional(),
						update_location: z.number().optional(),
						apply_to_all_stations: z.number().optional(),
						selected_stations: z.array(z.number()).optional(),
					})
					.optional()
			),
		})
		.optional(),
});

export const CustomTimeSlotSchema = z.object({
	id: z.number(),
	key: z.string(),
	title: z.string(),
	time_in_minute: z.number(),
	business_id: z.number(),
	created_at: z.string().refine((date) => !isNaN(Date.parse(date)), {
		message: "Invalid date format",
	}),
	updated_at: z.string().refine((date) => !isNaN(Date.parse(date)), {
		message: "Invalid date format",
	}),
	pivot: z.object({
		queue_setting_id: z.number(),
		time_slot_id: z.number(),
	}),
});

export const queueSettingsSchema = z.object({
	id: z.number().optional(),
	auto_approve: z.number().optional(),
	location_id: z.number().optional(),
	is_visible: z.number().optional(),
	apply_to_option: z
		.object({
			apply_to_all: z.number(), // 0 means locations array must have elements
			locations: z.array(
				z
					.object({
						id: z.number().optional(),
						update_location: z.number().optional(),
						apply_to_all_stations: z.number().optional(),
						selected_stations: z.array(z.number()).optional(),
					})
					.optional()
			),
		})
		.optional()
		.refine(
			(data) =>
				data?.apply_to_all === 1 ||
				(data?.locations && data.locations.length > 0),
			{
				message:
					"Kindly select at least one location or select apply to all",
				path: [""],
			}
		),
	waitlist_setting: waitlistSettingSchema.optional(),
	schedule_setting: scheduleSettingSchema.optional(),
	child_update_strategy: z.string().optional(),
	child_ids: z.array(z.number()).optional(),
	schedule_optimizer: scheduleOptimizerSchema.optional(),
	custom_time_slots: z.array(AppointmentTypeSchema).optional(),
	queue_setting: z
		.object({
			schedule_setting: scheduleSettingSchema.optional(),
			schedule_optimizer: scheduleOptimizerSchema.optional(),
			custom_time_slots: z.array(CustomTimeSlotSchema).optional(),
			waitlist_setting: waitlistSettingSchema.optional(),
		})
		.optional(),
	working_hours: z.array(operatingHourSchema).optional(),
});

export const QueueSchema = z.object({
	auto_approve: z.number().optional(),
	is_visible: z.number().optional(),
	schedule_setting: scheduleSettingSchema.optional(),
	schedule_optimizer: scheduleOptimizerSchema.optional(),
	custom_time_slots: z.array(CustomTimeSlotSchema).optional(),
	waitlist_setting: waitlistSettingSchema.optional(),
});

export type AddLocationData = z.infer<typeof AddLocationSchema>;
export type QueueSchemaData = z.infer<typeof QueueSchema>;
export type AddLocationDataV2 = z.infer<typeof AddLocationSchemaV2>;
export type EditLocationInfoData = z.infer<typeof EditLocationInfoSchema>;
export type WorkingHoursProps = z.infer<typeof WorkingHoursSchema>;
export type queueSettingsProps = z.infer<typeof queueSettingsSchema>;
export type scheduleOptimizerProps = z.infer<typeof scheduleOptimizerSchema>;
export type AppointmentTypeProps = z.infer<typeof AppointmentTypeSchema>;
export type CustomTimeSlotProps = z.infer<typeof CustomTimeSlotSchema>;
export type addRemoveCUstomSlotProps = z.infer<
	typeof addRemoveCUstomSlotSchema
>;
