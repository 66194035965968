import useLocationSelection from "@/src/hooks/waitlist/useLocationSelection";
import useStationSelection from "@/src/hooks/waitlist/useStationSelection";
import InputIcon from "@src/components/ui-extended/input-icon";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "@src/components/ui/select";
import useWaitlistWebSocket from "@src/hooks/waitlist/useWaitlistWebSocket";
import Header from "@src/layouts/Header/Header";
import HeaderTopItem from "@src/layouts/Header/HeaderTopItem";
import { GetQueryListSlice } from "@src/store/slices/waitlist/getWaitlistSlice";
import { cn } from "@utils/functions";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { LuClock3 } from "react-icons/lu";
import GridView from "../../components/Dashboard/waitlist/GridView";
import { CustomSelect } from "../../components/form/CustomSelect";
import { Tabs, TabsList, TabsTrigger } from "../../components/ui/tabs";
import useUserStore from "../../store/useUserStore";
import { PatientStatus } from "../../types/wait-list";
import WaitlistShowScheduleAddPatientRow from "./components/WaitlistShowScheduleAddPatientRow";
import WaitlistColumnContainer from "./components/WaitlistViews/WaitlistColumnContainer";
import useWaitListStore from "@src/store/useWaitListStore";
import { useQueryClient } from "react-query";
import { QueueData } from "@src/types/waitlist/waitlist";
import useJoinedFromSelection from "@/src/hooks/waitlist/useJoinedFromSelection";
import RequestIsLoading from "@/src/components/RequestIsLoading";

const Waitlist: React.FC = () => {
	const [tabView, setTabView] = useState<"grid_view" | "column_view">(
		"column_view"
	);
	const getQueueListQuery = GetQueryListSlice();

	const [isScheduleOn, setIsScheduleOn] = useState(false);
	const currentLocationId = useUserStore((s) => s.currentLocationId);
	const { patientsObject, setPatientsObject } = useWaitListStore((s) => ({
		setPatientsObject: s.setPatientsObject,
		patientsObject: s.patientsObject,
		patients: s.patients,
	}));

	const { handleUpdateWaitlist } = useWaitlistWebSocket();
	const queryClient = useQueryClient();

	const statuses: PatientStatus[] = [
		PatientStatus.Schedule,
		PatientStatus.Pending,
		PatientStatus.Upcoming,
		PatientStatus.NextInLine,
		PatientStatus.Serving,
	];

	if (
		getQueueListQuery.data &&
		getQueueListQuery.data?.data.queue_settings.auto_flow
	)
		statuses.splice(1, 1);
	if (!isScheduleOn) statuses.splice(0, 1);

	const estimated_wait_time = {
		hours: Math.floor(
			(getQueueListQuery.data?.data.queue_settings.average_wait_time ??
				0) / 60
		),
		minutes: getQueueListQuery.data?.data.queue_settings.average_wait_time
			? getQueueListQuery.data?.data.queue_settings.average_wait_time % 60
			: 0,
	};

	useWaitlistWebSocket();
	return (
		<main className="flex flex-1 flex-col">
			<Header
				title={"Flow Management"}
				content={
					<ul className="flex items-center space-x-6">
						<HeaderTopItem
							icon={"mgc_stopwatch_line"}
							title={
								(estimated_wait_time.hours > 0
									? `${estimated_wait_time.hours}h`
									: "") +
								" " +
								`${estimated_wait_time.minutes}min`
							}
							desc={"Estimated Wait Time"}
						/>
						<HeaderTopItem
							icon={"mgc_group_line"}
							title={
								getQueueListQuery.data?.data.queue_order
									.length ?? "0"
							}
							desc={"Patients Waiting"}
						/>
					</ul>
				}
			/>
			<div className="flex flex-1 flex-col overflow-auto">
				<section className="mt-2 flex items-center justify-between py-2">
					<div className="flex items-center space-x-4">
						<WaitlistLocationsSelectButton className="h-9" />
						<WaitlistLocationsStationsSelectButton className="h-9" />
						<WaitlistJoinedFromSelectButton className="h-9 w-[180px]" />
					</div>
					<div className="flex items-center space-x-4">
						<div className="w-[311px]">
							<InputIcon
								placeholder="Quick search by name."
								className="placeholder:text-[#858C95]"
								icon="mgc_search_line"
								onChange={(e) => {
									if (!e.target.value.length)
										return handleUpdateWaitlist(
											(
												queryClient.getQueryData([
													"queue-list",
													currentLocationId,
												]) as { data: QueueData }
											).data?.queue_order
										);
									setPatientsObject({
										[PatientStatus.Pending]: patientsObject[
											PatientStatus.Pending
										].filter((item) =>
											item.customer.full_name
												.toLowerCase()
												.includes(
													e.target.value.toLowerCase()
												)
										),
										[PatientStatus.Upcoming]:
											patientsObject[
												PatientStatus.Upcoming
											].filter((item) =>
												item.customer.full_name
													.toLowerCase()
													.includes(
														e.target.value.toLowerCase()
													)
											),
										[PatientStatus.NextInLine]:
											patientsObject[
												PatientStatus.NextInLine
											].filter((item) =>
												item.customer.full_name
													.toLowerCase()
													.includes(
														e.target.value.toLowerCase()
													)
											),
										[PatientStatus.Serving]: patientsObject[
											PatientStatus.Serving
										].filter((item) =>
											item.customer.full_name
												.toLowerCase()
												.includes(
													e.target.value.toLowerCase()
												)
										),
										[PatientStatus.Schedule]: [],
									});
								}}
							/>
						</div>
						<div
							className={`transition-opacity ${isScheduleOn ? "pointer-events-none opacity-50" : "pointer-events-auto opacity-100"} duration-200 ease-in-out`}
						>
							<Tabs
								value={tabView}
								className="rounded-lg bg-green-900"
							>
								<TabsList>
									<TabsTrigger
										onClick={() => setTabView("grid_view")}
										value="grid_view"
										className="flex items-center space-x-1.5"
									>
										<i
											className={`mgc_IDcard_line ${tabView === "grid_view" ? "before-text-dark" : "before-text-gray-3"} text-[20px] transition-colors duration-200 ease-in-out`}
										/>
										<p>Grid view</p>
									</TabsTrigger>
									<TabsTrigger
										onClick={() => {
											setTabView("column_view");
											console.log("value");
										}}
										value="column_view"
										className="flex items-center space-x-1.5"
									>
										<i
											className={`mgc_list_check_3_line ${tabView === "column_view" ? "before-text-dark" : "before-text-gray-3"} text-[20px] transition-colors duration-200 ease-in-out`}
										/>
										<p>Column view</p>
									</TabsTrigger>
								</TabsList>
							</Tabs>
						</div>
					</div>
				</section>
				<WaitlistShowScheduleAddPatientRow
					isScheduleOn={isScheduleOn}
					tabView={tabView}
					setIsScheduleOn={setIsScheduleOn}
					setTabView={setTabView}
				/>
				<div className="relative flex flex-1">
					{tabView === "column_view" ? (
						<WaitlistColumnContainer statuses={statuses} />
					) : (
						<div className="mt-4 grid grid-cols-2 gap-4">
							{statuses.map((status, index) => (
								<GridView key={index} status={status} />
							))}
						</div>
					)}
					<RequestIsLoading
						isLoading={
							// queryClient.getQueryState(["queue-list", currentLocationId])
							// 	?.status === "idle" ||
							// queryClient.getQueryState(["queue-list", currentLocationId])
							// 	?.status === "loading"
							getQueueListQuery.isFetching
						}
						size={24}
					/>
				</div>
				{/* <NewWaitlist /> */}
				{/* <PatientSwapRequest
				patientSwapDetails={{
					id: 1,
					order: "1",
					swap_id: "21212",
					desired_position: "3",
					reason: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu",
				}}
			/> */}
			</div>
		</main>
	);
};

const HoursTime: React.FC = () => {
	const [currentTime, setCurrentTime] = useState(dayjs().format("HH:mm:ss"));
	useEffect(() => {
		const intervalId = setInterval(() => {
			setCurrentTime(dayjs().format("HH:mm:ss"));
		}, 1000);

		return () => clearInterval(intervalId);
	}, []);

	return (
		<div className="flex items-center gap-x-1">
			<LuClock3 size={18} />
			<span className="min-w-[60px]">{currentTime}</span>
		</div>
	);
};

export const WaitlistLocationsSelectButton: React.FC<{
	className?: string;
}> = ({ className }) => {
	const locationsList = useUserStore((s) => s.locationsList);
	const currentLocationId = useUserStore((s) => s.currentLocationId);
	const { handleLocationChange } = useLocationSelection();

	return (
		<Select
			value={currentLocationId.toString()}
			onValueChange={(value) => {
				handleLocationChange(value);
			}}
		>
			<SelectTrigger className={cn("w-[180px]", className)}>
				<SelectValue
					placeholder="Locations"
					className="text-[#858C95]"
				/>
			</SelectTrigger>
			<SelectContent className="">
				{locationsList.map((option, mainIndex) => {
					return (
						<SelectGroup key={mainIndex}>
							<SelectItem value={option.value}>
								{option.label}
							</SelectItem>
						</SelectGroup>
					);
				})}
			</SelectContent>
		</Select>
	);
};

export const WaitlistLocationsStationsSelectButton: React.FC<{
	className?: string;
}> = ({ className }) => {
	const locations = useUserStore((s) => s.user?.business.locations);
	const currentLocationId = useUserStore((s) => s.currentLocationId);
	const currentStationId = useUserStore((s) => s.currentStationId);
	const { handleStationChange } = useStationSelection();

	const currentLocation = locations?.find(
		(location) => location.id === currentLocationId
	);

	const stations = currentLocation
		? [
				{ label: "All Stations", value: "All Stations" },
				...(currentLocation?.stations.map((station) => ({
					label: station.name,
					value: station.id.toString(),
				})) || []),
			]
		: [];

	return (
		<Select
			defaultValue={"All Stations"}
			value={currentStationId.toString()}
			onValueChange={handleStationChange}
		>
			<SelectTrigger className={cn("w-[180px]", className)}>
				<SelectValue
					placeholder="Stations"
					className="text-[#858C95]"
				/>
			</SelectTrigger>
			<SelectContent className="">
				{stations.map((option, mainIndex) => {
					return (
						<SelectGroup key={mainIndex}>
							<SelectItem value={option.value}>
								{option.label}
							</SelectItem>
						</SelectGroup>
					);
				})}
			</SelectContent>
		</Select>
	);
};

export default Waitlist;

export const WaitlistJoinedFromSelectButton: React.FC<{
	className?: string;
}> = ({ className }) => {
	const { handleJoinedFromChange } = useJoinedFromSelection();
	const currentJoinedFrom = useWaitListStore((s) => s.currentJoinedFrom);

	return (
		<Select
			value={currentJoinedFrom}
			onValueChange={handleJoinedFromChange}
		>
			<SelectTrigger className={cn("w-[180px]", className)}>
				<SelectValue
					placeholder="Joined From"
					className="text-[#858C95]"
				/>
			</SelectTrigger>
			<SelectContent className="">
				<SelectGroup>
					<SelectLabel>Joined From</SelectLabel>
					{[
						{ label: "All", value: "all" },
						{ label: "URL", value: "url" },
						{ label: "QR Code", value: "qr" },
					].map((option, mainIndex) => {
						return (
							<SelectItem key={mainIndex} value={option.value}>
								{option.label}
							</SelectItem>
						);
					})}
				</SelectGroup>
			</SelectContent>
		</Select>
	);
};
