import { Route, Routes } from "react-router";
import TeamMembers from "./index";
import AddTeamMember from "./AddTeamMember";
import EditTeamMember from "./EditTeamMember";

const TeamMemberRoutes: React.FC = () => {
	return (
		<Routes>
			<Route index element={<TeamMembers />} />
			<Route path="/add" element={<AddTeamMember />} />
			<Route path="/edit/:id" element={<EditTeamMember />} />
		</Routes>
	);
};

export default TeamMemberRoutes;
