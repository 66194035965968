import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { LuX } from "react-icons/lu";
import Overlay from "../Overlay";
import { Button } from "../ui/button";
import AddLocationTimer from "./AddLocationTimer";
import useUserStore from "../../store/useUserStore";
import { OperatingHour } from "@type/DaySlots";

const DefaultOperatingHoursModal: React.FC<{
	setDefaultSlots: Dispatch<SetStateAction<OperatingHour[]>>;
}> = ({ setDefaultSlots }) => {
	const onboardingLocationInfo = useUserStore(
		(s) => s.onboardingLocationInfo
	);
	const [show, setShow] = useState(onboardingLocationInfo ? false : true);
	const modalRef = useRef(null);

	const [slots, setSlots] = useState<OperatingHour[]>([
		{
			day: "Monday",
			day_value: 1,
			is_active: 1,
			time_slots: [{ start_time: "09:00:00", end_time: "17:00:00" }],
		},
	]);

	const updateSlots = () => {
		setDefaultSlots((prevSlots) => {
			const updatedSlots = prevSlots.map((item) => ({
				...item,
				time_slots: slots[0].time_slots,
			}));
			return updatedSlots;
		});
		setTimeout(() => {
			setShow(false);
		}, 300);
	};

	return (
		<Overlay show={show} setShow={setShow} modalRef={modalRef} isPersistent>
			<div
				className="relative z-50 w-full max-w-[447px] rounded-[10px] border border-[#E5E5E7] bg-white p-5 text-main-1"
				ref={modalRef}
			>
				<div className="flex flex-col justify-between space-y-8">
					<div className="flex items-center justify-between">
						<h1 className="text-left  text-[22px] font-medium leading-[30px] -tracking-[1%] text-main-1">
							Operating Hours
						</h1>
						<LuX
							color="#858C95"
							size={20}
							className="cursor-pointer self-center"
							onClick={() => {
								setShow(false);
							}}
						/>
					</div>
					<p className="leading-[20px] -tracking-[0.1px] text-[#323539]">
						What are your regular operating hours?
					</p>
					<AddLocationTimer
						{...slots[0]}
						index={0}
						slots={slots}
						setSlots={setSlots as any}
					/>
					<div className="mt-6 flex space-x-1 self-end">
						<Button
							variant="ghost"
							className="h-[46px] w-[130px]"
							onClick={() => setShow(false)}
						>
							Skip for now
						</Button>
						<Button
							className="h-[46px] w-[130px]"
							onClick={(e) => {
								e.preventDefault();
								updateSlots();
							}}
						>
							Next
						</Button>
					</div>
				</div>
			</div>
		</Overlay>
	);
};

export default DefaultOperatingHoursModal;
