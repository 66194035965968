/* eslint-disable react-hooks/exhaustive-deps */
import { CalendarIcon, ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { Button } from "@components/ui/button";
import moment from "moment";
import { Views, momentLocalizer } from "react-big-calendar";
import {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { cn } from "@utils/functions";
import { useElementWidth } from "@hooks/useElementWidth";
import { IoClose } from "react-icons/io5";
// import useScreenDimension from "@hooks/useScreenDimension";
import DateSelector from "@components/DateSelector";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@components/ui/popover";
import useCustomToast from "@src/components/CustomToast";
import useAppointmentStore from "@src/store/useAppointmentStore";

interface CustomCalendarHeaderProps {
	events: any;
	selectedStations: { value: string; label: string }[];
	setSelectedStations: Dispatch<
		SetStateAction<{ value: string; label: string }[]>
	>;
	handleRemoveSchedule: (selectedStations, station) => void;
	setDate: (date: Date) => void;
	date: Date | undefined;
}
const CustomCalendarHeader = ({
	selectedStations,
	handleRemoveSchedule,
	setDate,
	date,
}: CustomCalendarHeaderProps) => {
	const toast = useCustomToast();
	const { view } = useAppointmentStore();
	const [selected, setSelected] = useState("");
	const isMultiView = selectedStations.length > 0;
	const localizer = momentLocalizer(moment);
	const { elementRef, elementWidth } = useElementWidth();
	const appointments = useAppointmentStore((s) => s.appointments);
	const setFilteredAppointments = useAppointmentStore(
		(s) => s.setFilteredAppointments
	);

	const updateFilterEvents = (station_id: string) => {
		setTimeout(() => {
			const filteredEvents = appointments.filter((appointment) => {
				return (
					appointment.station && appointment.station.id == station_id
				);
			});
			if (filteredEvents.length < 1) {
				return toast("No appointment found", {
					id: "no-events",
					type: "success",
				});
			}
			setSelected(station_id);
			setFilteredAppointments(filteredEvents);
		}, 1500);
	};

	const handleStationSelection = (station: {
		value: string;
		label: string;
	}) => {
		updateFilterEvents(station.value);
	};

	const handleStationSelectionRemoval = (
		e,
		selectedStations,
		station: { value: string; label: string }
	) => {
		e.stopPropagation();
		handleRemoveSchedule(selectedStations, station);
	};

	const handleDateChange = useCallback(
		(type: "prev" | "next") => {
			let newDate;
			const adjustment = type === "prev" ? -1 : 1;
			if (view === Views.DAY) {
				newDate = moment(date).add(adjustment, "d").toDate();
			} else if (view === Views.WEEK) {
				newDate = localizer.add(date, adjustment * 5, "day");
			} else {
				newDate = moment(date).add(adjustment, "M").toDate();
			}
			setDate(newDate);
		},
		[view, date, localizer, setDate]
	);

	const onPrevClick = useCallback(
		() => handleDateChange("prev"),
		[handleDateChange]
	);
	const onNextClick = useCallback(
		() => handleDateChange("next"),
		[handleDateChange]
	);

	const dateText = useMemo(() => {
		if (view === Views.DAY)
			return moment(date).format("dddd, MMMM DD, YYYY");
		if (view === Views.WEEK) {
			const from = moment(date)?.startOf("week");
			const to = moment(date)?.endOf("week");
			return `${from.format("MMMM DD")} to ${to.format("MMMM DD")}`;
		}
		if (view === Views.MONTH) {
			return moment(date).format("MMMM YYYY");
		}
	}, [view, date]);

	useEffect(() => {
		if (view == "day" && selected !== "") {
			setSelected("");
			setFilteredAppointments(appointments);
		}
	}, [view]);

	if (view === Views.WEEK) {
		return (
			<>
				<div
					className={cn(
						" rounded-tl-md rounded-tr-md border border-[#E5E7EB]",
						{ hidden: !isMultiView }
					)}
				>
					<div
						className={` flex w-full  gap-4 overflow-x-auto border-b border-b-[#E5E5E7] px-4 `}
						style={{
							maxWidth: elementWidth > 0 ? elementWidth : "100%",
						}}
					>
						{selectedStations.length > 0 &&
							selectedStations?.map((station, index) => (
								<Button
									key={index}
									variant="ghost"
									className={cn(
										" flex w-fit items-center !justify-between gap-2 rounded-none px-4 py-2 text-[14px] font-[600] ",
										{
											"border-b-2 border-b-[#005893] text-[#09244B]":
												selected === station.value,
										}
									)}
									onClick={() =>
										handleStationSelection(station)
									}
								>
									<p>{station?.label}</p>
									<span
										onClick={(e) =>
											handleStationSelectionRemoval(
												e,
												selectedStations,
												station
											)
										}
									>
										<IoClose className="text-lg" />
									</span>
								</Button>
							))}
					</div>
				</div>

				<div
					className={cn(
						"absolute top-[4rem] flex w-full items-center justify-between",
						{
							"top-[6.5rem]": isMultiView,
						}
					)}
				>
					<Button
						className="bg-transparent text-[24px] text-[#09244B] hover:bg-transparent"
						onClick={onPrevClick}
					>
						<ChevronLeftIcon />
					</Button>
					<Button
						className="bg-transparent text-[24px] text-[#09244B] hover:bg-transparent"
						onClick={onNextClick}
					>
						<ChevronRightIcon />
					</Button>
				</div>
			</>
		);
	}

	if (view == Views.MONTH) {
		return (
			<div className="relative flex flex-col">
				<div
					className={cn(
						" rounded-tl-md rounded-tr-md border border-[#E5E7EB]",
						{ hidden: !isMultiView }
					)}
				>
					<div
						className={` flex w-full  gap-4 overflow-x-auto border-b border-b-[#E5E5E7] px-4 `}
						style={{
							maxWidth: elementWidth > 0 ? elementWidth : "100%",
						}}
					>
						{selectedStations &&
							selectedStations?.map((station, index) => (
								<Button
									key={`${station.value}-${index}`}
									variant="ghost"
									className={cn(
										" flex w-fit items-center !justify-between gap-2 rounded-none px-4 py-2 text-[14px] font-[600] ",
										{
											"border-b-2 border-b-[#005893] text-[#09244B]":
												selected === station.value,
										}
									)}
									onClick={() =>
										handleStationSelection(station)
									}
								>
									<p>{station?.label}</p>
									<span
										onClick={(e) =>
											handleStationSelectionRemoval(
												e,
												selectedStations,
												station
											)
										}
									>
										<IoClose className="text-lg" />
									</span>
								</Button>
							))}
					</div>
				</div>

				<div
					className={cn(
						" flex w-full items-center justify-between rounded-tl-md rounded-tr-md border-l border-r border-t",
						{
							" border-t-0": isMultiView,
						}
					)}
				>
					<Button
						className="bg-transparent text-[24px] text-[#09244B] hover:bg-transparent"
						onClick={onPrevClick}
					>
						<ChevronLeftIcon />
					</Button>
					<p className="flex items-center gap-3 font-inter font-semibold text-[#111827]">
						<span>{dateText}</span>
					</p>
					<Button
						className="bg-transparent text-[24px] text-[#09244B] hover:bg-transparent"
						onClick={onNextClick}
					>
						<ChevronRightIcon />
					</Button>
				</div>
			</div>
		);
	}

	// Day view container
	return (
		<div
			className={cn(
				"flex h-[56px] w-full items-center rounded-tl-md rounded-tr-md border border-[#E5E7EB] px-4 pt-3 "
			)}
		>
			<div
				ref={elementRef}
				className="flex w-full items-center justify-between"
			>
				<Button
					className="bg-transparent px-0 text-[#09244B] hover:bg-transparent"
					onClick={onPrevClick}
				>
					<ChevronLeftIcon />
				</Button>
				<p className="flex items-center gap-3 font-inter font-semibold text-[#111827]">
					<span>{dateText}</span>
					<span>
						<Popover>
							<PopoverTrigger>
								<button className="rounded-sm bg-[#ECECEC] p-2">
									<CalendarIcon
										className="text-primary"
										size={14}
									/>
								</button>
							</PopoverTrigger>
							<PopoverContent className="w-full">
								<DateSelector
									date={date}
									setDate={(date: Date) => setDate(date)}
								/>
							</PopoverContent>
						</Popover>
					</span>
				</p>
				<Button
					className="bg-transparent px-0 text-[#09244B]  hover:bg-transparent"
					onClick={onNextClick}
				>
					<ChevronRightIcon />
				</Button>
			</div>
		</div>
	);
};

export default CustomCalendarHeader;
