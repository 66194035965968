import useAppointmentStore from "@src/store/useAppointmentStore";
import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "../../../components/ui/tabs";
import AppointmentColumn from "./AppointmentColumn";
import AutoApprove from "./AutoApprove";

const TabList = () => {
	const appointments = useAppointmentStore((s) => s.appointments);
	const pendingAppointments = appointments?.filter(
		(appointment) => appointment.status == "waiting_to_be_approved"
	);
	const approvedAppointments = appointments?.filter(
		(appointment) => appointment.status == "approved"
	);
	const followupAppointments = appointments?.filter(
		(appointment) => appointment.status == "followup"
	);
	return (
		<Tabs defaultValue="pending" className="w-full space-y-4">
			<TabsList className="relative">
				<TabsTrigger
					value="confirmed"
					className="rounded-md text-[14px] data-[state=active]:border-primary data-[state=active]:text-primary"
				>
					<p className="flex items-center gap-1 text-[14px]">
						Confirmed{" "}
					</p>
				</TabsTrigger>
				<TabsTrigger
					value="pending"
					className="flex gap-2 rounded-md data-[state=active]:border-primary data-[state=active]:text-primary"
				>
					<p className="flex items-center gap-1 text-[14px]">
						Pending{" "}
						<span className="flex h-[12px] w-[16px] items-center justify-center rounded-full bg-[#E33B32] p-2 text-xs text-white">
							{pendingAppointments?.length}
						</span>
					</p>
				</TabsTrigger>
				<TabsTrigger
					value="followup"
					className="rounded-md text-[14px] data-[state=active]:border-primary data-[state=active]:text-primary"
				>
					<p className="flex items-center gap-1 text-[14px]">
						Follow Up{" "}
						{!!followupAppointments?.length && (
							<span className="flex h-[12px] w-[16px] items-center justify-center rounded-full bg-[#E33B32] p-2 text-xs text-white">
								{followupAppointments?.length}
							</span>
						)}
					</p>
				</TabsTrigger>
			</TabsList>
			<TabsContent value="confirmed">
				<AppointmentColumn
					data={approvedAppointments}
					status="confirmed"
				/>
			</TabsContent>
			<TabsContent value="pending">
				<AppointmentColumn
					status="pending"
					data={pendingAppointments}
				/>
			</TabsContent>

			<TabsContent value="followup">
				<AppointmentColumn
					data={followupAppointments}
					status="followup"
				/>
			</TabsContent>
		</Tabs>
	);
};

export default TabList;
