import { GetBusinessLocationsQueueResponse } from "@/src/types/Location";
import {
	ServingQueueResponse,
	WeeklyWaitlistHistoryResponse,
} from "@/src/types/serving/serving";
import { JoinWaitlistType } from "@/src/types/waitlist/add-customer-waitlist";
import {
	BulkNotifyWaitlistCustomersType,
	BulkUpdateWaitlistType,
	PatientHistoryResponse,
	SinglePatientQueueResponse,
	SinglePatientQueueWaitlistResponse,
	UpdatedPatientData,
	UpdateWaitlistType,
} from "@src/types/waitlist/waitlist";
import $http from "../xhr";

export const APIVersion2UpdateWaitlist = async (
	data: UpdateWaitlistType
): Promise<SinglePatientQueueWaitlistResponse> => {
	return $http
		.post("v2/my-business/queue-order/update", data)
		.then((res) => res.data);
};

export const APIVersion2BulkUpdateWaitlist = async (
	data: BulkUpdateWaitlistType
): Promise<SinglePatientQueueWaitlistResponse> => {
	return $http
		.post("v2/my-business/queue-order/bulk-update", data)
		.then((res) => res.data);
};

export const APIVersion2BulkNotifyWaitlist = async (
	data: BulkNotifyWaitlistCustomersType
): Promise<SinglePatientQueueWaitlistResponse> => {
	return $http
		.post("v2/my-business/customers/notify-custom-message", data)
		.then((res) => res.data);
};

export const APIVersion2GetJoinInfo = async (data: {
	join_code?: string;
}): Promise<any> =>
	$http
		.get(`v2/customer/waitlist/join/${data.join_code}/info`)
		.then((res) => res.data);

export const APIVersion2ClearQueue = async (data: {
	location_id?: number | string;
}): Promise<any> =>
	$http
		.post(`v2/my-business/queue-order/clear-queue/${data.location_id}`)
		.then((res) => res.data);

export const APIVersion2AddPatientToWaitlist = async (
	data: JoinWaitlistType & { station_id?: string | number }
): Promise<any> =>
	$http
		.post(`v2/my-business/queue-order/add/${data.station_id}`, {
			...data,
			station_id: undefined,
		})
		.then((res) => res.data);

export const APIVersion2UpdatePatientOnWaitlist = async (
	data: JoinWaitlistType & { customer_id?: number }
): Promise<UpdatedPatientData> =>
	$http
		.post(
			`v2/my-business/queue-order/update-customer-info/${data.customer_id}`,
			{
				...data,
				customer_id: undefined,
			}
		)
		.then((res) => res.data);

export const APIVersion2GetBusinessLocations =
	async (): Promise<GetBusinessLocationsQueueResponse> =>
		$http
			.get("v2/my-business/queue-order/business-info")
			.then((res) => res.data);

export const APIVersion2GetCustomerInfo = async (data: {
	customer_id?: number;
}): Promise<SinglePatientQueueResponse> =>
	$http
		.get(`v2/my-business/customers/${data.customer_id}/info`)
		.then((res) => res.data);

export const APIVersion2GetPatientActivity = async (data: {
	customer_id?: number;
}): Promise<PatientHistoryResponse> =>
	$http
		.get(`v2/my-business/customers/${data.customer_id}/last-activities`)
		.then((res) => res.data);

//Serving
export const APIVersion2GetServingDetails = async (data: {
	location_id?: number;
}): Promise<ServingQueueResponse> =>
	$http
		.get(`v2/my-business/customers/serving/${data.location_id}`)
		.then((res) => res.data);

export const APIVersion2UpdateCustomer = async (data: {
	customer_id?: number;
}): Promise<SinglePatientQueueResponse> =>
	$http
		.post(`v2/my-business/customers/${data.customer_id}/update`, {
			...data,
			customer_id: undefined,
		})
		.then((res) => res.data);

export const APIVersion2GetWeeklyWaitlistHistory = async (params: {
	location_id?: number;
}): Promise<WeeklyWaitlistHistoryResponse> =>
	$http
		.get(`v2/my-business/customers/weekly-history`, { params })
		.then((res) => res.data);

// Team Member
export const APIVersion2DeleteManyTeamMembers = async (data: {
	user_ids: number[];
}): Promise<any> =>
	$http
		.post(`v2/my-business/team-members/delete-many`, data)
		.then((res) => res.data);
