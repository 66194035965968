type ObjectValues<T> = T[keyof T];

export interface StatusCardProps {
	status: PatientStatus;
	statusText: string;
	statusCardBg?: string;
	children?: React.ReactNode;
}

export interface StatusActionButtonProps {
	buttonElement: React.ReactNode;
	onClick?: () => void;
}

export interface IStatusData {
	id: number;
	name: string;
	status: string;
}

export interface IPatientSwapDetailsData {
	id: string | number;
	swap_id: string | number;
	desired_position: string;
	order: string;
	reason: string;
}

export interface IGridViewProps {
	status: PatientStatus;
}

export interface ICustomCheckboxProps {
	handleCheckboxChange: () => void;
	isChecked: boolean;
	id: string;
	backgroundColor?: string;
	borderColor?: string;
	className?: string;
	hasText?: boolean;
	textBefore?: string;
	textAfter?: string;
}

export enum PatientStatus {
	Schedule = "Scheduled",
	Pending = "Pending Approval",
	Upcoming = "Confirmed",
	NextInLine = "Next to Serve",
	Serving = "Serving",
	Done = "Done",
}

// Schedule = "Schedule",
// 	Pending = "Pending",
// 	Upcoming = "Upcoming",
// 	NextInLine = "Next In Line",
// 	Serving = "Serving",

// export enum PatientStatus {
// 	Schedule = "Scheduled",
// 	Pending = "Pending Approval",
// 	Upcoming = "Confirmed",
// 	NextInLine = "Next to Serve",
// 	Serving = "Serving",
// }

export enum StationSequence {
	NoSequence = "No Sequence",
	SelectSqeuence = "Select Sequence",
}

export enum TypeOfEntry {
	QrCode,
	Web,
	Appointment,
}

export const StatusOfPatient = {
	AllStatus: "all_status",
	Pending: "waiting_to_be_approved",
	Upcoming: "accepted",
	NextInLine: "next_to_serve",
	GettingService: "getting_service",
	Done: "done",
	Cancelled: "cancelled",
} as const;

export type StatusOfPatientType = ObjectValues<typeof StatusOfPatient>;
