import Loader from "@/src/components/Loader/Loader";
import { Button } from "@/src/components/ui/button";
import { GetPatientWaitlistHistorysSlice } from "@/src/store/slices/customers/customersSlice";
import { useGetBusinessLocationsAndStations } from "@/src/store/slices/locations/locationSlice";
import { formattedPatientStatus } from "@/src/utils/constants";
import { getDurationFromInHourMinute } from "@/src/utils/date";
import { PatientStatus, StatusOfPatient } from "@src/types/wait-list";
import { WaitlistPatientHistoryRecord } from "@src/types/waitlist/waitlist";
import { cn, toTitleCase } from "@src/utils/functions";
import React from "react";

const ActivityTab: React.FC<{ customer_id: number }> = ({ customer_id }) => {
	const getPatientWaitlistHistaoryQuery =
		GetPatientWaitlistHistorysSlice(customer_id);

	React.useEffect(() => {
		getPatientWaitlistHistaoryQuery.refetch();
	}, []);

	return (
		<div>
			<h2 className="text-[22px] font-semibold leading-[30px] tracking-[-1%] text-[#323539]">
				Activity
			</h2>
			{getPatientWaitlistHistaoryQuery.isLoading ? (
				<div className="py-10">
					<Loader size={24} />
				</div>
			) : (
				<ul className="mt-4 flex flex-col space-y-1">
					{getPatientWaitlistHistaoryQuery.data &&
						getPatientWaitlistHistaoryQuery.data.data
							.slice(0, 4)
							.map((item, idx) => (
								<ActivityTabItem
									key={idx}
									// patientDetails={patientDetails}
									{...item.record}
								/>
							))}
				</ul>
			)}
		</div>
	);
};

export default ActivityTab;

const ActivityTabItem: React.FC<WaitlistPatientHistoryRecord> = (data) => {
	const getBusinessLocationsAndStationsQuery =
		useGetBusinessLocationsAndStations();

	const station =
		getBusinessLocationsAndStationsQuery.data &&
		getBusinessLocationsAndStationsQuery.data?.data.locations
			.find((location) => location.id == data.location_id)
			?.stations.find((station) => station.id == data.station_id);

	return (
		<li className="flex items-center justify-between rounded-lg border border-[#E5E5E7] p-2">
			<div className="flex items-center space-x-3 pl-4 text-[12px] leading-[12px] text-[#858C95]">
				<p className="text-[#323539]">{data.customer_id}</p>
				<figure className="flex items-center space-x-3 self-stretch">
					<div className="w-[1px] self-stretch bg-[#E5E5E7]" />
					{/* <i className="mgc_qrcode_line" /> */}
					{data.joined_through === "url" && (
						<i className="mgc_earth_line text-[12px] before:!text-main-1" />
					)}
					{data.joined_through === "qr" && (
						<i className="mgc_qrcode_line text-[12px] before:!text-main-1" />
					)}
					{data.joined_through === "admin" && (
						<i className="mgc_user_security_line text-[12px] before:!text-main-1" />
					)}
					<div className="w-[1px] self-stretch bg-[#E5E5E7]" />
				</figure>
				<div className="flex items-center space-x-2.5">
					<p>{station?.name}</p>
					{/* <PatientPriorityModal
						patientDetails={data}
						showPriorityModal={false}
						setShowPriorityModal={function () // value: React.SetStateAction<boolean>
						: void {
							throw new Error("Function not implemented.");
						}} // status={data.status}
					/> */}
					<Button className="flex h-[18px] w-[22px] cursor-default items-center justify-center rounded-[4px] bg-[#EAEBF0] p-0 hover:bg-[#EAEBF0]">
						<i
							className={cn(
								"mgc_tag_fill text-[14px] before:!text-[#969696]",
								{
									"before-text-red":
										data.status === PatientStatus.Schedule,
									"before:!text-[#C8322B]":
										data.priority === "high",
								}
							)}
						/>
					</Button>
					{data.check_in_status === "to_be_approved" && (
						<i className="size-2.5 rounded-full bg-[#2EBF43]" />
					)}
					{data.check_in_status === "pending" && (
						<i className="size-2.5 rounded-full bg-destructive" />
					)}
				</div>
			</div>
			<div className="flex items-center space-x-8">
				<p className="text-xs text-main-4">
					{data.status === StatusOfPatient.GettingService &&
						getDurationFromInHourMinute(
							data.service_start_at ?? ""
						)}
					{data.status !== StatusOfPatient.GettingService &&
						getDurationFromInHourMinute(data.created_at ?? "")}
					{/* Confirm if created at is for when the person joins the waitlist */}
				</p>
				<div
					className="grid w-[70px] place-content-center rounded-md text-xxs font-medium leading-[22px] tracking-[-1%] text-white"
					style={{
						backgroundColor:
							data.status === StatusOfPatient.GettingService
								? "#138576"
								: data.status === StatusOfPatient.Upcoming
									? "#005893"
									: data.status === StatusOfPatient.NextInLine
										? "#285A8A"
										: data.status ===
											  StatusOfPatient.Pending
											? "#C8322B"
											: "#596574",
					}}
				>
					{formattedPatientStatus[data.status] &&
						toTitleCase(
							formattedPatientStatus[data.status].replace(
								/_/g,
								" "
							)
						)}
				</div>
			</div>
		</li>
	);
};
