import { cn } from "@src/utils/functions";
import React from "react";

export interface ICustomCheckboxProps {
	handleCheckboxChange: () => void;
	isChecked: boolean;
	id: string;
	backgroundColor?: string;
	borderColor?: string;
	containerClassName?: string;
	className?: string;
	hasText?: boolean;
	textBefore?: string;
	textAfter?: string;
	disabled?: boolean;
	props?: any;
}

const Checkbox: React.FC<ICustomCheckboxProps> = ({
	handleCheckboxChange,
	isChecked,
	id,
	className,
	containerClassName,
	hasText,
	textBefore,
	textAfter,
	disabled = false,
	...props
}) => {
	return (
		<button
			className={cn(
				"inline-flex items-center",
				containerClassName,
				disabled && "cursor-not-allowed opacity-50"
			)}
			onClick={disabled ? undefined : handleCheckboxChange}
			disabled={disabled}
		>
			{textBefore && (
				<p className={disabled ? "text-gray-400" : ""}>{textBefore}</p>
			)}
			<label
				className={cn(
					"relative flex items-center rounded-full",
					disabled ? "cursor-not-allowed" : "cursor-pointer"
				)}
				htmlFor="check"
			>
				<input
					id={id}
					type="checkbox"
					className={cn(
						"peer relative h-[17px] w-[17px] appearance-none rounded-md border border-[#E5E5E7] bg-white transition-all",
						"checked:border-primary checked:bg-primary",
						!disabled && "hover:before:opacity-10",
						disabled ? "cursor-not-allowed" : "cursor-pointer",
						className
					)}
					checked={isChecked}
					readOnly
					disabled={disabled}
					{...props}
				/>
				<span className="pointer-events-none absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="h-[11px] w-[11px]"
						viewBox="0 0 20 20"
						fill="currentColor"
						stroke="currentColor"
						strokeWidth="1"
					>
						<path
							fillRule="evenodd"
							d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
							clipRule="evenodd"
						></path>
					</svg>
				</span>
			</label>
			{hasText && (
				<span
					className={cn(
						"ml-1",
						disabled ? "text-gray-400" : "text-gray-600"
					)}
				>
					{isChecked ? "Yes" : "No"}
				</span>
			)}
			{textAfter && (
				<p className={cn("ml-1", disabled ? "text-gray-400" : "")}>
					{textAfter}
				</p>
			)}
		</button>
	);
};

export default Checkbox;
