import { CONFIGS } from "@src/configs";
import { AnalyticsQuery } from "@src/store/useAnalyticsStore";
import { Analytics } from "@src/types/Analytics";
import {
	AddBusinessLocationResponseType,
	AddLocationData,
	AppointmentTypeProps,
	GetLocationsResponse,
	LocationResponse,
	StationsArrayProps,
	addRemoveCUstomSlotProps,
	queueSettingsProps,
} from "@src/types/Location";
import { User } from "@src/types/NewUser";
import {
	ChangePasswordType,
	UserAccountSettingType,
} from "@src/types/settings/account";
import { UpdateBusinessSettingsType } from "@src/types/settings/business-account";
import {
	FirstTimeOscarAddLinksIDType,
	FirstTimeOscarType,
	OscarDetailsType,
} from "@src/types/settings/integrations/oscar";
import { AddStationType } from "@src/types/Station";
import {
	QueueData,
	WaitlistGeneralSettings,
} from "@src/types/waitlist/waitlist";
import { serializeParamsToQueryString } from "@src/utils/functions";
import { AxiosResponse } from "axios";
import $http from "../xhr";
import { AddRemoveBulkCustomFieldProps } from "@src/types/CustomFields";
import { GetTeamMembersResponse } from "@/src/types/TeamMember";

//Auth Start

export const APIVersion1Register = async (data: unknown) =>
	$http.post(`/v1/auth/register`, data).then((res) => res.data);

export const APIVersion1Login = async (data: unknown): Promise<unknown> =>
	$http.post("/sign-in-with-google", data).then((res) => res.data);

export const APIVersion1GoogleLogin = async (data: {
	token: string;
}): Promise<{
	status: boolean;
	message: string;
	user: User;
	token: string;
}> => $http.post("/sign-in-with-google", data).then((res) => res.data);

//Auth End

// Analytics Queries Start

export const APIVersion1Analytics = async (
	business_id?: number,
	params?: AnalyticsQuery
): Promise<{
	status: boolean;
	data: Analytics;
}> =>
	$http
		.get(
			`/business-performance-monitor/summary/of-business/` + business_id,
			{
				params,
			}
		)
		.then((res) => res.data);

// Analytics Queries End

// Locations Queries Start
export const APIVersion1GetLocations =
	async (): Promise<GetLocationsResponse> =>
		$http.get("/v2/my-business/locations").then((res) => res.data);

export const APIVersion1GetSingleBusinessLocationDetail = async (
	id: number
): Promise<{
	status: boolean;
	data: LocationResponse;
}> => $http.get(`/v2/my-business/locations/${id}`).then((res) => res.data);

export const APIVersion1GetBusinessLocationStations = async (
	id: number
): Promise<{
	status: boolean;
	data: StationsArrayProps;
}> =>
	$http
		.get(`/v2/my-business/locations/${id}/stations`)
		.then((res) => res.data);

export const APIVersion1DeleteLocation = async (
	id: number | string
): Promise<{
	status: boolean;
	data: any;
}> =>
	$http.delete(`/my-business/locations/${id}/delete`).then((res) => res.data);

export const APIVersion1AddBusinessLocation = async (
	data: unknown
): Promise<AddBusinessLocationResponseType> =>
	$http.post("/v2/my-business/locations/add", data).then((res) => res.data);

// export const APIVersion1AddBusinessWorkingHours = async (
// 	data: unknown
// ): Promise<AddBusinessLocationResponseType> =>
// 	$http.post("/v2/my-business/working-hours", data).then((res) => res.data);

export const APIVersion1AddBusinessWorkingHours = async ({
	data,
	queryParams,
}: {
	data: queueSettingsProps;
	queryParams?: {
		station_id?: number | string;
		location_id?: number | string;
	};
}): Promise<AddBusinessLocationResponseType> => {
	return $http
		.post("/v2/my-business/working-hours", data, {
			params: queryParams,
			paramsSerializer: (params) => serializeParamsToQueryString(params),
		})
		.then((res) => res.data);
};

export const APIVersion1UpdateBusinessLocation = async (
	id: number,
	data: FormData
): Promise<AddBusinessLocationResponseType> =>
	$http
		.post(`/v2/my-business/locations/${id}/update`, data)
		.then((res) => res.data);

export const APIVersion1UpdateBusinessLocationStation = async (
	locationId: number,
	stationId: number,
	data: unknown
): Promise<AddBusinessLocationResponseType> =>
	$http
		.post(`/my-location/${locationId}/stations/${stationId}/update`, data)
		.then((res) => res.data);

export const APIVersion1AddBusinessLocationStation = async (
	locationId: number,
	data: AddStationType
): Promise<AddBusinessLocationResponseType> =>
	$http
		.post(`/my-location/${locationId}/stations/add`, data)
		.then((res) => res.data);

export const APIVersion1DeleteStation = async (
	locationId: number,
	stationId: number
): Promise<{
	status: boolean;
	data: any;
}> =>
	$http
		.delete(`/my-location/${locationId}/stations/${stationId}/delete`)
		.then((res) => res.data);

export const APIVersion1GetSingleBusinessStationDetail = async (
	id: number
): Promise<{
	status: boolean;
	data: LocationResponse;
}> => $http.get(`/v2/my-business/stations/${id}`).then((res) => res.data);

// Waitlist Queries Start

export const APIVersion1GetWaitlist = async (): Promise<{
	status: boolean;
	data: QueueData;
	message: string;
}> => $http.get("/my-queue/queue-order-v2/").then((res) => res.data);

export const APIVersion2GetWaitlist = async (
	currentLocationId: number
): Promise<{
	status: boolean;
	data: QueueData;
	message: string;
}> =>
	$http
		.get("/v2/my-business/queue-order", {
			params: {
				location_id: currentLocationId,
			},
		})
		.then((res) => res.data);

// Waitlist Queries End

// Team Members
export const APIVersion1GetAvailableTeamMembers = async (): Promise<{
	status: boolean;
	data: any;
}> =>
	$http.get("/v2/my-business/team-members/available").then((res) => res.data);

export const APIVersion1GetTeamMembers = async (
	queryParams?: Record<string, number | string>
): Promise<GetTeamMembersResponse> =>
	$http
		.get("/v2/my-business/team-members", {
			params: queryParams,
			// paramsSerializer: (params) => serializeParamsToQueryString(params),
		})
		.then((res) => res.data);

export const APIVersion1CreateTeamMemberSettings = async ({
	data,
	queryParams,
}: {
	data: queueSettingsProps;
	queryParams: {
		station_id?: number | string;
		location_id?: number | string;
	};
}): Promise<Station> => {
	return $http
		.post("/v2/my-business/team-members/create", data, {
			params: queryParams,
			paramsSerializer: (params) => serializeParamsToQueryString(params),
		})
		.then((res) => res.data);
};

export const APIVersion1ExisitingMemberToLocation = async ({
	data,
	queryParams,
}: {
	data: queueSettingsProps;
	queryParams: {
		station_id?: number | string;
		location_id?: number | string;
	};
}): Promise<Station> => {
	return $http
		.post("/v2/my-business/team-members/add", data, {
			params: queryParams,
			paramsSerializer: (params) => serializeParamsToQueryString(params),
		})
		.then((res) => res.data);
};

export const APIVersion1RemoveTeamMember = async (
	id: number | string
): Promise<{
	status: boolean;
	data: any;
}> =>
	$http
		.delete(`/v2/my-business/team-members/${id}/remove`)
		.then((res) => res.data);

export const APIVersion1DeleteTeamMember = async (
	id: number | string
): Promise<{
	status: boolean;
	data: any;
}> =>
	$http
		.delete(`/v2/my-business/team-members/${id}/delete`)
		.then((res) => res.data);

// Queue Settings
export const APIVersion1UpdateQueueSettings = async ({
	data,
	queryParams,
}: {
	data: queueSettingsProps;
	queryParams?: {
		station_id?: number | string;
		location_id?: number | string;
	};
}): Promise<Station> => {
	return $http
		.post("/v2/my-business/queue-settings", data, {
			params: queryParams,
			paramsSerializer: (params) => serializeParamsToQueryString(params),
		})
		.then((res) => res.data);
};

export const APIVersion1AddRemoveBulkCustomField = async ({
	data,
	queryParams,
}: {
	data: AddRemoveBulkCustomFieldProps;
	queryParams: {
		apply_to?: string;
	};
}): Promise<Station> => {
	return $http
		.post("/v2/my-business/custom-fields/add-remove-many", data, {
			params: queryParams,
			paramsSerializer: (params) => serializeParamsToQueryString(params),
		})
		.then((res) => res.data);
};

export const APIVersion1GetQueueSettings = async (
	queryParams: Record<string, number | string>
): Promise<{
	status: boolean;
	data: any;
}> =>
	$http
		.get("/v2/my-business/queue-settings", {
			params: queryParams,
			paramsSerializer: (params) => serializeParamsToQueryString(params),
		})
		.then((res) => res.data);

export const APIVersion1GetWorkingHours = async (
	queryParams: Record<string, number | string>
): Promise<{
	status: boolean;
	data: any;
}> =>
	$http
		.get("/v2/my-business/working-hours", {
			params: queryParams,
			paramsSerializer: (params) => serializeParamsToQueryString(params),
		})
		.then((res) => res.data);

/** ======= Settings ========= */

export const APIVersion1UpdateTheme = async (
	data: {
		theme: string;
	},
	id: string | number
): Promise<unknown> =>
	$http.post("/change-theme/" + id, data).then((res) => res.data);

export const APIVersion1ChangePassword = async (
	data: ChangePasswordType
): Promise<unknown> =>
	$http.post("/change-password", data).then((res) => res.data);

export const APIVersion1UpdateBusinessAccountSettings = async (
	data: UpdateBusinessSettingsType
): Promise<unknown> =>
	$http.post("/my-business/update", data).then((res) => res.data);

export const APIVersion1UpdateBusinessLogoSettings = async (
	data: FormData
): Promise<AxiosResponse<string>> =>
	$http.post("/my-business/update-logo", data).then((res) => res.data);

export const APIVersion1GetBusinessCategories = async (): Promise<
	AxiosResponse<
		{
			id: number;
			name: string;
			created_at: Date | null;
			updated_at: Date | null;
		}[]
	>
> => $http.get("/business-categories").then((res) => res.data);

export const APIVersion1UpdateAccountSettings = async (
	data: UserAccountSettingType
): Promise<unknown> =>
	$http.post("/change-profile", data).then((res) => res.data);

export const APIVersion1AddOscarEMRUrl = async (
	data: FirstTimeOscarType
): Promise<unknown> =>
	$http
		.post(CONFIGS.URL.API_BASE_EMR_URL + "/v1/emr/oscar/", data)
		.then((res) => res.data);

export const APIVersion1AddLinksIDOscar = async (
	data: FirstTimeOscarAddLinksIDType
): Promise<{ data: OscarDetailsType }> =>
	$http
		.patch(CONFIGS.URL.API_BASE_EMR_URL + "/v1/emr/oscar/", data)
		.then((res) => res.data);

export const APIVersion1GetOscarDetails = async (): Promise<OscarDetailsType> =>
	$http
		.get(CONFIGS.URL.API_BASE_EMR_URL + "/v1/emr/oscar/")
		.then((res) => res.data);

export const APIVersion1DeleteOscarDetails =
	async (): Promise<OscarDetailsType> =>
		$http
			.delete(CONFIGS.URL.API_BASE_EMR_URL + "/v1/emr/oscar/")
			.then((res) => res.data);

/** ======= Settings END ========= */

/** ======= Schedule ENDPOINTS ========= */
export const APIVersion1GetAdminLocationInformationForScheduling = async (
	locationId
): Promise<any> =>
	$http
		.get("/v2/my-business/scheduling/get-stations-time-slots/" + locationId)
		.then((res) => res.data);

export const APIVersion1GetAdminAppointments = async (
	locationId
): Promise<any> =>
	$http
		.get("/v2/my-business/scheduling/get-appointments/" + locationId)
		.then((res) => res.data);

export const APIVersion1GetAdminAppointmentDetails = async (
	appointmentId
): Promise<any> =>
	$http
		.get("/v2/my-business/scheduling/detail/" + appointmentId)
		.then((res) => res.data);

export const APIVersion1GetStationAvailableTimeSlotsForSchedule = async (
	locationId: number,
	data: {
		station_ids: string[];
		appointment_type_id: number;
		duration: number;
		date: string | undefined;
	}
): Promise<any> =>
	$http
		.post(
			"/v2/my-business/scheduling/get-time-slots-for-filter/" +
				locationId,
			data
		)
		.then((res) => res.data);

export const APIVersion1AdminCreateAppointment = async (
	stationId: number,
	data: Record<string, any>
): Promise<any> =>
	$http
		.post("/v2/my-business/scheduling/schedule/" + stationId, data)
		.then((res) => res.data);

export const APIVersion1AdminUpdateAppointment = async (
	appointmentId: number,
	data: {
		action:
			| "done"
			| "cancel"
			| "reschedule"
			| "follow_up"
			| "approve"
			| "serve"
			| "remind";
		reschedule_date?: string;
		reschedule_time?: string;
	}
): Promise<any> =>
	$http
		.post("/v2/my-business/scheduling/update/" + appointmentId, data)
		.then((res) => res.data);

/** ======= Schedule END ========= */

// Customization Start

export const APIVersion1GetCustomFields = async (queryParams: {
	station_id?: number | string;
	location_id?: number | string;
	apply_to?: string;
}): Promise<{ status: boolean; data: any }> => {
	return $http
		.get("/v2/my-business/custom-fields", {
			params: queryParams,
			paramsSerializer: (params) => serializeParamsToQueryString(params),
		})
		.then((res) => res.data);
};

export const APIVersion1CreateCustomFields = async ({
	data,
	queryParams,
}: {
	data: queueSettingsProps;
	queryParams?: {
		station_id?: number | string;
		location_id?: number | string;
	};
}): Promise<Station> => {
	return $http
		.post("/v2/my-business/custom-fields", data, {
			params: queryParams,
			paramsSerializer: (params) => serializeParamsToQueryString(params),
		})
		.then((res) => res.data);
};

export const APIVersion1UpdateCustomField = async (
	data: any,
	id: string | number
): Promise<unknown> =>
	$http
		.post(`/v2/my-business/custom-fields/${id}/update`, data)
		.then((res) => res.data);

export const APIVersion1UpdateStationCustomField = async (
	data: any,
	id: string | number
): Promise<unknown> =>
	$http
		.post(`/v2/my-business/custom-fields/update-stations/${id}`, data)
		.then((res) => res.data);

export const APIVersion1DeleteCustomFields = async (
	id: number | string
): Promise<{
	status: boolean;
	data: any;
}> =>
	$http.delete(`/v2/my-business/custom-fields/${id}`).then((res) => res.data);

export const APIVersion1RemoveCustomFields = async (
	id: number | string
): Promise<{
	status: boolean;
	data: any;
}> =>
	$http
		.delete(`/v2/my-business/custom-fields/remove-relation/${id}`)
		.then((res) => res.data);

export const APIVersion1AddRemoveCustomField = async (
	fieldId: number,
	data: addRemoveCUstomSlotProps
): Promise<AddBusinessLocationResponseType> =>
	$http
		.post(
			`/v2/my-business/custom-fields/${fieldId}/add-or-remove-relations`,
			data
		)
		.then((res) => res.data);

export const APIVersion1DeleteLocations = async (data: {
	location_ids: number[];
}): Promise<Station> => {
	return $http
		.post("v2/my-business/locations/delete-multiple", data)
		.then((res) => res.data);
};
// Customization End

// Custom Time Slot Start
export const APIVersion1AddCustomTimeSlot = async ({
	data,
	queryParams,
}: {
	data: AppointmentTypeProps;
	queryParams?: {
		station_id?: number | string;
		location_id?: number | string;
	};
}): Promise<Station> => {
	return $http
		.post("/v2/my-business/custom-time-slots", data, {
			params: queryParams,
			paramsSerializer: (params) => serializeParamsToQueryString(params),
		})
		.then((res) => res.data);
};

export const APIVersion1UpdateCustomTimeSlot = async ({
	data,
	customFieldId,
}: {
	data: AppointmentTypeProps;
	customFieldId: number;
}): Promise<Station> => {
	return $http
		.post(`/v2/my-business/custom-time-slots/${customFieldId}/update`, data)
		.then((res) => res.data);
};

export const APIVersion1AddRemoveCustomSlote = async (
	fieldId: number,
	data: addRemoveCUstomSlotProps
): Promise<AddBusinessLocationResponseType> =>
	$http
		.post(
			`/v2/my-business/custom-time-slots/${fieldId}/add-or-remove-relations`,
			data
		)
		.then((res) => res.data);

export const APIVersion1GetCustomTimeSlot = async (
	queryParams: Record<string, number | string>
): Promise<{
	status: boolean;
	data: any;
}> =>
	$http
		.get("/v2/my-business/custom-time-slots", {
			params: queryParams,
			paramsSerializer: (params) => serializeParamsToQueryString(params),
		})
		.then((res) => res.data);
// Custom Time Slot End

// Customers
export const APIVersion1GetCustomers = async (): Promise<{
	status: boolean;
	data: any;
}> => $http.get("/v2/my-business/customers/list").then((res) => res.data);
// Customers End

// Start Link Calendar
export const APIVersion1GetLinkedCalendar = async (
	station_id: number
): Promise<{
	status: boolean;
	data: any;
}> =>
	$http
		.get(`/v2/calendar/of-station/${station_id}/get-accounts`)
		.then((res) => res.data);

export const APIVersion1UpdateLinkedCalendar = async ({
	data,
	queryParams,
}: {
	data: Record<string, boolean>;
	queryParams: {
		station_id: number | string;
		account_id: number | string;
	};
}): Promise<Station> => {
	return $http
		.post(
			`/v2/calendar/of-station/${queryParams.station_id}/update-account/${queryParams.account_id}`,
			data
		)
		.then((res) => res.data);
};
// End Link Calendar
