import { Slider } from "@src/components/ui/slider";
import { Switch } from "@src/components/ui/switch";
import useStationStore from "@src/store/useStationStore";
import { Input } from "@src/components/ui/input";
import { Button } from "@src/components/ui/button";
import Loader from "@src/components/Loader/Loader";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { queueSettingsSchema, queueSettingsProps } from "@src/types/Location";
import { useEffect } from "react";
import {
	GetStationDetailsSlice,
	QueueSettingsSlice,
} from "@src/store/slices/locations/locationSlice";
import { useSearchParams } from "react-router-dom";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@src/components/ui/select";

interface GeneralProps {}

const General: React.FC<GeneralProps> = () => {
	const stationData = useStationStore((s) => s.stationDetails);
	const [searchParams] = useSearchParams();
	const stationId = searchParams.get("stationId") || "";

	const { invalidateQuery } = GetStationDetailsSlice(
		Number(parseInt(stationId))
	);

	const methods = useForm<queueSettingsProps>({
		resolver: zodResolver(queueSettingsSchema),
		defaultValues: {
			schedule_setting: {
				is_visible: 0,
				auto_approve: 0,
				schedule_block_away_count: 0,
				schedule_block_away_option: "weeks",
			},
		},
	});

	const {
		handleSubmit,
		reset,
		setValue,
		watch,
		control,
		formState: { errors },
	} = methods;

	const handleSwitchChange = (field: any) => (value: boolean) => {
		setValue(field, value ? 1 : 0);
	};

	const { mutate: updateQueueSettings, isLoading } = QueueSettingsSlice(
		(data) => {
			console.log("Update successful:", data);
			invalidateQuery();
		},
		(error) => {
			console.error("Update failed:", error);
		}
	);

	const onSubmit: SubmitHandler<queueSettingsProps> = (data) => {
		updateQueueSettings({
			data: {
				...data,
				apply_to_option: {
					apply_to_all: 0,
					locations: [
						{
							id: stationData?.location_id || 0,
							update_location: 0,
							apply_to_all_stations: 0,
							selected_stations: [stationData?.id || 0],
						},
					],
				},
			},
			queryParams: { station_id: stationId || 0 },
		});
	};

	useEffect(() => {
		reset({
			schedule_setting: {
				is_visible:
					stationData?.queue_setting?.schedule_setting?.is_visible ||
					0,
				auto_approve:
					stationData?.queue_setting?.schedule_setting
						?.auto_approve || 0,
				schedule_block_away_count:
					stationData?.queue_setting?.schedule_setting
						?.schedule_block_away_count || 0,
				schedule_block_away_option:
					(stationData?.queue_setting?.schedule_setting
						?.schedule_block_away_option as
						| "days"
						| "weeks"
						| "months") || "weeks",
			},
		});
	}, [reset, stationData]);

	const options = [
		{ value: "days", label: "days" },
		{ value: "weeks", label: "weeks" },
		{ value: "months", label: "months" },
	];

	return (
		<div className="space-y-8">
			<h3 className="text-xl font-semibold">General Schedule</h3>
			<div className="space-y-5">
				<div className=" space-y-5">
					<div className="flex items-center border-b pb-5">
						<div className="flex-1">
							<p>Schedule Visibility</p>
							<p className="text-sm text-gray-400">
								This enables people to view the availability of
								this station and book appointments
							</p>
						</div>
						<div className="flex items-center">
							<Controller
								name="schedule_setting.is_visible"
								control={control}
								render={({ field }) => (
									<Switch
										className="scale-[0.70] data-[state=checked]:bg-[#2EBF43]"
										checked={Boolean(field.value)}
										onCheckedChange={handleSwitchChange(
											"schedule_setting.is_visible"
										)}
									/>
								)}
							/>
							<span className="">
								{watch("schedule_setting.is_visible")
									? "On"
									: "Off"}
							</span>
						</div>
					</div>

					<div className="flex items-center border-b pb-5">
						<div className="flex-1">
							<p>Auto Approve</p>
							<p className="text-sm text-gray-400">
								If there is no conflict, appointments are
								automatically approved
							</p>
						</div>
						<div className="flex items-center">
							<Controller
								name="schedule_setting.auto_approve"
								control={control}
								render={({ field }) => (
									<Switch
										className="scale-[0.70] data-[state=checked]:bg-[#2EBF43]"
										checked={Boolean(field.value)}
										onCheckedChange={handleSwitchChange(
											"schedule_setting.auto_approve"
										)}
									/>
								)}
							/>
							<span className="">
								{watch("schedule_setting.auto_approve")
									? "On"
									: "Off"}
							</span>
						</div>
					</div>

					<div className="border-b pb-5">
						<div className="mb-8 flex items-center">
							<div className="flex-1">
								<p>Schedule Block</p>
								<p className="text-sm text-gray-400">
									This allows people to book appointments up
									until the time that is selected below
								</p>
							</div>
							<div className="flex items-center">
								<Controller
									name="schedule_setting.schedule_block_away_count"
									control={control}
									render={({ field }) => (
										<Switch
											className="scale-[0.70] data-[state=checked]:bg-[#2EBF43]"
											checked={Boolean(field.value)}
											onCheckedChange={handleSwitchChange(
												"schedule_setting.schedule_block_away_count"
											)}
										/>
									)}
								/>
								<span className="">
									{watch(
										"schedule_setting.schedule_block_away_count"
									)
										? "On"
										: "Off"}
								</span>
							</div>
						</div>
						<div className="flex items-center space-x-4">
							<Slider
								value={[
									watch(
										"schedule_setting.schedule_block_away_count"
									) || 0,
								]}
								max={4}
								step={1}
								onValueChange={(value) => {
									setValue(
										"schedule_setting.schedule_block_away_count",
										value[0]
									);
								}}
							/>

							<div className="focus-within::border-transparent flex h-9 w-max items-center divide-x-2 rounded-md border border-input py-1.5">
								<Input
									// defaultValue={String(
									// 	watch(
									// 		"schedule_setting.schedule_block_away_count"
									// 	)
									// )}
									className="remove-number-input-arrow max-h-full w-[30px] min-w-[48px] max-w-fit border-none text-right text-[14px] font-medium leading-[20px] focus-within:ring-0 focus:outline-none"
									value={String(
										watch(
											"schedule_setting.schedule_block_away_count"
										)
									)}
									onChange={(e) => {
										setValue(
											"schedule_setting.schedule_block_away_count",
											parseInt(e.target.value)
										);
									}}
									min={1}
									type="number"
								/>
								<Select
									value={watch(
										"schedule_setting.schedule_block_away_option"
									)}
									onValueChange={(
										value: "days" | "weeks" | "months"
									) => {
										setValue(
											"schedule_setting.schedule_block_away_option",
											value
										);
									}}
								>
									<SelectTrigger className="focus-within::border-transparent flex h-7 w-max items-center space-x-2.5 rounded-none border-0 px-4 py-0">
										<SelectValue
											placeholder={"Select duration"}
											className="focus-within::border-transparent text-[#858C95]"
										/>
									</SelectTrigger>
									<SelectContent className="focus-within::border-transparent !z-[9999]">
										<SelectGroup>
											{options.map(
												(option: {
													label: string;
													value: string;
												}) => {
													return (
														<SelectItem
															key={option.value}
															value={option.value}
															className="px-8"
														>
															{option.label +
																" " +
																"away"}
														</SelectItem>
													);
												}
											)}
										</SelectGroup>
									</SelectContent>
								</Select>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="mx-auto flex w-full max-w-[1000px] justify-end gap-2 py-4">
				<Button
					type="reset"
					// onClick={handleCancel}
					variant={"ghost"}
				>
					Cancel
				</Button>
				<Button
					type="submit"
					className="px-9"
					onClick={handleSubmit(onSubmit)}
				>
					{isLoading ? <Loader size={20} /> : "Update"}
				</Button>
			</div>
		</div>
	);
};

export default General;
