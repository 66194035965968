import { changeTheme } from "@src/utils/functions";
import { useNavigate } from "react-router-dom";
import useUserStore from "../store/useUserStore";
import { User } from "../types/NewUser";
import { getCookie, setCookie } from "../utils/cookies";

export const useHandleLoginSuccess = () => {
	const navigate = useNavigate();
	const {
		setUser,
		setCurrentLocationId,
		setCurrentStationId,
		setLocationsList,
		setStationsList,
		setQueueUrl,
		setOnboardingState,
	} = useUserStore((state) => ({
		setUser: state.setUser,
		setCurrentLocationId: state.setCurrentLocationId,
		setCurrentStationId: state.setCurrentStationId,
		setLocationsList: state.setLocationsList,
		setStationsList: state.setStationsList,
		setQueueUrl: state.setQueueUrl,
		setOnboardingState: state.setOnboardingState,
	}));

	return (data: {
		status: boolean;
		message: string;
		user: User;
		token: string;
	}) => {
		// Set cookies and user state
		setCookie("ac-token", data.token, 7);
		setUser(data.user);

		if (!data.user) {
			navigate("/sign-in");
			return;
		}

		// Handle navigation based on user role
		if (data.user.role === "SUPER_ADMIN") {
			navigate("/admin");
		} else if (data.user.role === "BUSINESS_ADMIN") {
			if (data.user.business_id && data.user.business) {
				if (
					data.user.business.locations &&
					data.user.business.locations.length
				) {
					setQueueUrl(
						data.user.business.locations[0].stations[0].url_code ??
							""
					);
					setCurrentLocationId(data.user.business.locations[0].id);
					setCurrentStationId("All Stations");

					setLocationsList(
						data.user.business.locations.map((location) => ({
							label: location.name,
							value: location.id.toString(),
						}))
					);

					setStationsList(
						data.user.business.locations.flatMap((location) =>
							location.stations.map((station) => ({
								label: station.name,
								value: station.id.toString(),
							}))
						)
					);
					setOnboardingState(3);
					changeTheme(data.user.business.theme ?? "#005893");
					//TODO: Add the check for products so as to route to correct product
					navigate("/dashboard/waitlist");
				} else {
					setOnboardingState(1);
					// navigate("/onboarding/add-location");
					window.open(
						"https://migranium.com/onboarding?redirect=" +
							getCookie("ac-token"),
						"_blank"
					);
				}
			} else {
				setOnboardingState(0);
				// navigate("/onboarding/about-business");
				window.open(
					"https://migranium.com/onboarding?redirect=" +
						getCookie("ac-token"),
					"_blank"
				);
			}
		}
		// toast.success("Login successful 🎉");
	};
};
