import { Button } from "@src/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@src/components/ui/dialog";
import { Label } from "@src/components/ui/label";
import React, { useEffect, useState } from "react";
import { LuX } from "react-icons/lu";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { RadioGroup, RadioGroupItem } from "@src/components/ui/radio-group";
import { CustomSelect } from "@components/form/CustomSelect";

const recipientsOptions = [
	{ value: "waitlist", label: "Waitlist" },
	{ value: "schedule", label: "Schedule" },
	{ value: "all", label: "All" },
];

const SendAlertModal: React.FC<{
	showSendAlert: boolean;
	setShowSendAlert: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ showSendAlert, setShowSendAlert }) => {
	const [isEditing, setIsEditing] = React.useState(false);

	const [initialValues, setInitialValues] = React.useState<any>({
		full_name: "",
		phone_number: "",
		email: "",
		custom_intakes: undefined,
		station_id: 0,
	});

	const {
		register,
		handleSubmit,
		reset,
		control,
		watch,
		setValue,
		formState: { errors },
	} = useForm<z.infer<any>>({
		// resolver: zodResolver(any),
		defaultValues: initialValues,
	});

	return (
		<Dialog
			open={showSendAlert}
			onOpenChange={(newValue) => {
				if (!newValue) setIsEditing(false);
				setShowSendAlert(newValue);
			}}
		>
			<DialogContent className="max-w-[400px]">
				<DialogTitle>
					<DialogHeader>
						<div className="flex items-center justify-between">
							<div className="flex flex-1 items-center space-x-3">
								<p className="text-[22px] font-semibold capitalize leading-[30px] -tracking-[1%] text-main-1">
									Alert
								</p>
							</div>
							<button
								type="button"
								onClick={() => {
									setShowSendAlert(false);
								}}
							>
								<LuX
									color="#858C95"
									className="cursor-pointer"
									size={20}
								/>
							</button>
						</div>
					</DialogHeader>
				</DialogTitle>
				<div className="border-t pt-3">
					<label className="bt-1.5 mb-1.5 block text-sm font-semibold tracking-[-0.1px] text-[#323539]">
						Select Recipients
					</label>
					<Controller
						name={"type"}
						control={control}
						render={({ field }) => (
							<CustomSelect
								placeholder="Select from below"
								className="mb-1 h-10"
								options={recipientsOptions}
								value={field.value}
								onChange={(value: any) => field.onChange(value)}
								// error={getErrorMessage(errors.type?.message)}
							/>
						)}
					/>
				</div>
				<div>
					<p className="mb-2 text-lg font-semibold">Apply To</p>
					<Controller
						name={"apply_to"}
						control={control}
						render={({ field }) => (
							<RadioGroup
								className="flex space-x-4"
								onValueChange={field.onChange}
								defaultValue={field.value}
							>
								<div className="flex items-center space-x-2">
									<RadioGroupItem value="all" id="all" />
									<Label htmlFor="all">Both</Label>
								</div>
								<div className="flex items-center space-x-2">
									<RadioGroupItem
										value="waitlist_only"
										id="waitlist_only"
									/>
									<Label htmlFor="waitlist_only">Email</Label>
								</div>
								<div className="flex items-center space-x-2">
									<RadioGroupItem
										value="schedule_only"
										id="schedule_only"
									/>
									<Label htmlFor="schedule_only">SMS</Label>
								</div>
							</RadioGroup>
						)}
					/>
				</div>
				<DialogFooter className="mt-3 flex items-center space-x-4 border-t-primary pt-5">
					<>
						<Button
							className="text-base font-semibold text-main-1"
							onClick={() => setShowSendAlert(false)}
							variant="ghost"
						>
							Cancel
						</Button>

						<Button
							className="tsxt-base bg-primary px-8 py-3 font-semibold text-white"
							onClick={() => setIsEditing(!isEditing)}
						>
							Send Alert
						</Button>
					</>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default SendAlertModal;
