import useWaitlistWebSocket from "@src/hooks/waitlist/useWaitlistWebSocket";
import { APIVersion2GetWaitlist } from "@src/http/v1";
import useUserStore from "@src/store/useUserStore";
import { QueueData } from "@src/types/waitlist/waitlist";
import toast from "react-hot-toast";
import { useQuery } from "react-query";

export const GetQueryListSlice = (
	// onSuccess: (data: BusinessQueueOrderLocation[]) => void = () => {
	// 	return;
	// },
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const currentLocationId = useUserStore((s) => s.currentLocationId);
	// const currentStationId = useUserStore((s) => s.currentStationId);
	const { handleUpdateWaitlist } = useWaitlistWebSocket();
	// const setPatients = useWaitListStore((s) => s.setPatients);

	return useQuery<
		{ status: boolean; data: QueueData; message: string },
		Error
	>({
		queryKey: ["queue-list", currentLocationId],
		queryFn: () => APIVersion2GetWaitlist(currentLocationId),
		onSuccess: (data) => {
			handleUpdateWaitlist(data.data.queue_order);

			// setTotalSchedule(
			// 	data.data.locations.flatMap((location) =>
			// 		location.stations.flatMap(
			// 			(station) =>
			// 				station.queue_informations
			// 					?.customers_waiting_to_be_approved
			// 					.appointmets
			// 		)
			// 	) as AppointmentWaitingToBeApproved[]
			// );

			// setSchedule(
			// 	data.data.locations.flatMap((location) =>
			// 		location.id === currentLocationId
			// 			? location.stations.flatMap((station) =>
			// 					currentStationId === "All Stations" ||
			// 					station.id === currentStationId
			// 						? station.queue_informations
			// 								?.customers_waiting_to_be_approved
			// 								.appointmets
			// 						: []
			// 				)
			// 			: []
			// 	) as AppointmentWaitingToBeApproved[]
			// );

			// setPatients([data.data.queue_order]);
		},
		onError: (error) => {
			onError(error);
			toast.error(
				<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
					<div className="flex items-center space-x-5">
						<p className="text-lg font-medium text-[#E33B32]">
							Error fetching queue order
						</p>
					</div>
					<button
						className="h-fit p-2.5"
						onClick={() => toast.dismiss("queue-list")}
					>
						<i className="mgc_close_line" />
					</button>
				</div>,
				{
					id: "queue-list",
					duration: 10000,
				}
			);
			console.error(error);
		},
		// staleTime: Infinity,
		// cacheTime: Infinity,
		refetchOnMount: false,
		refetchOnWindowFocus: false,
	});
};
