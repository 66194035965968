import useCustomToast from "@src/components/CustomToast";
import {
	APIVersion1GetTeamMembers,
	APIVersion1UpdateQueueSettings,
	APIVersion1GetAvailableTeamMembers,
	APIVersion1CreateTeamMemberSettings,
	APIVersion1ExisitingMemberToLocation,
	APIVersion1DeleteTeamMember,
	APIVersion1RemoveTeamMember,
} from "@src/http/v1";
import { queueSettingsProps } from "@src/types/Location";
import {
	displayErrorsWithTimeout,
	isValidQueryParams,
} from "@src/utils/functions";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ErrorResponse } from "@src/types";
import { GetTeamMembersResponse } from "@/src/types/TeamMember";
import { APIVersion2DeleteManyTeamMembers } from "@/src/http/v2";

// Teams Slice
export const CreateTeamMemberSlice = (
	onSuccess: (data: any) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const queryClient = useQueryClient();
	const customToast = useCustomToast();
	return useMutation<
		any,
		AxiosError<ErrorResponse>,
		{
			data: any;
			queryParams: {
				station_id?: number | string;
				location_id?: number | string;
			};
		}
	>(
		({ data, queryParams }) =>
			APIVersion1CreateTeamMemberSettings({ data, queryParams }),
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries("get-available-team-member");
				queryClient.invalidateQueries("get-team-members");
				customToast(
					data.message || "Team member created successfully 🎉",
					{
						id: "create-team-member",
						type: "success",
					}
				);

				setTimeout(() => {
					onSuccess(data);
				}, 500);
			},
			onError: (error: AxiosError<ErrorResponse>) => {
				onError?.(error);
				if (error.response?.data) {
					displayErrorsWithTimeout(error.response.data, customToast, {
						toastIdPrefix: "add-locations",
					});
				} else {
					displayErrorsWithTimeout(
						"An unexpected error occurred. Please try again later 🤕",
						customToast,
						{
							toastIdPrefix: "add-locations",
						}
					);
				}
			},
		}
	);
};

export const AddExisitingMemberToLocationSlice = (
	onSuccess: (data: any) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const queryClient = useQueryClient();
	const customToast = useCustomToast();
	return useMutation<
		any,
		AxiosError<ErrorResponse>,
		{
			data: any;
			queryParams: {
				station_id?: number | string;
				location_id?: number | string;
			};
		}
	>(
		({ data, queryParams }) =>
			APIVersion1ExisitingMemberToLocation({ data, queryParams }),
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries("get-available-team-member");
				queryClient.invalidateQueries(["get-team-members", variables]);

				customToast(
					data.message || "Team member added successfully 🎉",
					{
						id: "create-team-member",
						type: "success",
					}
				);
				setTimeout(() => {
					onSuccess(data);
				}, 500);
			},
			onError: (error: AxiosError<ErrorResponse>) => {
				// onError(error);
				if (error.response?.data) {
					displayErrorsWithTimeout(error.response.data, customToast, {
						toastIdPrefix: "add-locations",
					});
				} else {
					displayErrorsWithTimeout(
						"An unexpected error occurred. Please try again later 🎉",
						customToast,
						{
							toastIdPrefix: "add-locations",
						}
					);
				}
				onError?.(error);
			},
		}
	);
};

export const GetAvailableTeamMembersSlice = (
	onError: (error: Error) => void = () => {}
) => {
	return useQuery<{ status: boolean; data: any }, Error>(
		["get-available-team-member"],
		() => APIVersion1GetAvailableTeamMembers(),
		{
			onSuccess: () => {},
			onError,
		}
	);
};

export const GetTeamMembersSlice = (
	queryParams?: {
		location_id?: number | "All Locations";
		station_id?: number | "All Stations";
	},
	onError: (error: Error) => void = () => {},
	dontValidateQueryParams: boolean = true
) => {
	return useQuery<GetTeamMembersResponse, Error>(
		// ["get-team-members", queryParams],
		{
			queryKey: ["get-team-members", queryParams],
			queryFn: () => APIVersion1GetTeamMembers(queryParams),
			onSuccess: () => {},
			onError,
			enabled: dontValidateQueryParams
				? isValidQueryParams(queryParams)
				: true,
		}
	);
};

export const RemoveTeamMemberSlice = (
	onSuccess: (data: any) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const queryClient = useQueryClient();
	const customToast = useCustomToast();

	return useMutation<any, Error, { userId: number }>(
		({ userId }) => APIVersion1RemoveTeamMember(userId),
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries("get-available-team-member");

				customToast("Team member removed successfully 🎉", {
					id: "remove-team-member",
					type: "success",
				});

				setTimeout(() => {
					onSuccess(data);
				}, 500);
			},
			onError: (error) => {
				onError(error);
				customToast("Team member could not be removed 🤕", {
					id: "remove-team-member",
					type: "error",
				});
				console.error(error);
			},
		}
	);
};

export const DeleteTeamMemberSlice = (
	onSuccess: (data: any) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const customToast = useCustomToast();

	return useMutation<any, Error, { userId: number }>(
		({ userId }) => APIVersion1DeleteTeamMember(userId),
		{
			onSuccess: (data) => {
				customToast("Team member deleted successfully 🎉", {
					id: "delete-team-member",
					type: "success",
				});
				setTimeout(() => {
					onSuccess(data);
				}, 500);
			},
			onError: (error) => {
				onError(error);
				customToast("Team member could not be deleted 🤕", {
					id: "delete-team-member",
					type: "error",
				});

				console.error(error);
			},
		}
	);
};

export const DeleteManyTeamMemberSlice = (
	onSuccess: (data: any) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const customToast = useCustomToast();

	return useMutation<any, Error, { user_ids: number[] }>({
		mutationFn: APIVersion2DeleteManyTeamMembers,
		onSuccess: (data) => {
			customToast("Team members deleted successfully 🎉", {
				id: "many-delete-team-member",
				type: "success",
			});
			setTimeout(() => {
				onSuccess(data);
			}, 500);
		},
		onError: (error) => {
			onError(error);
			customToast("Team members could not be deleted 🤕", {
				id: "many-delete-team-member",
				type: "error",
			});

			console.error(error);
		},
	});
};
