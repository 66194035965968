import { z } from "zod";

export const TeamMemberSchema = z.object({
	name: z.string().min(1, "Name is required"),
	email: z.string().email("Invalid email address"),
	phone_number: z.string().min(10, "Invalid phone number").max(10),
	role: z.string(),
	location_ids: z.array(z.number()).optional(),
	room_ids: z.array(z.number()).optional(),
});

export const TeamMemberRole = {
	BusinessAdmin: "BUSINESS_ADMIN",
	LocationManager: "LOCATION_MANAGER",
	StationManager: "STATION_MANAGER",
	ServiceProvider: "SERVICE_PROVIDER",
	TeamMember: "TEAM_MEMBER",
} as const;

export type TeamMemberRoleType =
	(typeof TeamMemberRole)[keyof typeof TeamMemberRole];
