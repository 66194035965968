import { FaRegFile } from "react-icons/fa";
import EditDeletePill from "../EditDeletePill";
import AddNewAppointmentTypeModal from "./AddNewAppointmentTypeModal";
import AddExistingAppointmentTypeModal from "./AddExistingAppointmentTypeModal";
import { useEffect, useState } from "react";
import { Button } from "@src/components/ui/button";
import useStationStore from "@src/store/useStationStore";
import { useSearchParams } from "react-router-dom";
import { GetStationDetailsSlice } from "@src/store/slices/locations/locationSlice";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	CustomTimeSlotProps,
	queueSettingsProps,
	queueSettingsSchema,
} from "@src/types/Location";
import { DeleteLocationModal } from "../DeleteLocationModal";
import { AddRemoveCustomSlotSlice } from "@src/store/slices/custom-intake/customTimeSlotSlice";
import useCustomToast from "@src/components/CustomToast";
import toast from "react-hot-toast";
import { Switch } from "@components/ui/switch";

const CustomTimeSlot: React.FC = () => {
	const [modalMode, setModalMode] = useState<string | undefined>(undefined);
	const [selectedCustomField, setSelectedCustomField] =
		useState<CustomTimeSlotProps | null>(null);
	const [showDeleteSlotModal, setShowDeleteSlotModal] = useState(false);
	const stationData = useStationStore((s) => s.stationDetails);
	const [searchParams] = useSearchParams();
	const stationId = searchParams.get("stationId") || "";
	const customToast = useCustomToast();

	const { invalidateQuery } = GetStationDetailsSlice(
		Number(parseInt(stationId))
	);

	const handleSlotSelection = (slot: CustomTimeSlotProps) => {
		setSelectedCustomField(slot);
		setShowDeleteSlotModal(true);
	};

	const handleSlotEdit = (slot: CustomTimeSlotProps) => {
		setSelectedCustomField(slot);
		setModalMode("edit");
	};

	const methods = useForm<queueSettingsProps>({
		resolver: zodResolver(queueSettingsSchema),
		defaultValues: {
			custom_time_slots: [],
		},
	});

	const {
		reset,
		control,
		watch,
		formState: { errors },
	} = methods;

	const { fields } = useFieldArray<queueSettingsProps>({
		control,
		name: "custom_time_slots",
		keyName: "_id" as "id",
	});

	const { isLoading: removeFieldLoading, mutate: removeFieldMutation } =
		AddRemoveCustomSlotSlice(
			(data) => {
				console.log("Update successful:", data);
				invalidateQuery();
				setShowDeleteSlotModal(false);
			},
			(error) => {
				console.error("Update failed:", error);
				toast.dismiss("add-remove-custom-slot");
			}
		);

	const handleRemoveSlot = () => {
		customToast("Removing slot", {
			id: "add-remove-custom-slot",
			type: "loading",
		});
		if (selectedCustomField) {
			removeFieldMutation({
				id: selectedCustomField.id,
				data: { station_ids_to_remove: [stationData.id || 0] },
			});
		}
	};

	const handleAddExisitingSlot = () => {
		setSelectedCustomField(null);
		setModalMode("exisiting");
	};

	// const handleSwitchChange = (fieldPath: any) => (value: boolean) => {
	// 	const newValue = value ? 1 : 0;
	// 	setValue(fieldPath, value);
	// 	const updatedData = createNestedObject(fieldPath, newValue);
	// 	updateQueueSettings({
	// 		data: {
	// 			...updatedData,
	// 			apply_to_option: {
	// 				apply_to_all: 0,
	// 				locations: [
	// 					{
	// 						id: stationData?.data?.location_id || 0,
	// 						update_location: 0,
	// 						apply_to_all_stations: 0,
	// 						selected_stations: [stationData?.data?.id || 0],
	// 					},
	// 				],
	// 			},
	// 		},
	// 		queryParams: { station_id: stationData?.data?.id || 0 },
	// 	});
	// 	customToast("Updating queue settings", {
	// 		id: "update-queue-settings",
	// 		type: "loading",
	// 	});
	// };

	useEffect(() => {
		reset({
			custom_time_slots: stationData?.queue_setting?.custom_time_slots,
		});
	}, [reset, stationData]);

	return (
		<div className="space-y-8">
			<h3 className="text-xl font-semibold">Custom Appointment Types</h3>
			<div className=" space-y-5">
				<div className="flex items-center border-b pb-5">
					<div className="flex-1">
						<p>Activate Custom Time Slots</p>
						<p className="text-sm text-gray-400">
							These slots allow patients to book specific
							appointments with allocated times
						</p>
					</div>
					<div
						className="flex items-center"
						onClick={(e) => e.stopPropagation()}
					>
						<Controller
							name="schedule_setting.is_open"
							control={control}
							render={({ field }) => (
								<Switch
									className="scale-[0.70] data-[state=checked]:bg-[#2EBF43]"
									checked={Boolean(field.value)}
									// onCheckedChange={handleSwitchChange(
									// 	"schedule_setting.is_open"
									// )}
								/>
							)}
						/>
						<span className="">
							{watch("schedule_setting.is_open") ? "On" : "Off"}
						</span>
					</div>
				</div>
				<div className="flex items-center gap-x-5">
					<Button
						variant={"link"}
						className="h-auto rounded-none border-b-2 border-primary p-0 font-semibold leading-none outline-none hover:no-underline"
						onClick={handleAddExisitingSlot}
					>
						Add from Existing
					</Button>
					<AddNewAppointmentTypeModal
						modalMode={modalMode}
						setModalMode={setModalMode}
						selectedSlot={selectedCustomField}
					/>
					<div className="hidden">
						<AddExistingAppointmentTypeModal
							modalMode={modalMode}
							setModalMode={setModalMode}
						/>
					</div>
				</div>

				{fields.length > 0 ? (
					<div className="space-y-3 pb-4">
						<h3 className="text-xl font-semibold">
							Selected Time Slots for this Station
						</h3>
						{fields.map((field) => (
							<EditDeletePill
								key={field.id}
								slotData={field}
								handleSlotSelection={handleSlotSelection}
								handleSlotEdit={handleSlotEdit}
							/>
						))}
					</div>
				) : (
					<div className="space-y-1 rounded-xl bg-[#F8F8F8] p-6 text-center">
						<FaRegFile
							size={28}
							className="mx-auto text-gray-500"
						/>
						<p className="font-semibold">
							No custom time slots have been added
						</p>
						<p className="text-gray-400">
							Custom time slots will appear here once created.
						</p>
					</div>
				)}
			</div>
			<DeleteLocationModal
				locationName={selectedCustomField?.title || ""}
				showModal={showDeleteSlotModal}
				setShowModal={setShowDeleteSlotModal}
				isLoading={removeFieldLoading}
				buttonAction={handleRemoveSlot}
				DeleteContent={DeleteSlotContent}
				buttonText="Yes, remove slot"
			/>
		</div>
	);
};

export default CustomTimeSlot;

export const DeleteSlotContent = ({
	displayString,
}: {
	displayString: string;
}) => {
	return (
		<div className="space-y-4">
			<p className="mt-2 text-sm leading-[20px] -tracking-[0.1px] text-[#6D748D]">
				Are you sure you want to delete{" "}
				<span className="font-semibold">
					&quot;{displayString}?&quot;
				</span>
				{". "}
			</p>
			<p className="mt-2 text-sm leading-[20px] -tracking-[0.1px] text-[#6D748D]">
				This action cannot be undone.
			</p>
		</div>
	);
};
