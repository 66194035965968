import { useEffect, useState } from "react";
import QueueContainer from "./QueueContainer";

import axios from "axios";
import pusher from "@src/configs/PusherConfig";
export const token = "630|r1XuxV47nc2Kc9pglTJanDmxTtvp4HGlk5l4Gxlhf217d165";

interface QueueSettings {
	average_wait_time: number;
	is_active: boolean;
}

interface IIdName {
	id: number;
	name: string;
}

interface IStation {
	id: number;
	name: string;
}

interface ILocation {
	id: number;
	name: string;
	stations: IStation[];
}

interface IBusiness {
	id: number;
	name: string;
	locations: ILocation[];
}

declare global {
	interface Window {
		Pusher: any;
		Echo: any;
	}
}

export const BACKEND_URL =
	"https://dev-api.migranium.com/api/v2/my-business/queue-order";
export const BACKEND_BUSINESS_URL =
	"https://dev-api.migranium.com/api/v2/my-business/queue-order/business-info";

export interface ICustomer {
	id: number;
	full_name: string;
	email: string;
	phone_number: string;
	business_id: number;
	application_type: string;
	created_at: string;
	updated_at: string;
	priority: string | null;
	health_card_expire_date: string | null;
	location_id: number;
	station_id: number;
	custom_informations: any[];
}

export interface IWaitlist {
	id: number;
	customer_id: number;
	waitlist_id: number;
	track_code: string;
	station_id: number;
	station_name: string;
	location_id: number;
	location_name: string;
	business_id: number;
	business_name: string;
	order_at: string;
	status: string;
	station_average_service_time_in_minute: string;
	estimate_wait_time: string;
	customer: ICustomer;
}
export interface IIdNameSchema {
	id: number;
	name: string;
}

interface ILocationInfo extends IIdNameSchema {
	stations: IIdNameSchema[];
}
interface IBusinessInfo extends IIdNameSchema {
	locations: ILocationInfo[];
}

const businessId = 3;

const sessionId = pusher.sessionID;

enum QueueAction {
	ADDED = "customer_added",
	UPDATED = "queue_updated",
	MARKED_AS_DONE = "customer_marked_as_done",
	CANCELLED = "customer_cancelled",
	REMOVED = "customer_removed",
}

const QueueManagement = () => {
	const [queueData, setQueueData] = useState<IWaitlist[]>([]);
	const [queueSettings, setQueueSettings] = useState<QueueSettings | null>(
		null
	);
	const [selectedStationIds, setSelectedStationIds] = useState<number[]>([]);
	const [selectedLocationId, setSelectedLocation] = useState<number>();
	const [filterData, setFilterData] = useState<IBusinessInfo>();

	const [isLoading, setLoading] = useState<boolean>(true);
	const [hasError, setError] = useState<boolean>(false);

	const fetchQueueData = async () => {
		try {
			setLoading(true);
			setError(false);
			const response = await axios.get(BACKEND_URL, {
				params: {
					selected_station_ids: selectedStationIds,
					location_id: selectedLocationId,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});

			setQueueData(response.data.data.queue_order);
			setQueueSettings(response.data.data.queue_settings);
			setLoading(false);
		} catch (error) {
			setError(true);

			console.error("Error fetching queue data:", error);
		}
	};

	const fetchBusinessLocationsAndStations = async () => {
		try {
			setLoading(true);
			setError(false);

			const response = await axios.get(BACKEND_BUSINESS_URL, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});

			console.log("Response of business fil ", response);
			setFilterData(response.data.data as IBusinessInfo);
			setLoading(false);
		} catch (error) {
			setError(true);

			console.error("Error fetching business data:", error);
		}
	};

	useEffect(() => {
		fetchBusinessLocationsAndStations();
	}, []);

	useEffect(() => {
		if (filterData) {
			setSelectedLocation(filterData.locations[0].id);
		}
	}, [filterData]);

	useEffect(() => {
		if (selectedLocationId) {
			fetchQueueData();
		}
	}, [selectedLocationId, selectedStationIds]);

	useEffect(() => {
		if (selectedLocationId) {
			const trackQueueChannel = pusher.subscribe(
				`location-${selectedLocationId}`
			);

			trackQueueChannel.bind(
				"queue-updated",
				(received: {
					sessionId?: any;
					data: IWaitlist[];
					message?: string;
				}) => {
					console.log("Queue Update received", received);
					const {
						data,
						sessionId: updateSession,
						message,
					} = received;

					if (data && sessionId !== updateSession) {
						setQueueData(filterQueueData(data));
						alert(message);
					}
				}
			);
		}
	}, [selectedLocationId]);

	const filterQueueData = (data: IWaitlist[]) => {
		return data.filter(
			(item) =>
				selectedStationIds.length === 0 ||
				selectedStationIds.includes(item.station_id)
		);
	};

	const onQueueStatusChange = (data: any) => {
		console.log("Queue data called", data);
		setQueueData(data);
	};

	if (isLoading) {
		return (
			<div
				style={{
					display: "flex",
					width: "100%",
					height: "100%",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<h1 className="text-red-400">Loading...</h1>
			</div>
		);
	}

	if (hasError)
		return (
			<div
				style={{
					display: "flex",
					width: "100%",
					height: "100%",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<h1 className="text-red-600">Some thing went Wrong</h1>
			</div>
		);
	return (
		<div>
			<div>
				<select
					value={selectedLocationId}
					onChange={(e) =>
						setSelectedLocation(parseInt(e.target.value))
					}
				>
					{filterData &&
						filterData.locations.map((lc) => (
							<option value={lc.id} key={lc.id}>
								{lc.id} - {lc.name}
							</option>
						))}
				</select>
			</div>
			<QueueContainer
				sessionId={sessionId}
				stations={
					filterData?.locations.find(
						(l) => l.id === selectedLocationId
					)?.stations ?? []
				}
				onQueueStatusChange={onQueueStatusChange}
				queueData={queueData}
			/>
		</div>
	);
};

export default QueueManagement;
