import { z } from "zod";

export interface UserRegisterParams {
	name: string;
	password: string;
	email: string;
}

export interface ContactUsBody {
	name: string;
	email: string;
	phone: string;
	subject: string;
	message: string;
}

export const UserLoginParams = z.object({
	email: z
		.string()
		.min(4, { message: "Email must be at least 4 characters" })
		.email({ message: "Invalid email address format" }),
	password: z.string().min(8, { message: "Password too short" }),
});

export const ForgotParams = z.object({
	email: z
		.string()
		.min(4, { message: "Email must be at least 4 characters" })
		.email({ message: "Invalid email address format" }),
});

export const ResetParams = z
	.object({
		token: z.string(),
		password: z
			.string()
			.min(8, { message: "New Password must be at least 8 characters" })
			.regex(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
				{
					message:
						"Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
				}
			),
		password_confirmation: z.string(),
	})
	.refine(
		(values) => {
			return values.password === values.password_confirmation;
		},
		{
			message: "Passwords must match!",
			path: ["password_confirmation"],
		}
	);

export interface ResetPasswordDataResponse {
	product_types: string[];
	message: string;
	success: boolean;
}

export interface GetMFASettingsResponse {
	status: boolean;
	message: string;
	qrcode: string;
	code: string;
}

export const createOTPSchema = z.object({
	otp: z
		.string()
		.min(6, "OTP must be 6 digits")
		.max(6, "OTP must be 6 digits")
		.regex(/^[0-9]+$/, "OTP must contain only numbers"),
});

export interface VerifyApp2FAProps {
	token: string;
	code: string;
}
export interface ManualVerifyApp2FAProps {
	code: string;
}

export type OTPFormValues = z.infer<typeof createOTPSchema>;
export type UserLoginSchema = z.infer<typeof UserLoginParams>;
export type ForgotSchema = z.infer<typeof ForgotParams>;
export type ResetSchema = z.infer<typeof ResetParams>;
