import { z } from "zod";

export const TeamMemberSchema = z.object({
	id: z.number().optional(),
	user_id: z.number().optional(),
	name: z.string().min(1, "Name is required"),
	role: z.string().min(1, "Role is required"),
	email: z.string().email("Invalid email address"),
	phone_number: z.string().min(1, "Phone number is required"),
});

export const waitlistSettingSchema = z.object({
	auto_clearing: z.number().optional(),
	auto_clearing_minute: z.number().optional(),
	auto_flow: z.number().optional(),
	manual_wait_time: z.number().optional(),
	use_average_time: z.number().optional(),
	is_queue_active: z.number().optional(),
	is_visible: z.number().optional(),
});

export const scheduleSettingSchema = z.object({
	schedule_block_in_min: z.number().optional(),
	is_visible: z.number().optional(),
});

export const queueSettingsSchema = z.object({
	join_from_qr: z.number().optional(),
	join_from_url: z.number().optional(),
	auto_approve: z.number().optional(),
	is_visible: z.number().optional(),
	waitlist_setting: waitlistSettingSchema.optional(),
	schedule_setting: scheduleSettingSchema.optional(),
	child_update_strategy: z.string().optional(),
	child_ids: z.array(z.number()).optional(),
});

export type TeamMemberProps = z.infer<typeof TeamMemberSchema>;

export interface TeamMemberUser {
	id: number;
	name: string;
	email: string;
	google_user_id: string | null;
	role: string;
	job_title: string | null;
	stripe_customer_id: string | null;
	created_at: string;
	updated_at: string;
	stripe_id: string | null;
	pm_type: string | null;
	pm_last_four: string | null;
	trial_ends_at: string | null;
	business_id: number;
	is_email_verified: number;
	is_active: number;
	company_id: number | null;
	phone_number: string;
}

export interface TeamMember {
	id: number;
	user_id: number;
	name: string;
	email: string;
	phone_number: string;
	business_id: number;
	company_id: number | null;
	owningable_id: number;
	owningable_type: string;
	token: string;
	role: string;
	status: string;
	accepted_at: string | null;
	declined_at: string | null;
	cancelled_at: string | null;
	created_at: string;
	updated_at: string;
	user: TeamMemberUser;
}

export interface GetTeamMembersResponse {
	status?: boolean;
	message?: string;
	data: TeamMember[];
}
