import pusher from "@src/configs/PusherConfig";
import { PatientStatus, StatusOfPatient } from "@src/types/wait-list";
import { QueueData } from "@src/types/waitlist/waitlist";
import useUserStore from "@store/useUserStore";
import useWaitListStore from "@store/useWaitListStore";
import { useQueryClient } from "react-query";

const useLocationSelection: () => {
	handleLocationChange: (value: string) => void;
} = () => {
	const queryClient = useQueryClient();
	// const previousLocationId = useUserStore((s) => s.currentLocationId);
	const currentLocationId = useUserStore((s) => s.currentLocationId);
	const setCurrentLocationId = useUserStore((s) => s.setCurrentLocationId);
	const setCurrentStationId = useUserStore((s) => s.setCurrentStationId);
	const setPatientsObject = useWaitListStore((s) => s.setPatientsObject);

	const handleLocationChange = (value: string) => {
		const locationId = parseInt(value);
		pusher.unsubscribe(`location-${currentLocationId}`);
		setCurrentStationId("All Stations");

		const getQueryList = queryClient.getQueryData([
			"queue-list",
			locationId,
		]) as {
			status: boolean;
			data: QueueData;
			message: string;
		};
		// if (!getQueryList || !getQueryList?.data.queue_order) return;
		// console.log(value);

		queryClient.invalidateQueries(["queue-list", locationId]);
		setCurrentLocationId(locationId);
		// console.log(locationId)
		setPatientsObject({
			[PatientStatus.Pending]: getQueryList.data.queue_order.filter(
				(item) =>
					item.location_id === locationId &&
					item.status === StatusOfPatient.Pending
			),
			[PatientStatus.Upcoming]: getQueryList.data.queue_order.filter(
				(item) =>
					item.location_id === locationId &&
					item.status === StatusOfPatient.Upcoming
			),
			[PatientStatus.NextInLine]: getQueryList.data.queue_order.filter(
				(item) =>
					item.location_id === locationId &&
					item.status === StatusOfPatient.NextInLine
			),
			[PatientStatus.Serving]: getQueryList.data.queue_order.filter(
				(item) =>
					item.location_id === locationId &&
					item.status === StatusOfPatient.GettingService
			),
			[PatientStatus.Schedule]: [],
		});
	};

	return {
		handleLocationChange,
	};
};

export default useLocationSelection;
