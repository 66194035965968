import { LoaderButton } from "@src/components/form/LoaderButton";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogTitle,
	DialogTrigger,
} from "@src/components/ui/dialog";
import UpdateWaitlistSlice from "@src/store/slices/waitlist/updateWaitlistSlice";
import { QueueEntry, UpdateWaitlistEnum } from "@src/types/waitlist/waitlist";
import React, { useState } from "react";
import { LuX } from "react-icons/lu";
import useCustomToast from "../../CustomToast";
import { Button } from "../../ui/button";
import { StatusOfPatient } from "@src/types/wait-list";

const DeletePatient: React.FC<{
	patientDetails?: QueueEntry;
	showDeleteCustomerModal: boolean;
	setShowDeleteCustomerModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
	patientDetails,
	showDeleteCustomerModal,
	setShowDeleteCustomerModal,
}) => {
	// const [showDeleteCustomerModal, setShowDeleteCustomerModal] =
	// 	useState(false);

	const customToast = useCustomToast();

	const updateWaitlistMutation = UpdateWaitlistSlice(
		() => {
			customToast("Patient Deleted Successfully", {
				id: "manage-patient-" + patientDetails?.customer_id,
				type: "success",
			});
			setShowDeleteCustomerModal(false);
		},
		() => {
			customToast("Patient could not be deleted", {
				id: "manage-patient-" + patientDetails?.customer_id,
				type: "error",
			});
		}
	);

	return (
		<Dialog
			open={showDeleteCustomerModal}
			onOpenChange={(newState) => {
				if (!updateWaitlistMutation.isLoading)
					setShowDeleteCustomerModal(newState);
			}}
		>
			<DialogContent className="max-w-[360px] p-4">
				<div className="flex justify-between space-x-2">
					<i className="mgc_user_x_line py-1 text-[20px] before:!text-[#C8322B]" />
					<div className="flex flex-col">
						<DialogTitle className="text-[22px] font-semibold capitalize leading-[30px] -tracking-[1%] text-[#323539]">
							Delete From Waitlist
						</DialogTitle>
						<p className="trakcing-[-0.1px] text-[14px] leading-[20px] text-[#6D748D]">
							Are you sure you want to delete{" "}
							<b className="text-[#323539]">
								{patientDetails?.customer.full_name}
							</b>{" "}
							from the waitlist? This action can&apos;t be undone.
						</p>
						<DialogFooter className="mt-6 space-x-1 sm:space-x-1">
							<Button
								variant="ghost"
								className="space-x-5 text-[#323539]"
								onClick={() => {
									if (!updateWaitlistMutation.isLoading)
										setShowDeleteCustomerModal(false);
								}}
								disabled={updateWaitlistMutation.isLoading}
							>
								Cancel
							</Button>
							<LoaderButton
								variant="destructive"
								className="px-5 text-white"
								onClick={() => {
									customToast("Deleting Patient", {
										id:
											"manage-patient-" +
											patientDetails?.customer_id,
										type: "loading",
									});
									updateWaitlistMutation.mutate({
										waitlist_id:
											patientDetails?.waitlist_id ?? 0,
										status: UpdateWaitlistEnum.CancelledByBusinessAdmin,
									});
								}}
								loading={updateWaitlistMutation.isLoading}
								disabled={updateWaitlistMutation.isLoading}
								loaderSize={20}
							>
								Delete
							</LoaderButton>
						</DialogFooter>
					</div>
					<button
						disabled={updateWaitlistMutation.isLoading}
						className="flex items-start"
						onClick={(e) => {
							e.preventDefault();
							if (!updateWaitlistMutation.isLoading)
								setShowDeleteCustomerModal(false);
						}}
					>
						<LuX
							color="#858C95"
							className="cursor-pointer"
							width="20px"
							height="20px"
						/>
					</button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default DeletePatient;
