import { TabsContent } from "@src/components/ui/tabs";
import React from "react";
import ProfileInformation from "./ProfileInformation";
import ChangePassword from "./ChangePassword";
import { ScrollArea } from "@src/components/ui/scroll-area";

const Settings: React.FC = () => {
	return (
		<TabsContent
			value="account-setting"
			className="mt-0 flex w-full flex-col data-[state=inactive]:hidden data-[state=active]:flex-1"
		>
			<ScrollArea className="w-full">
				<div className="flex h-[80dvh] max-w-[540px] flex-col space-y-6">
					<ProfileInformation />
					<ChangePassword />
					<div className="space-y-2 p-4">
						<h3 className="font-medium leading-[16px] text-[#323539]">
							Close Account
						</h3>
						<p className="text-[14px] leading-[18px] text-[#71717A]">
							Warning! Deleting your account will permanently
							erase your current profile. You won&apos;t be able
							to get it back. If you&apos;re sure, contact{" "}
							<a
								href="mailto:support@migranium.com"
								className="text-primary underline"
							>
								support
							</a>{" "}
							to delete your account.
						</p>
					</div>
				</div>
			</ScrollArea>
		</TabsContent>
	);
};

export default Settings;
