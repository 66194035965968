import React from "react";

const CustomerVisitHistoryCard: React.FC<{
	data: {
		location_name: string;
		station_name: string;
		date: string;
		appointment_time: string;
	};
}> = ({ data }) => {
	return (
		<li className="grid grid-cols-2 gap-2 rounded-xl px-4 py-3 shadow-[0px_0px_5px_0px_rgba(0,0,0,0.25)]">
			<p className="text-lg tracking-[-0.1px] text-main-1">
				{data.location_name}
			</p>
			<div className="flex items-center space-x-1">
				<i className="mgc_calendar_line before:!text-[#858C95]" />
				<p className="text-sm tracking-[-0.1px] text-main-1">
					12 Aug 2024
				</p>
			</div>
			<div className="flex items-center space-x-1">
				<i className="mgc_hashtag_line before:!text-[#858C95]" />
				<p className="text-sm tracking-[-0.1px] text-main-1">
					{data.station_name}
				</p>
			</div>
			<div className="flex items-center space-x-1">
				<i className="mgc_time_line before:!text-[#858C95]" />
				<p className="text-sm tracking-[-0.1px] text-main-1">
					{data.appointment_time}
				</p>
			</div>
		</li>
	);
};

export default CustomerVisitHistoryCard;
