import {
	APIVersion2AddPatientToWaitlist,
	APIVersion2GetCustomerInfo,
	APIVersion2GetJoinInfo,
	APIVersion2UpdateCustomer,
	APIVersion2UpdatePatientOnWaitlist,
} from "@/src/http/v2";
import {
	JoinWaitlistSingleStation,
	JoinWaitlistType,
	JoinWorkingHoursErrorResponse,
	WaitlistCodeInfoResponse,
} from "@/src/types/waitlist/add-customer-waitlist";
import {
	SinglePatientQueueResponse,
	UpdatedPatientData,
} from "@/src/types/waitlist/waitlist";
import useCustomToast from "@components/CustomToast";
import { AxiosError, AxiosResponse } from "axios";
import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router";

export const AddPatientSlice = (
	onSuccess: (data: AxiosResponse<WaitlistCodeInfoResponse>) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const customToast = useCustomToast();
	const queryClient = useQueryClient();

	return useMutation<
		AxiosResponse<WaitlistCodeInfoResponse>,
		AxiosError,
		JoinWaitlistType & { station_id?: string | number }
	>({
		mutationFn: APIVersion2AddPatientToWaitlist,
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ["get-customers"] });
			onSuccess(data);
			customToast("Added patient to waitlist 🎉", {
				id: "join-waitlist",
			});
		},
		onError: (error) => {
			onError(error);
			customToast("Could not add patient waitlist 🤕", {
				id: "join-waitlist",
				type: "error",
			});
		},
	});
};

export const UpdatePatientSlice = (
	onSuccess: (data: UpdatedPatientData) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const customToast = useCustomToast();

	return useMutation<
		UpdatedPatientData,
		AxiosError,
		JoinWaitlistType & { customer_id?: number }
	>({
		mutationFn: APIVersion2UpdatePatientOnWaitlist,
		onSuccess: (data) => {
			onSuccess(data);
			customToast("Updated patient 🎉", {
				id: "update-waitlist",
			});
		},
		onError: (error) => {
			onError(error);
			customToast("Could not update patient 🤕", {
				id: "update-waitlist",
				type: "error",
			});
		},
	});
};

export const useGetStationJoinInfo = (
	onError: (
		error: AxiosError<JoinWorkingHoursErrorResponse> | null
	) => void = () => {
		return;
	}
) => {
	const { join_code } = useParams();

	const getJoinStationInfoQuery = useQuery<
		JoinWaitlistSingleStation,
		AxiosError<JoinWorkingHoursErrorResponse>
	>({
		queryFn: () => APIVersion2GetJoinInfo({ join_code }),
		queryKey: ["join-station-info"],
	});

	useEffect(() => {
		if (getJoinStationInfoQuery.isError)
			onError(getJoinStationInfoQuery.error);
	}, [getJoinStationInfoQuery.isError, onError]);

	return getJoinStationInfoQuery;
};

export const useGetPatientInfo = (
	onError: (error: AxiosError | null) => void = () => {
		return;
	},
	customer_id?: number,
	enabled?: boolean
) => {
	const getPatieintInfoQuery = useQuery<
		SinglePatientQueueResponse,
		AxiosError
	>({
		queryFn: () => APIVersion2GetCustomerInfo({ customer_id }),
		queryKey: ["get-customer-info", customer_id],
		enabled,
	});

	useEffect(() => {
		if (getPatieintInfoQuery.isError) onError(getPatieintInfoQuery.error);
	}, [getPatieintInfoQuery.isError, onError]);

	return getPatieintInfoQuery;
};

export const UpdateCustomerSlice = (
	onSuccess: (data: SinglePatientQueueResponse) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const customToast = useCustomToast();

	return useMutation<
		SinglePatientQueueResponse,
		AxiosError,
		Partial<JoinWaitlistType & { customer_id?: number }>
	>({
		mutationFn: APIVersion2UpdateCustomer,
		onSuccess: (data) => {
			onSuccess(data);
		},
		onError: (error) => {
			onError(error);
			customToast("Could not update patient 🤕", {
				id: "update-waitlist",
				type: "error",
			});
		},
	});
};
