import SendAlertModal from "@/src/components/Dashboard/SendAlertModal";
import { GetCustomersSlice } from "@/src/store/slices/customers/customersSlice";
import {
	Select,
	SelectTrigger,
	SelectValue,
	SelectContent,
	SelectItem,
} from "@components/ui/select";
import CustomerInformationModal from "@src/components/Dashboard/customers/CustomerInformationModal";
import CustomerListCard from "@src/components/Dashboard/customers/CustomerListCard";
import CustomerManualAdd from "@src/components/Dashboard/customers/CustomerManualAdd";
import Checkbox from "@src/components/ui-extended/checkbox";
import InputIcon from "@src/components/ui-extended/input-icon";
import { Button } from "@src/components/ui/button";
import { TooltipProvider } from "@src/components/ui/tooltip";
import Header from "@src/layouts/Header/Header";
import { CustomerData } from "@src/types/customers";
import { sortByFilter } from "@src/utils/constants";
import {
	handleRowCheckboxChange,
	handleSelectAllChange,
	sortByName,
} from "@src/utils/functions";
import React from "react";

const Customers: React.FC = () => {
	const [, setSelectedSortBy] = React.useState("asc");
	const [items, setItems] = React.useState<any[]>([]);
	const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
	const [selectAllChecked, setSelectAllChecked] =
		React.useState<boolean>(false);
	const [modalPatientInformation, setModalPatientInformation] =
		React.useState<CustomerData | undefined>();
	const [showPatientInformation, setShowPatientInformation] =
		React.useState(false);
	const [showAddPatient, setShowAddPatient] = React.useState(false);
	const [showSendAlert, setShowSendAlert] = React.useState(false);

	const handleSortChange = (
		sortOrder: "asc" | "dsc" | "date-created" | "last-updated"
	) => {
		setSelectedSortBy(sortOrder);
		if (sortOrder === "asc" || sortOrder === "dsc") {
			const sortedData = sortByName([...items], sortOrder);
			setItems(sortedData);
		}
	};

	const { data, isLoading } = GetCustomersSlice();

	return (
		<main className="flex flex-1 flex-col">
			<Header title="List of Customers" showDate={false} />
			<div className="flex flex-1 flex-col overflow-visible">
				<div className="flex items-center justify-between space-x-1.5">
					<InputIcon
						className="placeholder:text-[#858C95]"
						outerClassName="h-9 flex-1"
						icon="mgc_search_line"
						placeholder="Search by name , email"
					/>
					<Select onValueChange={handleSortChange}>
						<SelectTrigger className="h-9 max-w-[150px] flex-1">
							<SelectValue placeholder="Sort By" />
						</SelectTrigger>
						<SelectContent>
							{sortByFilter.map((sort, i) => (
								<SelectItem key={i} value={sort.value}>
									{sort.label}
								</SelectItem>
							))}
						</SelectContent>
					</Select>

					<CustomerManualAdd
						show={showAddPatient}
						setShow={setShowAddPatient}
					/>
				</div>
				<section className="mb-4 flex flex-1 flex-col space-y-4">
					<div className="flex justify-between py-[18px]">
						<div className="flex items-center space-x-1.5">
							<Checkbox
								className="rounded-sm border-2 border-[#424954]"
								handleCheckboxChange={() =>
									handleSelectAllChange(
										!selectAllChecked,
										setSelectedRows,
										setSelectAllChecked,
										dummyData.map(
											(item) => item.waitlist_id
										)
									)
								}
								isChecked={selectAllChecked}
								id={""}
							/>
							<p className="text-[14px] font-medium leading-[24px] tracking-[-0.14px] text-[#7D7E80]">
								Select All
							</p>
						</div>
						<div className="flex items-center space-x-1.5">
							<Button
								variant="outline-primary"
								className="size-fit p-2.5"
							>
								<i className="mgc_user_security_line before:!text-primary" />
							</Button>
							<Button
								variant="outline-primary"
								className="size-fit p-2.5"
							>
								<i className="mgc_delete_2_line before:!text-primary" />
							</Button>
							<Button
								variant="outline-primary"
								className="flex items-center gap-x-1 p-2.5"
								onClick={() => setShowSendAlert(true)}
							>
								Message
								<i className="mgc_chat_2_line before:!text-primary" />
							</Button>
						</div>
					</div>
					<div className="flex flex-col space-y-2">
						<TooltipProvider>
							{data?.data?.map((item: CustomerData) => (
								<CustomerListCard
									key={item?.id}
									onSelect={() =>
										handleRowCheckboxChange(
											!selectedRows.includes(item?.id),
											item?.id,
											setSelectedRows,
											dummyData.length,
											setSelectAllChecked
										)
									}
									checked={selectedRows.includes(item.id)}
									item={item as CustomerData}
									setShowPatientInformation={
										setShowPatientInformation
									}
									setModalPatientInformation={
										setModalPatientInformation
									}
								/>
							))}
						</TooltipProvider>
					</div>
				</section>
			</div>
			<CustomerInformationModal
				patientDetails={modalPatientInformation}
				showPatientInformation={showPatientInformation}
				setShowPatientInformation={setShowPatientInformation}
			/>
			<SendAlertModal
				showSendAlert={showSendAlert}
				setShowSendAlert={setShowSendAlert}
			/>
		</main>
	);
};

export default Customers;

const dummyData = [
	{
		waitlist_id: 101,
		is_paused: 0,
		status: "waiting",
		priority: "high",
		check_in_status: null,
		check_in_at: null,
		track_code: "X1A3QPDZ",
		customer_id: 201,
		station_id: 12,
		station_name: "Station 5",
		location_id: 10,
		location_name: "Test Location 5",
		business_id: 15,
		business_name: "Dummy Business 1",
		joined_at: "2024-09-05 08:45:13",
		order_at: null,
		swap_request_id: null,
		address: "123 Elm Street",
		station_average_in_minute: "25.0000",
		estimated_wait_time: "120.0000",
		customer: {
			id: 201,
			full_name: "John Doe",
			email: "john.doe@gmail.com",
			phone_number: "1234567890",
			business_id: 15,
			application_type: "QUEUE",
			created_at: "2024-09-05T08:45:13.000000Z",
			updated_at: "2024-09-05T08:45:13.000000Z",
			health_card_expire_date: null,
		},
	},
	{
		waitlist_id: 102,
		is_paused: 0,
		status: "accepted",
		priority: "normal",
		check_in_status: null,
		check_in_at: null,
		track_code: "Y8B5DJKL",
		customer_id: 202,
		station_id: 13,
		station_name: "Station 2",
		location_id: 11,
		location_name: "Test Location 6",
		business_id: 16,
		business_name: "Dummy Business 2",
		joined_at: "2024-09-05 09:15:20",
		order_at: null,
		swap_request_id: null,
		address: "456 Maple Avenue",
		station_average_in_minute: "20.0000",
		estimated_wait_time: "110.0000",
		customer: {
			id: 202,
			full_name: "Jane Smith",
			email: "jane.smith@gmail.com",
			phone_number: "0987654321",
			business_id: 16,
			application_type: "QUEUE",
			created_at: "2024-09-05T09:15:20.000000Z",
			updated_at: "2024-09-05T09:15:20.000000Z",
			health_card_expire_date: null,
		},
	},
	{
		waitlist_id: 103,
		is_paused: 0,
		status: "in_service",
		priority: "low",
		check_in_status: null,
		check_in_at: null,
		track_code: "M3C8OPZR",
		customer_id: 203,
		station_id: 14,
		station_name: "Station 4",
		location_id: 12,
		location_name: "Test Location 7",
		business_id: 17,
		business_name: "Dummy Business 3",
		joined_at: "2024-09-05 10:20:45",
		order_at: null,
		swap_request_id: null,
		address: "789 Oak Road",
		station_average_in_minute: "30.0000",
		estimated_wait_time: "90.0000",
		customer: {
			id: 203,
			full_name: "Mark Brown",
			email: "mark.brown@gmail.com",
			phone_number: "1122334455",
			business_id: 17,
			application_type: "QUEUE",
			created_at: "2024-09-05T10:20:45.000000Z",
			updated_at: "2024-09-05T10:20:45.000000Z",
			health_card_expire_date: null,
		},
	},
	{
		waitlist_id: 104,
		is_paused: 1,
		status: "waiting",
		priority: "normal",
		check_in_status: null,
		check_in_at: null,
		track_code: "H9P7VQXZ",
		customer_id: 204,
		station_id: 15,
		station_name: "Station 3",
		location_id: 13,
		location_name: "Test Location 8",
		business_id: 18,
		business_name: "Dummy Business 4",
		joined_at: "2024-09-05 11:00:00",
		order_at: null,
		swap_request_id: null,
		address: "101 Pine Drive",
		station_average_in_minute: "35.0000",
		estimated_wait_time: "140.0000",
		customer: {
			id: 204,
			full_name: "Lucy Green",
			email: "lucy.green@gmail.com",
			phone_number: "2233445566",
			business_id: 18,
			application_type: "QUEUE",
			created_at: "2024-09-05T11:00:00.000000Z",
			updated_at: "2024-09-05T11:00:00.000000Z",
			health_card_expire_date: null,
		},
	},
	{
		waitlist_id: 105,
		is_paused: 0,
		status: "accepted",
		priority: "high",
		check_in_status: null,
		check_in_at: null,
		track_code: "K4B1LZNX",
		customer_id: 205,
		station_id: 16,
		station_name: "Station 1",
		location_id: 14,
		location_name: "Test Location 9",
		business_id: 19,
		business_name: "Dummy Business 5",
		joined_at: "2024-09-05 12:30:30",
		order_at: null,
		swap_request_id: null,
		address: "202 Birch Lane",
		station_average_in_minute: "40.0000",
		estimated_wait_time: "130.0000",
		customer: {
			id: 205,
			full_name: "Alice White",
			email: "alice.white@gmail.com",
			phone_number: "3344556677",
			business_id: 19,
			application_type: "QUEUE",
			created_at: "2024-09-05T12:30:30.000000Z",
			updated_at: "2024-09-05T12:30:30.000000Z",
			health_card_expire_date: null,
		},
	},
	{
		waitlist_id: 106,
		is_paused: 0,
		status: "in_service",
		priority: "normal",
		check_in_status: null,
		check_in_at: null,
		track_code: "T6P3FJQW",
		customer_id: 206,
		station_id: 17,
		station_name: "Station 2",
		location_id: 15,
		location_name: "Test Location 10",
		business_id: 20,
		business_name: "Dummy Business 6",
		joined_at: "2024-09-05 13:45:10",
		order_at: null,
		swap_request_id: null,
		address: "303 Cedar Street",
		station_average_in_minute: "50.0000",
		estimated_wait_time: "100.0000",
		customer: {
			id: 206,
			full_name: "Charlie Gray",
			email: "charlie.gray@gmail.com",
			phone_number: "4455667788",
			business_id: 20,
			application_type: "QUEUE",
			created_at: "2024-09-05T13:45:10.000000Z",
			updated_at: "2024-09-05T13:45:10.000000Z",
			health_card_expire_date: null,
		},
	},
	{
		waitlist_id: 107,
		is_paused: 0,
		status: "accepted",
		priority: "low",
		check_in_status: null,
		check_in_at: null,
		track_code: "U2R9FJDK",
		customer_id: 207,
		station_id: 18,
		station_name: "Station 4",
		location_id: 16,
		location_name: "Test Location 11",
		business_id: 21,
		business_name: "Dummy Business 7",
		joined_at: "2024-09-05 14:15:50",
		order_at: null,
		swap_request_id: null,
		address: "404 Walnut Street",
		station_average_in_minute: "15.0000",
		estimated_wait_time: "70.0000",
		customer: {
			id: 207,
			full_name: "Diana Blue",
			email: "diana.blue@gmail.com",
			phone_number: "5566778899",
			business_id: 21,
			application_type: "QUEUE",
			created_at: "2024-09-05T14:15:50.000000Z",
			updated_at: "2024-09-05T14:15:50.000000Z",
			health_card_expire_date: null,
		},
	},
	{
		waitlist_id: 108,
		is_paused: 0,
		status: "waiting",
		priority: "high",
		check_in_status: null,
		check_in_at: null,
		track_code: "X9M4BCDT",
		customer_id: 208,
		station_id: 19,
		station_name: "Station 3",
		location_id: 17,
		location_name: "Test Location 12",
		business_id: 22,
		business_name: "Dummy Business 8",
		joined_at: "2024-09-05 15:00:25",
		order_at: null,
		swap_request_id: null,
		address: "505 Chestnut Avenue",
		station_average_in_minute: "35.0000",
		estimated_wait_time: "160.0000",
		customer: {
			id: 208,
			full_name: "Eli Black",
			email: "eli.black@gmail.com",
			phone_number: "6677889900",
			business_id: 22,
			application_type: "QUEUE",
			created_at: "2024-09-05T15:00:25.000000Z",
			updated_at: "2024-09-05T15:00:25.000000Z",
			health_card_expire_date: null,
		},
	},
	{
		waitlist_id: 109,
		is_paused: 0,
		status: "in_service",
		priority: "normal",
		check_in_status: null,
		check_in_at: null,
		track_code: "Q8L7DMPZ",
		customer_id: 209,
		station_id: 20,
		station_name: "Station 1",
		location_id: 18,
		location_name: "Test Location 13",
		business_id: 23,
		business_name: "Dummy Business 9",
		joined_at: "2024-09-05 16:45:00",
		order_at: null,
		swap_request_id: null,
		address: "606 Fir Lane",
		station_average_in_minute: "45.0000",
		estimated_wait_time: "120.0000",
		customer: {
			id: 209,
			full_name: "Fiona Pink",
			email: "fiona.pink@gmail.com",
			phone_number: "7788990011",
			business_id: 23,
			application_type: "QUEUE",
			created_at: "2024-09-05T16:45:00.000000Z",
			updated_at: "2024-09-05T16:45:00.000000Z",
			health_card_expire_date: null,
		},
	},
	{
		waitlist_id: 110,
		is_paused: 0,
		status: "accepted",
		priority: "normal",
		check_in_status: null,
		check_in_at: null,
		track_code: "R4S8GHTX",
		customer_id: 210,
		station_id: 21,
		station_name: "Station 2",
		location_id: 19,
		location_name: "Test Location 14",
		business_id: 24,
		business_name: "Dummy Business 10",
		joined_at: "2024-09-05 17:30:55",
		order_at: null,
		swap_request_id: null,
		address: "707 Poplar Drive",
		station_average_in_minute: "25.0000",
		estimated_wait_time: "140.0000",
		customer: {
			id: 210,
			full_name: "George Red",
			email: "george.red@gmail.com",
			phone_number: "8899001122",
			business_id: 24,
			application_type: "QUEUE",
			created_at: "2024-09-05T17:30:55.000000Z",
			updated_at: "2024-09-05T17:30:55.000000Z",
			health_card_expire_date: null,
		},
	},
];
