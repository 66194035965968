import ChangePositionOrderModal from "@/src/components/Dashboard/waitlist/ChangePatientOrder";
import {
	closestCorners,
	DndContext,
	DragEndEvent,
	DragOverlay,
	KeyboardSensor,
	PointerSensor,
	TouchSensor,
	UniqueIdentifier,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import ChangeStation from "@src/components/Dashboard/waitlist/ChangeStation";
import DeletePatient from "@src/components/Dashboard/waitlist/DeletePatient";
import PatientInformation from "@src/components/Dashboard/waitlist/PatientInformation/PatientInformation";
import PatientPriorityModal from "@src/components/Dashboard/waitlist/PatientPriorityModal";
import WaitlistColumn from "@src/components/Dashboard/waitlist/WaitlistColumn";
import { TooltipProvider } from "@src/components/ui/tooltip";
import useWaitlistWebSocketActions from "@src/hooks/waitlist/useWaitlistWebSocketActions";
import useWaitListStore from "@src/store/useWaitListStore";
import "@src/styles/waitlist.css";
import { PatientStatus } from "@src/types/wait-list";
import { QueueEntry } from "@src/types/waitlist/waitlist";
import { addSeconds, format, parse } from "date-fns";
import { debounce } from "lodash";
import React, { useState } from "react";
import WaitlistColumnCard from "./WaitlistColumnCard";

const WaitlistColumnContainer: React.FC<{ statuses: PatientStatus[] }> = ({
	statuses,
}) => {
	const { updateStatusOfPatientCard } = useWaitlistWebSocketActions();
	const patientsObject = useWaitListStore((s) => s.patientsObject);
	const setPatientsObject = useWaitListStore((s) => s.setPatientsObject);
	const [activeId, setActiveId] = useState<UniqueIdentifier>();
	const [modalPatientInformation, setModalPatientInformation] =
		React.useState<QueueEntry | undefined>();
	const [showPatientInformation, setShowPatientInformation] =
		React.useState(false);
	const [showPatientInformationState, setShowPatientInformationState] =
		React.useState<"intake" | "message" | "activity">();
	const [showDeleteCustomerModal, setShowDeleteCustomerModal] =
		React.useState(false);
	const [showChangePatientStationModal, setShowChangePatientStationModal] =
		React.useState(false);
	const [showPriorityModal, setShowPriorityModal] = React.useState(false);
	const [showChangePositionOrderModal, setShowChangePositionOrderModal] =
		React.useState(false);

	const [selectedPatient, setSelectedPatient] = useState<
		QueueEntry | undefined
	>();

	// Find the column that contains a specific item
	const findColumn = (unique) => {
		console.log(unique);
		if (!unique) return null;
		for (const column in patientsObject) {
			if (patientsObject[column].some((card) => card.id === unique)) {
				// console.log(patientsObject[column]);
				return column;
			}
		}
		return null;
	};

	// Handle the start of a drag action
	const onDragStart = React.useCallback(
		(event) => {
			const { active } = event;
			console.log(event);

			const activeId = active.id;

			// Find the selected patient from patientsObject
			const selectedPatient = Object.keys(patientsObject).reduce(
				(acc, key) => {
					const patient = patientsObject[key].find(
						(item) => item.id === activeId
					);
					return patient ? patient : acc;
				},
				undefined
			);

			// Set the selected patient and active ID
			setSelectedPatient(selectedPatient);
			setActiveId(activeId);
		},
		[patientsObject]
	);

	// Handle the drag over action
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const onDragOver = debounce((event) => {
		// NOTE: I have commented out the setPatientsObject for ondragover because it is not needed atm

		const { active, over, delta } = event;
		const activeId = active.id;
		const overId = over ? over.id : null;

		// Find the columns the items belong to
		const activeColumn = findColumn(activeId);
		const overColumn = findColumn(overId);

		// If activeColumn or overColumn is not found, or they are the same, do nothing
		if (
			!activeColumn ||
			!overColumn ||
			patientsObject[activeColumn] === patientsObject[overColumn]
		) {
			return;
		}

		const activeItems = patientsObject[activeColumn];
		const overItems = patientsObject[overColumn];
		const activeIndex = activeItems.findIndex((i) => i.id === activeId);
		const overIndex = overItems.findIndex((i) => i.id === overId);

		// Determine where to insert the active item in the overColumn
		const newIndex = () => {
			const putOnBelowLastItem =
				overIndex === overItems.length - 1 && delta.y > 0;
			const modifier = putOnBelowLastItem ? 1 : 0;
			return overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
		};

		// console.log(activeColumn, overColumn);

		// Update the state with the new order
		setPatientsObject({
			...patientsObject,
			[activeColumn]: activeItems.filter((i) => i.id !== activeId),
			[overColumn]: [
				...overItems.slice(0, newIndex()),
				activeItems[activeIndex],
				...overItems.slice(newIndex(), overItems.length),
			],
		});
	}, 200);

	// Helper function to add or subtract seconds from a timestamp string
	const addSecondsToString = (
		dateTimeString: string,
		seconds: number
	): string => {
		const date = parse(dateTimeString, "yyyy-MM-dd HH:mm:ss", new Date());
		const newDate = addSeconds(date, seconds);
		return format(newDate, "yyyy-MM-dd HH:mm:ss");
	};

	// Handle the end of a drag action
	const onDragEnd = React.useCallback(
		(event: DragEndEvent) => {
			const { active, over } = event;
			if (!over) return;

			const activeId = active.id;
			const overId = over.id;

			// Handle the end of a drag action
			const calculateNewOrderAt = (
				prevOrderAt: string | undefined,
				nextOrderAt: string | undefined
			): string => {
				if (!prevOrderAt && !nextOrderAt) {
					// No surrounding items, return current timestamp
					return format(new Date(), "yyyy-MM-dd HH:mm:ss");
				} else if (!prevOrderAt) {
					// Only next item exists, subtract a few seconds
					return addSecondsToString(nextOrderAt ?? "", -10);
				} else if (!nextOrderAt) {
					// Only previous item exists, add a few seconds
					return addSecondsToString(prevOrderAt, 10);
				} else {
					// Both items exist, find the midpoint
					const prevDate = parse(
						prevOrderAt,
						"yyyy-MM-dd HH:mm:ss",
						new Date()
					);
					const nextDate = parse(
						nextOrderAt,
						"yyyy-MM-dd HH:mm:ss",
						new Date()
					);
					const averageTime = new Date(
						(prevDate.getTime() + nextDate.getTime()) / 2
					);
					return format(averageTime, "yyyy-MM-dd HH:mm:ss");
				}
			};

			const activeColumn = Object.keys(patientsObject).find((column) =>
				patientsObject[column].some((item) => item.id === activeId)
			);

			const overColumn = Object.keys(patientsObject).find(
				(column) =>
					patientsObject[column].some((item) => item.id === overId) ||
					column === overId
			);

			// console.log(activeColumn, overColumn);

			if (!activeColumn || !overColumn) return;

			const activeItems = patientsObject[activeColumn];
			const overItems = patientsObject[overColumn];
			const activeItem = activeItems.find((item) => item.id === activeId);

			if (activeColumn !== overColumn) {
				// Move to a different column
				const prevItem = overItems[overItems.length - 1];
				const nextItem = overItems[0];
				const newOrderAt = calculateNewOrderAt(
					prevItem?.order_at,
					nextItem?.order_at
				);

				setPatientsObject({
					...patientsObject,
					[activeColumn]: activeItems.filter(
						(item) => item.id !== activeId
					),
					[overColumn]: [
						{ ...activeItem, order_at: newOrderAt },
						...overItems,
					],
				});
				if (selectedPatient && over.id !== selectedPatient.id)
					updateStatusOfPatientCard(overColumn, {
						...activeItem,
						order_at: newOrderAt,
					});
			} else {
				// Move within the same column
				const oldIndex = activeItems.findIndex(
					(item) => item.id === activeId
				);
				const newIndex = overItems.findIndex(
					(item) => item.id === overId
				);
				const reorderedItems = arrayMove(
					activeItems,
					oldIndex,
					newIndex
				) as QueueEntry[];

				const prevItem = reorderedItems[newIndex - 1] as QueueEntry;
				const nextItem = reorderedItems[newIndex + 1] as QueueEntry;
				const newOrderAt = calculateNewOrderAt(
					prevItem?.order_at,
					nextItem?.order_at
				);

				setPatientsObject({
					...patientsObject,
					[activeColumn]: reorderedItems.map((item, idx) =>
						idx === newIndex
							? { ...item, order_at: newOrderAt }
							: item
					),
				});
				if (selectedPatient && over.id !== selectedPatient.id)
					updateStatusOfPatientCard(
						overColumn,
						selectedPatient,
						newOrderAt
					);
				// console.log(newOrderAt);
			}
			setActiveId(undefined);
			setSelectedPatient(undefined);
		},
		[
			patientsObject,
			selectedPatient,
			setPatientsObject,
			updateStatusOfPatientCard,
		]
	);
	// console.log(1)
	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 8,
			},
		}),
		useSensor(TouchSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	// const queryClient = useQueryClient();

	return (
		<section className="relative mb-2 mt-4 flex flex-1">
			<div className="flex flex-1 gap-4 overflow-x-scroll">
				<DndContext
					sensors={sensors}
					collisionDetection={closestCorners}
					onDragEnd={onDragEnd}
					// onDragOver={onDragOver} // This is the original onDragOver
					onDragStart={onDragStart}
					// onDragMove={onDragMove}
				>
					{statuses.map((status, index) => (
						<WaitlistColumn
							key={index}
							status={status as PatientStatus}
							statuses={statuses}
							activeId={activeId}
							setActiveId={setActiveId}
							setShowPatientInformation={
								setShowPatientInformation
							}
							setModalPatientInformation={
								setModalPatientInformation
							}
							setShowDeleteCustomerModal={
								setShowDeleteCustomerModal
							}
							setShowChangePatientStationModal={
								setShowChangePatientStationModal
							}
							setShowPriorityModal={setShowPriorityModal}
							setShowChangePositionOrderModal={
								setShowChangePositionOrderModal
							}
							setShowPatientInformationState={
								setShowPatientInformationState
							}
						/>
					))}
					<DragOverlay>
						<TooltipProvider>
							{activeId && selectedPatient ? (
								<div className="bending-card">
									<WaitlistColumnCard
										noOfPatients={0}
										status={
											findColumn(
												selectedPatient.id
											) as PatientStatus
										}
										index={0}
										patient={selectedPatient}
										setShowPatientInformation={
											setShowPatientInformation
										}
										setModalPatientInformation={
											setModalPatientInformation
										}
										setShowDeleteCustomerModal={
											setShowDeleteCustomerModal
										}
										setShowChangePatientStationModal={
											setShowChangePatientStationModal
										}
										setShowPriorityModal={
											setShowPriorityModal
										}
										setShowChangePositionOrderModal={
											setShowChangePositionOrderModal
										}
										setShowPatientInformationState={
											setShowPatientInformationState
										}
									/>
								</div>
							) : null}
						</TooltipProvider>
					</DragOverlay>
				</DndContext>
			</div>

			<PatientInformation
				patientDetails={modalPatientInformation}
				showPatientInformation={showPatientInformation}
				setShowPatientInformation={setShowPatientInformation}
				showPatientInformationState={showPatientInformationState}
			/>
			<DeletePatient
				patientDetails={modalPatientInformation}
				showDeleteCustomerModal={showDeleteCustomerModal}
				setShowDeleteCustomerModal={setShowDeleteCustomerModal}
			/>
			<ChangeStation
				patientDetails={modalPatientInformation}
				showChangePatientStationModal={showChangePatientStationModal}
				setShowChangePatientStationModal={
					setShowChangePatientStationModal
				}
			/>
			<PatientPriorityModal
				patientDetails={modalPatientInformation}
				showPriorityModal={showPriorityModal}
				setShowPriorityModal={setShowPriorityModal}
			/>
			<ChangePositionOrderModal
				patientDetails={modalPatientInformation}
				showChangePositionOrderModal={showChangePositionOrderModal}
				setShowChangePositionOrderModal={
					setShowChangePositionOrderModal
				}
			/>
		</section>
	);
};

export default WaitlistColumnContainer;
