/* eslint-disable @typescript-eslint/no-explicit-any */

import { cn } from "@/src/utils/functions";
import Select, { Props as SelectProps } from "react-select";

const CustomSelect = ({
	placeholder,
	className,
	height,
	focuscolor = "#9CBDF9",
	bordercolor = "#E5E5E7",
	value,
	...props
}: SelectProps & {
	height?: string;
	focuscolor?: string;
	bordercolor?: string;
}) => {
	const customSelectStyles = {
		control: (defaultStyles: any) => ({
			...defaultStyles,
			"&:active": { borderColor: "inherit" },
			"&:focus": { borderColor: focuscolor },
			borderColor: bordercolor,
			borderRadius: "8px",
			borderWidth: "0.5px",
		}),
		menu: (provided: any) => ({
			...provided,
			backgroundColor: "#fff",
			zIndex: 9999,
			borderRadius: "8px",
			color: "#323539",
		}),
		placeholder: (base: any) => ({
			...base,
			fontWeight: 400,
			fontSize: "14px",
			color: "#858C95",
		}),
		input: (provided: any) => ({
			...provided,
			caretColor: "transparent",
			border: "none",
			fontSize: "15px",
			height: height,
			borderWidth: "0.5px",
			// "&:focus": {
			// 	outline: "transparent",
			// },
		}),
	};
	return (
		<div className={cn("", className)}>
			<Select
				// defaultValue={value}
				value={value}
				styles={customSelectStyles}
				className={cn("text-[14px]")}
				placeholder={placeholder}
				{...props}
			/>
		</div>
	);
};

export default CustomSelect;
