import React, { useState } from "react";
import { MdMoreVert } from "react-icons/md";
import { cn } from "@utils/functions";
import moment from "moment";

import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@components/ui/popover";
import DetailsPane from "../../DetailsPane";
import { QueueEntry } from "@src/types/waitlist/waitlist";
import PatientInformation from "@src/components/Dashboard/waitlist/PatientInformation/PatientInformation";
import useCustomToast from "@src/components/CustomToast";
import useUserStore from "@src/store/useUserStore";
import { UpdateAppointment } from "@src/store/slices/schedule/scheduleSlice";

const AppointmentBlock = ({
	appointment,
	// appointments,
	viewType,
}: {
	viewType: "Day" | "Week" | "Month";
	appointment?: any;
}) => {
	const [action, setAction] = useState<
		| "done"
		| "cancel"
		| "reschedule"
		| "follow_up"
		| "approve"
		| "serve"
		| "remind"
	>("approve");
	const { start, customer } = appointment || {};
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const [showPatientInformation, setShowPatientInformation] =
		React.useState(false);
	const handleShowPatientDetails = () => {
		setIsPopoverOpen(false);
		setShowPatientInformation(!showPatientInformation);
	};
	const handleOpenPopover = () => {
		setIsPopoverOpen(!isPopoverOpen);
	};
	const renderPopover = () => (
		<Popover
			onOpenChange={() => setIsPopoverOpen(!isPopoverOpen)}
			open={isPopoverOpen}
		>
			<PopoverTrigger asChild>
				<button>
					<MdMoreVert className="text-sm" />
				</button>
			</PopoverTrigger>
			<PopoverContent className="w-fit bg-[#F8F9FB] px-4 py-3 pr-8">
				<DetailsPane
					status={appointment?.status}
					onApprove={handleApproveAppointment}
					onCancel={handleCancelAppointment}
					onClick={handleShowPatientDetails}
				/>
			</PopoverContent>
		</Popover>
	);

	const appointment_id = appointment?.id;
	const location_id = useUserStore((s) => s.currentLocationId);
	const toast = useCustomToast();
	const onSuccess = (response) => {
		toast(`${response?.data?.message || "Request was successful"}`, {
			type: "success",
			id: action,
		});
	};

	const onError = (error) => {
		toast(`${error?.response?.data?.message ?? "An Error Occured"}`, {
			type: "error",
			id: action,
		});
	};

	const { mutate } = UpdateAppointment(
		location_id,
		appointment_id,
		onSuccess,
		onError
	);
	const handleCancelAppointment = () => {
		setAction("cancel");
		toast("Cancelling Appointment", {
			type: "loading",
			id: action,
		});
		mutate({ action: "cancel" });
	};

	const handleApproveAppointment = () => {
		setAction("approve");
		toast("Approving Appointment", {
			type: "loading",
			id: action,
		});
		mutate({ action: "approve" });
	};
	return (
		<div
			className={cn(
				"relative flex h-full items-start rounded-[8px] border bg-[#EFF2F5] font-inter text-black",
				{
					" rounded-sm py-0.5":
						viewType === "Month" || viewType === "Week",
					"bg-[#FFF5F5]":
						appointment?.status === "waiting_to_be_approved",
				}
			)}
		>
			{viewType === "Day" && (
				<>
					<div
						className="my-auto flex w-full flex-row items-center justify-between px-2"
						onDoubleClick={handleOpenPopover}
					>
						<div className="flex w-full items-center gap-3  self-center text-[#323539]">
							<p className="truncate text-xs font-medium ">
								{customer?.full_name}
							</p>
							<p className="text-[10px] font-[400]">{`${moment(start).format("h:mm A")}`}</p>
						</div>
						{renderPopover()}
					</div>
				</>
			)}
			{viewType === "Week" && (
				<div
					className="my-auto  flex w-full flex-row items-center justify-between px-2"
					onDoubleClick={handleOpenPopover}
				>
					<div className="flex w-full  items-center  gap-3 truncate text-[#323539]">
						<p className=" text-xs font-medium ">
							{customer?.full_name}
						</p>
					</div>
					{renderPopover()}
				</div>
			)}
			{viewType === "Month" && (
				<div
					className="my-auto  flex w-full flex-row items-center justify-between px-2"
					onDoubleClick={handleOpenPopover}
				>
					<div className="flex w-full  items-center  gap-3 truncate text-[#323539]">
						<p className=" text-xs font-medium ">
							{customer?.full_name}
						</p>
					</div>
					{renderPopover()}
				</div>
			)}
			{/* for day view  */}

			{/* <Dialog
				open={showPatientDetails}
				onOpenChange={setShowPatientDetails}
			>
				<DialogContent className="min-h-[551px] min-w-[860px] space-y-4">
					<PatientDetails />
				</DialogContent>
			</Dialog> */}
			<PatientInformation
				patientDetails={appointment as QueueEntry}
				showPatientInformation={showPatientInformation}
				setShowPatientInformation={setShowPatientInformation}
			/>
			{/* <PatientDetails
				show={showPatientDetails}
				setShow={setShowPatientDetails}
			/> */}
		</div>
	);
};

export default AppointmentBlock;
