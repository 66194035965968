import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "@components/ui/button";
import { CustomSelect } from "@components/form/CustomSelect";
import FormInput from "@components/form/FormInput";
import { useFormContext, Controller } from "react-hook-form";
import { HiOutlineCloudUpload } from "react-icons/hi";
import { CustomImage } from "@components/common/CustomImage";
import { countryOptions } from "@utils/constants";
import useUserStore from "@store/useUserStore";
import { getErrorMessage } from "@utils/functions";
import { updateCountryAndState, changeCountry } from "../components";
import AddressComponent, {
	findCountryByLabel,
	findProvinceByLabel,
} from "@src/components/Onboarding/AddressComponent";
import useCustomToast from "@src/components/CustomToast";

const acceptedFormats = ["jpg", "jpeg", "png", "gif", "svg"];

export default function LocationInfo() {
	const fileInputRef = useRef<HTMLInputElement>(null);
	const {
		register,
		watch,
		setValue,
		getValues,
		trigger,
		control,
		formState: { errors },
	} = useFormContext();

	const user = useUserStore((s) => s.user);
	const [countryCode, setCountryCode] = useState("+1");
	const [previewUrl, setPreviewUrl] = useState<string | null>(null);
	const [provinceOptions, setProvinceOptions] = useState<
		{
			label: string;
			value: string;
		}[]
	>([]);

	const customToast = useCustomToast();

	const updateBusinessCheckThenUpdateFields = () => {
		if (user?.business) {
			setValue("address", user?.business.address ?? "");
			const countryValue = findCountryByLabel(
				user?.business?.country ?? ""
			);
			const newProvinceOptions = changeCountry(countryValue);

			const newProvince = findProvinceByLabel(
				newProvinceOptions,
				user?.business.state ?? ""
			);

			updateCountryAndState(
				setValue,
				setProvinceOptions,
				true,
				newProvince,
				countryValue
			);

			setValue("city", user?.business.city ?? "");
			setValue(
				"phone_number",
				user?.business.phone_number?.slice(-10) ?? ""
			);
			setCountryCode(user?.business.phone_number?.slice(0, -10) ?? "+1");
			trigger();
		} else {
			updateCountryAndState(
				setValue,
				setProvinceOptions,
				true,
				"ON",
				"CA"
			);
		}
	};

	useEffect(() => {
		updateBusinessCheckThenUpdateFields();
	}, []);

	const handleImageChange = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		if (event && event.target) {
			const files: FileList | null = event.target.files;
			if (files && files.length > 0) {
				const file = files[0];
				const fileFormat = file.type.split("/")[1];
				const fileSize = files[0].size;
				if (fileSize > 2097152) {
					customToast("Max file size is 2MB", {
						id: "logo-file-upload",
						type: "error",
					});
					return;
				}
				if (!acceptedFormats.includes(fileFormat)) {
					customToast(
						(fileFormat ?? file.name) + " is not supported",
						{
							id: "logo-file-upload",
							type: "error",
						}
					);
					return;
				}
				setValue("image", file);
				setPreviewUrl(URL.createObjectURL(file));
			}
		}
	};

	const handleButtonClick = () => {
		fileInputRef.current?.click();
	};

	const imageFile = watch("image");
	useEffect(() => {
		setPreviewUrl(imageFile);
	}, []);

	return (
		<div className=" space-y-4">
			<h3 className="text-xl font-medium">Location information</h3>
			<div className="flex items-center space-x-4">
				<CustomImage
					src={previewUrl}
					alt="location"
					imageClass="aspect-square w-[112px] rounded-full border bg-slate-50 max-h-[112px] min-h-[112px]"
				/>
				<div>
					<input
						type="file"
						accept="image/jpeg,image/png"
						{...register("image", {
							onChange: (e) => handleImageChange(e),
						})}
						ref={fileInputRef}
						className="hidden"
						id="image-upload"
					/>
					<Button
						type="button"
						variant={"outline"}
						className="mb-2 border border-primary font-semibold text-primary"
						onClick={handleButtonClick}
					>
						<HiOutlineCloudUpload className="mr-2 text-lg" />
						Replace Image
					</Button>

					<p className="font-medium text-[#6B7280]">
						File must be a JPG or PNG. Max size of 5MB
					</p>
					{getErrorMessage(errors.name?.message) &&
						getErrorMessage(errors.name?.message)?.length && (
							<small className="mt-1.5 text-sm text-red-500">
								{getErrorMessage(errors.image?.message)}
							</small>
						)}
				</div>
			</div>
			<div className="grid grid-cols-6 gap-4">
				<div className="col-span-6">
					<Controller
						name={"name"}
						control={control}
						render={({ field }) => (
							<FormInput
								inputType={"text"}
								label="Location Name"
								value={field?.value}
								register={{ ...register("name") }}
								required
								className="h-10"
								error={getErrorMessage(errors.name?.message)}
								maxLength={40}
							/>
						)}
					/>
				</div>
				<div className="col-span-3">
					<FormInput
						inputType={"text"}
						label="Email Address "
						register={{ ...register("email") }}
						className="h-10"
						error={getErrorMessage(errors.email?.message)}
						maxLength={40}
					/>
				</div>
				<div className="col-span-3">
					<FormInput
						inputType={"text"}
						label="Phone Number  "
						register={{ ...register("phone_number") }}
						className="h-10"
						error={getErrorMessage(errors.phone_number?.message)}
						maxLength={14}
					/>
				</div>

				<div className="col-span-6 flex w-full">
					<AddressComponent
						setFormValue={setValue}
						setProvinceOptions={setProvinceOptions}
						formTrigger={trigger}
						errorMessage={errors.address?.message}
						buttonClassName="w-full max-w-full"
					/>
				</div>

				<div className="col-span-2">
					<label className="text-sm font-medium tracking-[-0.1px] text-[#323539]">
						Country {<span className="text-red-600">*</span>}
					</label>
					<CustomSelect
						placeholder="Select"
						label="Country  *"
						className="mt-1"
						options={countryOptions}
						value={watch("country")}
						onChange={(value) => {
							updateCountryAndState(
								setValue,
								setProvinceOptions,
								false,
								undefined,
								value
							);
						}}
						error={getErrorMessage(errors.country?.message)}
					/>
				</div>
				<div className="col-span-2">
					<label className="mb-3 text-sm font-medium tracking-[-0.1px] text-[#323539]">
						Province {<span className="text-red-600">*</span>}
					</label>
					<CustomSelect
						placeholder="Select"
						label="Province  *"
						className="mt-1"
						options={provinceOptions}
						value={watch("state")}
						onChange={(value) => {
							updateCountryAndState(
								setValue,
								setProvinceOptions,
								false,
								value,
								getValues("country")
							);
						}}
						error={getErrorMessage(errors.state?.message)}
					/>
				</div>
				<div className="col-span-2">
					<FormInput
						inputType={"text"}
						label="City"
						register={{ ...register("city") }}
						className="h-10"
						error={getErrorMessage(errors.city?.message)}
						required
					/>
				</div>
				<div className="col-span-6">
					<FormInput
						label="Location Description"
						register={{ ...register("description") }}
						error={getErrorMessage(errors.description?.message)}
						className="h-[124px] min-h-[75px]"
						minLength={5}
						maxLength={1024}
						textarea
					/>
				</div>
			</div>
		</div>
	);
}
