import { QueueEntry } from "@type/waitlist/waitlist";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { StatusOfPatientType } from "../types/wait-list";

interface WaitListStore {
	patients: QueueEntry[];
	schedule: QueueEntry[];
	totalSchedule: QueueEntry[];
	patientsObject: {
		"Pending Approval": QueueEntry[];
		Confirmed: QueueEntry[];
		"Next to Serve": QueueEntry[];
		Serving: QueueEntry[];
		Scheduled: QueueEntry[];
	};
	currentStatus?: StatusOfPatientType;
	currentJoinedFrom: "qr" | "url" | "all";
	setPatients: (patientObject: QueueEntry[]) => void;
	setSchedule: (patientObject: QueueEntry[]) => void;
	setTotalSchedule: (patientObject: QueueEntry[]) => void;
	setPatientsObject: (patientObject: {
		"Pending Approval": QueueEntry[];
		Confirmed: QueueEntry[];
		"Next to Serve": QueueEntry[];
		Serving: QueueEntry[];
		Scheduled: QueueEntry[];
	}) => void;
	setCurrentStatus: (newStatus?: StatusOfPatientType) => void;
	setCurrentJoinedFrom: (newJoinedFrom: "qr" | "url" | "all") => void;
}

const initialState = {
	patients: [],
	schedule: [],
	totalSchedule: [],
	patientsObject: {
		"Pending Approval": [],
		Confirmed: [],
		"Next to Serve": [],
		Serving: [],
		Scheduled: [],
	},
	currentStatus: undefined,
	currentJoinedFrom: "all" as "qr" | "url" | "all",
};

const useWaitListStore = create<
	WaitListStore,
	[["zustand/persist", WaitListStore]]
>(
	persist(
		(set) => ({
			...initialState,
			setPatients: (patientObject: QueueEntry[]) =>
				set(() => ({
					patients: [...patientObject],
				})),
			setSchedule: (schedule: QueueEntry[]) =>
				set(() => ({ schedule: [...schedule] })),
			setTotalSchedule: (totalSchedule: QueueEntry[]) =>
				set(() => ({ totalSchedule: [...totalSchedule] })),
			setPatientsObject: (
				patientsObject: WaitListStore["patientsObject"]
			) => set(() => ({ patientsObject })),

			setCurrentStatus: (currentStatus) => set(() => ({ currentStatus })),
			setCurrentJoinedFrom: (currentJoinedFrom) =>
				set(() => ({ currentJoinedFrom })),
		}),
		{
			name: "waitlist-storage",
			getStorage: () => localStorage,
		}
	)
);

export default useWaitListStore;
