import CustomSelect from "@components/common/CustomSelect";
import Checkbox from "@src/components/ui-extended/checkbox";
import InputIcon from "@src/components/ui-extended/input-icon";
import { Button } from "@components/ui/button";
import { Card } from "@components/ui/card";
import { Switch } from "@components/ui/switch";
import { LuMapPin, LuTrash2, LuChevronRight, LuInfo } from "react-icons/lu";
import { Badge } from "@components/ui/badge";
import { IoMdStopwatch } from "react-icons/io";
import { PiGear, PiQrCode } from "react-icons/pi";
import RatingComponent from "@components/common/RatingComponent";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import {
	GetLocationsSlice,
	DeleteLocationSlice,
	DeleteLocationsSlice,
} from "@src/store/slices/locations/locationSlice";
import useLocationsStore from "@src/store/useLocationsStore";
import RequestIsLoading from "@components/RequestIsLoading";
import { convertTimeFormat } from "@src/utils/functions";
import { DeleteLocationModal, LocationInfoModal } from "./components";
import useCustomToast from "@components/CustomToast";
import {
	LocationsIProps,
	LocationArray,
	AddBusinessLocationResponseType,
} from "@src/types/Location";
import { CustomImage } from "@components/common/CustomImage";
import {
	useForm,
	FormProvider,
	useFieldArray,
	Controller,
	useWatch,
} from "react-hook-form";
import { UpdateBusinessLocationSlice } from "@src/store/slices/locations/locationSlice";
import { useQueryClient } from "react-query";
import Header from "@src/layouts/Header/Header";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@src/components/ui/select";
import { sortByFilter } from "@src/utils/constants";

interface FormData {
	locations: LocationArray;
}

const DeleteContent = ({ displayString }: { displayString: string }) => {
	return (
		<p className="mt-2 text-sm leading-[20px] -tracking-[0.1px] text-[#6D748D]">
			Are you sure you want to delete{" "}
			<span className="font-semibold">&quot;{displayString}&quot;</span>
			{". "}This action cannot be undone.{" "}
			<span className="font-semibold">&quot;{displayString}&quot;</span>{" "}
			will no longer be visible to the system.
		</p>
	);
};

type SortCriteria = "asc" | "desc" | "date-created" | "last-updated";

const Locations: React.FC = () => {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { isLoading, invalidateQuery } = GetLocationsSlice();
	const customToast = useCustomToast();
	const locationsData = useLocationsStore((s) => s.locations);
	const [checkAllLocation, setCheckAllLocations] = useState(false);
	const [locationIds, setLocationIds] = useState<number[]>([]);
	const [showDeleteLocationModal, setShowDeleteLocationModal] =
		useState(false);
	const [showLocationInfoModal, setShowLocationInfoModal] = useState(false);
	const [selectedLocation, setSelectedLocation] =
		useState<LocationsIProps | null>(null);
	const { isLoading: deleteLoading, mutate: deleteMutation } =
		DeleteLocationSlice(selectedLocation?.id || 0, () => {
			setShowDeleteLocationModal(false);
			invalidateQuery();
		});

	const handleCheckAllLocations = () => {
		if (locationIds?.length === locationsData?.length) {
			setLocationIds([]);
			setCheckAllLocations(false);
		} else {
			setLocationIds(
				locationsData?.map((location: any) => location.id) || []
			);
			setCheckAllLocations(true);
		}
	};

	const updateBusinessLocationMutation = UpdateBusinessLocationSlice(
		(data) => {
			queryClient.setQueryData<AddBusinessLocationResponseType[]>(
				"locationDetails",
				(oldData) =>
					oldData?.map((location) =>
						location.location.id === data.location.id
							? {
									...location,
									is_active: data.location.is_active,
								}
							: location
					) ?? []
			);
		},
		(error) => console.log("🚀 ~ LocationTabList ~ error:", error)
	);

	const {
		isLoading: deleteCustomFieldLoading,
		mutate: deleteCustomFieldMutation,
	} = DeleteLocationsSlice(
		() => {
			invalidateQuery();
			setShowDeleteLocationModal(false);
		},
		() => null
	);

	const handleDeleteLocationModal = (location: any) => {
		setSelectedLocation(location);
		setShowDeleteLocationModal(true);
	};

	const handleShowLocationInfoModal = (location: any) => {
		setSelectedLocation(location);
		setShowLocationInfoModal(true);
	};

	const handleDeleteLocation = () => {
		if (locationIds.length > 0) {
			deleteCustomFieldMutation({ location_ids: locationIds });
		} else if (selectedLocation) {
			deleteMutation({ id: selectedLocation.id });
		}
	};

	const methods = useForm<FormData>({
		defaultValues: {
			locations: [],
		},
	});

	const { control, setValue } = methods;
	const { fields } = useFieldArray<FormData>({
		control,
		name: "locations",
		keyName: "_id" as "id",
	});

	const watchLocations = useWatch({
		control,
		name: "locations",
	});

	const [filteredData, setFilteredData] = useState<any[]>([]);
	const [searchTerm, setSearchTerm] = useState<string>("");

	const sortLocations = (
		locations: LocationArray,
		criteria: SortCriteria
	): LocationArray => {
		return [...locations].sort((a, b) => {
			switch (criteria) {
				case "asc":
					return a.name.localeCompare(b.name);
				case "desc":
					return b.name.localeCompare(a.name);
				case "date-created":
					return (
						new Date(a.created_at).getTime() -
						new Date(b.created_at).getTime()
					);
				case "last-updated":
					return (
						new Date(a.updated_at).getTime() -
						new Date(b.updated_at).getTime()
					);
				default:
					return 0;
			}
		});
	};

	const [selectedSortBy, setSelectedSortBy] = useState<SortCriteria>("asc");

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		const term = event.target.value;
		setSearchTerm(term);
		const filteredLocations = fields.filter((location: any) =>
			location.name.toLowerCase().includes(term.toLowerCase())
		);
		const sortedLocations = sortLocations(
			filteredLocations as LocationArray,
			selectedSortBy
		);
		setFilteredData(sortedLocations);
	};

	const handleSort = (sortOrder: SortCriteria) => {
		setSelectedSortBy(sortOrder);
		const sortedLocations = sortLocations(
			fields as LocationArray,
			sortOrder
		);
		setFilteredData(sortedLocations);
	};

	const handleSwitchChange = useCallback(
		async (locationId: number, value: boolean, index: number) => {
			const isActive = value ? 1 : 0;
			const newFormData = new FormData();
			newFormData.append("is_active", isActive.toString());

			try {
				customToast("Updating location", {
					id: "update-location",
					type: "loading",
				});

				setValue(`locations.${index}.is_active`, isActive);

				const result = await updateBusinessLocationMutation.mutateAsync(
					{
						id: locationId,
						data: newFormData,
					}
				);

				const serverIsActive = result.location.is_active ? 1 : 0;
				setValue(`locations.${index}.is_active`, serverIsActive);

				customToast("Location updated successfully", {
					id: "update-location",
					type: "success",
				});
			} catch (error) {
				setValue(`locations.${index}.is_active`, !isActive ? 1 : 0);
			}
		},
		[updateBusinessLocationMutation, setValue, customToast]
	);

	useEffect(() => {
		if (locationsData) {
			methods.reset({ locations: locationsData });
		}
	}, [locationsData, methods]);

	useEffect(() => {
		setFilteredData(fields);
	}, [fields]);

	return (
		<main className="flex flex-1 flex-col">
			<Header
				title={"Locations"}
				showDate={false}
				showLocationStationCount
			/>
			<RequestIsLoading isLoading={isLoading} />
			<div className="flex flex-1 flex-col overflow-auto">
				<section className="mt-6 flex flex-1 flex-col space-y-4">
					<div className="flex items-center space-x-4">
						<div className="flex w-full items-center">
							<InputIcon
								placeholder="Search"
								className=" w-full placeholder:text-[#858C95]"
								outerClassName="w-full shadow-custom-small border-main-3"
								icon="mgc_search_line"
								onChange={handleSearch}
							/>
						</div>
						<div className="flex w-full basis-1/3 items-center space-x-2">
							<div className="w-full flex-1">
								<Select
									onValueChange={(value) =>
										handleSort(value as SortCriteria)
									}
								>
									<SelectTrigger className="h-9 flex-1">
										<SelectValue placeholder="Sort By" />
									</SelectTrigger>
									<SelectContent>
										{sortByFilter.map((sort, i) => (
											<SelectItem
												key={i}
												value={sort.value}
											>
												{sort.label}
											</SelectItem>
										))}
									</SelectContent>
								</Select>
							</div>
							<Button
								className="max-w-[140px] flex-1 self-center rounded-md "
								type="submit"
								onClick={() => {
									navigate(
										"/dashboard/locations/add-location"
									);
								}}
							>
								Add Location
							</Button>
						</div>
					</div>

					<div className="flex items-center justify-between">
						<div className="flex items-center space-x-2">
							<Checkbox
								isChecked={checkAllLocation}
								handleCheckboxChange={handleCheckAllLocations}
								className="rounded-sm border-2 border-[#D1D1D1]"
								id={"checkbox-all"}
							/>
							<label
								htmlFor="checkbox-all"
								className="font-medium"
							>
								Select all
							</label>
						</div>
						<div className="flex items-center space-x-2.5">
							<label className="font-regular text-[#7D7E80]">
								Deactivate Selected
							</label>
							<Switch
								checked={false}
								id="queue-on-off"
								className="scale-[0.70] data-[state=checked]:bg-[#2EBF43]"
							/>
							<p>No</p>
							<Button
								variant="secondary"
								size="icon-sm"
								disabled={!locationIds.length}
								onClick={() => handleDeleteLocationModal("")}
							>
								<LuTrash2 size={18} className=" text-black" />
							</Button>
						</div>
					</div>
					<div>
						<FormProvider {...methods}>
							<form>
								<div className="flex flex-col space-y-4">
									{!isLoading &&
										filteredData.map(
											(location: any, index: number) => (
												<Card
													key={location.id}
													className="flex items-center justify-between space-x-3 bg-neutral p-4 shadow-custom-medium"
												>
													<div className="flex flex-1 items-center space-x-3">
														<Checkbox
															isChecked={locationIds.includes(
																location?.id
															)}
															handleCheckboxChange={() => {
																setLocationIds(
																	(prev) => {
																		if (
																			prev.includes(
																				location?.id
																			)
																		) {
																			return prev.filter(
																				(
																					id
																				) =>
																					id !==
																					location.id
																			);
																		} else {
																			return [
																				...prev,
																				location.id,
																			];
																		}
																	}
																);
															}}
															id={
																"location-" +
																location?.id
															}
															className="rounded-sm border-2 border-[#D1D1D1]"
														/>
														<CustomImage
															src={
																location?.image_url
															}
															alt="location"
															imageClass="aspect-square w-[64px] overflow-hidden max-h-[64px] min-h-[64px]"
														/>
														<div className="flex-1 space-y-2">
															<div className="flex w-full items-center space-x-3">
																<p className="line-clamp-1 font-semibold">
																	{
																		location?.name
																	}
																</p>
																<Button
																	type="button"
																	variant="secondary"
																	size="xs"
																	onClick={() =>
																		handleShowLocationInfoModal(
																			location
																		)
																	}
																>
																	<LuInfo
																		size={
																			12
																		}
																		className=" text-black"
																	/>
																</Button>
															</div>
															<div className="flex items-center space-x-1 text-gray-400">
																<LuMapPin
																	size={12}
																/>
																<p className="line-clamp-1 text-xs">
																	{location?.address ||
																		"N/A"}
																</p>
															</div>
															<div className="">
																<RatingComponent
																	rating={3}
																	readOnly
																/>
															</div>
														</div>
													</div>
													<div className="flex-1 space-x-3">
														<Badge
															className="bg-neutral-600 text-sm"
															variant={
																"secondary"
															}
														>
															<i className="mgc_shop_line before-text-gray-4 mr-2 text-[18px]" />
															<span className=" mr-1 font-normal">
																Stations
															</span>{" "}
															{
																location
																	?.stations
																	.length
															}
														</Badge>
														<Badge
															className="bg-neutral-600 text-sm"
															variant={
																"secondary"
															}
														>
															<IoMdStopwatch
																className="mr-2"
																size={14}
															/>
															<span className="mr-1 font-normal">
																Avg. Wait Time
															</span>{" "}
															{convertTimeFormat(
																location?.approximate_waiting_time ||
																	""
															)}
														</Badge>
													</div>

													<div className="flex flex-1 items-center justify-end space-x-4">
														<div className="flex items-center space-x-2">
															<p>
																Location Active
															</p>
															<Controller
																name={`locations.${location?.is_active}`}
																control={
																	control
																}
																render={({
																	field,
																}) => (
																	<Switch
																		ref={
																			field.ref
																		}
																		checked={Boolean(
																			watchLocations[
																				index
																			]
																				?.is_active
																		)}
																		className="scale-[0.70] data-[state=checked]:bg-[#2EBF43]"
																		onCheckedChange={(
																			value
																		) =>
																			handleSwitchChange(
																				location?.id,
																				value,
																				index
																			)
																		}
																	/>
																)}
															/>
															<span>
																{location?.is_active ===
																1
																	? "Yes"
																	: "No"}
															</span>
														</div>

														<Button
															variant="secondary"
															size="icon-sm"
															type="button"
															onClick={() => {
																navigate(
																	`/dashboard/locations/edit-location?locationId=${location?.id}`
																);
															}}
														>
															<PiGear
																size={18}
																className=" text-black"
															/>
														</Button>
														<Button
															variant="secondary"
															size="icon-sm"
															type="button"
															onClick={() => {
																handleShowLocationInfoModal(
																	location
																);
															}}
														>
															<PiQrCode
																size={18}
																className=" text-black"
															/>
														</Button>
														<Button
															variant="secondary"
															size="icon-sm"
															type="button"
															onClick={() =>
																handleDeleteLocationModal(
																	location
																)
															}
														>
															<LuTrash2
																size={18}
																className=" text-black"
															/>
														</Button>
														<Button
															variant="ghost"
															size="icon-sm"
															type="button"
															onClick={() =>
																navigate(
																	`/dashboard/locations/details?locationId=${location?.id}`
																)
															}
														>
															<LuChevronRight
																size={18}
																className=" text-black"
															/>
														</Button>
													</div>
												</Card>
											)
										)}
								</div>
							</form>
						</FormProvider>
					</div>
					<DeleteLocationModal
						locationName={
							selectedLocation?.name || "selected locations"
						}
						showModal={showDeleteLocationModal}
						setShowModal={setShowDeleteLocationModal}
						isLoading={deleteLoading || deleteCustomFieldLoading}
						buttonAction={handleDeleteLocation}
						DeleteContent={DeleteContent}
					/>
					<LocationInfoModal
						location={selectedLocation}
						showModal={showLocationInfoModal}
						setShowModal={setShowLocationInfoModal}
						isLoading={deleteLoading}
						buttonAction={() =>
							navigate(
								`/dashboard/locations/edit-location?locationId=${selectedLocation?.id}`
							)
						}
					/>
				</section>
			</div>
		</main>
	);
};

export default Locations;
