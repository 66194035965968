import useCustomToast from "@src/components/CustomToast";
import { LoaderButton } from "@src/components/form/LoaderButton";
import { Input } from "@src/components/ui/input";
import { Label } from "@src/components/ui/label";
import { TabsContent } from "@src/components/ui/tabs";
import { UpdateThemeSettings } from "@src/store/slices/settings/themeSettingsSlice";
import useUserStore from "@src/store/useUserStore";
import { AvailableThemes } from "@src/utils/constants";
import { changeTheme } from "@src/utils/functions";
import React from "react";

const ThemeSettings: React.FC = () => {
	const user = useUserStore((s) => s.user);
	const setUser = useUserStore((s) => s.setUser);
	const customToast = useCustomToast();
	const [theme, setTheme] = React.useState(
		user?.business.theme === "default"
			? "#005893"
			: (user?.business.theme ?? "#005893")
	);
	const selectedThemeBackground = `bg-[${theme}]`;

	const updateThemeMutation = UpdateThemeSettings(() => {
		setTheme(changeTheme(theme));
		if (user) setUser({ ...user, theme });
		customToast("Theme updated successfully 🎉", {
			id: "update-theme",
			type: "success",
		});
	});

	const handleUpdateTheme = async () => {
		updateThemeMutation.mutate({
			theme,
		});
	};

	return (
		<TabsContent
			value="theme"
			className="mt-0 flex w-full justify-between space-x-10 data-[state=inactive]:hidden data-[state=active]:flex-1"
		>
			<div className="space-y-3">
				<h2 className="py-3 text-[22px] font-semibold leading-[24px] tracking-[-1%] text-[#323539]">
					Theme
				</h2>
				<div className="flex flex-wrap gap-x-4 gap-y-6">
					{AvailableThemes.map((theme, index) => (
						<button
							key={index}
							onClick={() => setTheme(theme)}
							className="h-[73px] w-[73px] rounded-lg border-4  border-white shadow-md"
							style={{ background: theme }}
						/>
					))}
				</div>
			</div>
			<div className="flex flex-col space-y-3">
				<h2 className="py-3 text-[22px] font-semibold leading-[24px] tracking-[-1%] text-[#323539]">
					Selected
				</h2>
				<figure
					className={`mx-auto h-[321px] w-[321px] rounded-[32px] border-8 border-white duration-200 ease-in-out ${selectedThemeBackground} shadow-[0px_0px_10px_2px_rgba(0,0,0,0.15)]`}
					style={{ background: theme }}
				/>
				<form onSubmit={handleUpdateTheme}>
					<Label>
						Color Code{" "}
						<small className="text-xxs">(# for hex values)</small>
					</Label>
					<Input
						className="border border-[#E4E4E7] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
						value={theme}
						onChange={(e) => setTheme(e.target.value)}
					/>
				</form>
				<LoaderButton
					variant="default"
					disabled={
						theme === user?.business.theme ||
						updateThemeMutation.isLoading
					}
					onClick={handleUpdateTheme}
					loading={updateThemeMutation.isLoading}
					loaderSize={20}
					className="w-fit px-4 py-2 text-white"
				>
					Save
				</LoaderButton>
			</div>
		</TabsContent>
	);
};

export default ThemeSettings;
