import CustomCheckbox2 from "@src/components/form/CustomCheckbox2";
import { Button } from "@src/components/ui/button";
import { Card } from "@src/components/ui/card";
import { useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import General from "./General";
import ScheduleOptimizer from "./ScheduleOptimizer";
import OpreatingTime from "./OperatingTime";
import CustomTimeSlot from "./CustomTimeSlot";
import CustomFieldSettings from "../CustomFieldSettings";
import { ScrollArea } from "@src/components/ui/scroll-area";
import { cn, createNestedObject } from "@src/utils/functions";
import useCustomToast from "@components/CustomToast";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@src/components/ui/accordion";
import { CgTikcode } from "react-icons/cg";
import { Switch } from "@components/ui/switch";
import {
	GetQueueSettingsSlice,
	QueueSettingsSlice,
} from "@src/store/slices/locations/locationSlice";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	StationsProps,
	queueSettingsProps,
	queueSettingsSchema,
} from "@src/types/Location";
import LinkedCalendar from "./LinkedCalendar";

interface ScheduleSettingsProps {
	stationData: any;
	setShowInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
	setActiveInfoModalScreen: React.Dispatch<React.SetStateAction<string>>;
}

const TAB_LIST = [
	{ key: "general", label: "General" },
	{ key: "schedule_optimizer", label: "Schedule Optimizer" },
	{ key: "operating_hours", label: "Operating hours" },
	{ key: "custom_appointment_types", label: "Custom Appointment Types" },
	{ key: "custom_intake_field", label: "Custom Intake Field" },
	{ key: "linked_calendars", label: "Linked Calendars" },
] as const;

type TabView = (typeof TAB_LIST)[number]["key"];

const ScheduleSettings: React.FC<ScheduleSettingsProps> = ({
	stationData,
	setActiveInfoModalScreen,
	setShowInfoModal,
}) => {
	const customToast = useCustomToast();
	const [searchParams, setSearchParams] = useSearchParams();
	const stationId = searchParams.get("stationId");
	const settingsParam = searchParams.get("schedule_settings");
	const [isOpenScheduleSettings, setIsOpenScheduleSettings] = useState(true);
	const [tabView, setTabView] = useState<TabView>(() => {
		const foundTab = TAB_LIST.find((tab) => tab.key === settingsParam);
		return foundTab ? foundTab.key : "general";
	});

	useEffect(() => {
		if (settingsParam) {
			const foundTab = TAB_LIST.find((tab) => tab.key === settingsParam);
			if (foundTab) {
				setTabView(foundTab.key);
			}
		}
	}, [settingsParam]);

	const handleTabSwitch = (tab: TabView) => {
		setTabView(tab);
		setSearchParams({
			stationId: stationId || "",
			schedule_settings: tab,
		});
	};

	const toggleStationModal = (screenType?: string) => {
		setShowInfoModal(true);
		setActiveInfoModalScreen(screenType || "station");
	};

	const RenderTab = (activeStep: TabView) => {
		switch (activeStep) {
			case "general":
				return <General />;
			case "schedule_optimizer":
				return <ScheduleOptimizer />;
			case "custom_appointment_types":
				return <CustomTimeSlot />;
			case "operating_hours":
				return <OpreatingTime tabView={"schedule"} />;
			case "custom_intake_field":
				return <CustomFieldSettings customFieldMode="schedule" />;
			case "linked_calendars":
				return <LinkedCalendar />;
			default:
				return "";
		}
	};

	const { mutate: updateQueueSettings } = QueueSettingsSlice(
		(data) => {
			console.log("Update successful:", data);
		},
		(error) => {
			console.error("Update failed:", error);
		}
	);

	const { control, setValue, reset, watch } = useForm<queueSettingsProps>({
		resolver: zodResolver(queueSettingsSchema),
		defaultValues: {
			auto_approve: 0,
			is_visible: 0,
			waitlist_setting: {
				is_queue_active: 0,
				join_from_qr: 0,
				join_from_url: 0,
			},
			schedule_setting: {
				join_from_qr: 0,
				join_from_url: 0,
				is_open: 0,
			},
		},
	});

	// const handleSwitchChange = (field: any) => (value: boolean) => {
	// 	setValue(field, value);
	// 	updateQueueSettings({
	// 		data: {
	// 			[field]: value ? 1 : 0,
	// 			apply_to_option: {
	// 				apply_to_all: 0,
	// 				locations: [
	// 					{
	// 						id: stationData?.data?.location_id || 0,
	// 						update_location: 0,
	// 						apply_to_all_stations: 0,
	// 						selected_stations: [stationData?.data?.id || 0],
	// 					},
	// 				],
	// 			},
	// 		},
	// 		queryParams: { station_id: stationData?.data?.id || 0 },
	// 	});
	// 	customToast("Updating queue settings", {
	// 		id: "update-queue-settings",
	// 		type: "loading",
	// 	});
	// };

	const handleSwitchChange = (fieldPath: any) => (value: boolean) => {
		const newValue = value ? 1 : 0;
		setValue(fieldPath, value);
		const updatedData = createNestedObject(fieldPath, newValue);
		updateQueueSettings({
			data: {
				...updatedData,
				apply_to_option: {
					apply_to_all: 0,
					locations: [
						{
							id: stationData?.data?.location_id || 0,
							update_location: 0,
							apply_to_all_stations: 0,
							selected_stations: [stationData?.data?.id || 0],
						},
					],
				},
			},
			queryParams: { station_id: stationData?.data?.id || 0 },
		});
		customToast("Updating queue settings", {
			id: "update-queue-settings",
			type: "loading",
		});
	};

	const handleAccordionClick = (event: any) => {
		event.stopPropagation();
		if (!event.target.closest(".checkbox-wrapper")) {
			setIsOpenScheduleSettings(!isOpenScheduleSettings);
		}
	};

	useEffect(() => {
		if (stationData) {
			reset(stationData.data?.queue_setting);
		}
	}, [stationData, reset]);

	return (
		<Accordion
			type="single"
			collapsible
			className="boxShadow-custom2 flex flex-col space-y-6 rounded-md border"
			value={isOpenScheduleSettings ? "schedule" : ""}
		>
			<AccordionItem
				value="schedule"
				className=" w-full bg-[#FFFFFF] p-4"
			>
				<AccordionTrigger
					onClick={handleAccordionClick}
					className="flex w-full items-center justify-start gap-x-2.5 py-1 text-main-1 hover:no-underline"
				>
					<div className="flex w-full items-center justify-between space-x-2">
						<p className="flex flex-1 items-center justify-start space-x-2.5">
							<span className="text-lg font-semibold">
								Schedule Settings{" "}
							</span>
						</p>
						<div className="flex items-center space-x-2">
							<p>Allow Appointments</p>
							<div
								className="flex items-center"
								onClick={(e) => e.stopPropagation()}
							>
								<Controller
									name="schedule_setting.is_open"
									control={control}
									render={({ field }) => (
										<Switch
											className="scale-[0.70] data-[state=checked]:bg-primary"
											checked={Boolean(field.value)}
											onCheckedChange={handleSwitchChange(
												"schedule_setting.is_open"
											)}
										/>
									)}
								/>
								<span className="">
									{watch("schedule_setting.is_open")
										? "Yes"
										: "No"}
								</span>
							</div>
						</div>
						<Button
							variant="secondary"
							size="icon-sm"
							type="button"
							onClick={(e) => {
								e.stopPropagation();
								toggleStationModal("schedule");
							}}
						>
							<CgTikcode className="text-[14px]" size={18} />
						</Button>
						<p className="flex items-center justify-start space-x-2 pl-2.5">
							<span>Customizations</span>{" "}
							<span className="flex items-center ">
								<svg
									width="10"
									height="10"
									viewBox="0 0 9 9"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M5.86374 0.106902C5.34294 -0.0272813 4.79519 -0.0148774 4.28099 0.142743C3.7668 0.300364 3.30619 0.597065 2.95006 1.00006C2.59393 1.40306 2.35614 1.89667 2.26296 2.42635C2.16979 2.95602 2.22485 3.50114 2.42208 4.00149L0.505827 5.62357C0.360497 5.74639 0.242239 5.89803 0.158535 6.06891C0.0748315 6.23978 0.027504 6.42617 0.0195475 6.61628C0.011591 6.80639 0.0431786 6.99608 0.112311 7.17336C0.181444 7.35063 0.286617 7.51162 0.421177 7.64615C0.555737 7.78068 0.716754 7.88582 0.894042 7.95492C1.07133 8.02401 1.26103 8.05556 1.45114 8.04756C1.64124 8.03957 1.82762 7.9922 1.99848 7.90846C2.16934 7.82472 2.32096 7.70643 2.44374 7.56107L4.06499 5.64565C4.5654 5.8432 5.11068 5.89852 5.64056 5.80549C6.17044 5.71246 6.66427 5.47471 7.06746 5.11853C7.47065 4.76234 7.76748 4.3016 7.92514 3.78724C8.08281 3.27287 8.09516 2.72493 7.96083 2.20399C7.94189 2.13079 7.90358 2.06404 7.84992 2.01077C7.79627 1.9575 7.72926 1.91967 7.65593 1.90125C7.5826 1.88283 7.50566 1.88451 7.4332 1.90611C7.36074 1.9277 7.29544 1.96842 7.24416 2.02399L5.97541 3.4019L5.01833 3.04982L4.66541 2.09149L6.04416 0.824819C6.10005 0.773556 6.14106 0.708141 6.16283 0.635492C6.1846 0.562843 6.18634 0.48566 6.16786 0.412105C6.14937 0.338551 6.11135 0.271358 6.05782 0.217634C6.00429 0.16391 5.93723 0.12565 5.86374 0.106902Z"
										fill="#EEA23E"
									/>
								</svg>
							</span>
						</p>
					</div>
				</AccordionTrigger>
				<AccordionContent className="flex flex-col border-t pb-0">
					<div className="space-y-8 overflow-y-auto pt-5">
						<div className="mx-auto grid w-full max-w-6xl items-start gap-6 md:grid-cols-[180px_1fr] lg:grid-cols-[250px_1fr]">
							<nav className="grid gap-1 text-sm text-muted-foreground">
								{TAB_LIST.map((tab) => (
									<Button
										key={tab.key}
										type="button"
										variant={"ghost"}
										onClick={() => handleTabSwitch(tab.key)}
										className={cn(
											`flex items-center justify-start rounded-lg px-3 py-2 font-medium text-[#323539] opacity-100 hover:bg-transparent hover:text-muted-foreground`,
											{
												"bg-muted text-primary hover:bg-muted":
													tabView === tab.key,
												"text-foreground":
													tabView !== tab.key,
											}
										)}
									>
										{tab.label}
									</Button>
								))}
							</nav>
							<div className="grid h-full max-w-[800px] gap-6 pr-8">
								{RenderTab(tabView)}
							</div>
						</div>
					</div>
				</AccordionContent>
			</AccordionItem>
		</Accordion>
	);
};

export default ScheduleSettings;
