import TeamMembersTab from "@src/components/Dashboard/team-members/TeamMembersTabs";
import Header from "@src/layouts/Header/Header";
import React from "react";

export const TeamMembers: React.FC = () => {
	return (
		<main className="flex flex-1 flex-col">
			<Header title="Manage Members" />
			<section className="flex flex-1 ">
				<TeamMembersTab />
			</section>
		</main>
	);
};

export default TeamMembers;
