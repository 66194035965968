import { TimeSlot } from "@/src/types/DaySlots";
import { CustomToastProps } from "@src/components/CustomToast";
import { ErrorResponse } from "@src/types";
import { OperatingHour, ScheduleData } from "@type/DaySlots";
import { convertColumnTimeToMinutesOrSeconds } from "@utils/constants";
import { type ClassValue, clsx } from "clsx";
import { format } from "date-fns";
import { Dispatch, SetStateAction } from "react";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import toast from "react-hot-toast";
import { twMerge } from "tailwind-merge";

type FormError =
	| string
	| FieldError
	| Merge<FieldError, FieldErrorsImpl<any>>
	| undefined;

export const formatStringToMMYY = (e: any): string => {
	const code = e.keyCode;
	const allowedKeys = [8];
	if (allowedKeys.indexOf(code) !== -1) {
		return "";
	}

	e.target.value = e.target.value
		.replace(/^([1-9]\/|[2-9])$/g, "0$1/")
		.replace(/^(0[1-9]|1[0-2])$/g, "$1/")
		.replace(/^([0-1])([3-9])$/g, "0$1/$2")
		.replace(/^(0?[1-9]|1[0-2])([0-9]{2})$/g, "$1/$2")
		.replace(/^([0]+)\/|[0]+$/g, "0")
		.replace(/[^\d/]|^[/]*$/g, "")
		.replace(/\/\//g, "/");
	return e.target.value;
};

export const formatCreditCard = (string: string) => {
	const inputVal = string.replace(/ /g, "");
	let inputNumbersOnly = inputVal.replace(/\D/g, "");

	if (inputNumbersOnly.length > 20) {
		inputNumbersOnly = inputNumbersOnly.slice(0, 20);
	}

	const splits = inputNumbersOnly.match(/.{1,4}/g);

	let spacedNumber = "";
	if (splits) {
		spacedNumber = splits.join(" ");
	}
	return spacedNumber;
};

export const scheduleBlockOptions = [
	{ value: "5", label: "5" },
	{ value: "10", label: "10" },
	...Array.from({ length: 8 }).map((_, index) => ({
		value: ((index + 1) * 15).toString(),
		label: ((index + 1) * 15).toString(),
	})),
];

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export const toTitleCase = (string?: string): string => {
	if (!string || typeof string !== "string") {
		return "";
	}

	return string
		.toLocaleLowerCase()
		.split(" ")
		.map((item) => item[0].toUpperCase() + item.slice(1, item.length))
		.join(" ");
};

export function encodeValue(val: string | object | null) {
	if (typeof val === "string") {
		return val;
	}

	return JSON.stringify(val);
}

// how can val be object or string

export function decodeValue(val: string | object | null) {
	if (typeof val === "string") {
		try {
			return JSON.parse(val);
		} catch (_) {
			/* empty */
		}
	}

	return val;
}

/**
 * Extracts and returns a substring from the last period to the end of the given string.
 * If no period is found in the input string, the function returns the original string.
 *
 * @function
 * @param {string} inputString - The string from which to extract the substring.
 *
 * @returns {string} A substring from the last period to the end of the input string, or the original string if no period is found.
 *
 * Example usage:
 * ```javascript
 * const result = spliceToEndAtFirstPeriod("paul.pdf");
 * console.log(result); // Output: ".pdf"
 * ```
 */

export const getFormatOfFileUsingName: (inputString: string) => string = (
	inputString
) => {
	const periodIndex = inputString.lastIndexOf(".");
	if (periodIndex === -1) {
		// No period found, return original string or handle as needed
		return inputString;
	}
	// Extract from the period to the end of the string
	return inputString.slice(periodIndex);
};

/**
 * Handles the "Select All" checkbox change event.
 * Sets the selection state for all rows based on the "Select All" checkbox status.
 *
 * @param {boolean} isChecked - The current checked state of the "Select All" checkbox.
 * @param {Dispatch<SetStateAction<number[]>>} setSelectedRows - Function to update the selected rows state.
 * @param {Dispatch<SetStateAction<boolean>>} setSelectAllChecked - Function to update the "Select All" checkbox state.
 * @param {any[]} allIds - An array containing the IDs of all rows that can be selected.
 */
export const handleSelectAllChange = (
	isChecked: boolean,
	setSelectedRows: Dispatch<SetStateAction<number[]>>,
	setSelectAllChecked: Dispatch<SetStateAction<boolean>>,
	allIds: any[]
) => {
	setSelectAllChecked(isChecked);
	if (isChecked) {
		setSelectedRows(allIds || []);
	} else {
		setSelectedRows([]);
	}
};

/**
 * Handles the change event for an individual row's checkbox.
 * Updates the selected rows state based on the checkbox status of the row.
 * Also updates the "Select All" checkbox status if necessary.
 *
 * @param {boolean} isChecked - The current checked state of the row's checkbox.
 * @param {number} id - The ID of the row that was selected or deselected.
 * @param {Dispatch<SetStateAction<number[]>>} setSelectedRows - Function to update the selected rows state.
 * @param {number} noOfItems - The total number of items available for selection.
 * @param {Dispatch<SetStateAction<boolean>>} setSelectAllChecked - Function to update the "Select All" checkbox state.
 */
export const handleRowCheckboxChange = (
	isChecked: boolean,
	id: number,
	setSelectedRows: Dispatch<SetStateAction<number[]>>,
	noOfItems: number,
	setSelectAllChecked: Dispatch<SetStateAction<boolean>>
) => {
	setSelectedRows((prevSelectedRows) => {
		if (isChecked) {
			const newSelectedRows = [...prevSelectedRows, id];
			setSelectAllChecked(newSelectedRows.length === noOfItems);
			return newSelectedRows;
		} else {
			const newSelectedRows = prevSelectedRows.filter(
				(rowId) => rowId !== id
			);
			setSelectAllChecked(newSelectedRows.length === noOfItems);
			return newSelectedRows;
		}
	});
};

export const convertSchedule = (
	input: OperatingHour[] | undefined
): ScheduleData => {
	const daysMapping: { [key: string]: string } = {
		Monday: "monday",
		Tuesday: "tuesday",
		Wednesday: "wednesday",
		Thursday: "thursday",
		Friday: "friday",
		Saturday: "saturday",
		Sunday: "sunday",
	};

	const output: ScheduleData = {};

	input?.forEach((daySchedule) => {
		const dayKey =
			daysMapping[
				daySchedule?.day ?? toTitleCase(daySchedule.day_of_week) ?? "0"
			];
		if (dayKey) {
			output[dayKey] = {
				is_active: Boolean(daySchedule.is_active),
				time_slots: daySchedule.time_slots.map((slot) => ({
					start_time: slot.start_time.slice(0, 5),
					end_time: slot.end_time.slice(0, 5),
					...(slot.is_active !== undefined && {
						is_active: slot.is_active as 0 | 1,
					}),
				})),
			};
		}
	});

	return output;
};

/**
 * Converts a time string in "HH:MM:SS" format to a human-readable format.
 * @param timeString - The input time string in "HH:MM:SS" format.
 * @returns A formatted time string.
 */
export const convertTimeFormat = (timeString: string): string => {
	const [hoursStr, minutesStr] = timeString.split(":");
	const hours = parseInt(hoursStr, 10);
	const minutes = parseInt(minutesStr, 10);

	let hrString = "";
	let minString = "";

	if (hours > 0) {
		hrString = `${hours} hr${hours > 1 ? "s" : ""}`;
	}

	if (minutes > 0) {
		minString = `${minutes} min${minutes > 1 ? "s" : ""}`;
	}

	// Construct the combined format
	const combinedFormat = [hrString, minString].filter(Boolean).join(" ");

	return timeString
		? combinedFormat
		: combinedFormat
			? combinedFormat
			: "N/A";
};

/**
 * Extracts the error message from a form error object.
 *
 * @param {FormError} error - The error object which can be a string, FieldError, or Merge<FieldError, FieldErrorsImpl<any>>.
 * @returns {string | null} - The extracted error message as a string, or null if no valid message is found.
 */
export const getErrorMessage = (error: FormError): string | null => {
	if (typeof error === "string") {
		return error;
	} else if (error && typeof error.message === "string") {
		return error.message;
	} else {
		return null;
	}
};

type CustomToastFunction = (message: string, options: any) => void;

/**
 * Displays error messages with a timeout between each message.
 *
 * @param {ErrorResponse | string} errorData - The error data object or a single error message string.
 * @param {CustomToastFunction} customToast - The function to display toast notifications.
 * @param {object} options - Additional options for customization.
 * @param {number} options.delay - The delay in milliseconds between each message (default: 1000ms).
 * @param {string} options.toastIdPrefix - The prefix for the toast ID (default: 'error').
 */
export const displayErrorsWithTimeout = (
	errorData: ErrorResponse | string,
	customToast: CustomToastFunction,
	options: { delay?: number; toastIdPrefix?: string } = {}
) => {
	const { delay = 2000, toastIdPrefix = "error" } = options;

	if (typeof errorData === "string") {
		customToast(errorData, {
			id: `${toastIdPrefix}-single`,
			type: "error",
		});
		return;
	} else if (errorData.errors) {
		Object.entries(errorData.errors).forEach(([field, messages], index) => {
			messages.forEach((message, messageIndex) => {
				const toastDelay =
					(index * messages.length + messageIndex) * delay;
				setTimeout(() => {
					customToast(`${message}`, {
						id: `${toastIdPrefix}-${field}-${messageIndex}`,
						type: "error",
					});
				}, toastDelay);
			});
		});
		return;
	} else if (errorData.message) {
		customToast(errorData.message, {
			id: `${toastIdPrefix}-message`,
			type: "error",
		});
	}
};

/** 
Function to check if the time slots are overlapping
 * @param {OperatingHour[]} time_slots - Array of time slots
 * @param slots.start - Start time of the time slot
 * @param slots.end - End time of the time slot
 * @returns {boolean} - Returns true if the time slots are overlapping
 */

export const isTimeOverlapping = (time_slots: OperatingHour[]): boolean => {
	for (let h = 0; h < time_slots.length; h++) {
		const slots = time_slots[h].time_slots.map((slot) => ({
			start: convertColumnTimeToMinutesOrSeconds(
				slot.start_time,
				"minutes"
			),
			end: convertColumnTimeToMinutesOrSeconds(slot.end_time, "minutes"),
			day_value: time_slots[h].day_value,
		}));
		for (let i = 0; i < slots.length; i++) {
			for (let j = i + 1; j < slots.length; j++) {
				if (
					(slots[i].start < slots[j].end &&
						slots[i].end > slots[j].start) ||
					(slots[j].start < slots[i].end &&
						slots[j].end > slots[i].start)
				) {
					toast.error(
						`${time_slots[h].day}'s times are overlapping`,
						{
							id: "add-location-form",
						}
					);
					return true;
				}
			}
		}
	}

	return false;
};

/**
 * Converts an ISO date string to a formatted string "DD Month YYYY, HH:MM:SS".
 *
 * @param {string} dateString - The ISO date string to be formatted.
 * @returns {string} The formatted date string.
 */
export function formatDate(dateString: string): string {
	const date = new Date(dateString);

	const options: Intl.DateTimeFormatOptions = {
		day: "2-digit",
		month: "long",
		year: "numeric",
		hour: "2-digit",
		minute: "2-digit",
		second: "2-digit",
		hour12: false,
	};

	// Formatting date part
	const datePart = date.toLocaleDateString("en-GB", {
		day: "2-digit",
		month: "long",
		year: "numeric",
	});

	// Formatting time part
	const timePart = date.toLocaleTimeString("en-GB", {
		hour: "2-digit",
		minute: "2-digit",
		second: "2-digit",
		hour12: false,
	});

	return `${datePart}, ${timePart}`;
}

/**
 * Filters out undefined values from an object.
 *
 * @param {Record<string, any>} obj - The object to filter.
 * @returns {Record<string, any>} A new object with all undefined values removed.
 *
 * @example
 * // Returns { a: 1, b: 2 }
 * filterUndefined({ a: 1, b: 2, c: undefined });
 */
export const filterUndefinedFromObject = (
	obj: Record<string, any>
): Record<string, any> => {
	return Object.fromEntries(
		Object.entries(obj).filter(([_, v]) => v !== undefined)
	);
};

/**
 * Serializes an object of parameters into a query string.
 *
 * @param {Record<string, any>} params - The parameters to serialize.
 * @returns {string} - The serialized query string.
 */
export const serializeParamsToQueryString = (params: Record<string, any>) =>
	Object.keys(params)
		.map((key) => {
			if (Array.isArray(params[key])) {
				return `${key}=[${params[key].toString()}]`;
			}
			return `${key}=${params[key]}`;
		})
		.join("&");

/**
 * Transforms the input text based on the provided transformation type.
 *
 * @param {string} text - The input text to be transformed.
 * @param {'capitalize' | 'uppercase' | 'lowercase'} [transformType='capitalize'] - The type of transformation: 'capitalize', 'uppercase', or 'lowercase'.
 * @returns {string} - The transformed text.
 */
export const transformText = (
	text: string,
	transformType: "capitalize" | "uppercase" | "lowercase" = "capitalize"
): string => {
	switch (transformType) {
		case "uppercase":
			return text.toUpperCase();
		case "lowercase":
			return text.toLowerCase();
		case "capitalize":
		default:
			// Split the text by multiple delimiters: _, -, ,, .
			return text
				.toLowerCase()
				.split(/[_\-,.]+/)
				.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
				.join(" ");
	}
};

/**
 * Extracts the initials from a given name.
 *
 * @param {string} name - The full name from which to extract initials.
 * @param {boolean} [includeSecondNameInitial=false] - Whether to include the first letter of the second name.
 * @returns {string} The initials extracted from the name.
 *
 * @example
 * // returns 'J'
 * getInitials('John Doe');
 *
 * @example
 * // returns 'JD'
 * getInitials('John Doe', true);
 *
 * @example
 * // returns 'A'
 * getInitials('Alice');
 */
export const getInitials = (
	name: string,
	includeSecondNameInitial: boolean = false
): string => {
	const nameParts = name.trim().split(" ");

	// Extract the first letter of the first name
	let initials = nameParts[0]?.charAt(0).toUpperCase() || "";

	// If includeSecondNameInitial is true and there's a second name, add its initial
	if (includeSecondNameInitial && nameParts.length > 1) {
		initials += nameParts[1]?.charAt(0).toUpperCase() || "";
	}

	return initials;
};

/**
 * Validates the query parameters.
 *
 * This function checks if the provided query parameters object is valid by ensuring
 * none of its values are `0`, `null`, or `undefined`.
 *
 * @param {Record<string, number | string> | undefined} params - The query parameters to validate.
 * @returns {boolean} - Returns `true` if all query parameter values are valid, otherwise `false`.
 */
export const isValidQueryParams = (
	params?: Record<string, number | string>
): boolean => {
	if (!params) return false;
	return Object.values(params).every((value) => value !== 0 && value != null);
};

/**
 * Removes all trailing slashes from a given string.
 *
 * This function checks if the input string ends with a slash (`/`),
 * and if so, it removes the slash. The process repeats until no trailing
 * slashes remain.
 *
 * @param {string} string - The input string from which trailing slashes will be removed.
 * @returns {string} The string without any trailing slashes.
 */
export const removeTrailingSlash = (string: string): string => {
	while (string.endsWith("/")) {
		string = string.slice(0, -1);
	}
	return string;
};

/**
 * Converts a hex color code to an RGB object.
 *
 * This function takes a hex color code as a string and converts it to an RGB object.
 * The hex code can be in the short format (`#RGB`) or the full format (`#RRGGBB`).
 * If the hex code is not valid, an error is thrown.
 *
 * @param {string} hex - The hex color code to convert. It can be in `#RGB` or `#RRGGBB` format.
 * @returns {{r: number, g: number, b: number}} - An object containing the red (r), green (g), and blue (b) values as numbers.
 * @throws {Error} If the hex color code is not valid.
 */
export const hexToRGB = (hex: string) => {
	hex = hex.replace(/^#/, "");
	let r, g, b;
	if (hex.length === 3) {
		r = parseInt(hex[0] + hex[0], 16);
		g = parseInt(hex[1] + hex[1], 16);
		b = parseInt(hex[2] + hex[2], 16);
	} else if (hex.length === 6) {
		r = parseInt(hex.slice(0, 2), 16);
		g = parseInt(hex.slice(2, 4), 16);
		b = parseInt(hex.slice(4, 6), 16);
	} else {
		throw new Error("Invalid HEX color.");
	}

	return { r, g, b };
};

/**
 * Changes the theme color by setting a CSS variable with the specified color.
 *
 * If the provided theme is "default", it will be replaced with a default color (`#005893`).
 * If the theme is a valid hex color (with or without `#`), it will be converted to its RGB equivalent
 * and the CSS `--primary` variable will be updated with the RGB values.
 *
 * @param {string} theme - The hex color code or the string "default" to set the theme color.
 * @returns {string} - The theme that was actually set (either the original hex value or the default color).
 */
export const changeTheme = (theme: string): string => {
	const defaultColor = "#005893";

	// Handle the default theme case
	if (theme === "default") {
		theme = defaultColor;
	}

	// Ensure the theme is in a proper hex format
	if (!theme.startsWith("#")) {
		theme = `#${theme}`;
	}

	// Validate the hex color format using a regex
	const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
	if (!hexColorRegex.test(theme)) {
		console.warn(
			`Invalid hex color: ${theme}. Falling back to default color.`
		);
		theme = defaultColor;
	}

	// Convert hex to RGB
	const { r, g, b } = hexToRGB(theme);
	document.documentElement.style.setProperty("--primary", `${r} ${g} ${b}`);

	return theme;
};

/**
 * Handles the file input change event, validating file size and format.
 * If any file exceeds the size limit or is in an invalid format, a custom toast message is displayed.
 *
 * @param {React.ChangeEvent<HTMLInputElement>} event - The file input change event.
 * @param {string[]} allowedFormats - An array of allowed file formats (e.g., ['image/jpeg', 'image/png']).
 * @param {function} customToast - A function to display a custom toast message.
 * @param {string} customToast.message - The message to display in the toast.
 * @param {Object} customToast.options - The options for the toast message.
 * @param {string} customToast.options.id - The unique ID for the toast message.
 * @param {string} customToast.options.type - The type of toast message (e.g., 'error', 'success').
 * @param {number} [customToast.options.duration] - The duration for which the toast message is displayed (in milliseconds).
 * @param {string} [customToast.options.undoText] - The text for the undo action (if any).
 * @param {function} [customToast.options.undoHandler] - The function to call if the undo action is triggered.
 *
 * @returns {FileList | void} - Returns the selected `FileList` if all files are valid, otherwise returns `void`.
 */
export const handleFileChange = (
	event: React.ChangeEvent<HTMLInputElement>,
	allowedFormats: string[],
	customToast: (
		message: string,
		{ id, type, duration, undoText, undoHandler }: CustomToastProps
	) => void
): FileList | void => {
	if (event && event.target) {
		const files: FileList | null = event.target.files;
		if (files && files.length > 0) {
			for (let i = 0; i < files.length; i++) {
				if (files[i].size >= 2 * 1024 * 1024) {
					// Check if the file size is 2MB or larger
					customToast(
						"File size is too large. Please select a file smaller than 2MB.",
						{
							id: "file-size-error",
							type: "error",
						}
					);
					return;
				}

				if (!allowedFormats.includes(files[i].type)) {
					// Check if the file format is allowed
					customToast(
						"Invalid file format. Please select a JPG, JPEG, PNG, GIF, or SVG file.",
						{
							id: "file-format-error",
							type: "error",
						}
					);
					return;
				}
			}

			return files;
		}
	}
};

// Define types for Location and Station
interface Location {
	id: number;
	name: string;
}

interface Station {
	id: number;
	name: string;
	location_id: number;
}

// Define the input data structure type
interface InputData {
	locations: Location[];
	location_count: number;
	stations: Station[];
	station_count: number;
}

// Define the output structure type
interface TransformedLocation {
	id: number;
	update_location: number;
	apply_to_all_stations: number;
	selected_stations: number[];
}

interface OutputData {
	locations: TransformedLocation[];
}

/**
 * Converts the input data structure to the desired format.
 *
 * @param data - The input data containing locations and stations.
 * @returns The transformed data structure.
 */
export const convertLocationData = (data: InputData): OutputData => {
	return {
		locations: data?.locations?.map((location) => {
			const selectedStations = data.stations
				?.filter((station) => station.location_id === location.id)
				?.map((station) => station.id);

			return {
				id: location.id,
				update_location: selectedStations.length > 0 ? 1 : 0,
				apply_to_all_stations: 0,
				selected_stations: selectedStations,
			};
		}),
	};
};

/**
 * Converts an object to FormData
 * @param {Record<string, any>} obj - The object to convert to FormData
 * @returns {FormData} The resulting FormData object
 */
export const objectToFormData = (obj: Record<string, any>): FormData => {
	const formData = new FormData();

	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			formData.append(key, obj[key]);
		}
	}

	return formData;
};

export const sortByName = (
	data: any[],
	sortOrder: "asc" | "dsc" = "asc"
): any[] => {
	return data.sort((a, b) => {
		if (sortOrder === "asc") {
			return a.name.localeCompare(b.name);
		} else {
			return b.name.localeCompare(a.name);
		}
	});
};

/**
 * Creates a nested object based on a given path and value.
 *
 * @param {string} path - The path to the field in the form data, separated by dots.
 * @param {any} value - The value to be set at the end of the path.
 * @returns {object} A nested object with the value set at the specified path.
 */
export const createNestedObject = (path: string, value: any): object => {
	const keys = path.split(".");
	const lastKey = keys.pop()!;
	const obj: any = {};
	let current = obj;

	keys.forEach((key) => {
		current[key] = {};
		current = current[key];
	});

	current[lastKey] = value;
	return obj;
};

export function formatTime(time: string): string {
	const [hours, minutes] = time.split(":");
	const meridiem = parseInt(hours) >= 12 ? "PM" : "AM";
	let formattedHours: number | string = parseInt(hours) % 12 || 12;
	formattedHours =
		formattedHours < 10 ? `0${formattedHours}` : formattedHours;
	const formattedTime = `${formattedHours}:${minutes} ${meridiem}`;
	return formattedTime;
}

/**
 * @typedef {Object} TimeSlot
 * @property {number} id - The ID of the time slot.
 * @property {string} start_time - The start time of the time slot in HH:mm:ss format.
 * @property {string} end_time - The end time of the time slot in HH:mm:ss format.
 * @property {number} is_active - Indicates whether the time slot is active.
 * @property {number} product_working_hour_id - The ID of the associated working hour.
 * @property {string} created_at - The timestamp when the time slot was created.
 * @property {string} updated_at - The timestamp when the time slot was last updated.
 */

/**
 * @typedef {Object} OperatingHour
 * @property {number} id - The ID of the working hour.
 * @property {string} day_of_week - The day of the week for the working hour.
 * @property {number} workable_id - The ID of the related workable entity.
 * @property {string} workable_type - The type of the workable entity.
 * @property {number} is_active - Indicates whether the working hour is active.
 * @property {string} created_at - The timestamp when the working hour was created.
 * @property {string} updated_at - The timestamp when the working hour was last updated.
 * @property {TimeSlot[]} time_slots - An array of time slots associated with the working hour.
 */

/**
 * Merges working hours by day of the week, combining their time slots if they have the same day_of_week.
 *
 * @param {TimeSlot[]} hours - Array of working hours to merge by day of the week.
 * @returns {OperatingHour[]} - The merged array of working hours.
 */
export const mergeWorkingHours: (hours?: OperatingHour[]) => OperatingHour[] = (
	hours
) => {
	const merged = {};

	if (hours?.length) return hours;

	hours?.forEach((hour) => {
		if (merged[hour?.day_of_week ?? 0]) {
			merged[hour.day_of_week ?? 0].time_slots = [
				...merged[hour.day_of_week ?? 0].time_slots,
				...hour.time_slots,
			];
		} else {
			merged[hour.day_of_week ?? 0] = { ...hour };
		}
	});

	return Object.values(merged);
};

// Merge possible duplicate working hours by day_of_week

/**
 * Sorts an array of working hours based on the day of the week, from Monday to Sunday.
 *
 * @param {JoinWorkingHour[]} mergedWorkingHours - Array of merged working hours to sort.
 * @returns {JoinWorkingHour[]} - The sorted array of working hours.
 */
export const sortWorkingHoursByDay = (workingHours?: OperatingHour[]) => {
	const mergedWorkingHours = mergeWorkingHours(workingHours);
	const daysOfWeekOrder = [
		"monday",
		"tuesday",
		"wednesday",
		"thursday",
		"friday",
		"saturday",
		"sunday",
	];

	return mergedWorkingHours.sort(
		(a, b) =>
			daysOfWeekOrder.indexOf((a.day_of_week ?? "").toLowerCase()) -
			daysOfWeekOrder.indexOf((b.day_of_week ?? "").toLowerCase())
	);
};

/**
 * Formats a time range given two Date objects.
 *
 * @param {Date} startTime - The start time of the range.
 * @param {Date} endTime - The end time of the range.
 * @returns {string} - The formatted time range (e.g., "7:30 AM - 8:12 AM").
 */
export const formatTimeRangeForHistory = (
	startTime: Date,
	endTime: Date
): string => {
	const start = format(startTime, "h : mm a");
	const end = format(endTime, "h : mm a");
	return `${start} - ${end}`;
};
