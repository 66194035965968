import { useEffect, useState } from "react";
import CustomCalendar from "./components/calendar/customcalendar";
import TabList from "./components/TabList";
import AddAppointment from "./modals/AddAppointment";
import ScheduleHeader from "./components/ScheduleHeader";
import ScheduleSettings from "./modals/ScheduleSettings";
import { cn } from "../../utils/functions";
import Header from "@src/layouts/Header/Header";
import { Dialog, DialogContent } from "@src/components/ui/dialog";
import { GetAppointments } from "@src/store/slices/schedule/scheduleSlice";
import useUserStore from "@src/store/useUserStore";
import useAppointmentStore from "@src/store/useAppointmentStore";
import { DialogTitle } from "@radix-ui/react-dialog";

const Schedule = () => {
	const currentLocationId = useUserStore((s) => s.currentLocationId);
	const [showAppointmentModal, setShowAppointmentModal] = useState(false);
	// const [showSettingsModal, setShowSettingsModal] = useState(false);
	const {
		view,
		showAppointmentSettingsModal,
		setShowAppointmentSettingsModal,
	} = useAppointmentStore();
	const [events, setEvents] = useState<any>([]);
	const setFilteredAppointments = useAppointmentStore(
		(s) => s.setFilteredAppointments
	);
	const appointments = useAppointmentStore((s) => s.appointments);
	const filteredAppointments = useAppointmentStore(
		(s) => s.filteredAppointments
	);
	const [selectedStations, setSelectedStations] = useState<
		{
			value: string;
			label: string;
		}[]
	>([]);
	const handleRemoveSchedule = (selectedStations, station) => {
		setSelectedStations(
			selectedStations.filter(
				(selectedStation) => selectedStation !== station
			)
		);
		if (filteredAppointments?.length != events?.length) {
			setFilteredAppointments(events);
		}
	};
	const { isLoading, isFetching } = GetAppointments(currentLocationId);

	useEffect(() => {
		setEvents(appointments);
	}, [appointments]);
	return (
		<div className="flex flex-col ">
			<Header title={"Schedule"} />
			<div className="flex flex-col gap-y-3">
				<ScheduleHeader
					events={events}
					setEvents={setEvents}
					selectedStations={selectedStations}
					setSelectedStations={setSelectedStations}
					setShowAppointmentModal={setShowAppointmentModal}
					// setShowSettingsModal={setShowAppointmentSettingsModal}
				/>
				<div className="flex gap-6">
					<div className="flex-[0.2]">
						<TabList />
					</div>
					<div
						className={cn("h-[72svh] w-full flex-[0.8]", {
							"h-[75svh]": selectedStations.length > 0,
							"h-[72vh]":
								selectedStations.length > 0 && view == "day",
							"h-full max-h-[79svh]": view == "week",
							"h-full max-h-[75svh]":
								view == "week" && selectedStations.length,
							"h-full max-h-[80svh]": view == "month",
							"h-full max-h-[77svh]":
								view == "month" && selectedStations.length > 0,
						})}
					>
						<CustomCalendar
							handleRemoveSchedule={handleRemoveSchedule}
							setSelectedStations={setSelectedStations}
							selectedStations={selectedStations}
							events={events}
							// setEvents={setEvents}
							setShowAppointmentModal={setShowAppointmentModal}
						/>
					</div>
				</div>
				<AddAppointment
					show={showAppointmentModal}
					setShow={setShowAppointmentModal}
				/>
				<Dialog
					open={showAppointmentSettingsModal}
					onOpenChange={() => {
						setShowAppointmentSettingsModal(
							!showAppointmentSettingsModal
						);
					}}
				>
					<DialogContent className="h-[85svh] max-h-[85svh] max-w-[1140px]">
						<DialogTitle className="sr-only">
							Schedule Settings
						</DialogTitle>
						<ScheduleSettings />
					</DialogContent>
				</Dialog>
			</div>
		</div>
	);
};

export default Schedule;
