import {
	MainPatientInfo,
	TabButton,
} from "@/src/components/Dashboard/waitlist/PatientInformation/PatientInformation";
import Loader from "@/src/components/Loader/Loader";
import {
	UpdateCustomerSlice,
	useGetPatientInfo,
} from "@/src/store/slices/waitlist/managePatientSlice";
import { Button } from "@src/components/ui/button";
import { Label } from "@src/components/ui/label";
import { Textarea } from "@src/components/ui/textarea";
import {
	SinglePatientQueueData,
	SinglePatientQueueResponse,
} from "@src/types/waitlist/waitlist";
import { cn, toTitleCase } from "@src/utils/functions";
import { formatDistance } from "date-fns";
import React, { useEffect, useState } from "react";
import ServingIntakeFieldsTab from "../ServingPatientInformation/ServingIntakeFieldsTab";
import { useQueryClient } from "react-query";
import useUserStore from "@/src/store/useUserStore";
import { ServingQueueResponse } from "@/src/types/serving/serving";
import useCustomToast from "@/src/components/CustomToast";

const ServingSectionLargeCard: React.FC<{
	patient: SinglePatientQueueData;
	setShowPriorityModal: React.Dispatch<React.SetStateAction<boolean>>;
	setActivePatient?: React.Dispatch<
		React.SetStateAction<SinglePatientQueueData | undefined>
	>;
}> = ({ patient, setShowPriorityModal, setActivePatient }) => {
	const queryClient = useQueryClient();
	const customToast = useCustomToast();
	const [textAreaActive, setTextAreaActive] = useState(false);
	const getPatieintInfoQuery = useGetPatientInfo(
		undefined,
		patient?.id,
		!!patient?.id
	);

	const updatePatientMutation = UpdateCustomerSlice((data) => {
		customToast("Patient marked for follow up", {
			id: "manage-patient-" + patient?.id,
			type: "success",
		});

		queryClient.setQueryData<SinglePatientQueueResponse | undefined>(
			["get-customer-info", patient?.id],
			(prevData): SinglePatientQueueResponse | undefined => {
				if (!prevData || !data.data) return;
				setActivePatient?.({
					...prevData?.data,
					...data?.data,
				});
				return {
					...prevData,
					data: {
						...prevData?.data,
						...data?.data,
					},
				};
			}
		);
	});

	useEffect(() => {
		getPatieintInfoQuery.refetch();
	}, [patient?.id]);

	return (
		<div className="flex flex-1 flex-col space-y-3 rounded-[10px] border border-[#138576] p-6 pb-5">
			<div className="flex items-center justify-between border-b-2 border-b-primary pb-2.5">
				<div className="flex items-center space-x-3">
					<h3 className="text-[24px] font-semibold tracking-[-1%] text-[#323539]">
						{patient.full_name}
					</h3>
					{/* <PatientPriorityModal
						patientDetails={patient}
						// status={PatientStatus.Serving}
						danger
						showPriorityModal={false}
						setShowPriorityModal={function (): void {
							throw new Error("Function not implemented.");
						}}
					/> */}
					<Button
						className={cn(
							"flex h-[22px] w-[28px] items-center justify-center rounded-[4px] bg-[#EAEBF0] p-0 hover:bg-[#EAEBF0]"
						)}
						onClick={(e) => {
							e.stopPropagation();
							setShowPriorityModal(true);
						}}
					>
						<i
							className={cn(
								"mgc_tag_fill text-[14px] before:!text-[#969696]",
								{
									"before:!text-[#C8322B]":
										patient.waitlist?.priority === "high",
								}
							)}
						/>
					</Button>
					<Button
						className="space-x-2 bg-main-8 px-2 duration-200 ease-in-out hover:bg-main-8/90"
						disabled={updatePatientMutation.isLoading}
						onClick={() => {
							customToast("Marking patient for follow up", {
								id: "manage-patient-" + patient?.id,
								type: "loading",
							});
							updatePatientMutation.mutate({
								customer_id: patient.id,
								is_follow_up: Number(!patient.is_follow_up) as
									| 0
									| 1,
							});
						}}
					>
						<i className="mgc_back_2_line text-[18px] before:!text-white" />
						<p className="text-white">
							{patient.is_follow_up
								? "Unmark for Follow Up"
								: "Marked for Follow Up"}
						</p>
					</Button>{" "}
				</div>
				<Button className="space-x-3 bg-[#1E8A7C] px-2 py-1 hover:bg-[#1E8A7C]">
					<p className="font-normal text-white">Mark as Served</p>
					<i className="mgc_check_circle_fill text-[14px] before:!text-white" />
				</Button>
			</div>
			<ul className="mt-3 grid grid-cols-[min(215px)_auto] gap-3 py-3">
				<MainPatientInfo
					title={"Phone Number"}
					description={
						getPatieintInfoQuery.data?.data.phone_number ??
						patient?.phone_number
					}
				/>
				{patient?.email && (
					<MainPatientInfo
						title={"Email Address"}
						description={
							getPatieintInfoQuery.data?.data.email ??
							patient?.email
						}
					/>
				)}

				<MainPatientInfo
					title={"Joined"}
					description={toTitleCase(
						formatDistance(
							new Date(
								patient?.waitlist?.joined_at ?? new Date()
							),
							new Date(),
							{ addSuffix: true }
						)
					)}
				/>

				<MainPatientInfo
					title={"Service Started"}
					description={toTitleCase(
						formatDistance(
							new Date(
								patient?.waitlist?.service_start_at ??
									new Date()
							),
							new Date(),
							{ addSuffix: true }
						)
					)}
				/>
			</ul>
			<ServingSectionLargeCardTabs patient={patient} />
			<form className="flex flex-col space-y-2">
				<Label>Add Notes</Label>
				<div
					className="flex items-center space-x-2
        rounded-md border border-input bg-background px-3 py-1.5 shadow-inner focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2"
				>
					<Textarea
						className={cn(
							"h-fit min-h-[24px] resize-none border-none p-0 text-[#323539]/50 shadow-none focus:outline-none focus-visible:ring-0",
							{ "h-[200px]": textAreaActive }
						)}
						placeholder="Enter Here"
						defaultValue="Patient is having back pain and cough. Previous prescription was not as affective and so I ha..."
						onFocus={() => setTextAreaActive(true)}
						onBlur={() => setTextAreaActive(false)}
					/>
					<Button
						className="h-fit w-fit self-end px-5 py-1 text-[12px] font-medium leading-[24px] text-white"
						type="button"
					>
						Save Notes
					</Button>
				</div>
			</form>
		</div>
	);
};

export default ServingSectionLargeCard;

const ServingSectionLargeCardTabs: React.FC<{
	patient: SinglePatientQueueData;
}> = ({ patient }) => {
	const [currentTab, setCurrentTab] = React.useState<"intake" | "activity">(
		"intake"
	);
	const queryClient = useQueryClient();

	return (
		<div className="flex flex-1 flex-col">
			<div className="flex">
				<TabButton
					label="Intake Information"
					isActive={currentTab === "intake"}
					onClick={() => setCurrentTab("intake")}
				/>

				<TabButton
					label="Activity"
					isActive={currentTab === "activity"}
					onClick={() => setCurrentTab("activity")}
				/>
			</div>
			<div className="flex flex-1 items-stretch justify-stretch">
				{currentTab === "intake" && (
					<div className="mb-2.5 mt-4 flex w-full flex-col">
						<Button
							type="button"
							className="flex h-fit w-fit items-center space-x-2 self-end rounded-[2px] bg-white p-0 hover:bg-white"
						>
							<i className="mgc_clipboard_line text-[20px] before:!text-primary" />
							<p className="text-[14px] font-semibold leading-[20px] text-[#323539]">
								View PDF
							</p>
						</Button>
						{queryClient.getQueryState([
							"get-customer-info",
							patient.id,
						])?.status === "idle" ? (
							<div className="py-10">
								<Loader size={24} />
							</div>
						) : (
							<ServingIntakeFieldsTab
								isEditing={false}
								patientDetails={patient}
							/>
						)}
					</div>
				)}
				{/* {currentTab === "activity" && <ActivityTab patient={patient} />} */}
			</div>
		</div>
	);
};
