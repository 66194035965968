import { useEffect, useState } from "react";
import { DndContext, DragEndEvent, closestCenter } from "@dnd-kit/core";
import {
	arrayMove,
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { MdOutlineDragIndicator } from "react-icons/md";
import { Button } from "@src/components/ui/button";
import { GoEye, GoEyeClosed } from "react-icons/go";
import { HiOutlineTrash } from "react-icons/hi";
import AddFieldModal from "@src/pages/customization/components/AddFieldModal";
import {
	AddCustomizationFieldSchema,
	CustomizationProps,
	// CustomizationSchema,
} from "@src/types/customization";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	FormProvider,
	SubmitHandler,
	useFieldArray,
	useForm,
	Controller,
	UseFormRegister,
	Control,
} from "react-hook-form";
import {
	// AddRemoveBulkCustomFieldSlice,
	CreateCustomFields,
	GetCustomFieldsSlice,
	UpdateStationCustomFields,
} from "@src/store/slices/customization/customFieldsSlice";
import useStationStore from "@src/store/useStationStore";
import { displayErrorsWithTimeout } from "@src/utils/functions";
import useCustomToast from "@src/components/CustomToast";
import {
	CustomFieldProps,
	CustomFieldsArrayProps,
	CustomFieldsArraySchema,
} from "@src/types/CustomFields";
import FormInput from "@src/components/form/FormInput";
import { DeleteLocationModal } from "@src/pages/locations/components/DeleteLocationModal";
import Loader from "@src/components/Loader/Loader";
import Checkbox from "@src/components/ui-extended/checkbox";
import { z } from "zod";
import _ from "lodash";
import AddExistingCustomFieldModal from "./ScheduleSettings/AddExisitingCustomField";

type DraggableContainerProps = {
	index: number;
	field: CustomFieldProps;
	control: Control<
		{
			fieldArray: CustomFieldsArrayProps;
		},
		any
	>;
	register: UseFormRegister<{
		fieldArray: CustomFieldsArrayProps;
	}>;
	errors: any;
	customFieldsIds: number[];
	setCustomFieldsIds: React.Dispatch<React.SetStateAction<number[]>>;
	handleDeleteCustomField: (id: number) => void;
};

const SortableItem = ({
	index,
	field,
	control,
	register,
	errors,
	customFieldsIds,
	setCustomFieldsIds,
	handleDeleteCustomField,
}: DraggableContainerProps) => {
	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({ id: field.id });
	const style = {
		transform: CSS?.Transform?.toString(transform),
		transition,
	};

	const [showDeleteCustomFieldsModal, setShowDeleteCustomFieldsModal] =
		useState(false);

	return (
		<div
			ref={setNodeRef}
			style={style}
			{...attributes}
			className="mb-2 flex overflow-hidden rounded-lg bg-gray-100"
		>
			<div
				{...listeners}
				className="flex h-auto cursor-grab items-center px-3 hover:bg-[#000]/5"
			>
				<MdOutlineDragIndicator className="text-gray-400" size={"24"} />
			</div>
			<div className="flex flex-1 items-center justify-between space-x-4 p-2">
				<Checkbox
					isChecked={customFieldsIds.includes(field?.id)}
					handleCheckboxChange={() =>
						setCustomFieldsIds((prev: any) => {
							if (prev.includes(field.id)) {
								return prev.filter((id) => id !== field.id);
							} else {
								return [...prev, field.id];
							}
						})
					}
					className="rounded-sm border-2 border-[#D1D1D1]"
					id={`fieldArray.${index}.id`.toString()}
				/>
				<div className="flex-1">
					<Controller
						name={`fieldArray.${index}.name`}
						control={control}
						render={({ field }) => (
							<FormInput
								{...field}
								inputType="text"
								className="h-10"
								ref={field.ref}
								value={field?.value}
								placeholder="Field Name"
								register={{
									...register(`fieldArray.${index}.name`),
								}}
								error={
									errors?.fieldArray?.[index]?.name?.message
								}
							/>
						)}
					/>
				</div>
				<p className="w-[100px]">{field?.type}</p>
				<Controller
					name={`fieldArray.${index}.field_requirement`}
					control={control}
					render={({ field }) => (
						<Checkbox
							isChecked={field?.value === "yes"}
							handleCheckboxChange={() => {
								field.onChange(
									field.value === "yes" ? "no" : "yes"
								);
							}}
							className="rounded-sm border-2 border-[#D1D1D1]"
							id={`fieldArray.${index}.id`.toString()}
						/>
					)}
				/>
				<p className="w-[100px]">Required</p>
				<div className="flex space-x-0">
					<Button
						variant="ghost"
						size="icon-sm"
						type="button"
						onClick={() => setShowDeleteCustomFieldsModal(true)}
					>
						<HiOutlineTrash
							size={"24"}
							className="text-[#5373A1]"
						/>
					</Button>
					<Controller
						name={`fieldArray.${index}.is_visible`}
						control={control}
						render={({ field }) => (
							<Button
								variant="ghost"
								type="button"
								className="text-red-500"
								size="icon-sm"
								onClick={() => {
									field.onChange(field.value ? 0 : 1);
								}}
							>
								{field?.value ? (
									<GoEye
										size={"24"}
										className="text-primary"
									/>
								) : (
									<GoEyeClosed
										size={"24"}
										className="text-gray-400"
									/>
								)}
							</Button>
						)}
					/>
				</div>
				<DeleteLocationModal
					locationName={field?.name || ""}
					showModal={showDeleteCustomFieldsModal}
					setShowModal={setShowDeleteCustomFieldsModal}
					isLoading={false}
					buttonAction={() => handleDeleteCustomField(field?.id)}
					DeleteContent={DeleteCustomFieldsContent}
					buttonText="Yes, delete custom field"
				/>
			</div>
		</div>
	);
};

const DeleteCustomFieldsContent = ({
	displayString,
}: {
	displayString: string;
}) => {
	return (
		<div className="space-y-4">
			<p className="mt-2 text-sm leading-[20px] -tracking-[0.1px] text-[#6D748D]">
				Are you sure you want to delete{" "}
				<span className="font-semibold">
					&quot;{displayString}?&quot;
				</span>
				{". "}
			</p>
			<p className="mt-2 text-sm leading-[20px] -tracking-[0.1px] text-[#6D748D]">
				This action cannot be undone.
			</p>
		</div>
	);
};

interface CustomFieldSettingsProps {
	customFieldMode?: string | undefined;
}

const CustomFieldSettings: React.FC<CustomFieldSettingsProps> = ({
	customFieldMode,
}) => {
	const stationData = useStationStore((s) => s.stationDetails);
	const [modalMode, setModalMode] = useState<string | undefined>(undefined);
	const [customFieldsIds, setCustomFieldsIds] = useState<number[]>([]);
	const [checkAllCustomFields, setCheckAllCustomFields] = useState(false);
	const customToast = useCustomToast();
	const [filteredCustomFieldData, setFilteredCustomFieldData] =
		useState<CustomFieldsArrayProps>([]);
	const { data, isLoading, isSuccess, invalidateQuery } =
		GetCustomFieldsSlice({
			station_id: stationData.id || 0,
			apply_to:
				customFieldMode === "waitlist"
					? "waitlist_only"
					: customFieldMode === "schedule"
						? "schedule_only"
						: "",
		});

	const methods = useForm<CustomizationProps>({
		resolver: zodResolver(AddCustomizationFieldSchema),
		mode: "onChange",
		defaultValues: {
			name: "",
			subtitle: "",
			type: undefined,
			numeric_unit_title: "",
			info_text_value: "",
			long_text: 0,
			date_range: 0,
			date_range_value: null,
			options: ["Option 1"],
			allow_multiple: 0,
			image: "",
			approved_formats: [],
			apply_to: "all",
			field_requirement: "yes",
			// apply_to_option: {
			// 	apply_to_all: 0,
			// 	locations: [],
			// },
		},
	});

	const updateMethods = useForm<{ fieldArray: CustomFieldsArrayProps }>({
		resolver: zodResolver(
			z.object({
				fieldArray: CustomFieldsArraySchema,
			})
		),
		mode: "onChange",
		defaultValues: {
			fieldArray: [],
		},
	});

	const { reset } = methods;

	const {
		reset: updateReset,
		watch: updateWatch,
		control: updateControl,
		register: updateRegister,
		formState: { isValid: updateIsValid, errors: updateErrors },
		handleSubmit: updateHandleSubmit,
	} = updateMethods;

	const { fields } = useFieldArray<{ fieldArray: CustomFieldsArrayProps }>({
		control: updateControl,
		name: "fieldArray",
		keyName: "_id" as "id",
	});

	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;

		if (active.id !== over?.id) {
			const oldIndex = fields.findIndex(
				(field) => field.id === active.id
			);
			const newIndex = fields.findIndex((field) => field.id === over?.id);
			const updatedFields = arrayMove(fields, oldIndex, newIndex);
			updateReset({
				fieldArray: updatedFields,
			});
			setFilteredCustomFieldData(updatedFields);
		}
	};

	const {
		mutate: createCustomFieldsMutation,
		isLoading: createCustomFieldsLoading,
	} = CreateCustomFields(() => {
		invalidateQuery();
		setModalMode(undefined);
		reset({
			name: "",
			subtitle: "",
			type: undefined,
			numeric_unit_title: "",
			long_text: 0,
			date_range: 0,
			date_range_value: null,
			options: ["Option 1", "Option 2"],
			allow_multiple: 0,
			image: "",
			approved_formats: [],
			apply_to: "all",
			field_requirement: "yes",
			apply_to_option: {
				apply_to_all: 0,
				locations: [],
			},
		});
	});

	const {
		mutate: updateCustomFieldsMutation,
		isLoading: updateCustomFieldsLoading,
	} = UpdateStationCustomFields(() => {
		invalidateQuery();
		setModalMode(undefined);
		reset({
			name: "",
			subtitle: "",
			type: undefined,
			numeric_unit_title: "",
			long_text: 0,
			date_range: 0,
			date_range_value: null,
			options: ["Option 1", "Option 2"],
			allow_multiple: 0,
			image: "",
			approved_formats: [],
			apply_to: "all",
			field_requirement: "yes",
			apply_to_option: {
				apply_to_all: 0,
				locations: [],
			},
		});
	});

	const onSubmit: SubmitHandler<CustomizationProps> = (data) => {
		createCustomFieldsMutation(
			{
				data: {
					...data,
					apply_to:
						customFieldMode === "waitlist"
							? "waitlist_only"
							: customFieldMode === "schedule"
								? "schedule_only"
								: "all",
					field_requirement: "yes",
					apply_to_option: {
						apply_to_all: 0,
						locations: [
							{
								id: stationData?.location_id || 0,
								update_location: 0,
								apply_to_all_stations: 0,
								selected_stations: [stationData?.id || 0],
							},
						],
					},
				},
			},
			{
				onSuccess: () => {
					setModalMode(undefined);
				},
				onError: (error: any) => {
					displayErrorsWithTimeout(
						error.response.data?.error ||
							"An unexpected error occurred. Please try again later",
						customToast,
						{
							toastIdPrefix: "create-custom-fields",
						}
					);
				},
			}
		);
	};

	const onUpdateSubmit: SubmitHandler<{
		fieldArray: CustomFieldsArrayProps;
	}> = (data) => {
		updateCustomFieldsMutation(
			{
				data: {
					custom_fields: data.fieldArray,
					apply_to:
						customFieldMode === "waitlist"
							? "waitlist_only"
							: customFieldMode === "schedule"
								? "schedule_only"
								: "all",
				},
				id: stationData.id || 0,
			},
			{
				onSuccess: () => {
					setModalMode(undefined);
				},
				onError: (error: any) => {
					displayErrorsWithTimeout(
						error.response.data?.error ||
							"An unexpected error occurred. Please try again later",
						customToast,
						{
							toastIdPrefix: "update-custom-fields",
						}
					);
				},
			}
		);
	};

	const handleDeleteCustomField = (id: number) => {
		const updatedFields = filteredCustomFieldData.filter(
			(field) => field.id !== id
		);
		updateReset({
			fieldArray: updatedFields,
		});
		setCheckAllCustomFields(false);
		setFilteredCustomFieldData(updatedFields);
	};

	const handleDeleteMultipleCustomField = () => {
		const updatedFields = filteredCustomFieldData.filter(
			(field) => !customFieldsIds.includes(field.id)
		);
		updateReset({
			fieldArray: updatedFields,
		});
		setFilteredCustomFieldData(updatedFields);
		setCheckAllCustomFields(false);
	};

	const handleCancel = () => {
		const fields = data?.data;
		setFilteredCustomFieldData(fields || []);
		updateReset({
			fieldArray: fields || [],
		});
		setCustomFieldsIds([]);
		setCheckAllCustomFields(false);
	};

	const handleCheckAllCustomFields = () => {
		if (customFieldsIds?.length === data?.data.length) {
			setCustomFieldsIds([]);
			setCheckAllCustomFields(false);
		} else {
			setCustomFieldsIds(data?.data?.map((field: any) => field.id) || []);
			setCheckAllCustomFields(true);
		}
	};

	const handleToggleVisibility = () => {
		const updatedFields = filteredCustomFieldData.map((field) => {
			if (customFieldsIds.includes(field.id)) {
				return { ...field, is_visible: 1 };
			}
			return field;
		});
		setFilteredCustomFieldData(updatedFields);
		updateReset({
			fieldArray: updatedFields,
		});
	};

	useEffect(() => {
		if (isSuccess) {
			const fields = data?.data;
			updateReset({
				fieldArray: fields,
			});
			setFilteredCustomFieldData(data?.data);
		}
	}, [isSuccess, data?.data, updateReset]);

	return (
		<div>
			<h3 className="text-xl font-semibold">Custom Field Settings</h3>

			<div className="mt-7 flex items-center border-b pb-4">
				<div className=" flex w-full justify-between">
					<Button
						variant={"link"}
						className="h-auto rounded-none border-b-2 border-primary p-0 font-semibold leading-none outline-none hover:no-underline"
						disabled
					>
						View Form
					</Button>
					<AddExistingCustomFieldModal
						modalMode={modalMode}
						setModalMode={setModalMode}
					/>
				</div>
				<FormProvider {...methods}>
					<form>
						<div className="hidden">
							<AddFieldModal
								modalMode={modalMode}
								setModalMode={setModalMode}
								onSubmit={methods.handleSubmit(onSubmit)}
								isLoading={createCustomFieldsLoading}
							/>
						</div>
					</form>
				</FormProvider>
			</div>
			<div className="flex items-center justify-between pt-4">
				<div className="flex items-center space-x-2 pl-14">
					<Checkbox
						isChecked={checkAllCustomFields}
						handleCheckboxChange={handleCheckAllCustomFields}
						className="rounded-sm border-2 border-[#D1D1D1]"
						disabled={!filteredCustomFieldData?.length}
						id={""}
					/>
					<label className="font-medium text-[#7D7E80]">
						Select all
					</label>
				</div>
				<div className="flex space-x-0">
					<Button
						variant="ghost"
						size="icon-sm"
						type="button"
						disabled={!customFieldsIds?.length}
						onClick={handleDeleteMultipleCustomField}
					>
						<HiOutlineTrash
							size={"24"}
							className="text-[#5373A1]"
						/>
					</Button>
					<Button
						variant="ghost"
						type="button"
						className="text-red-500"
						size="icon-sm"
						disabled={!customFieldsIds?.length}
						onClick={handleToggleVisibility}
					>
						<GoEye size={"24"} className="text-primary" />
					</Button>
				</div>
			</div>
			{isLoading ? (
				<div className="flex min-h-48 w-full items-center justify-center">
					<Loader size={40} />
				</div>
			) : (
				<FormProvider {...updateMethods}>
					<form onSubmit={updateHandleSubmit(onUpdateSubmit)}>
						<div className="border-b py-4">
							{filteredCustomFieldData?.length > 0 ? (
								<DndContext
									collisionDetection={closestCenter}
									onDragEnd={handleDragEnd}
								>
									<SortableContext
										items={
											!isLoading &&
											filteredCustomFieldData
												? filteredCustomFieldData?.map(
														(field: any) => field.id
													)
												: []
										}
										strategy={verticalListSortingStrategy}
									>
										{(filteredCustomFieldData || [])?.map(
											(item: any, index: number) => (
												<SortableItem
													key={item?.id || 0}
													field={item}
													index={index}
													control={updateControl}
													register={updateRegister}
													errors={updateErrors}
													customFieldsIds={
														customFieldsIds
													}
													setCustomFieldsIds={
														setCustomFieldsIds
													}
													handleDeleteCustomField={
														handleDeleteCustomField
													}
												/>
											)
										)}
									</SortableContext>
								</DndContext>
							) : (
								<div className="flex h-full flex-col items-center justify-center py-4">
									<i className="mgc_file_line text-[32px] text-primary"></i>
									<h2 className="mt-5 font-semibold">
										No intake field data
									</h2>
									<p className="text-gray-500">
										{data?.data.length > 0
											? "Selected intake fields will be deleted"
											: "Get started by adding a new intake field."}
									</p>
								</div>
							)}
						</div>
						<div className="mx-auto flex w-full max-w-[1000px] justify-end gap-2 py-4">
							<Button
								type="button"
								onClick={handleCancel}
								variant={"ghost"}
							>
								Cancel
							</Button>
							<Button
								type="submit"
								className="px-9"
								disabled={_.isEqual(
									data?.data,
									updateWatch("fieldArray")
								)}
							>
								{updateCustomFieldsLoading ? (
									<Loader size={20} />
								) : (
									"Update"
								)}
							</Button>
						</div>
					</form>
				</FormProvider>
			)}
		</div>
	);
};

export default CustomFieldSettings;
