import { LoaderButton } from "@src/components/form/LoaderButton";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@src/components/ui/accordion";
import { Button } from "@src/components/ui/button";
import { Input } from "@src/components/ui/input";
import { Label } from "@src/components/ui/label";
import { DeleteOscarDetails } from "@src/store/slices/settings/integrations/oscarSlice";
import { OscarDetailsType } from "@src/types/settings/integrations/oscar";
import dayjs from "dayjs";
import React from "react";
import { useQueryClient } from "react-query";

const CompletedAllSteps: React.FC<{
	emrIntegrationInfromation?: OscarDetailsType;
	setEMRIntegrationInformation: React.Dispatch<
		React.SetStateAction<OscarDetailsType | undefined>
	>;
	setIsCompleted: React.Dispatch<React.SetStateAction<boolean>>;
	setCurrentAPIStep: React.Dispatch<React.SetStateAction<number>>;
}> = ({
	emrIntegrationInfromation,
	setIsCompleted,
	setCurrentAPIStep,
	setEMRIntegrationInformation,
}) => {
	const queryClient = useQueryClient();

	const [emrCompletedDetails, setEmrCompletedDetails] = React.useState<
		OscarDetailsType | undefined
	>(
		emrIntegrationInfromation ??
			queryClient.getQueryData("integration-oscar-details")
	);

	const deleteOscarDetailsMutation = DeleteOscarDetails(() => {
		setEmrCompletedDetails(undefined);
		setEMRIntegrationInformation(undefined);
		setIsCompleted(false);
		setCurrentAPIStep(1);
		queryClient.invalidateQueries("integration-oscar-details");
	});

	console.log();

	return (
		<section className="m-1.5 flex flex-1 flex-col items-center pb-4 pt-[57px]">
			<div className="flex items-center space-x-[52px]">
				<img
					src="/assets/images/logo-small-white.svg"
					className="size-16 rounded-xl border border-[#DEDEDE] bg-[#005893] p-3"
					alt="Migranium logo"
				/>
				<svg
					width="16"
					height="16"
					viewBox="0 0 16 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M0 16L7.19836 7.91111L0.163599 0H1.86503L8.01636 6.99259L14.135 0H15.8364L8.80164 7.91111L16 16H14.2658L8.01636 8.82963L1.73415 16H0Z"
						fill="#858C95"
					/>
				</svg>

				<img
					className="size-16 rounded-xl border border-[#28C466] bg-white p-1.5 shadow-[0px_0px_12px_0px_rgba(40,196,102,0.5),0px_0px_20px_0px_rgba(0,0,0,0.1)]"
					src="/assets/images/integration/oscar/oscar.png"
					alt="Oscar Pro logo"
				/>
			</div>
			<div className="mt-12 space-y-3 text-center">
				<p className="text-[24px] font-semibold leading-[30px] text-[#09090B]">
					You are <span className="text-[#28C466]">connected!</span>{" "}
				</p>
				<p className="text-[18px] text-[#71717A]">
					Connected since:{" "}
					{dayjs(emrCompletedDetails?.created_at).format(
						"D MMM YYYY"
					)}
				</p>
			</div>
			<div className="mt-[26px] w-full max-w-[805px] rounded-xl border border-[#E4E4E7] p-6 shadow-[0px_10px_15px_-3px_rgba(0,0,0,0.1),0px_4px_6px_-4px_rgba(16,24,40,0.1)]">
				<Accordion
					type="single"
					collapsible
					className="w-full max-w-[805px]"
				>
					<AccordionItem value="item-1" className="border-none">
						<AccordionTrigger
							className="flex w-full items-center justify-between p-0 hover:no-underline [&[data-state=open]>figure>i]:rotate-180"
							showChevron={false}
						>
							<div className="flex flex-col">
								<h3 className="text-lg font-semibold text-[#09090B]">
									EMR Integration Information
								</h3>
								<p className="text-start text-[14px] font-normal text-[#71717A]">
									View your connection details
								</p>
							</div>
							<Button className="grid size-[38px] place-content-center rounded-lg bg-[#09244B0A] hover:bg-[#09244B0A]">
								<i className="mgc_down_line text-[18px] transition-transform duration-200" />
							</Button>
						</AccordionTrigger>
						<AccordionContent className="pb-0">
							<form className="flex flex-col space-y-4">
								<div className="flex flex-col space-y-4 py-4">
									<div className="flex flex-col space-y-1.5">
										<Label>Client ID / Key</Label>
										<Input
											value={
												emrCompletedDetails?.client_id ??
												""
											}
											className="select-none text-opacity-30 shadow-[0px_1px_2px_0_rgba(16,24,40,0.04)]"
											readOnly
											disabled
										/>
									</div>
									<div className="flex flex-col space-y-1.5">
										<Label>Client Secret</Label>
										<Input
											value={
												emrCompletedDetails?.client_secret ??
												""
											}
											className="select-none text-opacity-30 shadow-[0px_1px_2px_0_rgba(16,24,40,0.04)]"
											readOnly
											disabled
										/>
									</div>
									<div className="flex flex-col space-y-1.5">
										<Label>EMR Base URI</Label>
										<Input
											value={
												emrCompletedDetails?.emr_base_url ??
												""
											}
											className="select-none text-opacity-30 shadow-[0px_1px_2px_0_rgba(16,24,40,0.04)]"
											readOnly
											disabled
										/>
									</div>
								</div>
								<div className="flex justify-between space-x-4">
									<Button
										variant="outline"
										className="self-end border-[#989898] px-6 text-[#989898]"
									>
										Cancel
									</Button>
									<Button className="px-6 text-white">
										Update
									</Button>
								</div>
							</form>
						</AccordionContent>
					</AccordionItem>
				</Accordion>
			</div>
			<div className="mt-10 flex w-full flex-col space-y-3 rounded-lg bg-[#F8F9FB] px-4 py-3.5">
				<div className="space-y-2">
					<h3 className="font-medium leading-[16px] text-[#E33B32]">
						Disconnect
					</h3>
					<p className="text-[14px] leading-[18px] text-[#71717A]">
						Disconnecting this EMR will limit certain functions. You
						may disconnect here or contact{" "}
						<a
							href="mailto:support@migranium.com"
							className="text-primary underline"
						>
							support
						</a>{" "}
						.
					</p>
				</div>
				<LoaderButton
					variant="outline"
					className="self-end border-[#989898] text-[#989898]"
					onClick={() => deleteOscarDetailsMutation.mutate()}
					loaderSize={20}
					loading={deleteOscarDetailsMutation.isLoading}
					disabled={deleteOscarDetailsMutation.isLoading}
				>
					Disconnect
				</LoaderButton>
			</div>
		</section>
	);
};

export default CompletedAllSteps;
