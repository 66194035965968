import { useEffect, useState } from "react";
import { Button } from "../../../components/ui/button";
import General from "../components/settings/General";
import LinkedCalendars from "../components/settings/LinkedCalendars";
import OperationalHours from "../components/settings/OperationalHours";
import ScheduleOptimizer from "../components/settings/ScheduleOptimizer";
import { GetQueueSettingsSlice } from "@/src/store/slices/locations/locationSlice";
import useAppointmentStore from "@/src/store/useAppointmentStore";
import CustomTimeSlots from "../components/settings/CustomTimeSlots";
import { Label } from "@/src/components/ui/label";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "@/src/components/ui/select";
import { useQueryClient } from "react-query";
import useUserStore from "@/src/store/useUserStore";
import Loader from "@/src/components/Loader/Loader";

const ScheduleSettings = () => {
	const queryClient = useQueryClient();
	const [settingTab, setSettingTab] = useState<
		| "General"
		| "Schedule Optimizer"
		| "Operating hours"
		| "Custom Time Slot"
		| "Linked Calendars"
	>("General");

	const tabList = [
		"General",
		"Schedule Optimizer",
		"Operating hours",
		"Custom Time Slot",
		"Linked Calendars",
	];
	const {
		setAppointmentSettings,
		setAppointmentSettingsSelectedStation,
		setShowAppointmentSettingsModal,
	} = useAppointmentStore();

	const { user, currentLocationId } = useUserStore((s) => ({
		user: s.user,
		currentLocationId: s.currentLocationId,
	}));
	// const [selectedLocation, setSelectedLocation] = useState(currentLocationId);
	const [selectedStation, setSelectedStation] = useState(currentLocationId);

	const { data, isLoading, isSuccess } = GetQueueSettingsSlice({
		...(selectedStation
			? { station_id: selectedStation }
			: { location_id: currentLocationId }),
	});

	useEffect(() => {
		if (isSuccess && data) {
			setAppointmentSettings(data?.data);
		}
	}, [isSuccess, data, setAppointmentSettings]);

	useEffect(() => {
		if (selectedStation) {
			setAppointmentSettingsSelectedStation(selectedStation.toString());
		}
	}, []);

	const locations = user?.business?.locations;
	const stations = user?.business?.locations?.flatMap(
		(location) => location?.stations
	);
	const findLocationName = (id: number) => {
		return user?.business?.locations?.find((location) => location.id == id)
			?.name;
	};

	const findStationName = (
		station_id: number,
		multiplelocations: typeof locations
	) => {
		for (const location of multiplelocations!) {
			const station = location?.stations?.find((s) => s.id == station_id);
			if (station) {
				return station.name;
			}
		}
		return null;
	};

	const stationGroups = new Map<string | undefined, typeof stations>();

	stations?.forEach((station) => {
		const locationName = findLocationName(station.location_id);

		if (!stationGroups.has(locationName)) {
			stationGroups.set(locationName, []);
		}

		stationGroups.get(locationName)?.push(station);
	});

	return (
		<>
			<div className="flex  flex-col gap-y-4 bg-white ">
				<div className="border-b pb-2">
					<div className="flex flex-col border-b pb-2">
						<button
							className="self-end"
							onClick={() =>
								setShowAppointmentSettingsModal(false)
							}
						>
							<i className="mgc_close_line text-[16px] before:!text-main-7"></i>
						</button>
						<h1 className="text-[22px] font-semibold text-[#323539]">
							Schedule Settings For{" "}
							{findStationName(selectedStation, locations) ??
								"[Stations]"}{" "}
						</h1>
					</div>

					<div className="flex flex-col gap-y-2.5 pt-6">
						<div>
							<h2 className="text-base font-medium text-main-1">
								Select a Station
							</h2>
							<Label className="text-base font-normal text-main-7">
								Select from the dropdown below to{" "}
								<span className="font-semibold">view</span> and{" "}
								<span className="font-semibold">edit</span> a
								specific station&apos;s schedule settings
							</Label>
						</div>
						<Select
							value={selectedStation?.toString()}
							onValueChange={(e) => {
								// const newLocationId =
								// 	user?.business?.locations.find(
								// 		(location) => location.id == +e
								// 	)?.id ?? 0;
								setSelectedStation(parseInt(e));
								setAppointmentSettingsSelectedStation(e);

								queryClient.invalidateQueries([
									"get-queue-settingss",
									{ station_id: e },
								]);
							}}
						>
							<SelectTrigger className="flex-1">
								<SelectValue />
							</SelectTrigger>
							<SelectContent>
								{/* {stations?.map((station, i) => (
									<SelectItem
										key={i}
										value={station.id.toString()}
									>
										{`${station?.name}-
										${findLocationName(station?.location_id)}`}
									</SelectItem>
								))} */}
								{Array.from(stationGroups.entries()).map(
									([locationName, stations], index) => (
										<SelectGroup
											key={`${locationName}-${index}`}
										>
											<SelectLabel>
												{locationName}
											</SelectLabel>
											{stations?.map((station) => (
												<SelectItem
													key={station?.id}
													value={station?.id?.toString()}
												>
													{station?.name}
												</SelectItem>
											))}
										</SelectGroup>
									)
								)}
							</SelectContent>
						</Select>
					</div>
				</div>

				{/* For the tab selection  */}
				<section className="h-full pt-4">
					{/* The top tabs  */}

					{/* the main content  */}
					<div className="flex gap-8 ">
						<nav className=" flex flex-[0.2] flex-col gap-2 text-sm text-muted-foreground">
							{tabList.map((list: any, index) => (
								<Button
									key={index}
									type="button"
									variant={"ghost"}
									onClick={() => setSettingTab(list)}
									className={`flex items-center justify-start rounded-lg px-3 py-2 font-medium text-[#323539]  opacity-100 hover:bg-transparent hover:text-muted-foreground 
										${settingTab === list ? "bg-muted text-primary hover:bg-muted" : "text-foreground"}`}
								>
									{list}
								</Button>
							))}
						</nav>

						{/* Settings content  */}
						{isLoading ? (
							<div className="mx-auto my-auto h-full w-full ">
								<Loader size={40} />
							</div>
						) : (
							<div className="h-[80%] w-full flex-[0.8]  px-2 ">
								{settingTab === "General" ? (
									<General />
								) : settingTab === "Schedule Optimizer" ? (
									<ScheduleOptimizer />
								) : settingTab === "Operating hours" ? (
									<OperationalHours />
								) : settingTab === "Custom Time Slot" ? (
									<CustomTimeSlots />
								) : settingTab === "Linked Calendars" ? (
									<LinkedCalendars />
								) : (
									<p>Operational Hours</p>
								)}
							</div>
						)}
					</div>
				</section>
			</div>
		</>
	);
};

export default ScheduleSettings;
