import { Switch } from "@src/components/ui/switch";
import useStationStore from "@src/store/useStationStore";
import { Button } from "@src/components/ui/button";
import Loader from "@src/components/Loader/Loader";
import { useEffect, useState } from "react";
import { FaRegFile } from "react-icons/fa";
import {
	GetLinkedCalendarSlice,
	UpdateLinkedCalendarSlice,
} from "@src/store/slices/locations/locationSlice";
import { useSearchParams } from "react-router-dom";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "@src/components/ui/dropdown";
import { PiMicrosoftOutlookLogo } from "react-icons/pi";
import { PiGoogleLogo } from "react-icons/pi";

interface GeneralProps {}

interface ICalendarAccount {
	id: number;
	user_id: number;
	provider_name: string;
	provider_user_id: string;
	email: string;
	check_for_conflict: boolean;
	add_to_calendar: boolean;
	connected?: ICalendar;
}

interface ICalendar {}

const LinkedCalendar: React.FC<GeneralProps> = () => {
	const stationData = useStationStore((s) => s.stationDetails);
	const [searchParams] = useSearchParams();
	const stationId = searchParams.get("stationId") || "";

	// const { invalidateQuery } = GetStationDetailsSlice(
	// 	Number(parseInt(stationId))
	// );

	const [calendarData, setCalendarData] = useState<ICalendarAccount[]>([]);
	const [googleAddLink, setGoogleAddLink] = useState<string>("");
	const [outlookAddLink, setOutlookAddLink] = useState<string>("");

	const { data, invalidateQuery, isLoading } = GetLinkedCalendarSlice(
		Number(parseInt(stationId))
	);

	useEffect(() => {
		if (data?.data) {
			setCalendarData(data?.data?.user_accounts);
			setGoogleAddLink(data?.data?.google_link_url);
			setOutlookAddLink(data?.data?.outlook_link_url);
		}
	}, [data?.data]);

	// const methods = useForm<queueSettingsProps>({
	// 	resolver: zodResolver(queueSettingsSchema),
	// 	defaultValues: {
	// 		schedule_setting: {
	// 			is_visible: 0,
	// 			auto_approve: 0,
	// 			schedule_block_away_count: 0,
	// 			schedule_block_away_option: "weeks",
	// 		},
	// 	},
	// });

	// const {
	// 	handleSubmit,
	// 	reset,
	// 	setValue,
	// 	watch,
	// 	control,
	// 	formState: { errors },
	// } = methods;

	const { mutate: updateLinkedCalendarSettings, isLoading: isUpdating } =
		UpdateLinkedCalendarSlice(
			(data) => {
				console.log("Update successful:", data);
				invalidateQuery();
			},
			(error) => {
				console.error("Update failed:", error);
			}
		);

	const handleSwitchChange =
		(field: any, account_id: any) => (value: boolean) => {
			// setValue(field, value ? 1 : 0);
			updateLinkedCalendarSettings({
				data: {
					[field]: value,
				},
				queryParams: {
					station_id: stationId || 0,
					account_id: account_id || 0,
				},
			});
		};

	// const onSubmit: SubmitHandler<queueSettingsProps> = (data) => {
	// 	updateQueueSettings({
	// 		data: {
	// 			...data,
	// 			apply_to_option: {
	// 				apply_to_all: 0,
	// 				locations: [
	// 					{
	// 						id: stationData?.location_id || 0,
	// 						update_location: 0,
	// 						apply_to_all_stations: 0,
	// 						selected_stations: [stationData?.id || 0],
	// 					},
	// 				],
	// 			},
	// 		},
	// 		queryParams: { station_id: stationId || 0 },
	// 	});
	// };

	// useEffect(() => {
	// 	reset({
	// 		schedule_setting: {
	// 			is_visible:
	// 				stationData?.queue_setting?.schedule_setting?.is_visible ||
	// 				0,
	// 			auto_approve:
	// 				stationData?.queue_setting?.schedule_setting
	// 					?.auto_approve || 0,
	// 			schedule_block_away_count:
	// 				stationData?.queue_setting?.schedule_setting
	// 					?.schedule_block_away_count || 0,
	// 			schedule_block_away_option:
	// 				(stationData?.queue_setting?.schedule_setting
	// 					?.schedule_block_away_option as
	// 					| "days"
	// 					| "weeks"
	// 					| "months") || "weeks",
	// 		},
	// 	});
	// }, [reset, stationData]);

	// const handleSwitchChange = (fieldPath: any) => (value: boolean) => {
	// 	const newValue = value ? 1 : 0;
	// 	setValue(fieldPath, value);
	// 	const updatedData = createNestedObject(fieldPath, newValue);
	// 	updateQueueSettings({
	// 		data: {
	// 			...updatedData,
	// 			apply_to_option: {
	// 				apply_to_all: 0,
	// 				locations: [
	// 					{
	// 						id: stationData?.data?.location_id || 0,
	// 						update_location: 0,
	// 						apply_to_all_stations: 0,
	// 						selected_stations: [stationData?.data?.id || 0],
	// 					},
	// 				],
	// 			},
	// 		},
	// 		queryParams: { station_id: stationData?.data?.id || 0 },
	// 	});
	// 	customToast("Updating queue settings", {
	// 		id: "update-queue-settings",
	// 		type: "loading",
	// 	});
	// };

	const [selectedOption, setSelectedOption] = useState<string | null>(null);

	const handleSelectChange = (value: string) => {
		setSelectedOption(value);
		if (value === "google") {
			window.open(googleAddLink, "_blank", "width=600,height=600");
		} else if (value === "outlook") {
			window.open(outlookAddLink, "_blank", "width=600,height=600");
		}
	};

	return (
		<div className="space-y-4">
			<h3 className="text-xl font-semibold">Linked Calendars</h3>
			<div className="space-y-5">
				<div className="">
					<div className="flex items-center pb-5">
						<div className="flex-1">
							<p className="text-[15px] font-medium">
								Link your calendar to sync all appointments
							</p>
						</div>

						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<Button variant="outline">Link</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent className="w-auto">
								<DropdownMenuGroup>
									<DropdownMenuItem
										onClick={() =>
											handleSelectChange("google")
										}
									>
										<PiGoogleLogo className="mr-2 h-4 w-4" />
										<span>Google</span>
									</DropdownMenuItem>
									<DropdownMenuSeparator />
									<DropdownMenuItem
										onClick={() =>
											handleSelectChange("outlook")
										}
									>
										<PiMicrosoftOutlookLogo className="mr-2 h-4 w-4" />
										<span>Outlook</span>
									</DropdownMenuItem>
								</DropdownMenuGroup>
							</DropdownMenuContent>
						</DropdownMenu>
					</div>
					{isLoading ? (
						<div className="flex min-h-48 w-full items-center justify-center">
							<Loader size={40} />
						</div>
					) : calendarData.length > 0 ? (
						<div className="divide-y border-t">
							{calendarData?.map((account: ICalendarAccount) => (
								<div
									key={account.id}
									className="flex flex-col space-y-4 pb-4 pt-5"
								>
									<div className="flex items-center space-x-1.5">
										<i className="mgc_mail_line text-2xl before:!text-[#1C1B1F]" />
										<h2 className="text-lg font-medium">
											{account.email}
										</h2>
										{/* <i className="mgc_edit_2_line before:!text-gray-400" /> */}
										<Button
											type="button"
											variant={"ghost"}
											className="m-0 p-0 hover:bg-transparent"
											// onClick={handleSubmit(onSubmit)}
										>
											<i className="mgc_edit_2_line before:!text-gray-400" />
										</Button>
									</div>
									<div className="space-y-2.5">
										<div className="flex flex-1 items-center">
											<div className="flex flex-1 flex-col">
												<h3 className="text-[15px] font-medium">
													Check for Conflicts
												</h3>
												<p className="text-sm text-gray-400">
													Will not allow appointments
													if there is a conflict with
													this calendar
												</p>
											</div>
											<div className="flex items-center">
												<Switch
													className="scale-[0.70] data-[state=checked]:bg-[#2EBF43]"
													checked={
														account.check_for_conflict
													}
													onCheckedChange={handleSwitchChange(
														"check_for_conflict",
														account.id
													)}
												/>
												<span className="">
													{account.check_for_conflict
														? "On"
														: "Off"}
												</span>
											</div>
										</div>
										<div className="flex flex-1 items-center">
											<div className="flex flex-1 flex-col">
												<h3 className="text-[15px] font-medium">
													Add to Calendar
												</h3>
												<p className="text-sm text-gray-400">
													Add appointments created on
													Migranium to this calendar
												</p>
											</div>
											<div className="flex items-center">
												<Switch
													className="scale-[0.70] data-[state=checked]:bg-[#2EBF43]"
													checked={
														account.add_to_calendar
													}
													onCheckedChange={handleSwitchChange(
														"add_to_calendar",
														account.id
													)}
												/>
												<span className="">
													{account.add_to_calendar
														? "On"
														: "Off"}
												</span>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					) : (
						<div className="space-y-1 rounded-xl bg-[#F8F8F8] p-6 text-center">
							<FaRegFile
								size={28}
								className="mx-auto text-gray-500"
							/>
							<p className="font-semibold">No calendar linked</p>
							<p className="text-gray-400">
								Accounts will appear here once linked.
							</p>
						</div>
					)}

					{/* <div className="border-b pb-5">
						<div className="mb-8 flex items-center">
							<div className="flex-1">
								<p>Schedule Block</p>
								<p className="text-sm text-gray-400">
									This allows people to book appointments up
									until the time that is selected below
								</p>
							</div>
							<div className="flex items-center">
								<Controller
									name="schedule_setting.schedule_block_away_count"
									control={control}
									render={({ field }) => (
										<Switch
											className="scale-[0.70] data-[state=checked]:bg-[#2EBF43]"
											checked={Boolean(field.value)}
											onCheckedChange={handleSwitchChange(
												"schedule_setting.schedule_block_away_count"
											)}
										/>
									)}
								/>
								<span className="">
									{watch(
										"schedule_setting.schedule_block_away_count"
									)
										? "On"
										: "Off"}
								</span>
							</div>
						</div>
						<div className="flex items-center space-x-4">
							<Slider
								value={[
									watch(
										"schedule_setting.schedule_block_away_count"
									) || 0,
								]}
								max={4}
								step={1}
								onValueChange={(value) => {
									setValue(
										"schedule_setting.schedule_block_away_count",
										value[0]
									);
								}}
							/>

							<div className="focus-within::border-transparent flex h-9 w-max items-center divide-x-2 rounded-md border border-input py-1.5">
								<Input
									// defaultValue={String(
									// 	watch(
									// 		"schedule_setting.schedule_block_away_count"
									// 	)
									// )}
									className="remove-number-input-arrow max-h-full w-[30px] min-w-[48px] max-w-fit border-none text-right text-[14px] font-medium leading-[20px] focus-within:ring-0 focus:outline-none"
									value={String(
										watch(
											"schedule_setting.schedule_block_away_count"
										)
									)}
									onChange={(e) => {
										setValue(
											"schedule_setting.schedule_block_away_count",
											parseInt(e.target.value)
										);
									}}
									min={1}
									type="number"
								/>
								<Select
									value={watch(
										"schedule_setting.schedule_block_away_option"
									)}
									onValueChange={(
										value: "days" | "weeks" | "months"
									) => {
										setValue(
											"schedule_setting.schedule_block_away_option",
											value
										);
									}}
								>
									<SelectTrigger className="focus-within::border-transparent flex h-7 w-max items-center space-x-2.5 rounded-none border-0 px-4 py-0">
										<SelectValue
											placeholder={"Select duration"}
											className="focus-within::border-transparent text-[#858C95]"
										/>
									</SelectTrigger>
									<SelectContent className="focus-within::border-transparent !z-[9999]">
										<SelectGroup>
											{options.map(
												(option: {
													label: string;
													value: string;
												}) => {
													return (
														<SelectItem
															key={option.value}
															value={option.value}
															className="px-8"
														>
															{option.label +
																" " +
																"away"}
														</SelectItem>
													);
												}
											)}
										</SelectGroup>
									</SelectContent>
								</Select>
							</div>
						</div>
					</div> */}
				</div>
			</div>
			{/* <div className="mx-auto flex w-full max-w-[1000px] justify-end gap-2 border-t py-4">
				<Button
					type="reset"
					// onClick={handleCancel}
					variant={"ghost"}
				>
					Cancel
				</Button>
				<Button
					type="submit"
					className="px-9"
					// onClick={handleSubmit(onSubmit)}
				>
					{isLoading ? <Loader size={20} /> : "Update"}
				</Button>
			</div> */}
		</div>
	);
};

export default LinkedCalendar;
