import { BulkNotifyWaitlistSlice } from "@/src/store/slices/waitlist/updateWaitlistSlice";
import {
	BulkNotifyWaitlistCustomersSchema,
	BulkNotifyWaitlistCustomersType,
} from "@/src/types/waitlist/waitlist";
import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { LuX } from "react-icons/lu";
import useCustomToast from "../../CustomToast";
import { LoaderButton } from "../../form/LoaderButton";
import { Button } from "../../ui/button";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "../../ui/dialog";
import { Label } from "../../ui/label";
import { Textarea } from "../../ui/textarea";

const NotifyAllPatients: React.FC<{
	customerIds: number[];
	showBulkNotifyPatientsModal: boolean;
	setShowBulkNotifyPatientsModal: React.Dispatch<
		React.SetStateAction<boolean>
	>;
}> = ({
	customerIds,
	showBulkNotifyPatientsModal,
	setShowBulkNotifyPatientsModal,
}) => {
	const {
		handleSubmit,
		register,
		// setError,
		setValue,
		formState: { errors },
	} = useForm<BulkNotifyWaitlistCustomersType>({
		resolver: zodResolver(BulkNotifyWaitlistCustomersSchema),
	});

	const customToast = useCustomToast();

	const bulkNotifyWaitlistMutation = BulkNotifyWaitlistSlice(
		() => {
			customToast("Message sent", {
				id: "notify-all-patients",
				type: "success",
			});
			setShowBulkNotifyPatientsModal(false);
		},
		() => {
			customToast("Could not send message", {
				id: "notify-all-patients",
				type: "error",
			});
		}
	);

	const onSubmit: SubmitHandler<BulkNotifyWaitlistCustomersType> = async (
		data
	) => {
		customToast("Sending message", {
			id: "notify-all-patients",
			type: "loading",
		});
		bulkNotifyWaitlistMutation.mutate(data);
		console.log(data);
	};

	React.useEffect(() => {
		if (showBulkNotifyPatientsModal) {
			setValue("customer_ids", customerIds);
		}
	}, [showBulkNotifyPatientsModal]);

	return (
		<Dialog
			onOpenChange={() => {
				if (!bulkNotifyWaitlistMutation.isLoading) {
					setShowBulkNotifyPatientsModal(
						!showBulkNotifyPatientsModal
					);
				}
			}}
			open={showBulkNotifyPatientsModal}
		>
			<DialogContent className="max-w-[400px]">
				<form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
					<DialogHeader className="pb-0">
						<div className="flex justify-between">
							<DialogTitle className="text-[22px] leading-[30px] -tracking-[1%] text-[#323539]">
								Notify All Patients
							</DialogTitle>
							<LuX
								color="#858C95"
								size={20}
								className="cursor-pointer self-center"
								onClick={() => {
									if (!bulkNotifyWaitlistMutation.isLoading)
										setShowBulkNotifyPatientsModal(false);
								}}
							/>
						</div>
						<DialogDescription>
							Notify all patients in the waitlist
						</DialogDescription>
					</DialogHeader>

					<div className="space-y-1.5">
						<Label>Message</Label>
						<Textarea
							{...register("message")}
							placeholder="Type in message"
							className="w-full"
						/>
						{errors.message?.message && (
							<small className="!-mt-0 px-6 text-sm tracking-[-0.1px] text-red-500">
								{errors.message?.message}
							</small>
						)}
					</div>
					<DialogFooter className="flex justify-end">
						<Button
							variant="ghost"
							disabled={bulkNotifyWaitlistMutation.isLoading}
							onClick={() =>
								setShowBulkNotifyPatientsModal(false)
							}
						>
							Cancel
						</Button>

						<LoaderButton
							disabled={
								bulkNotifyWaitlistMutation.isLoading ||
								!customerIds.length
							}
							loading={bulkNotifyWaitlistMutation.isLoading}
							loaderSize={18}
							className="max-w-[176px] px-8 text-white"
						>
							Send Message
						</LoaderButton>
					</DialogFooter>
				</form>
			</DialogContent>
		</Dialog>
	);
};

export default NotifyAllPatients;
