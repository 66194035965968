import { z } from "zod";

const BaseCustomFieldSchema = z.object({
	id: z.number(),
	is_visible: z.number(),
	key: z.string(),
	name: z.string().min(1, { message: "Field name is required" }),
	subtitle: z.string(),
	field_requirement: z.enum(["yes", "no"]),
	apply_to: z.enum(["all", "schedule_only", "waitlist_only"]),
	type: z.enum([
		"checkbox",
		"text",
		"dropdown",
		"infoText",
		"infoImage",
		"numeric",
	]), // Add other types if needed
	created_at: z.string().datetime(),
	updated_at: z.string().datetime(),
	relation_id: z.number(),
});

const CheckboxFieldSchema = BaseCustomFieldSchema.extend({
	type: z.literal("checkbox"),
	options: z.array(z.string()),
	allow_multiple: z.number().min(0).max(1),
});

const TextFieldSchema = BaseCustomFieldSchema.extend({
	type: z.literal("text"),
	long_text: z.number().optional(),
});

const DropdownFieldSchema = BaseCustomFieldSchema.extend({
	type: z.literal("dropdown"),
	options: z.array(z.string()),
	allow_multiple: z.number().min(0).max(1),
});

const InfoTextFieldSchema = BaseCustomFieldSchema.extend({
	type: z.literal("infoText"),
});

const InfoImageFieldSchema = BaseCustomFieldSchema.extend({
	type: z.literal("infoImage"),
	image_url: z.string(),
});
const InfoNumericFieldSchema = BaseCustomFieldSchema.extend({
	type: z.literal("numeric"),
	numeric_unit_title: z.string().nullable().optional(),
});

const CustomFieldSchema = z.discriminatedUnion("type", [
	CheckboxFieldSchema,
	TextFieldSchema,
	DropdownFieldSchema,
	InfoTextFieldSchema,
	InfoImageFieldSchema,
	InfoNumericFieldSchema,
]);

const CustomFieldsArraySchema = z.array(CustomFieldSchema);

export const AddRemoveBulkCustomFieldSchema = z
	.object({
		location_id: z.number().optional(),
		station_id: z.number().optional(),
		custom_field_ids_to_add: z.array(z.number()).optional(),
		custom_field_ids_to_remove: z.array(z.number()).optional(),
	})
	.refine(
		(data) => {
			const hasLocationOrStation =
				data.location_id !== undefined || data.station_id !== undefined;
			const hasCustomFieldIds =
				data.custom_field_ids_to_add !== undefined ||
				data.custom_field_ids_to_remove !== undefined;
			return hasLocationOrStation && hasCustomFieldIds;
		},
		{
			message:
				"At least one of 'location_id' or 'station_id' and one of 'custom_field_ids_to_add' or 'custom_field_ids_to_remove' must be provided.",
		}
	);

export { CustomFieldSchema, CustomFieldsArraySchema };
export type CustomFieldProps = z.infer<typeof CustomFieldSchema>;
export type CustomFieldsArrayProps = z.infer<typeof CustomFieldsArraySchema>;
export type AddRemoveBulkCustomFieldProps = z.infer<
	typeof AddRemoveBulkCustomFieldSchema
>;
