import { zodResolver } from "@hookform/resolvers/zod";
import { LoaderButton } from "@src/components/form/LoaderButton";
import { Button } from "@src/components/ui/button";
import { Input } from "@src/components/ui/input";
import { Label } from "@src/components/ui/label";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "@src/components/ui/select";
import { useGetUserInformation } from "@src/hooks/useAuthData";
import { UpdateAccountSettings } from "@src/store/slices/settings/accountSettingsSlice";
import useUserStore from "@src/store/useUserStore";
import {
	UserAccountSettingSchema,
	UserAccountSettingType,
} from "@src/types/settings/account";
import { countryCodes } from "@src/utils/constants";
import { cn } from "@src/utils/functions";
import { isEqual } from "lodash";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";

const ProfileInformation: React.FC = () => {
	const user = useUserStore((s) => s.user);

	const updateAccountSettingsMutation = UpdateAccountSettings(() =>
		getUserInformationMutation.mutate({})
	);
	const getUserInformationMutation = useGetUserInformation(() =>
		setTimeout(() => {
			reset();
		}, 2000)
	);

	const initialFormState = {
		full_name: user?.name.split(" ")[0] ?? "",
		email: user?.email,
		phone_number: user?.phone_number ? user?.phone_number.slice(-10) : "",
	};

	const {
		register,
		handleSubmit,
		// setValue,
		setError,
		reset,
		watch,
		getValues,
		formState: { errors },
	} = useForm<UserAccountSettingType>({
		resolver: zodResolver(UserAccountSettingSchema),
		defaultValues: initialFormState,
	});

	const [countryCode, setCountryCode] = React.useState(
		user?.phone_number ? user?.phone_number.slice(0, -10) : "+1"
	);
	const [firstName, setFirstName] = React.useState(
		user?.name.split(" ")[1] ?? ""
	);
	const [isEditing, setIsEditing] = React.useState(false);
	const [isDisabled, setIsDisabled] = React.useState(true);

	const onSubmit: SubmitHandler<UserAccountSettingType> = async (data) => {
		try {
			updateAccountSettingsMutation.mutate({
				...data,
				full_name: (data.full_name + " " + firstName).trim(),
				phone_number: data.phone_number
					? (countryCode + data.phone_number).trim()
					: undefined,
			});
		} catch (error) {
			setError("root", {
				message: "An error occured kindly try again later",
			});
		}
	};

	React.useEffect(() => {
		const subscription = watch(() => {
			// Combine the form state with the first name and compare to the initial state
			const isFormUnchanged = isEqual(getValues(), initialFormState);
			const isLastNameUnchanged =
				(user?.name.split(" ")[1] ?? "") === firstName;

			setIsDisabled(isFormUnchanged && isLastNameUnchanged);
		});

		// Run initially to set the correct disabled state
		setIsDisabled(
			isEqual(getValues(), initialFormState) &&
				(user?.name.split(" ")[1] ?? "") === firstName
		);

		return () => subscription.unsubscribe();
	}, [watch, firstName, initialFormState]);

	return (
		<section>
			<h2 className="text-[22px] font-semibold leading-[24px] tracking-[-1%] text-[#323539]">
				Profile Information
			</h2>
			<form
				className="mt-3 grid grid-cols-2 gap-4"
				onSubmit={handleSubmit(onSubmit)}
			>
				<div className="flex flex-col space-y-1.5">
					<Label>
						Last Name <span className="text-red-500">*</span>
					</Label>
					<Input
						className="border border-[#E4E4E7] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
						{...register("full_name")}
						disabled={!isEditing}
					/>
					{errors.full_name && errors?.full_name?.message?.length && (
						<small className="mt-1.5 text-sm leading-[16px] text-red-500">
							{errors.full_name.message}
						</small>
					)}
				</div>
				<div className="flex flex-col space-y-1.5">
					<Label>First Name</Label>
					<Input
						className="border border-[#E4E4E7] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
						value={firstName}
						onChange={(e) => setFirstName(e.target.value)}
						disabled={!isEditing}
					/>
				</div>
				<div className="col-span-2 flex flex-col space-y-1.5">
					<div>
						<Label>
							Email Address{" "}
							<span className="text-red-500">*</span>
						</Label>
						<small className="block text-[14px] leading-[16px] text-[#71717A]">
							Once you&apos;ve updated the email, we will send you
							a new verification link
						</small>
					</div>
					<Input
						className="border border-[#E4E4E7] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
						{...register("email")}
						disabled={!isEditing}
					/>
					{errors.email && errors?.email?.message?.length && (
						<small className="mt-1.5 text-sm leading-[16px] text-red-500">
							{errors.email.message}
						</small>
					)}
				</div>
				<div className="col-span-2 flex flex-col space-y-1.5">
					<Label>Phone Number</Label>
					<div className="flex">
						<Select
							value={countryCode}
							onValueChange={(value) => {
								setCountryCode(value);
							}}
							disabled={!isEditing}
						>
							<SelectTrigger className="w-fit rounded-r-none border-r-transparent shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]">
								<SelectValue
									className="text-[#858C95]"
									placeholder="+1"
								/>
							</SelectTrigger>
							<SelectContent className="!z-[9999]">
								<SelectGroup>
									<SelectLabel className="px-2">
										Country Codes
									</SelectLabel>

									{countryCodes.map((option) => {
										return (
											<SelectItem
												key={option.value}
												value={option.value}
												className="px-8"
											>
												{option.label}
											</SelectItem>
										);
									})}
								</SelectGroup>
							</SelectContent>
						</Select>
						<Input
							className="rounded-l-none border border-[#E4E4E7] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
							disabled={!isEditing}
							minLength={11}
							maxLength={11}
							{...register("phone_number", {
								minLength: 11,
								maxLength: 11,
							})}
						/>
					</div>
					{errors.phone_number &&
						errors?.phone_number?.message?.length && (
							<small className="mt-1.5 text-sm leading-[16px] text-red-500">
								{errors.phone_number.message}
							</small>
						)}
				</div>
				<div className="col-span-2 ml-auto space-x-4">
					{isEditing ? (
						<>
							<Button
								variant="outline"
								className="shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
								type="button"
								disabled={
									updateAccountSettingsMutation.isLoading
								}
								onClick={(e) => {
									e.preventDefault();
									setIsEditing(false);
									reset();
									setCountryCode(
										user?.phone_number?.slice(0, -10) ??
											"+1"
									);
									setFirstName(
										user?.name.split(" ")[1] ?? ""
									);
								}}
							>
								Cancel
							</Button>
							<LoaderButton
								className={cn("text-white", {
									"text-[#A1A1AA]": isDisabled,
								})}
								disabled={
									isDisabled ||
									updateAccountSettingsMutation.isLoading
								}
								loading={
									updateAccountSettingsMutation.isLoading
								}
								variant={isDisabled ? "secondary" : "default"}
								loaderSize={20}
								type="submit"
							>
								Save
							</LoaderButton>
						</>
					) : (
						<Button
							className="text-white"
							type="button"
							onClick={(e) => {
								e.preventDefault();
								setIsEditing(true);
							}}
						>
							Edit
						</Button>
					)}
				</div>
			</form>
		</section>
	);
};

export default ProfileInformation;
