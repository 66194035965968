import { z } from "zod";

export const CustomizationSchema = z
	.object({
		id: z.number().optional(),
		name: z.string().min(1, "Name is required"),
		subtitle: z.string().optional(),
		type: z
			.enum([
				"text",
				"numeric",
				"boolean",
				"date",
				"dropdown",
				"checkbox",
				"attachment",
				"infoImage",
				"infoText",
				"",
			])
			.refine((value) => value !== "", {
				message: "Input type is required",
			}),
		numeric_unit_title: z.string().optional(),
		info_text_value: z.string().optional(),
		long_text: z.number().optional(),
		date_range: z.number().optional(),
		date_range_value: z
			.object({
				from: z.date(),
				to: z.date(),
			})
			.refine((data) => data.from <= data.to, {
				message: "End date must be after start date",
				path: ["to"],
			})
			.nullable()
			.optional(),
		options: z.array(z.string()).optional(),
		allow_multiple: z.number().optional(),
		image: z.string().optional(),
		approved_formats: z
			.array(z.enum(["Png", "Pdf", "Jpeg", "Csv"]))
			.optional(),
		apply_to: z.enum(["all", "schedule_only", "waitlist_only"]),
		field_requirement: z.enum(["yes", "new_customers_only", "no"]),
		apply_to_option: z.object({
			apply_to_all: z.number(),
			locations: z.array(
				z.object({
					id: z.number(),
					update_location: z.number(),
					apply_to_all_stations: z.number(),
					selected_stations: z.array(z.number()),
				})
			),
		}),
	})
	.refine(
		(data) => {
			if (data.type === "infoImage") {
				return !!data.image && data.image.trim() !== "";
			}
			return true;
		},
		{
			message: "Image is required",
			path: ["image"],
		}
	)
	.refine(
		(data) => {
			if (["dropdown", "checkbox"].includes(data.type)) {
				return Array.isArray(data.options) && data.options.length > 0;
			}
			return true;
		},
		{
			message:
				"options are required and must not be empty for dropdown or checkbox types",
			path: ["options"],
		}
	);

const FieldTypes = [
	"text",
	"numeric",
	"boolean",
	"date",
	"dropdown",
	"checkbox",
	"attachment",
	"infoImage",
	"infoText",
	"date_range",
	"",
] as const;

const ApprovedFormats = ["Png", "Pdf", "Jpeg", "Csv"] as const;
// const base64Regex = /^data:image\/(png|jpg|jpeg|gif);base64,/;

export const AddCustomizationFieldSchema = z
	.object({
		id: z.number().optional(),
		name: z.string().min(1, "Name is required"),
		subtitle: z.string().optional(),
		type: z
			.enum(FieldTypes)
			.refine((value) => value !== undefined && value !== "", {
				message: "Input type is required",
			}),
		numeric_unit_title: z.string().optional(),
		info_text_value: z.string().optional(),
		long_text: z.number().optional(),
		date_range: z.number().optional(),
		date_range_value: z
			.object({
				from: z.coerce.date(),
				to: z.coerce.date(),
			})
			.refine((data) => data?.from <= data?.to, {
				message: "End date must be after start date",
				path: ["to"],
			})
			.nullish(),
		// .optional(),
		options: z.array(z.string()).optional(),
		allow_multiple: z.number().optional(),
		image: z
			.string()
			// .refine((value) => base64Regex.test(value), {
			// 	message: "Image must be a valid base64 data URL",
			// 	path: ["image"],
			// })
			.optional(),
		approved_formats: z.array(z.enum(ApprovedFormats)).optional(),
		apply_to: z.enum(["all", "schedule_only", "waitlist_only"]),
		field_requirement: z.enum(["yes", "new_customers_only", "no"]),
		apply_to_option: z
			.object({
				apply_to_all: z.number(),
				locations: z.array(
					z.object({
						id: z.number(),
						update_location: z.number(),
						apply_to_all_stations: z.number(),
						selected_stations: z.array(z.number()),
					})
				),
			})
			.optional(),
	})
	// .refine(
	// 	(data) => {
	// 		if (data.type === "infoImage") {
	// 			return !!data.image && data.image.trim() !== "";
	// 		}
	// 		return true;
	// 	},
	// 	{
	// 		message: "Image is required for infoImage type",
	// 		path: ["image"],
	// 	}
	// )
	.refine(
		(data) => {
			if (["dropdown", "checkbox"].includes(data.type)) {
				return Array.isArray(data.options) && data.options.length > 0;
			}
			return true;
		},
		{
			message: "Options are required for dropdown or checkbox types",
			path: ["options"],
		}
	);

export const CustomizationTagSchema = z.object({
	apply_to: z.enum(["all", "schedule_only", "waitlist_only"]),
	field_requirement: z.enum(["yes", "new_customers_only", "no"]),
	apply_to_option: z.object({
		apply_to_all: z.number(),
		locations: z.array(
			z.object({
				id: z.number(),
				update_location: z.number(),
				apply_to_all_stations: z.number(),
				selected_stations: z.array(z.number()),
			})
		),
	}),
});

export const UpdateCustomizationFieldSchema = z.object({
	id: z
		.number()
		.nullable()
		.optional()
		.refine((value) => value !== undefined && value !== null, {
			message: "Custom field is required",
		}),
	apply_to: z.enum(["all", "schedule_only", "waitlist_only"]),
	field_requirement: z.enum(["yes", "new_customers_only", "no"]),
});

export type CustomizationProps = z.infer<typeof CustomizationSchema>;
export type UpdateCustomizationFieldProps = z.infer<
	typeof UpdateCustomizationFieldSchema
>;
export type AddCustomizationFieldProps = z.infer<
	typeof AddCustomizationFieldSchema
>;
