import { CustomSelect } from "@components/form/CustomSelect";
import CustomCheckbox2 from "@src/components/form/CustomCheckbox2";
import { Button } from "@src/components/ui/button";
import { Card } from "@src/components/ui/card";
import MemberList from "./MemberList";
import { AccordionComboboxSearch } from "@src/components/common/CustomSearch";
import {
	GetTeamMembersSlice,
	AddExisitingMemberToLocationSlice,
	CreateTeamMemberSlice,
	GetAvailableTeamMembersSlice,
} from "@src/store/slices/team-members/teamMembersSlice";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { TeamMemberProps, TeamMemberSchema } from "@src/types/TeamMember";
import FormInput from "@src/components/form/FormInput";
import { getErrorMessage } from "@src/utils/functions";
import Loader from "@src/components/Loader/Loader";
import { useQueryClient } from "react-query";

const roleOptions = [
	{ value: "ROOM_MANAGER", label: "Room Manager" },
	{ value: "LOCATION_MANAGER", label: "Location Manager" },
	{ value: "PARTNER_MANAGER", label: "Partner Manager" },
	{ value: "TEAM_MEMBER", label: "Team Member" },
	{ value: "SUPER_ADMIN", label: "Super Admin" },
	{ value: "BUSINESS_ADMIN", label: "Business Admin" },
	{ value: "LOCATION_ADMIN", label: "Location Admin" },
	{ value: "STATION_ADMIN", label: "Station Admin" },
	{ value: "PARTENER_SUPER_ADMIN", label: "Partner Super Admin" },
	{ value: "PARTNER_ADMIN", label: "Partner Admin" },
];

interface MembersProps {}

const Members: React.FC<MembersProps> = () => {
	const queryClient = useQueryClient();
	const [showAddForm, setShowAddForm] = useState(false);
	const [isUpdateTeamMember, setIsUpdateTeamMember] = useState(false);
	const [isAddExisitingTeamMember, setIsAddExisitingTeamMember] =
		useState(false);
	const [searchParams] = useSearchParams();
	const locationId = parseInt(searchParams.get("locationId") || "");

	const { data: availableMemberData, isLoading: availableMemberLoading } =
		GetAvailableTeamMembersSlice();

	const { data, isLoading } = GetTeamMembersSlice({
		location_id: locationId,
	});

	const methods = useForm({
		resolver: zodResolver(TeamMemberSchema),
		defaultValues: {
			members: [],
		},
	});

	const {
		reset,
		control,
		formState: { errors },
	} = methods;

	const {
		register: registerMmeber,
		handleSubmit: handleSubmitMember,
		reset: resetMember,
		setValue: setValueMember,
		getValues: getValuesMember,
		watch: watchMember,
		formState: { errors: errorsMember },
	} = useForm({
		resolver: zodResolver(TeamMemberSchema),
		defaultValues: {
			id: undefined,
			name: "",
			role: "",
			email: "",
			phone_number: "",
		},
	});

	const { fields, append, update, remove } = useFieldArray<any>({
		control,
		name: "members",
		keyName: "_id" as "id",
	});

	const {
		mutate: addExisitingMemberToLocationMutation,
		isLoading: addExisitingMemberToLocationLoading,
	} = AddExisitingMemberToLocationSlice(
		(data) => {
			const payload = {
				id: data?.data?.id,
				name: data?.data?.name,
				role: data?.data?.role,
				email: data?.data?.email,
				phone_number: data?.data?.phone_number,
			};
			append(payload);
			setShowAddForm(false);
			setIsAddExisitingTeamMember(false);
		},
		() => {
			setIsAddExisitingTeamMember(false);
		}
	);

	const {
		mutate: createTeamLocationMutation,
		isLoading: addCreateTeamMemberLoading,
	} = CreateTeamMemberSlice(
		(data) => {
			// console.log("🚀 ~ data:", data);
			// const index = fields.findIndex(
			// 	(item) => item.id === data?.data?.id
			// );
			queryClient.invalidateQueries([
				"get-team-members",
				{ location_Id: locationId },
			]);
			const payload = {
				id: data?.data?.id,
				name: data?.data?.name,
				role: data?.data?.role,
				email: data?.data?.email,
				phone_number: data?.data?.phone_number,
			};
			// if (index !== -1) {
			// 	update(index, payload);
			// } else {
			// }
			append(payload);
			resetMember({
				id: undefined,
				name: "",
				role: "",
				email: "",
				phone_number: "",
			});
			setShowAddForm(false);
		},
		(error) => console.log("🚀 ~ LocationTabList ~ error:", error)
	);

	const handleChange = (value: string) => {
		const member = availableMemberData?.data?.find(
			(item: any) => item.id === value
		);

		addExisitingMemberToLocationMutation({
			data: {
				...member,
				role: "LOCATION_MANAGER",
				user_id: member.id,
			},
			queryParams: { location_id: locationId },
		});
	};

	const handleAddNewMember = () => {
		setShowAddForm(true);
		setIsUpdateTeamMember(false);
		resetMember();
	};

	const onSubmit = (data: TeamMemberProps) => {
		if (isUpdateTeamMember) {
			addExisitingMemberToLocationMutation({
				data: { ...data, user_id: data.id },
				queryParams: { location_id: locationId },
			});
		} else {
			createTeamLocationMutation({
				data: data,
				queryParams: { location_id: locationId },
			});
		}
	};

	useEffect(() => {
		if (data?.data) reset({ members: (data?.data as any) || [] });
	}, [reset, data?.data]);

	return (
		<div className="space-y-4">
			<Card className="space-y-2 p-4">
				<div className="flex items-center justify-between">
					<p className="font-medium">
						Add from Existing Team members
					</p>
					{/* <p className=" text-primary">Add new custom fields</p> */}
					<Button
						type="button"
						variant={"link"}
						className="h-auto rounded-none border-b-2 border-primary p-0 font-semibold leading-none outline-none hover:no-underline"
						onClick={handleAddNewMember}
					>
						{/* <i className="mgc_user_add_line mr-2 text-sm" /> */}
						Add New Team Members
					</Button>
				</div>
				{/* <CustomSelect placeholder="Select all the applies" /> */}
				<AccordionComboboxSearch
					options={
						availableMemberData?.data?.map((item: any) => ({
							value: item.id,
							label: item.name,
						})) || []
					}
					placeholder="Select all the applies"
					emptyMessage="No options found."
					onSelect={handleChange}
				/>
			</Card>

			{showAddForm && (
				<Card className="p-4">
					<div className="flex items-center justify-between space-x-1 rounded-lg bg-gray-100 p-3">
						<div className="grid flex-1 grid-cols-4 gap-3">
							<div>
								<FormInput
									inputType={"text"}
									label="Member Name  * "
									register={{
										...registerMmeber("name"),
									}}
									className="h-10"
									error={getErrorMessage(
										errorsMember?.name?.message
									)}
								/>
							</div>
							<div className="flex-1">
								<label className="bt-1.5 mb-1.5 block text-sm font-medium tracking-[-0.1px] text-[#323539]">
									Role *
								</label>
								<CustomSelect
									placeholder="Select"
									label="Role  *"
									className="h-10"
									options={roleOptions}
									defaultValue={getValuesMember("role")}
									value={watchMember("role")}
									onChange={(value) => {
										setValueMember("role", value);
									}}
									error={getErrorMessage(
										errorsMember.role?.message
									)}
								/>
							</div>
							<div>
								<FormInput
									inputType={"text"}
									label="Email Address "
									register={{
										...registerMmeber("email"),
									}}
									className="h-10"
									error={getErrorMessage(
										errorsMember.email?.message
									)}
									minLength={5}
									maxLength={40}
								/>
							</div>
							<div>
								<FormInput
									inputType={"text"}
									label="Phone Number"
									register={{
										...registerMmeber("phone_number"),
									}}
									className="h-10"
									error={getErrorMessage(
										errorsMember.phone_number?.message
									)}
									minLength={5}
									maxLength={40}
								/>
							</div>
						</div>
					</div>
					<div className="mt-2 flex items-center justify-end">
						<Button
							type="button"
							size="icon-sm"
							className="w-[90px] p-1"
							onClick={handleSubmitMember(onSubmit)}
						>
							{addCreateTeamMemberLoading ||
							addExisitingMemberToLocationLoading ? (
								<div className="">
									<Loader size={20} />
								</div>
							) : isUpdateTeamMember ? (
								"Update"
							) : (
								"Add"
							)}
						</Button>
					</div>
				</Card>
			)}

			{availableMemberData?.data.length > 0 ? (
				<>
					<div className="flex items-center justify-between">
						<div className="flex items-center space-x-2">
							<CustomCheckbox2
								isChecked
								handleCheckboxChange={() => {}}
								className="checked:border-[#195388] checked:bg-[#195388]"
								id={""}
							/>
							<label className="font-normal text-gray-400">
								Select all
							</label>
						</div>
						<Button variant="secondary">
							<i className="mgc_delete_2_line text-[16px] text-primary"></i>
						</Button>
					</div>

					<div className="w-full space-y-2">
						<FormProvider {...methods}>
							{fields.length > 0 &&
								fields.map((item: any) => (
									<div key={item.id}>
										<MemberList
											member={item}
											role={roleOptions}
											locationId={locationId}
										/>
									</div>
								))}
						</FormProvider>
					</div>
				</>
			) : (
				<div className="flex flex-col items-center justify-center py-4">
					<i className="mgc_file_line text-[32px] text-primary"></i>
					<h2 className="mt-5 font-semibold">No custom fields</h2>
					<p className="text-gray-500">
						Get started by adding a new custom field.
					</p>
				</div>
			)}
		</div>
	);
};

export default Members;
