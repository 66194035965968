import { queueSettingsProps } from "@/src/types/Location";
import AddLocationTimer from "@src/components/Onboarding/AddLocationTimer";
import { ScrollArea } from "@src/components/ui/scroll-area";
import { OperatingHour } from "@src/types/DaySlots";
import React from "react";
import { useFormContext, UseFormReturn } from "react-hook-form";
import { isEqual } from "lodash";

const OperatingHours: React.FC<{
	formMethods: UseFormReturn<queueSettingsProps>;
}> = () => {
	const {
		// control,
		// register,
		watch,
		setValue,
		getValues,
		// trigger,
		formState: { errors },
	} = useFormContext<queueSettingsProps>();
	const [slots, setSlots] = React.useState<OperatingHour[]>([]);

	React.useEffect(() => {
		setSlots(getValues("working_hours") ?? []);
	}, [watch("working_hours")]);

	React.useEffect(() => {
		if (slots.length && !isEqual(getValues("working_hours"), slots))
			setValue("working_hours", slots);
	}, [slots]);

	return (
		<div className="flex flex-1 flex-col self-stretch">
			<div className="pl-12 pr-6">
				<h2 className="text-[22px] font-semibold leading-[30px] tracking-[-1%] text-[#323539]">
					Operating Hours Settings
				</h2>
			</div>
			<ScrollArea className="mt-6 max-h-[482px] flex-1 border-b border-[#1018280A] pl-12 pr-6">
				<div className="flex flex-col space-y-4 border-t border-[#979AA0] pt-5">
					{(watch("working_hours") ?? [])?.length > 0 &&
						slots.map((slot, i) => (
							<AddLocationTimer
								{...slot}
								key={i}
								index={i}
								slots={slots}
								shouldShowDay
								shouldShowPlus
								setSlots={setSlots as any}
							/>
						))}
				</div>
			</ScrollArea>
		</div>
	);
};

export default OperatingHours;
