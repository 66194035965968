import { zodResolver } from "@hookform/resolvers/zod";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@src/components/ui/dialog";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@src/components/ui/select";
import UpdateWaitlistSlice from "@src/store/slices/waitlist/updateWaitlistSlice";
import useUserStore from "@src/store/useUserStore";
import {
	QueueEntry,
	UpdateWaitlistSchema,
	UpdateWaitlistType,
} from "@type/waitlist/waitlist";
import React, { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { LuX } from "react-icons/lu";
import useCustomToast from "../../CustomToast";
import { LoaderButton } from "../../form/LoaderButton";
import { Button } from "../../ui/button";
import { Label } from "../../ui/label";

const ChangeStation: React.FC<{
	patientDetails?: QueueEntry;
	showChangePatientStationModal: boolean;
	setShowChangePatientStationModal: React.Dispatch<
		React.SetStateAction<boolean>
	>;
}> = ({
	patientDetails,
	showChangePatientStationModal,
	setShowChangePatientStationModal,
}) => {
	const {
		handleSubmit,
		// setError,
		setValue,
		formState: { errors },
	} = useForm<UpdateWaitlistType>({
		resolver: zodResolver(UpdateWaitlistSchema),
		defaultValues: {
			status: "change_station",
		},
	});
	const customToast = useCustomToast();

	const updateWaitlistMutation = UpdateWaitlistSlice(
		() => {
			customToast("Updated patient's station", {
				id: "change-patient-station",
				type: "success",
			});
			setShowChangePatientStationModal(false);
		},
		() => {
			customToast("Could not update patient's station", {
				id: "change-patient-station",
				type: "error",
			});
		}
	);

	const selectedLocationId = useUserStore((s) => s.currentLocationId);

	const stationsList =
		useUserStore((s) => s.user)
			?.business.locations.filter(
				(location) => location.id === selectedLocationId
			)[0]
			.stations.filter(
				(station) => station.id !== patientDetails?.station_id
			) || [];

	const onSubmit: SubmitHandler<UpdateWaitlistType> = async (data) => {
		customToast("Updating patient's station", {
			id: "change-patient-station",
			type: "loading",
		});
		updateWaitlistMutation.mutate(data);
		// console.log(data);
	};

	return (
		<Dialog
			onOpenChange={() => {
				// if (!manageWaitlistMutation.isLoading) {
				// 	reset();
				setShowChangePatientStationModal(
					!showChangePatientStationModal
				);
				// }
			}}
			open={showChangePatientStationModal}
		>
			<DialogContent className="max-w-[400px]">
				<form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
					<DialogHeader className="pb-0">
						<div className="flex justify-between">
							<DialogTitle className="text-[22px] leading-[30px] -tracking-[1%] text-[#323539]">
								Change Station
							</DialogTitle>
							<LuX
								color="#858C95"
								size={20}
								className="cursor-pointer self-center"
								onClick={() => {
									if (!updateWaitlistMutation.isLoading)
										setShowChangePatientStationModal(false);
								}}
							/>
						</div>
						<DialogDescription>
							Current Station: {patientDetails?.station_name}
						</DialogDescription>
					</DialogHeader>
					<div className="grid w-full items-center gap-4">
						<div className="flex flex-col space-y-1.5">
							<Label>Change station to</Label>

							<Select
								onValueChange={(value) => {
									setValue("station_id", value);
									setValue(
										"waitlist_id",
										patientDetails?.waitlist_id ?? 0
									);
								}}
							>
								<SelectTrigger>
									<SelectValue
										placeholder="Select a station"
										className="text-[#858C95]"
									/>
								</SelectTrigger>
								<SelectContent>
									{stationsList.length ? (
										stationsList.map((item, i) => (
											<SelectItem
												key={i}
												value={item.id.toString()}
											>
												{item.name}
											</SelectItem>
										))
									) : (
										<SelectItem
											value={"no-options"}
											disabled
										>
											No Station
										</SelectItem>
									)}
								</SelectContent>
							</Select>
						</div>
					</div>

					{errors.station_id?.message && (
						<small className="!-mt-0 px-6 text-sm tracking-[-0.1px] text-red-500">
							{errors.station_id?.message}
						</small>
					)}
					<DialogFooter className="flex justify-end">
						<Button
							variant="ghost"
							disabled={updateWaitlistMutation.isLoading}
							onClick={() =>
								setShowChangePatientStationModal(false)
							}
						>
							Cancel
						</Button>

						<LoaderButton
							disabled={updateWaitlistMutation.isLoading}
							loading={updateWaitlistMutation.isLoading}
							loaderSize={18}
							className="max-w-[176px] px-8 text-white"
						>
							Update Station
						</LoaderButton>
					</DialogFooter>
				</form>
			</DialogContent>
		</Dialog>
	);
};

export default ChangeStation;
