import { createContext, useContext, useState } from "react";

const FormContext = createContext<{
	formData: any;
	setFormData: React.Dispatch<React.SetStateAction<any>>;
} | null>(null);

export const useFormContext = () => {
	const context = useContext(FormContext);
	if (!context) {
		throw new Error("useFormContext must be used within a FormProvider");
	}
	return context;
};

export const AppointmentFormProvider = ({ children }) => {
	const [formData, setFormData] = useState<any>({
		patientDetails: {},
		appointmentDetails: {},
		customFieldDetails: {},
	});

	return (
		<FormContext.Provider value={{ formData, setFormData }}>
			{children}
		</FormContext.Provider>
	);
};
