import { Option } from "@/src/components/form/CustomSelect";
import UpdateWaitlistSlice from "@/src/store/slices/waitlist/updateWaitlistSlice";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@src/components/ui/dialog";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@src/components/ui/select";
import {
	QueueData,
	QueueEntry,
	UpdateWaitlistSchema,
	UpdateWaitlistType,
} from "@type/waitlist/waitlist";
import { AxiosResponse } from "axios";
import { isEqual } from "lodash";
import { NumberOrdinal } from "ordinalify";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { LuX } from "react-icons/lu";
import { useQueryClient } from "react-query";
import useCustomToast from "../../CustomToast";
import { LoaderButton } from "../../form/LoaderButton";
import { Button } from "../../ui/button";
import { Label } from "../../ui/label";

const ChangePositionOrderModal: React.FC<{
	patientDetails?: QueueEntry;
	showChangePositionOrderModal: boolean;
	setShowChangePositionOrderModal: React.Dispatch<
		React.SetStateAction<boolean>
	>;
}> = ({
	patientDetails,
	showChangePositionOrderModal,
	setShowChangePositionOrderModal,
}) => {
	const {
		handleSubmit,
		setError,
		reset,
		watch,
		getValues,
		setValue,
		formState: { errors },
	} = useForm<UpdateWaitlistType>({
		resolver: zodResolver(UpdateWaitlistSchema),
	});

	const customToast = useCustomToast();
	const queryClient = useQueryClient();

	const [newPosition, setNewPosition] = React.useState<number | null>(null);
	const [isDisabled, setIsDisabled] = React.useState(true);
	const [otherPositions, setOtherPositions] = React.useState<Option[]>([]);

	const updateWaitlistMutation = UpdateWaitlistSlice(
		() => {
			customToast("Updated patient's position", {
				id: "change-patient-position",
				type: "success",
			});
			setShowChangePositionOrderModal(false);
		},
		() => {
			customToast("Could not update patient's position", {
				id: "change-patient-position",
				type: "error",
			});
		}
	);
	const onSubmit: SubmitHandler<UpdateWaitlistType> = async (data) => {
		if (!patientDetails || newPosition === null) return;

		customToast("Updating patient's position", {
			id: "change-patient-position",
			type: "loading",
		});

		const queueData = queryClient.getQueryData<AxiosResponse<QueueData>>([
			"queue-list",
			patientDetails.location_id,
		]);
		if (!queueData) return;

		const nextPosition = queueData.data.queue_order.find(
			(entry) =>
				entry.station_id === patientDetails.station_id &&
				entry.station_order === newPosition
		);

		const newOrderAt = calculateNewOrderAt(
			patientDetails.order_at,
			nextPosition?.order_at
		);

		updateWaitlistMutation.mutate({
			...data,
			waitlist_id: patientDetails.waitlist_id,
			order_at: newOrderAt,
			status: patientDetails.status,
		});
	};

	React.useEffect(() => {
		const subscription = watch(() => {
			setIsDisabled(
				isEqual(getValues(), {
					waitlist_id: patientDetails?.waitlist_id,
				})
			);
		});
		return () => subscription.unsubscribe();
	}, [watch, patientDetails]);

	const calculateNewOrderAt = (
		prevOrderAt: string,
		nextOrderAt?: string
	): string => {
		if (!nextOrderAt) {
			// If there's no next item, add 10 seconds to the previous order_at
			const prevDate = new Date(prevOrderAt);
			prevDate.setSeconds(prevDate.getSeconds() + 10);
			return prevDate.toISOString().slice(0, 19).replace("T", " ");
		}

		// Calculate the midpoint between prevOrderAt and nextOrderAt
		const prevDate = new Date(prevOrderAt);
		const nextDate = new Date(nextOrderAt);
		const midpointDate = new Date(
			(prevDate.getTime() + nextDate.getTime()) / 2
		);
		return midpointDate.toISOString().slice(0, 19).replace("T", " ");
	};

	const handlePositionChange = (value: string) => {
		setNewPosition(Number(value));
		setIsDisabled(false); // Enable submit button once a position is selected
	};

	React.useEffect(() => {
		// Ensure patientDetails is defined before accessing its properties
		if (patientDetails) {
			reset({
				waitlist_id: patientDetails.waitlist_id,
			});

			setOtherPositions(() => {
				const queueData = queryClient.getQueryData<
					AxiosResponse<QueueData>
				>(["queue-list", patientDetails.location_id]);

				if (queueData) {
					const length = queueData.data.queue_order.length;
					return Array.from({ length })
						.map((_, idx) => {
							// Exclude the patient's own number from the result
							if (
								idx + 1 === patientDetails.station_order ||
								patientDetails.station_id !==
									queueData.data.queue_order[idx].station_id
							)
								return null;

							return {
								value: (idx + 1).toString(),
								label: (idx + 1).toString(),
							};
						})
						.filter(Boolean) as Option[];
				}
				return [];
			});
		}
	}, [patientDetails]);

	return (
		<>
			<Dialog
				onOpenChange={() => {
					// if (!manageWaitlistMutation.isLoading) {
					// 	reset();
					setShowChangePositionOrderModal(
						!showChangePositionOrderModal
					);
					// }
				}}
				open={showChangePositionOrderModal}
			>
				<DialogContent className="max-w-[400px] ">
					<form
						onSubmit={handleSubmit(onSubmit)}
						className="space-y-8"
					>
						<DialogHeader className="pb-0">
							<div className="flex justify-between">
								<DialogTitle className="text-[22px] leading-[30px] -tracking-[1%] text-[#323539]">
									Change Patient Position
								</DialogTitle>
								<LuX
									color="#858C95"
									size={20}
									className="cursor-pointer self-center"
									onClick={() => {
										if (!updateWaitlistMutation.isLoading)
											setShowChangePositionOrderModal(
												false
											);
									}}
								/>
							</div>
							<DialogDescription>
								Current Position:{" "}
								{NumberOrdinal(
									patientDetails?.station_order ?? 0
								)}
								{/* {NumberOrdinal(patientDetails?.order)} */}
							</DialogDescription>
						</DialogHeader>
						<div className="grid w-full items-center gap-8">
							{patientDetails?.swap_request_id && (
								<>
									<div className="space-y-2">
										<h3 className="font-semibold tracking-[-1%] text-main-1">
											Desired Position
										</h3>
										<p className="tracking-[-1%] text-main-1">
											{NumberOrdinal(
												patientDetails.swap_request
													.position
											)}{" "}
											Position
										</p>
									</div>
									<div className="space-y-2">
										<h3 className="font-semibold tracking-[-1%] text-main-1">
											Reason
										</h3>
										<p className="tracking-[-1%] text-main-1">
											{patientDetails.swap_request.reason}
										</p>
									</div>
								</>
							)}

							<div className="grid w-full items-center gap-8">
								<div className="flex flex-col space-y-1.5">
									<Label>Change position to</Label>
									<Select
										onValueChange={handlePositionChange}
									>
										<SelectTrigger>
											<SelectValue
												placeholder="Select a position"
												className="text-[#858C95]"
											/>
										</SelectTrigger>
										<SelectContent>
											{otherPositions.map((item, idx) => (
												<SelectItem
													key={item.value + idx}
													value={item.value}
												>
													{item.label}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</div>
							</div>
						</div>

						{errors.root?.message && (
							<small className="!-mt-0 px-6 text-sm tracking-[-0.1px] text-red-500">
								{errors.root?.message}
							</small>
						)}
						<DialogFooter className="flex justify-end">
							<Button
								variant="ghost"
								disabled={updateWaitlistMutation.isLoading}
								onClick={() =>
									setShowChangePositionOrderModal(false)
								}
							>
								Cancel
							</Button>

							<LoaderButton
								disabled={
									updateWaitlistMutation.isLoading ||
									isDisabled
								}
								loading={updateWaitlistMutation.isLoading}
								loaderSize={18}
								className="max-w-[176px] px-8 text-white"
							>
								Update Position
							</LoaderButton>
						</DialogFooter>
					</form>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default ChangePositionOrderModal;
