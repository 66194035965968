import completeIcon from "@assets/images/complete.svg";
import playIcon from "@assets/images/play-icon.svg";
import useCustomToast from "@components/CustomToast";
import CustomCheckbox2 from "@components/form/CustomCheckbox2";
import { Button } from "@components/ui/button";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@components/ui/tooltip";
import { useNotifyPatient } from "@hooks/useLocationData";
import { PatientStatus } from "@type/wait-list";
import { QueueEntry } from "@type/waitlist/waitlist";
import { handleRowCheckboxChange } from "@utils/functions";
import React, { Dispatch, SetStateAction } from "react";
import { LuCheckCircle2 } from "react-icons/lu";
import ChangePosition from "./ChangePatientOrder";
import ChangeStation from "./ChangeStation";
import DeletePatient from "./DeletePatient";
import PatientDetails from "./PatientDetails";
import PatientPriorityModal from "./PatientPriorityModal";
import PatientSentToServingModal from "./PatientSentToServingModal";
import TimeAgoShort from "./TimeAgoShort";

const GridCard: React.FC<{
	selectedRows: number[];
	noOfPatients: number;
	status: PatientStatus;
	index: number;
	patient: QueueEntry;
	setSelectAllChecked: Dispatch<SetStateAction<boolean>>;
	setSelectedRows: Dispatch<SetStateAction<number[]>>;
}> = ({
	selectedRows,
	status,
	index,
	patient,
	noOfPatients,
	setSelectedRows,
	setSelectAllChecked,
}) => {
	const isChecked = selectedRows.includes(patient.waitlist_id);
	const customToast = useCustomToast();
	const notifyPatientMutation = useNotifyPatient(patient.customer_id, () =>
		customToast("Patient notified", {
			id: "notify-patient",
			type: "success",
		})
	);

	return (
		<div
			className={`grid w-full grid-cols-[24px_200px_auto_auto_auto] place-items-center justify-items-stretch gap-2 overflow-visible rounded-lg border-[#E5E5E7] bg-white px-4 py-2 text-left shadow-sm`}
		>
			<div>
				<CustomCheckbox2
					handleCheckboxChange={() =>
						handleRowCheckboxChange(
							!isChecked,
							patient.waitlist_id,
							setSelectedRows,
							noOfPatients,
							setSelectAllChecked
						)
					}
					isChecked={selectedRows.includes(patient.waitlist_id)}
					id={status + " selectAll"}
					className="rounded-sm border-2"
				/>
			</div>

			<div className="flex items-center">
				<div className="flex min-w-[20px] items-center text-xxs">
					<span className="font-semibold text-main-1">
						{(index + 1).toString().padStart(2, "0")}
					</span>
					<i className="mgc_user_3_line ml-2 text-xs" />
					<div className="ml-2 mr-2 h-4 border-l bg-main-3" />
				</div>
				<Tooltip delayDuration={0}>
					<TooltipTrigger asChild>
						<span className="truncate-grid w-auto text-xxs font-semibold text-main-1">
							{patient.customer.full_name}
						</span>
					</TooltipTrigger>
					<TooltipContent side="top" sideOffset={4}>
						{patient.customer.full_name}
					</TooltipContent>
				</Tooltip>
				<div className="flex items-center gap-1 pl-2">
					<PatientDetails patientDetails={patient} />
					<PatientPriorityModal
						patientDetails={patient}
						showPriorityModal={false}
						setShowPriorityModal={function () // value: React.SetStateAction<boolean>
						: void {
							throw new Error("Function not implemented.");
						}}
					/>
				</div>
			</div>

			<div className="flex items-center space-x-1">
				<span className="truncate-grid-station text-xxs font-medium text-[#6B7280]">
					{patient.station_name}
				</span>
				<ChangeStation
					patientDetails={patient}
					showChangePatientStationModal={false}
					setShowChangePatientStationModal={function () // value: React.SetStateAction<boolean>
					: void {
						throw new Error("Function not implemented.");
					}}
				/>
			</div>

			{status === PatientStatus.NextInLine && (
				<p className="text-xxs font-medium text-[#858C95]">
					{patient?.customer.phone_number}
				</p>
			)}

			{(status === PatientStatus.Pending ||
				status === PatientStatus.Upcoming ||
				status === PatientStatus.Serving) && (
				<p className="text-center text-xs font-semibold text-[#111827]">
					<TimeAgoShort patient={patient} />
				</p>
			)}

			<div className="flex items-center justify-end gap-x-1.5">
				<DeletePatient
					patientDetails={patient}
					showDeleteCustomerModal={false}
					setShowDeleteCustomerModal={function () // value: React.SetStateAction<boolean>
					: void {
						throw new Error("Function not implemented.");
					}}
				/>
				<Button
					className="size-[30px] rounded-lg bg-[#F5F5F5] p-0 hover:bg-[#F5F5F5]"
					onClick={() => {
						customToast("Patient being notified", {
							id: "notify-patient",
							type: "loading",
						});
						notifyPatientMutation.mutate({});
					}}
				>
					<i className="mgc_bell_ringing_line text-[14px]" />
				</Button>
				{/* <ChangeStation patientDetails={patient} /> */}
				{status !== PatientStatus.NextInLine && (
					<ChangePosition
						patientDetails={patient}
						showChangePositionOrderModal={false}
						setShowChangePositionOrderModal={function () // value: React.SetStateAction<boolean>
						: void {
							throw new Error("Function not implemented.");
						}}
					/>
				)}

				{status === PatientStatus.Pending && (
					<div className="rounded-lg border border-[#C8322B] bg-[#F5F5F5] p-2">
						<div>
							<LuCheckCircle2
								className="bg-[#F5F5F5]"
								color="#C8322B"
								width="14px"
								height="14px"
							/>
						</div>
					</div>
				)}
				{status === PatientStatus.Upcoming && (
					<div className="rounded-lg bg-[#D78100] p-2">
						<img src={playIcon} alt="transfer icon" />
					</div>
				)}
				{status === PatientStatus.NextInLine && (
					<PatientSentToServingModal patientDetails={patient} />
				)}
				{status === PatientStatus.Serving && (
					<div className="rounded-lg bg-[#1E8A7C] p-2">
						<i className="mgc_check_circle_fill text-[14px] before:!text-white" />
					</div>
				)}
			</div>
		</div>
	);
};

export default GridCard;
