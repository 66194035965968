import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/src/components/ui/select";
import {
	GetServingSlice,
	GetWeeklyHistorySlice,
} from "@/src/store/slices/serving/getServingSlice";
import { GetQueryListSlice } from "@/src/store/slices/waitlist/getWaitlistSlice";
import {
	ServingQueueResponse,
	WeeklyWaitlistHistoryResponse,
} from "@/src/types/serving/serving";
import { cn } from "@/src/utils/functions";
import InputIcon from "@src/components/ui-extended/input-icon";
import { Button } from "@src/components/ui/button";
import Header from "@src/layouts/Header/Header";
import useUserStore from "@src/store/useUserStore";
import React, { useEffect, useState } from "react";
import { LuRotateCcw } from "react-icons/lu";
import { useQueryClient } from "react-query";
import { WaitlistLocationsSelectButton } from "../waitlist/Waitlist";
import ServingWrapper from "./components/ServingWrapper";

const Serving: React.FC = () => {
	const queryClient = useQueryClient();
	const { currentLocationId, currentStationId } = useUserStore((s) => ({
		currentStationId: s.currentStationId,
		currentLocationId: s.currentLocationId,
	}));
	const getWaitlistQuery = GetQueryListSlice();
	const getServingQuery = GetServingSlice();
	const getHistoryQuery = GetWeeklyHistorySlice();
	const estimated_wait_time = {
		hours: Math.floor(
			(getWaitlistQuery.data?.data.queue_settings.average_wait_time ??
				0) / 60
		),
		minutes: getWaitlistQuery.data?.data.queue_settings.average_wait_time
			? getWaitlistQuery.data?.data.queue_settings.average_wait_time % 60
			: 0,
	};

	const [initialServingData, setInitialServingData] = useState<
		undefined | ServingQueueResponse
	>();
	const [initialHistoryData, setInitialHistoryData] = useState<
		undefined | WeeklyWaitlistHistoryResponse
	>();
	const [selectedStation, setSelectedStation] = useState<
		number | "All Stations"
	>(currentStationId);

	useEffect(() => {
		getWaitlistQuery.refetch();
		getHistoryQuery.refetch();
		getServingQuery.refetch();
	}, []);

	useEffect(() => {
		if (getServingQuery.data && getServingQuery.isSuccess)
			setInitialServingData(getServingQuery.data);
	}, [getServingQuery.isSuccess]);

	useEffect(() => {
		if (getHistoryQuery.data && getHistoryQuery.isSuccess)
			setInitialHistoryData(getHistoryQuery.data);
	}, [getHistoryQuery.isSuccess]);

	return (
		<main className="flex flex-1 flex-col">
			<Header
				title={"Serving Now"}
				content={
					<div className="flex flex-1 justify-end space-x-[24px] pr-4">
						<div className="relative rounded-full">
							<i className="mgc_group_line text-[20px] before:text-[#323539]" />
							<div className="absolute -right-[85%] -top-1/2 w-fit rounded-full bg-white p-0.5">
								<p className="rounded-full bg-primary px-1.5 py-0.5 text-[13px] font-semibold leading-[16px] tracking-[0.1px] text-white">
									{getWaitlistQuery.data?.data.queue_order
										.length ?? 0}
								</p>
							</div>
						</div>
						<div className="flex items-center space-x-1">
							<i className="mgc_stopwatch_line text-[24px] before:text-[#323539]" />
							<p className="font-medium leading-[24px] tracking-[-1%] text-[#323539]">
								{(estimated_wait_time.hours > 0
									? `${estimated_wait_time.hours}h`
									: "") +
									" " +
									`${estimated_wait_time.minutes}min`}
							</p>
						</div>
					</div>
				}
			/>
			<section className="relative flex flex-1 flex-col items-stretch space-y-3.5 overflow-auto pt-3.5">
				<div className="flex justify-between space-x-2.5">
					<InputIcon
						placeholder="Search by name"
						className="placeholder:text-[#858C95]"
						outerClassName="h-9 flex-1"
						icon="mgc_search_line"
						onChange={(e) => {
							if (!e.target.value.length) {
								queryClient.setQueryData(
									["get-serving", currentLocationId],
									{
										...initialServingData,
										data: initialServingData?.data.filter(
											(patient) => {
												const patientType =
													patient.waitlist ??
													patient.appointment;
												return (
													patientType?.station &&
													patientType?.station.id ==
														+selectedStation
												);
											}
										),
									}
								);
								queryClient.setQueryData(
									["get-weekly-history", currentLocationId],
									{
										...initialHistoryData,
										data: initialHistoryData?.data.filter(
											(patient) => {
												const patientType =
													patient.waitlist ??
													patient.appointment;
												return (
													patientType?.station &&
													patientType?.station.id ==
														+selectedStation
												);
											}
										),
									}
								);
							} else {
								queryClient.setQueryData<
									ServingQueueResponse | undefined
								>(
									["get-serving", currentLocationId],
									(prevData) => {
										if (!prevData) return;
										return {
											...prevData,
											data:
												queryClient
													.getQueryData<
														| ServingQueueResponse
														| undefined
													>(["get-serving", currentLocationId])
													?.data.filter((item) =>
														item.full_name
															.toLowerCase()
															.includes(
																e.target.value.toLowerCase()
															)
													) || [],
										};
									}
								);
								queryClient.setQueryData<
									WeeklyWaitlistHistoryResponse | undefined
								>(
									["get-weekly-history", currentLocationId],
									(prevData) => {
										if (!prevData) return;
										return {
											...prevData,
											data:
												queryClient
													.getQueryData<
														| WeeklyWaitlistHistoryResponse
														| undefined
													>(["get-weekly-history", currentLocationId])
													?.data.filter((item) =>
														item.full_name
															.toLowerCase()
															.includes(
																e.target.value.toLowerCase()
															)
													) || [],
										};
									}
								);
							}
						}}
					/>
					<WaitlistLocationsSelectButton className="h-9" />

					<WaitlistLocationsStationsSelectButton
						className="h-9"
						initialServingData={initialServingData}
						initialHistoryData={initialHistoryData}
						selectedStation={selectedStation}
						setSelectedStation={setSelectedStation}
					/>

					<Button
						variant="outline"
						size="icon-sm"
						className="shadow-[0px_1px_2px_0_rgba(16,24,40,0.04)]"
						onClick={() => {
							getWaitlistQuery.refetch();
							getServingQuery.refetch();
							getHistoryQuery.refetch();
						}}
					>
						<LuRotateCcw
							size={18}
							className={cn({
								"animate-spin":
									getWaitlistQuery.isFetching ||
									getServingQuery.isFetching ||
									getHistoryQuery.isFetching,
							})}
						/>
					</Button>

					{/* <Button
						className="flex h-9 space-x-2 p-3 shadow-[0px_1px_2px_0_rgba(16,24,40,0.04)]"
						variant="outline"
					>
						<i className="mgc_pause_circle_line text-[16px] before:!text-[#323539]" />
						<p className="text-[14px] font-semibold leading-[20px] text-[#323539]">
							Pause Queue
						</p>
					</Button> */}
				</div>
				<ServingWrapper />
			</section>
		</main>
	);
};

export default Serving;

export const WaitlistLocationsStationsSelectButton: React.FC<{
	className?: string;
	initialServingData: ServingQueueResponse | undefined;
	initialHistoryData: WeeklyWaitlistHistoryResponse | undefined;
	selectedStation: number | "All Stations";
	setSelectedStation: React.Dispatch<
		React.SetStateAction<number | "All Stations">
	>;
}> = ({
	className,
	initialServingData,
	initialHistoryData,
	selectedStation,
	setSelectedStation,
}) => {
	const queryClient = useQueryClient();
	// const patientType = patient.waitlist ?? patient.appointment;
	const locations = useUserStore((s) => s.user?.business.locations);
	const currentLocationId = useUserStore((s) => s.currentLocationId);
	// const [selectedStation, setSelectedStation] = useState<
	// 	number | "All Stations"
	// >("All Stations");
	// const { handleStationChange } = useStationSelection();

	const currentLocation = locations?.find(
		(location) => location.id === currentLocationId
	);

	const stations = currentLocation
		? [
				{ label: "All Stations", value: "All Stations" },
				...(currentLocation?.stations.map((station) => ({
					label: station.name,
					value: station.id.toString(),
				})) || []),
			]
		: [];

	return (
		<Select
			defaultValue={"All Stations"}
			value={selectedStation.toString()}
			onValueChange={(newValue) => {
				if (newValue !== "All Stations") {
					setSelectedStation(+newValue);
					queryClient.setQueryData<ServingQueueResponse | undefined>(
						["get-serving", currentLocationId],
						(prevData) => {
							if (!prevData) return;
							return {
								...prevData,
								data:
									queryClient
										.getQueryData<
											ServingQueueResponse | undefined
										>(["get-serving", currentLocationId])
										?.data.filter((patient) => {
											const patientType =
												patient.waitlist ??
												patient.appointment;
											return (
												patientType?.station &&
												patientType?.station.id ==
													+newValue
											);
										}) || [],
							};
						}
					);
					queryClient.setQueryData<
						WeeklyWaitlistHistoryResponse | undefined
					>(["get-weekly-history", currentLocationId], (prevData) => {
						if (!prevData) return;
						return {
							...prevData,
							data:
								queryClient
									.getQueryData<
										| WeeklyWaitlistHistoryResponse
										| undefined
									>(["get-weekly-history", currentLocationId])
									?.data.filter((patient) => {
										const patientType =
											patient.waitlist ??
											patient.appointment;
										return (
											patientType?.station &&
											patientType?.station.id == +newValue
										);
									}) || [],
						};
					});
				} else {
					setSelectedStation("All Stations");
					queryClient.setQueryData<ServingQueueResponse | undefined>(
						["get-serving", currentLocationId],
						initialServingData
					);
					queryClient.setQueryData<
						WeeklyWaitlistHistoryResponse | undefined
					>(
						["get-weekly-history", currentLocationId],
						initialHistoryData
					);
				}
			}}
		>
			<SelectTrigger className={cn("w-[180px]", className)}>
				<SelectValue
					placeholder="Stations"
					className="text-[#858C95]"
				/>
			</SelectTrigger>
			<SelectContent className="">
				{stations.map((option, mainIndex) => {
					return (
						<SelectGroup key={mainIndex}>
							<SelectItem value={option.value}>
								{option.label}
							</SelectItem>
						</SelectGroup>
					);
				})}
			</SelectContent>
		</Select>
	);
};
