import { Input } from "@src/components/ui/input";
import { Label } from "@src/components/ui/label";
import { Textarea } from "@src/components/ui/textarea";
import Checkbox from "@src/components/ui-extended/checkbox";
import { RadioGroup, RadioGroupItem } from "@src/components/ui/radio-group";
import { Controller } from "react-hook-form";
import { format } from "date-fns";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@src/components/ui/popover";
import { Button } from "@src/components/ui/button";
import { Calendar } from "@src/components/ui/calendar";
import MultiSelect from "@src/components/ui-extended/multi-select";
import { Option, ScheduleCustomIntake } from "@src/types/schedule";
import { cn } from "@/src/utils/functions";
import CustomSelect from "@/src/components/common/CustomSelect";
import { useState } from "react";

const ScheduleCustomIntakeField: React.FC<{
	intake: ScheduleCustomIntake;
	register: any;
	errors: any;
	control: any;
}> = ({ intake, register, errors, control }) => {
	const [isDateRangeOpen, setIsDateRangeOpen] = useState(false);
	const [isDateOpen, setIsDateOpen] = useState(false);
	switch (intake.type) {
		case "text":
			return (
				<div className="flex flex-col gap-y-1.5">
					<CustomIntakeFieldLabel intake={intake} />
					{intake.long_text ? (
						<Textarea
							{...register(`custom_intakes.${intake.key}`)}
						/>
					) : (
						<Input {...register(`custom_intakes.${intake.key}`)} />
					)}
					{errors.custom_intakes?.[intake.key]?.message && (
						<small className="text-sm text-destructive">
							{errors.custom_intakes[intake.key].message}
						</small>
					)}
				</div>
			);
		case "numeric":
			return (
				<div className="flex flex-col gap-y-1.5">
					<CustomIntakeFieldLabel intake={intake} />
					<div className="flex items-center space-x-2.5">
						<Input
							type="number"
							{...register(`custom_intakes.${intake.key}`)}
						/>
						<p className="text-sm font-medium tracking-[-0.1px] text-main-1">
							Years
						</p>
					</div>
					{errors.custom_intakes?.[intake.key]?.message && (
						<small className="text-sm text-destructive">
							{errors.custom_intakes[intake.key].message}
						</small>
					)}
				</div>
			);
		case "boolean":
			return (
				<div className="flex flex-col gap-y-1.5">
					<CustomIntakeFieldLabel intake={intake} />
					<Controller
						name={`custom_intakes.${intake.key}`}
						control={control}
						rules={{ required: `${intake.name} is required` }}
						render={({ field }) => (
							<RadioGroup
								className="flex items-center space-x-2.5"
								value={field?.value?.toString()}
								onValueChange={(value) =>
									field.onChange(value === "true")
								}
							>
								<div className="flex items-center space-x-2">
									<RadioGroupItem
										value="true"
										id={`${intake.key}-true`}
										className="border-[#D9D9D9]"
									/>
									<Label
										htmlFor={`${intake.key}-true`}
										className="text-sm"
									>
										True
									</Label>
								</div>
								<div className="flex items-center space-x-2">
									<RadioGroupItem
										value="false"
										id={`${intake.key}-false`}
										className="border-[#D9D9D9]"
									/>
									<Label
										htmlFor={`${intake.key}-false`}
										className="text-sm"
									>
										False
									</Label>
								</div>
							</RadioGroup>
						)}
					/>

					{errors.custom_intakes?.[intake.key]?.message && (
						<small className="text-sm text-destructive">
							{errors.custom_intakes[intake.key].message}
						</small>
					)}
				</div>
			);

		case "date":
			return (
				<div className="flex flex-col gap-y-1.5">
					<CustomIntakeFieldLabel intake={intake} />
					<Controller
						name={`custom_intakes.${intake.key}`}
						control={control}
						render={({ field }) => (
							<Popover
								open={isDateOpen}
								onOpenChange={setIsDateOpen}
							>
								<PopoverTrigger asChild>
									<Button
										variant={"outline"}
										className={cn(
											"w-[280px] justify-start text-left font-normal",
											!field.value &&
												"text-muted-foreground"
										)}
										onClick={() => setIsDateOpen(true)}
									>
										<i className="mgc_calendar_line mr-2 before:!text-main-1" />
										{field.value ? (
											format(field.value, "PPP")
										) : (
											<span>Pick a date</span>
										)}
									</Button>
								</PopoverTrigger>
								<PopoverContent className="w-auto p-0">
									<Calendar
										mode="single"
										selected={field.value}
										onSelect={(date) => {
											field.onChange(date);
											setIsDateOpen(false);
										}}
										initialFocus
									/>
								</PopoverContent>
							</Popover>
						)}
					/>
					{errors.custom_intakes?.[intake.key]?.message && (
						<small className="text-sm text-destructive">
							{errors.custom_intakes[intake.key].message}
						</small>
					)}
				</div>
			);
		case "date_range":
			return (
				<div className="flex flex-col gap-y-1.5">
					<CustomIntakeFieldLabel intake={intake} />
					<Controller
						name={`custom_intakes.${intake.key}`}
						control={control}
						render={({ field }) => {
							return (
								<Popover
									open={isDateRangeOpen}
									onOpenChange={setIsDateRangeOpen}
								>
									<PopoverTrigger asChild>
										<Button
											variant={"outline"}
											className={cn(
												"w-full justify-start text-left font-normal",
												!field.value &&
													"text-muted-foreground"
											)}
											onClick={() =>
												setIsDateRangeOpen(true)
											}
										>
											<i className="mgc_calendar_line mr-2 before:!text-main-1" />
											{field.value?.from ? (
												field.value.to ? (
													<>
														{format(
															field.value.from,
															"PPP"
														)}{" "}
														-{" "}
														{format(
															field.value.to,
															"PPP"
														)}
													</>
												) : (
													format(
														field.value.from,
														"PPP"
													)
												)
											) : (
												<span>Pick a date range</span>
											)}
										</Button>
									</PopoverTrigger>
									<PopoverContent className="w-auto p-0">
										<Calendar
											mode="range"
											selected={field.value}
											onSelect={(range) => {
												field.onChange(range);
												setIsDateRangeOpen(false);
											}}
											numberOfMonths={2}
											initialFocus
										/>
									</PopoverContent>
								</Popover>
							);
						}}
					/>
					{errors.custom_intakes?.[intake.key]?.message && (
						<small className="text-sm text-destructive">
							{errors.custom_intakes[intake.key].message}
						</small>
					)}
				</div>
			);

		case "dropdown":
			return (
				<div className="flex flex-col gap-y-1.5">
					<CustomIntakeFieldLabel intake={intake} />
					<Controller
						name={`custom_intakes.${intake.key}`}
						control={control}
						render={({ field }) =>
							intake.allow_multiple ? (
								<MultiSelect
									options={intake.options.map((option) => ({
										label: option,
										value: option,
									}))}
									placeholder="Select options"
									selected={field.value || []}
									onSelect={(selected) =>
										field.onChange(selected)
									}
									onUnselect={(option) => {
										const newSelected = (
											field.value || []
										).filter(
											(item: Option) =>
												item.value !== option.value
										);
										field.onChange(newSelected);
									}}
									checkBoxed={true}
								/>
							) : (
								<CustomSelect
									options={
										intake.options?.map((option) => ({
											value: option,
											label: option,
										})) || []
									}
									value={{
										value: field.value?.[0]?.value,
										label: field.value?.[0]?.value,
									}}
									onChange={(selected: any) => {
										field.onChange([
											{
												label: selected.value,
												value: selected.value,
											},
										]);
									}}
									className="react-select-container"
									classNamePrefix="react-select"
								/>
							)
						}
					/>
					{errors.custom_intakes?.[intake.key]?.message && (
						<small className="text-sm text-destructive">
							{errors.custom_intakes[intake.key].message}
						</small>
					)}
				</div>
			);
		case "checkbox":
			return (
				<div className="flex flex-col gap-y-1.5">
					<CustomIntakeFieldLabel intake={intake} />
					{intake.allow_multiple ? (
						<Controller
							name={`custom_intakes.${intake.key}`}
							control={control}
							rules={{ required: `${intake.name} is required` }}
							defaultValue={[]} // Default value is an empty array for multiple selections
							render={({ field }) => (
								<ul className="flex flex-col  gap-y-1">
									{intake.options.map((option, index) => (
										<li
											key={index}
											className="flex items-center space-x-2"
										>
											<Checkbox
												// No value prop here, we rely on checked and onChange
												id={field.value}
												isChecked={field.value.includes(
													option
												)}
												className="rounded-sm"
												handleCheckboxChange={() => {
													if (
														field.value.includes(
															option
														)
													) {
														// Remove value from array if already selected
														field.onChange(
															field.value.filter(
																(val) =>
																	val !==
																	option
															)
														);
													} else {
														// Add value to array if not selected
														field.onChange([
															...field.value,
															option,
														]);
													}
												}}
											/>
											<Label className="text-sm font-normal text-[#1E1E1E]">
												{option}
											</Label>
										</li>
									))}
								</ul>
							)}
						/>
					) : (
						<Controller
							name={`custom_intakes.${intake.key}`}
							control={control}
							rules={{ required: `${intake.name} is required` }}
							render={({ field }) => (
								<RadioGroup
									value={field.value}
									onValueChange={(value) =>
										field.onChange([value])
									}
								>
									<ul className="flex flex-col gap-y-1.5">
										{intake.options.map((option, index) => (
											<li
												key={index}
												className="flex items-center space-x-2"
											>
												<RadioGroupItem
													value={option}
													id={`${intake.key}-${index}`}
													checked={
														field.value
															? field.value.includes(
																	option
																)
															: undefined
													}
													className="border-[#D9D9D9]"
												/>
												<Label
													htmlFor={`${intake.key}-${index}`}
													className="text-sm font-normal text-[#1E1E1E]"
												>
													{option}
												</Label>
											</li>
										))}
									</ul>
								</RadioGroup>
							)}
						/>
					)}

					{errors.custom_intakes?.[intake.key]?.message && (
						<small className="text-sm text-destructive">
							{errors.custom_intakes[intake.key].message}
						</small>
					)}
				</div>
			);
		case "attachment":
			return (
				<div className="flex flex-col gap-y-1.5">
					<CustomIntakeFieldLabel intake={intake} />
					<Input
						type="file"
						{...register(`custom_intakes.${intake.key}`)}
					/>
					{errors.custom_intakes?.[intake.key]?.message && (
						<small className="text-sm text-destructive">
							{errors.custom_intakes[intake.key].message}
						</small>
					)}
				</div>
			);
		case "infoImage":
			return (
				<div className="flex flex-col gap-y-1.5">
					<CustomIntakeFieldLabel intake={intake} notRequired />
					<img src={intake.image} alt={intake.name} />
				</div>
			);
		case "infoText":
			return (
				<div className="flex flex-col gap-y-1.5">
					<CustomIntakeFieldLabel intake={intake} notRequired />
					<p className="text-sm text-main-1">{intake.subtitle}</p>
				</div>
			);
		default:
			return null;
	}
};

export default ScheduleCustomIntakeField;

const CustomIntakeFieldLabel: React.FC<{
	intake: ScheduleCustomIntake;
	notRequired?: boolean;
}> = ({ intake, notRequired }) => {
	return (
		<div className="flex flex-col">
			<Label className="text-sm font-medium" htmlFor={intake.key}>
				{intake.name}{" "}
				{intake.field_requirement === "yes" && !notRequired && (
					<span className="text-destructive">*</span>
				)}
			</Label>
			<small className="text-xs text-[#757575]">{intake.subtitle}</small>
		</div>
	);
};
