import { CustomSelect, Option } from "@components/form/CustomSelect";
import Checkbox from "@src/components/ui-extended/checkbox";
import InputIcon from "@src/components/ui-extended/input-icon";
import RequestIsLoading from "@components/RequestIsLoading";
import useCustomToast from "@src/components/CustomToast";
import IntakeFieldList from "@src/pages/locations/components/IntakeFieldList";
import AddFieldModal from "./AddFieldModal";
import TagFieldModal from "./TagFieldModal";
import {
	CreateCustomFields,
	GetCustomFieldsSlice,
	UpdateCustomFields,
} from "@src/store/slices/customization/customFieldsSlice";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	CustomizationSchema,
	CustomizationProps,
	CustomizationTagSchema,
	AddCustomizationFieldSchema,
} from "@src/types/customization";
import { custom } from "zod";
import { useEffect, useState } from "react";
import {
	displayErrorsWithTimeout,
	convertLocationData,
} from "@src/utils/functions";
import useLocationsStore from "@src/store/useLocationsStore";
import FormInput from "@src/components/form/FormInput";

const IntakeFields = () => {
	const [modalMode, setModalMode] = useState<string | undefined>(undefined);
	const [customFieldsIds, setCustomFieldsIds] = useState<number[]>([]);
	const [selectedCustomFieldId, setSelectedCustomFieldId] =
		useState<number>(0);
	const [checkAllCustomFields, setCheckAllCustomFields] = useState(false);
	const [searchKeyword, setSearchKeyword] = useState<string>("");

	const [selectedLocationIds, setSelectedLocationIds] = useState<number[]>(
		[]
	);
	const [selectedStations, setSelectedStations] = useState<Option[]>([]);
	const locationsData = useLocationsStore((s) => s.locations);
	const [filteredData, setFilteredData] = useState<any[]>([]);

	const methods = useForm<CustomizationProps>({
		resolver: zodResolver(
			modalMode === "add" || modalMode === "edit-add"
				? AddCustomizationFieldSchema
				: CustomizationSchema
		),
		mode: "onChange",
		defaultValues: {
			name: "",
			subtitle: "",
			type: undefined,
			numeric_unit_title: "",
			info_text_value: "",
			long_text: 0,
			date_range: 0,
			date_range_value: null,
			options: ["Option 1"],
			allow_multiple: 0,
			image: "",
			approved_formats: [],
			apply_to: "all",
			field_requirement: "yes",
			apply_to_option: {
				apply_to_all: 0,
				locations: [],
			},
		},
	});

	const customToast = useCustomToast();

	const {
		reset,
		formState: { isValid },
		handleSubmit,
		getValues,
	} = methods;

	const { data, isLoading, isSuccess, invalidateQuery } =
		GetCustomFieldsSlice({});

	const {
		mutate: createCustomFieldsMutation,
		isLoading: createCustomFieldsLoading,
	} = CreateCustomFields(() => {
		invalidateQuery();
		setModalMode(undefined);
		reset({
			name: "",
			subtitle: "",
			type: undefined,
			numeric_unit_title: "",
			long_text: 0,
			date_range: 0,
			date_range_value: null,
			options: ["Option 1", "Option 2"],
			allow_multiple: 0,
			image: "",
			approved_formats: [],
			apply_to: "all",
			field_requirement: "yes",
			apply_to_option: {
				apply_to_all: 0,
				locations: [],
			},
		});
	});

	const {
		mutate: updateCustomFieldsMutation,
		isLoading: updateCustomFieldsLoading,
	} = UpdateCustomFields(() => {
		// invalidateQuery();
		setModalMode(undefined);
		reset({
			name: "",
			subtitle: "",
			type: undefined,
			numeric_unit_title: "",
			long_text: 0,
			date_range: 0,
			date_range_value: null,
			options: ["Option 1", "Option 2"],
			allow_multiple: 0,
			image: "",
			approved_formats: [],
			apply_to: "all",
			field_requirement: "yes",
			apply_to_option: {
				apply_to_all: 0,
				locations: [],
			},
		});
	});

	const handleFieldListAction = (action: string, data: any) => {
		setModalMode(action);
		setSelectedCustomFieldId(data?.id);
		reset({
			name: data?.name || "",
			subtitle: data?.subtitle || "",
			type: data?.type || undefined,
			numeric_unit_title: data?.numeric_unit_title || "",
			long_text: data?.long_text || 0,
			date_range: data?.date_range || 0,
			date_range_value: data?.date_range_value || null,
			options: data?.options || ["Option 1", "Option 2"],
			allow_multiple: data?.allow_multiple || 0,
			image: data?.image_url || "",
			approved_formats: data?.approved_formats || [],
			apply_to: data?.apply_to || "all",
			field_requirement: data?.field_requirement || "yes",
			apply_to_option: {
				apply_to_all: 0,
				locations:
					convertLocationData(data?.relations || []).locations || [],
			},
		});
	};

	const handleCheckAllCustomFields = () => {
		if (customFieldsIds?.length === data?.data.length) {
			setCustomFieldsIds([]);
			setCheckAllCustomFields(false);
		} else {
			setCustomFieldsIds(data?.data?.map((field: any) => field.id) || []);
			setCheckAllCustomFields(true);
		}
	};

	const onSubmit = (data: CustomizationProps) => {
		if (isValid && modalMode === "add") {
			setModalMode("tag");
		} else if (isValid && modalMode === "edit-add") {
			setModalMode("edit-tag");
		}
		if (isValid && modalMode === "tag") {
			createCustomFieldsMutation(
				{
					data: data,
				},
				{
					onSuccess: () => {
						setModalMode(undefined);
					},
					onError: (error: any) => {
						displayErrorsWithTimeout(
							error.response.data?.error ||
								"An unexpected error occurred. Please try again later",
							customToast,
							{
								toastIdPrefix: "create-custom-fields",
							}
						);
					},
				}
			);
		}
		if (isValid && modalMode === "edit-tag") {
			updateCustomFieldsMutation(
				{
					data: data,
					id: selectedCustomFieldId,
				},
				{
					onSuccess: () => {
						setModalMode(undefined);
						invalidateQuery();
					},
					onError: (error: any) => {
						displayErrorsWithTimeout(
							error.response.data?.error ||
								"An unexpected error occurred. Please try again later",
							customToast,
							{
								toastIdPrefix: "create-custom-fields",
							}
						);
					},
				}
			);
		}
	};

	useEffect(() => {
		const filteredResponse = data?.data?.filter((item) => {
			const locationMatches =
				selectedLocationIds.length > 0
					? item.relations?.locations?.some((location) =>
							selectedLocationIds?.includes(location.id)
						)
					: true;
			const stationMatches =
				selectedStations.length > 0
					? item.relations?.stations?.some((station) =>
							selectedStations?.some(
								(option) =>
									option.value === station?.id?.toString()
							)
						)
					: true;
			const keywordMatches = searchKeyword
				? Object.values(item).some(
						(value) =>
							typeof value === "string" &&
							value
								.toLowerCase()
								.includes(searchKeyword.toLowerCase())
					)
				: true;

			return locationMatches && stationMatches && keywordMatches;
		});

		setFilteredData(filteredResponse);
	}, [data?.data, selectedLocationIds, selectedStations, searchKeyword]);

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="py-3">
					<p className="font-hoves text-xl font-[500] capitalize">
						All Intake Fields
					</p>
					<p className="text-gray-500">
						Showing {filteredData?.length} out of{" "}
						{data?.data?.length}
					</p>
					<div className="flex space-x-4 px-1 py-4">
						<div className="flex basis-1/3 items-center">
							<InputIcon
								placeholder="Quick search by category name..."
								className=" w-full placeholder:text-[#858C95]"
								outerClassName="w-full shadow-custom-small border-main-3"
								icon="mgc_search_line"
								onChange={(e) => {
									setSearchKeyword(e.target.value);
								}}
							/>
						</div>
						<div className="basis-1/4">
							<FormInput
								select
								isMulti
								placeholder="Location Name...."
								options={
									locationsData?.map((location) => ({
										label: location.name,
										value: location.id,
									})) || []
								}
								onChange={(selectedOptions) => {
									const selectedIds = selectedOptions?.map(
										(option: any) => option.value
									);
									setSelectedLocationIds(selectedIds);
								}}
								className="h-10"
							/>
						</div>
						<div className="basis-1/4">
							<FormInput
								select
								isMulti
								placeholder="Select Station(s)"
								options={
									selectedLocationIds?.length > 0
										? locationsData
												.find((location) =>
													selectedLocationIds?.includes(
														location.id
													)
												)
												?.stations.map((station) => ({
													label: station.name,
													value: station.id.toString(),
												})) || []
										: locationsData.flatMap((loc) =>
												loc?.stations?.map(
													(station) => ({
														label: station.name,
														value: station.id?.toString(),
													})
												)
											) || []
								}
								onChange={(selectedOptions) => {
									const selectedIds = selectedOptions?.map(
										(option: any) => option.value
									);
									setSelectedStations(selectedIds);
								}}
								className="h-10"
							/>
						</div>
						<div className="basis-1/4">
							<CustomSelect
								className="text-sm text-gray-400"
								options={[]}
								placeholder="All Services / Events"
								onChange={() => {
									//
								}}
							/>
						</div>
						<AddFieldModal
							modalMode={modalMode}
							setModalMode={setModalMode}
							onSubmit={methods.handleSubmit(onSubmit)}
						/>
						<TagFieldModal
							modalMode={modalMode}
							setModalMode={setModalMode}
							onSubmit={methods.handleSubmit(onSubmit)}
							isLoading={
								createCustomFieldsLoading ||
								updateCustomFieldsLoading
							}
						/>
					</div>
					{isSuccess ? (
						filteredData?.length > 0 ? (
							<div className="w-full space-y-2">
								<div className="flex justify-between rounded-t-lg border-b p-2 text-sm">
									<div className="flex w-[40%] items-center font-semibold">
										<Checkbox
											isChecked={checkAllCustomFields}
											handleCheckboxChange={
												handleCheckAllCustomFields
											}
											className="rounded-sm border-2 border-[#D1D1D1]"
											id={""}
										/>
										<p className="ml-2">Field name</p>
									</div>
									<div className="w-[35%] font-semibold">
										Type
									</div>
								</div>
								{filteredData?.map((item: any) => (
									<IntakeFieldList
										key={item.id}
										fieldData={item}
										handleFieldListAction={
											handleFieldListAction
										}
										customFieldsIds={customFieldsIds}
										setCustomFieldsIds={setCustomFieldsIds}
									/>
								))}
							</div>
						) : (
							<div className="flex flex-col items-center justify-center py-4">
								<i className="mgc_file_line text-[32px] text-primary"></i>
								<h2 className="mt-5 font-semibold">
									No custom fields
								</h2>
								<p className="text-gray-500">
									Get started by adding a new custom field.
								</p>
							</div>
						)
					) : (
						<RequestIsLoading isLoading={isLoading} />
					)}
				</div>
			</form>
		</FormProvider>
	);
};

export default IntakeFields;
