import PatientSentToSentToDoneModal from "@/src/components/Dashboard/waitlist/PatientSentToDoneModal";
import rescheduleIcon from "@assets/images/reschedule.svg";
import CustomCheckbox2 from "@components/form/CustomCheckbox2";
import { UniqueIdentifier } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { PatientPriority, QueueEntry } from "@type/waitlist/waitlist";
import { cn, handleRowCheckboxChange } from "@utils/functions";
import React, { Dispatch, SetStateAction } from "react";
import NotifyPatient from "../../../../components/Dashboard/waitlist/NotifyPatient";
import PatientApprovedModal from "../../../../components/Dashboard/waitlist/PatientApprovedModal";
import PatientSentToServingModal from "../../../../components/Dashboard/waitlist/PatientSentToServingModal";
import TimeAgoShort from "../../../../components/Dashboard/waitlist/TimeAgoShort";
import { Button } from "../../../../components/ui/button";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "../../../../components/ui/tooltip";
import { PatientStatus } from "../../../../types/wait-list";

// interface CheckboxTableProps {
// 	rows?: Row[];
// 	status?: PatientStatus;
// }

const WaitlistCard: React.FC<{
	activeId?: UniqueIdentifier;
	selectedRows?: number[];
	noOfPatients: number;
	status: PatientStatus;
	index: number;
	patient: QueueEntry;
	setSelectAllChecked?: Dispatch<SetStateAction<boolean>>;
	setShowPatientInformation: Dispatch<SetStateAction<boolean>>;
	setShowPatientInformationState?: Dispatch<
		SetStateAction<"intake" | "message" | "activity" | undefined>
	>;
	setSelectedRows?: Dispatch<SetStateAction<number[]>>;
	setModalPatientInformation?: Dispatch<
		SetStateAction<QueueEntry | undefined>
	>;
	setShowDeleteCustomerModal: Dispatch<SetStateAction<boolean>>;
	setShowChangePatientStationModal: Dispatch<SetStateAction<boolean>>;
	setShowPriorityModal: Dispatch<SetStateAction<boolean>>;
	setShowChangePositionOrderModal: Dispatch<SetStateAction<boolean>>;
}> = ({
	// index,
	status,
	patient,
	activeId,
	selectedRows,
	noOfPatients,
	setSelectedRows,
	setSelectAllChecked,
	setShowPriorityModal,
	setShowPatientInformation,
	setShowPatientInformationState,
	setModalPatientInformation,
	setShowDeleteCustomerModal,
	setShowChangePositionOrderModal,
	setShowChangePatientStationModal,
}) => {
	// const stationsList = usepatientStore((s) => s.stationsList);
	const isChecked = selectedRows?.includes(patient.id);
	// const managePatientMutation = useManagePatient();

	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({ id: patient.id });
	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};
	// console.log(activeId, patient.id);

	return (
		<>
			<li
				ref={setNodeRef}
				className={cn(
					"mt-1 flex cursor-grab flex-col space-y-3 rounded-xl bg-white box-decoration-slice p-3 shadow-sm duration-200 ease-in-out hover:outline hover:outline-primary",
					{
						"cursor-grabbing": isDragging,
						"border border-[#822D7F]":
							status === PatientStatus.Schedule,
						"opacity-50": activeId === patient.id,
					}
				)}
				style={style}
				{...attributes}
				{...listeners}
			>
				<div className="flex items-center justify-between">
					<div className="flex items-center gap-x-1.5">
						<p className="text-sm font-semibold leading-[14px] text-[#858C95]">
							{patient.station_order
								? patient.station_order
										.toString()
										.padStart(2, "0")
								: ""}
						</p>
						<div className="h-[8px] w-[1px] rounded-full bg-[#E5E5E7]" />
						<div className="flex items-center space-x-1">
							<Tooltip delayDuration={1000}>
								<TooltipTrigger asChild>
									<p
										className="truncate text-sm font-bold capitalize text-main-1"
										title={patient.customer.full_name}
									>
										{patient.customer.full_name}
									</p>
								</TooltipTrigger>
								<TooltipContent side="top" sideOffset={4}>
									{patient.customer.full_name}
								</TooltipContent>
							</Tooltip>
							<Tooltip delayDuration={1000}>
								<TooltipTrigger asChild>
									<Button
										className="h-[18px] w-[22px] rounded-sm bg-[#F5F5F5] p-0 hover:bg-[#F5F5F5]"
										onClick={() => {
											setModalPatientInformation?.(
												patient
											);
											setShowPatientInformation(true);
										}}
									>
										<i className="mgc_information_line text-[14px] before:!text-main-1" />
									</Button>
								</TooltipTrigger>
								<TooltipContent side="top" sideOffset={4}>
									Details
								</TooltipContent>
							</Tooltip>
						</div>
						<Tooltip delayDuration={1000}>
							<TooltipTrigger asChild>
								<Button
									className={cn(
										"peer flex h-[18px] w-[22px] items-center justify-center rounded-[4px] p-0",
										{
											"bg-[#EAEBF0] hover:bg-[#383D43]":
												PatientPriority.Normal ===
												patient.priority,
											"bg-[#FFE4C0] hover:bg-[#EEA23E]":
												PatientPriority.Medium ===
												patient.priority,
										}
									)}
									onClick={() => {
										setModalPatientInformation?.(patient);
										setShowPriorityModal(true);
									}}
								>
									<i
										className={cn(
											"mgc_tag_fill text-[14px] duration-200 ease-in-out peer-hover:before:!text-[100px]",
											{
												// Change text color of the <i> tag when the button is hovered
												// "": true, // Change color when parent button is hovered
												"before-text-red":
													status ===
													PatientStatus.Schedule,
												"before:!text-[#969696]":
													PatientPriority.Normal ===
													patient.priority,
												"before:!text-[#D78100]":
													PatientPriority.Medium ===
													patient.priority,
												"before:!text-[#C8322B]":
													PatientPriority.High ===
													patient.priority,
											}
										)}
									/>
								</Button>
							</TooltipTrigger>
							<TooltipContent
								side="top"
								sideOffset={4}
								className="capitalize"
							>
								{patient.priority === PatientPriority.Normal
									? "Average"
									: patient.priority}{" "}
								Priority
							</TooltipContent>
						</Tooltip>
					</div>
					<div className="flex items-center space-x-2">
						<CustomCheckbox2
							handleCheckboxChange={() => {
								if (setSelectedRows && setSelectAllChecked)
									handleRowCheckboxChange(
										!isChecked,
										patient.id,
										setSelectedRows,
										noOfPatients,
										setSelectAllChecked
									);
							}}
							isChecked={
								selectedRows?.includes(patient.id) ?? false
							}
							id={status + " selectAll"}
							className="rounded-sm border-2"
						/>
					</div>
				</div>
				<div className="flex items-center justify-between text-[#6B7280]">
					<div className="flex items-center gap-x-1">
						{patient.joined_through === "url" && (
							<i className="mgc_earth_line text-[12px] before:!text-main-1" />
						)}
						{patient.joined_through === "qr" && (
							<i className="mgc_qrcode_line text-[12px] before:!text-main-1" />
						)}
						{patient.joined_through === "admin" && (
							<i className="mgc_user_security_line text-[12px] before:!text-main-1" />
						)}
						<p className="text-[10px] leading-[10px]">
							{patient.station_name}
						</p>
					</div>

					<TimeAgoShort patient={patient} ago />

					{/* {status !== PatientStatus.Schedule &&
						!patient.appointment ? (
							<>
							</>
						) : (
							<p className="text-xxs text-[#6B7280]">
								{patient?.appointment
									? dayjs(
											patient?.appointment
												.appointment_date
										).format("DD MMM YYYY")
									: ""}
							</p>
						)} */}
				</div>
				{/* <div className="flex justify-between">
						<p className="text-xxs text-[#6B7280]">
							[Type of Appointment]
						</p>
						{patient?.appointment && (
							<p className="text-xxs text-[#6B7280]">
								{patient?.appointment
									? new Date(
											patient?.appointment.appointment_date
										).getHours() +
										":" +
										new Date(
											patient?.appointment.appointment_date
										).getMinutes() +
										(new Date(
											patient?.appointment.appointment_date
										).getHours() >= 12
											? " PM"
											: " AM")
									: ""}
							</p>
						)}
					</div> */}

				<div className="flex items-center justify-between">
					<div className="flex items-center gap-x-1.5">
						{(status === PatientStatus.NextInLine ||
							status === PatientStatus.Serving) && (
							<NotifyPatient patientDetails={patient} />
						)}

						{(status === PatientStatus.Pending ||
							status === PatientStatus.Upcoming) && (
							<Tooltip delayDuration={1000}>
								<TooltipTrigger>
									<Button
										className="size-[30px] rounded-md bg-[#F5F5F5] p-0 hover:bg-[#F5F5F5]"
										onClick={() => {
											setShowPatientInformationState?.(
												"message"
											);
											setModalPatientInformation?.(
												patient
											);
											setShowPatientInformation(true);
										}}
									>
										<i className="mgc_chat_2_line text-[14px] before:!text-[#09244B]" />
									</Button>
								</TooltipTrigger>
								<TooltipContent side="top" sideOffset={4}>
									Message
								</TooltipContent>
							</Tooltip>
						)}

						{status === PatientStatus.Schedule && (
							<Tooltip delayDuration={1000}>
								<TooltipTrigger>
									<Button
										className="size-[30px] rounded-md bg-[#F5F5F5] p-0 hover:bg-[#F5F5F5]"
										// onClick={() => setShowDeleteCustomerModal(true)}
									>
										<i className="mgc_phone_call_line text-[14px] before:!text-[#09244B]" />
									</Button>
								</TooltipTrigger>
								<TooltipContent side="top" sideOffset={4}>
									Place Call
								</TooltipContent>
							</Tooltip>
						)}

						{status === PatientStatus.Schedule && (
							<Tooltip delayDuration={1000}>
								<TooltipTrigger>
									<Button
										className="size-[30px] rounded-md bg-[#F5F5F5] p-0 hover:bg-[#F5F5F5]"
										// onClick={() => setShowDeleteCustomerModal(true)}
									>
										<img
											src={rescheduleIcon}
											alt="reschedule icon"
										/>
									</Button>
								</TooltipTrigger>
								<TooltipContent side="top" sideOffset={4}>
									Reschedule
								</TooltipContent>
							</Tooltip>
						)}
						<Tooltip delayDuration={1000}>
							<TooltipTrigger>
								<Button
									className="size-[30px] rounded-md bg-[#F5F5F5] p-0 hover:bg-destructive"
									onClick={() => {
										setModalPatientInformation?.(patient);
										setShowDeleteCustomerModal(true);
									}}
								>
									<i className="mgc_delete_2_line text-[14px] before:!text-[#09244B]" />
								</Button>
							</TooltipTrigger>
							<TooltipContent side="top" sideOffset={4}>
								Delete Patient
							</TooltipContent>
						</Tooltip>
						{status !== PatientStatus.Schedule && (
							<>
								<Tooltip delayDuration={1000}>
									<TooltipTrigger>
										<Button
											className="relative size-[30px] rounded-md bg-[#F5F5F5] p-0 hover:bg-[#F5F5F5]"
											onClick={() => {
												setModalPatientInformation?.(
													patient
												);
												setShowChangePatientStationModal(
													true
												);
											}}
										>
											<i
												className={cn(
													"absolute -top-1 right-1.5 size-2 rounded-full",
													{
														"bg-[#2EBF43]":
															patient.check_in_status ===
															"pending",
														// "bg-destructive":
														// 	patient.check_in_status ===
														// 		"to_be_approved" ||
														// 	!patient.check_in_status,
													}
												)}
											/>
											<img
												src="/assets/images/transfer.svg"
												alt="transfer icon"
											/>
										</Button>
									</TooltipTrigger>
									<TooltipContent side="top" sideOffset={4}>
										Swap Station
									</TooltipContent>
								</Tooltip>
								<Tooltip delayDuration={1000}>
									<TooltipTrigger>
										<Button
											className="relative size-[30px] rounded-sm bg-[#F5F5F5] p-0 hover:bg-[#F5F5F5]"
											onClick={() => {
												setModalPatientInformation?.(
													patient
												);
												setShowChangePositionOrderModal(
													true
												);
											}}
										>
											<img
												src="/assets/icons/swap.svg"
												alt="transfer icon"
											/>
											{patient.swap_request_id && (
												<i className="absolute right-1 top-[-4px] size-2 rounded-full bg-[#2EBF43]" />
											)}
										</Button>
									</TooltipTrigger>
									<TooltipContent side="top" sideOffset={4}>
										Swap Position
									</TooltipContent>
								</Tooltip>
							</>
						)}
					</div>
					{status === PatientStatus.Pending && (
						<PatientApprovedModal patientDetails={patient} />
					)}

					{/* {status === PatientStatus.Upcoming && (
						<PatientSentToNextInLineModal
							patientDetails={patient}
						/>
					)} */}

					{status === PatientStatus.Upcoming && (
						<NotifyPatient
							patientDetails={patient}
							className="border-[#D78100]"
							iconClassName="before:!text-[#D78100]"
							variant="outline"
						/>
					)}
					{status === PatientStatus.NextInLine && (
						<PatientSentToServingModal patientDetails={patient} />
					)}
					{status === PatientStatus.Serving && (
						<PatientSentToSentToDoneModal
							waitlist_id={patient.waitlist_id}
							customer_id={patient.customer.id}
						/>
					)}
				</div>
			</li>
		</>
	);
};

export default WaitlistCard;
