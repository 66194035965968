import { Button } from "@src/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@src/components/ui/dialog";
import { Input } from "@src/components/ui/input";
import { Label } from "@src/components/ui/label";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "@src/components/ui/select";
import { countryCodes } from "@src/utils/constants";
import { cn } from "@src/utils/functions";
import { format, differenceInHours } from "date-fns";
import React, { useEffect, useState } from "react";
import { LuX } from "react-icons/lu";
import IntakeFieldsTab from "../waitlist/PatientInformation/IntakeFieldsTab";
import CustomerDelete from "./CustomerDelete";
import CustomerVisitHistoryCard from "./CustomerVisitHistoryCard";
import { CustomerData } from "@/src/types/customers";
import { useForm } from "react-hook-form";
import { QueueEntry } from "@/src/types/waitlist/waitlist";
import { createJoinWaitlistSchema } from "../CustomIntakeField";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

const CustomerInformationModal: React.FC<{
	patientDetails?: CustomerData;
	showPatientInformation: boolean;
	setShowPatientInformation: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
	patientDetails,
	showPatientInformation,
	setShowPatientInformation,
}) => {
	const [currentTab, setCurrentTab] = React.useState<
		"intake" | "visit-history"
	>("visit-history");
	const [isEditing, setIsEditing] = React.useState(false);
	const [hasPassed24Hours, setHasPassed24Hours] = React.useState(false);
	const [schema, setSchema] = React.useState(createJoinWaitlistSchema([]));

	const [initialValues, setInitialValues] = React.useState<
		z.infer<typeof schema>
	>({
		full_name: "",
		phone_number: "",
		email: "",
		custom_intakes: undefined,
		station_id: 0,
	});

	const {
		register,
		handleSubmit,
		reset,
		control,
		watch,
		setValue,
		formState: { errors },
	} = useForm<z.infer<typeof schema>>({
		resolver: zodResolver(schema),
		defaultValues: initialValues,
	});

	const [countryCode, setCountryCode] = React.useState("+1");

	const [showDeleteCustomerModal, setShowDeleteCustomerModal] =
		useState(false);

	useEffect(() => {
		if (patientDetails?.updated_at) {
			setHasPassed24Hours(
				has24HoursPassed(patientDetails?.updated_at || undefined)
			);
		}
	}, [patientDetails?.updated_at]);

	useEffect(() => {
		setCountryCode(
			patientDetails?.phone_number.slice(0, -10)
				? patientDetails?.phone_number.slice(0, -10)
				: "+1"
		);
	}, [patientDetails?.phone_number]);

	return (
		<>
			<Dialog
				open={showPatientInformation}
				onOpenChange={(newValue) => {
					if (!newValue) setIsEditing(false);
					setShowPatientInformation(newValue);
				}}
			>
				<DialogContent className="max-w-[860px]">
					<DialogTitle>
						<DialogHeader>
							<div className="flex items-center justify-between">
								<div className="flex flex-1 items-center space-x-3">
									{isEditing ? (
										<>
											<div className="max-w-[215px] flex-1 space-y-1.5">
												<Label className="font-normal text-[#1e324c]">
													First Name
												</Label>

												<Input
													defaultValue={
														patientDetails?.full_name.split(
															" "
														)[0]
													}
													className="flex-1"
												/>
											</div>
											<div className="max-w-[215px] flex-1 space-y-1.5">
												<Label className="font-normal text-[#858C95]">
													Last Name
												</Label>
												<Input
													defaultValue={
														patientDetails?.full_name.split(
															" "
														)[1]
													}
													className="flex-1"
												/>
											</div>
										</>
									) : (
										<>
											<p className="text-[22px] font-medium capitalize leading-[30px] -tracking-[1%] text-main-1">
												{patientDetails?.full_name}
											</p>
										</>
									)}
								</div>
								<button
									type="button"
									onClick={() => {
										setIsEditing(false);
										setShowPatientInformation(false);
									}}
								>
									<LuX
										color="#858C95"
										className="cursor-pointer"
										size={20}
									/>
								</button>
							</div>
						</DialogHeader>
					</DialogTitle>
					<ul className="grid grid-cols-[min(215px)_auto_auto] gap-3 border-y-2 border-primary py-3">
						{isEditing ? (
							<>
								<div className="max-w-[215px]">
									<Label className="font-normal text-[#858C95]">
										Email Address
									</Label>
									<Input
										defaultValue={
											patientDetails?.email ?? ""
										}
										// className="max-w-[215px]"
									/>
								</div>
								<div className="max-w-[215px]">
									<Label className="font-normal text-[#858C95]">
										Phone Number
									</Label>
									<div className="flex">
										<Select
											value={countryCode}
											onValueChange={(value) => {
												setCountryCode(value);
											}}
										>
											<SelectTrigger className="w-fit rounded-r-none border-r-transparent">
												<SelectValue
													className="text-[#858C95]"
													placeholder="+1"
												/>
											</SelectTrigger>
											<SelectContent className="!z-[9999]">
												<SelectGroup>
													<SelectLabel className="px-2">
														Country Codes
													</SelectLabel>

													{countryCodes.map(
														(option) => {
															return (
																<SelectItem
																	key={
																		option.value
																	}
																	value={
																		option.value
																	}
																	className="px-8"
																>
																	{
																		option.label
																	}
																</SelectItem>
															);
														}
													)}
												</SelectGroup>
											</SelectContent>
										</Select>
										<Input
											className="rounded-l-none border border-[#E4E4E7]"
											minLength={11}
											maxLength={11}
											defaultValue={patientDetails?.phone_number.slice(
												-10
											)}
										/>
									</div>
								</div>
								<div className="max-w-[215px]">
									<Label className="font-normal text-[#858C95]">
										Health Card Number
									</Label>
									<Input
										defaultValue={
											patientDetails?.full_name.split(
												" "
											)[1]
										}
										// className="max-w-[215px]"
									/>
								</div>
								<div className="max-w-[215px]">
									<Label className="font-normal text-[#858C95]">
										Expiry Date
									</Label>
									<Input
										defaultValue={
											patientDetails?.full_name.split(
												" "
											)[1]
										}
										// className="max-w-[215px]"
									/>
								</div>
							</>
						) : (
							<>
								<MainPatientInfo
									title={"Email Address"}
									description={patientDetails?.email ?? ""}
								/>
								<MainPatientInfo
									title={"Phone Number"}
									description={
										patientDetails?.phone_number ?? ""
									}
								/>
								<MainPatientInfo
									title="Patient ID"
									description={patientDetails?.id}
								/>
								<MainPatientInfo
									title={"Health Card Number"}
									description={"839283482948"}
								/>
								<MainPatientInfo
									title="No. of Visits"
									description="6"
								/>
								<MainPatientInfo
									title="Last Visit"
									description={
										patientDetails?.updated_at
											? format(
													new Date(
														patientDetails.updated_at
													),
													"dd MMM yyyy"
												)
											: "N/A"
									}
								/>
							</>
						)}
					</ul>
					<section className="mt-4 flex flex-1 flex-col items-stretch">
						<div className="flex">
							{!hasPassed24Hours ? (
								<>
									<TabButton
										label="Intake Fields"
										isActive={currentTab === "intake"}
										onClick={() => setCurrentTab("intake")}
									/>
									<TabButton
										label="Visit History"
										isActive={
											currentTab === "visit-history"
										}
										onClick={() =>
											setCurrentTab("visit-history")
										}
									/>
								</>
							) : (
								<h3 className="text-xl">Visit History</h3>
							)}
						</div>
						<div className="flex max-h-[390px] flex-1 flex-col items-stretch overflow-y-auto pt-4">
							{hasPassed24Hours ? (
								<ul className="flex flex-col space-y-2">
									{dummyVisitHistory.map((item, idx) => (
										<CustomerVisitHistoryCard
											data={item}
											key={idx}
										/>
									))}
								</ul>
							) : (
								<>
									{currentTab === "intake" && (
										<IntakeFieldsTab
											isEditing={isEditing}
											patientDetails={
												{
													location_id:
														patientDetails?.waitlist
															?.location_id,
													station_id:
														patientDetails?.waitlist
															?.station_id,
													customer: {
														id:
															patientDetails?.id ||
															0,
													},
												} as any
											}
											register={register}
											errors={errors}
											control={control}
										/>
									)}
									{currentTab === "visit-history" && (
										<ul className="flex flex-col space-y-2">
											{dummyVisitHistory.map(
												(item, idx) => (
													<CustomerVisitHistoryCard
														data={item}
														key={idx}
													/>
												)
											)}
										</ul>
									)}
								</>
							)}
						</div>
					</section>
					<DialogFooter className="mt-3 flex items-center space-x-4 border-t-primary pt-5">
						{isEditing ? (
							<>
								<Button
									className="max-w-[150px] flex-1"
									onClick={() => setIsEditing(!isEditing)}
									// TODO: Add reset functionality when doing integration
									variant="outline-primary"
								>
									Cancel
								</Button>

								<Button
									className="max-w-[150px] flex-1 bg-primary text-white"
									onClick={() => setIsEditing(!isEditing)}
								>
									Save
								</Button>
							</>
						) : (
							<div className="flex w-full items-center justify-between">
								<CustomerDelete
									patientDetails={patientDetails}
									setShowDeleteCustomerModal={
										setShowDeleteCustomerModal
									}
									showDeleteCustomerModal={
										showDeleteCustomerModal
									}
								/>
								<div className="flex items-center space-x-4">
									<Button
										className="w-[150px] flex-1"
										onClick={() =>
											setShowPatientInformation(false)
										}
										// TODO: Add reset functionality when doing integration
										variant="outline-primary"
									>
										Cancel
									</Button>
									<Button
										className="w-[150px] flex-1 bg-primary text-white"
										onClick={() => setIsEditing(!isEditing)}
									>
										Edit
									</Button>
								</div>
							</div>
						)}
					</DialogFooter>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default CustomerInformationModal;

const MainPatientInfo: React.FC<{
	title: string;
	description?: string | number;
}> = ({ title, description }) => {
	return (
		<li className="flex flex-col ">
			<p className="tracking-[-1%] text-[#858C95]">{title}</p>
			<p className="tracking-[-1%] text-[#323539]">{description}</p>
		</li>
	);
};

interface TabButtonProps {
	label: string;
	isActive: boolean;

	onClick: () => void;
}

const TabButton: React.FC<TabButtonProps> = ({ label, isActive, onClick }) => {
	return (
		<button
			className="flex flex-1 items-center justify-center border-b"
			type="button"
			onClick={onClick}
		>
			<div
				className={cn(
					"-mb-[1.5px] flex items-center justify-center space-x-2 border-b-2 border-transparent py-2.5 transition-all duration-200 ease-in-out",
					{
						"border-b-primary": isActive,
					}
				)}
			>
				<h1
					className={cn(
						"text-[15px] font-semibold leading-[22px] text-[#858C95] transition-all duration-200 ease-in-out",
						{
							"text-primary": isActive,
						}
					)}
				>
					{label}
				</h1>
			</div>
		</button>
	);
};

const dummyVisitHistory = [
	{
		location_name: "Main Office",
		station_name: "Reception",
		date: "12 Aug 2024",
		appointment_time: "12/15/20 - 8:12 a.m.",
	},
	{
		location_name: "Main Office",
		station_name: "Reception",
		date: "12 Aug 2024",
		appointment_time: "12/15/20 - 8:12 a.m.",
	},
	{
		location_name: "Main Office",
		station_name: "Reception",
		date: "12 Aug 2024",
		appointment_time: "12/15/20 - 8:12 a.m.",
	},
	{
		location_name: "Main Office",
		station_name: "Reception",
		date: "12 Aug 2024",
		appointment_time: "12/15/20 - 8:12 a.m.",
	},
];

export const has24HoursPassed = (
	date: string | Date | null | undefined
): boolean => {
	if (!date) return false;

	const now = new Date();
	const hoursPassed = differenceInHours(now, new Date(date));

	return hoursPassed >= 24;
};
