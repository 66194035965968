import { create } from "zustand";
import { persist } from "zustand/middleware";
import { queueSettingsProps, StationsArrayProps } from "../types/Location";

interface StationDetailStore {
	stations: StationsArrayProps;
	setStations: (stations: StationsArrayProps) => void;
	stationDetails: queueSettingsProps;
	setStationDetails: (stationDetails: queueSettingsProps) => void;
	reset: () => void;
}

const initialState = {
	stations: [],
	stationDetails: {},
};

const useStationStore = create<
	StationDetailStore,
	[["zustand/persist", StationDetailStore]]
>(
	persist(
		(set) => ({
			...initialState,
			setStationDetails: (stationDetails: queueSettingsProps) => {
				set(() => ({ stationDetails }));
			},
			setStations: (stations: StationsArrayProps) => {
				set(() => ({ stations }));
			},
			reset: () => set(initialState),
		}),
		{
			name: "station-storage",
			getStorage: () => localStorage,
		}
	)
);

export default useStationStore;
