import {
	EditorConfig,
	NodeKey,
	SerializedLexicalNode,
	Spread,
	LexicalNode,
} from "lexical";
import { DecoratorNode } from "lexical";

export type SerializedImageNode = Spread<
	{
		src: string;
		altText: string;
	},
	SerializedLexicalNode
>;

export class ImageNode extends DecoratorNode<JSX.Element> {
	__src: string;
	__altText: string;

	static getType(): string {
		return "image";
	}

	static clone(node: ImageNode): ImageNode {
		return new ImageNode(node.__src, node.__altText, node.__key);
	}

	constructor(src: string, altText: string, key?: NodeKey) {
		super(key);
		this.__src = src;
		this.__altText = altText;
	}

	setSrc(src: string): void {
		const writable = this.getWritable();
		writable.__src = src;
	}

	setAltText(altText: string): void {
		const writable = this.getWritable();
		writable.__altText = altText;
	}

	getSrc(): string {
		return this.__src;
	}

	getAltText(): string {
		return this.__altText;
	}

	createDOM() // config: EditorConfig
	: HTMLElement {
		const img = document.createElement("img");
		img.src = this.__src;
		img.alt = this.__altText;
		return img;
	}

	updateDOM(): false {
		return false;
	}

	static importJSON(serializedNode: SerializedImageNode): ImageNode {
		const { src, altText } = serializedNode;
		const node = $createImageNode(src, altText);
		return node;
	}

	exportJSON(): SerializedImageNode {
		return {
			type: "image",
			src: this.getSrc(),
			altText: this.getAltText(),
			version: 1,
		};
	}

	decorate(): JSX.Element {
		return (
			<img
				src={this.__src}
				alt={this.__altText}
				className="h-auto max-w-full"
			/>
		);
	}
}

export function $createImageNode(src: string, altText: string): ImageNode {
	return new ImageNode(src, altText);
}

export function $isImageNode(
	node: LexicalNode | null | undefined
): node is ImageNode {
	return node instanceof ImageNode;
}
