import NotifyAllPatients from "@/src/components/Dashboard/waitlist/NotifyAllPatients";
import { QueueData } from "@/src/types/waitlist/waitlist";
import DisplayLocationAndStationCount from "@src/components/Dashboard/display/DisplayLocationAndStationCount";
import dayjs from "dayjs";
import React, { ReactNode } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { LuCalendarDays } from "react-icons/lu";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/ui/button";
import useUserStore from "../../store/useUserStore";

const Header: React.FC<{
	content?: ReactNode;
	titleContent?: ReactNode;
	title: string;
	showDate?: boolean;
	showLocationStationCount?: boolean;
	canGoBack?: boolean;
}> = ({
	content,
	title,
	showDate = true,
	titleContent,
	showLocationStationCount,
	canGoBack,
}) => {
	const location = useLocation();
	const navigate = useNavigate();
	const pageName = [
		"waitlist",
		"schedule",
		"analytics",
		"display",
		"locations",
	];
	const currentPage = location.pathname.split("/")[2];
	// const title = pageName.includes(currentPage) ? currentPage : "Dashboard";
	const user = useUserStore((s) => s.user);
	const queryClient = useQueryClient();
	const currentLocationId = useUserStore((s) => s.currentLocationId);
	const customerIds =
		queryClient
			.getQueryData<{
				status: boolean;
				data: QueueData;
				message: string;
			}>(["queue-list", currentLocationId])
			?.data.queue_order.map((item) => item.customer_id) || [];
	const [showBulkNotifyPatientsModal, setShowBulkNotifyPatientsModal] =
		React.useState(false);

	return (
		<header className="flex items-center justify-between pb-4">
			<div className="flex items-center">
				{canGoBack && (
					<Button
						variant={"link"}
						className="pl-0"
						onClick={() => navigate(-1)}
					>
						<IoIosArrowBack size={24} />
					</Button>
				)}
				<div>
					{titleContent ? (
						titleContent
					) : (
						<p className="text-sm text-[#6D748D]">
							{user?.business.name}
						</p>
					)}
					<h2 className="text-[28px] font-semibold capitalize leading-[28px] text-[#323539]">
						{/* {currentPage.includes("display")
						? "Today's Waitlist"
						: title} */}
						{title}
					</h2>
				</div>
			</div>
			{content}
			{showLocationStationCount && <DisplayLocationAndStationCount />}
			<div className="flex items-center space-x-8">
				{showDate && (
					<div className="flex items-center space-x-2 text-[16px] font-medium">
						<LuCalendarDays size={18} />
						<div>{dayjs().format("MMM DD, YYYY")}</div>
					</div>
				)}

				{currentPage === "waitlist" && (
					<>
						<Button
							size="icon-sm"
							variant="outline"
							onClick={() =>
								setShowBulkNotifyPatientsModal(
									!showBulkNotifyPatientsModal
								)
							}
						>
							<i className="mgc_notification_line grid size-9 place-content-center text-[20px] before:!text-[#4E4E4E]" />
						</Button>
						<NotifyAllPatients
							customerIds={customerIds}
							showBulkNotifyPatientsModal={
								showBulkNotifyPatientsModal
							}
							setShowBulkNotifyPatientsModal={
								setShowBulkNotifyPatientsModal
							}
						/>
					</>
				)}

				<Button size="sm" variant="outline">
					<i
						className="mgc_large_arrow_up_line text-[20px] before:!text-main-1
					"
					/>
					<span className="ml-2">Upgrade plan</span>
				</Button>
			</div>
		</header>
	);
};

export default Header;
