import { Check, ChevronDown } from "lucide-react";
import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useState,
} from "react";
import {
	UseFormSetValue,
	UseFormTrigger,
	FieldValues,
	FieldError,
	Merge,
	FieldErrorsImpl,
} from "react-hook-form";
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
import { AddBusinessInfoData } from "../../types/Business";
import { countryOptions } from "../../utils/constants";
import { cn } from "../../utils/functions";
import { Option } from "../form/CustomSelect";
import { Button } from "../ui/button";
import { Command, CommandInput, CommandItem, CommandList } from "../ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { changeCountry, updateCountryAndState } from "./AboutBusiness";
import useUserStore from "../../store/useUserStore";
import { Label } from "../ui/label";

const AddressComponent: React.FC<{
	setFormValue: UseFormSetValue<FieldValues | AddBusinessInfoData>;
	setProvinceOptions: Dispatch<
		SetStateAction<
			{
				label: string;
				value: string;
			}[]
		>
	>;
	formTrigger: UseFormTrigger<FieldValues | AddBusinessInfoData>;
	errorMessage?:
		| string
		| FieldError
		| Merge<FieldError, FieldErrorsImpl<any>>
		| undefined;
	buttonClassName?: string;
}> = ({
	setFormValue,
	setProvinceOptions,
	formTrigger,
	errorMessage,
	buttonClassName,
}) => {
	const {
		ready,
		value,
		suggestions: { data },
		setValue,
		clearSuggestions,
	} = usePlacesAutocomplete({
		debounce: 300,
	});
	// console.log("🚀 ~ value:", value);
	const [open, setOpen] = useState(false);
	const [selected, setSelected] = useState<string>("");
	const user = useUserStore((s) => s.user);

	const handleSetActive = useCallback((suggestion) => {
		setSelected(suggestion.description);
		setValue(suggestion.description);

		setOpen(false);

		clearSuggestions();

		getGeocode({ address: suggestion.description }).then((results) => {
			// Extract address components to get city, province, country, and postal code
			const addressComponents = results[0].address_components;
			let city = "";
			let province = "";
			let country = "";
			let postalCode = "";

			for (const component of addressComponents) {
				const types = component.types;
				if (types.includes("locality")) {
					city = component.long_name;
				} else if (types.includes("administrative_area_level_1")) {
					province = component.long_name;
				} else if (types.includes("country")) {
					country = component.long_name;
				} else if (types.includes("postal_code")) {
					postalCode = component.long_name;
				}
			}
			const countryValue = findCountryByLabel(country);

			setFormValue("city", city);
			setFormValue("postal_code", postalCode);
			setFormValue("address", suggestion.description);

			const newProvinceOptions = changeCountry(countryValue);
			setProvinceOptions(newProvinceOptions);

			const newProvince = findProvinceByLabel(
				newProvinceOptions,
				province
			);
			updateCountryAndState(
				setFormValue,
				setProvinceOptions,
				true,
				newProvince,
				countryValue
			);
			formTrigger();
			// setFormValue("postal_code", postalCode);
		});
	}, []);

	useEffect(() => {
		if (user?.business) {
			setValue(user?.business?.address ?? "");
		}
	}, []);

	const displayName = selected ? selected : "Enter your address";

	return (
		<div className="flex-1">
			<Label>
				Address <span className="text-red-600">*</span>
			</Label>
			<Popover open={open} onOpenChange={setOpen}>
				<PopoverTrigger asChild className="flex w-full">
					<Button
						variant="outline"
						role="combobox"
						className={cn(
							`max-w-[385px] justify-between overflow-hidden ${buttonClassName ? buttonClassName : ""}`
						)}
					>
						<p
							className={cn(
								"truncate-address font-medium text-[#323539]",
								{
									"text-[#323539]/40":
										displayName === "Enter your address",
								}
							)}
						>
							{displayName}
						</p>
						<ChevronDown className="ml-auto h-4 w-4 shrink-0 opacity-50" />
					</Button>
				</PopoverTrigger>

				<PopoverContent
					side="bottom"
					className={cn(
						"flex-1 p-0",
						"min-w-[350px]",
						"max-h-[--radix-popover-content-available-height] w-[--radix-popover-trigger-width]"
					)}
				>
					<Command
						shouldFilter={false}
						className="h-auto rounded-lg border border-b-0 shadow-md"
					>
						<CommandInput
							value={value}
							onValueChange={setValue}
							disabled={!ready}
							placeholder="Search for your address"
						/>

						<CommandList>
							{data.map((suggestion) => {
								const { place_id } = suggestion;
								return (
									<CommandItem
										key={place_id}
										onSelect={() =>
											handleSetActive(suggestion)
										}
										value={suggestion}
									>
										<Check
											className={cn(
												"mr-2 h-4 w-4",
												selected === suggestion
													? "opacity-100"
													: "opacity-0"
											)}
										/>
										{suggestion.description}
									</CommandItem>
								);
							})}
						</CommandList>
					</Command>
				</PopoverContent>
			</Popover>
			{errorMessage &&
				(typeof errorMessage === "string" ? (
					<small className="mt-1.5 text-sm text-red-500">
						{errorMessage}
					</small>
				) : (
					<small className="mt-1.5 text-sm text-red-500">
						{String(errorMessage)}
					</small>
				))}
		</div>
	);
};

export default AddressComponent;

export const findCountryByLabel = (searchingCountry: string) => {
	return (
		countryOptions.find(
			(country) =>
				country.label.toLowerCase() === searchingCountry.toLowerCase()
		)?.value ?? ""
	);
};

export const findProvinceByLabel = (
	newProvinceOptions: Option[],
	searchingProvince: string
) => {
	return (
		newProvinceOptions.find(
			(province) =>
				province.label.toLowerCase() === searchingProvince.toLowerCase()
		)?.value ?? ""
	);
};
