import React, { useState } from "react";
import { MdOutlineDragIndicator } from "react-icons/md";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
	arrayMove,
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button } from "../ui/button";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import FormInput from "@src/components/form/FormInput";

type DraggableContainerProps = {
	id: string;
	children: React.ReactNode;
	onDelete: (() => void) | null;
	onAdd?: () => void;
};

export const DraggableContainer = ({
	id,
	children,
	onDelete,
	onAdd,
}: DraggableContainerProps) => {
	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({ id });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	return (
		<div
			ref={setNodeRef}
			style={style}
			{...attributes}
			className="mb-2 flex overflow-hidden"
		>
			<div
				{...listeners}
				className="flex h-auto cursor-grab items-center justify-start"
			>
				<MdOutlineDragIndicator className="text-gray-400" size={16} />
			</div>
			<div className="relative flex flex-grow items-center justify-between space-x-4 px-2 pr-14">
				{children}

				{onDelete && (
					<div className="absolute right-8 top-1/2 -translate-y-1/2 transform">
						<i
							onClick={onDelete}
							className="mgc_close_line cursor-pointer text-[16px] text-primary"
						></i>
					</div>
				)}
				{onAdd && (
					<div className="absolute right-2 top-1/2 -translate-y-1/2 transform">
						<i
							onClick={onAdd}
							className="mgc_add_circle_fill cursor-pointer text-[16px] text-primary"
						></i>
					</div>
				)}
			</div>
		</div>
	);
};

const DraggableWrapper = ({ initialItems, newItem }: any) => {
	const [items, setItems] = useState(
		initialItems.map((item, index) => ({
			id: `item-${index + 1}`,
			content: item,
		}))
	);

	const {
		watch,
		control,
		register,
		getValues,
		setValue,
		reset,
		formState: { errors },
	} = useFormContext();

	const { fields, append, remove, move } = useFieldArray({
		control,
		name: "options",
	});

	console.log("🚀 ~ DraggableWrapper ~ fields:", fields);
	const handleDragEnd = (event) => {
		const { active, over } = event;

		if (active.id !== over.id) {
			const oldIndex = fields.findIndex((item) => item.id === active.id);
			const newIndex = fields.findIndex((item) => item.id === over.id);
			move(oldIndex, newIndex);
		}
	};

	return (
		<div>
			<DndContext
				collisionDetection={closestCenter}
				onDragEnd={handleDragEnd}
			>
				<SortableContext
					items={fields.map((f) => f.id)}
					strategy={verticalListSortingStrategy}
				>
					{fields.map((field, index) => (
						<DraggableContainer
							key={field.id}
							id={field.id}
							onDelete={
								fields.length > 1 ? () => remove(index) : null
							}
							onAdd={
								index === fields.length - 1
									? () => append(newItem)
									: undefined
							}
						>
							<Controller
								name={`options.${index}`}
								control={control}
								render={({ field }) => (
									<FormInput
										{...field}
										inputType="text"
										className="h-10"
										ref={field.ref}
										placeholder="Option"
										register={{
											...register(`options.${index}`),
										}}
									/>
								)}
							/>
							{errors.options?.[index] && (
								<p className="mt-1 text-xs text-red-500">
									{errors.options[index].message}
								</p>
							)}
						</DraggableContainer>
					))}
				</SortableContext>
			</DndContext>
		</div>
	);
};

export default DraggableWrapper;
