import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";
import "./styles/index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { CONFIGS } from "./configs/index.ts";
import QueryClientConfig from "./configs/QueryClientConfig.tsx";
import "mingcute_icon/font/Mingcute.css";
import "./styles/ming-cute-icon-styles.scss";
import Routes from "./Routes.tsx";

if (CONFIGS.SENTRY.DSN)
	Sentry.init({
		dsn: CONFIGS.SENTRY.DSN,
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [
			"localhost",
			/^https:\/\/migranium\.com\/api/,
			/^https:\/\/migranium\.com/,
		],
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});

ReactDOM.createRoot(document.getElementById("root")!).render(
	<>
		<GoogleOAuthProvider clientId={CONFIGS.GOOGLE.OAUTH_CLIENT_ID}>
			<React.StrictMode>
				<QueryClientConfig>
					<Routes />
				</QueryClientConfig>
			</React.StrictMode>
		</GoogleOAuthProvider>
	</>
);
