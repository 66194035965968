import { GetLocationsSlice } from "@/src/store/slices/locations/locationSlice";
import {
	DeleteManyTeamMemberSlice,
	GetTeamMembersSlice,
} from "@/src/store/slices/team-members/teamMembersSlice";
import { GetTeamMembersResponse, TeamMember } from "@/src/types/TeamMember";
import Checkbox from "@src/components/ui-extended/checkbox";
import InputIcon from "@src/components/ui-extended/input-icon";
import { Button } from "@src/components/ui/button";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@src/components/ui/select";
import { TooltipProvider } from "@src/components/ui/tooltip";
import { sortByFilter } from "@src/utils/constants";
import {
	cn,
	handleRowCheckboxChange,
	handleSelectAllChange,
	sortByName,
} from "@src/utils/functions";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import RequestIsLoading from "../../RequestIsLoading";
import TeamMemberCard from "./TeamMemberCard";
import DeleteTeamMemberModal from "./DeleteTeamMember";
import DeleteManyTeamMemberModal from "./ManyDeleteTeamMember";
// import {Location} from ""

const TeamMembersTab: React.FC = () => {
	const queryClient = useQueryClient();
	const locations = GetLocationsSlice();
	// const { user, currentLocationId, currentStationId } = useUserStore((s) => ({
	// 	user: s.user,
	// 	locations: s.user?.business.locations,
	// 	currentLocationId: s.currentLocationId,
	// 	currentStationId: s.currentStationId,
	// }));

	const [selectedLocationId, setSelectedLocationId] =
		useState<string>("All Locations");
	const [selectedStationId, setSelectedStaionId] = useState("All Stations");
	const [initialTeamMembers, setInitialTeamMembers] =
		useState<GetTeamMembersResponse>();
	const [selectedRows, setSelectedRows] = useState<any[]>([]);
	const [selectAllChecked, setSelectAllChecked] =
		React.useState<boolean>(false);
	const [sortField, setSortField] = useState<
		"asc" | "dsc" | "created_at" | "updated_at" | undefined
	>();
	const [searchValue, setSearchValue] = useState<string | undefined>();
	const [selectedTeamMember, setSelectedTeamMember] = useState<
		undefined | TeamMember
	>();
	const [showDeleteTeamMemberModal, setShowDeleteTeamMemberModal] =
		useState(false);
	const [showManyDeleteTeamMemberModal, setShowManyDeleteTeamMemberModal] =
		useState(false);

	const [queryParams, setQueryparams] = useState<
		Record<string, string | number | undefined>
	>({
		location_id: undefined,
		station_id: undefined,
	});

	const teamMemberQuery = GetTeamMembersSlice(queryParams, undefined, false);
	const deleteManyTeamMemberMutation = DeleteManyTeamMemberSlice();

	const currentLocation = locations.data?.data?.find(
		(location) => location.id == +selectedLocationId
	);

	const stations = currentLocation
		? [
				{ label: "All Stations", value: "All Stations" },
				...(currentLocation?.stations.map((station) => ({
					label: station.name,
					value: station.id.toString(),
				})) || []),
			]
		: [];

	// Sorting logic for team members
	const sortTeamMembers = (
		data: GetTeamMembersResponse["data"],
		selectedSortField: "updated_at" | "asc" | "dsc" | "created_at"
	) => {
		return [...data].sort((a, b) => {
			// Handle alphabetical sorting for asc and dsc
			if (selectedSortField === "asc" || selectedSortField === "dsc") {
				const nameA = a.name.toLowerCase();
				const nameB = b.name.toLowerCase();
				if (selectedSortField === "asc") {
					return nameA < nameB ? -1 : 1;
				} else {
					return nameA > nameB ? -1 : 1;
				}
			}

			// Handle date sorting for created_at and updated_at
			if (
				selectedSortField === "created_at" ||
				selectedSortField === "updated_at"
			) {
				const dateA = new Date(a[selectedSortField]).getTime();
				const dateB = new Date(b[selectedSortField]).getTime();
				return selectedSortField === "created_at"
					? dateA - dateB
					: dateB - dateA;
			}

			return 0;
		});
	};

	// Handle sort change
	const handleSortChange = (
		selectedSortField: "updated_at" | "asc" | "dsc" | "created_at"
	) => {
		setSortField(selectedSortField);

		// Sorting items based on the selected field and order
		const sortedData = sortTeamMembers(
			teamMemberQuery?.data?.data || [],
			selectedSortField
		);
		queryClient.setQueryData<GetTeamMembersResponse | undefined>(
			["get-team-members", queryParams],
			(prevData) => {
				if (!prevData) return undefined;

				return {
					...prevData,
					data: sortedData,
				};
			}
		);
	};

	const handleSearch = (value: string) => {
		const searchTerm = value.toLowerCase();
		setSearchValue(searchTerm);
		if (!searchTerm.length) {
			// Reset to initial team members if search is cleared
			queryClient.setQueryData<GetTeamMembersResponse | undefined>(
				["get-team-members", queryParams],
				() => initialTeamMembers
			);
			return;
		}

		// Filter based on search input
		queryClient.setQueryData<GetTeamMembersResponse | undefined>(
			["get-team-members", queryParams],
			(prevData) => {
				if (!prevData) return undefined;

				const filteredData = prevData.data.filter((item) =>
					item.name.toLowerCase().includes(searchTerm)
				);

				return {
					...prevData,
					data: filteredData,
				};
			}
		);
	};

	React.useEffect(() => {
		queryClient.invalidateQueries(["get-team-members", queryParams]);
	}, [queryParams]);

	React.useEffect(() => {
		if (teamMemberQuery.isFetched) {
			setInitialTeamMembers(teamMemberQuery.data);
		}
	}, [
		// teamMemberQuery.isSuccess,
		teamMemberQuery.isFetched,
		teamMemberQuery.isFetching,
	]);

	return (
		<>
			<div className="mt-5 flex w-full flex-col space-y-3.5">
				<div className="flex w-full justify-stretch space-x-1.5">
					<InputIcon
						position="left"
						className="placeholder:text-[#8491a2]"
						outerClassName="h-9 flex-1"
						placeholder="Search by name"
						icon="mgc_search_line"
						onChange={(e) => handleSearch(e.target.value)}
						value={searchValue}
					/>

					<Select
						value={selectedLocationId.toString()}
						onValueChange={(value) => {
							setSelectedLocationId(value);
							setSelectedStaionId("All Stations");
							setQueryparams({
								location_id:
									value === "All Locations"
										? undefined
										: +value,
								station_id: undefined,
							});
							setSortField(undefined);
						}}
					>
						<SelectTrigger
							className={cn(
								"h-9 flex-1"
								// 	{
								// 	"max-w-[100%]":
								// 		selectedLocationId === "All Locations",
								// }
							)}
						>
							<SelectValue
								placeholder="Locations"
								className="text-[#858C95]"
							/>
						</SelectTrigger>
						<SelectContent className="">
							{[
								{
									label: "All Locations",
									value: "All Locations",
								},
								...(locations?.data?.data.map((location) => ({
									label: location.name,
									value: location.id.toString(),
								})) || []),
							]?.map((option, mainIndex) => {
								return (
									<SelectGroup key={mainIndex}>
										<SelectItem value={option.value}>
											{option.label}
										</SelectItem>
									</SelectGroup>
								);
							})}
						</SelectContent>
					</Select>
					{!!stations.length && (
						<Select
							defaultValue={"All Stations"}
							value={selectedStationId}
							onValueChange={(value) => {
								setSelectedStaionId(value);

								setQueryparams({
									location_id:
										value === "All Stations"
											? +selectedLocationId
											: undefined,
									station_id:
										value !== "All Stations"
											? +value
											: undefined,
								});
								setSortField(undefined);
							}}
						>
							<SelectTrigger className="h-9 flex-1">
								<SelectValue
									placeholder="Stations"
									className="text-[#858C95]"
								/>
							</SelectTrigger>
							<SelectContent className="">
								{stations.map((option, mainIndex) => {
									return (
										<SelectGroup key={mainIndex}>
											<SelectItem value={option.value}>
												{option.label}
											</SelectItem>
										</SelectGroup>
									);
								})}
							</SelectContent>
						</Select>
					)}

					<Select onValueChange={handleSortChange} value={sortField}>
						<SelectTrigger className="h-9 flex-1">
							<SelectValue placeholder="Sort By" />
						</SelectTrigger>
						<SelectContent>
							{sortByFilter.map((sort, i) => (
								<SelectItem key={i} value={sort.value}>
									{sort.label}
								</SelectItem>
							))}
						</SelectContent>
					</Select>
					<Link to="add" className="flex max-w-[144px] flex-1">
						<Button className="h-9 flex-1 text-white">Add</Button>
					</Link>
				</div>
				<section className="flex flex-1 flex-col space-y-4">
					<div className="flex justify-between">
						<div className="flex items-center space-x-1.5">
							<Checkbox
								className="rounded-sm border-2 border-[#424954]"
								handleCheckboxChange={() =>
									handleSelectAllChange(
										!selectAllChecked,
										setSelectedRows,
										setSelectAllChecked,
										teamMemberQuery?.data?.data.map(
											(item) => item.user.id
										) ?? []
									)
								}
								isChecked={selectAllChecked}
								id={""}
							/>
							<p className="text-[14px] font-medium leading-[24px] tracking-[-0.14px] text-[#7D7E80]">
								Select All
							</p>
						</div>
						<div
							className={cn(
								"flex items-center space-x-1.5 duration-200 ease-in-out",
								{
									"opacity-30": !selectedRows.length,
								}
							)}
						>
							<Button
								variant="outline-primary"
								className="size-fit p-2.5"
								disabled={
									deleteManyTeamMemberMutation.isLoading
								}
							>
								<i className="mgc_user_security_line before:!text-primary" />
							</Button>
							<Button
								variant="outline-primary"
								className="size-fit p-2.5"
								disabled={
									deleteManyTeamMemberMutation.isLoading
								}
								onClick={() =>
									setShowManyDeleteTeamMemberModal(true)
								}
							>
								<i className="mgc_delete_2_line before:!text-primary" />
							</Button>
						</div>
					</div>
					<div className="relative flex flex-1 flex-col space-y-2 py-2.5">
						<TooltipProvider>
							{teamMemberQuery.data &&
								teamMemberQuery.data.data.map((item, idx) => (
									<TeamMemberCard
										key={idx}
										onSelect={() =>
											handleRowCheckboxChange(
												!selectedRows.includes(
													item.user.id
												),
												item.user.id,
												setSelectedRows,
												teamMemberQuery?.data?.data
													.length,
												setSelectAllChecked
											)
										}
										checked={selectedRows.includes(
											item.user.id
										)}
										item={item}
										onDelete={() => {
											setSelectedTeamMember(item);
											setShowDeleteTeamMemberModal(true);
										}}
									/>
								))}
						</TooltipProvider>
						<RequestIsLoading
							isLoading={teamMemberQuery.isLoading}
							size={20}
						/>
					</div>
				</section>
			</div>
			<DeleteTeamMemberModal
				showDeleteTeamMemberModal={showDeleteTeamMemberModal}
				setShowDeleteTeamMemberModal={setShowDeleteTeamMemberModal}
				teamMember={selectedTeamMember}
				queryParams={queryParams}
			/>
			<DeleteManyTeamMemberModal
				showManyDeleteTeamMemberModal={showManyDeleteTeamMemberModal}
				setShowManyDeleteTeamMemberModal={
					setShowManyDeleteTeamMemberModal
				}
				selectedRows={selectedRows}
				queryParams={queryParams}
			/>
		</>
	);
};

export default TeamMembersTab;
