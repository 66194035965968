import pusher from "@src/configs/PusherConfig";
import useUserStore from "@src/store/useUserStore";
import useWaitListStore from "@src/store/useWaitListStore";
import {
	PatientStatus,
	StatusOfPatient,
	StatusOfPatientType,
} from "@src/types/wait-list";
import { QueueEntry } from "@src/types/waitlist/waitlist";
import { debounce } from "lodash";
import React from "react";
import { useQueryClient } from "react-query";

const useWaitlistWebSocket = () => {
	const sessionId = pusher.sessionID;
	const currentLocationId = useUserStore((s) => s.currentLocationId);
	const currentStationId = useUserStore((s) => s.currentStationId);
	const setPatientsObject = useWaitListStore((s) => s.setPatientsObject);
	const queryClient = useQueryClient();

	React.useEffect(() => {
		if (!currentLocationId) return;

		const trackQueueChannel = pusher.subscribe(
			`location-${currentLocationId}`
		);

		const handleQueueUpdated = debounce(
			(data: QueueEntry[]) => {
				if (data) {
					handleUpdateWaitlist(data);
					queryClient.setQueryData(
						["queue-list", currentLocationId],
						(prevQueryData: any) => {
							if (!prevQueryData)
								return {
									...prevQueryData,
									data: {
										queue_order: data,
										queue_settings: {
											...prevQueryData.data
												.queue_settings,
											is_active:
												!prevQueryData.data
													.queue_settings.is_active,
										},
									},
								};
							// Update only the queue_order if it has changed
							if (
								JSON.stringify(
									prevQueryData.data.queue_order
								) === JSON.stringify(data)
							) {
								return prevQueryData;
							}
							return {
								...prevQueryData,
								data: {
									...prevQueryData.data,
									queue_order: data,
								},
							};
						}
					);
				}
			},
			300 // Adjust debounce time as needed
		);

		trackQueueChannel.bind("queue-updated", handleQueueUpdated);

		return () => {
			trackQueueChannel.unbind("queue-updated", handleQueueUpdated);
			pusher.unsubscribe(`location-${currentLocationId}`);
		};
	}, [currentLocationId, sessionId]);

	const handleUpdateWaitlist = React.useCallback(
		(data: QueueEntry[]) => {
			if (!data || !data.length) {
				// console.log("No data");
				setPatientsObject({
					[PatientStatus.Pending]: [],
					[PatientStatus.Upcoming]: [],
					[PatientStatus.NextInLine]: [],
					[PatientStatus.Serving]: [],
					[PatientStatus.Schedule]: [],
				});
				return;
			}

			const filterByLocationAndStation = (status: StatusOfPatientType) =>
				data.filter(
					(item) =>
						item.location_id === currentLocationId &&
						item.status === status &&
						(currentStationId === "All Stations" ||
							item.station_id === currentStationId)
				);
			setPatientsObject({
				[PatientStatus.Pending]: filterByLocationAndStation(
					StatusOfPatient.Pending
				),
				[PatientStatus.Upcoming]: filterByLocationAndStation(
					StatusOfPatient.Upcoming
				),
				[PatientStatus.NextInLine]: filterByLocationAndStation(
					StatusOfPatient.NextInLine
				),
				[PatientStatus.Serving]: filterByLocationAndStation(
					StatusOfPatient.GettingService
				),
				[PatientStatus.Schedule]: [],
			});
		},
		[currentLocationId, currentStationId, setPatientsObject]
	);

	return { handleUpdateWaitlist };
};

export default useWaitlistWebSocket;
