import React from "react";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
	DialogFooter,
} from "@src/components/ui/dialog";
import { Label } from "@src/components/ui/label";
import { Button } from "@src/components/ui/button";
import FormInput from "@components/form/FormInput";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import {
	displayErrorsWithTimeout,
	getErrorMessage,
} from "@src/utils/functions";
import { zodResolver } from "@hookform/resolvers/zod";

import useStationStore from "@src/store/useStationStore";
import Loader from "@src/components/Loader/Loader";
import toast from "react-hot-toast";
import { RadioGroup, RadioGroupItem } from "@src/components/ui/radio-group";
import {
	UpdateCustomizationFieldProps,
	UpdateCustomizationFieldSchema,
} from "@src/types/customization";
import {
	GetCustomFieldsSlice,
	UpdateCustomFields,
} from "@src/store/slices/customization/customFieldsSlice";
import useCustomToast from "@src/components/CustomToast";

interface AddFieldsProps {
	modalMode: string | undefined;
	setModalMode: React.Dispatch<React.SetStateAction<string | undefined>>;
	updateMode?: string;
}

const AddExistingCustomFieldModal: React.FC<AddFieldsProps> = ({
	setModalMode,
	modalMode,
	updateMode,
}) => {
	const stationData = useStationStore((s) => s.stationDetails);
	const customToast = useCustomToast();

	const toggleModal = (status: boolean) => {
		setModalMode(status ? "exisiting" : "");
	};

	const {
		data: customFieldData,
		isLoading,
		isSuccess,
		invalidateQuery,
	} = GetCustomFieldsSlice({});

	const methods = useForm<UpdateCustomizationFieldProps>({
		resolver: zodResolver(UpdateCustomizationFieldSchema),
		defaultValues: {
			id: undefined,
			apply_to: "all",
			field_requirement: "yes",
		},
	});

	const {
		handleSubmit,
		reset,
		control,
		formState: { errors, isValid },
	} = methods;

	const {
		mutate: updateCustomFieldsMutation,
		isLoading: updateCustomFieldsLoading,
	} = UpdateCustomFields(() => {
		invalidateQuery();
		setModalMode(undefined);
		reset({
			id: undefined,
			apply_to: "all",
			field_requirement: "yes",
		});
	});

	const onUpdateSubmit: SubmitHandler<UpdateCustomizationFieldProps> = (
		data
	) => {
		updateCustomFieldsMutation(
			{
				data: {
					...data,
					apply_to_option: {
						apply_to_all: 0,
						locations: [
							{
								id: stationData?.location_id || 0,
								update_location:
									updateMode === "location" ? 1 : 0,
								apply_to_all_stations: 0,
								selected_stations:
									updateMode === "location"
										? []
										: [stationData?.id || 0],
							},
						],
					},
				},
				id: data.id || 0,
			},
			{
				onSuccess: () => {
					setModalMode(undefined);
				},
				onError: (error: any) => {
					displayErrorsWithTimeout(
						error.response.data?.error ||
							"An unexpected error occurred. Please try again later",
						customToast,
						{
							toastIdPrefix: "update-custom-fields",
						}
					);
				},
			}
		);
	};

	return (
		<Dialog
			open={modalMode === "exisiting"}
			onOpenChange={(e: boolean) => toggleModal(e)}
		>
			<DialogTrigger asChild>
				<Button
					variant={"default"}
					className="h-auto border-b-2 border-primary font-semibold leading-none outline-none hover:no-underline"
				>
					Add intake Field
				</Button>
			</DialogTrigger>
			<DialogContent aria-describedby="dialog-description">
				<DialogHeader>
					<DialogTitle className="text-center">
						Add Intake Field
					</DialogTitle>
					<DialogDescription></DialogDescription>
				</DialogHeader>
				<form
					className="mt-4 grid gap-4"
					onSubmit={handleSubmit(onUpdateSubmit)}
				>
					<div className="divide-y-2 divide-gray-200">
						<div className="py-4">
							<Controller
								name={"id"}
								control={control}
								render={({ field }) => (
									<FormInput
										ref={field.ref}
										select
										label="Select from Existing Fields"
										labelStyles="font-semibold"
										placeholder="Select"
										options={
											customFieldData?.data.map(
												(field: any) => ({
													label: field.name,
													value: field.id,
												})
											) || []
										}
										className="h-10"
										onChange={(selectedOptions) =>
											field.onChange(
												selectedOptions.value
											)
										}
										error={getErrorMessage(
											errors.id?.message
										)}
									/>
								)}
							/>
							<Button
								type="button"
								variant={"link"}
								className="mt-3 h-auto rounded-none border-b-2 border-primary p-0 font-semibold leading-none outline-none hover:no-underline"
								onClick={() => setModalMode("add")}
							>
								Add New Intake Field
							</Button>
						</div>
						<div className="py-4">
							<p className="mb-2.5 font-semibold">
								Field Requirement
							</p>
							<Controller
								name={"field_requirement"}
								control={control}
								render={({ field }) => (
									<RadioGroup
										className="flex space-x-4"
										onValueChange={field.onChange}
										defaultValue={field.value}
									>
										<div className="flex items-center space-x-2">
											<RadioGroupItem
												value="yes"
												id="yes"
											/>
											<Label htmlFor="yes">Yes</Label>
										</div>
										<div className="flex items-center space-x-2">
											<RadioGroupItem
												value="no"
												id="no"
											/>
											<Label htmlFor="no">No</Label>
										</div>
										<div className="flex items-center space-x-2">
											<RadioGroupItem
												value="new_customers_only"
												id="new_customers_only"
											/>
											<Label htmlFor="new_customers_only">
												Only for first time visitors
											</Label>
										</div>
									</RadioGroup>
								)}
							/>
						</div>
						<div className="py-4">
							<p className="mb-2.5 font-semibold">Apply To</p>
							<Controller
								name={"apply_to"}
								control={control}
								render={({ field }) => (
									<RadioGroup
										className="flex space-x-4"
										onValueChange={field.onChange}
										defaultValue={field.value}
									>
										<div className="flex items-center space-x-2">
											<RadioGroupItem
												value="waitlist_only"
												id="waitlist_only"
											/>
											<Label htmlFor="waitlist_only">
												Waitlist
											</Label>
										</div>
										<div className="flex items-center space-x-2">
											<RadioGroupItem
												value="schedule_only"
												id="schedule_only"
											/>
											<Label htmlFor="schedule_only">
												Schedule
											</Label>
										</div>
										<div className="flex items-center space-x-2">
											<RadioGroupItem
												value="all"
												id="all"
											/>
											<Label htmlFor="all">Both</Label>
										</div>
									</RadioGroup>
								)}
							/>
						</div>
					</div>
					<DialogFooter>
						<div className="mt-2 flex justify-end space-x-3 pt-3">
							<Button
								variant={"outline"}
								type="button"
								className=" self-center rounded-md"
								onClick={() => setModalMode(undefined)}
							>
								Cancel
							</Button>
							<Button
								type="submit"
								disabled={!isValid}
								className="min-w-[90px] rounded-md"
							>
								{updateCustomFieldsLoading ? (
									<Loader size={20} />
								) : (
									"Add"
								)}
							</Button>
						</div>
					</DialogFooter>
				</form>
			</DialogContent>
		</Dialog>
	);
};

export default AddExistingCustomFieldModal;
