import { LoaderButton } from "@/src/components/form/LoaderButton";
import { Button } from "@/src/components/ui/button";
import {
	Card,
	CardContent,
	CardFooter,
	CardHeader,
	CardTitle,
} from "@/src/components/ui/card";
import UpdateWaitlistSlice from "@/src/store/slices/waitlist/updateWaitlistSlice";
import { ServingQueueResponse } from "@/src/types/serving/serving";
import { zodResolver } from "@hookform/resolvers/zod";
import useCustomToast from "@src/components/CustomToast";
import CustomCheckbox2 from "@src/components/form/CustomCheckbox2";
import { Dialog, DialogContent } from "@src/components/ui/dialog";
import { waitlistPriority } from "@src/utils/constants";
import {
	SinglePatientQueueData,
	SinglePatientQueueResponse,
	UpdateWaitlistEnum,
	UpdateWaitlistSchema,
	UpdateWaitlistType,
} from "@type/waitlist/waitlist";
import { isEqual } from "lodash";
import React from "react";
import { useForm } from "react-hook-form";
import { LuX } from "react-icons/lu";
import { useQueryClient } from "react-query";

const PatientPriorityModal: React.FC<{
	patientDetails?: SinglePatientQueueData;
	danger?: boolean;
	showPriorityModal: boolean;
	setShowPriorityModal: React.Dispatch<React.SetStateAction<boolean>>;
	setActivePatient?: React.Dispatch<
		React.SetStateAction<SinglePatientQueueData | undefined>
	>;
}> = ({
	patientDetails,
	showPriorityModal,
	setShowPriorityModal,
	setActivePatient,
}) => {
	const {
		setValue,
		handleSubmit,
		watch,
		reset,
		getValues,
		formState: { errors },
	} = useForm<UpdateWaitlistType>({
		resolver: zodResolver(UpdateWaitlistSchema),
	});

	const [isDisabled, setIsDisabled] = React.useState(true);

	const customToast = useCustomToast();
	const queryClient = useQueryClient();

	const updateWaitlistMutation = UpdateWaitlistSlice(
		(data) => {
			setShowPriorityModal(false);
			customToast(`Patient's priority updated successfully`, {
				id: "manage-patient-" + patientDetails?.id,
				type: "success",
			});
			setShowPriorityModal(false);

			queryClient.setQueryData<ServingQueueResponse | undefined>(
				["get-serving", patientDetails?.waitlist?.location_id],
				(prevData) => {
					if (!prevData) return;
					const updatedData = prevData.data.map((patient) =>
						patient?.waitlist?.id === data.data.id
							? {
									...patient,
									waitlist: {
										...patient.waitlist,
										...data.data,
									},
								}
							: patient
					);

					return {
						...prevData,
						data: updatedData,
					};
				}
			);

			queryClient.setQueryData<SinglePatientQueueResponse | undefined>(
				["get-customer-info", patientDetails?.id],
				(prevData): SinglePatientQueueResponse | undefined => {
					if (!prevData || !data.data) return;
					setActivePatient?.({
						...prevData?.data,
						waitlist: {
							...prevData?.data.waitlist,
							...data?.data,
						},
					});
					return {
						...prevData,
						data: {
							...prevData?.data,
							waitlist: {
								...prevData?.data.waitlist,
								...data?.data,
							},
						},
					};
				}
			);
		},
		() => {
			customToast(`Patient's priority could not be updated`, {
				id: "manage-patient-" + patientDetails?.id,
				type: "error",
			});
		}
	);

	const onSubmit = async (data: UpdateWaitlistType) => {
		updateWaitlistMutation.mutate(data);
	};

	React.useEffect(() => {
		const subscription = watch(() => {
			setIsDisabled(
				isEqual(getValues(), {
					priority: patientDetails?.waitlist?.priority,
					waitlist_id: patientDetails?.waitlist?.id,
					status: UpdateWaitlistEnum.ChangePriority,
				})
			);
		});
		return () => subscription.unsubscribe();
	}, [watch, patientDetails]);

	React.useEffect(() => {
		reset({
			priority: patientDetails?.waitlist?.priority,
			waitlist_id: patientDetails?.waitlist?.id,
			status: UpdateWaitlistEnum.ChangePriority,
		});
	}, [patientDetails]);

	return (
		<>
			<Dialog
				onOpenChange={() => {
					if (!updateWaitlistMutation.isLoading) {
						reset();
						setShowPriorityModal(!showPriorityModal);
					}
				}}
				open={showPriorityModal}
			>
				<DialogContent className="max-w-[400px] p-0">
					<form onSubmit={handleSubmit(onSubmit)}>
						<Card className="flex flex-col space-y-8">
							<CardHeader className="flex flex-row justify-between pb-0">
								<div className="flex flex-col">
									<p className="text-sm leading-[24px] tracking-[-1%] text-main-4">
										Patient Name
									</p>
									<CardTitle className="text-[22px] leading-[30px] -tracking-[1%] text-[#323539]">
										{patientDetails?.full_name}
									</CardTitle>
								</div>
								<LuX
									color="#858C95"
									size={20}
									className="cursor-pointer"
									onClick={() => {
										if (!updateWaitlistMutation.isLoading) {
											reset();
											setShowPriorityModal(false);
										}
									}}
								/>
							</CardHeader>
							<div className="flex flex-col items-start space-y-2 px-6">
								<CardTitle className="text-[22px] leading-[30px] -tracking-[1%]">
									Select Priority Level
								</CardTitle>
								<p className="text-sm leading-[24px] tracking-[-1%] text-main-4">
									Designate Priority Level for Patient&apos;s
									Attention
								</p>
							</div>
							<CardContent className="space-y-5 pb-0">
								{waitlistPriority.map((item, index) => (
									<div
										key={index}
										className="flex items-center space-x-1.5"
									>
										<CustomCheckbox2
											id={`${index}`}
											isChecked={
												watch("priority") === item.value
											}
											handleCheckboxChange={() => {
												setValue(
													"priority",
													item.value
												);
											}}
											className="checked:border-[#A72020] checked:bg-[#A72020]"
										/>
										<label htmlFor="">{item.label}</label>
									</div>
								))}
							</CardContent>
							{errors.root?.message && (
								<p className="!-mt-0 px-8 text-sm tracking-[-0.1px] text-red-500">
									{errors.root?.message}
								</p>
							)}
							<CardFooter className="flex justify-between space-x-4">
								<Button
									variant="outline"
									disabled={updateWaitlistMutation.isLoading}
									className="w-full flex-1 px-8"
									onClick={() => {
										if (!updateWaitlistMutation.isLoading) {
											reset();
											setShowPriorityModal(false);
										}
									}}
								>
									Cancel
								</Button>

								<LoaderButton
									disabled={
										isDisabled ||
										updateWaitlistMutation.isLoading
									}
									loading={updateWaitlistMutation.isLoading}
									loaderSize={20}
									className="flex-1 px-8 text-white"
									type="submit"
								>
									Update
								</LoaderButton>
							</CardFooter>
						</Card>
					</form>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default PatientPriorityModal;
