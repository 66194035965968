import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import moment from "moment";
import { Views } from "react-big-calendar";
import { Calendar } from "..";
import AppointmentBlock from "./AppointmentBlock";
import WeekView from "./WeekView";
import CustomCalendarHeader from "./CustomCalendarHeader";
import CustomCalendarToolbar from "./CustomCalendarToolbar";
import { IoClose } from "react-icons/io5";
import useAppointmentStore from "@src/store/useAppointmentStore";
// import DateSelector from "@components/DateSelector";
// import useScreenDimension from "@hooks/useScreenDimension";

type Keys = keyof typeof Views;

interface CustomCalendarProps {
	events: any;
	handleRemoveSchedule: (selectedStations, station) => void;
	selectedStations: { value: string; label: string }[];
	setSelectedStations: Dispatch<
		SetStateAction<{ value: string; label: string }[]>
	>;
	setShowAppointmentModal: Dispatch<SetStateAction<boolean>>;
}
const CustomCalendar = ({
	events,
	handleRemoveSchedule,
	selectedStations,
	setSelectedStations,
	setShowAppointmentModal,
}: CustomCalendarProps) => {
	const startTimeShown = moment()
		.set({ hour: 6, minute: 0, second: 0, millisecond: 0 })
		.toDate();
	const setFilteredAppointments = useAppointmentStore(
		(s) => s.setFilteredAppointments
	);
	const { view, setView, date, setDate } = useAppointmentStore();
	const filteredAppointments = useAppointmentStore(
		(s) => s.filteredAppointments
	);
	const newSetDate = (newDate: Date | string) => {
		setDate(new Date(newDate).toISOString());
	};
	// const [date, setDate] = useState<Date | undefined>(new Date());
	const onNavigate = useCallback((newDate) => setDate(newDate), [setDate]);
	const onView = useCallback((newView) => setView(newView), [setView]);
	const formats = useMemo(
		() => ({
			timeGutterFormat: (date, culture, localizer) =>
				localizer.format(date, "hh A", culture),
		}),
		[]
	);

	const newResourceMap = selectedStations?.map((station) => ({
		resourceId: station.value,
		resourceTitle: (
			<p className="flex w-full max-w-[80%] justify-between">
				{" "}
				<span>{station.label}</span>
				<button
					onClick={() =>
						handleRemoveSchedule(selectedStations, station)
					}
				>
					<IoClose className="text-lg" />
				</button>
			</p>
		),
	}));
	const mainevents = useMemo(() => {
		if (events?.length === 0) {
			return [];
		}
		return filteredAppointments?.map((event) => {
			return {
				...event,
				start: moment(
					event.appointment_date,
					"YYYY-MM-DD HH:mm:ss"
				).toDate(),
				resourceId: event.station_id,
				end: moment(event.end_at, "YYYY-MM-DD HH:mm:ss").toDate(),
			};
		});
	}, [events, filteredAppointments]);

	useEffect(() => {
		if (events?.length > 0) {
			setFilteredAppointments(events);
		}
	}, [events, setFilteredAppointments]);

	const { components, views } = useMemo(
		() => ({
			components: {
				day: {
					event: (event) => (
						<AppointmentBlock
							appointment={event.event}
							viewType="Day"
						/>
					),
				},
				week: {
					event: (event) => (
						<AppointmentBlock
							appointment={event.event}
							viewType="Week"
						/>
					),
				},
				month: {
					event: (event) => (
						<AppointmentBlock
							appointment={event.event}
							viewType="Month"
						/>
					),
				},
			},

			views: {
				month: true,
				week: WeekView,
				day: true,
			},
		}),
		[]
	);
	return (
		<div className="relative flex h-full w-full flex-col gap-y-4">
			<CustomCalendarToolbar
				view={view}
				date={new Date(date)}
				setDate={newSetDate}
				setView={setView}
				setShowAppointmentModal={setShowAppointmentModal}
			/>
			<div className=" h-full ">
				<CustomCalendarHeader
					handleRemoveSchedule={handleRemoveSchedule}
					setSelectedStations={setSelectedStations}
					selectedStations={selectedStations}
					events={events}
					setDate={newSetDate}
					date={new Date(date)}
				/>
				<div className="flex h-full w-full">
					<Calendar
						className="w-full max-w-full"
						components={components}
						date={date}
						view={view}
						views={views}
						onView={onView}
						{...(view === "day" &&
							selectedStations?.length > 0 && {
								resourceIdAccessor: "resourceId",
								resources: newResourceMap,
								resourceTitleAccessor: "resourceTitle",
							})}
						onNavigate={onNavigate}
						startAccessor="start"
						endAccessor="end"
						formats={formats}
						events={mainevents}
						scrollToTime={startTimeShown}
						showAllEvents={true}
						defaultDate={date}
						// dayLayoutAlgorithm="no-overlap"
						toolbar={false}
					/>
				</div>
			</div>
		</div>
	);
};

export default CustomCalendar;
