import CustomIntakeField from "@/src/components/Dashboard/CustomIntakeField";
import { useGetBusinessLocationsAndStations } from "@/src/store/slices/locations/locationSlice";
import { useGetPatientInfo } from "@/src/store/slices/waitlist/managePatientSlice";
import { JoinWaitlistType } from "@/src/types/waitlist/add-customer-waitlist";
import { SinglePatientQueueData } from "@/src/types/waitlist/waitlist";
import { cn } from "@/src/utils/functions";
import { Button } from "@src/components/ui/button";
import React from "react";
import { Control, FieldErrors, UseFormRegister } from "react-hook-form";

const ServingIntakeFieldsTab: React.FC<{
	isEditing?: boolean;
	patientDetails?: SinglePatientQueueData;
	register?: UseFormRegister<JoinWaitlistType>;
	errors?: FieldErrors<JoinWaitlistType>;
	control?: Control<JoinWaitlistType>;
}> = ({ patientDetails, isEditing, register, errors, control }) => {
	// const customToast = useCustomToast();
	const getBusinessLocationsAndStationsQuery =
		useGetBusinessLocationsAndStations();
	const getPatieintInfoQuery = useGetPatientInfo(
		undefined,
		patientDetails?.id,
		true
	);

	return (
		<>
			{isEditing ? (
				<ul className={cn("flex flex-1 flex-col space-y-4")}>
					{getBusinessLocationsAndStationsQuery.data?.data
						.locations && isEditing
						? getBusinessLocationsAndStationsQuery.data?.data.locations
								.find(
									(location) =>
										location.id ===
										patientDetails?.waitlist?.location_id
								)
								?.stations.find(
									(station) =>
										station.id.toString() ===
										patientDetails?.waitlist?.station_id.toString()
								)
								?.waitlist_custom_fields.map(
									(customField, idx) => (
										<CustomIntakeField
											key={idx}
											intake={customField}
											register={register}
											errors={errors}
											control={control}
										/>
									)
								)
						: ""}
				</ul>
			) : (
				<ul className={cn("flex flex-1 flex-col space-y-2.5")}>
					{!isEditing &&
						getPatieintInfoQuery.data?.data.custom_intakes.map(
							(item, index) => (
								<li
									key={index}
									className="flex items-center justify-between border-b-[0.5px] border-b-[#E6E6E6]"
								>
									<div>
										<p className="leading-[24px] tracking-[-1%] text-[#858C95]">
											{item.name}
										</p>
										<div className="text-[#323539]">
											{/* {valueIsRegex.test(item.value) &&
									JSON.parse(item.value).map(
										(detail: string, idx: number) => {
											console.log(
												Array.isArray(
													JSON.parse(item.value)
												)
											);
											return (
												<p key={idx}>{detail}l</p>
											);
										}
									)} */}
											{/* {item.value} */}
											<ServingIntakeValue
												{...item}
												key={undefined}
											/>
										</div>
									</div>
									<Button
										type="button"
										className="h-fit w-fit rounded-[2px] bg-white p-0 hover:bg-white"
										// onClick={() => {
										// 	navigator.clipboard
										// 		.writeText(
										// 			Array.isArray(
										// 				JSON.parse(item.value)
										// 			)
										// 				? item.value.join("\n")
										// 				: item.value[0]
										// 		)
										// 		.then(() => {
										// 			customToast(
										// 				"Copied to Clipboard",
										// 				{
										// 					id: "copy-to-clipboard",
										// 					type: "success",
										// 				}
										// 			);
										// 		})
										// 		.catch(() => {
										// 			customToast("Unable to copy", {
										// 				id: "copy-to-clipboard",
										// 				type: "error",
										// 			});
										// 		});
										// }}
									>
										{/* <i className="mgc_copy_2_fill text-[18px] before:!text-[#858C95]" /> */}
									</Button>
								</li>
							)
						)}
				</ul>
			)}
		</>
	);
};

export default ServingIntakeFieldsTab;

const ServingIntakeValue: React.FC<{ value: string; type: string }> = ({
	value,
	type,
}) => {
	try {
		let parsedValue: any;

		// Parse the value if possible (e.g., for checkboxes that store arrays as strings)
		if (type !== "date" && type !== "date_range") {
			parsedValue = JSON.parse(value);
		}

		// Switch case to handle different intake types
		const [startDate, endDate] = value.split(" - ");
		switch (type) {
			case "boolean":
				return <p>{+value ? "Yes" : "No"}</p>;
			case "date_range":
				return (
					<div className="flex items-center space-x-1">
						<p>{new Date(startDate).toLocaleDateString("en-us")}</p>
						<p>to</p>
						<p>{new Date(endDate).toLocaleDateString("en-us")}</p>
					</div>
				);

			case "date":
				return <p>{new Date(value).toLocaleDateString("en-us")}</p>;

			default:
				// Handle arrays (for checkboxes) or return the parsed value if it's a string
				if (Array.isArray(parsedValue)) {
					return (
						<ul>
							{parsedValue.map((item: string, index: number) => (
								<li key={item + index}>
									{parsedValue.length > 1
										? index + 1 + "."
										: ""}{" "}
									{item}
								</li>
							))}
						</ul>
					);
				} else {
					// If not an array, return the value as a string
					return <p>{value}</p>;
				}
		}
	} catch {
		// If parsing fails, assume it's a string and just return the value
		return <p>{value}</p>;
	}
};
