import { PatientStatus, StatusOfPatient } from "@src/types/wait-list";
import { QueueData } from "@src/types/waitlist/waitlist";
import useUserStore from "@store/useUserStore";
import useWaitListStore from "@store/useWaitListStore";
import { useQueryClient } from "react-query";

const useJoinedFromSelection: () => {
	handleJoinedFromChange: (value: "qr" | "url" | "all") => void;
} = () => {
	const queryClient = useQueryClient();
	// const previousLocationId = useUserStore((s) => s.currentLocationId);
	const currentLocationId = useUserStore((s) => s.currentLocationId);
	const { setPatientsObject, setCurrentJoinedFrom } = useWaitListStore(
		(s) => ({
			setPatientsObject: s.setPatientsObject,
			setCurrentJoinedFrom: s.setCurrentJoinedFrom,
		})
	);

	const handleJoinedFromChange = (value: "qr" | "url" | "all") => {
		const getQueryList = queryClient.getQueryData([
			"queue-list",
			currentLocationId,
		]) as {
			status: boolean;
			data: QueueData;
			message: string;
		};
		if (!getQueryList || !getQueryList?.data.queue_order) return;
		setCurrentJoinedFrom(value);

		setPatientsObject({
			[PatientStatus.Pending]: getQueryList.data.queue_order.filter(
				(item) =>
					(value === "all" || item.joined_through === value) &&
					item.status === StatusOfPatient.Pending
			),

			[PatientStatus.Upcoming]: getQueryList.data.queue_order.filter(
				(item) =>
					(value === "all" || item.joined_through === value) &&
					item.status === StatusOfPatient.Upcoming
			),
			[PatientStatus.NextInLine]: getQueryList.data.queue_order.filter(
				(item) =>
					(value === "all" || item.joined_through === value) &&
					item.status === StatusOfPatient.NextInLine
			),
			[PatientStatus.Serving]: getQueryList.data.queue_order.filter(
				(item) =>
					(value === "all" || item.joined_through === value) &&
					item.status === StatusOfPatient.GettingService
			),
			[PatientStatus.Schedule]: [],
		});
	};

	return {
		handleJoinedFromChange,
	};
};

export default useJoinedFromSelection;
