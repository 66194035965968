import { cn } from "@/src/utils/functions";
import { zodResolver } from "@hookform/resolvers/zod";
import useCustomToast from "@src/components/CustomToast";
import AddPatient from "@src/components/Dashboard/waitlist/AddPatient/AddPatient";
import WaitlistClearQueue from "@src/components/Dashboard/waitlist/WaitlistClearQueue";
import { Button } from "@src/components/ui/button";
import { Label } from "@src/components/ui/label";
import { Switch } from "@src/components/ui/switch";
import { QueueSettingsSlice } from "@src/store/slices/locations/locationSlice";
import { GetQueryListSlice } from "@src/store/slices/waitlist/getWaitlistSlice";
import useUserStore from "@src/store/useUserStore";
import { queueSettingsProps, queueSettingsSchema } from "@src/types/Location";
import { QueueData } from "@src/types/waitlist/waitlist";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { LuRotateCcw } from "react-icons/lu";
import { useQueryClient } from "react-query";
import WaitlistSettings from "./WaitlistSettings/WaitlistSettings";

const WaitlistShowScheduleAddPatientRow: React.FC<{
	isScheduleOn: boolean;
	tabView: "grid_view" | "column_view";
	setIsScheduleOn: Dispatch<SetStateAction<boolean>>;
	setTabView: Dispatch<SetStateAction<"grid_view" | "column_view">>;
}> = ({ isScheduleOn, setIsScheduleOn, tabView, setTabView }) => {
	const queryClient = useQueryClient();
	const currentLocationId = useUserStore((s) => s.currentLocationId);
	const [showAddPatientModal, setShowAddPatientModal] =
		useState<boolean>(false);

	// const businessSettingsMutation = BusinessSettingsSlice(undefined, () => {
	// 	// setValue("is_queue_active", getValues("is_queue_active") === 1 ? 0 : 1);
	// });

	// const updateGeneralSettigs: (
	// 	data: WaitlistGeneralSettings
	// ) => void = async (data) => {
	// 	// const newIsQueueOn = data.is_queue_active === 1 ? 0 : 1;
	// 	// console.log(data);
	// 	try {
	// 		businessSettingsMutation.mutate({
	// 			data,
	// 		});
	// 	} catch (error) {
	// 		console.error(error);
	// 	}
	// };

	return (
		<>
			<section className="flex items-center justify-between">
				<div className="flex items-center gap-x-6 text-sm">
					<ToggleWaitListOnOff />
					<div className="flex items-center">
						<Label
							htmlFor="schedule-on-off"
							className="font-medium leading-[24px] tracking-[-0.16px] text-[#323539]"
						>
							Show Scheduled Appointments
						</Label>
						<Switch
							className="scale-75 data-[state=checked]:bg-[#32D74B] data-[state=unchecked]:bg-[#E5E5E7]"
							defaultChecked={isScheduleOn}
							id="schedule-on-off"
							onClick={() => {
								if (tabView === "grid_view") {
									setTabView("column_view");
									setIsScheduleOn(!isScheduleOn);
								} else {
									setIsScheduleOn(!isScheduleOn);
								}
							}}
						/>
						<Label
							htmlFor="schedule-on-off"
							className="font-medium leading-[24px] tracking-[-0.16px] text-[#858C95]"
						>
							{isScheduleOn ? "On" : "Off"}
						</Label>
					</div>
					<ToggleAutoFlowOnOff />
				</div>
				<div className="flex items-center gap-x-4 text-sm">
					<Button
						onClick={() =>
							setShowAddPatientModal(!showAddPatientModal)
						}
						className="flex space-x-2 p-3 shadow-[0px_1px_2px_0_rgba(16,24,40,0.04)]"
					>
						<i className="mgc_add_circle_line text-[16px] before:!text-white"></i>
						<p className="text-[14px] font-semibold leading-[20px] text-white">
							Add Patient
						</p>
					</Button>

					<WaitlistClearQueue />

					<Button
						variant="outline"
						size="icon-sm"
						className="shadow-[0px_1px_2px_0_rgba(16,24,40,0.04)]"
						onClick={() => {
							queryClient.invalidateQueries([
								"queue-list",
								currentLocationId,
							]);
							queryClient.invalidateQueries([
								"get-business-locations",
							]);
						}}
					>
						<LuRotateCcw
							size={18}
							className={cn({
								"animate-spin":
									queryClient.getQueryState([
										"queue-list",
										currentLocationId,
									])?.status === "idle" ||
									queryClient.getQueryState([
										"get-business-locations",
									])?.status === "idle",
							})}
						/>
					</Button>

					<WaitlistSettings />
				</div>
			</section>

			<AddPatient
				show={showAddPatientModal}
				setShow={setShowAddPatientModal}
			/>
		</>
	);
};

export default WaitlistShowScheduleAddPatientRow;

// const NumberOfUser: React.FC = () => {
// 	const patients = useWaitListStore((s) => s.patients);
// 	return (
// 		<div className="relative">
// 			<span className="absolute -right-3 -top-2 flex h-[18px] w-[18px] items-center justify-center rounded-full bg-primary p-[2px] text-[9px] text-white">
// 				{patients.length}
// 			</span>
// 			<LuUsers size={18} />
// 		</div>
// 	);
// };

const ToggleWaitListOnOff: React.FC = () => {
	const customToast = useCustomToast();
	const queryClient = useQueryClient();
	const currentLocationId = useUserStore((s) => s.currentLocationId);
	const getQueueList = GetQueryListSlice();

	const initialData = {
		waitlist_setting: {
			is_queue_active: Number(
				queryClient.getQueryData<{ data: QueueData }>([
					"queue-list",
					currentLocationId,
				])?.data.queue_settings.is_active
			),
		},
	};

	const { setValue, getValues, watch } = useForm<queueSettingsProps>({
		resolver: zodResolver(queueSettingsSchema),
		defaultValues: initialData,
	});

	const updateQueueSettingsMutation = QueueSettingsSlice((data) => {
		// Update the form state directly

		queryClient.setQueryData<
			| {
					status: boolean;
					data: QueueData;
					message: string;
			  }
			| undefined
		>(["queue-list", currentLocationId], (oldData) => {
			if (!oldData) return oldData;

			const newIsActive = data?.data?.waitlist_setting?.is_queue_active;

			customToast(`Waitlist ${newIsActive ? "on" : "off"}`, {
				id: "update-queue-settings",
				type: "success",
			});

			// Update the form value directly
			setValue("waitlist_setting.is_queue_active", Number(newIsActive));

			// Update the cached data
			return {
				...oldData,
				data: {
					...oldData.data,
					queue_settings: {
						...oldData.data.queue_settings,
						is_active: Boolean(newIsActive),
					},
				},
			};
		});
	});

	const handleToggleWaitlist = () => {
		const newValue = !getValues("waitlist_setting.is_queue_active");
		customToast("Turning waitlist " + (newValue ? "on" : "off"), {
			id: "update-queue-settings",
			type: "loading",
		});
		updateQueueSettingsMutation.mutate({
			data: {
				apply_to_option: {
					apply_to_all: 0,
					locations: [
						{
							id: currentLocationId,
							update_location: 1,
							apply_to_all_stations: 1,
						},
					],
				},
				waitlist_setting: {
					is_queue_active: Number(newValue),
				},
			},
			queryParams: {
				location_id: currentLocationId,
			},
		});
	};

	React.useEffect(() => {
		if (getQueueList.isSuccess) {
			setValue(
				"waitlist_setting.is_queue_active",
				Number(getQueueList.data?.data.queue_settings.is_active)
			);
		}
	}, [getQueueList.data]);

	return (
		<div className="flex items-center">
			<Label htmlFor="queue-toggle">Waitlist</Label>

			<Switch
				checked={Boolean(watch("waitlist_setting.is_queue_active"))}
				onClick={handleToggleWaitlist}
				disabled={updateQueueSettingsMutation.isLoading}
				id="queue-toggle"
				className="scale-75 data-[state=checked]:bg-[#32D74B] data-[state=unchecked]:bg-[#E5E5E7]"
			/>
			<Label
				htmlFor="queue-toggle"
				className="font-medium leading-[24px] tracking-[-0.16px] text-[#858C95]"
			>
				{watch("waitlist_setting.is_queue_active") ? "On" : "Off"}
			</Label>
		</div>
	);
};

const ToggleAutoFlowOnOff: React.FC = () => {
	const customToast = useCustomToast();
	const queryClient = useQueryClient();
	const currentLocationId = useUserStore((s) => s.currentLocationId);
	const getQueueList = GetQueryListSlice();

	const initialData = {
		waitlist_setting: {
			auto_flow: Number(
				queryClient.getQueryData<{ data: QueueData }>([
					"queue-list",
					currentLocationId,
				])?.data.queue_settings.auto_flow
			),
		},
	};

	const { setValue, getValues, watch } = useForm<queueSettingsProps>({
		resolver: zodResolver(queueSettingsSchema),
		defaultValues: initialData,
	});

	const updateQueueSettingsMutation = QueueSettingsSlice((data) => {
		// Update the form state directly

		queryClient.setQueryData<
			| {
					status: boolean;
					data: QueueData;
					message: string;
			  }
			| undefined
		>(["queue-list", currentLocationId], (oldData) => {
			if (!oldData) return oldData;

			const newIsActive = data?.data?.waitlist_setting?.auto_flow;

			customToast(`Auto flow ${newIsActive ? "on" : "off"}`, {
				id: "update-queue-settings",
				type: "success",
			});

			// Update the form value directly
			setValue("waitlist_setting.auto_flow", Number(newIsActive));

			return {
				...oldData,
				data: {
					...oldData.data,
					queue_settings: {
						...oldData.data.queue_settings,
						auto_flow: Number(newIsActive),
					},
				},
			};
		});
	});

	const handleToggleAutoflow = () => {
		const newValue = !getValues("waitlist_setting.auto_flow");

		customToast("Turning auto flow " + (newValue ? "on" : "off"), {
			id: "update-queue-settings",
			type: "loading",
		});

		updateQueueSettingsMutation.mutate({
			data: {
				apply_to_option: {
					apply_to_all: 0,
					locations: [
						{
							id: currentLocationId,
							update_location: 1,
							apply_to_all_stations: 1,
						},
					],
				},
				waitlist_setting: {
					auto_flow: Number(newValue),
				},
			},
			queryParams: {
				location_id: currentLocationId,
			},
		});
	};

	React.useEffect(() => {
		if (getQueueList.isSuccess) {
			setValue(
				"waitlist_setting.auto_flow",
				Number(getQueueList.data?.data.queue_settings.auto_flow)
			);
		}
	}, [getQueueList.data]);

	return (
		<div className="flex items-center">
			<Label htmlFor="autoflow-toggle">Auto Flow</Label>

			<Switch
				checked={Boolean(watch("waitlist_setting.auto_flow"))}
				onClick={handleToggleAutoflow}
				disabled={updateQueueSettingsMutation.isLoading}
				id="autoflow-toggle"
				className="scale-75 data-[state=checked]:bg-[#32D74B] data-[state=unchecked]:bg-[#E5E5E7]"
			/>
			<Label
				htmlFor="autoflow-toggle"
				className="font-medium leading-[24px] tracking-[-0.16px] text-[#858C95]"
			>
				{watch("waitlist_setting.auto_flow") ? "On" : "Off"}
			</Label>
		</div>
	);
};
