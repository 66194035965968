import { DeleteTeamMemberSlice } from "@/src/store/slices/team-members/teamMembersSlice";
import { GetTeamMembersResponse, TeamMember } from "@/src/types/TeamMember";
import { LoaderButton } from "@src/components/form/LoaderButton";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogTitle,
} from "@src/components/ui/dialog";
import React from "react";
import { LuX } from "react-icons/lu";
import useCustomToast from "../../CustomToast";
import { Button } from "../../ui/button";
import { useQueryClient } from "react-query";

const DeleteTeamMemberModal: React.FC<{
	teamMember?: TeamMember;
	showDeleteTeamMemberModal: boolean;
	setShowDeleteTeamMemberModal: React.Dispatch<React.SetStateAction<boolean>>;
	queryParams: Record<string, string | number | undefined>;
}> = ({
	teamMember,
	queryParams,
	showDeleteTeamMemberModal,
	setShowDeleteTeamMemberModal,
}) => {
	const customToast = useCustomToast();
	const queryClient = useQueryClient();

	const deleteTeamMemberMutation = DeleteTeamMemberSlice(
		() => {
			queryClient.setQueryData<GetTeamMembersResponse | undefined>(
				["get-team-members", queryParams],
				(prevData) => {
					if (!prevData) return undefined;

					return {
						...prevData,
						data: prevData.data.filter(
							(member) => member.id !== teamMember?.id
						),
					};
				}
			);
			queryClient.invalidateQueries(["get-team-members", queryParams]);
			setShowDeleteTeamMemberModal(false);
		},
		() => {
			// customToast("Team Member could not be deleted", {
			// 	id: "delete-team-member",
			// 	type: "error",
			// });
		}
	);

	return (
		<Dialog
			open={showDeleteTeamMemberModal}
			onOpenChange={(newState) => {
				if (!deleteTeamMemberMutation.isLoading)
					setShowDeleteTeamMemberModal(newState);
			}}
		>
			<DialogContent className="max-w-[360px] p-4">
				<div className="flex justify-between space-x-2">
					<i className="mgc_user_x_line py-1 text-[20px] before:!text-[#C8322B]" />
					<div className="flex flex-col">
						<DialogTitle className="text-[22px] font-semibold capitalize leading-[30px] -tracking-[1%] text-[#323539]">
							Delete{" "}
							{teamMember?.user.role
								.toLowerCase()
								.replace("_", " ")}
						</DialogTitle>
						<p className="trakcing-[-0.1px] text-[14px] leading-[20px] text-[#6D748D]">
							Are you sure you want to delete{" "}
							<b className="text-[#323539]">
								{teamMember?.user.name}
							</b>{" "}
							as{" "}
							{teamMember?.user.role
								.toLowerCase()
								.replace("_", " ")}
							? This action can&apos;t be undone.
						</p>
						<DialogFooter className="mt-6 space-x-1 sm:space-x-1">
							<Button
								variant="ghost"
								className="space-x-5 text-[#323539]"
								onClick={() => {
									if (!deleteTeamMemberMutation.isLoading)
										setShowDeleteTeamMemberModal(false);
								}}
								disabled={deleteTeamMemberMutation.isLoading}
							>
								Cancel
							</Button>
							<LoaderButton
								variant="destructive"
								className="px-5 text-white"
								onClick={() => {
									customToast("Deleting Team Member", {
										id: "delete-team-member",
										type: "loading",
									});
									deleteTeamMemberMutation.mutate({
										userId: teamMember?.id ?? 0,
									});
								}}
								loading={deleteTeamMemberMutation.isLoading}
								disabled={deleteTeamMemberMutation.isLoading}
								loaderSize={20}
							>
								Delete
							</LoaderButton>
						</DialogFooter>
					</div>
					<button
						disabled={deleteTeamMemberMutation.isLoading}
						className="flex items-start"
						onClick={(e) => {
							e.preventDefault();
							if (!deleteTeamMemberMutation.isLoading)
								setShowDeleteTeamMemberModal(false);
						}}
					>
						<LuX
							color="#858C95"
							className="cursor-pointer"
							width="20px"
							height="20px"
						/>
					</button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default DeleteTeamMemberModal;
