import { z } from "zod";
import { StatusOfPatient, StatusOfPatientType } from "../wait-list";
import { CustomIntake, IntakeType } from "./custom-intakes";
import { Appointment } from "../UpcomingAppointments";

type ObjectValues<T> = T[keyof T];

export interface QueueCustomer {
	id: number;
	full_name: string;
	email: string;
	phone_number: string;
	business_id: number;
	application_type: string;
	created_at: string;
	updated_at: string;
	health_card_expire_date: string | null;
}

export interface QueueEntry {
	id: number;
	waitlist_id: number;
	is_paused: number;
	status: UpdateWaitlistEnumType;
	priority: PatientPriorityType;
	check_in_status: string | null;
	check_in_at: string | null;
	track_code: string;
	customer_id: number;
	station_id: number;
	station_name: string;
	location_id: number;
	location_name: string;
	business_id: number;
	business_name: string;
	joined_at: string;
	joined_through: "qr" | "url" | "admin";
	order_at: string;
	swap_request_id: number | null;
	address: string;
	station_average_in_minute: string;
	estimated_wait_time: string;
	customer: QueueCustomer;
	station_order: number;
	swap_request: {
		id: number;
		waitlist_id: number;
		status: "waiting";
		created_at: string;
		updated_at: string;
		reason: string;
		position: number;
	};
}

// interface WaitlistInfo {
// 	next_to_serve: QueueEntry[];
// 	accepted: QueueEntry[];
// 	getting_service: QueueEntry[];
// 	waiting_to_be_approved: QueueEntry[];
// }

export interface QueueData {
	queue_settings: {
		auto_flow: number;
		average_wait_time: number;
		is_active: boolean;
	};
	queue_order: QueueEntry[];
}

export interface SinglePatientQueueResponse {
	status: boolean;
	message: string;
	data: SinglePatientQueueData;
}

export interface UpdatedPatientData {
	status: boolean;
	message: string;
	data: SinglePatientQueueData;
}

export interface SinglePatientQueueData {
	id: number;
	full_name: string;
	email: string;
	phone_number: string;
	business_id: number;
	application_type: string;
	created_at: string;
	updated_at: string;
	is_follow_up: 0 | 1;
	health_card_expire_date: string | null;
	custom_intakes: {
		id: number;
		key: string;
		name: string;
		type: IntakeType;
		customer_id: number;
		value: string;
		created_at: string;
		updated_at: string;
	}[];
	waitlist?: SinglePatientQueueWaitlistData;
	appointment?: Appointment;
}

export interface SinglePatientQueueWaitlistResponse {
	status: boolean;
	message: string;
	data: SinglePatientQueueWaitlistData;
}

export interface SinglePatientQueueWaitlistData {
	id?: number;
	queue_id: number;
	business_id: number;
	location_id: number;
	station_id: number;
	customer_id: number;
	assigned_user_id: number | null;
	approved_user_id: number;
	cancelled_user_id: number | null;
	service_start_at: string | null;
	service_done_at: string | null;
	approved_at: string;
	cancelled_at: string | null;
	cancelled_reason: string | null;
	order: number;
	track_code: string;
	status: StatusOfPatientType; // e.g., "accepted"
	created_at: string;
	updated_at: string;
	ordered_at: string | null;
	joined_at: string | null;
	checked_in_at: string | null;
	checked_in_confirmed: number;
	order_at: string;
	is_paused: number;
	next_to_serve_at: string | null;
	check_in_status: string | null;
	check_in_at: string | null;
	priority: PatientPriorityType;
	joined_through: "qr" | "url" | "admin"; // e.g., "qr"
	track_url: string;
	feedback: string | null;
	station?: {
		id: number;
		name: string;
		location_id: number;
		admin_id: number;
		created_at: string;
		updated_at: string;
		is_active: number;
		business_id: number;
		schedule_before: string | null;
		average_waiting_time: string | null;
		use_average_wait_time: number;
	};
	location?: {
		id: number;
		name: string;
		address: string;
		country: string;
		state: string;
		city: string;
		admin_id: number;
		business_id: number;
		created_at: string;
		updated_at: string;
		is_active: number;
		time_zone: string;
		average_waiting_time: string | null;
		use_average_wait_time: number;
		image: string | null;
		phone_number: string | null;
		description: string | null;
		url_code: string;
		qr_code: string;
	};
	swap_request: any | null;
}

export interface PatientHistoryResponse {
	status: boolean;
	message: string;
	data: PatientHistoryRecord[];
}

export interface PatientHistoryRecord {
	type: string;
	record: WaitlistPatientHistoryRecord;
	date: string;
}

export interface WaitlistPatientHistoryRecord {
	id: number;
	queue_id: number;
	business_id: number;
	location_id: number;
	station_id: number;
	customer_id: number;
	assigned_user_id: number | null;
	approved_user_id: number | null;
	cancelled_user_id: number | null;
	service_start_at: string | null;
	service_done_at: string | null;
	approved_at: string | null;
	cancelled_at: string | null;
	cancelled_reason: string | null;
	order: number;
	track_code: string;
	status: string;
	created_at: string;
	updated_at: string;
	ordered_at: string | null;
	joined_at: string | null;
	checked_in_at: string | null;
	checked_in_confirmed: number;
	order_at: string | null;
	is_paused: number;
	next_to_serve_at: string | null;
	check_in_status: string | null;
	check_in_at: string | null;
	priority: string;
	joined_through: "qr" | "url" | "admin";
	track_url: string;
	feedback: string | null;
}

export const WaitlistGeneralSettingsSchema = z.object({
	manual_wait_time: z.string().optional(),
	time_zone: z.string().optional(),
	use_average_wait_time: z.union([z.literal(0), z.literal(1)]).optional(),
	schedule_block_in_min: z.union([z.literal(0), z.literal(1)]).optional(),
	is_queue_active: z.union([z.literal(0), z.literal(1)]).optional(),
	auto_approve_enabled: z.union([z.literal(0), z.literal(1)]).optional(),
});

export type WaitlistGeneralSettings = z.infer<
	typeof WaitlistGeneralSettingsSchema
>;

export const PatientPriority = {
	High: "high",
	Medium: "medium",
	Normal: "normal",
} as const;

export const UpdateWaitlistEnum = {
	...StatusOfPatient,
	CancelledByBusinessAdmin: "cancelled_by_business_admin",
	CancelledByCustomer: "cancelled_by_customer",
	Expired: "expired",
	CheckIn: "check_in",
	UndoCheckIn: "undo_check_in",
	SwapApprove: "swap_approve",
	SwapDenied: "swap_denied",
	NotifyTurn: "notify_turn",
	ChangeStation: "change_station",
	ChangePriority: "change_priority",
} as const;

export const UpdateWaitlistSchema = z.object({
	waitlist_id: z.number(),
	status: z
		.enum(
			Object.values(UpdateWaitlistEnum) as [
				UpdateWaitlistEnumType,
				...UpdateWaitlistEnumType[],
			]
		)
		.optional(),
	order_at: z.string().optional(),
	station_id: z.string().optional(),
	priority: z
		.enum(
			Object.values(PatientPriority) as [
				PatientPriorityType,
				...PatientPriorityType[],
			]
		)
		.optional(),
});

export const BulkUpdateWaitlistSchema = UpdateWaitlistSchema.extend({
	waitlist_ids: z.array(z.number().int()),
}).omit({
	waitlist_id: true,
});

export const BulkNotifyWaitlistCustomersSchema = z.object({
	customer_ids: z.array(z.number().int()),
	message: z.string().optional(),
});

export type UpdateWaitlistType = z.infer<typeof UpdateWaitlistSchema>;
export type BulkUpdateWaitlistType = z.infer<typeof BulkUpdateWaitlistSchema>;
export type UpdateWaitlistEnumType = ObjectValues<typeof UpdateWaitlistEnum>;
export type PatientPriorityType = ObjectValues<typeof PatientPriority>;
export type BulkNotifyWaitlistCustomersType = z.infer<
	typeof BulkNotifyWaitlistCustomersSchema
>;
