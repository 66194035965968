import { Button } from "../../../../components/ui/button";
import { FaRegFile } from "react-icons/fa";
import { Switch } from "@/src/components/ui/switch";
import Loader from "@/src/components/Loader/Loader";
import { useEffect, useState } from "react";
import useStationStore from "@/src/store/useStationStore";
import {
	GetLinkedCalendarSlice,
	UpdateLinkedCalendarSlice,
} from "@/src/store/slices/locations/locationSlice";
import { PiGoogleLogo, PiMicrosoftOutlookLogo } from "react-icons/pi";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "@/src/components/ui/dropdown";
import { useSearchParams } from "react-router-dom";

interface ICalendarAccount {
	id: number;
	user_id: number;
	provider_name: string;
	provider_user_id: string;
	email: string;
	check_for_conflict: boolean;
	add_to_calendar: boolean;
	connected?: any;
}

const LinkedCalendars = () => {
	const stationData = useStationStore((s) => s.stationDetails);
	const [searchParams] = useSearchParams();
	const stationId = searchParams.get("stationId") || "";
	const [calendarData, setCalendarData] = useState<ICalendarAccount[]>([]);
	const [googleAddLink, setGoogleAddLink] = useState<string>("");
	const [outlookAddLink, setOutlookAddLink] = useState<string>("");

	const { data, invalidateQuery, isLoading } = GetLinkedCalendarSlice(
		Number(parseInt(stationId))
	);

	useEffect(() => {
		if (data?.data) {
			setCalendarData(data?.data?.user_accounts);
			setGoogleAddLink(data?.data?.google_link_url);
			setOutlookAddLink(data?.data?.outlook_link_url);
		}
	}, [data?.data]);

	const { mutate: updateLinkedCalendarSettings, isLoading: isUpdating } =
		UpdateLinkedCalendarSlice(
			(data) => {
				console.log("Update successful:", data);
				invalidateQuery();
			},
			(error) => {
				console.error("Update failed:", error);
			}
		);

	const handleSwitchChange =
		(field: any, account_id: any) => (value: boolean) => {
			// setValue(field, value ? 1 : 0);
			updateLinkedCalendarSettings({
				data: {
					[field]: value,
				},
				queryParams: {
					station_id: stationId || 0,
					account_id: account_id || 0,
				},
			});
		};

	const [selectedOption, setSelectedOption] = useState<string | null>(null);

	const handleSelectChange = (value: string) => {
		setSelectedOption(value);
		if (value === "google") {
			window.open(googleAddLink, "_blank", "width=600,height=600");
		} else if (value === "outlook") {
			window.open(outlookAddLink, "_blank", "width=600,height=600");
		}
	};
	return (
		<div className="flex w-full flex-col gap-y-4">
			<div className="flex  justify-between">
				<div className="flex w-full flex-col gap-y-4">
					<h1 className="text-xl font-medium text-[#323539] ">
						Linked Calendars
					</h1>
					<div className="flex w-full items-center justify-between">
						<p className="text-[14px] text-[#858C95]">
							Link your calendar to sync all appointments
						</p>

						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<Button variant="outline">Link</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent className="w-auto">
								<DropdownMenuGroup>
									<DropdownMenuItem
										onClick={() =>
											handleSelectChange("google")
										}
									>
										<PiGoogleLogo className="mr-2 h-4 w-4" />
										<span>Google</span>
									</DropdownMenuItem>
									<DropdownMenuSeparator />
									<DropdownMenuItem
										onClick={() =>
											handleSelectChange("outlook")
										}
									>
										<PiMicrosoftOutlookLogo className="mr-2 h-4 w-4" />
										<span>Outlook</span>
									</DropdownMenuItem>
								</DropdownMenuGroup>
							</DropdownMenuContent>
						</DropdownMenu>
					</div>
				</div>
			</div>
			{isLoading ? (
				<div className="flex min-h-48 w-full items-center justify-center">
					<Loader size={40} />
				</div>
			) : calendarData.length > 0 ? (
				<div className="divide-y border-t">
					{calendarData?.map((account: ICalendarAccount) => (
						<div
							key={account.id}
							className="flex flex-col space-y-4 pb-4 pt-5"
						>
							<div className="flex items-center space-x-1.5">
								<i className="mgc_mail_line text-2xl before:!text-[#1C1B1F]" />
								<h2 className="text-lg font-medium">
									{account.email}
								</h2>
								{/* <i className="mgc_edit_2_line before:!text-gray-400" /> */}
								<Button
									type="button"
									variant={"ghost"}
									className="m-0 p-0 hover:bg-transparent"
									// onClick={handleSubmit(onSubmit)}
								>
									<i className="mgc_edit_2_line before:!text-gray-400" />
								</Button>
							</div>
							<div className="space-y-2.5">
								<div className="flex flex-1 items-center">
									<div className="flex flex-1 flex-col">
										<h3 className="text-[15px] font-medium">
											Check for Conflicts
										</h3>
										<p className="text-sm text-gray-400">
											Will not allow appointments if there
											is a conflict with this calendar
										</p>
									</div>
									<div className="flex items-center">
										<Switch
											className="scale-[0.70] data-[state=checked]:bg-[#2EBF43]"
											checked={account.check_for_conflict}
											onCheckedChange={handleSwitchChange(
												"check_for_conflict",
												account.id
											)}
										/>
										<span className="">
											{account.check_for_conflict
												? "On"
												: "Off"}
										</span>
									</div>
								</div>
								<div className="flex flex-1 items-center">
									<div className="flex flex-1 flex-col">
										<h3 className="text-[15px] font-medium">
											Add to Calendar
										</h3>
										<p className="text-sm text-gray-400">
											Add appointments created on
											Migranium to this calendar
										</p>
									</div>
									<div className="flex items-center">
										<Switch
											className="scale-[0.70] data-[state=checked]:bg-[#2EBF43]"
											checked={account.add_to_calendar}
											onCheckedChange={handleSwitchChange(
												"add_to_calendar",
												account.id
											)}
										/>
										<span className="">
											{account.add_to_calendar
												? "On"
												: "Off"}
										</span>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			) : (
				<div className="space-y-1 rounded-xl bg-[#F8F8F8] p-6 text-center">
					<FaRegFile size={28} className="mx-auto text-gray-500" />
					<p className="font-semibold">No calendar linked</p>
					<p className="text-gray-400">
						Accounts will appear here once linked.
					</p>
				</div>
			)}
		</div>
	);
};

export default LinkedCalendars;
