import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Stations from "../components/Stations";
import OperatingHours from "../components/OperatingHours";
import IntakeFields from "../components/IntakeFields";
import Members from "../components/Members";
// import { useForm } from "react-hook-form";
import {
	GetLocationDetailsSlice,
	GetLocationStationsSlice,
} from "@src/store/slices/locations/locationSlice";
// import CustomCheckbox2 from "@components/form/CustomCheckbox2";
import useCustomToast from "@components/CustomToast";
import { CustomImage } from "@components/common/CustomImage";
import RatingComponent from "@components/common/RatingComponent";
import { LuMapPin, LuTrash2, LuInfo } from "react-icons/lu";
import { IoMdStopwatch } from "react-icons/io";
import { PiPencilSimple } from "react-icons/pi";
import { Card } from "@components/ui/card";
import { Button } from "@components/ui/button";
import { Badge } from "@components/ui/badge";
import { LocationsIProps } from "@src/types/Location";
import { convertTimeFormat } from "@src/utils/functions";
import { DeleteLocationModal, LocationInfoModal } from "../components";
import { DeleteLocationSlice } from "@src/store/slices/locations/locationSlice";
import Header from "@src/layouts/Header/Header";

const DeleteLocationContent = ({
	displayString,
}: {
	displayString: string;
}) => {
	return (
		<p className="mt-2 text-sm leading-[20px] -tracking-[0.1px] text-[#6D748D]">
			Are you sure you want to delete{" "}
			<span className="font-semibold">&quot;{displayString}&quot;</span>
			{". "}This action cannot be undone.{" "}
			<span className="font-semibold">&quot;{displayString}&quot;</span>{" "}
			will no longer be visible to the system.
		</p>
	);
};

const LocationDetails: React.FC = () => {
	const navigate = useNavigate();
	const customToast = useCustomToast();
	const [tab, setTab] = useState("Station");
	const [showLocationInfoModal, setShowLocationInfoModal] = useState(false);
	const [selectedLocation, setSelectedLocation] =
		useState<LocationsIProps | null>(null);
	const [showDeleteLocationModal, setShowDeleteLocationModal] =
		useState(false);
	const [searchParams] = useSearchParams();
	const locationId = searchParams.get("locationId") || "";

	const { data, isLoading, invalidateQuery } = GetLocationDetailsSlice(
		Number(parseInt(locationId))
	);
	const { data: stationData, isLoading: isStationLoading } =
		GetLocationStationsSlice(parseInt(locationId));

	const { isLoading: deleteLoading, mutate: deleteMutation } =
		DeleteLocationSlice(selectedLocation?.id || 0, () => {
			setShowDeleteLocationModal(false);
			invalidateQuery();
		});

	const handleDeleteLocationModal = (location: any) => {
		setSelectedLocation(location);
		setShowDeleteLocationModal(true);
	};

	const RenderTab = (activeStep: string) => {
		switch (activeStep) {
			case "Station":
				return <Stations stationLoading={isStationLoading} />;
			case "Intake Fields":
				return <IntakeFields />;
			case "Members":
				return <Members />;
			case "Operating Hours":
				return (
					<OperatingHours
						stationLoading={isLoading}
						stationData={data?.data?.stations}
					/>
				);
			default:
				return "";
		}
	};

	useEffect(() => {
		if (!locationId) navigate("/dashboard/locations");
	}, [navigate, locationId]);

	const handleShowLocationInfoModal = (location: any) => {
		setSelectedLocation(location);
		setShowLocationInfoModal(true);
	};

	const handleDeleteLocation = () => {
		customToast("Deleting location", {
			id: "delete-location",
			type: "loading",
		});
		setShowDeleteLocationModal(false);
		if (selectedLocation) {
			deleteMutation({ id: selectedLocation.id });
		}
	};

	return (
		<main className="flex flex-1 flex-col">
			<Header title={"Locations"} showDate={false} canGoBack />
			<div className="flex flex-1 flex-col overflow-auto">
				<div className="flex-1 space-y-4">
					<Card className="flex items-center justify-between space-x-3 bg-slate-50 p-4">
						<div className="flex flex-1 items-center space-x-3">
							<CustomImage
								src={data?.data?.image_url}
								alt="location"
								imageClass="aspect-square w-[112px] rounded-full border bg-slate-50 max-h-[112px] min-h-[112px]"
							/>
							<div className="flex-1 space-y-2">
								<div className="flex w-full items-center space-x-3">
									<p className="line-clamp-1 font-semibold">
										{data?.data?.name}
									</p>
									<div className="">
										<RatingComponent rating={3} readOnly />
									</div>
								</div>
								<div className="flex items-center space-x-1 text-gray-400">
									<LuMapPin size={12} />
									<p className="line-clamp-1 text-xs">
										{data?.data?.address || "N/A"}
									</p>
								</div>
							</div>
						</div>
						<div className="flex flex-1 items-center justify-between space-x-2">
							<div className="flex-2 space-x-3">
								<Badge
									className="bg-slate-200 text-sm"
									variant={"secondary"}
								>
									<i className="mgc_shop_line before-text-gray-4 mr-2 text-[18px]" />
									<span className=" mr-1 font-normal">
										Stations
									</span>{" "}
									{data?.data?.stations.length}
								</Badge>
								<Badge
									className="bg-slate-200 text-sm"
									variant={"secondary"}
								>
									<IoMdStopwatch className="mr-2" size={14} />
									<span className="mr-1 font-normal">
										Avg. Wait Time
									</span>{" "}
									{convertTimeFormat(
										data?.data?.approximate_waiting_time ||
											""
									)}
								</Badge>
							</div>

							<div className="flex flex-1 items-center justify-end space-x-4">
								<Button
									variant="secondary"
									size="icon-sm"
									type="button"
									onClick={() =>
										handleShowLocationInfoModal(data?.data)
									}
								>
									<LuInfo size={18} className=" text-black" />
								</Button>
								<Button
									variant="secondary"
									size="icon-sm"
									type="button"
									onClick={() => {
										navigate(
											`/dashboard/locations/edit-location?locationId=${data?.data?.id}`
										);
									}}
								>
									<PiPencilSimple
										size={18}
										className=" text-black"
									/>
								</Button>
								<Button
									variant="secondary"
									size="icon-sm"
									type="button"
									onClick={() =>
										handleDeleteLocationModal(data?.data)
									}
								>
									<LuTrash2
										size={18}
										className=" text-black"
									/>
								</Button>
							</div>
						</div>
					</Card>
					<div className="flex justify-evenly border-b font-medium text-gray-400">
						{[
							"Station",
							"Operating Hours",
							"Intake Fields",
							"Members",
						].map((item, index) => (
							<p
								key={index}
								onClick={() => setTab(item)}
								className={`cursor-pointer py-3 ${tab === item ? "border-b-2 border-primary font-semibold text-primary" : ""}`}
							>
								{item}
							</p>
						))}
					</div>

					{RenderTab(tab)}
					<DeleteLocationModal
						locationName={selectedLocation?.name || ""}
						showModal={showDeleteLocationModal}
						setShowModal={setShowDeleteLocationModal}
						isLoading={deleteLoading}
						buttonAction={handleDeleteLocation}
						DeleteContent={DeleteLocationContent}
					/>
					<LocationInfoModal
						location={selectedLocation}
						showModal={showLocationInfoModal}
						setShowModal={setShowLocationInfoModal}
						isLoading={deleteLoading}
						buttonAction={() =>
							navigate(
								`/dashboard/locations/edit-location?locationId=${data?.data?.id}`
							)
						}
					/>
				</div>
			</div>
		</main>
	);
};

export default LocationDetails;
