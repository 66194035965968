import RequestIsLoading from "@/src/components/RequestIsLoading";
import { GetServingSlice } from "@/src/store/slices/serving/getServingSlice";
import { GetQueryListSlice } from "@/src/store/slices/waitlist/getWaitlistSlice";
import { Button } from "@src/components/ui/button";
import { ScrollArea } from "@src/components/ui/scroll-area";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@src/components/ui/tooltip";
import { PatientStatus, StatusOfPatient } from "@src/types/wait-list";
import {
	QueueEntry,
	SinglePatientQueueData,
	SinglePatientQueueResponse,
	SinglePatientQueueWaitlistResponse,
} from "@src/types/waitlist/waitlist";
import { cn, toTitleCase } from "@src/utils/functions";
import React from "react";
import ServingPatientInformation from "../ServingPatientInformation/ServingPatientInformation";
import ServingPatientPriorityModal from "../ServingPatientPriorityModal";
import ServingSectionLargeCard from "./ServingSectionLargeCard";
import { Badge } from "@/src/components/ui/badge";
import PatientSentToSentToDoneModal from "@/src/components/Dashboard/waitlist/PatientSentToDoneModal";
import useUserStore from "@/src/store/useUserStore";
import { ServingQueueResponse } from "@/src/types/serving/serving";
import { useQueryClient } from "react-query";

const formattedPatientStatus = {
	[StatusOfPatient.GettingService]: "Serving",
	[StatusOfPatient.Done]: "Done",
	expired: "Expired",
};

const ServingSection: React.FC = () => {
	const getWaitlistQuery = GetQueryListSlice();
	const getServingQuery = GetServingSlice();
	const queryClient = useQueryClient();
	const [activePatient, setActivePatient] =
		React.useState<SinglePatientQueueData>();
	const [showPatientInformation, setShowPatientInformation] =
		React.useState(false);
	const [showPriorityModal, setShowPriorityModal] = React.useState(false);
	const currentLocationId = useUserStore((s) => s.currentLocationId);

	const onDoneSuccess = (data: SinglePatientQueueWaitlistResponse) => {
		setActivePatient(undefined);
		queryClient.setQueryData<ServingQueueResponse | undefined>(
			["get-serving", currentLocationId],
			(prevData) => {
				if (!prevData) return;
				const updatedData = prevData.data.filter(
					(patient) => patient?.waitlist?.id !== data.data.id
				);

				return {
					...prevData,
					data: updatedData,
				};
			}
		);
	};

	return (
		<>
			<section className="flex flex-1 space-x-[30px] overflow-hidden pt-[28px]">
				<ScrollArea
					className={cn("max-h-[64dvh] min-w-fit self-stretch", {
						"flex-1": !activePatient,
					})}
				>
					<div className="flex flex-1 flex-col space-y-7 pb-2">
						<ul className="flex flex-1 flex-col space-y-2.5">
							<h2 className="text-[32px] font-medium tracking-[-1%]">
								Serving Now
							</h2>
							<TooltipProvider>
								{getServingQuery.data &&
									getServingQuery.data.data.map(
										(patient, index) => (
											<ServingSectionCard
												key={index}
												patient={patient}
												isActive={
													patient?.waitlist?.id ===
													activePatient?.waitlist?.id
												}
												setActivePatient={
													setActivePatient
												}
												setShowPatientInformation={
													setShowPatientInformation
												}
												setShowPriorityModal={
													setShowPriorityModal
												}
												onDoneSuccess={onDoneSuccess}
											/>
										)
									)}
							</TooltipProvider>
						</ul>
						{/* <ul className="flex flex-1 flex-col space-y-2.5">
							<h2 className="text-[32px] font-medium tracking-[-1%]">
								Upcoming
							</h2>
							<TooltipProvider>
								{patients.map((patient, index) => (
									<ServingSectionCard
										key={index}
										patient={patient}
										isActive={
											patient.waitlist_id ===
											activePatient
										}
										activePatient={activePatient}
										setActivePatient={setActivePatient}
									/>
								))}
							</TooltipProvider>
						</ul> */}
					</div>
				</ScrollArea>
				{!!activePatient && (
					<ServingSectionLargeCard
						patient={activePatient}
						setActivePatient={setActivePatient}
						setShowPriorityModal={setShowPriorityModal}
					/>
				)}
			</section>
			<RequestIsLoading
				isLoading={
					// queryClient.getQueryState(["queue-list", currentLocationId])
					// 	?.status === "idle" ||
					// queryClient.getQueryState(["queue-list", currentLocationId])
					// 	?.status === "loading"
					getWaitlistQuery.isLoading || getServingQuery.isLoading
				}
				size={24}
			/>
			<ServingPatientInformation
				patientDetails={activePatient}
				showPatientInformation={showPatientInformation}
				setShowPatientInformation={setShowPatientInformation}
			/>
			<ServingPatientPriorityModal
				patientDetails={activePatient}
				showPriorityModal={showPriorityModal}
				setShowPriorityModal={setShowPriorityModal}
				setActivePatient={setActivePatient}
			/>
		</>
	);
};

export default ServingSection;

const ServingSectionCard: React.FC<{
	patient: SinglePatientQueueData;
	isActive: boolean;
	setActivePatient: React.Dispatch<
		React.SetStateAction<undefined | SinglePatientQueueData>
	>;
	setShowPatientInformation: React.Dispatch<React.SetStateAction<boolean>>;
	setShowPriorityModal: React.Dispatch<React.SetStateAction<boolean>>;
	onDoneSuccess: (data: SinglePatientQueueWaitlistResponse) => void;
}> = ({
	isActive,
	patient,
	setActivePatient,
	setShowPatientInformation,
	setShowPriorityModal,
	onDoneSuccess,
}) => {
	return (
		<li
			className={cn(
				"m-[5px] flex items-center justify-between rounded-lg bg-white px-4 py-2 shadow-[0px_0px_8px_0px_rgba(0,0,0,0.15)] outline outline-transparent duration-200 ease-in-out",
				{
					"outline-2 outline-[#1E8A7C]": isActive,
					"outline-[#E5E5E7] ": !isActive,
					"space-x-1": isActive,
				}
			)}
			onClick={() => {
				setActivePatient(isActive ? undefined : patient);
			}}
		>
			<div
				className={cn("flex flex-1 flex-col", {
					"flex-[0.35]": !isActive,
				})}
			>
				<div className="flex items-center space-x-4">
					<Tooltip delayDuration={0}>
						<TooltipTrigger asChild className="flex items-center">
							<h3 className="max-w-[13ch] overflow-hidden text-ellipsis whitespace-nowrap text-[20px] font-semibold text-[#323539]">
								{patient.full_name}
							</h3>
						</TooltipTrigger>
						<TooltipContent
							side="top"
							className="capitalize"
							sideOffset={10}
						>
							{patient.full_name}
						</TooltipContent>
					</Tooltip>

					<Button
						className="h-[18px] w-[22px] rounded-sm bg-[#F5F5F5] p-0 hover:bg-[#F5F5F5]"
						onClick={(e) => {
							e.stopPropagation();
							setActivePatient?.(patient);
							setShowPatientInformation(true);
						}}
					>
						<i className="mgc_information_line text-[14px] before:!text-main-1" />
					</Button>
					<Button
						className={cn(
							"flex h-[18px] w-[22px] items-center justify-center rounded-[4px] bg-[#EAEBF0] p-0 hover:bg-[#EAEBF0]"
						)}
						onClick={(e) => {
							e.stopPropagation();
							setActivePatient?.(patient);
							setShowPriorityModal(true);
						}}
					>
						<i
							className={cn(
								"mgc_tag_fill text-[14px] before:!text-[#969696]",
								{
									"before:!text-[#C8322B]":
										patient.waitlist?.priority === "high",
								}
							)}
						/>
					</Button>

					<i
						className={cn(`block size-2 rounded-full`, {
							"bg-[#2EBF43]":
								patient.waitlist?.check_in_status === "pending",
							"bg-destructive":
								patient.waitlist?.check_in_status ===
								"to_be_approved",
						})}
					/>
				</div>

				<Tooltip delayDuration={0}>
					<TooltipTrigger asChild>
						<div className="flex max-w-fit items-center space-x-1 py-2">
							<i className="mgc_shop_line before:!text-[#6D748D]" />
							<p className="max-w-[15ch] text-[14px] text-[#858C95]">
								{patient.waitlist?.station?.name}
							</p>
						</div>
					</TooltipTrigger>
					<TooltipContent
						side="top"
						className="capitalize"
						sideOffset={10}
					>
						{patient.waitlist?.station?.name}
					</TooltipContent>
				</Tooltip>
			</div>
			{patient.appointment && (
				<div
					className={cn(
						"flex min-w-fit max-w-fit  items-center space-x-1.5 rounded-full bg-[#EAE2F4] px-3 py-1",
						{ "flex-[0.14]": !isActive }
					)}
				>
					<>
						<i className="mgc_calendar_time_add_line text-[18px] before:!text-[#323539]" />
						{!isActive && <p className="text-[12px]">Scheduled</p>}
					</>
				</div>
			)}
			<div className="flex items-center space-x-6">
				{!isActive && (
					<Badge
						className="h-7 w-[100px] place-content-center rounded-md text-[14px] font-normal leading-[24px] tracking-[-1%] text-white"
						style={{
							backgroundColor:
								patient.waitlist?.status ===
								StatusOfPatient.GettingService
									? "#138576"
									: patient.waitlist?.status ===
										  StatusOfPatient.Done
										? "#596574"
										: "#596574",
						}}
					>
						{formattedPatientStatus[
							patient.waitlist?.status ?? 0
						] &&
							toTitleCase(
								formattedPatientStatus[
									patient.waitlist?.status ?? 0
								].replace(/_/g, " ")
							)}
					</Badge>
				)}
				{isActive && (
					<PatientSentToSentToDoneModal
						waitlist_id={patient.waitlist?.id ?? 0}
						customer_id={patient.id}
						onSuccess={onDoneSuccess}
					/>
				)}
				{isActive && (
					<Button
						variant="secondary"
						className="size-[38px] p-0"
						onClick={() => {
							setActivePatient(isActive ? undefined : patient);
						}}
					>
						<i className="mgc_right_line text-[20px]" />
					</Button>
				)}
			</div>
		</li>
	);
};
