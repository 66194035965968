import { PatientStatus, StatusOfPatient } from "@src/types/wait-list";
import { QueueData } from "@src/types/waitlist/waitlist";
import useUserStore from "@store/useUserStore";
import useWaitListStore from "@store/useWaitListStore";
import { QueueEntry } from "@type/waitlist/waitlist";
import { useQueryClient } from "react-query";

const useStatusSelection: () => {
	handlePatientStatusChange: (value: string) => void;
} = () => {
	const queryClient = useQueryClient();
	const setCurrentPatientStatus = useUserStore(
		(s) => s.setCurrentPatientStatus
	);
	const currentLocationId = useUserStore((s) => s.currentLocationId);
	const currentStationId = useUserStore((s) => s.currentStationId);
	const setPatientsObject = useWaitListStore((s) => s.setPatientsObject);
	const getQueryList = queryClient.getQueryData([
		"queue-list",
		currentLocationId,
	]) as {
		status: boolean;
		data: QueueData;
		message: string;
	};

	const filterByStation = (items: QueueEntry[]) => {
		if (currentStationId === "All Stations") {
			return items.filter(
				(item) => item.location_id === currentLocationId
			);
		} else {
			return items.filter((item) => item.station_id === currentStationId);
		}
	};

	const handlePatientStatusChange = (value: string) => {
		if (value !== "all_status") {
			setCurrentPatientStatus(value);

			setPatientsObject({
				[PatientStatus.Pending]: filterByStation(
					getQueryList.data.queue_order.filter(
						(item) =>
							item.status === value &&
							item.status === StatusOfPatient.Pending
					)
				),
				[PatientStatus.Upcoming]: filterByStation(
					getQueryList.data.queue_order.filter(
						(item) =>
							item.status === value &&
							item.status === StatusOfPatient.Upcoming
					)
				),
				[PatientStatus.NextInLine]: filterByStation(
					getQueryList.data.queue_order.filter(
						(item) =>
							item.status === value &&
							item.status === StatusOfPatient.NextInLine
					)
				),
				[PatientStatus.Serving]: filterByStation(
					getQueryList.data.queue_order.filter(
						(item) =>
							item.status === value &&
							item.status === StatusOfPatient.GettingService
					)
				),
				[PatientStatus.Schedule]: [],
			});
		} else {
			setCurrentPatientStatus("all_status");
			setPatientsObject({
				[PatientStatus.Pending]: filterByStation(
					getQueryList.data.queue_order.filter(
						(item) =>
							item.location_id === +currentLocationId &&
							item.status === StatusOfPatient.Pending
					)
				),
				[PatientStatus.Upcoming]: filterByStation(
					getQueryList.data.queue_order.filter(
						(item) =>
							item.location_id === +currentLocationId &&
							item.status === StatusOfPatient.Upcoming
					)
				),
				[PatientStatus.NextInLine]: filterByStation(
					getQueryList.data.queue_order.filter(
						(item) =>
							item.location_id === +currentLocationId &&
							item.status === StatusOfPatient.NextInLine
					)
				),
				[PatientStatus.Serving]: filterByStation(
					getQueryList.data.queue_order.filter(
						(item) =>
							item.location_id === +currentLocationId &&
							item.status === StatusOfPatient.GettingService
					)
				),
				[PatientStatus.Schedule]: [],
			});
		}
	};

	return {
		handlePatientStatusChange,
	};
};

export default useStatusSelection;
