import useCustomToast from "@components/CustomToast";
import {
	APIVersion1AddLinksIDOscar,
	APIVersion1AddOscarEMRUrl,
	APIVersion1DeleteOscarDetails,
	APIVersion1GetOscarDetails,
} from "@src/http/v1";
import {
	FirstTimeOscarAddLinksIDType,
	FirstTimeOscarType,
	OscarDetailsType,
} from "@src/types/settings/integrations/oscar";
import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";

// Get oscar emr url
export const AddOscarEMRUrl = (
	onSuccess: (data: unknown) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const customToast = useCustomToast();
	// const queryClient = useQueryClient();

	return useMutation<unknown, Error, FirstTimeOscarType>(
		APIVersion1AddOscarEMRUrl,
		{
			onSuccess: (data) => {
				onSuccess(data);
				setTimeout(() => {
					customToast("Oscar url added successfully", {
						id: "oscar-emr-url",
						type: "success",
					});
				}, 750);
			},
			onError: (error) => {
				onError(error);
				customToast("Oscar url could not be added", {
					id: "oscar-emr-url",
					type: "error",
				});

				// console.error(error);
			},
		}
	);
};

// Get oscar client secret and client id
export const AddLinksIDOscar = (
	onSuccess: (data: { data: OscarDetailsType }) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const customToast = useCustomToast();
	// const queryClient = useQueryClient();

	return useMutation<
		{ data: OscarDetailsType },
		Error,
		FirstTimeOscarAddLinksIDType
	>(APIVersion1AddLinksIDOscar, {
		onSuccess: (data) => {
			onSuccess(data);
			setTimeout(() => {
				customToast("Oscar client data added successfully", {
					id: "oscar-emr-url",
					type: "success",
				});
			}, 750);
		},
		onError: (error) => {
			onError(error);
			customToast("Oscar client data could not be added", {
				id: "oscar-emr-url",
				type: "error",
			});

			// console.error(error);
		},
	});
};

// Delete Oscar details
export const DeleteOscarDetails = (
	onSuccess: (data: OscarDetailsType) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const customToast = useCustomToast();

	return useMutation<OscarDetailsType, Error>(APIVersion1DeleteOscarDetails, {
		onSuccess: (data) => {
			onSuccess(data);
			setTimeout(() => {
				customToast("Oscar emr removed successfully", {
					id: "oscar-emr-url",
					type: "success",
				});
			}, 750);
		},
		onError: (error) => {
			onError(error);
			customToast("Oscar emr could not be removed", {
				id: "oscar-emr-url",
				type: "error",
			});

			// console.error(error);
		},
	});
};

// Get oscar details
export const GetOscarDetails = (
	onSuccess: (data: OscarDetailsType) => void = () => {
		return;
	},
	onError: (error: AxiosError<{ detail: string }>) => void = () => {
		return;
	}
) => {
	// const customToast = useCustomToast();
	// const queryClient = useQueryClient();

	return useQuery<OscarDetailsType, AxiosError<{ detail: string }>>(
		"integration-oscar-details",
		APIVersion1GetOscarDetails,
		{
			onSuccess: (data) => {
				onSuccess(data);
			},
			onError: (error) => {
				onError(error);
				// customToast("Oscar details could not be gotten", {
				// 	id: "oscar-emr-url",
				// 	type: "error",
				// });

				// console.error(error);
			},
			retry: 1,
			refetchOnMount: false,
			refetchOnReconnect: false,
			refetchOnWindowFocus: false,
			staleTime: 0,
			cacheTime: 0,
		}
	);
};
