import {
	createJoinWaitlistSchema,
	customIntakesFormat,
	reverseCustomIntakesFormat,
} from "@/src/components/Dashboard/CustomIntakeField";
import MessagesTab from "@/src/components/Dashboard/waitlist/PatientInformation/MessagesTab";
import { TabButton } from "@/src/components/Dashboard/waitlist/PatientInformation/PatientInformation";
import { LoaderButton } from "@/src/components/form/LoaderButton";
import Loader from "@/src/components/Loader/Loader";
import { useGetBusinessLocationsAndStations } from "@/src/store/slices/locations/locationSlice";
import {
	UpdatePatientSlice,
	useGetPatientInfo,
} from "@/src/store/slices/waitlist/managePatientSlice";
import { ServingQueueResponse } from "@/src/types/serving/serving";
import { getDurationFromInHourMinute } from "@/src/utils/date";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@src/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@src/components/ui/dialog";
import { Input } from "@src/components/ui/input";
import { Label } from "@src/components/ui/label";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "@src/components/ui/select";
import {
	SinglePatientQueueData,
	SinglePatientQueueResponse,
} from "@src/types/waitlist/waitlist";
import { countryCodes } from "@src/utils/constants";
import { cn, toTitleCase } from "@src/utils/functions";
import { formatDistance } from "date-fns";
import _ from "lodash";
import React, { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { LuX } from "react-icons/lu";
import { useQueryClient } from "react-query";
import { z } from "zod";
import ActivityTab from "@/src/components/Dashboard/waitlist/PatientInformation/ActivityTab";
import ServingIntakeFieldsTab from "./ServingIntakeFieldsTab";

const PatientInformation: React.FC<{
	patientDetails?: SinglePatientQueueData;
	showPatientInformation: boolean;
	setShowPatientInformation: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
	patientDetails,
	showPatientInformation,
	setShowPatientInformation,
}) => {
	const queryClient = useQueryClient();
	const [currentTab, setCurrentTab] = React.useState<
		"intake" | "message" | "activity"
	>("intake");
	const [isEditing, setIsEditing] = React.useState(false);
	const [schema, setSchema] = React.useState(createJoinWaitlistSchema([]));
	const [firstName, setFirstName] = React.useState(
		patientDetails?.full_name.split(" ").slice(1).join(" ")
	);
	const [lastName, setLastName] = React.useState(
		patientDetails?.full_name.split(" ")[0]
	);
	const getBusinessLocationsAndStationsQuery =
		useGetBusinessLocationsAndStations();

	const updatePatientInformationMutation = UpdatePatientSlice((data) => {
		setIsEditing(!isEditing);

		//To Update the patient's individual data
		queryClient.setQueryData<SinglePatientQueueResponse | undefined>(
			["get-customer-info", patientDetails?.id],
			(prevData): SinglePatientQueueResponse | undefined => {
				if (!prevData?.data && !data.data) return prevData;

				return data;
			}
		);

		//To Update the serving array
		queryClient.setQueryData<ServingQueueResponse | undefined>(
			["get-serving", patientDetails?.waitlist?.location_id],
			(prevData) => {
				if (!prevData) return;
				const updatedData = prevData.data.map((patient) =>
					patient.id === data.data.id
						? { ...patient, ...data.data }
						: patient
				);

				return {
					...prevData,
					data: updatedData,
				};
			}
		);
	});

	const [countryCode, setCountryCode] = React.useState(
		patientDetails?.phone_number.slice(0, -10)
			? patientDetails?.phone_number.slice(0, -10)
			: "+1"
	);

	const getPatieintInfoQuery = useGetPatientInfo(
		undefined,
		patientDetails?.id,
		!!patientDetails?.id
	);

	const [initialValues, setInitialValues] = React.useState<
		z.infer<typeof schema>
	>({
		full_name: "",
		phone_number: "",
		email: "",
		custom_intakes: undefined,
		station_id: 0,
	});

	const {
		register,
		handleSubmit,
		reset,
		control,
		watch,
		setValue,
		formState: { errors },
	} = useForm<z.infer<typeof schema>>({
		resolver: zodResolver(schema),
		defaultValues: initialValues,
	});

	const onSubmit: SubmitHandler<z.infer<typeof schema>> = async (data) => {
		const formattedData = {
			full_name: (data.full_name + " " + firstName).trim(),
			phone_number: countryCode + data.phone_number.slice(-10),
			email: data.email,
			custom_intakes: customIntakesFormat(
				schema,
				getBusinessLocationsAndStationsQuery.data?.data.locations
					.find(
						(location) =>
							location.id == patientDetails?.waitlist?.location_id
					)
					?.stations.find(
						(station) =>
							station.id == patientDetails?.waitlist?.station_id
					)?.waitlist_custom_fields,
				data.custom_intakes
			),
		};
		// Compare the fields using Lodash's isEqual and pick only the changed fields
		const changedData = _.pickBy(formattedData, (value, key) => {
			return !_.isEqual(value, initialValues[key]);
		});

		// If there are changes, submit the changed fields
		if (Object.keys(changedData).length > 0) {
			changedData.customer_id = patientDetails?.id;

			updatePatientInformationMutation.mutate(changedData);
		}
	};

	React.useEffect(() => {
		if (!patientDetails || !showPatientInformation) return;
		if (
			getBusinessLocationsAndStationsQuery.data?.data.locations &&
			getBusinessLocationsAndStationsQuery.data?.data.locations
				.find(
					(location) =>
						location.id === patientDetails?.waitlist?.location_id
				)
				?.stations.find(
					(station) =>
						station.id.toString() ===
						patientDetails?.waitlist?.station_id.toString()
				)?.waitlist_custom_fields
		) {
			const newSchema = createJoinWaitlistSchema(
				(getBusinessLocationsAndStationsQuery.data?.data.locations &&
					getBusinessLocationsAndStationsQuery.data?.data.locations
						.find(
							(location) =>
								location.id ===
								patientDetails?.waitlist?.location_id
						)
						?.stations.find(
							(station) =>
								station.id.toString() ===
								patientDetails?.waitlist?.station_id?.toString()
						)?.waitlist_custom_fields) ??
					[]
			);
			setSchema(newSchema);

			const reversedIntakes = reverseCustomIntakesFormat(
				schema,
				getPatieintInfoQuery?.data?.data.custom_intakes
			);

			setInitialValues({
				full_name: getPatieintInfoQuery?.data?.data?.full_name ?? "",
				phone_number:
					getPatieintInfoQuery?.data?.data?.phone_number ?? "",
				email: getPatieintInfoQuery?.data?.data.email,
				custom_intakes: reversedIntakes,
				station_id: patientDetails?.waitlist?.station_id ?? 0,
			});

			setLastName(
				getPatieintInfoQuery?.data?.data.full_name.split(" ")[0]
			);

			setFirstName(
				getPatieintInfoQuery?.data?.data.full_name
					.split(" ")
					.slice(1)
					.join(" ")
			);

			setCountryCode(
				getPatieintInfoQuery?.data?.data.phone_number
					? getPatieintInfoQuery?.data?.data.phone_number.slice(
							0,
							-10
						)
					: "+1"
			);

			reset({
				full_name:
					getPatieintInfoQuery?.data?.data?.full_name.split(" ")[0] ??
					"",
				phone_number:
					getPatieintInfoQuery?.data?.data?.phone_number ?? "",
				email: getPatieintInfoQuery?.data?.data.email,
				custom_intakes: reversedIntakes,
			});
		}
	}, [
		getBusinessLocationsAndStationsQuery.data,
		patientDetails,
		getPatieintInfoQuery.data,
		showPatientInformation,
	]);

	useEffect(() => {
		// if (showPatientInformation) getPatieintInfoQuery.refetch();
	}, [showPatientInformation]);

	if (!patientDetails || !showPatientInformation) return;
	// console.log(getPatieintInfoQuery.data?.data.custom_intakes)
	return (
		<>
			<Dialog
				open={showPatientInformation}
				onOpenChange={(newValue) => {
					if (!newValue) {
						setIsEditing(false);
						reset();
					}
					setShowPatientInformation(newValue);
				}}
			>
				<DialogContent className="max-w-[860px]">
					<form onSubmit={handleSubmit(onSubmit)}>
						<DialogTitle>
							<DialogHeader>
								<div className="flex items-center justify-between">
									<div className="flex flex-1 items-center space-x-3">
										{isEditing ? (
											<>
												<div className="max-w-[215px] flex-1 space-y-1.5">
													<Label className="font-normal text-[#858C95]">
														Last Name
													</Label>
													{/* {watch("full_name")} */}
													<Input
														className="flex-1"
														value={lastName}
														onChange={(e) => {
															setLastName(
																e.target.value
															);
															setValue(
																"full_name",
																e.target.value
															);
														}}
													/>
													{errors.full_name
														?.message && (
														<small className="text-sm text-destructive">
															{
																errors.full_name
																	?.message as string
															}
														</small>
													)}
												</div>
												<div className="max-w-[215px] flex-1 space-y-1.5">
													<Label className="font-normal text-[#858C95]">
														First Name
													</Label>
													<Input
														className="flex-1"
														value={firstName}
														onChange={(e) => {
															setFirstName(
																e.target.value
															);
														}}
													/>
												</div>
											</>
										) : (
											<>
												<DialogTitle className="text-[22px] font-medium capitalize leading-[30px] -tracking-[1%] text-main-1">
													{getPatieintInfoQuery.data
														?.data.full_name ??
														patientDetails?.full_name}
												</DialogTitle>
												<Button
													className="flex h-[18px] w-[22px] items-center justify-center rounded-[4px] bg-[#F6E2E1] p-0 hover:bg-[#F6E2E1]"
													type="button"
												>
													<i
														className={`mgc_tag_fill text-[14px] before:!text-[#C8322B]`}
													/>
												</Button>
											</>
										)}
									</div>
									<button
										type="button"
										onClick={() => {
											setIsEditing(false);
											setShowPatientInformation(false);
										}}
									>
										<LuX
											color="#858C95"
											className="cursor-pointer"
											size={20}
										/>
									</button>
								</div>
							</DialogHeader>
						</DialogTitle>
						<ul className="mt-3 grid grid-cols-[min(215px)_auto] gap-3 border-y-2 border-primary py-3">
							{isEditing ? (
								<>
									<div className="max-w-[215px]">
										<Label className="font-normal text-[#858C95]">
											Phone Number
										</Label>
										<div className="flex">
											<Select
												value={countryCode}
												onValueChange={(value) => {
													setCountryCode(value);
												}}
											>
												<SelectTrigger className="w-fit rounded-r-none border-r-transparent">
													<SelectValue
														className="text-[#858C95]"
														placeholder="+1"
													/>
												</SelectTrigger>
												<SelectContent className="!z-[9999]">
													<SelectGroup>
														<SelectLabel className="px-2">
															Country Codes
														</SelectLabel>

														{countryCodes.map(
															(option) => {
																return (
																	<SelectItem
																		key={
																			option.value
																		}
																		value={
																			option.value
																		}
																		className="px-8"
																	>
																		{
																			option.label
																		}
																	</SelectItem>
																);
															}
														)}
													</SelectGroup>
												</SelectContent>
											</Select>
											<Input
												className="rounded-l-none border border-[#E4E4E7]"
												minLength={10}
												maxLength={10}
												defaultValue={watch(
													"phone_number"
												).slice(-10)}
												onChange={(e) => {
													setValue(
														"phone_number",
														e.target.value
													);
												}}
											/>
										</div>
										{errors.phone_number?.message && (
											<small className="text-sm text-destructive">
												{
													errors.phone_number
														?.message as string
												}
											</small>
										)}
									</div>
									{patientDetails?.email && (
										<div className="max-w-[215px]">
											<Label className="font-normal text-[#858C95]">
												Email Address
											</Label>
											<Input
												{...register("email")}
												// className="max-w-[215px]"
											/>
											{errors.email?.message && (
												<small className="text-sm text-destructive">
													{
														errors.email
															?.message as string
													}
												</small>
											)}
										</div>
									)}
								</>
							) : (
								<>
									<MainPatientInfo
										title={"Phone Number"}
										description={
											getPatieintInfoQuery.data?.data
												.phone_number ??
											patientDetails.phone_number
										}
									/>
									{patientDetails?.email && (
										<MainPatientInfo
											title={"Email Address"}
											description={
												getPatieintInfoQuery.data?.data
													.email ??
												patientDetails.email
											}
										/>
									)}

									<MainPatientInfo
										title={"Created"}
										description={toTitleCase(
											formatDistance(
												new Date(
													patientDetails.created_at
												),
												new Date(),
												{ addSuffix: true }
											)
										)}
									/>

									{patientDetails?.waitlist?.joined_at && (
										<MainPatientInfo
											title={"Joined"}
											description={toTitleCase(
												formatDistance(
													new Date(
														patientDetails?.waitlist
															?.joined_at ??
															new Date()
													),
													new Date(),
													{ addSuffix: true }
												)
											)}
										/>
									)}
									{patientDetails.waitlist
										?.service_start_at && (
										<MainPatientInfo
											title={"Service Start At"}
											description={getDurationFromInHourMinute(
												patientDetails.waitlist
													?.service_start_at ?? ""
											)}
										/>
									)}
								</>
							)}
						</ul>

						<section
							className={cn(
								"mt-4 flex flex-1 flex-col items-stretch pb-5",
								{
									"border-b-2 border-b-primary":
										getPatieintInfoQuery.isSuccess,
								}
							)}
						>
							{getPatieintInfoQuery.isLoading ? (
								<div className="py-10">
									<Loader size={24} />
								</div>
							) : (
								<>
									<div className="flex">
										{getPatieintInfoQuery.data?.data
											.custom_intakes && (
											<TabButton
												label="Intake Fields"
												isActive={
													currentTab === "intake"
												}
												onClick={() =>
													setCurrentTab("intake")
												}
											/>
										)}

										<TabButton
											label="Message"
											isActive={currentTab === "message"}
											onClick={() =>
												setCurrentTab("message")
											}
										/>
										<TabButton
											label="Activity"
											isActive={currentTab === "activity"}
											onClick={() =>
												setCurrentTab("activity")
											}
										/>
									</div>

									<div className="flex flex-1 flex-col items-stretch pt-4">
										{currentTab === "intake" &&
											getPatieintInfoQuery.data?.data
												.custom_intakes && (
												<ServingIntakeFieldsTab
													isEditing={isEditing}
													patientDetails={
														patientDetails
													}
													register={register}
													errors={errors}
													control={control}
												/>
											)}
										{currentTab === "message" && (
											<MessagesTab />
										)}
										{currentTab === "activity" && (
											<ActivityTab
												customer_id={patientDetails.id}
											/>
										)}
									</div>
								</>
							)}
						</section>
						{getPatieintInfoQuery.isSuccess && (
							<DialogFooter className="mt-3 flex w-full !flex-row-reverse items-center !justify-start gap-x-4">
								{isEditing ? (
									<>
										<LoaderButton
											className="max-w-[150px] flex-1 bg-primary text-white"
											type="submit"
											loaderSize={20}
											loading={
												updatePatientInformationMutation.isLoading
											}
										>
											Save
										</LoaderButton>
										<Button
											className="max-w-[150px] flex-1"
											onClick={() => {
												reset({
													full_name:
														getPatieintInfoQuery
															?.data?.data
															?.full_name ?? "",
													phone_number:
														getPatieintInfoQuery
															?.data?.data
															?.phone_number ??
														"",
													email: getPatieintInfoQuery
														?.data?.data.email,
													custom_intakes:
														reverseCustomIntakesFormat(
															schema,
															getPatieintInfoQuery
																?.data?.data
																.custom_intakes
														),
												});
												setIsEditing(!isEditing);
											}}
											variant="outline-primary"
											type="button"
										>
											Cancel
										</Button>
									</>
								) : (
									<>
										<Button
											className="max-w-[150px] flex-1 bg-primary text-white"
											type="button"
											onClick={(e) => {
												e.preventDefault();
												setIsEditing(!isEditing);
											}}
										>
											Edit
										</Button>
									</>
								)}
							</DialogFooter>
						)}
					</form>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default PatientInformation;

const MainPatientInfo: React.FC<{
	title: string;
	description: string;
}> = ({ title, description }) => {
	return (
		<li className="flex flex-col">
			<p className="tracking-[-1%] text-[#858C95]">{title}</p>
			<p className="tracking-[-1%] text-[#323539]">{description}</p>
		</li>
	);
};
