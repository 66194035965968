import { zodResolver } from "@hookform/resolvers/zod";
import useCustomToast from "@src/components/CustomToast";
import { LoaderButton } from "@src/components/form/LoaderButton";
import Loader from "@src/components/Loader/Loader";
import {
	changeCountry,
	updateCountryAndState,
} from "@src/components/Onboarding/AboutBusiness";
import { SearchableSelect } from "@src/components/ui-extended/searchable-select";
import { Button } from "@src/components/ui/button";
import { Input } from "@src/components/ui/input";
import { Label } from "@src/components/ui/label";
import { ScrollArea } from "@src/components/ui/scroll-area";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "@src/components/ui/select";
import { TabsContent } from "@src/components/ui/tabs";
import {
	findCountryByLabel,
	findProvinceByLabel,
	useAddressAutocomplete,
} from "@src/hooks/useAddressAutoComplete";
import { useGetUserInformation } from "@src/hooks/useAuthData";
import {
	UpdateBusinessLogoSettings,
	UpdateBusinessSettings,
	useGetBusinessCategories,
} from "@src/store/slices/settings/businessSettingsSlice";
import useUserStore from "@src/store/useUserStore";
import {
	UpdateBusinessSettingsSchema,
	UpdateBusinessSettingsType,
} from "@src/types/settings/business-account";
import {
	allowedUploadFormats,
	countryCodes,
	countryOptions,
	findCountry,
	findState,
} from "@src/utils/constants";
import { cn, handleFileChange, objectToFormData } from "@src/utils/functions";
import { isEqual } from "lodash";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";

const BusinessSettings: React.FC = () => {
	const user = useUserStore((s) => s.user);
	const getBusinessCategoriesQuery = useGetBusinessCategories();

	const initialFormState = {
		name: user?.business.name ?? "",
		email: user?.email ?? "",
		phone_number: user?.business?.phone_number
			? user?.business?.phone_number?.slice(-10)
			: "",
		address: user?.business?.address ?? "",
		city: user?.business.city ?? "",
		zip_code: user?.business.zip_code ?? "",
		business_category_id: user?.business?.business_category_id
			? user?.business?.business_category_id.toString()
			: "0",
	};

	const {
		register,
		handleSubmit,
		setValue,
		setError,
		reset,
		watch,
		getValues,
		formState: { errors },
	} = useForm<UpdateBusinessSettingsType>({
		resolver: zodResolver(UpdateBusinessSettingsSchema),
		defaultValues: initialFormState,
	});

	const logoRef: any | null = React.useRef(null);
	const customToast = useCustomToast();

	const [provinceOptions, setProvinceOptions] = React.useState<
		{
			label: string;
			value: string;
		}[]
	>([]);
	const [logoUrl, setLogoUrl] = React.useState<string | null>(null);
	const [countryCode, setCountryCode] = React.useState(
		user?.business?.phone_number
			? user?.business?.phone_number.slice(0, -10)
			: "+1"
	);
	const [isEditing, setIsEditing] = React.useState(false);
	const [isDisabled, setIsDisabled] = React.useState(true);

	const addressAutocomplete = useAddressAutocomplete(
		(address, city, province, country, postalCode) => {
			setValue("address", address);
			setValue("city", city);
			setValue("state", province);
			setValue("country", country);
			setValue("zip_code", postalCode);

			// console.log({ address, city, province, country, postalCode });

			const countryValue = findCountryByLabel(country);

			const newProvinceOptions = changeCountry(countryValue);
			setProvinceOptions(newProvinceOptions);

			const newProvince = findProvinceByLabel(
				newProvinceOptions,
				province
			);
			updateCountryAndState(
				setValue,
				setProvinceOptions,
				true,
				newProvince,
				countryValue
			);
		}
	);

	const updateBusinessAccountSettingsMutation = UpdateBusinessSettings(() => {
		setIsEditing(false);
		getUserInformationMutation.mutate({});
	});

	const updateBusinessLogoSettingsMutation = UpdateBusinessLogoSettings(
		() => {
			setIsEditing(false);
		}
	);

	const getUserInformationMutation = useGetUserInformation(() => {
		reset();
	});

	const onSubmit: SubmitHandler<UpdateBusinessSettingsType> = async (
		data
	) => {
		try {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { logo, ...formDataWithoutLogo } = data;

			const updatedCountry = findCountry(data.country || "");

			const updatedState = findState(
				data.state || "",
				data.country || ""
			);

			updateBusinessAccountSettingsMutation.mutate({
				...formDataWithoutLogo,
				country: updatedCountry,
				state: updatedState,
				phone_number: data.phone_number
					? (countryCode + data.phone_number.slice(-10)).trim()
					: undefined,
			});
		} catch (error) {
			setError("root", {
				message: "An error occured kindly try again later",
			});
		}
	};

	const onSubmitBusinessLogoChange = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const file = handleFileChange(event, allowedUploadFormats, customToast);
		try {
			setLogoUrl(URL.createObjectURL(file[0]));
			setIsEditing(false);
			updateBusinessLogoSettingsMutation.mutate(
				objectToFormData({
					logo: file[0],
				})
			);
		} catch (error) {
			setError("root", {
				message: "An error occured kindly try again later",
			});
		}
	};

	const setCountryAndProvince = () => {
		const countryValue = findCountryByLabel(user?.business.country ?? "");
		const newProvinceOptions = changeCountry(countryValue);

		const newProvince = findProvinceByLabel(
			newProvinceOptions,
			user?.business.state ?? ""
		);

		updateCountryAndState(
			setValue,
			setProvinceOptions,
			true,
			newProvince,
			countryValue
		);
	};

	React.useEffect(() => {
		const subscription = watch(() => {
			setIsDisabled(isEqual(getValues(), initialFormState));
		});

		return () => subscription.unsubscribe();
	}, [watch, initialFormState]);

	React.useLayoutEffect(() => {
		setCountryAndProvince();
	}, []);

	// console.log(errors, getValues());

	return (
		<TabsContent
			value="business-setting"
			className="mt-0 flex w-full flex-col data-[state=inactive]:hidden data-[state=active]:flex-1"
		>
			{!getBusinessCategoriesQuery.isLoading ? (
				<ScrollArea className="w-full">
					<section className="flex h-[80dvh] max-w-[540px] flex-col space-y-6">
						<h2 className="text-[22px] font-semibold leading-[24px] tracking-[-1%] text-[#323539]">
							Organisation Information
						</h2>
						<form className="flex space-x-6">
							<figure className="grid size-[56px] place-content-center overflow-hidden rounded-full border border-[#E5E5E7] bg-[#F8F9FB]">
								{user?.business.logo_url &&
								(logoUrl || user?.business.logo_url) ? (
									<img
										src={
											(logoUrl ||
												user?.business.logo_url) ??
											""
										}
										alt="Logo"
										className="h-full w-full rounded-[20px] object-scale-down"
									/>
								) : (
									<img
										src="/assets/icons/no-logo.svg"
										alt=""
									/>
								)}
							</figure>

							<div className="flex flex-col space-y-3">
								<div>
									<h3 className="text-[18px] font-semibold leading-[28px] tracking-[-1%] text-[#323539]">
										Organization Logo
									</h3>
									<p className="text-[15px] leading-[22px] text-[#858C95]">
										Upload photos in JPG, or PNG format.
										<br />
										Minimum resolution: 800x600 pixels. Max
										file size: 2 MB.
									</p>
								</div>
								{watch("logo") && (
									<p className="text-sm">
										{watch("logo")?.name}
									</p>
								)}

								<LoaderButton
									variant="outline"
									className="h-fit w-fit px-4 py-2.5 text-[#323539]"
									disabled={
										!isEditing ||
										updateBusinessLogoSettingsMutation.isLoading
									}
									loading={
										updateBusinessLogoSettingsMutation.isLoading
									}
									onClick={(e) => {
										logoRef.current.click();
										e.preventDefault();
									}}
									loaderSize={20}
								>
									Change Logo
								</LoaderButton>
								<input
									type="file"
									ref={logoRef}
									onChange={onSubmitBusinessLogoChange}
									accept={allowedUploadFormats.join(",")}
									hidden
								/>
							</div>
						</form>
						<form
							className="mt-3 grid grid-cols-2 gap-4"
							onSubmit={handleSubmit(onSubmit)}
						>
							<div className="flex flex-col space-y-1.5">
								<Label>
									Business Name{" "}
									<span className="text-red-500">*</span>
								</Label>
								<Input
									className="border border-[#E4E4E7] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
									{...register("name")}
									disabled={!isEditing}
								/>
								{errors.name &&
									errors?.name?.message?.length && (
										<small className="mt-1.5 text-sm leading-[16px] text-red-500">
											{errors.name.message}
										</small>
									)}
							</div>
							<div className="flex flex-col space-y-1.5">
								<Label>Type</Label>
								<Select
									value={watch("business_category_id")}
									onValueChange={(value) =>
										setValue("business_category_id", value)
									}
									disabled={!isEditing}
								>
									<SelectTrigger className="shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]">
										<SelectValue
											className="text-[#858C95]"
											placeholder="Business Category"
										/>
									</SelectTrigger>
									<SelectContent className="!z-[9999]">
										<SelectGroup>
											<SelectLabel className="px-2">
												Business Category
											</SelectLabel>

											{getBusinessCategoriesQuery?.data &&
												getBusinessCategoriesQuery?.data?.data.map(
													(option) => {
														return (
															<SelectItem
																key={option.id}
																value={option.id.toString()}
																className="px-8"
															>
																{option.name}
															</SelectItem>
														);
													}
												)}
										</SelectGroup>
									</SelectContent>
								</Select>
							</div>
							<div className="col-span-2 flex flex-col space-y-1.5">
								<div>
									<Label>
										Email Address{" "}
										<span className="text-red-500">*</span>
									</Label>
									<small className="block text-[14px] leading-[16px] text-[#71717A]">
										Once you&apos;ve updated the email, we
										will send you a new verification link
									</small>
								</div>
								<Input
									className="border border-[#E4E4E7] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
									{...register("email")}
									disabled={!isEditing}
								/>
								{errors.email &&
									errors?.email?.message?.length && (
										<small className="mt-1.5 text-sm leading-[16px] text-red-500">
											{errors.email.message}
										</small>
									)}
							</div>
							<div className="col-span-2 flex flex-col space-y-1.5">
								<Label>Phone Number</Label>
								<div className="flex">
									<Select
										value={countryCode}
										onValueChange={(value) => {
											setCountryCode(value);
										}}
										disabled={!isEditing}
									>
										<SelectTrigger className="w-fit rounded-r-none border-r-transparent shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]">
											<SelectValue
												className="text-[#858C95]"
												placeholder="+1"
											/>
										</SelectTrigger>
										<SelectContent className="!z-[9999]">
											<SelectGroup>
												<SelectLabel className="px-2">
													Country Codes
												</SelectLabel>

												{countryCodes.map((option) => {
													return (
														<SelectItem
															key={option.value}
															value={option.value}
															className="px-8"
														>
															{option.label}
														</SelectItem>
													);
												})}
											</SelectGroup>
										</SelectContent>
									</Select>
									<Input
										className="rounded-l-none border border-[#E4E4E7] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
										disabled={!isEditing}
										minLength={11}
										maxLength={11}
										{...register("phone_number", {
											minLength: 10,
											maxLength: 11,
										})}
									/>
								</div>
								{errors.phone_number &&
									errors?.phone_number?.message?.length && (
										<small className="mt-1.5 text-sm leading-[16px] text-red-500">
											{errors.phone_number.message}
										</small>
									)}
							</div>
							<div className="col-span-2 flex flex-col space-y-1.5">
								<Label>
									Address Line{" "}
									<span className="text-red-500">*</span>
								</Label>

								<SearchableSelect
									options={addressAutocomplete.suggestions.map(
										(suggestion) => ({
											value: suggestion.description,
											label: suggestion.description,
										})
									)}
									value={{
										label: watch("address"),
										value: watch("address"),
									}}
									onTypingChange={
										addressAutocomplete.setValue
									}
									onValueChange={(option) => {
										addressAutocomplete.handleSelectSuggestion(
											option.label
										);
									}}
									isLoading={addressAutocomplete.isLoading}
									emptyMessage={
										!addressAutocomplete.suggestions
											.length &&
										addressAutocomplete.value.length &&
										!addressAutocomplete.isLoading
											? "No address found"
											: "Enter an address"
									}
									disabled={!isEditing}
								/>
								{errors.address &&
									errors?.address?.message?.length && (
										<small className="mt-1.5 text-sm leading-[16px] text-red-500">
											{errors.address.message}
										</small>
									)}
							</div>

							<div className="flex flex-col space-y-1.5">
								<Label>
									Country{" "}
									<span className="text-red-500">*</span>
								</Label>

								<Select
									disabled={!isEditing}
									value={watch("country")}
									onValueChange={(value) => {
										updateCountryAndState(
											setValue,
											setProvinceOptions,
											false,
											undefined,
											value
										);
									}}
								>
									<SelectTrigger className="shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]">
										<SelectValue
											className="text-[#858C95]"
											placeholder="+1"
										/>
									</SelectTrigger>
									<SelectContent className="!z-[9999]">
										<SelectGroup>
											<SelectLabel className="px-2">
												Province
											</SelectLabel>

											{countryOptions.map((option) => {
												return (
													<SelectItem
														key={option.value}
														value={option.value}
														className="px-8"
													>
														{option.label}
													</SelectItem>
												);
											})}
										</SelectGroup>
									</SelectContent>
								</Select>
								{errors.country &&
									errors?.country?.message?.length && (
										<small className="mt-1.5 text-sm leading-[16px] text-red-500">
											{errors.country.message}
										</small>
									)}
							</div>
							<div className="flex flex-col space-y-1.5">
								<Label>
									State{" "}
									<span className="text-red-500">*</span>
								</Label>
								<Select
									disabled={!isEditing}
									value={watch("state")}
									onValueChange={(value) => {
										updateCountryAndState(
											setValue,
											setProvinceOptions,
											false,
											value,
											getValues("country")
										);
									}}
								>
									<SelectTrigger className="shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]">
										<SelectValue
											className="text-[#858C95]"
											placeholder="+1"
										/>
									</SelectTrigger>
									<SelectContent className="!z-[9999]">
										<SelectGroup>
											<SelectLabel className="px-2">
												Province
											</SelectLabel>

											{provinceOptions.map((option) => {
												return (
													<SelectItem
														key={option.value}
														value={option.value}
														className="px-8"
													>
														{option.label}
													</SelectItem>
												);
											})}
										</SelectGroup>
									</SelectContent>
								</Select>
								{errors.state &&
									errors?.state?.message?.length && (
										<small className="mt-1.5 text-sm leading-[16px] text-red-500">
											{errors.state.message}
										</small>
									)}
							</div>
							<div className="flex flex-col space-y-1.5">
								<Label>
									City <span className="text-red-500">*</span>
								</Label>
								<Input
									className="border border-[#E4E4E7] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
									{...register("city")}
									disabled={!isEditing}
								/>
								{errors.city &&
									errors?.city?.message?.length && (
										<small className="mt-1.5 text-sm leading-[16px] text-red-500">
											{errors.city.message}
										</small>
									)}
							</div>
							<div className="flex flex-col space-y-1.5">
								<Label>
									Zip Code{" "}
									<span className="text-red-500">*</span>
								</Label>
								<Input
									className="border border-[#E4E4E7] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
									{...register("zip_code")}
									disabled={!isEditing}
								/>
								{errors["zip_code"] &&
									errors["zip_code"]?.message?.length && (
										<small className="mt-1.5 text-sm leading-[16px] text-red-500">
											{errors["zip_code"].message}
										</small>
									)}
							</div>

							<div className="col-span-2 ml-auto space-x-4">
								{isEditing ? (
									<>
										<Button
											variant="outline"
											className="shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
											type="button"
											disabled={
												updateBusinessAccountSettingsMutation.isLoading
											}
											onClick={(e) => {
												e.preventDefault();
												setIsEditing(false);
												reset();
												setCountryAndProvince();
												setCountryCode(
													user?.business?.phone_number?.slice(
														0,
														-10
													) ?? "+1"
												);
											}}
										>
											Cancel
										</Button>
										<LoaderButton
											className={cn("text-white", {
												"text-[#A1A1AA]": isDisabled,
											})}
											disabled={
												isDisabled ||
												updateBusinessAccountSettingsMutation.isLoading
											}
											loading={
												updateBusinessAccountSettingsMutation.isLoading
											}
											variant={
												isDisabled
													? "secondary"
													: "default"
											}
											loaderSize={20}
											type="submit"
										>
											Save
										</LoaderButton>
									</>
								) : (
									<Button
										className="text-white"
										type="button"
										onClick={(e) => {
											e.preventDefault();
											setIsEditing(true);
										}}
									>
										Edit
									</Button>
								)}
							</div>
						</form>
					</section>
				</ScrollArea>
			) : (
				<div className="grid h-full place-content-center">
					<Loader size={20} />
				</div>
			)}
		</TabsContent>
	);
};

export default BusinessSettings;
