import UpdateWaitlistSlice from "@/src/store/slices/waitlist/updateWaitlistSlice";
import CustomCheckbox2 from "@components/form/CustomCheckbox2";
import { Button } from "@components/ui/button";
import { TooltipProvider } from "@components/ui/tooltip";
import { UniqueIdentifier, useDroppable } from "@dnd-kit/core";
import {
	SortableContext,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Switch } from "@src/components/ui/switch";
import {
	QueueEntry,
	UpdateWaitlistEnum,
	UpdateWaitlistEnumType,
} from "@src/types/waitlist/waitlist";
import { cn, handleSelectAllChange } from "@utils/functions";
import React, { useState } from "react";
import fileIcon from "../../../../public/assets/images/file.svg";
import WaitlistCard from "../../../pages/waitlist/components/WaitlistViews/WaitlistColumnCard";
import useWaitListStore from "../../../store/useWaitListStore";
import { PatientStatus } from "../../../types/wait-list";
import { ScrollArea } from "../../ui/scroll-area";
import BulkUpdateModal from "./BulkUpdateModal";
import NotifyAllPatients from "./NotifyAllPatients";
import StatusCard from "./StatusCard";

const WaitlistColumn: React.FC<{
	status: PatientStatus;
	statuses: PatientStatus[];
	activeId?: UniqueIdentifier;
	setActiveId: any;
	setShowPatientInformation: React.Dispatch<React.SetStateAction<boolean>>;
	setShowPatientInformationState?: React.Dispatch<
		React.SetStateAction<"intake" | "message" | "activity" | undefined>
	>;
	setShowDeleteCustomerModal: React.Dispatch<React.SetStateAction<boolean>>;
	setModalPatientInformation?: React.Dispatch<
		React.SetStateAction<QueueEntry | undefined>
	>;
	setShowChangePatientStationModal: React.Dispatch<
		React.SetStateAction<boolean>
	>;
	setShowPriorityModal: React.Dispatch<React.SetStateAction<boolean>>;
	setShowChangePositionOrderModal: React.Dispatch<
		React.SetStateAction<boolean>
	>;
}> = ({
	status,
	statuses,
	activeId,
	setShowPatientInformation,
	setModalPatientInformation,
	setShowDeleteCustomerModal,
	setShowChangePatientStationModal,
	setShowPriorityModal,
	setShowChangePositionOrderModal,
	setShowPatientInformationState,
}) => {
	const [selectAllChecked, setSelectAllChecked] = useState(false);
	const [showBulkUpdateModal, setShowBulkUpdateModal] = React.useState(false);
	const [selectedRows, setSelectedRows] = useState<number[]>([]);
	const patientsObject = useWaitListStore((s) => s.patientsObject);
	const [bulkAction, setBulkAction] = React.useState<
		UpdateWaitlistEnumType | undefined
	>();
	const [showBulkNotifyPatientsModal, setShowBulkNotifyPatientsModal] =
		React.useState(false);

	const number: number = patientsObject[status].length;

	const { setNodeRef } = useDroppable({ id: status });
	const [isAutoServe, setIsAutoServe] = useState(false);

	const updateWaitlistMutation = UpdateWaitlistSlice(() => {
		setSelectAllChecked(false);
		setSelectedRows([]);
	});

	return (
		<>
			<SortableContext
				items={patientsObject[status]}
				strategy={verticalListSortingStrategy}
			>
				<div
					ref={setNodeRef}
					className={`flex w-full flex-1 flex-col overflow-hidden rounded-lg bg-[#FAFBFC] shadow-[0px_0px_16px_0px_rgba(0,0,0,0.02)] m1.5xl:min-w-[300px] ${statuses.length !== 4 && "min-w-[300px]"}`}
				>
					<div className="sticky top-0 z-10 rounded-t-lg">
						<StatusCard
							status={status}
							statusCardBg="bg-status-1"
							statusText={`Showing ${number} of ${number} `}
						>
							{status === PatientStatus.Serving && (
								<div className="">
									<p className="text-[14px] font-medium leading-[24px] tracking-[-0.12px]">
										Auto Serve
									</p>
									<div className="flex items-center space-x-1.5">
										<Switch
											id={"queue-on-of-" + status}
											className="scale-80 data-[state=checked]:bg-[#32D74B] data-[state=unchecked]:bg-[#E5E5E7]"
											onClick={() =>
												setIsAutoServe(!isAutoServe)
											}
										/>
										<p className="text-[14px] font-medium leading-[24px] tracking-[-0.12px]">
											{isAutoServe ? "On" : "Off"}
										</p>
									</div>
								</div>
							)}
						</StatusCard>
						<div className="flex items-center justify-between bg-[#FAFBFC] px-3">
							<div className="flex items-center gap-2  py-4">
								{/* <CustomCheckbox
							id={status + " selectAll"}
							isChecked={selectAllChecked}
							handleCheckboxChange={handleSelectAllChange}
							className=""
						/> */}
								<CustomCheckbox2
									handleCheckboxChange={() =>
										handleSelectAllChange(
											!selectAllChecked,
											setSelectedRows,
											setSelectAllChecked,
											(
												patientsObject[
													status
												] as QueueEntry[]
											).map((item) => item.id)
										)
									}
									isChecked={selectAllChecked}
									id={status + " selectAll"}
									className="rounded-sm border-2"
								/>
								<p className="text-sm leading-6 -tracking-[1%] text-[#7D7E80]">
									Select All
								</p>
							</div>

							<div
								className={cn(
									"flex space-x-2.5 duration-200 ease-in-out",
									{
										"opacity-90": selectedRows.length,
										"opacity-50": !selectedRows.length,
									}
								)}
							>
								{/* <Button
									className="before-text-gray-2 flex h-8 w-8 items-center justify-center rounded-[6px] border border-[#6B7280] bg-transparent duration-200"
									variant="outline"
									type="button"
									disabled={
										!selectedRows.length ||
										updateWaitlistMutation.isLoading
									}
									onClick={() => {
										setBulkAction(
											UpdateWaitlistEnum.NotifyTurn
										);
										setShowBulkUpdateModal(true);
									}}
								>
									<i className="mgc_chat_2_line before-text-gray-2 before-text-gray-2 text-[12px]" />
								</Button> */}

								<Button
									className="before-text-gray-2 flex h-8 w-8 items-center justify-center rounded-[6px] border border-[#6B7280] bg-transparent duration-200"
									size="icon-sm"
									variant="outline"
									disabled={
										!selectedRows.length ||
										updateWaitlistMutation.isLoading
									}
									onClick={() => {
										setShowBulkNotifyPatientsModal(
											!showBulkNotifyPatientsModal
										);
									}}
								>
									<i className="mgc_chat_2_line before-text-gray-2 before-text-gray-2 text-[12px]" />
								</Button>

								<Button
									className="before-text-gray-2 flex h-8 w-8 items-center justify-center rounded-[6px] border border-[#6B7280] bg-transparent duration-200"
									variant="outline"
									type="button"
									disabled={
										!selectedRows.length ||
										updateWaitlistMutation.isLoading
									}
									onClick={() => {
										setBulkAction(
											UpdateWaitlistEnum.CancelledByBusinessAdmin
										);
										setShowBulkUpdateModal(true);
									}}
								>
									<i className="mgc_delete_2_line before-text-gray-2 before-text-gray-2 text-[12px]" />
								</Button>
								{/* {(status === PatientStatus.Pending ||
									status === PatientStatus.Upcoming ||
									status === PatientStatus.NextInLine ||
									status === PatientStatus.Serving) && ( */}

								<Button
									className="before-text-gray-2 flex h-8 w-8 items-center justify-center rounded-[6px] border border-[#6B7280] bg-transparent duration-200"
									variant="outline"
									type="button"
									disabled={
										!selectedRows.length ||
										updateWaitlistMutation.isLoading
									}
									onClick={() => {
										setBulkAction(
											UpdateWaitlistEnum.NotifyTurn
										);
										setShowBulkUpdateModal(true);
									}}
								>
									<i className="mgc_bell_ringing_line before-text-gray-2 before-text-gray-2 text-[12px]" />
								</Button>
								{/* )} */}

								{(status === PatientStatus.Pending ||
									status === PatientStatus.NextInLine ||
									status === PatientStatus.Upcoming) && (
									<Button
										className="before-text-gray-2 flex h-8 w-8 items-center justify-center rounded-[6px] border border-[#6B7280] bg-transparent duration-200"
										variant="outline"
										type="button"
										disabled={
											!selectedRows.length ||
											updateWaitlistMutation.isLoading
										}
										onClick={() => {
											setBulkAction(
												status === PatientStatus.Pending
													? UpdateWaitlistEnum.Upcoming
													: status ===
														  PatientStatus.Upcoming
														? UpdateWaitlistEnum.NextInLine
														: status ===
															  PatientStatus.NextInLine
															? UpdateWaitlistEnum.GettingService
															: undefined
											);
											setShowBulkUpdateModal(true);
										}}
									>
										<i className="mgc_check_circle_line before-text-gray-2 before-text-gray-2 text-[12px]" />
									</Button>
								)}
								{status === PatientStatus.Serving && (
									<Button
										className="before-text-gray-2 flex h-8 w-8 items-center justify-center rounded-[6px] border border-[#6B7280] bg-transparent duration-200"
										variant="outline"
										type="button"
										disabled={
											!selectedRows.length ||
											updateWaitlistMutation.isLoading
										}
										onClick={() => {
											setBulkAction(
												UpdateWaitlistEnum.Done
											);
											setShowBulkUpdateModal(true);
										}}
									>
										<i className="mgc_check_circle_fill before-text-gray-2 before-text-gray-2 text-[12px]" />
									</Button>
								)}
							</div>
						</div>
					</div>

					<ScrollArea className="flex-1">
						<TooltipProvider>
							{!patientsObject[status]?.length ? (
								<div className="mt-6 flex justify-center text-base text-[#A1AAB6]">
									<img src={fileIcon} alt="file" />
									<span className="ml-1">No Data</span>
								</div>
							) : (
								<ul className="flex flex-col space-y-2 px-2">
									{patientsObject[status].map(
										(
											patient: QueueEntry,
											index: number
										) => (
											<WaitlistCard
												key={patient.id}
												selectedRows={selectedRows}
												setSelectAllChecked={
													setSelectAllChecked
												}
												setSelectedRows={
													setSelectedRows
												}
												noOfPatients={
													patientsObject[status]
														.length
												}
												status={status}
												index={index}
												patient={patient}
												activeId={activeId}
												setShowPatientInformation={
													setShowPatientInformation
												}
												setModalPatientInformation={
													setModalPatientInformation
												}
												setShowDeleteCustomerModal={
													setShowDeleteCustomerModal
												}
												setShowChangePatientStationModal={
													setShowChangePatientStationModal
												}
												setShowPriorityModal={
													setShowPriorityModal
												}
												setShowChangePositionOrderModal={
													setShowChangePositionOrderModal
												}
												setShowPatientInformationState={
													setShowPatientInformationState
												}
											/>
										)
									)}
								</ul>
							)}
						</TooltipProvider>
					</ScrollArea>
				</div>
			</SortableContext>
			<NotifyAllPatients
				customerIds={selectedRows}
				showBulkNotifyPatientsModal={showBulkNotifyPatientsModal}
				setShowBulkNotifyPatientsModal={setShowBulkNotifyPatientsModal}
			/>
			<BulkUpdateModal
				showBulkUpdateModal={showBulkUpdateModal}
				setShowBulkUpdateModal={setShowBulkUpdateModal}
				selectedRows={selectedRows}
				action={bulkAction}
				setSelectAllChecked={setSelectAllChecked}
				setSelectedRows={setSelectedRows}
			/>
		</>
	);
};

export default WaitlistColumn;
