import React, { useEffect, useRef } from "react";
import Overlay from "@components/Overlay";
import { Button } from "@components/ui/button";
import { Card } from "@components/ui/card";
import { IoClose } from "react-icons/io5";
import { PiInfo, PiCopyBold, PiBuildingOfficeBold } from "react-icons/pi";
import { TbUserShield } from "react-icons/tb";
import { Badge } from "@components/ui/badge";
import { IoMdStopwatch } from "react-icons/io";
import {
	LuGrid,
	LuChevronRight,
	LuChevronLeft,
	LuCalendarClock,
} from "react-icons/lu";
import { RiListCheck2 } from "react-icons/ri";
import {
	convertTimeFormat,
	createNestedObject,
	formatDate,
} from "@src/utils/functions";
import { Switch } from "@components/ui/switch";
import {
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@components/ui/accordion";
import { QRCodeSVG } from "qrcode.react";
import {
	StationsProps,
	queueSettingsProps,
	queueSettingsSchema,
} from "@src/types/Location";
import RequestIsLoading from "@src/components/RequestIsLoading";
import {
	GetQueueSettingsSlice,
	QueueSettingsSlice,
} from "@src/store/slices/locations/locationSlice";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import useCustomToast from "@components/CustomToast";
import { GetTeamMembersSlice } from "@src/store/slices/team-members/teamMembersSlice";

export const StationInfoModal: React.FC<{
	station: StationsProps | null;
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
	showModal: boolean;
	isLoading: boolean;
	buttonAction: () => void;
	activeInfoModalScreen: string;
	setActiveInfoModalScreen: React.Dispatch<React.SetStateAction<string>>;
}> = ({
	station,
	showModal,
	setShowModal,
	isLoading,
	buttonAction,
	activeInfoModalScreen,
	setActiveInfoModalScreen,
}) => {
	const modalRef = useRef(null);

	const customToast = useCustomToast();

	const {
		data: queueSettingsData,
		error: queueSettingsError,
		isLoading: queueSettingsLoading,
		invalidateQuery,
	} = GetQueueSettingsSlice({ station_id: station?.id || 0 });

	const { data, isLoading: teamMemberLoading } = GetTeamMembersSlice({
		station_id: station?.id || 0,
	});

	const handleScreenToggle = (screen: string) => {
		setActiveInfoModalScreen(screen);
	};

	const { control, setValue, reset, watch, getValues } =
		useForm<queueSettingsProps>({
			resolver: zodResolver(queueSettingsSchema),
			defaultValues: {
				auto_approve: 0,
				is_visible: 0,
				waitlist_setting: {
					is_queue_active: 0,
					join_from_qr: 0,
					join_from_url: 0,
				},
				schedule_setting: {
					join_from_qr: 0,
					join_from_url: 0,
					is_open: 0,
				},
			},
		});

	useEffect(() => {
		if (queueSettingsData) {
			reset(queueSettingsData.data);
		}
	}, [queueSettingsData, reset]);

	const { mutate: updateQueueSettings } = QueueSettingsSlice(
		(data) => {
			invalidateQuery();
			reset(data?.data);
		},
		(error) => {
			console.error("Update failed:", error);
		}
	);

	const handleSwitchChange = (fieldPath: any) => (value: boolean) => {
		const newValue = value ? 1 : 0;
		setValue(fieldPath, value);
		const updatedData = createNestedObject(fieldPath, newValue);
		updateQueueSettings({
			data: {
				...updatedData,
				apply_to_option: {
					apply_to_all: 0,
					locations: [
						{
							id: station?.location_id || 0,
							update_location: 0,
							apply_to_all_stations: 0,
							selected_stations: [station?.id || 0],
						},
					],
				},
			},
			queryParams: { station_id: station?.id || 0 },
		});
		customToast("Updating queue settings", {
			id: "update-queue-settings",
			type: "loading",
		});
	};

	// const handleNestedSwitchChange =
	// 	(
	// 		parentField: "waitlist_setting" | "schedule_setting",
	// 		nestedField:
	// 			| keyof queueSettingsProps["waitlist_setting"]
	// 			| keyof queueSettingsProps["schedule_setting"]
	// 			| string
	// 	) =>
	// 	(value: boolean) => {
	// 		setValue(
	// 			`${parentField}.${nestedField}` as keyof queueSettingsProps,
	// 			value ? 1 : 0
	// 		);
	// 		updateQueueSettings({
	// 			data: {
	// 				[parentField]: {
	// 					...getValues()[parentField],
	// 					[nestedField]: value ? 1 : 0,
	// 				},
	// 			},
	// 			queryParams: { station_id: station?.id || 0 },
	// 		});
	// 		customToast("Updating queue settings", {
	// 			id: "update-queue-settings",
	// 			type: "loading",
	// 		});
	// 	};

	const RenderScreen = (activeStep: string) => {
		switch (activeStep) {
			case "station":
				return (
					<Card
						className="relative z-50 max-h-screen w-full max-w-[500px] space-y-6 overflow-hidden bg-white p-5 text-main-1"
						ref={modalRef}
					>
						<Button
							className="absolute right-1 top-7 bg-transparent text-black hover:bg-transparent "
							onClick={() => setShowModal(false)}
						>
							<IoClose className=" text-black" size={20} />
						</Button>

						<div className="flex flex-1 items-center space-x-3">
							<div className="flex flex-1 flex-col items-center space-y-3">
								<div className="flex items-center space-x-3">
									<PiBuildingOfficeBold size={14} />
									<p className="font-semibold">
										{station?.name}
									</p>
								</div>
								<div className="flex items-start space-x-2 text-gray-400">
									<p className="text-sm font-normal leading-none tracking-tight">
										Last activity-{" "}
										{formatDate(station?.updated_at || "")}
									</p>
								</div>
								<div className="flex flex-1 items-center space-x-3">
									<Badge
										className="my-o rounded-sm bg-[#EFEFF1] px-2"
										variant={"secondary"}
									>
										<IoMdStopwatch
											className="mr-2"
											size={14}
										/>
										<span className="mr-1 font-normal">
											Avg. Wait Time
										</span>{" "}
										{convertTimeFormat(
											station?.average_waiting_time || ""
										)}
									</Badge>
									<Badge
										className="rounded-sm bg-[#EFEFF1] px-2 text-xs text-[#323539]"
										variant={"secondary"}
									>
										{/* <i className="mgc_shop_line before-text-gray-4 mr-2 text-[18px]" /> */}
										<LuGrid className="mr-2" size={14} />
										<span className=" mr-1 font-normal ">
											Services
										</span>{" "}
										{"N/A"}
									</Badge>
								</div>
							</div>
						</div>
						<div className="flex flex-1 flex-col items-center space-y-3">
							<div className=" item-center flex w-full justify-between rounded-l-md bg-neutral-50 p-5 text-main-1">
								<span className="flex items-center gap-x-2">
									<RiListCheck2 size={24} />
									Waitlist
								</span>
								<Button
									type="button"
									variant="secondary"
									size="icon-sm"
									className="bg-white"
									onClick={() =>
										handleScreenToggle("waitlist")
									}
								>
									<LuChevronRight size={24} />
								</Button>
							</div>
							<div className=" item-center flex w-full justify-between rounded-l-md bg-neutral-50 p-5 text-main-1">
								<span className="flex items-center gap-x-2">
									<LuCalendarClock size={24} />
									Schedule
								</span>
								<Button
									variant="secondary"
									size="icon-sm"
									className="bg-white"
									onClick={() =>
										handleScreenToggle("schedule")
									}
								>
									<LuChevronRight size={24} />
								</Button>
							</div>
						</div>
						{data?.data && data?.data?.length > 0 ? (
							<div className="space-y-2">
								<h3 className="font-medium">Members</h3>
								<div className="flex max-h-[207px] flex-1 flex-col divide-y-2 divide-gray-200 overflow-y-auto">
									{data?.data.map((member: any) => (
										<div
											key={member.id}
											className="flex items-center space-x-3 px-4 py-3 "
										>
											<TbUserShield size={16} />
											<div>
												<h3 className="space-x-3 text-sm">
													<span className="font-semibold">
														Daniella Dalsheim
													</span>
													<span className="font-normal">
														(Admin)
													</span>
												</h3>
												<div className="flex items-center space-x-3 text-xs font-normal">
													<p>+ 1 416 938 8888</p>
													<p>email@example.com</p>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						) : (
							<div className="flex flex-col items-center justify-center py-4">
								<i className="mgc_file_line text-[32px] text-primary"></i>
								<h2 className="mt-5 font-semibold">
									No team member data
								</h2>
								<p className="text-gray-500">
									Get started by adding a new custom field.
								</p>
							</div>
						)}

						<div className="flex items-start justify-between space-x-2">
							<div className="flex flex-1 flex-col">
								<div className="mt-6 flex justify-end space-x-3">
									<Button
										className="font-semibold text-[#323539]"
										variant={"ghost"}
										onClick={() => setShowModal(false)}
									>
										Close
									</Button>
									<Button
										className="font-semibold"
										// variant={""}
										onClick={buttonAction}
									>
										{/* <Loader size={24} /> */}
										View Details
									</Button>
								</div>
							</div>
						</div>
					</Card>
				);
			case "waitlist":
				return (
					<Card
						className="relative z-50 max-h-screen w-full max-w-[500px] overflow-hidden bg-white  text-main-1"
						ref={modalRef}
					>
						<RequestIsLoading isLoading={isLoading} />
						<div className="space-y-6 p-5">
							<div className="relative">
								<Button
									className="absolute left-1 top-1 bg-transparent p-1 text-black hover:bg-transparent"
									onClick={() =>
										handleScreenToggle("station")
									}
								>
									<LuChevronLeft
										className=" text-black"
										size={24}
									/>
								</Button>

								<Button
									className="absolute right-1 top-1 bg-transparent p-1 text-black hover:bg-transparent"
									onClick={() => setShowModal(false)}
								>
									<IoClose
										className=" text-black"
										size={24}
									/>
								</Button>
							</div>

							<div className="flex w-full flex-1 items-center justify-center space-x-3 border-b">
								<p className="-mb-0.5 border-b border-b-primary pb-2 font-semibold text-primary">
									Join Waitlist
								</p>
							</div>

							<div className="mt-6">
								<div className="flex items-center justify-between border-b">
									<div className="flex items-center space-x-1">
										<p className="font-semibold">
											Waitlist Active
										</p>
										<Button
											type="button"
											variant="link"
											size="xs"
											className="m-0 p-0"
											onClick={() =>
												handleScreenToggle("waitlist")
											}
										>
											<PiInfo
												size={12}
												className=" text-[#C5CEDB]"
											/>
										</Button>
									</div>
									<div className="flex items-center">
										<Controller
											name="waitlist_setting.is_queue_active"
											control={control}
											render={({ field }) => (
												<Switch
													className="scale-[0.70] data-[state=checked]:bg-[#2EBF43]"
													checked={Boolean(
														field.value
													)}
													onCheckedChange={handleSwitchChange(
														"waitlist_setting.is_queue_active"
													)}
												/>
											)}
										/>
										<span className="">
											{watch(
												"waitlist_setting.is_queue_active"
											)
												? "Yes"
												: "No"}
										</span>
									</div>
								</div>
								<div className="mt-2 flex flex-col items-center space-y-3">
									<h3 className="m-0 p-0 text-[#858C95]">
										Use this QR code/link to join the{" "}
										<span className="font-bold">
											waitlist
										</span>{" "}
										of {station?.name}
									</h3>
									<QRCodeComponent
										url={
											queueSettingsData?.data
												?.waitlist_setting
												?.qr_code_url || ""
										}
									/>
									<h3 className="text-xs font-semibold">
										Waitlist QR CODE
									</h3>
									<div className="flex w-full items-center justify-between space-x-4 rounded-md border px-3 py-2.5">
										<h3 className="overflow-hidden text-ellipsis text-[15px]">
											{
												queueSettingsData?.data
													?.waitlist_setting
													?.qr_code_url
											}
										</h3>
										<Button
											variant="link"
											size="xs"
											className="m-0 p-0"
											onClick={() => {
												navigator.clipboard
													.writeText(
														queueSettingsData?.data
															?.waitlist_setting
															?.join_url || ""
													)
													.then(() => {
														customToast(
															"Copied to Clipboard",
															{
																id: "copy-to-clipboard",
																type: "success",
															}
														);
													})
													.catch(() => {
														customToast(
															"Unable to copy",
															{
																id: "copy-to-clipboard",
																type: "error",
															}
														);
													});
											}}
										>
											<PiCopyBold
												size={15}
												className=" text-[#858C95]"
											/>
										</Button>
									</div>
								</div>
								<div className="mt-7 flex w-full items-center justify-between border-b pb-3">
									<p className="font-semibold">
										Join through QR code
									</p>
									<div className="flex items-center">
										<Controller
											name="waitlist_setting.join_from_qr"
											control={control}
											render={({ field }) => (
												<Switch
													className="scale-[0.70] data-[state=checked]:bg-primary"
													checked={Boolean(
														field.value
													)}
													onCheckedChange={handleSwitchChange(
														"waitlist_setting.join_from_qr"
													)}
												/>
											)}
										/>
										<span className="">
											{watch(
												"waitlist_setting.join_from_qr"
											)
												? "On"
												: "Off"}
										</span>
									</div>
								</div>
								<div className="mt-3 flex w-full items-center justify-between pb-3">
									<div className="flex items-center space-x-1">
										<p className="font-semibold">
											Join through web link
										</p>
										<Button
											variant="link"
											size="xs"
											className="m-0 p-0"
										>
											<PiInfo
												size={12}
												className=" text-[#C5CEDB]"
											/>
										</Button>
									</div>

									<div className="flex items-center">
										<Controller
											name="waitlist_setting.join_from_url"
											control={control}
											render={({ field }) => (
												<Switch
													className="scale-[0.70] data-[state=checked]:bg-primary"
													checked={Boolean(
														field.value
													)}
													onCheckedChange={handleSwitchChange(
														"waitlist_setting.join_from_url"
													)}
												/>
											)}
										/>
										<span className="">
											{watch(
												"waitlist_setting.join_from_url"
											)
												? "On"
												: "Off"}
										</span>
									</div>
								</div>
							</div>

							<div className="mt-6 flex justify-end space-x-3">
								<Button
									className="font-semibold text-[#323539]"
									variant={"ghost"}
									onClick={() => setShowModal(false)}
								>
									Close
								</Button>
								<Button
									className="font-semibold"
									// variant={""}
									onClick={buttonAction}
								>
									{/* <Loader size={24} /> */}
									View Details
								</Button>
							</div>
						</div>
					</Card>
				);
			case "schedule":
				return (
					<Card
						className="relative z-50 max-h-screen w-full max-w-[500px] overflow-hidden bg-white  text-main-1"
						ref={modalRef}
					>
						<RequestIsLoading isLoading={isLoading} />
						<div className="space-y-6 p-5">
							<div className="relative">
								<Button
									className="absolute left-1 top-1 bg-transparent p-1 text-black hover:bg-transparent"
									onClick={() =>
										handleScreenToggle("station")
									}
								>
									<LuChevronLeft
										className=" text-black"
										size={24}
									/>
								</Button>

								<Button
									className="absolute right-1 top-1 bg-transparent p-1 text-black hover:bg-transparent"
									onClick={() => setShowModal(false)}
								>
									<IoClose
										className=" text-black"
										size={24}
									/>
								</Button>
							</div>

							<div className="flex w-full flex-1 items-center justify-center space-x-3 border-b">
								<p className="-mb-0.5 border-b border-b-primary pb-2 font-semibold text-primary">
									Schedule Appointment
								</p>
							</div>

							<div className="mt-6">
								<div className="flex items-center justify-between border-b">
									<div className="flex items-center space-x-1">
										<p className="font-semibold">
											Allow New Appointments
										</p>
										<Button
											variant="link"
											size="xs"
											className="m-0 p-0"
										>
											<PiInfo
												size={12}
												className=" text-[#C5CEDB]"
											/>
										</Button>
									</div>
									<div className="flex items-center">
										<Controller
											name="schedule_setting.is_open"
											control={control}
											render={({ field }) => (
												<Switch
													className="scale-[0.70] data-[state=checked]:bg-[#2EBF43]"
													checked={Boolean(
														field.value
													)}
													onCheckedChange={handleSwitchChange(
														"schedule_setting.is_open"
													)}
												/>
											)}
										/>
										<span className="">
											{watch("schedule_setting.is_open")
												? "Yes"
												: "No"}
										</span>
									</div>
								</div>
								<div className="mt-2 flex flex-col items-center space-y-3">
									<h3 className="m-0 p-0 text-[#858C95]">
										Use this QR code/link to join the{" "}
										<span className="font-bold">
											schedule
										</span>{" "}
										of {station?.name}
									</h3>
									<QRCodeComponent
										url={
											queueSettingsData?.data
												?.schedule_setting
												?.qr_code_url || ""
										}
									/>
									<h3 className="text-xs font-semibold">
										Schedule QR CODE
									</h3>
									<div className="flex w-full items-center justify-between space-x-4 rounded-md border px-3 py-2.5">
										<h3 className="overflow-hidden text-ellipsis text-[15px]">
											{
												queueSettingsData?.data
													?.schedule_setting?.join_url
											}
										</h3>
										<Button
											variant="link"
											size="xs"
											className="m-0 p-0"
											onClick={() => {
												navigator.clipboard
													.writeText(
														queueSettingsData?.data
															?.schedule_setting
															?.join_url || ""
													)
													.then(() => {
														customToast(
															"Copied to Clipboard",
															{
																id: "copy-to-clipboard",
																type: "success",
															}
														);
													})
													.catch(() => {
														customToast(
															"Unable to copy",
															{
																id: "copy-to-clipboard",
																type: "error",
															}
														);
													});
											}}
										>
											<PiCopyBold
												size={15}
												className=" text-[#858C95]"
											/>
										</Button>
									</div>
								</div>
								<div className="mt-7 flex w-full items-center justify-between border-b pb-3">
									<p className="font-semibold">
										Schedule through QR code
									</p>
									<div className="flex items-center">
										<Controller
											name="schedule_setting.join_from_qr"
											control={control}
											render={({ field }) => (
												<Switch
													className="scale-[0.70] data-[state=checked]:bg-primary"
													checked={Boolean(
														field.value
													)}
													onCheckedChange={handleSwitchChange(
														"schedule_setting.join_from_qr"
													)}
												/>
											)}
										/>
										<span className="">
											{watch(
												"schedule_setting.join_from_qr"
											)
												? "On"
												: "Off"}
										</span>
									</div>
								</div>
								<div className="mt-3 flex w-full items-center justify-between pb-3">
									<div className="flex items-center space-x-1">
										<p className="font-semibold">
											Schedule through web link
										</p>
										<Button
											variant="link"
											size="xs"
											className="m-0 p-0"
										>
											<PiInfo
												size={12}
												className=" text-[#C5CEDB]"
											/>
										</Button>
									</div>

									<div className="flex items-center">
										<Controller
											name="schedule_setting.join_from_url"
											control={control}
											render={({ field }) => (
												<Switch
													className="scale-[0.70] data-[state=checked]:bg-primary"
													checked={Boolean(
														field.value
													)}
													onCheckedChange={handleSwitchChange(
														"schedule_setting.join_from_url"
													)}
												/>
											)}
										/>
										<span className="">
											{watch(
												"schedule_setting.join_from_url"
											)
												? "On"
												: "Off"}
										</span>
									</div>
								</div>
							</div>

							<div className="mt-6 flex justify-end space-x-3">
								<Button
									className="font-semibold text-[#323539]"
									variant={"ghost"}
									onClick={() => setShowModal(false)}
								>
									Close
								</Button>
								<Button
									className="font-semibold"
									// variant={""}
									onClick={buttonAction}
								>
									{/* <Loader size={24} /> */}
									View Details
								</Button>
							</div>
						</div>
					</Card>
				);
			// case 2:
			// 	return <LocationMember />;
			default:
				return "";
		}
	};

	return (
		<>
			<Overlay
				show={showModal}
				setShow={setShowModal}
				modalRef={modalRef}
				isPersistent={isLoading}
			>
				{RenderScreen(activeInfoModalScreen)}
			</Overlay>
		</>
	);
};

const WaitListAccordion: React.FC = () => {
	return (
		<AccordionItem value="item-2" className="border-0">
			<AccordionTrigger className="rounded-l-md bg-neutral-50 p-5 text-main-1 hover:no-underline">
				<span className="flex items-center gap-x-2">
					<RiListCheck2 size={24} />
					Waitlist
				</span>
			</AccordionTrigger>
			<AccordionContent className="flex flex-col pb-0">
				<p className="border-b py-1">
					Windows 11 is a simple yet effective way to enhance your
					computing experience. Whether you&apos;re a professional
					juggling multiple tasks, a student doing research, or are
					just looking to improve your productivity, utilizing this
					small feature while you work can provide a significant boost
					to your levels of focus.
				</p>
			</AccordionContent>
		</AccordionItem>
	);
};

const QRCodeComponent: React.FC<{ url: string | null }> = ({ url }) => {
	return (
		<QRCodeSVG
			value={url || ""}
			size={114}
			bgColor={"#ffffff"}
			fgColor={"#000000"}
			level={"L"}
			includeMargin={false}
		/>
	);
};
