import { z } from "zod";

export const timeSlotSchema = z.object({
	id: z.number().optional(),
	start_time: z.string(),
	end_time: z.string(),
	is_active: z.number().int().optional(),
	product_working_hour_id: z.number().optional(),
	created_at: z.string().optional(),
	updated_at: z.string().optional(),
});

export const operatingHourSchema = z.object({
	id: z.number().optional(),
	day: z.string().optional(),
	day_value: z.number().optional(),
	day_of_week: z.string().optional(),
	workable_id: z.number().optional(),
	workable_type: z.string().optional(),
	is_active: z.union([z.literal(0), z.literal(1)]),
	created_at: z.string().optional(),
	updated_at: z.string().optional(),
	time_slots: z.array(timeSlotSchema),
	service_time_slots: z.any(),
});

export type TimeSlot = z.infer<typeof timeSlotSchema>;
export type OperatingHour = z.infer<typeof operatingHourSchema>;

// export interface OperatingHour {
// 	id?: number;
// 	day: string;
// 	day_value: number;
// 	is_active: 0 | 1;
// 	location?: any;
// 	location_id?: number;
// 	service_time_slots?: any[];
// 	time_slots: TimeSlot[];
// 	day_of_week: string; // e.g., "monday", "tuesday"
// 	workable_id: number;
// 	workable_type: string;
// 	created_at: string; // ISO date string
// 	updated_at: string; // ISO date string
// }

interface DayData {
	is_active: boolean;
	time_slots: TimeSlot[];
}

export interface OperatingHourV2 {
	[day: string]: DayData; // Allows any string as a key
}

export interface DaySchedule {
	is_active: boolean;
	time_slots: TimeSlot[];
}

export interface ScheduleData {
	[key: string]: DaySchedule;
}

export interface OldDateSlot {
	day: string;
	day_value: number;
	id?: number;
	is_active: 0 | 1;
	location?: any;
	location_id?: number;
	service_time_slots?: any[];
	time_slots: {
		id?: number;
		is_active?: 0 | 1;
		start_time: string;
		end_time: string;
	}[];
}
