import { Badge } from "@src/components/ui/badge";
import { Button } from "@src/components/ui/button";
import { Switch } from "@src/components/ui/switch";
import React, { useEffect } from "react";
import { IoMdStopwatch } from "react-icons/io";
import { LuChevronRight } from "react-icons/lu";
import { TiSpanner } from "react-icons/ti";
import {
	queueSettingsProps,
	queueSettingsSchema,
	StationsProps,
} from "@src/types/Location";
import {
	formatDate,
	convertTimeFormat,
	createNestedObject,
} from "@src/utils/functions";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import {
	GetLocationStationsSlice,
	QueueSettingsSlice,
} from "@src/store/slices/locations/locationSlice";
import { zodResolver } from "@hookform/resolvers/zod";
import useCustomToast from "@src/components/CustomToast";
import Checkbox from "@src/components/ui-extended/checkbox";

interface Props {
	stationData: StationsProps;
	watchLocations: any;
	index: number;
	handleSwitchChange: (
		locationId: number,
		stationId: number,
		value: boolean,
		index: number
	) => Promise<void>;
	control: any;
	handleSelectStation: (station: StationsProps) => void;
	handleDeleteStationModal: (station: StationsProps) => void;
	setShowInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
	stationIds: number[];
	setStationIds: React.Dispatch<React.SetStateAction<number[]>>;
	setActiveInfoModalScreen: React.Dispatch<React.SetStateAction<string>>;
}

export const DetailCard: React.FC<Props> = ({
	stationData,
	handleSelectStation,
	setShowInfoModal,
	stationIds,
	setStationIds,
	handleDeleteStationModal,
	setActiveInfoModalScreen,
}) => {
	const navigate = useNavigate();
	const toggleStationModal = (
		station: StationsProps,
		screenType?: string
	) => {
		handleSelectStation(station);
		setShowInfoModal(true);
		setActiveInfoModalScreen(screenType || "station");
	};
	const customToast = useCustomToast();

	const { invalidateQuery } = GetLocationStationsSlice(
		stationData.location_id
	);

	const {
		control: queueControl,
		setValue,
		reset,
		watch,
	} = useForm<queueSettingsProps>({
		resolver: zodResolver(queueSettingsSchema),
		defaultValues: {
			auto_approve: 0,
			is_visible: 0,
			waitlist_setting: {
				is_queue_active: 0,
				join_from_qr: 0,
				join_from_url: 0,
			},
			schedule_setting: {
				join_from_qr: 0,
				join_from_url: 0,
				is_open: 0,
			},
		},
	});

	const handleSwitchChange = (fieldPath: any) => (value: boolean) => {
		const newValue = value ? 1 : 0;
		setValue(fieldPath, value);
		const updatedData = createNestedObject(fieldPath, newValue);
		updateQueueSettings({
			data: {
				...updatedData,
				apply_to_option: {
					apply_to_all: 0,
					locations: [
						{
							id: stationData?.location_id || 0,
							update_location: 0,
							apply_to_all_stations: 0,
							selected_stations: [stationData?.id || 0],
						},
					],
				},
			},
			queryParams: { station_id: stationData?.id || 0 },
		});
		customToast("Updating queue settings", {
			id: "update-queue-settings",
			type: "loading",
		});
	};

	useEffect(() => {
		if (stationData) {
			reset({
				auto_approve: stationData?.queue_setting?.auto_approve || 0,
				is_visible: stationData?.queue_setting?.is_visible || 0,
				waitlist_setting: {
					is_queue_active:
						stationData?.queue_setting?.waitlist_setting
							?.is_queue_active || 0,
					join_from_qr:
						stationData?.queue_setting?.waitlist_setting
							?.join_from_qr || 0,
					join_from_url:
						stationData?.queue_setting?.waitlist_setting
							?.join_from_url || 0,
				},
				schedule_setting: {
					join_from_qr:
						stationData?.queue_setting?.schedule_setting
							?.join_from_qr || 0,
					join_from_url:
						stationData?.queue_setting?.schedule_setting
							?.join_from_url || 0,
					is_open:
						stationData?.queue_setting?.schedule_setting?.is_open ||
						0,
				},
			});
		}
	}, [stationData, reset]);

	const { mutate: updateQueueSettings } = QueueSettingsSlice(
		(data) => {
			invalidateQuery();
			reset(data?.data);
		},
		(error) => {
			console.error("Update failed:", error);
		}
	);

	return (
		<div className="h-full w-full space-y-4 divide-y rounded-lg border p-4 shadow-custom3">
			<div className="space-y-4">
				<div className="flex justify-between space-x-2">
					<Checkbox
						isChecked={stationIds.includes(stationData.id)}
						handleCheckboxChange={() => {
							setStationIds((prev) => {
								if (prev.includes(stationData.id)) {
									return prev.filter(
										(id) => id !== stationData.id
									);
								} else {
									return [...prev, stationData.id];
								}
							});
						}}
						className="rounded-sm border-2 border-[#D1D1D1]"
						containerClassName="items-start"
						id={""}
					/>
					<div className="flex-1">
						<p className="-mt-1 text-sm font-semibold">
							{stationData.name}
						</p>
						<p className="text-xs text-gray-400">
							Last active : {formatDate(stationData.updated_at)}
						</p>
					</div>
					<Button
						type="button"
						variant="secondary"
						size="xs"
						onClick={() => toggleStationModal(stationData)}
					>
						<i className="mgc_information_line" />
					</Button>
				</div>
				<Badge className="bg-slate-100 text-sm" variant={"secondary"}>
					<IoMdStopwatch className="mr-2" size={14} />
					<span className="mr-1 text-xs font-normal">
						Avg. Wait Time
					</span>{" "}
					{convertTimeFormat(stationData.average_waiting_time || "")}
				</Badge>
			</div>
			<div className="space-y-4 pt-4">
				<div className="flex items-center justify-between">
					<p className="text-sm font-semibold">Wait List</p>
					<Button
						variant="secondary"
						size="icon-sm"
						type="button"
						onClick={() =>
							toggleStationModal(stationData, "waitlist")
						}
					>
						<i className="mgc_qrcode_line" />
					</Button>
				</div>
				<div className="flex items-center justify-between">
					<Badge
						className="bg-slate-100 px-2 text-xs"
						variant={"secondary"}
					>
						<i className="mgc_list_check_3_line mr-2" />
						<span className="text-xs font-semibold">
							{stationData?.schedule_optimizer
								?.waitlist_number_of_people || "N/A"}
						</span>
					</Badge>
					<div className="flex items-center space-x-2">
						<p className="text-xs">Wait List Active</p>
						<Controller
							name="waitlist_setting.is_queue_active"
							control={queueControl}
							render={({ field }) => (
								<Switch
									className="scale-[0.70]"
									checked={Boolean(field.value)}
									onCheckedChange={handleSwitchChange(
										"waitlist_setting.is_queue_active"
									)}
								/>
							)}
						/>
						<span className="">
							{watch("waitlist_setting.is_queue_active")
								? "On"
								: "Off"}
						</span>
					</div>
				</div>
			</div>
			<div className="space-y-4 pt-4">
				<div className="flex items-center justify-between">
					<div className="flex items-center">
						<p className="mr-1 text-sm font-semibold">Schedule</p>
						<TiSpanner color="#EEA23E" size={12} />
					</div>
					<Button
						variant="secondary"
						size="icon-sm"
						type="button"
						onClick={() => {
							toggleStationModal(stationData, "schedule");
						}}
					>
						<i className="mgc_qrcode_line" />
					</Button>
				</div>
				<div className="flex items-center justify-between">
					<Badge
						className="bg-slate-100 px-1 text-sm"
						variant={"secondary"}
					>
						<i className="mgc_calendar_time_add_line mr-2" />
						<span className="mr-1 text-xs font-semibold">
							{stationData?.schedule_optimizer
								?.upcoming_appointments_number_of_people ||
								"N/A"}
						</span>
					</Badge>
					<div className="flex items-center space-x-2">
						<p className="text-xs">Allow Appointments</p>
						<Controller
							name="schedule_setting.is_open"
							control={queueControl}
							render={({ field }) => (
								<Switch
									className="scale-[0.70]"
									checked={Boolean(field.value)}
									onCheckedChange={handleSwitchChange(
										"schedule_setting.is_open"
									)}
								/>
							)}
						/>
						<span className="">
							{watch("schedule_setting.is_open") ? "On" : "Off"}
						</span>
					</div>
				</div>
			</div>
			<div className="space-y-4 pt-4">
				<div className="flex items-center justify-between">
					<Button
						variant="secondary"
						size="icon-sm"
						type="button"
						onClick={() => handleDeleteStationModal(stationData)}
					>
						<i className="mgc_delete_2_line" />
					</Button>
					<Button
						variant="secondary"
						type="button"
						onClick={() =>
							navigate(
								`/dashboard/locations/details/station-detail?stationId=${stationData.id}`
							)
						}
					>
						<div className="flex items-center space-x-4">
							Details
							<LuChevronRight className="text-[14px]" size={20} />
						</div>
					</Button>
				</div>
			</div>
		</div>
	);
};
