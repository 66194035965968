import { Outlet } from "react-router";
import logoBlueFooter from "../../public/assets/images/logo-blue-footer.svg";
import Sidebar from "../components/Dashboard/Sidebar";
// import Header from "../components/Header";
import DynamicHeader from "../components/DynamicHeader";
import Header from "./Header/Header";
import { GetQueryListSlice } from "@src/store/slices/waitlist/getWaitlistSlice";
import { cn } from "@src/utils/functions";
import { useGetBusinessLocationsAndStations } from "../store/slices/locations/locationSlice";

const Layout = () => {
	GetQueryListSlice();
	useGetBusinessLocationsAndStations();

	return (
		<main className="relative flex h-[100dvh] flex-col">
			<Sidebar />
			<div className="flex min-h-[100svh] w-full flex-col justify-between">
				{/* //Add topbar here too  */}
				<div
					className={cn(
						"flex w-full flex-1 flex-col justify-between overflow-auto pl-[96px] pr-8 pt-6"
					)}
				>
					<Outlet />
					<footer className="sticky bottom-0 flex items-center justify-center space-x-2 bg-white py-2">
						<small className="py-0.5 text-[12px] text-[#6D748D]">
							Powered by
						</small>
						<img
							src={logoBlueFooter}
							alt="Migranium logo"
							className="py-0.5"
						/>
					</footer>
				</div>
			</div>
		</main>
	);
};

export default Layout;
