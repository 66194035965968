import {
	Calendar as BigCalendar,
	CalendarProps,
	momentLocalizer,
} from "react-big-calendar";
import moment from "moment";
import "./styles/index.scss";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

export const Calendar = (props: Omit<CalendarProps, "localizer">) => {
	return (
		<BigCalendar
			{...props}
			localizer={localizer}
			defaultDate={"2022-10-10"}
			defaultView={"week"}
		/>
	);
};
