import { LuBellRing, LuCheckCircle2, LuInfo, LuTrash2 } from "react-icons/lu";
import moment from "moment";
import rescheduleIcon from "/assets/images/reschedule.svg";
import gridIcon from "/assets/images/grid.svg";
import transferIcon from "/assets/images/transfer.svg";
import { cn } from "../../../utils/functions";
import { FaTag } from "react-icons/fa6";
import { BiPhoneCall } from "react-icons/bi";
import { UpdateAppointment } from "@src/store/slices/schedule/scheduleSlice";
import useUserStore from "@src/store/useUserStore";
import useCustomToast from "@src/components/CustomToast";
import React, { useState } from "react";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@src/components/ui/tooltip";
import PatientInformation from "@src/components/Dashboard/waitlist/PatientInformation/PatientInformation";
import { QueueEntry } from "@src/types/waitlist/waitlist";
import useAppointmentStore from "@/src/store/useAppointmentStore";
import RescheduleAppointment from "../modals/RescheduleAppointment";

interface AppointmentCardProps {
	patientData?: Array<Record<string, object>>;
	data?: Record<string, any>;
	status: "pending" | "confirmed" | "followup";
	checked_in?: boolean;
}
const AppointmentCard = ({ data }: AppointmentCardProps) => {
	const { setDate } = useAppointmentStore();
	const [action, setAction] = useState<
		| "done"
		| "cancel"
		| "reschedule"
		| "follow_up"
		| "approve"
		| "serve"
		| "remind"
	>("approve");

	const status = data?.status;
	const checked_in = data?.checked_in == 1 ? true : false;
	const appointment_id = data?.id;
	const location_id = useUserStore((s) => s.currentLocationId);
	const toast = useCustomToast();
	const [showPatientInformation, setShowPatientInformation] = useState(false);
	const [showRescheduleModal, setShowRescheduleModal] = useState(false);
	const onSuccess = (response) => {
		toast(`${response?.data?.message || "Request was successful"}`, {
			type: "success",
			id: action,
		});
	};

	const onError = (error) => {
		toast(`${error?.response?.data?.message ?? "An Error Occured"}`, {
			type: "error",
			id: action,
		});
	};

	const { mutate } = UpdateAppointment(
		location_id,
		appointment_id,
		onSuccess,
		onError
	);
	const handleCancelAppointment = () => {
		setAction("cancel");
		toast("Cancelling Appointment", {
			type: "loading",
			id: action,
		});
		mutate({ action: "cancel" });
	};

	const handleNotifyPatient = () => {
		setAction("remind");
		toast("Notifying Patient", {
			type: "loading",
			id: action,
		});
		mutate({ action: "remind" });
	};

	const handleApproveAppointment = () => {
		setAction("approve");
		toast("Approving Appointment", {
			type: "loading",
			id: action,
		});
		mutate({ action: "approve" });
	};

	const handleFollowUpAppointment = () => {
		setAction("follow_up");
		toast("Requesting Follow up", {
			type: "loading",
			id: action,
		});
		mutate({ action: "follow_up" });
	};

	return (
		<>
			<div
				className={cn(
					"shadow-sm-x-0-y-1-blur-3-spread-0-color-101828-opacity-10 m-2 flex cursor-grab space-x-3 rounded-xl border bg-white p-3 lg:flex-col lg:space-x-0 lg:space-y-3",
					{
						"hover:border-[#C8322B]":
							status == "waiting_to_be_approved",
						"hover:border-[#138576]": status == "confirmed",
					}
				)}
				onClick={() =>
					setDate(new Date(data?.appointment_date).toISOString())
				}
			>
				<div className="flex items-center justify-between">
					<div className="flex items-center gap-x-1.5">
						<p className="font-medium text-[#323539]">
							{data?.customer?.full_name}
						</p>
						<button
							className="rounded-md bg-[#F5F5F5] px-1.5 py-1"
							onClick={() => setShowPatientInformation(true)}
						>
							<LuInfo
								width="14px"
								height="14px"
								color="#323539"
							/>
						</button>
						<div className="rounded-md bg-[#EAEBF0] px-1.5 py-1 text-red-500">
							<FaTag />
						</div>
					</div>
				</div>
				<div className="flex items-center justify-between text-[#6B7280]">
					<div className="flex items-center gap-x-1">
						<img src={gridIcon} alt="grid icon" />
						<p className="text-[10px] leading-[10px]">
							{data?.station?.name || "N/A"}
						</p>
						{checked_in && (
							<span className="h-1.5 w-1.5 rounded-full bg-[#2EBF43]"></span>
						)}
					</div>
					<p className="text-[10px] leading-[10px]">
						{data?.appointment_date
							? moment(data?.appointment_date)?.format(
									"Do MMMM YYYY"
								)
							: "N/A"}
					</p>
				</div>
				<div className="flex items-center justify-between text-[#6B7280]">
					<p className="text-[10px] leading-[10px]">
						{data?.appointment_type ?? "N/A"}
					</p>
					<p className="text-[10px] leading-[10px]">
						{data?.appointment_date
							? moment(data?.appointment_date)?.format("h:mm A")
							: "N/A"}{" "}
						-{" "}
						{data?.end_at
							? moment(data?.end_at)?.format("h:mm A")
							: "N/A"}
					</p>
				</div>
				<div className="flex items-center justify-between">
					<div className="flex items-center gap-x-1.5">
						{status === "approved" && (
							<>
								<button
									className="cursor-pointer rounded-lg bg-[#F5F5F5] p-2"
									onClick={() =>
										setShowRescheduleModal(
											!showRescheduleModal
										)
									}
								>
									<img
										src={rescheduleIcon}
										alt="transfer icon"
									/>
								</button>
								<button
									className="cursor-pointer rounded-lg bg-[#F5F5F5] p-2"
									onClick={handleCancelAppointment}
								>
									<LuTrash2 width="14px" height="14px" />
								</button>
							</>
						)}
						{status === "waiting_to_be_approved" && (
							<>
								{!checked_in && (
									<button className="cursor-pointer rounded-lg bg-[#F5F5F5] p-2">
										<img
											src={transferIcon}
											alt="transfer icon"
										/>
									</button>
								)}

								<button
									className="cursor-pointer rounded-lg bg-[#F5F5F5] p-2"
									onClick={handleCancelAppointment}
								>
									<LuTrash2 width="14px" height="14px" />
								</button>
							</>
						)}
						{status === "follow_up" && (
							<>
								<button className="cursor-pointer rounded-lg bg-[#F5F5F5] p-2">
									<BiPhoneCall width="14px" height="14px" />
								</button>
								<button
									className="cursor-pointer rounded-lg bg-[#F5F5F5] p-2"
									onClick={handleCancelAppointment}
								>
									<LuTrash2 width="14px" height="14px" />
								</button>
							</>
						)}
					</div>
					{status === "approved" && (
						<>
							{checked_in ? (
								<TooltipProvider>
									<Tooltip delayDuration={0}>
										<TooltipTrigger asChild>
											<button className="rounded-lg border border-[#3F9590] bg-[#F5F5F5] p-2 text-[#3F9590]">
												<BiPhoneCall
													width="14px"
													height="14px"
												/>
											</button>
										</TooltipTrigger>

										<TooltipContent
											side="top"
											sideOffset={10}
										>
											{data?.customer?.phone_number ??
												"N/A"}
										</TooltipContent>
									</Tooltip>
								</TooltipProvider>
							) : (
								<button
									className="rounded-lg border border-[#3F9590] bg-[#F5F5F5] p-2 text-[#3F9590]"
									onClick={handleNotifyPatient}
								>
									<LuBellRing width="14px" height="14px" />
								</button>
							)}
						</>
					)}
					{status === "waiting_to_be_approved" && (
						<button
							className="rounded-lg border border-[#C8322B] bg-[#F5F5F5] p-2 text-[#3F9590]"
							onClick={handleApproveAppointment}
						>
							<LuCheckCircle2
								className="bg-[#F5F5F5]"
								color="#C8322B"
								width="14px"
								height="14px"
							/>
						</button>
					)}

					{status === "follow_up" && (
						<button
							className="rounded-lg border border-[#C8322B] bg-[#3F9590] p-2 text-[#3F9590]"
							onClick={handleFollowUpAppointment}
						>
							<i className="mgc_calendar_time_add_line  text-lg before:!text-[#3F9590] " />
						</button>
					)}
				</div>
			</div>
			<PatientInformation
				patientDetails={data as QueueEntry}
				showPatientInformation={showPatientInformation}
				setShowPatientInformation={setShowPatientInformation}
			/>
			<RescheduleAppointment
				showRescheduleModal={showRescheduleModal}
				setShowRescheduleModal={setShowRescheduleModal}
				appointmentData={data}
				closeModal={() => setShowRescheduleModal(false)}
			/>
		</>
	);
};

export default AppointmentCard;
