import { Input } from "@/src/components/ui/input";
import { useGetBusinessLocationsAndStations } from "@/src/store/slices/locations/locationSlice";
import { Label } from "@components/ui/label";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoaderButton } from "@src/components/form/LoaderButton";
import { Button } from "@src/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@src/components/ui/dialog";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "@src/components/ui/select";
import { AddPatientSlice } from "@/src/store/slices/waitlist/managePatientSlice";
import useUserStore from "@src/store/useUserStore";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { LuX } from "react-icons/lu";
import { z } from "zod";
import { ModalProps } from "../../../../types";
import { countryCodes } from "../../../../utils/constants";
import CustomIntakeField, {
	createJoinWaitlistSchema,
	customIntakesFormat,
} from "../../CustomIntakeField";

const AddPatient: React.FC<ModalProps> = ({ show, setShow }) => {
	const { user, currentStationId } = useUserStore((s) => ({
		user: s.user,
		currentStationId: s.currentStationId,
	}));
	const getBusinessLocationsAndStationsQuery =
		useGetBusinessLocationsAndStations();

	const [countryCode, setCountryCode] = useState("+1");
	const [selectedLocationId, setSelectedLocationId] = useState<
		string | undefined
	>(user?.business.locations[0].id.toString() ?? undefined);
	const [selectedStationId, setSelectedStationId] = useState<
		string | undefined
	>(currentStationId.toString());
	const [firstName, setFirstName] = useState("");
	const [schema, setSchema] = React.useState(createJoinWaitlistSchema([]));

	const addPatientMutation = AddPatientSlice(() => {
		setShow(false);
		reset();
	});

	const {
		reset,
		register,
		handleSubmit,
		setError,
		setValue,
		control,
		formState: { errors },
	} = useForm<z.infer<typeof schema>>({
		resolver: zodResolver(schema),
	});

	const onSubmit: SubmitHandler<z.infer<typeof schema>> = async (data) => {
		try {
			// const station =
			// 	getBusinessLocationsAndStationsQuery.data?.data.locations
			// 		.find(
			// 			(location) =>
			// 				location.id === parseInt(selectedLocationId ?? "0")
			// 		)
			// 		?.stations.find(
			// 			(station) =>
			// 				station.id.toString() ===
			// 				(selectedStationId === "All Stations"
			// 					? undefined
			// 					: selectedStationId?.toString())
			// 		);
			addPatientMutation.mutate({
				...data,
				full_name: (data.full_name + " " + firstName).trim(),
				phone_number: countryCode + data.phone_number,
				custom_intakes: customIntakesFormat(
					schema,
					getBusinessLocationsAndStationsQuery.data?.data.locations
						.find(
							(location) =>
								location.id ===
								parseInt(selectedLocationId ?? "0")
						)
						?.stations.find(
							(station) =>
								station.id.toString() ===
								(selectedStationId === "All Stations"
									? undefined
									: selectedStationId?.toString())
						)?.waitlist_custom_fields,
					data.custom_intakes
				),
				station_id: selectedStationId ?? "",
			});
		} catch (error) {
			setError("root", {
				message: "An error occured kindly try again later",
			});
		}
	};

	// console.log(errors);

	React.useEffect(() => {
		if (
			getBusinessLocationsAndStationsQuery.data?.data.locations &&
			getBusinessLocationsAndStationsQuery.data?.data.locations
				.find(
					(location) =>
						location.id === parseInt(selectedLocationId ?? "0")
				)
				?.stations.find(
					(station) =>
						station.id.toString() ===
						(selectedStationId === "All Stations"
							? undefined
							: selectedStationId?.toString())
				)?.waitlist_custom_fields
		) {
			const newSchema = createJoinWaitlistSchema(
				(getBusinessLocationsAndStationsQuery.data?.data.locations &&
					getBusinessLocationsAndStationsQuery.data?.data.locations
						.find(
							(location) =>
								location.id ===
								parseInt(selectedLocationId ?? "0")
						)
						?.stations.find(
							(station) =>
								station.id.toString() ===
								(selectedStationId === "All Stations"
									? undefined
									: selectedStationId?.toString())
						)?.waitlist_custom_fields) ??
					[]
			);
			setSchema(newSchema);
		}
	}, [getBusinessLocationsAndStationsQuery.data, selectedStationId]);

	return (
		<>
			<Dialog
				open={show}
				onOpenChange={(newOpen) => {
					reset();

					setShow(newOpen);
				}}
			>
				<DialogContent className="max-w-[516px] gap-0 px-0">
					<DialogHeader className="pb-0">
						<div className="flex justify-between px-6">
							<DialogTitle className="text-[22px] font-medium capitalize leading-[30px] -tracking-[1%] text-main-1">
								Add Patient
							</DialogTitle>
							<LuX
								onClick={() => {
									setShow(false);
									reset();
								}}
								color="#858C95"
								className="cursor-pointer"
								width="20px"
								height="20px"
							/>
						</div>
					</DialogHeader>
					<form
						onSubmit={handleSubmit(onSubmit)}
						className="mt-8 flex flex-col space-y-4 px-6"
					>
						<div className="flex items-center justify-between space-x-3">
							<div className="flex-1 space-y-1.5">
								<Label className="text-sm font-medium leading-5 tracking-[0.1%] text-main-1">
									Last Name{" "}
									<span className="text-destructive"> *</span>
								</Label>
								<Input
									className="h-10"
									{...register("full_name")}
								/>
								{errors.full_name?.message && (
									<small className="text-sm text-destructive">
										{errors.full_name?.message as string}
									</small>
								)}
							</div>
							<div className="flex-1 space-y-1.5">
								<Label className="text-sm font-medium leading-5 tracking-[0.1%] text-main-1">
									First Name
								</Label>
								<Input
									className="h-10"
									value={firstName}
									onChange={(e) => {
										setFirstName(e.target.value);
									}}
								/>
							</div>
						</div>

						<div className="space-y-1.5">
							<Label className="text-sm font-medium leading-5 tracking-[0.1%] text-main-1">
								Phone Number{" "}
								<span className="text-destructive"> *</span>
							</Label>
							<div className="flex h-10 items-stretch">
								<Select
									value={countryCode}
									onValueChange={(value) => {
										setCountryCode(value);
									}}
								>
									<SelectTrigger className="h-full w-fit rounded-r-none border-r-transparent shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]">
										<SelectValue
											className="text-[#858C95]"
											placeholder="+1"
										/>
									</SelectTrigger>
									<SelectContent className="!z-[9999]">
										<SelectGroup>
											<SelectLabel className="px-2">
												Country Codes
											</SelectLabel>

											{countryCodes.map((option) => {
												return (
													<SelectItem
														key={option.value}
														value={option.value}
														className="px-8"
													>
														{option.label}
													</SelectItem>
												);
											})}
										</SelectGroup>
									</SelectContent>
								</Select>
								<Input
									className="h-full rounded-l-none border border-[#E4E4E7] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
									minLength={10}
									maxLength={10}
									{...register("phone_number")}
								/>
							</div>
							{errors.phone_number?.message && (
								<small className="text-sm text-destructive">
									{errors.phone_number?.message as string}
								</small>
							)}
						</div>
						<div className="space-y-1.5">
							<Label className="text-sm font-medium leading-5 tracking-[0.1%] text-main-1">
								Email
							</Label>
							<Input {...register("email")} />
							{errors.email?.message && (
								<small className="text-sm text-destructive">
									{errors.email?.message as string}
								</small>
							)}
						</div>

						<div className="space-y-1.5 bg-white">
							<Label className="text-sm font-medium leading-5 tracking-[0.1%] text-main-1">
								Location<span className="text-red-500"> *</span>
							</Label>
							<Select
								value={selectedLocationId}
								onValueChange={(value) => {
									setSelectedLocationId(value);
									// setSelectedStations([]);
									setSelectedStationId(undefined);
								}}
							>
								<SelectTrigger>
									<SelectValue
										placeholder={"Select Location"}
										className="text-[#858C95]"
									/>
								</SelectTrigger>
								<SelectContent className="!z-[9999]">
									<SelectGroup>
										<SelectLabel className="px-2">
											Select Location
										</SelectLabel>

										{(user?.business.locations.length
											? user?.business.locations?.map(
													(location) => ({
														label: location.name,
														value: location.id.toString(),
													})
												) || []
											: []
										).map((option) => {
											return (
												<SelectItem
													key={option.value}
													value={option.value}
													className="px-8"
												>
													{option.label}
												</SelectItem>
											);
										})}
									</SelectGroup>
								</SelectContent>
							</Select>
						</div>
						<div className="space-y-1.5">
							<Label className="text-sm font-medium leading-5 tracking-[0.1%] text-main-1">
								Station <span className="text-red-500"> *</span>
							</Label>
							{/* <MultiSelect
								selected={selectedStations}
								onUnselect={(selectedOption) => {
									setSelectedStations((prev) =>
										prev.filter(
											(s) =>
												s.value !== selectedOption.value
										)
									);
								}}
								maxSelections={1}
								options={
									selectedLocationId
										? user?.business.locations
												.find(
													(location) =>
														location.id.toString() ===
														selectedLocationId
												)
												?.stations.map((station) => ({
													label: station.name,
													value: station.id.toString(),
												})) || []
										: []
								}
								placeholder={"Select Station(s)"}
								onSelect={(newSelectedOptions) => {
									if (newSelectedOptions.length >= 2) return;
									setSelectedStations(newSelectedOptions);
								}}
							/> */}
							<Select
								value={
									selectedStationId === "All Stations"
										? undefined
										: selectedStationId?.toString()
								}
								onValueChange={(value) => {
									setSelectedStationId(value);
									setValue("station_id", value);
								}}
							>
								<SelectTrigger>
									<SelectValue
										placeholder={"Select Station"}
										className="text-[#858C95]"
									/>
								</SelectTrigger>
								<SelectContent className="!z-[9999]">
									<SelectGroup>
										<SelectLabel className="px-2">
											Select Station
										</SelectLabel>

										{(selectedLocationId
											? user?.business.locations
													.find(
														(location) =>
															location.id.toString() ===
															selectedLocationId
													)
													?.stations.map(
														(station) => ({
															label: station.name,
															value: station.id.toString(),
														})
													) || []
											: []
										).map((option) => {
											return (
												<SelectItem
													key={option.value}
													value={option.value}
													className="px-8"
												>
													{option.label}
												</SelectItem>
											);
										})}
									</SelectGroup>
								</SelectContent>
							</Select>
						</div>

						{getBusinessLocationsAndStationsQuery.data?.data
							.locations
							? getBusinessLocationsAndStationsQuery.data?.data.locations
									.find(
										(location) =>
											location.id ===
											parseInt(selectedLocationId ?? "0")
									)
									?.stations.find(
										(station) =>
											station.id.toString() ===
											(selectedStationId ===
											"All Stations"
												? undefined
												: selectedStationId?.toString())
									)
									?.waitlist_custom_fields.map(
										(customField, idx) => (
											<CustomIntakeField
												key={idx}
												intake={customField}
												register={register}
												errors={errors}
												control={control}
											/>
										)
									)
							: ""}

						<div className="flex flex-row-reverse space-x-1 self-end">
							<LoaderButton
								className="px-6 text-white"
								type="submit"
								loading={addPatientMutation.isLoading}
								disabled={addPatientMutation.isLoading}
								loaderSize={20}
							>
								Add
							</LoaderButton>
							<Button
								className="text-[#323539]"
								variant={"ghost"}
								onClick={() => {
									setShow(false);
									reset();
								}}
							>
								Cancel
							</Button>
						</div>
					</form>
				</DialogContent>
			</Dialog>

			{/* <ShouldDoAutoSequenceForAddingPatient
				show={showShouldDoAutoSequenceModal}
				setShow={setShowShouldDoAutoSequenceModal}
				setShowPatientDetailsModal={setShowPatientDetailsModal}
				setStationList={setStationList}
			/>

			<PatientDetail
				show={showPatientDetailsModal}
				setShow={setShowPatientDetailsModal}
				patientDetails={patientDetails}
				stationList={stationList}
				setStationList={setStationList}
			/> */}
		</>
	);
};

export default AddPatient;
