import { APIVersion1GetCustomers } from "@/src/http/v1";
import { APIVersion2GetPatientActivity } from "@/src/http/v2";
import { PatientHistoryResponse } from "@/src/types/waitlist/waitlist";
import {
	useMutation,
	UseMutationResult,
	useQueryClient,
	useQuery,
	UseQueryOptions,
} from "react-query";

export const GetCustomersSlice = (
	onError: (error: Error) => void = () => {}
) => {
	return useQuery<{ status: boolean; data: any }, Error>({
		queryKey: ["get-customers"],
		queryFn: APIVersion1GetCustomers,
		onSuccess: () => {
			console.error("Unexpected data structure");
		},
		onError,
	} as UseQueryOptions<{ status: boolean; data: any }, Error>);
};

export const GetPatientWaitlistHistorysSlice = (
	customer_id: number,
	onError: (error: Error) => void = () => {}
) => {
	return useQuery<PatientHistoryResponse, Error>({
		queryKey: ["get-patient-waitlist-history", customer_id],
		queryFn: () => APIVersion2GetPatientActivity({ customer_id }),
		onSuccess: () => {
			console.error("Unexpected data structure");
		},
		onError,
	});
};
