import {
	APIVersion1AddRemoveBulkCustomField,
	APIVersion1CreateCustomFields,
	APIVersion1DeleteCustomFields,
	APIVersion1GetCustomFields,
	APIVersion1RemoveCustomFields,
	APIVersion1UpdateCustomField,
	APIVersion1UpdateStationCustomField,
} from "@src/http/v1";
import { useQuery, useQueryClient, useMutation } from "react-query";
import toast from "react-hot-toast";
import { AxiosError } from "axios";
import { ErrorResponse } from "@src/types";
import useCustomToast from "@src/components/CustomToast";
import { displayErrorsWithTimeout } from "@src/utils/functions";
import { AddRemoveBulkCustomFieldProps } from "@src/types/CustomFields";
import { queueSettingsProps } from "@src/types/Location";

export const GetCustomFieldsSlice = (queryParams: {
	station_id?: number | string;
	location_id?: number | string;
	apply_to?: string;
}) => {
	const queryClient = useQueryClient();
	const queryKey = ["custom-fields", queryParams];
	const query = useQuery<{ status: boolean; data: any }, Error>(
		queryKey,
		() => APIVersion1GetCustomFields(queryParams),
		{
			// enabled: Boolean(queryParams.location_id || queryParams.station_id),
			onError: (error) => {
				toast.error(error.message);
			},
		}
	);

	const invalidateQuery = () => {
		queryClient.invalidateQueries(queryKey);
	};

	return { ...query, invalidateQuery };
};

export const CreateCustomFields = (
	onSuccess: (data: any) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	// const queryClient = useQueryClient();
	const customToast = useCustomToast();
	return useMutation<
		any,
		AxiosError<ErrorResponse>,
		{
			data: any;
			queryParams?: {
				station_id?: number | string;
				location_id?: number | string;
			};
		}
	>(
		({ data, queryParams }) =>
			APIVersion1CreateCustomFields({ data, queryParams }),
		{
			onSuccess: (data) => {
				toast.success(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p>
								{data.message ||
									"Custom field created successfully"}
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() =>
								toast.dismiss("create-custom-fields")
							}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "create-custom-fields",
						duration: 10000,
					}
				);
				setTimeout(() => {
					onSuccess(data);
				}, 500);
			},
			onError: (error: AxiosError<ErrorResponse | any>) => {
				onError?.(error);
				if (error.response?.data) {
					displayErrorsWithTimeout(error.response.data, customToast, {
						toastIdPrefix: "create-custom-fields",
					});
				} else {
					displayErrorsWithTimeout(
						"An unexpected error occurred. Please try again later",
						customToast,
						{
							toastIdPrefix: "create-custom-fields",
						}
					);
				}
			},
		}
	);
};

export const UpdateCustomFields = (
	onSuccess: (data: any) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	// const queryClient = useQueryClient();
	const customToast = useCustomToast();
	return useMutation<
		any,
		AxiosError<ErrorResponse>,
		{
			data: any;
			id: number | string;
		}
	>(({ data, id }) => APIVersion1UpdateCustomField(data, id), {
		onSuccess: (data) => {
			toast.success(
				<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
					<div className="flex items-center space-x-5">
						<p>
							{data.message ||
								"Custom field updated successfully"}
						</p>
					</div>
					<button
						className="h-fit p-2.5"
						onClick={() => toast.dismiss("update-custom-fields")}
					>
						<i className="mgc_close_line" />
					</button>
				</div>,
				{
					id: "update-custom-fields",
					duration: 10000,
				}
			);
			setTimeout(() => {
				onSuccess(data);
			}, 500);
		},
		onError: (error: AxiosError<ErrorResponse | any>) => {
			onError?.(error);
			if (error.response?.data) {
				displayErrorsWithTimeout(error.response.data, customToast, {
					toastIdPrefix: "update-custom-fields",
				});
			} else {
				displayErrorsWithTimeout(
					"An unexpected error occurred. Please try again later",
					customToast,
					{
						toastIdPrefix: "update-custom-fields",
					}
				);
			}
		},
	});
};

export const UpdateStationCustomFields = (
	onSuccess: (data: any) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	// const queryClient = useQueryClient();
	const customToast = useCustomToast();
	return useMutation<
		any,
		AxiosError<ErrorResponse>,
		{
			data: any;
			id: number | string;
		}
	>(({ data, id }) => APIVersion1UpdateStationCustomField(data, id), {
		onSuccess: (data) => {
			toast.success(
				<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
					<div className="flex items-center space-x-5">
						<p>
							{data.message ||
								"Custom field updated successfully"}
						</p>
					</div>
					<button
						className="h-fit p-2.5"
						onClick={() => toast.dismiss("update-custom-fields")}
					>
						<i className="mgc_close_line" />
					</button>
				</div>,
				{
					id: "update-custom-fields",
					duration: 10000,
				}
			);
			setTimeout(() => {
				onSuccess(data);
			}, 500);
		},
		onError: (error: AxiosError<ErrorResponse | any>) => {
			onError?.(error);
			if (error.response?.data) {
				displayErrorsWithTimeout(error.response.data, customToast, {
					toastIdPrefix: "update-custom-fields",
				});
			} else {
				displayErrorsWithTimeout(
					"An unexpected error occurred. Please try again later",
					customToast,
					{
						toastIdPrefix: "update-custom-fields",
					}
				);
			}
		},
	});
};

export const DeleteCustomFieldsSlice = (
	onSuccess: (data: any) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	return useMutation<any, Error, { id: number }>(
		({ id }) => APIVersion1DeleteCustomFields(id),
		{
			onSuccess: (data) => {
				toast.success(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p>
								{data?.message ||
									"Deleted custom field successfully"}
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() => toast.dismiss("delete-custom-field")}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "delete-custom-field",
						duration: 10000,
					}
				);
				setTimeout(() => {
					onSuccess(data);
				}, 500);
			},
			onError: (error) => {
				onError(error);
				toast.error(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p className="text-lg font-medium text-[#E33B32]">
								Custom field could not be deleted
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() => toast.dismiss("delete-custom-field")}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "delete-custom-field",
						duration: 10000,
					}
				);
				console.error(error);
			},
		}
	);
};

export const RemoveCustomFieldsSlice = (
	onSuccess: (data: any) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	return useMutation<any, Error, { id: number }>(
		({ id }) => APIVersion1RemoveCustomFields(id),
		{
			onSuccess: (data) => {
				toast.success(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p>
								{data?.message ||
									"Deleted custom field successfully"}
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() => toast.dismiss("remove-custom-field")}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "remove-custom-field",
						duration: 10000,
					}
				);
				setTimeout(() => {
					onSuccess(data);
				}, 500);
			},
			onError: (error) => {
				onError(error);
				toast.error(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p className="text-lg font-medium text-[#E33B32]">
								Custom field could not be deleted
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() => toast.dismiss("remove-custom-field")}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "remove-custom-field",
						duration: 10000,
					}
				);
				console.error(error);
			},
		}
	);
};

export const AddRemoveBulkCustomFieldSlice = (
	onSuccess: (data: { data: queueSettingsProps }) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	return useMutation<
		any,
		Error,
		{
			data: AddRemoveBulkCustomFieldProps;
			queryParams: {
				apply_to?: string;
			};
		}
	>(
		({ data, queryParams }) =>
			APIVersion1AddRemoveBulkCustomField({ data, queryParams }),
		{
			onSuccess: (data) => {
				onSuccess(data);

				toast.success(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p>
								{data.message ||
									"Settings updated successfully"}
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() =>
								toast.dismiss("update-queue-settings")
							}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "update-queue-settings",
						duration: 10000,
					}
				);
				setTimeout(() => {
					onSuccess(data);
				}, 500);
			},
			onError: (error) => {
				onError(error);
				toast.error(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p className="text-lg font-medium text-[#E33B32]">
								{error?.message || "Update failed"}
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() =>
								toast.dismiss("update-queue-settings")
							}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "update-queue-settings",
						duration: 10000,
					}
				);
				console.error(error);
			},
		}
	);
};
