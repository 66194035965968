import {
	useMutation,
	UseMutationResult,
	useQueryClient,
	useQuery,
	UseQueryOptions,
} from "react-query";
import toast from "react-hot-toast";
import {
	displayErrorsWithTimeout,
	isValidQueryParams,
} from "@src/utils/functions";
import { APIVersion1GetWorkingHours } from "@src/http/v1";

// Working Hours
export const GetWorkingHoursSlice = (
	queryParams: Record<string, number | string>,
	onError: (error: Error) => void = () => {}
) => {
	return useQuery<{ status: boolean; data: any }, Error>(
		["get-working-hours", queryParams],
		() => APIVersion1GetWorkingHours(queryParams),
		{
			onSuccess: () => {},
			onError,
			enabled: isValidQueryParams(queryParams),
		}
	);
};

// Working Hours End
