import { z } from "zod";

export const UserAccountSettingSchema = z.object({
	email: z.string().email({ message: "Invalid email address format" }),
	full_name: z
		.string()
		.min(5, { message: "Full name must be at least 5 characters" }),

	phone_number: z.string().optional(),
});

export const ChangePasswordSchema = z
	.object({
		password: z
			.string()
			.min(8, { message: "Password must be at least 8 characters long" })
			.regex(/[A-Z]/, {
				message: "Password must contain at least one uppercase letter",
			})
			.regex(/[a-z]/, {
				message: "Password must contain at least one lowercase letter",
			})
			.regex(/\d/, {
				message: "Password must contain at least one number",
			})
			.regex(/[@$!%*?&#]/, {
				message: "Password must contain at least one special character",
			}),
		password_confirmation: z.string(),
	})
	.refine(
		(values) => {
			return values.password === values.password_confirmation;
		},
		{
			message: "Passwords do not match!",
			path: ["password_confirmation"],
		}
	);

export type UserAccountSettingType = z.infer<typeof UserAccountSettingSchema>;
export type ChangePasswordType = z.infer<typeof ChangePasswordSchema>;
