import { Tabs, TabsContent, TabsList, TabsTrigger } from "@components/ui/tabs";
import React, { useState } from "react";
import { WaitingHours } from "./WaitingHours";
import { Button } from "@components/ui/button";
import { useFormContext } from "react-hook-form";

export default function ScheduleForm({
	title,
	showTab = true,
	activeTab,
}: {
	title?: string | boolean;
	showTab?: boolean;
	activeTab?: "waitlist" | "schedule";
}) {
	const { setValue } = useFormContext();
	const [tabView, setTabView] = useState<"waitlist" | "schedule">(
		activeTab || "waitlist"
	);

	const handleTabSwitch = (tab: "waitlist" | "schedule") => {
		setTabView(tab);
		setValue("tabView", tab);
		if (tab === "waitlist") {
			setValue("is_waitlist", true);
			setValue("is_schedule", false);
		} else {
			setValue("is_schedule", true);
			setValue("is_waitlist", false);
		}
	};

	return (
		<div className="space-y-8">
			{!title ? null : (
				<h3 className="text-xl font-medium">
					{title || "Operating Hours"}
				</h3>
			)}

			<div
				className={`${showTab ? "mx-auto grid w-full max-w-6xl items-start gap-6 md:grid-cols-[180px_1fr] lg:grid-cols-[250px_1fr]" : ""}`}
			>
				{showTab && (
					<nav className="grid gap-1 text-sm text-muted-foreground">
						<Button
							type="button"
							variant={"ghost"}
							onClick={() => handleTabSwitch("waitlist")}
							className={`flex items-center justify-start rounded-lg px-3 py-2 font-medium text-[#323539]  opacity-100 hover:bg-transparent hover:text-muted-foreground ${tabView === "waitlist" ? "bg-primary-50 text-primary hover:bg-primary-50" : "text-foreground"}`}
						>
							Waitlist
						</Button>
						<Button
							type="button"
							variant={"ghost"}
							onClick={() => handleTabSwitch("schedule")}
							className={`flex items-center justify-start rounded-lg px-3 py-2 font-medium text-[#323539]  opacity-100 hover:bg-transparent hover:text-muted-foreground ${tabView === "schedule" ? "bg-primary-50 text-primary hover:bg-primary-50" : "text-foreground"}`}
						>
							Schedule
						</Button>
					</nav>
				)}
				<div className="grid gap-6">
					<WaitingHours tabView={tabView} showTab={showTab} />
				</div>
			</div>
		</div>
	);
}
