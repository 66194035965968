import React, { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
	arrayMove,
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
// import { Switch } from "@radix-ui/react-switch";
import CustomCheckbox2 from "@src/components/form/CustomCheckbox2";
import { MdOutlineDragIndicator } from "react-icons/md";
import CustomSwitchAlt from "@src/components/global/CustomSwitchAlt";
import { Input } from "@src/components/ui/input";
import { Slider } from "@src/components/ui/slider";
// import FormInput from "@src/components/form/FormInput";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@src/components/ui/select";
import {
	GetStationDetailsSlice,
	QueueSettingsSlice,
} from "@src/store/slices/locations/locationSlice";
import { useSearchParams } from "react-router-dom";
import {
	// queueSettingsProps,
	// queueSettingsSchema,
	// scheduleOptimizerSchema,
	// scheduleOptimizerProps,
	queueSettingsProps,
	queueSettingsSchema,
} from "@src/types/Location";
import Checkbox from "@src/components/ui-extended/checkbox";
import { Button } from "@src/components/ui/button";
import useStationStore from "@src/store/useStationStore";
import { Switch } from "@src/components/ui/switch";
import Loader from "@src/components/Loader/Loader";
import _ from "lodash";

// type Item = {
// 	id: string;
// 	title: string;
// 	checked: boolean;
// 	quantity: number;
// 	duration: string;
// 	isRange?: boolean;
// };

// const initialItems: Item[] = [
// 	{
// 		id: "1",
// 		title: "Waitlist",
// 		checked: false,
// 		quantity: 10,
// 		duration: "Weeks away",
// 		isRange: false,
// 	},
// 	{
// 		id: "2",
// 		title: "High Priority",
// 		checked: false,
// 		quantity: 10,
// 		duration: "Weeks away",
// 		isRange: false,
// 	},
// 	{
// 		id: "3",
// 		title: "Upcoming Appointments",
// 		checked: true,
// 		quantity: 4,
// 		duration: "Weeks away",
// 		isRange: true,
// 	},
// ];

// const SortableItem = ({ id, item }: { id: string; item: Item }) => {
// 	const [searchParams] = useSearchParams();
// 	const stationId = searchParams.get("stationId") || "";
// 	const { attributes, listeners, setNodeRef, transform, transition } =
// 		useSortable({ id });

// 	const style = {
// 		transform: CSS.Transform.toString(transform),
// 		transition,
// 	};

// 	const options = [
// 		{ value: "days", label: "Days" },
// 		{ value: "weeks", label: "Weeks" },
// 		{ value: "months", label: "Months" },
// 	];

// 	const { invalidateQuery } = GetStationDetailsSlice(
// 		Number(parseInt(stationId))
// 	);

// 	const methods = useForm<queueSettingsProps>({
// 		resolver: zodResolver(queueSettingsSchema),
// 		defaultValues: {
// 			schedule_optimizer: {
// 				waitlist_selected: 0,
// 				waitlist_number_of_people: 0,
// 				high_priority_selected: 0,
// 				high_priority_number_of_people: 0,
// 				upcoming_appointments_selected: 0,
// 				upcoming_appointments_number_of_people: 0,
// 				upcoming_appointments_limit: 0,
// 				upcoming_appointments_limit_options: "days",
// 			},
// 		},
// 	});

// 	const {
// 		handleSubmit,
// 		reset,
// 		setValue,
// 		watch,
// 		control,
// 		formState: { errors },
// 	} = methods;

// 	return (
// 		<div
// 			ref={setNodeRef}
// 			style={style}
// 			{...attributes}
// 			className="mb-2 flex overflow-hidden rounded-lg bg-[#F8F8F8]"
// 		>
// 			<div
// 				{...listeners}
// 				className="flex h-auto cursor-grab items-center px-3 hover:bg-[#000]/5"
// 			>
// 				<MdOutlineDragIndicator className="text-gray-400" size={"24"} />
// 			</div>
// 			<div className="flex flex-1 items-center justify-between space-x-4 p-2">
// 				<Checkbox
// 					isChecked={item.checked}
// 					handleCheckboxChange={() => {}}
// 					className="rounded-sm border-2 border-[#D1D1D1]"
// 					id={item.id}
// 				/>
// 				<p className="w-[185px] text-sm">{item.title}</p>
// 				{item?.isRange && (
// 					<Input
// 						className="h-10 w-[60px] border-[#E5E5E7] bg-[#FFFFFF] text-center text-main-1  opacity-50"
// 						maxLength={40}
// 					/>
// 				)}

// 				<div className="flex-1">
// 					<Slider
// 						defaultValue={[33]}
// 						max={100}
// 						step={1}
// 						thumbClassName="bg-[#DEDEDE] border-[#FFFFFF] border-[0.5px]"
// 						beforeThumbClassName="bg-[#DEDEDE]"
// 						afterThumbClassName="bg-[#DEDEDE] shadow-custom-inset"
// 					/>
// 				</div>
// 				<div>
// 					<Input
// 						className="h-10 w-[80px] border-[#E5E5E7] bg-[#FFFFFF] text-center text-main-1 opacity-50"
// 						maxLength={40}
// 					/>
// 				</div>
// 				{item?.isRange && (
// 					<Select
// 						value={watch(
// 							"schedule_setting.schedule_block_away_option"
// 						)}
// 						onValueChange={(value: "days" | "weeks" | "months") => {
// 							setValue(
// 								"schedule_setting.schedule_block_away_option",
// 								value
// 							);
// 						}}
// 					>
// 						<SelectTrigger className="focus-within::border-transparent flex w-max items-center space-x-2.5 rounded-md border-[#E5E5E7] bg-[#FFFFFF] px-4 py-0 opacity-50">
// 							<SelectValue
// 								placeholder={"Select duration"}
// 								className="focus-within::border-transparent text-[#323539]"
// 							/>
// 						</SelectTrigger>
// 						<SelectContent className="focus-within::border-transparent !z-[9999]">
// 							<SelectGroup>
// 								{options.map(
// 									(option: {
// 										label: string;
// 										value: string;
// 									}) => {
// 										return (
// 											<SelectItem
// 												key={option.value}
// 												value={option.value}
// 												className="px-8"
// 											>
// 												{option.label + " " + "away"}
// 											</SelectItem>
// 										);
// 									}
// 								)}
// 							</SelectGroup>
// 						</SelectContent>
// 					</Select>
// 				)}
// 			</div>
// 		</div>
// 	);
// };

const ScheduleOptimizer = () => {
	const stationData = useStationStore((s) => s.stationDetails);
	const [searchParams] = useSearchParams();
	const stationId = searchParams.get("stationId") || "";

	const { invalidateQuery } = GetStationDetailsSlice(
		Number(parseInt(stationId))
	);

	const methods = useForm<queueSettingsProps>({
		resolver: zodResolver(queueSettingsSchema),
		defaultValues: {
			schedule_optimizer: {
				is_active: 0,
				waitlist_selected: 0,
				waitlist_number_of_people: 0,
				high_priority_selected: 0,
				high_priority_number_of_people: 0,
				upcoming_appointments_selected: 0,
				upcoming_appointments_number_of_people: 0,
				upcoming_appointments_limit: 0,
				upcoming_appointments_limit_options: "days",
			},
		},
	});

	const {
		handleSubmit,
		reset,
		setValue,
		watch,
		control,
		formState: { errors },
	} = methods;

	const defaultData = {
		is_active: stationData?.queue_setting?.schedule_optimizer?.is_active,
		waitlist_selected:
			stationData?.queue_setting?.schedule_optimizer?.waitlist_selected,
		waitlist_number_of_people:
			stationData?.queue_setting?.schedule_optimizer
				?.waitlist_number_of_people,
		high_priority_selected:
			stationData?.queue_setting?.schedule_optimizer
				?.high_priority_selected,
		high_priority_number_of_people:
			stationData?.queue_setting?.schedule_optimizer
				?.high_priority_number_of_people,
		upcoming_appointments_selected:
			stationData?.queue_setting?.schedule_optimizer
				?.upcoming_appointments_selected,
		upcoming_appointments_number_of_people:
			stationData?.queue_setting?.schedule_optimizer
				?.upcoming_appointments_number_of_people,
		upcoming_appointments_limit:
			stationData?.queue_setting?.schedule_optimizer
				?.upcoming_appointments_limit,
		upcoming_appointments_limit_options:
			stationData?.queue_setting?.schedule_optimizer
				?.upcoming_appointments_limit_options,
	};

	const checkUpdatePayloadButton = {
		is_active: watch("schedule_optimizer")?.is_active,
		waitlist_selected: watch("schedule_optimizer")?.waitlist_selected,
		waitlist_number_of_people:
			watch("schedule_optimizer")?.waitlist_number_of_people,
		high_priority_selected:
			watch("schedule_optimizer")?.high_priority_selected,
		high_priority_number_of_people:
			watch("schedule_optimizer")?.high_priority_number_of_people,
		upcoming_appointments_selected:
			watch("schedule_optimizer")?.upcoming_appointments_selected,
		upcoming_appointments_number_of_people:
			watch("schedule_optimizer")?.upcoming_appointments_number_of_people,
		upcoming_appointments_limit:
			watch("schedule_optimizer")?.upcoming_appointments_limit,
		upcoming_appointments_limit_options:
			watch("schedule_optimizer")?.upcoming_appointments_limit_options,
	};

	const handleSwitchChange = (field: any) => (value: boolean) => {
		setValue(field, value ? 1 : 0);
	};

	const { mutate: updateQueueSettings, isLoading } = QueueSettingsSlice(
		(data) => {
			console.log("Update successful:", data);
			invalidateQuery();
		},
		(error) => {
			console.error("Update failed:", error);
		}
	);

	const handleCancel = () => {
		reset({
			schedule_optimizer: {
				is_active:
					stationData?.queue_setting?.schedule_optimizer?.is_active ||
					0,
				waitlist_selected:
					stationData?.queue_setting?.schedule_optimizer
						?.waitlist_selected || 0,
				waitlist_number_of_people:
					stationData?.queue_setting?.schedule_optimizer
						?.waitlist_number_of_people || 0,
				high_priority_selected:
					stationData?.queue_setting?.schedule_optimizer
						?.high_priority_selected || 0,
				high_priority_number_of_people:
					stationData?.queue_setting?.schedule_optimizer
						?.high_priority_number_of_people || 0,
				upcoming_appointments_selected:
					stationData?.queue_setting?.schedule_optimizer
						?.upcoming_appointments_selected || 0,
				upcoming_appointments_number_of_people:
					stationData?.queue_setting?.schedule_optimizer
						?.upcoming_appointments_number_of_people || 0,
				upcoming_appointments_limit:
					stationData?.queue_setting?.schedule_optimizer
						?.upcoming_appointments_limit || 0,
				upcoming_appointments_limit_options:
					stationData?.queue_setting?.schedule_optimizer
						?.upcoming_appointments_limit_options || "days",
			},
		});
	};

	const onSubmit: SubmitHandler<queueSettingsProps> = (data) => {
		updateQueueSettings({
			data: {
				...data,
				apply_to_option: {
					apply_to_all: 0,
					locations: [
						{
							id: stationData?.location_id || 0,
							update_location: 0,
							apply_to_all_stations: 0,
							selected_stations: [stationData?.id || 0],
						},
					],
				},
			},
			queryParams: { station_id: stationId || 0 },
		});
	};

	useEffect(() => {
		reset({
			schedule_optimizer: {
				is_active:
					stationData?.queue_setting?.schedule_optimizer?.is_active ||
					0,
				waitlist_selected:
					stationData?.queue_setting?.schedule_optimizer
						?.waitlist_selected || 0,
				waitlist_number_of_people:
					stationData?.queue_setting?.schedule_optimizer
						?.waitlist_number_of_people || 0,
				high_priority_selected:
					stationData?.queue_setting?.schedule_optimizer
						?.high_priority_selected || 0,
				high_priority_number_of_people:
					stationData?.queue_setting?.schedule_optimizer
						?.high_priority_number_of_people || 0,
				upcoming_appointments_selected:
					stationData?.queue_setting?.schedule_optimizer
						?.upcoming_appointments_selected || 0,
				upcoming_appointments_number_of_people:
					stationData?.queue_setting?.schedule_optimizer
						?.upcoming_appointments_number_of_people || 0,
				upcoming_appointments_limit:
					stationData?.queue_setting?.schedule_optimizer
						?.upcoming_appointments_limit || 0,
				upcoming_appointments_limit_options:
					stationData?.queue_setting?.schedule_optimizer
						?.upcoming_appointments_limit_options || "days",
			},
		});
	}, [reset, stationData]);

	// const handleDragEnd = (event: any) => {
	// 	const { active, over } = event;

	// 	if (active.id !== over.id) {
	// 		setItems((items) => {
	// 			const oldIndex = items.findIndex(
	// 				(item) => item.id === active.id
	// 			);
	// 			const newIndex = items.findIndex((item) => item.id === over.id);
	// 			return arrayMove(items, oldIndex, newIndex);
	// 		});
	// 	}
	// };

	const options = [
		{ value: "days", label: "Days" },
		{ value: "weeks", label: "Weeks" },
		{ value: "months", label: "Months" },
	];

	const handleCheckboxSelection2 = (type: any, isChecked: boolean) => {
		setValue(type, isChecked ? 1 : 0);
	};

	return (
		<div>
			<div className="flex justify-between">
				<div className="">
					<h3 className="text-xl font-semibold">
						Schedule Optimizer
					</h3>
					<p className="text-sm text-gray-400">
						We email/sms your patients who are in line in the
						following categories.
					</p>
				</div>
				{/* <CustomSwitchAlt /> */}
				<div className="flex items-center">
					<Controller
						name="schedule_optimizer.is_active"
						control={control}
						render={({ field }) => (
							<Switch
								className="scale-[0.70] data-[state=checked]:bg-[#2EBF43]"
								checked={Boolean(field.value)}
								onCheckedChange={handleSwitchChange(
									"schedule_optimizer.is_active"
								)}
							/>
						)}
					/>
					<span className="">
						{watch("schedule_optimizer.is_active") ? "Yes" : "No"}
					</span>
				</div>
			</div>

			<div className="border-b py-4">
				<h2 className="text-lg">
					Select categories you want notified in order.
				</h2>
				<p className="mb-4">
					You may also select the quantity of people who will be
					notified.
				</p>
				{/* <DndContext
					collisionDetection={closestCenter}
					onDragEnd={handleDragEnd}
				>
					<SortableContext
						items={items}
						strategy={verticalListSortingStrategy}
					>
						{items.map((item) => (
							<SortableItem
								key={item.id}
								id={item.id}
								item={item}
							/>
						))}
					</SortableContext>
				</DndContext> */}
				<div
					className={`mb-2 flex overflow-hidden rounded-lg bg-[#F8F8F8] ${
						watch("schedule_optimizer.waitlist_selected")
							? "border border-primary shadow-custom-primary"
							: ""
					}`}
				>
					<div className="flex h-auto cursor-grab items-center px-3 hover:bg-[#000]/5">
						<MdOutlineDragIndicator
							className="text-gray-400"
							size={"24"}
						/>
					</div>
					<div className="flex flex-1 items-center justify-between space-x-4 p-2">
						<Controller
							name="schedule_optimizer.waitlist_selected"
							control={control}
							render={({ field }) => (
								<Checkbox
									isChecked={Boolean(field.value)}
									handleCheckboxChange={() =>
										handleCheckboxSelection2(
											"schedule_optimizer.waitlist_selected",
											!watch(
												"schedule_optimizer.waitlist_selected"
											)
										)
									}
									className="rounded-sm border-2 border-[#D1D1D1]"
									id={"schedule_optimizer.waitlist_selected"}
								/>
							)}
						/>

						<p className="w-[185px] text-sm">Waitlist</p>
						<div className="flex-1">
							<Slider
								defaultValue={[33]}
								max={100}
								step={1}
								thumbClassName={`border-[0.5px] ${
									watch(
										"schedule_optimizer.waitlist_selected"
									)
										? "bg-primary border-primary"
										: "bg-[#DEDEDE] border-[#FFFFFF]"
								}`}
								beforeThumbClassName={` ${
									watch(
										"schedule_optimizer.waitlist_selected"
									)
										? "bg-primary"
										: "bg-[#DEDEDE]"
								}`}
								afterThumbClassName="bg-[#DEDEDE] shadow-custom-inset"
							/>
						</div>
						<div>
							<Controller
								name="schedule_optimizer.waitlist_number_of_people"
								control={control}
								render={({ field }) => (
									<Input
										className={`h-10 w-[80px] border-[#E5E5E7] bg-[#FFFFFF] text-center text-main-1 ${
											watch(
												"schedule_optimizer.waitlist_selected"
											)
												? "opacity-100"
												: "opacity-50"
										}`}
										value={String(field.value)}
										onChange={(e) => {
											setValue(
												"schedule_optimizer.waitlist_number_of_people",
												parseInt(e.target.value)
											);
										}}
										min={1}
										type="number"
									/>
								)}
							/>
						</div>
					</div>
				</div>
				<div
					className={`mb-2 flex overflow-hidden rounded-lg bg-[#F8F8F8] ${
						watch("schedule_optimizer.high_priority_selected")
							? "border border-primary shadow-custom-primary"
							: ""
					}`}
				>
					<div className="flex h-auto cursor-grab items-center px-3 hover:bg-[#000]/5">
						<MdOutlineDragIndicator
							className="text-gray-400"
							size={"24"}
						/>
					</div>
					<div className="flex flex-1 items-center justify-between space-x-4 p-2">
						<Controller
							name="schedule_optimizer.high_priority_selected"
							control={control}
							render={({ field }) => (
								<Checkbox
									isChecked={Boolean(field.value)}
									handleCheckboxChange={() =>
										handleCheckboxSelection2(
											"schedule_optimizer.high_priority_selected",
											!watch(
												"schedule_optimizer.high_priority_selected"
											)
										)
									}
									className="rounded-sm border-2 border-[#D1D1D1]"
									id={
										"schedule_optimizer.high_priority_selected"
									}
								/>
							)}
						/>

						<p className="w-[185px] text-sm">High Priority</p>
						<div className="flex-1">
							<Slider
								defaultValue={[33]}
								max={100}
								step={1}
								thumbClassName={`border-[0.5px] ${
									watch(
										"schedule_optimizer.high_priority_selected"
									)
										? "bg-primary border-primary"
										: "bg-[#DEDEDE] border-[#FFFFFF]"
								}`}
								beforeThumbClassName={` ${
									watch(
										"schedule_optimizer.high_priority_selected"
									)
										? "bg-primary"
										: "bg-[#DEDEDE]"
								}`}
								afterThumbClassName="bg-[#DEDEDE] shadow-custom-inset"
							/>
						</div>
						<div>
							<Controller
								name="schedule_optimizer.high_priority_number_of_people"
								control={control}
								render={({ field }) => (
									<Input
										className={`h-10 w-[80px] border-[#E5E5E7] bg-[#FFFFFF] text-center text-main-1 ${
											watch(
												"schedule_optimizer.high_priority_selected"
											)
												? "opacity-100"
												: "opacity-50"
										}`}
										value={String(field.value)}
										onChange={(e) => {
											setValue(
												"schedule_optimizer.high_priority_number_of_people",
												parseInt(e.target.value)
											);
										}}
										min={1}
										type="number"
									/>
								)}
							/>
						</div>
					</div>
				</div>
				<div
					className={`mb-2 flex overflow-hidden rounded-lg bg-[#F8F8F8] ${
						watch(
							"schedule_optimizer.upcoming_appointments_selected"
						)
							? "border border-primary shadow-custom-primary"
							: ""
					}`}
				>
					<div className="flex h-auto cursor-grab items-center px-3 hover:bg-[#000]/5">
						<MdOutlineDragIndicator
							className="text-gray-400"
							size={"24"}
						/>
					</div>
					<div className="flex flex-1 items-center justify-between space-x-4 p-2">
						<Controller
							name="schedule_optimizer.upcoming_appointments_selected"
							control={control}
							render={({ field }) => (
								<Checkbox
									isChecked={Boolean(field.value)}
									handleCheckboxChange={() =>
										handleCheckboxSelection2(
											"schedule_optimizer.upcoming_appointments_selected",
											!watch(
												"schedule_optimizer.upcoming_appointments_selected"
											)
										)
									}
									className="rounded-sm border-2 border-[#D1D1D1]"
									id={
										"schedule_optimizer.upcoming_appointments_selected"
									}
								/>
							)}
						/>

						<p className="w-[185px] text-sm">
							Upcoming Appointments
						</p>
						<Controller
							name="schedule_optimizer.waitlist_number_of_people"
							control={control}
							render={({ field }) => (
								<Input
									className={`h-10 w-[80px] border-[#E5E5E7] bg-[#FFFFFF] text-center text-main-1 ${
										watch(
											"schedule_optimizer.upcoming_appointments_selected"
										)
											? "opacity-100"
											: "opacity-50"
									}`}
									value={String(field.value)}
									onChange={(e) => {
										setValue(
											"schedule_optimizer.waitlist_number_of_people",
											parseInt(e.target.value)
										);
									}}
									min={1}
									type="number"
								/>
							)}
						/>

						<div className="flex-1">
							<Slider
								defaultValue={[33]}
								max={100}
								step={1}
								thumbClassName={`border-[0.5px] ${
									watch(
										"schedule_optimizer.upcoming_appointments_selected"
									)
										? "bg-primary border-primary"
										: "bg-[#DEDEDE] border-[#FFFFFF]"
								}`}
								beforeThumbClassName={` ${
									watch(
										"schedule_optimizer.upcoming_appointments_selected"
									)
										? "bg-primary"
										: "bg-[#DEDEDE]"
								}`}
								afterThumbClassName="bg-[#DEDEDE] shadow-custom-inset"
							/>
						</div>
						<div>
							<Controller
								name="schedule_optimizer.upcoming_appointments_limit"
								control={control}
								render={({ field }) => (
									<Input
										className={`h-10 w-[80px] border-[#E5E5E7] bg-[#FFFFFF] text-center text-main-1 ${
											watch(
												"schedule_optimizer.upcoming_appointments_selected"
											)
												? "opacity-100"
												: "opacity-50"
										}`}
										value={String(field.value)}
										onChange={(e) => {
											setValue(
												"schedule_optimizer.upcoming_appointments_limit",
												parseInt(e.target.value)
											);
										}}
										min={1}
										type="number"
									/>
								)}
							/>
						</div>
						<Select
							value={watch(
								"schedule_optimizer.upcoming_appointments_limit_options"
							)}
							onValueChange={(
								value: "days" | "weeks" | "months"
							) => {
								setValue(
									"schedule_optimizer.upcoming_appointments_limit_options",
									value
								);
							}}
						>
							<SelectTrigger
								className={`focus-within::border-transparent flex w-max items-center space-x-2.5 rounded-md border-[#E5E5E7] bg-[#FFFFFF] px-4 py-0 ${
									watch(
										"schedule_optimizer.upcoming_appointments_selected"
									)
										? "opacity-100"
										: "opacity-50"
								}`}
							>
								<SelectValue
									placeholder={"Select duration"}
									className="focus-within::border-transparent text-[#323539]"
								/>
							</SelectTrigger>
							<SelectContent className="focus-within::border-transparent !z-[9999]">
								<SelectGroup>
									{options.map(
										(option: {
											label: string;
											value: string;
										}) => {
											return (
												<SelectItem
													key={option.value}
													value={option.value}
													className="px-8"
												>
													{option.label +
														" " +
														"away"}
												</SelectItem>
											);
										}
									)}
								</SelectGroup>
							</SelectContent>
						</Select>
					</div>
				</div>
			</div>

			<div className="mx-auto flex w-full max-w-[1000px] justify-end gap-2 py-4">
				<Button type="reset" onClick={handleCancel} variant={"ghost"}>
					Cancel
				</Button>
				<Button
					type="submit"
					className="px-9"
					disabled={_.isEqual(defaultData, checkUpdatePayloadButton)}
					onClick={handleSubmit(onSubmit)}
				>
					{isLoading ? <Loader size={20} /> : "Update"}
				</Button>
			</div>
		</div>
	);
};

export default ScheduleOptimizer;
